// import ToolkitProvider, {
// 	Search as _Search
// } from 'react-bootstrap-table2-toolkit';

/**
 * @description SearchBar.js:9 Uncaught ReferenceError: arguments is not defined
	at ./node_modules/react-bootstrap-table2-toolkit/lib/src/search/SearchBar.js
	to fix above issue need to use dist file
 */

import ToolkitProvider, {
	Search as _Search
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

export const Search = _Search;

export default ToolkitProvider;
