import React, { Component } from 'react';
import resetIcon from './../../assets/images/undo.png';

export default class App extends Component {
	initialState = {
		searchForm: {
			userId: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter User ID',
					labelname: 'User ID'
				},
				value: '',
				validation: {
					required: false,
					isAlphaNumeric: true
				},
				attrValidation: {
					maxLength: 10
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			firstName: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter First Name',
					labelname: 'First Name'
				},
				value: '',
				validation: {
					required: false,
					isAlphabets: true
				},
				attrValidation: {
					maxLength: 40
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			lastName: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter Last Name',
					labelname: 'Last Name'
				},
				value: '',
				validation: {
					required: false,
					isAlphabets: true
				},
				attrValidation: {
					maxLength: 40
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			phoneNo: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter Phone Number',
					labelname: 'Phone Number'
				},
				value: '',
				validation: {
					required: false,
					isPhoneNumber: true
				},
				attrValidation: {
					maxLength: 20
				},
				validationMessage: '',
				valid: false,
				touched: false
			}
		},
		formIsValid: false
	};

	state = this.initialState;

	handleFormReset = () => {
		this.setState(() => this.initialState);
	};

	inputChangedHandler = (value, isValidObj, inputIdentifier, dateTimeRangeChanged) => {
		const updatedLoginForm = { ...this.state.searchForm };
		const updatedFormElement = { ...updatedLoginForm[inputIdentifier] };

		updatedFormElement.value = value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;

		updatedLoginForm[inputIdentifier] = updatedFormElement;

		let formIsValid = true;
		for (let inputIdentifier in updatedLoginForm) {
			formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ searchForm: updatedLoginForm, formIsValid: formIsValid });
	};

	onInputChange = (event, definedRules, inputIdentifier) => {
		let value = event.target.value;
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value.trim() !== '';
				isValid.message = isValid.value ? '' : '';
			}

			if (rules.minLength) {
				isValid.value = value.length >= rules.minLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Minimum length is ' + rules.minLength;
			}

			if (rules.maxLength) {
				isValid.value = value.length <= rules.maxLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Maximum length is ' + rules.maxLength;
			}

			if (rules.isEmail && value) {
				let regEx = new RegExp(
					/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid email';
			}

			if (rules.isPhoneNumber && value) {
				let regEx = new RegExp(
					/^\+?(\d+|(\(\d+\))+)+((([ ]?(\d+|(\(\d+\))+)+)*)|((\-\d+)*)|((\.\d+)*))*$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid phone number';
			}

			if (rules.isAlphabets && value) {
				let regEx = new RegExp('^[a-zA-Z]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets';
			}

			if (rules.isAlphaNumeric && value) {
				let regEx = new RegExp('^[a-zA-Z0-9]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets and numbers';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, false);
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.searchForm) {
			formElementsArray.push({
				id: key,
				config: this.state.searchForm[key]
			});
		}
		return (
			<React.Fragment>
				<div className="modal-body">
					<div className="row p-4">
						<div className="col-md-8">
							<input
								type={formElementsArray[0].config.elementConfig.type}
								className={`form_control ${
									!formElementsArray[0].config.valid &&
									formElementsArray[0].config.touched
										? 'Invalid'
										: ''
								}`}
								value={formElementsArray[0].config.value}
								placeholder={formElementsArray[0].config.elementConfig.placeholder}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[0].config.validation,
										formElementsArray[0].id
									)
								}
								{...formElementsArray[0].config.attrValidation}
							/>
							<label
								className={`${
									formElementsArray[0].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[0].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[0].config.validationMessage}
							</p>
						</div>
					</div>
					<div className="row p-4">
						<div className="col-md-8">
							<input
								type={formElementsArray[1].config.elementConfig.type}
								className={`form_control ${
									!formElementsArray[1].config.valid &&
									formElementsArray[1].config.touched
										? 'Invalid'
										: ''
								}`}
								value={formElementsArray[1].config.value}
								placeholder={formElementsArray[1].config.elementConfig.placeholder}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[1].config.validation,
										formElementsArray[1].id
									)
								}
								{...formElementsArray[1].config.attrValidation}
							/>
							<label
								className={`${
									formElementsArray[1].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[1].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[1].config.validationMessage}
							</p>
						</div>
					</div>

					<div className="row p-4">
						<div className="col-md-8">
							<input
								type={formElementsArray[2].config.elementConfig.type}
								className={`form_control ${
									!formElementsArray[2].config.valid &&
									formElementsArray[2].config.touched
										? 'Invalid'
										: ''
								}`}
								value={formElementsArray[2].config.value}
								placeholder={formElementsArray[2].config.elementConfig.placeholder}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[2].config.validation,
										formElementsArray[2].id
									)
								}
								{...formElementsArray[2].config.attrValidation}
							/>
							<label
								className={`${
									formElementsArray[2].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[2].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[2].config.validationMessage}
							</p>
						</div>
					</div>
					<div className="row p-4">
						<div className="col-md-8">
							<input
								type={formElementsArray[3].config.elementConfig.type}
								className={`form_control ${
									!formElementsArray[3].config.valid &&
									formElementsArray[3].config.touched
										? 'Invalid'
										: ''
								}`}
								value={formElementsArray[3].config.value}
								placeholder={formElementsArray[3].config.elementConfig.placeholder}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[3].config.validation,
										formElementsArray[3].id
									)
								}
								{...formElementsArray[3].config.attrValidation}
							/>
							<label
								className={`${
									formElementsArray[3].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[3].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[3].config.validationMessage}
							</p>
						</div>
					</div>
				</div>
				<div className="modal-footer border-0">
					<p
						onClick={() => {
							this.props.resetUsersArray();
							this.handleFormReset();
						}}
						className="marginright-16 grey-button roboto-b-16"
					>
						<img src={resetIcon} alt="img" /> Reset
					</p>
					<button
						onClick={() => this.props.handleSelection(false)}
						className="cancel-btn roboto-b-16"
					>
						Cancel
					</button>
					<button
						className={
							this.state.searchForm.userId.value === '' &&
							this.state.searchForm.firstName.value === '' &&
							this.state.searchForm.lastName.value === '' &&
							this.state.searchForm.phoneNo.value === ''
								? 'disabled btnNxtPrev ml-2 roboto-b-16'
								: 'btnNxtPrev ml-2 roboto-b-16'
						}
						onClick={() => {
							this.props.handleSearch(this.state.searchForm);
						}}
					>
						Search
					</button>
				</div>
			</React.Fragment>
		);
	}
}
