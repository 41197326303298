import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import * as _ from 'lodash';
import {
	isObjectEmpty,
	validateMinOverMax,
	validateMaxOverMin,
	validateCSV
} from '../../../utils/helperFunction';
// import { productData } from './productData';
import { InformationModal } from '../../../MainContent/InformationModal';
import * as RestSvc from '../../../libraries/offer-services';
import * as urlTypes from '../../../libraries/offer-service-urls';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import { CSVReader } from 'react-papaparse';
import { modifiedValueFormatter } from './Audit/auditHelperFunction';
import ToolkitProvider from '../../../utils/reactBootstrapTable2Toolkit';

var itmDescObj = {};
var itmSrcBrandObj = {};

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
	<div className="btn-group" role="group">
		{options.map((option) => {
			const isSelect = currSizePerPage === `${option.page}`;
			return (
				<button
					key={option.text}
					type="button"
					onClick={() => onSizePerPageChange(option.page)}
					className={`btn ${isSelect ? 'btn-primary' : 'btn-secondary'}`}
				>
					{option.text}
				</button>
			);
		})}
	</div>
);

let options = {};

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
	let a = column.text;
	if (a != undefined) {
		a = a.split('$');
		return (
			<div>
				<div>
					{sortElement}
					{filterElement}
				</div>
				<div>
					<b> {a[0]} </b>
				</div>
				<div>
					<b> {a[1]} </b>
				</div>
			</div>
		);
	}
}

const eligibilityType = {
	SimplePromotion: 'simplePromotionEligibility'
};

const pricemodificationmedthodtype = {
	type1: 'AMOUNT_OFF',
	type2: 'APPORTIONED_AMOUNT_OFF',
	type3: 'PERCENTAGE_OFF',
	type4: 'APPORTIONED_PERCENTAGE_OFF',
	type5: 'MARKDOWN'
};

const ProductTableBoostrap = (props) => {
	// state and props
	const [products, setProducts] = useState([]);
	const [productID, setProductID] = useState('');
	const [columns, setColumns] = useState('');
	const [selectedProducts, setSelectedProducts] = useState([]);
	// const { SearchBar } = Search;
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState('');
	const [informationTitle] = useState('Information');

	// for table column creation
	useEffect(() => {
		const { pricemodificationmedthodcode } = props;
		const columnsSimplePromotion = [
			{
				dataField: 'TableSlNo',
				text: 'Serial No. $ ',
				headerFormatter: headerFormatter,
				headerAlign: 'center',
				sort: true,
				editable: false
			},
			{
				dataField: 'productID',
				text: 'Product ID',
				headerFormatter: headerFormatter,
				headerAlign: 'center',
				sort: true,
				headerClasses: 'hdrProductId',
				filter: textFilter()
			},
			{
				dataField: 'itemDescription',
				text: 'Description',
				headerFormatter: headerFormatter,
				headerAlign: 'center',
				sort: true,
				editable: false,
				headerClasses: 'hdrProductDesc',
				filter: textFilter()
			},
			{
				dataField: 'minimumPrice',
				text: 'Minimum Price $ Range (0.01-9999999.99)',
				sort: true,
				headerAlign: 'center',
				headerFormatter: headerFormatter,
				validator: (newValue, row, column) => {
					return validateMinOverMax(newValue, row, column);
				},
				formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
			},
			{
				dataField: 'maximumPrice',
				text: 'Maximum Price $ Range (0.01-9999999.99)',
				sort: true,
				headerFormatter: headerFormatter,
				headerAlign: 'center',
				validator: (newValue, row, column) => {
					return validateMaxOverMin(newValue, row, column);
				},
				formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
			},
			{
				dataField: 'sourceBrand',
				text: 'Source Brand',
				headerFormatter: headerFormatter,
				headerAlign: 'center',
				sort: true,
				editable: false,
				headerClasses: 'hdrProductDesc'
				// filter: textFilter()
			},

			{
				dataField:
					pricemodificationmedthodcode == pricemodificationmedthodtype.type1 ||
					pricemodificationmedthodcode == pricemodificationmedthodtype.type2
						? 'priceModificationAmount'
						: pricemodificationmedthodcode == pricemodificationmedthodtype.type3 ||
						  pricemodificationmedthodcode == pricemodificationmedthodtype.type4
						? 'priceModificationPercent'
						: 'newPriceAmount',
				text:
					pricemodificationmedthodcode == pricemodificationmedthodtype.type1 ||
					pricemodificationmedthodcode == pricemodificationmedthodtype.type2
						? 'Price Modification Amount $ Range (0.01-9999999.99)'
						: pricemodificationmedthodcode == pricemodificationmedthodtype.type3 ||
						  pricemodificationmedthodcode == pricemodificationmedthodtype.type4
						? 'Price Modification Percent $ Range (0.01-100)'
						: 'New Price Amount $ Range (0.01-9999999.99)',
				headerFormatter: headerFormatter,
				sort: true,
				validator: (newValue) => {
					const decimalRegex = /^\d+(\.\d{1,2})?$/;
					if (isNaN(newValue)) {
						return {
							valid: false,
							message: 'Value should be Numeric'
						};
					}
					if (
						pricemodificationmedthodcode === pricemodificationmedthodtype.type3 ||
						pricemodificationmedthodcode === pricemodificationmedthodtype.type4
					) {
						if (!decimalRegex.test(newValue)) {
							return {
								valid: false,
								message: 'Only two decimal places allowed.'
							};
						} else if (newValue > 100 || newValue < 0) {
							return {
								valid: false,
								message: 'Value should be (0.01 - 100).'
							};
						}
					} else if (
						pricemodificationmedthodcode === pricemodificationmedthodtype.type1 ||
						pricemodificationmedthodcode === pricemodificationmedthodtype.type2 ||
						pricemodificationmedthodcode === pricemodificationmedthodtype.type5
					) {
						if (!decimalRegex.test(newValue)) {
							return {
								valid: false,
								message: 'Only two decimal places allowed.'
							};
						} else if (newValue > 9999999.99 || newValue < 0) {
							return {
								valid: false,
								message: 'Value should be (0.01 - 9999999.99).'
							};
						}
					}
					return true;
				},
				formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
			}
		];
		setColumns(columnsSimplePromotion);
		setProducts([]);
	}, [props.pricemodificationmedthodcode]);
	//useEffects
	useEffect(() => {
		/* let prdcts = props.data1.map((val, index, arrayobj) => {
            return { ...val, TableSlNo: index+1 };
        })
        
        setProducts(prdcts) */
		getItemDescription(props.data1);
		return () => {
			setProducts([]);
		};
	}, []);

	useEffect(() => {
		let prdcts = JSON.parse(JSON.stringify(products));

		prdcts = prdcts.map((val) => {
			delete val['TableSlNo'];
			delete val['itemDescription'];
			return val;
		});
		props.onProductTableChange(prdcts);

		let confgrdDataToViewTableRecords = JSON.parse(
			sessionStorage.getItem('configuredDataToViewTableRecords')
		).recordGroupForTable;

		let sizePerPageLst = confgrdDataToViewTableRecords.map((val) => {
			return { text: val, value: val };
		});
		sizePerPageLst.push({ text: 'All', value: products.length });

		options = {
			sizePerPageRenderer,
			sizePerPageList: sizePerPageLst
		};
	}, [products]);

	// event handlers
	const selectRow = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			//debugger
			let newSelectedProducts = [...selectedProducts];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row.productID);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.productID);
			}
			setSelectedProducts(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			let newSelectedProducts = [...selectedProducts];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.productID;
				});
			}
			setSelectedProducts(newSelectedProducts);
		}
	};

	const getItemDescription = async (prodcts) => {
		if (prodcts.length) {
			let flatPrdcts = prodcts.map((val) => {
				return val.productID;
			});

			const businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const dataToSend = {
				getItemDescriptionRequest: {
					id: flatPrdcts,
					businessUnitFunctionID: businessUnitFunctionID,
					idType: 'product'
				}
			};

			props.showLoadingButton(true);

			const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
			RestSvc.postData(
				urlTypes.offers_maintenance.getItemDescriptionUrl,
				dataToSend,
				svPromUrl
			)
				.then((response) => {
					props.showLoadingButton(false);
					const { itemDescription } = response.data.getItemDescriptionResponse;

					itemDescription &&
						itemDescription.map((val) => {
							itmDescObj[val.itemID] = val.description;
						});
					itemDescription &&
						itemDescription.map((val) => {
							itmSrcBrandObj[val.itemID] = val.sourceBrand;
						});
					let prdctsWithDesc = prodcts.map((val, index) => {
						return {
							...val,
							TableSlNo: index + 1,
							itemDescription: itmDescObj[val.productID],
							sourceBrand: itmSrcBrandObj[val.productID]
						};
					});
					setProducts(prdctsWithDesc);
				})
				.catch((error) => {
					props.showLoadingButton(false);
					console.error(error);
				});
		} else {
			setProducts([]);
		}
	};

	const addProduct = async () => {
		let errorMsgs = [];
		if (productID) {
			const { eligibility, pricemodificationmedthodcode } = props;
			const businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const serviceInput = {
				validateItemRequest: {
					id: [productID],
					businessUnitFunctionID: businessUnitFunctionID,
					idType: 'product'
				}
			};

			if (eligibility === eligibilityType.SimplePromotion) {
				if (!products.find((x) => x.productID == productID)) {
					const serviceResponse = await getValidProductId(serviceInput);
					const { invalidId, itemDescription } =
						serviceResponse.data.validateItemResponse;
					if (invalidId.length == 0) {
						itemDescription &&
							itemDescription.map((val) => {
								itmDescObj[val.itemID] = val.description;
							});
						itemDescription &&
							itemDescription.map((val) => {
								itmSrcBrandObj[val.itemID] = val.sourceBrand;
							});
						if (
							pricemodificationmedthodcode == pricemodificationmedthodtype.type1 ||
							pricemodificationmedthodcode == pricemodificationmedthodtype.type2
						) {
							setProducts([
								...products,
								{
									TableSlNo: products.length + 1,
									itemDescription: itmDescObj[productID],
									productID: productID,
									maximumPrice: props.defaultValueForAdd.maximumPrice,
									minimumPrice: props.defaultValueForAdd.minimumPrice,
									trigger: true,
									getReward: true,
									priceModificationAmount: 1,
									sourceBrand: itmSrcBrandObj[productID]
									/*  if (pricemodificationmedthodcode == pricemodificationmedthodtype.type1 || pricemodificationmedthodcode == pricemodificationmedthodtype.type2)
                           {
                            priceModificationAmount:1
                           }
                           else if(pricemodificationmedthodcode == pricemodificationmedthodtype.type3 || pricemodificationmedthodcode == pricemodificationmedthodtype.type4){
                            priceModificationPercent:0.01 
                           }
                           else{
                            NewPriceAmount:1
                           } */
								}
							]);
						} else if (
							pricemodificationmedthodcode == pricemodificationmedthodtype.type3 ||
							pricemodificationmedthodcode == pricemodificationmedthodtype.type4
						) {
							setProducts([
								...products,
								{
									TableSlNo: products.length + 1,
									itemDescription: itmDescObj[productID],
									productID: productID,
									maximumPrice: props.defaultValueForAdd.maximumPrice,
									minimumPrice: props.defaultValueForAdd.minimumPrice,
									trigger: true,
									getReward: true,
									priceModificationPercent: 0.01,
									sourceBrand: itmSrcBrandObj[productID]
								}
							]);
						} else {
							setProducts([
								...products,
								{
									TableSlNo: products.length + 1,
									itemDescription: itmDescObj[productID],
									productID: productID,
									maximumPrice: props.defaultValueForAdd.maximumPrice,
									minimumPrice: props.defaultValueForAdd.minimumPrice,
									trigger: true,
									getReward: true,
									newPriceAmount: 1,
									sourceBrand: itmSrcBrandObj[productID]
								}
							]);
						}
					} else {
						errorMsgs.push(
							<>
								<b>Invalid Products</b>
								<p>{`${invalidId.join(',')}`}</p>
							</>
						);
					}
				} else {
					errorMsgs.push(
						<>
							<b>Duplicate Products</b>
							<p>{productID}</p>
						</>
					);
				}
			}

			setProductID('');
			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		}
	};

	const buttonRef = React.createRef();

	const handleOpenDialog = (e) => {
		// Note that the ref is set async, so it might be null at some point
		if (buttonRef.current) {
			buttonRef.current.open(e);
		}
	};

	const handleOnFileLoad = async (data) => {
		let errorMsgs = [];
		let csvUploadStatus = validateCSV(props.keys, data);

		if (csvUploadStatus == 'CORRECT') {
			let csvHeaders = data[0].data;
			let tmpData = [];

			for (let i = 1; i < data.length - 1; i++) {
				let tmpObj = {};

				for (let j = 0; j < csvHeaders.length; j++) {
					tmpObj[csvHeaders[j]] = data[i].data[j]
						? data[i].data[j].toString().trim()
						: data[i].data[j];
				}

				tmpData.push(tmpObj);
			}

			const { eligibility, pricemodificationmedthodcode } = props;
			let parsedData = tmpData;
			let duplicateValues;
			let Failedcnt = 0;
			let alreadyAddedProducts = [];

			let productFlatArray = [...parsedData];
			productFlatArray = productFlatArray.map((val) => {
				return val.productID;
			});
			let businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const serviceInput = {
				validateItemRequest: {
					id: productFlatArray,
					businessUnitFunctionID: businessUnitFunctionID,
					idType: 'product'
				}
			};

			const serviceResponse = await getValidProductId(serviceInput);

			const { invalidId, duplicateId, validId, itemDescription } =
				serviceResponse.data.validateItemResponse;

			itemDescription &&
				itemDescription.map((val) => {
					itmDescObj[val.itemID] = val.description;
				});
			itemDescription &&
				itemDescription.map((val) => {
					itmSrcBrandObj[val.itemID] = val.sourceBrand;
				});
			let tmpParsData = [];

			validId.map((val) => {
				for (let i = 0; i < parsedData.length; i++) {
					if (val == parsedData[i].productID) {
						tmpParsData.push(parsedData[i]);
					}
				}
			});

			parsedData = tmpParsData;

			if (eligibility === eligibilityType.MTMB) {
				props.group.map((g) => {
					g.multibuyProduct.map((item) => {
						alreadyAddedProducts.push(item);
					});
				});
			}
			if (eligibility === eligibilityType.MTMB) {
				duplicateValues = _.intersectionBy(alreadyAddedProducts, parsedData, 'productID');
				//  parsedData = parsedData.filter(val => !alreadyAddedProducts.includes((a)=>{a.productID==val.productID}));
				parsedData = _.reject(parsedData, function (e1) {
					return _.find(duplicateValues, function (e2) {
						return e1.productID == e2.productID;
					});
				});
			} else {
				duplicateValues = _.intersectionBy(products, parsedData, 'productID');
			}
			//debugger
			duplicateValues = duplicateValues.map((val) => {
				return val.productID;
			});
			if (duplicateValues.length > 0) {
				// alert('Duplicate product ID are removed ' + duplicateValues.join(', '))
				Failedcnt = Failedcnt + duplicateValues.length;
				errorMsgs.push(
					<>
						<b>Product id(s) already added:{duplicateValues.length}</b>
						<p>{`${duplicateValues.join(', ')}`}</p>
					</>
				);
			}
			/* let productFlatArray = [...parsedData]
            productFlatArray = productFlatArray.map(val => {
                return val.productID
            })
            let businessUnitFunctionID = JSON.parse(sessionStorage.getItem("taggedBrandToLoggedInUser")).businessUnitGroupFunctionID
            const serviceInput = {
                "validateItemRequest": {
                    "id": productFlatArray,
                    "businessUnitFunctionID": businessUnitFunctionID,
                    "idType": "product"
                }
            }
            //debugger
            // getValidProductId(serviceInput);
            const serviceResponse = await getValidProductId(serviceInput);
            
            const { invalidId } = serviceResponse.data.validateItemResponse
            const { duplicateId } = serviceResponse.data.validateItemResponse */
			Failedcnt = productFlatArray.length - validId.length;
			if (duplicateId && duplicateId.length > 0) {
				// duplicateValues=duplicateValues.concat(duplicateId);
				// duplicateValues= duplicateValues.filter((it, i, ar) => ar.indexOf(it) === i);
				//Failedcnt=Failedcnt+duplicateId.length;
				errorMsgs.push(
					<>
						<b>Duplicate product id(s) :{duplicateId.length}</b>
						<p>{`${duplicateId.join(', ')}`}</p>
					</>
				);
			}

			if (invalidId.length > 0) {
				//Failedcnt=Failedcnt+invalidId.length;
				// errorMsgs = `${errorMsgs} Products  ${invalidId.join(', ')} are invalid. <br>`
				errorMsgs.push(
					<>
						<b>Invalid product id(s) : {invalidId.length}</b>
						<p>{`${invalidId.join(', ')}`}</p>
					</>
				);
			}

			// parsedData = parsedData.filter(val => {
			//     return !val.productID.includes(invalidId)
			// })
			parsedData = parsedData.filter((val) => !invalidId.includes(val.productID));

			//  validation for the data table
			const decimalRegex = /^\d+(\.\d{1,2})?$/;
			//validation for the benifitvalue
			if (
				pricemodificationmedthodcode == pricemodificationmedthodtype.type3 ||
				pricemodificationmedthodcode == pricemodificationmedthodtype.type4
			) {
				let removedRow = parsedData.filter((val) => {
					return val.benefitValue > 100 || val.benefitValue < 0;
				});
				removedRow = removedRow.map((val) => {
					return val.productID;
				});
				if (removedRow.length > 0) {
					Failedcnt = Failedcnt + removedRow.length;
					// alert('Benefit amount is beyond threshold. product ID(s) ' + removedRow.join(', ') + ' removed')
					// errorMsgs = `${errorMsgs} Benefit amount is beyond threshold. Product ID(s) ${removedRow.join(', ')} removed.`
					errorMsgs.push(
						<>
							<b>Benefit percetage is beyond range: {removedRow.length}</b>
							<p>{`Product ID(s) ${removedRow.join(', ')} removed. `}</p>
						</>
					);
				}

				parsedData = parsedData.filter((val) => {
					return val.benefitValue <= 100 && val.benefitValue > 0;
				});

				let invalidRow = parsedData.filter((val) => {
					return !decimalRegex.test(val.benefitValue);
				});
				invalidRow = invalidRow.map((val) => {
					return val.productID;
				});
				if (invalidRow.length > 0) {
					Failedcnt = Failedcnt + invalidRow.length;
					errorMsgs.push(
						<>
							<b>
								Benefit percetage is only two decimal places allowed. :
								{invalidRow.length}
							</b>
							<p>{`Product ID(s) ${invalidRow.join(', ')} removed. `}</p>
						</>
					);
				}
				parsedData = parsedData.filter((val) => {
					return decimalRegex.test(val.benefitValue);
				});
			} else if (
				pricemodificationmedthodcode == pricemodificationmedthodtype.type1 ||
				pricemodificationmedthodcode == pricemodificationmedthodtype.type2 ||
				pricemodificationmedthodcode == pricemodificationmedthodtype.type5
			) {
				let removedRow = parsedData.filter((val) => {
					return val.benefitValue > 9999999.99 || val.benefitValue < 0;
				});
				removedRow = removedRow.map((val) => {
					return val.productID;
				});
				if (removedRow.length > 0) {
					Failedcnt = Failedcnt + removedRow.length;
					// alert('Benefit amount is beyond threshold. product ID(s) ' + removedRow.join(', ') + ' removed')
					// errorMsgs = `${errorMsgs} Benefit amount is beyond threshold. Product ID(s)  ${removedRow.join(', ')} removed.`
					errorMsgs.push(
						<>
							<b>Benefit amount is beyond range :{removedRow.length}</b>
							<p>{`Product ID(s) ${removedRow.join(', ')} removed. `}</p>
						</>
					);
				}
				//debugger
				parsedData = parsedData.filter((val) => {
					return val.benefitValue <= 9999999.99 && val.benefitValue > 0;
				});

				let invalidRow = parsedData.filter((val) => {
					return !decimalRegex.test(val.benefitValue);
				});
				invalidRow = invalidRow.map((val) => {
					return val.productID;
				});
				if (invalidRow.length > 0) {
					Failedcnt = Failedcnt + invalidRow.length;
					errorMsgs.push(
						<>
							<b>
								Benefit percetage is only two decimal places allowed.:
								{invalidRow.length}
							</b>
							<p>{`Product ID(s) ${invalidRow.join(', ')} removed. `}</p>
						</>
					);
				}
				parsedData = parsedData.filter((val) => {
					return decimalRegex.test(val.benefitValue);
				});
			}

			//
			if (parsedData.length >= 0) {
				let mergedData = [...products, ...parsedData];
				mergedData = _.uniqBy(mergedData, function (e) {
					return e.productID;
				});

				//

				mergedData = mergedData.map((val) => {
					/*  if (val.trigger == '' || val.getReward == '') {
                            return val.trigger == '' ? { ...val, trigger: 'N' } : { ...val, getReward: 'N' }
                        }
                        return val */

					if (
						pricemodificationmedthodcode == pricemodificationmedthodtype.type1 ||
						pricemodificationmedthodcode == pricemodificationmedthodtype.type2
					) {
						return {
							trigger: true,
							getReward: true,
							maximumPrice: val.maximumPrice,
							minimumPrice: val.minimumPrice,
							productID: val.productID,
							priceModificationAmount:
								val.benefitValue != undefined
									? val.benefitValue
									: val.priceModificationAmount
						};
					} else if (
						pricemodificationmedthodcode == pricemodificationmedthodtype.type3 ||
						pricemodificationmedthodcode == pricemodificationmedthodtype.type4
					) {
						return {
							trigger: true,
							getReward: true,
							maximumPrice: val.maximumPrice,
							minimumPrice: val.minimumPrice,
							productID: val.productID,
							priceModificationPercent:
								val.benefitValue != undefined
									? val.benefitValue
									: val.priceModificationPercent
						};
					} else {
						return {
							trigger: true,
							getReward: true,
							maximumPrice: val.maximumPrice,
							minimumPrice: val.minimumPrice,
							productID: val.productID,
							newPriceAmount:
								val.benefitValue != undefined
									? val.benefitValue
									: val.newPriceAmount
						};
					}
				});

				errorMsgs.push(
					<>
						<b>Product id(s) uploaded successfully: </b>
						<p>{` ${tmpData.length - Failedcnt}`}</p>
						<b>Product id(s) failed to upload: </b>
						<p>{` ${Failedcnt}`}</p>
					</>
				);

				mergedData = mergedData.map((val, index) => {
					return {
						...val,
						TableSlNo: index + 1,
						itemDescription: itmDescObj[val.productID],
						sourceBrand: itmSrcBrandObj[val.productID]
					};
				});

				setProducts(mergedData);
			}
			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		} else {
			errorMsgs.push(
				<>
					<b>The uploaded CSV format is incorrect. The colum headers must be: </b>
					<p>{csvUploadStatus}</p>
				</>
			);

			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		}
	};

	const handleOnError = () => {};

	const handleOnRemoveFile = () => {};

	const getValidProductId = (dataToSend) => {
		props.showLoadingButton(true);

		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		return RestSvc.postData('/rest/offers/configurator/validateItems', dataToSend, svPromUrl)
			.then((response) => {
				props.showLoadingButton(false);
				return response;
				//debugger
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const deleteSelected = () => {
		let newProducts = [...products];

		//debugger
		if (selectedProducts.length > 0) {
			newProducts = newProducts.filter((val) => {
				return !selectedProducts.includes(val.productID);
			});

			newProducts = newProducts.map((val, index) => {
				return { ...val, TableSlNo: index + 1 };
			});

			setProducts(newProducts);
			setSelectedProducts([]);
		}
	};

	const handleTableChange = (oldValue, newValue, row) => {
		let newProducts = [...products];
		newProducts.map((val) => {
			if (val.productID == row.productID) {
				return row;
			}
			return val;
		});
		setProducts(newProducts);

		//debugger
	};

	const FuncEnter = (e) => {
		if (e.keyCode == 13) {
			e.preventDefault();
			addProduct();
		}
	};

	//render
	return (
		<div>
			{/* <div>
                <input type='text' value={productID} onChange={handleProductIDChange} />
                <button onClick={addProduct}>+</button>
                <CsvParse
                    keys={props.keys}
                    onDataUploaded={(data) => handleCSVData(data)}
                    onError={() => { }}
                    render={onChange => <input type="file" onChange={onChange} />}
                />
                <button onClick={deleteSelected}>X</button>
            </div> */}
			<div className="justify-content-flex-start align-items-center mb-3">
				<div className="container">
					<div className="rowContainer hideForAudit">
						<div className="customLabelContainer">
							<label htmlFor="forprogramID" className="col	col-form-label">
								Product ID
							</label>
						</div>
						<div className="customInputContainer">
							<div className="row">
								<input
									type="text"
									className="form-control"
									onKeyDown={(e) => FuncEnter(e)}
									onChange={(e) => setProductID(e.target.value)}
									value={productID}
								/>
								<button
									type="button"
									className="addPlusBtn ml-2"
									onClick={addProduct}
								></button>
								{/* <CsvParse
                                    keys={props.keys}
                                    onDataUploaded={(data) => handleCSVData(data)}
                                    onError={() => { }}
                                    render={onChange => <>

                                        <input
                                            type="file"
                                            // id="idLoadProductFromFile"
                                            id={`idLoadProductFromFile${props.i?props.i:''}${props.j?props.j:''}`}
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                    onChange(e)
                                    e.target.value = ''
                                }}
                                        />
                                        <label className={`mb-0 uploadBtn ml-1 ${props.eligibility == 'MultiByProduct'? 'd-none' :''}`} 
                                            for={`idLoadProductFromFile${props.i?props.i:''}${props.j?props.j:''}`}> </label>
                                    </>}
                                /> */}
								<CSVReader
									ref={buttonRef}
									onFileLoad={handleOnFileLoad}
									onError={handleOnError}
									noClick
									noDrag
									onRemoveFile={handleOnRemoveFile}
								>
									{() => (
										<aside
											style={{
												display: 'flex',
												flexDirection: 'row'
											}}
										>
											<label
												className={`mb-0 uploadBtn ml-1 ${
													props.eligibility == 'MultiByProduct'
														? 'd-none'
														: ''
												}`}
												onClick={handleOpenDialog}
											></label>
											{/* <button
                                                type='button'
                                                onClick={handleOpenDialog}
                                                style={{
                                                    borderRadius: 0,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                    width: '40%',
                                                    paddingLeft: 0,
                                                    paddingRight: 0
                                                }}
                                            >
                                                Browe file
                                            </button>
                                            <div
                                                style={{
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    borderColor: '#ccc',
                                                    height: 45,
                                                    lineHeight: 2.5,
                                                    marginTop: 5,
                                                    marginBottom: 5,
                                                    paddingLeft: 13,
                                                    paddingTop: 3,
                                                    width: '60%'
                                                }}
                                            >
                                                {file && file.name}
                                            </div>
                                            <button
                                                style={{
                                                    borderRadius: 0,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                    paddingLeft: 20,
                                                    paddingRight: 20
                                                }}
                                                onClick={handleRemoveFile}
                                            >
                                                Remove
                                            </button> */}
										</aside>
									)}
								</CSVReader>
								<button
									type="button"
									className="addDeleteBtn ml-1"
									onClick={deleteSelected}
								></button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{products.length > 0 && !isObjectEmpty(columns) && (
				<ToolkitProvider keyField="productID" data={products} columns={columns} search>
					{(props) => (
						<>
							{/* <SearchBar className="text-left" {...props.searchProps} /> */}
							<div className="pt-3 pb-4 pl-2 border-1-lightgray">
								<b>Filter by:</b>
							</div>
							<BootstrapTable
								// onTableChange={onTableChange}
								{...props.baseProps}
								keyField="productID"
								data={products}
								columns={columns}
								cellEdit={cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: handleTableChange
								})}
								pagination={paginationFactory(options)}
								filter={filterFactory()}
								selectRow={selectRow}
							/>
						</>
					)}
				</ToolkitProvider>
			)}
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTableBoostrap);
