import React, { useEffect } from 'react';

import { useField, useFormikContext } from 'formik';

import CustomInputText from '../customfields/CustomInputText';
import CustomSelect from '../customfields/CustomSelect';

function ConditionalRedering(props) {
	const [field] = useField(props);

	const localConst = {
		eligibilitySettings: 'Eligibility Settings',
		thresholdCode: 'Threshold Type Code',
		triggerCombinationCode: 'Trigger Combination Code',
		subTriggerCombinationCode:
			'Determines the combination code to be used between the trigger items for a promotion',
		thresholdQuantity: 'Threshold Quantity',
		thresholdAmount: 'Threshold Amount',
		rewardCombinationCode: 'Reward Combination Code',
		subRewardCombinationCode:
			'Determines the combination code to be used between the reward item for a promotion',
		rewardQuantity: 'Reward Quantity',
		benefitSettings: 'Benefit Settings',
		ProductSetting: 'Product Settings',
		quantityLimit: 'Quantity Limit',
		maximumThresholdAmount: 'Maximum Threshold Amount',
		maximumThresholdQuantity: 'Maximum Threshold Quantity'
	};

	const fieldvaltxn = field.value.totalPurchaseMarketBasedPriceDerivationRuleEligibiity;

	const rewardCombinationDropdown = {
		operator: [
			{
				value: '',
				name: 'Select one'
			},
			{
				value: '<',
				name: '<'
			},
			{
				value: '>',
				name: '>'
			},
			{
				value: '<=',
				name: '<='
			},
			{
				value: '>=',
				name: '>='
			},
			{
				value: '=',
				name: '='
			},
			{
				value: 'BETWEEN',
				name: 'BETWEEN'
			}
		]
	};

	const getThresholdDropdown = (dropdownOptions) => {
		return dropdownOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};
	useEffect(() => {
		if (!fieldvaltxn.groupRewardQuantity) {
			fieldvaltxn.groupRewardQuantity = undefined;
		}

		if (
			fieldvaltxn.thresholdTypeCode == 'BASKET_INTERVAL_QTY_THRESHOLD' ||
			fieldvaltxn.thresholdTypeCode == 'BASKET_QTY_THRESHOLD'
		) {
			if (!fieldvaltxn.thresholdQuantity) {
				fieldvaltxn.thresholdQuantity = 1;
			}
			fieldvaltxn.quantityLimit = undefined;

			fieldvaltxn.maximumThresholdAmount = undefined;
			fieldvaltxn.thresholdAmount = undefined;
		}
		if (fieldvaltxn.thresholdTypeCode == 'BASKET_LIMIT_QTY_THRESHOLD') {
			if (!fieldvaltxn.thresholdQuantity) {
				fieldvaltxn.thresholdQuantity = 1;
			}
			// fieldvaltxn.quantityLimit = undefined;
			fieldvaltxn.maximumThresholdQuantity = undefined;
			fieldvaltxn.maximumThresholdAmount = undefined;
		}

		/* if (fieldvaltxn.thresholdTypeCode == "GROUP_RANGE_QTY_THRESHOLD"
    ) {
      if(!fieldvaltxn.groupThresholdQuantity){
        fieldvaltxn.groupThresholdQuantity = 1;
      }
      fieldvaltxn.quantityLimit = undefined;
      //fieldvaltxn.groupMaximumThresholdQuantity = undefined;
      fieldvaltxn.groupMaximumThresholdAmount = undefined;
    }*/
		if (
			fieldvaltxn.thresholdTypeCode == 'BASKET_INTERVAL_VALUE_THRESHOLD' ||
			fieldvaltxn.thresholdTypeCode == 'BASKET_VALUE_THRESHOLD'
		) {
			if (!fieldvaltxn.thresholdAmount) {
				fieldvaltxn.thresholdAmount = 0.01;
			}
			fieldvaltxn.quantityLimit = undefined;
			fieldvaltxn.maximumThresholdQuantity = undefined;
			fieldvaltxn.maximumThresholdAmount = undefined;
			fieldvaltxn.thresholdQuantity = undefined;
		}
		if (fieldvaltxn.thresholdTypeCode == 'BASKET_RANGE_VALUE_THRESHOLD') {
			if (!fieldvaltxn.thresholdAmount) {
				fieldvaltxn.thresholdAmount = 0.01;
			}
			fieldvaltxn.quantityLimit = undefined;
			fieldvaltxn.maximumThresholdQuantity = undefined;
			fieldvaltxn.thresholdQuantity = undefined; // ES-287644
			//fieldvaltxn.maximumThresholdAmount = undefined;
		}
		if (fieldvaltxn.thresholdTypeCode == 'BASKET_LIMIT_QTY_THRESHOLD') {
			if (!fieldvaltxn.quantityLimit) {
				fieldvaltxn.quantityLimit = 1;
			}
		}
		/*if (
      fieldvaltxn.thresholdTypeCode == "GROUP_RANGE_QTY_THRESHOLD"
    ) {
      if(!fieldvaltxn.groupMaximumThresholdQuantity){
        fieldvaltxn.groupMaximumThresholdQuantity = 999999;
      }
      
    }*/
		if (fieldvaltxn.thresholdTypeCode == 'BASKET_RANGE_VALUE_THRESHOLD') {
			if (!fieldvaltxn.maximumThresholdAmount) {
				fieldvaltxn.maximumThresholdAmount = 999999.99;
			}
		}
	}, [fieldvaltxn.thresholdTypeCode]);

	/* useEffect(() => {
    
   // fieldvaltxn.groupTriggerCombinationCode = "OR";
   // fieldvaltxn.groupRewardCombinationCode = "OR";
    fieldvaltxn.groupRewardQuantity = undefined;
    if (
      fieldvaltxn.thresholdTypeCode == "BASKET_INTERVAL_QTY_THRESHOLD" ||
      fieldvaltxn.thresholdTypeCode == "BASKET_LIMIT_QTY_THRESHOLD" ||
      fieldvaltxn.thresholdTypeCode == "BASKET_QTY_THRESHOLD"
   
    ) {
      if(!fieldvaltxn.thresholdQuantity){
        fieldvaltxn.thresholdQuantity = 1;
      }
      fieldvaltxn.quantityLimit = undefined;
     // fieldvaltxn.maximumThresholdQuantity = undefined;
      fieldvaltxn.maximumThresholdAmount = undefined;
    }
    if (
      fieldvaltxn.thresholdTypeCode == "BASKET_VALUE_THRESHOLD" ||
      fieldvaltxn.thresholdTypeCode == "BASKET_INTERVAL_VALUE_THRESHOLD" ||
      fieldvaltxn.thresholdTypeCode == "BASKET_RANGE_VALUE_THRESHOLD"
    ) {
      if(!fieldvaltxn.thresholdAmount){
        fieldvaltxn.thresholdAmount = 0.01;
      }
      fieldvaltxn.quantityLimit = undefined;
      fieldvaltxn.maximumThresholdQuantity = undefined;
      fieldvaltxn.maximumThresholdAmount = undefined;
    }
    if (fieldvaltxn.thresholdTypeCode == "BASKET_LIMIT_QTY_THRESHOLD") {
      if(!fieldvaltxn.quantityLimit){
        fieldvaltxn.quantityLimit = 1;
      }
    }
    
    if (fieldvaltxn.thresholdTypeCode == "BASKET_RANGE_VALUE_THRESHOLD") {
      if(!fieldvaltxn.maximumThresholdAmount){
        fieldvaltxn.maximumThresholdAmount = 999999.99;
      }
    }
  }, [fieldvaltxn.thresholdTypeCode]);

  useEffect(() => {
    if(!fieldvaltxn.transactionRewardQuantity){
      fieldvaltxn.transactionRewardProduct = [];
    }
  }, [fieldvaltxn.transactionRewardQuantity]); */
	const { setFieldValue } = useFormikContext();

	useEffect(() => {
		if (!fieldvaltxn.transactionRewardQuantity) {
			fieldvaltxn.transactionRewardProduct = [];
			props.resetProductTable([]);
			setFieldValue('', '');
		}
	}, [fieldvaltxn.transactionRewardQuantity]);

	const fieldCR = fieldvaltxn;
	//newly added
	useEffect(() => {
		if (
			fieldCR.triggerOperator == '<' ||
			fieldCR.triggerOperator == '>' ||
			fieldCR.triggerOperator == '<=' ||
			fieldCR.triggerOperator == '>=' ||
			fieldCR.triggerOperator == '='
		) {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
		} else if (fieldCR.triggerOperator == 'BETWEEN') {
			fieldCR.triggerOperatorValue = undefined;
		} else {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
			fieldCR.triggerOperatorValue = undefined;
		}
	}, [fieldCR.triggerOperator]);

	useEffect(() => {
		if (
			fieldCR.rewardOperator == '<' ||
			fieldCR.rewardOperator == '>' ||
			fieldCR.rewardOperator == '<=' ||
			fieldCR.rewardOperator == '>=' ||
			fieldCR.rewardOperator == '='
		) {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
		} else if (fieldCR.rewardOperator == 'BETWEEN') {
			fieldCR.rewardOperatorValue = undefined;
		} else {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
			fieldCR.rewardOperatorValue = undefined;
		}
	}, [fieldCR.rewardOperator]);

	//html
	return (
		<div className="purchaseContainer">
			<div className="purchaseRow">
				Purchase Conditions
				{fieldvaltxn.thresholdTypeCode == 'BASKET_INTERVAL_QTY_THRESHOLD' ||
				fieldvaltxn.thresholdTypeCode == 'BASKET_QTY_THRESHOLD' ||
				fieldvaltxn.thresholdTypeCode == 'BASKET_LIMIT_QTY_THRESHOLD' ? (
					<CustomInputText
						name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.thresholdQuantity"
						required={true}
						label={localConst.thresholdQuantity}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				{fieldvaltxn.thresholdTypeCode == 'BASKET_INTERVAL_VALUE_THRESHOLD' ||
				fieldvaltxn.thresholdTypeCode == 'BASKET_VALUE_THRESHOLD' ||
				fieldvaltxn.thresholdTypeCode == 'BASKET_RANGE_VALUE_THRESHOLD' ? (
					<CustomInputText
						name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.thresholdAmount"
						required={true}
						label={localConst.thresholdAmount}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				{fieldvaltxn.thresholdTypeCode == 'BASKET_RANGE_VALUE_THRESHOLD' ? (
					<CustomInputText
						name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.maximumThresholdAmount"
						required={true}
						label={localConst.maximumThresholdAmount}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				{fieldvaltxn.thresholdTypeCode == 'BASKET_LIMIT_QTY_THRESHOLD' ? (
					<CustomInputText
						name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.quantityLimit"
						required={true}
						label={localConst.quantityLimit}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				<CustomSelect
					name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.triggerOperator"
					label="Price Range Operator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{fieldCR.triggerOperator == '<' ||
				fieldCR.triggerOperator == '>' ||
				fieldCR.triggerOperator == '<=' ||
				fieldCR.triggerOperator == '>=' ||
				fieldCR.triggerOperator == '=' ? (
					<CustomInputText
						name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.triggerOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{fieldCR.triggerOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.triggerOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.triggerOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>

			<div className="purchaseRow">
				Reward Conditions
				<CustomInputText
					name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.transactionRewardQuantity"
					required={false}
					label={localConst.rewardQuantity}
					smallText=""
					disabled={false}
				/>
				<CustomSelect
					name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.rewardOperator"
					label="Price Range Operator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{fieldCR.rewardOperator == '<' ||
				fieldCR.rewardOperator == '>' ||
				fieldCR.rewardOperator == '<=' ||
				fieldCR.rewardOperator == '>=' ||
				fieldCR.rewardOperator == '=' ? (
					<CustomInputText
						name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.rewardOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{fieldCR.rewardOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.rewardOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.rewardOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>
		</div>
	);
}
export default ConditionalRedering;
