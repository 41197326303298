import React, { useEffect, useState } from 'react';
import ExclusionTableBootstrap from '../ExclusionTableBootstrap';

const AuditExclusionSHTable = ({ ...props }) => {
	// const [productTableData, setProductTableData] = useState([]);
	// const [sHTableData, setSHTableData] = useState([]);
	const keys = ['productID'];
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	// let productsAdded=[];
	const getAdded = (products, products1) => {
		var added = [];
		for (var i = 0; i < products.length; i++) {
			var id = products[i].merchandizeGroupId[0];
			var flag = false;
			for (var j = 0; j < products1.length; j++) {
				var id2 = products1[j].merchandizeGroupId[0];
				if (id === id2) {
					flag = true;
					// products1[j].contained = true;
				}
			}
			if (!flag) {
				added.push(products[i]);
			}
		}
		return added;
	};

	/* const getReplaced = (newProds, oldProds, added, removed) => {
        let newProdsModified = [], oldProdsModifed = [], replaced = [];
        newProdsModified = getAdded(newProds, added);//Remove added from new json
        oldProdsModifed = getAdded(oldProds, removed);//Remove deleted from old json
        
        
        newProdsModified.map((item, i) => {
            if (JSON.stringify(newProdsModified[i]) != JSON.stringify(oldProdsModifed[i])) {
                if (newProdsModified[i].minimumPrice != oldProdsModifed[i].minimumPrice) {
                    // newProdsModified[i].oldminimumPrice=oldProdsModifed[i].minimumPrice
                    newProdsModified[i].minimumPrice = 'old value:' + oldProdsModifed[i].minimumPrice + ', new value:' + newProdsModified[i].minimumPrice
                }
                if (newProdsModified[i].maximumPrice != oldProdsModifed[i].maximumPrice) {
                    // newProdsModified[i].oldmaximumPrice=oldProdsModifed[i].maximumPrice
                    newProdsModified[i].maximumPrice = 'old value:' + oldProdsModifed[i].maximumPrice + ', new value:' + newProdsModified[i].maximumPrice
                }
                if (newProdsModified[i].trigger != oldProdsModifed[i].trigger) {
                    // newProdsModified[i].oldtrigger=oldProdsModifed[i].trigger
                    newProdsModified[i].trigger = 'old value:' + oldProdsModifed[i].trigger + ', new value:' + newProdsModified[i].trigger
                }
                if (newProdsModified[i].getReward != oldProdsModifed[i].getReward) {
                    // newProdsModified[i].oldgetReward=oldProdsModifed[i].getReward
                    newProdsModified[i].getReward = 'old value:' + oldProdsModifed[i].getReward + ', new value:' + newProdsModified[i].getReward
                }
                replaced.push(newProdsModified[i])
            }
        })
        return replaced
    } */
	const [productsAdded, setProductsAdded] = useState([]);
	const [productsRemoved, setProductsRemoved] = useState([]);
	// const [productsReplaced, setProductsReplaced] = useState([]);

	useEffect(() => {
		if (isAuditFlw) {
			//debugger
			let OldProds = props.oldJson;
			let NewProds = props.newJson.promotionPolicy.priceDerivationRuleEligibility[
				props.valueEligbility
			]?.exclusions?.merchandizeHierarchyGroup
				? props.newJson.promotionPolicy.priceDerivationRuleEligibility[
						props.valueEligbility
				  ]?.exclusions?.merchandizeHierarchyGroup
				: [];
			let tempRemoved = getAdded(OldProds, NewProds);
			setProductsRemoved(tempRemoved);
			let tempAdded = getAdded(NewProds, OldProds);
			setProductsAdded(tempAdded);
			/*  let tempReplaced = getReplaced(NewProds, OldProds, tempAdded, tempRemoved)
            setProductsReplaced(tempReplaced) */
		}
		return () => {};
	}, []);

	return (
		<div>
			{productsAdded.length + productsRemoved.length > 0 ? (
				<div className="font-weight-bold pl-3">Selling Hierarchy</div>
			) : undefined}
			<div
				className={
					productsAdded.length == 0 && productsRemoved.length == 0
						? 'd-none'
						: 'box-blackout-period lightBlue container pl-2'
				}
			>
				{productsAdded.length > 0 ? (
					<div className="issuerOuterBox mb-3">
						<div>
							<label className="font-weight-bold">
								Added ({productsAdded.length})
							</label>
						</div>
						<div className="w-auto dataAdded">
							<ExclusionTableBootstrap
								dataProduct={[]}
								/*  SelectIDProp = {SelectID} */
								dataMer={productsAdded}
								keys={keys}
								defaultValueForAdd={{
									maximumPrice: '999999.99',
									minimumPrice: '0.01',
									trigger: true,
									getReward: true
								}}
								onProductTableChange={(val, val2) => {
									// setProductTableData(val);
									// setSHTableData(val2);
								}}
								eligibility={'MultiBuyGroup'}
								/* type={SelectID} */
							/>
						</div>
					</div>
				) : undefined}
				{productsRemoved.length > 0 ? (
					<div className="issuerOuterBox mb-3">
						<div>
							<label className="font-weight-bold">
								Removed ({productsRemoved.length})
							</label>
						</div>
						<div className="w-auto dataRemoved">
							<ExclusionTableBootstrap
								dataProduct={[]}
								/*  SelectIDProp = {SelectID} */
								dataMer={productsRemoved}
								keys={keys}
								defaultValueForAdd={{
									maximumPrice: '999999.99',
									minimumPrice: '0.01',
									trigger: true,
									getReward: true
								}}
								onProductTableChange={(val, val2) => {
									// setProductTableData(val);
									// setSHTableData(val2
								}}
								eligibility={'MultiBuyGroup'}
								/* type={SelectID} */
							/>
						</div>
					</div>
				) : undefined}
			</div>
		</div>
	);
};

export default AuditExclusionSHTable;
