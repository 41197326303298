import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './leftMenuNav.scss';

import NotificationLogo from '../../assets/images/notification.png';
import customerUploadIcon from '../../assets/images/couponUploadNew.png';
import customerUploadHover from '../../assets/images/couponUploadHover.png';
import TagIcon from '../../assets/images/tag.png';
import LevelsIcon from '../../assets/images/levels.png';
import LevelsHover from '../../assets/images/levels-hover.png';
import UserIcon from '../../assets/images/Icon_metro_user.png';
import UserIconHover from '../../assets/images/user-hover.png';
import couponManagement from '../../assets/images/couponMainIcon.png';
import couponManagementHover from '../../assets/images/couponManagement.png';
import TagIconHover from '../../assets/images/tag-hover.png';
import AuditHover from '../../assets/images/audit_hover.png';
import Audit from '../../assets/images/audit.png';
import binIconHover from '../../assets/images/binIconHover.png';
import binIcon from '../../assets/images/binIconNormal.png';
import dashboard from '../../assets/images/dashboard.png';
import dashboardHover from '../../assets/images/dashboard-hover.png';

const nav = [
	{
		resource: 'BO003',
		url: '/MainPage/offerconfigurator',
		navClass: 'tag',
		imgClass: 'tagImage',
		title: 'Offer Configurator',
		imgActive: TagIconHover,
		img: TagIcon
	},
	{
		resource: 'BO003',
		url: '/MainPage/levelmaintenance',
		navClass: 'levels',
		imgClass: 'levelImage',
		title: 'Level Management',
		imgActive: LevelsHover,
		img: LevelsIcon
	},
	{
		resource: 'BO003',
		url: '/MainPage/UserMaintenance',
		navClass: 'user',
		imgClass: 'userImage',
		title: 'User Management',
		imgActive: UserIconHover,
		img: UserIcon
	},
	{
		resource: 'BO003',
		url: '/MainPage/couponManagement',
		navClass: 'user',
		imgClass: 'couponImage',
		title: 'Coupon Management',
		imgActive: couponManagementHover,
		img: couponManagement
	},
	{
		resource: 'BO003',
		url: '/MainPage/segmentManagement',
		navClass: 'segment-nav',
		// imgClass: 'segmentImage',
		title: 'Segment',
		imgActive: dashboardHover,
		img: dashboard
	},
	{
		resource: 'OC010',
		url: '/MainPage/auditMaintenance',
		navClass: 'tag',
		imgClass: 'tagImage',
		title: 'Audit Management',
		imgActive: AuditHover,
		img: Audit
	},
	{
		resource: 'BO003',
		url: '/MainPage/binRangeManagement',
		navClass: 'user',
		imgClass: 'couponImage',
		title: 'Bin Setup',
		imgActive: binIconHover,
		img: binIcon
	},
	{
		resource: 'BO003',
		url: '/MainPage/notificationManager',
		navClass: 'user',
		imgClass: 'couponImage',
		title: 'Notification Management',
		imgActive: NotificationLogo,
		img: NotificationLogo
	},
	{
		resource: 'BO003',
		url: '/MainPage/CustomerManager',
		navClass: 'user',
		imgClass: 'couponImage',
		title: 'Promotion Customer Mapping',
		imgActive: customerUploadHover,
		img: customerUploadIcon
	}
];

const LeftMenuNav = ({ disableItem, assignedResources, location, isCustomerService }) => {
	if (isCustomerService) {
		nav[0] = {
			...nav[0],
			url: '/MainPage/offerconfiguratorcs',
			title: 'Offer Configurator Customer Servicer'
		};
	}

	return (
		<div className="left-menu">
			<div className={`sidebar ${disableItem ? 'disabled' : ''}`}>
				{nav.map(({ resource, url, navClass, imgClass, title, imgActive, img }, ind) => {
					let isDisable =
						disableItem || !(assignedResources.indexOf(resource) === -1)
							? 'disabled'
							: '';
					if (title === 'Audit Management') {
						isDisable =
							disableItem || !(assignedResources.indexOf(resource) !== -1)
								? 'disabled'
								: '';
					}
					return (
						<NavLink
							key={ind}
							className={`${navClass} ${isDisable}`}
							to={disableItem ? '#' : url}
						>
							<img
								// className={imgClass}
								title={title}
								alt={title}
								src={location.pathname !== url ? img : imgActive}
							/>
						</NavLink>
					);
				})}
			</div>
		</div>
	);
};

LeftMenuNav.propTypes = {
	disableItem: PropTypes.bool,
	assignedResources: PropTypes.arrayOf(PropTypes.string),
	location: PropTypes.shape({}),
	isCustomerService: PropTypes.bool
};

export default LeftMenuNav;
