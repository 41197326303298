import React from 'react';
import { withRouter } from 'react-router-dom';

class Home extends React.Component {
	componentDidMount() {}

	render() {
		return <div className="m-0 row fullheight2"></div>;
	}
}

export default withRouter(Home);
