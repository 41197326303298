import React, { Component } from 'react';
import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import resetIcon from './../../assets/images/undo.png';
import Datetime from 'react-datetime';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import { withRouter } from 'react-router-dom';

export class AuditSearchOffer extends Component {
	// constructor(props) {
	//     super(props);

	// }
	initialState = {
		searchForm: {
			offerType: {
				elementType: 'select',
				elementConfig: {
					type: '',
					placeholder: 'Enter Offer Type',
					labelname: 'Promotion Type',
					options: [
						{ value: 'ALL', displayValue: 'ALL' },
						{ value: 'MULTIBUYPRODUCT', displayValue: 'Multibuy Product' },
						{ value: 'MULTIBUYGROUP', displayValue: 'Multibuy Group' },
						{ value: 'MERCHANDIZE', displayValue: 'Selling Hierarchy' },
						{ value: 'MULTIBUYPACKAGE', displayValue: 'Package' },
						/* { value: 'PACKAGEPRODUC T', displayValue: 'Package Product' },
                    { value: 'PACKAGEMERCHANDIZE', displayValue: 'Package Selling Hierarchy' }, */
						{ value: 'MULTIBUYSCALE', displayValue: 'Scale' },
						/*  { value: 'SCALEMERCHANDIZE', displayValue: 'Scale Selling Hierarchy' }, */
						{ value: 'TXN', displayValue: 'Transaction' },
						{ value: 'SCALETXN', displayValue: 'Transaction Scale' },
						{ value: 'MTMB', displayValue: 'Multi Target Multi Benefit' },
						{ value: 'EARN_BURN', displayValue: 'Earn Burn' },
						{ value: 'SIMPLE', displayValue: 'Simple' }
					]
				},
				value: 'ALL',
				validation: {
					required: false
				},

				validationMessage: '',
				valid: false,
				touched: false
			},
			status: {
				elementType: 'select',
				elementConfig: {
					type: '',
					placeholder: 'Enter Status',
					labelname: 'Status',
					options: [
						{ value: 'ALL', displayValue: 'ALL' },
						{ value: 'Active', displayValue: 'Active' },
						{ value: 'Scheduled', displayValue: 'Scheduled' },
						{ value: 'Expired', displayValue: 'Expired' },
						{ value: 'Paused', displayValue: 'Paused' }
					]
				},
				value: 'ALL',
				validation: {
					required: false
				},
				validationMessage: '',
				valid: false,
				touched: false
			},

			modifiedStartDateTime: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter Modified  Date & Time',
					labelname: 'Modified Date & Time (>)'
				},
				value: '',
				validation: {
					required: false,
					isPhoneNumber: true
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			modifiedEndDateTime: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter Modified End Date & Time',
					labelname: 'Modified End Date & Time'
				},
				value: '',
				validation: {
					required: false,
					isPhoneNumber: true
				},
				validationMessage: '',
				valid: false,
				touched: false
			},

			modifiedBy: {
				elementType: 'select',
				elementConfig: {
					type: '',
					placeholder: 'Enter Modified By',
					labelname: 'Modified By',
					options: [{ value: 'ALL', displayValue: 'ALL' }]
				},
				value: 'ALL',
				validation: {
					required: false
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			productID: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter Product ID',
					labelname: 'Product ID'
				},
				value: '',
				validation: {
					// required: true,
					// maxLength: 10
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			}
		},
		formIsValid: false
	};

	state = this.initialState;

	componentDidMount = () => {
		this.getModifiedByOptions();
	};

	handleFormReset = () => {
		this.setState(() => this.initialState);
	};

	inputChangedHandler = (value, isValidObj, inputIdentifier, dateTimeRangeChanged) => {
		const updatedLoginForm = { ...this.state.searchForm };
		const updatedFormElement = { ...updatedLoginForm[inputIdentifier] };

		updatedFormElement.value = value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;

		updatedLoginForm[inputIdentifier] = updatedFormElement;
		if (dateTimeRangeChanged) {
			const updatedFormStartDateTimeElement = {
				...updatedLoginForm['modifiedStartDateTime']
			};
			const updatedFormEndDateTimeElement = { ...updatedLoginForm['modifiedEndDateTime'] };
			if (!moment(value, 'DD-MM-YYYY HH:mm', true).isValid()) {
				updatedFormElement.valid = false;
				updatedFormElement.validationMessage = 'Invalid date format';
			} else {
				updatedFormElement.valid = true;
				updatedFormElement.validationMessage = '';
			}
			if (updatedFormStartDateTimeElement.value && updatedFormEndDateTimeElement.value) {
				if (
					moment(updatedFormStartDateTimeElement.value) >
					moment(updatedFormEndDateTimeElement.value)
				) {
					updatedFormEndDateTimeElement.valid = false;
					updatedFormEndDateTimeElement.validationMessage = 'Invalid date time range';
				} else {
					updatedFormEndDateTimeElement.valid = isValidObj.value;
					updatedFormEndDateTimeElement.validationMessage = isValidObj.message;
				}
			}

			updatedLoginForm['modifiedEndDateTime'] = updatedFormEndDateTimeElement;
		}
		let formIsValid = true;
		for (let inputIdentifier in updatedLoginForm) {
			formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ searchForm: updatedLoginForm, formIsValid: formIsValid });
	};

	onInputChange = (event, definedRules, inputIdentifier) => {
		let value = event.target.value;
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value.trim() !== '';
				isValid.message = isValid.value ? '' : '';
			}

			if (rules.minLength) {
				isValid.value = value.length >= rules.minLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Minimum length is ' + rules.minLength;
			}

			if (rules.maxLength) {
				isValid.value = value.length <= rules.maxLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Maximum length is ' + rules.maxLength;
			}

			if (rules.isEmail && value) {
				let regEx = new RegExp(
					/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid email';
			}

			if (rules.isPhoneNumber && value) {
				let regEx = new RegExp(
					/^\+?(\d+|(\(\d+\))+)+((([ ]?(\d+|(\(\d+\))+)+)*)|((\-\d+)*)|((\.\d+)*))*$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid phone number';
			}

			if (rules.isAlphabets && value) {
				let regEx = new RegExp('^[a-zA-Z]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets';
			}

			if (rules.isAlphaNumeric && value) {
				let regEx = new RegExp('^[a-zA-Z0-9]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets and numbers';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, false);
	};
	onDateChange = (startDate, definedRules, inputIdentifier) => {
		let value = startDate;
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value !== '';
				isValid.message = isValid.value ? '' : 'Field required';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, true);
	};

	getModifiedByOptions = () => {
		this.props.showLoadingButton2(true);
		var dataToSend = {
			getUsersForFunctionIdRequest: {
				functionID: this.props.loggedInBrand.businessUnitGroupFunctionID
			}
		};

		RestSvc.postData(urlTypes.offers_maintenance.getUsersForFunctionID, dataToSend)
			.then((response) => {
				this.props.showLoadingButton2(false);
				const inputIdentifier = 'modifiedBy';
				const updatedSearchForm = { ...this.state.searchForm };
				const updatedFormElement = { ...updatedSearchForm[inputIdentifier] };
				updatedSearchForm[inputIdentifier] = updatedFormElement;
				var tempArr = [{ value: '', displayValue: 'ALL' }];
				var Users = response.data.getUsersForFunctionIdResponse.userIds;
				Users.map(function (item) {
					tempArr.push({
						value: item,
						displayValue: item
					});
				});
				updatedFormElement.elementConfig.options = tempArr;
				this.setState({ searchForm: updatedSearchForm });
			})
			.catch((error) => {
				this.props.showLoadingButton2(false);
				console.error(error);
			});
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.searchForm) {
			formElementsArray.push({
				id: key,
				config: this.state.searchForm[key]
			});
		}
		return (
			<React.Fragment>
				<div className="modal-body">
					<div className="row p-4">
						<div className="col-md-6">
							<select
								className={`form_control customSelectIcon ${
									!formElementsArray[0].config.valid &&
									formElementsArray[0].config.touched
										? 'Invalid'
										: ''
								}`}
								value={formElementsArray[0].config.value}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[0].config.validation,
										formElementsArray[0].id
									)
								}
							>
								{formElementsArray[0].config.elementConfig.options.map((option) => (
									<option key={option.value} value={option.value}>
										{option.displayValue}
									</option>
								))}
							</select>
							<label
								className={`${
									formElementsArray[0].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[0].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[0].config.validationMessage}
							</p>
						</div>
						<div className="col-md-6 row ml-0">
							<Datetime
								className={`${
									!formElementsArray[2].config.valid &&
									formElementsArray[2].config.touched
										? 'Invalid w333px'
										: 'w333px'
								}`}
								locale="en-GB"
								onChange={(ev) =>
									this.onDateChange(
										ev,
										formElementsArray[2].config.validation,
										formElementsArray[2].id
									)
								}
								//For min and max date
								//isValidDate={(current) => { return current.isBefore(moment()) }}
								dateFormat="DD-MM-YYYY"
								inputProps={{ placeholder: 'DD-MM-YYYY HH:mm' }}
								value={formElementsArray[2].config.value}
								closeOnSelect={true}
								onViewModeChange={() => {
									if (formElementsArray[2].config.value == '') {
										formElementsArray[2].config.value = new Date();
									}
								}}
								timeFormat="HH:mm"
							/>
							<button
								className="clrBtn "
								onClick={() => {
									this.setState((prevState) => {
										let previousState = { ...prevState };
										previousState.searchForm.modifiedStartDateTime.value = '';
										previousState.searchForm.modifiedStartDateTime.valid = true;
										previousState.searchForm.modifiedStartDateTime.validationMessage =
											'';
										previousState.searchForm.modifiedEndDateTime.valid = true;
										previousState.searchForm.modifiedEndDateTime.validationMessage =
											'';
										return previousState;
									});
								}}
							>
								X
							</button>
							<label
								className={`labelStyle dateLabel ${
									formElementsArray[2].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[2].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[2].config.validationMessage}
							</p>
						</div>
						<div className="col-md-6 d-none">
							<select
								className={`form_control customSelectIcon ${
									!formElementsArray[1].config.valid &&
									formElementsArray[1].config.touched
										? 'Invalid'
										: ''
								}`}
								value={formElementsArray[1].config.value}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[1].config.validation,
										formElementsArray[1].id
									)
								}
							>
								{formElementsArray[1].config.elementConfig.options.map((option) => (
									<option key={option.value} value={option.value}>
										{option.displayValue}
									</option>
								))}
							</select>
							<label
								className={`${
									formElementsArray[1].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[1].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[1].config.validationMessage}
							</p>
						</div>
					</div>
					{/* <div className="row p-4 searchOfferDate">
                       
                      <div className="col-md-6 row ml-3">
                            <Datetime
                                className={`${!formElementsArray[3].config.valid && formElementsArray[3].config.touched ? "Invalid w333px" : "w333px"}`}
                                locale="en-GB"
                                onChange={(ev) => this.onDateChange(ev, formElementsArray[3].config.validation, formElementsArray[3].id)}
                                //For min and max date 
                                //isValidDate={(current) => { return current.isBefore(moment()) }}
                                dateFormat="DD-MM-YYYY"
                                inputProps={{ placeholder: 'DD-MM-YYYY HH:mm' }}
                                value={formElementsArray[3].config.value}
                                closeOnSelect={true}
                                onViewModeChange={() => { if (formElementsArray[3].config.value == "") { formElementsArray[3].config.value = new Date() } }}
                                timeFormat="HH:mm"
                            />
                            <button className="clrBtn "
                                onClick={() => {
                                    this.setState((prevState) => {
                                        let previousState = { ...prevState }
                                        previousState.searchForm.modifiedEndDateTime.value = '';
                                        previousState.searchForm.modifiedEndDateTime.valid = true;
                                        previousState.searchForm.modifiedEndDateTime.validationMessage = '';
                                        return previousState
                                    })
                                }
                                }
                            >X</button>
                            <label className={`labelStyle dateLabel ${formElementsArray[3].config.validation.required ? "require" : ""}`}>{formElementsArray[3].config.elementConfig.labelname}</label>
                            <p className="errorMessage">{formElementsArray[3].config.validationMessage}</p>
                        </div> 
                    </div>*/}

					<div className="row p-4">
						<div className="col-md-6 row ml-0">
							<select
								className={`form_control customSelectIcon ${
									!formElementsArray[4].config.valid &&
									formElementsArray[4].config.touched
										? 'Invalid'
										: ''
								}`}
								value={formElementsArray[4].config.value}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[4].config.validation,
										formElementsArray[4].id
									)
								}
							>
								{formElementsArray[4].config.elementConfig.options.map((option) => (
									<option key={option.value} value={option.value}>
										{option.displayValue}
									</option>
								))}
							</select>
							<label
								className={`${
									formElementsArray[4].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[4].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[4].config.validationMessage}
							</p>
						</div>
					</div>
				</div>
				<div className="modal-footer border-0">
					<p
						onClick={() => {
							this.handleFormReset();
						}}
						className="grey-button roboto-b-16"
					>
						<img src={resetIcon} alt="img" /> Reset
					</p>
					<button
						onClick={() => this.props.handleSelection(false)}
						className="cancel-btn roboto-b-16  ml-auto"
					>
						Cancel
					</button>
					<button
						className={
							!formElementsArray[3].config.valid &&
							formElementsArray[3].config.touched
								? 'disabled btnNxtPrev ml-2 roboto-b-16'
								: 'btnNxtPrev ml-2 roboto-b-16'
						}
						onClick={() => {
							this.props.handleSearch(this.state.searchForm);
						}}
					>
						Search
					</button>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		showLoadingButton2: (val) => dispatch({ type: actionTypes.LOADING_BUTTON2, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuditSearchOffer));
