import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './toggleSwitch.scss';

/**
 * @description custom hook to check firstRender of the Component
 * @returns boolean
 */
export const useFirstRender = () => {
	const firstRender = useRef(true);

	useEffect(() => {
		firstRender.current = false;
	}, []);

	return firstRender.current;
};

const ToggleSwitch = ({
	label,
	name,
	initialValue,
	toggleHandler,
	className,
	onValue,
	offValue,
	customStyle
}) => {
	const [isChecked, setIsChecked] = useState(initialValue);

	const isFirstRender = useFirstRender();

	useEffect(() => {
		if (isFirstRender) return;
		toggleHandler && toggleHandler(isChecked);
	}, [isChecked]);

	const onChangeHandler = () => {
		setIsChecked(!isChecked);
	};
	const style = customStyle;

	return (
		<div className={`toggle-switch-container ${className}`} style={style}>
			{label && <span className="mr-1">{label}</span>}
			<div className="toggle-switch">
				<input
					type="checkbox"
					className="checkbox"
					name={name}
					id={name}
					checked={isChecked}
					onChange={onChangeHandler}
				/>
				<label className="label" htmlFor={name}>
					 {/* eslint-disable react/no-unknown-property  */}
					<span className="inner" on={onValue} off={offValue} />
					<span className="switch" />
				</label>
			</div>
		</div>
	);
};

ToggleSwitch.defaultProps = {
	label: '',
	name: 'toggle-switch',
	initialValue: false,
	toggleHandler: null,
	className: '',
	onValue: 'ON',
	offValue: 'Off',
	customStyle: {}
};

ToggleSwitch.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	initialValue: PropTypes.bool,
	toggleHandler: PropTypes.func,
	className: PropTypes.string,
	onValue: PropTypes.string,
	offValue: PropTypes.string,
	customStyle: PropTypes.shape({})
};

export default ToggleSwitch;
