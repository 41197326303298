import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';

import './couponsList.scss';
import appLabels from '../../utils/appLabels';

import ReactTable from '../../Components/ReactTable/ReactTable';
import Input from '../../Components/Input/Input';
import copyIcon from '../../assets/images/path.png';
import downloadIcon from '../../assets/images/Icon.png';
import appConstants from '../../utils/appConstants';

const { eventType } = appConstants;

const {
	table: tableLabels,
	page: { generateCoupon: labels }
} = appLabels;

const CouponsList = forwardRef(function CouponsList(
	{ couponsListData = [], downloadCouponsHandler, replicateCouponsHandler },
	ref
) {
	const [selectAll, setSelectAll] = useState(false);
	const [couponsList, setCouponsList] = useState([]);
	const [selectedCoupons, setSelectedCoupons] = useState([]);

	useEffect(() => {
		setCouponsList(couponsListData);
	}, []);

	useImperativeHandle(
		ref,
		() => {
			return {
				getSelectedCoupons: () => selectedCoupons
			};
		},
		[selectedCoupons]
	);

	const rowSelectAllHandler = () => {
		const _selectAll = !selectAll;
		setSelectAll(_selectAll);

		setCouponsList((couponsList) => {
			let _couponsList = [...couponsList];

			_couponsList = _couponsList.map((val) => {
				let _val = { ...val, isSelect: _selectAll };
				return _val;
			});
			return _couponsList;
		});
		let newSelectedCoupons = _selectAll ? [...couponsList] : [];
		setSelectedCoupons(newSelectedCoupons);
	};

	const rowSelectHandler = (e, i, coupon) => {
		setCouponsList((couponsList) => {
			const isRowSelected = e.target.checked;
			let _couponsList = [...couponsList];
			_couponsList = _couponsList.map((val) => {
				let _val = { ...val };
				if (val.ind === coupon.ind) {
					_val.isSelect = isRowSelected;
				}
				return _val;
			});

			/* select all checkbox check start */
			let _selectedOffers = _couponsList.filter((val) => {
				return val.isSelect;
			});
			const _selectAll = _selectedOffers.length === _couponsList.length;
			/* select all checkbox check end */
			setSelectAll(_selectAll);
			setSelectedCoupons(_selectedOffers);
			return _couponsList;
		});
	};

	const actionFormatter = ({ value, row, index }) => {
		const { generationDetails } = row;
		return (
			<div className="action-container">
				<button
					title={labels.actionBtnReplicateCouponText}
					className="btn btn-link"
					onClick={() => {
						replicateCouponsHandler?.(row, generationDetails === null);
					}}
				>
					<img src={copyIcon} alt={labels.actionBtnReplicateCouponText} />
				</button>
				<button
					title={labels.actionBtnDownloadCouponText}
					className="btn btn-link"
					onClick={() => {
						downloadCouponsHandler?.({
							userId: sessionStorage.getItem('loggedInUserId'),
							filename: row.fileName,
							uuId: row.notificationId,
							eventType:
								eventType[
									generationDetails === null ? 'CUSTOM_COUPON' : 'GENERATE_COUPON'
								]
						});
					}}
				>
					<img
						className="download-icon-img"
						src={downloadIcon}
						alt={labels.actionBtnDownloadCouponText}
					/>
				</button>
			</div>
		);
	};

	const checkFormater = ({ value, coupon, ind }) => {
		const isChecked = coupon.isSelect || false;
		return (
			<Input
				customClasses="table-checkbox"
				elementType="checkbox"
				changed={(e) => {
					rowSelectHandler(e, ind, coupon);
				}}
				checked={isChecked}
				elementConfig={{
					type: 'checkbox',
					id: `row-select-${ind}`
				}}
			/>
		);
	};

	const columns = [
		{
			dataField: 'ind',
			text: tableLabels.sNo,
			headerAlign: 'center',
			align: 'center',
			headerStyle: {
				width: '55px'
			}
		},
		{
			isDummyField: true,
			dataField: 'selectColumn',
			text: 'Select ',
			headerAlign: 'center',
			align: 'center',
			headerStyle: {
				width: '40px'
			},
			style: {
				width: '40px'
			},
			headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
				return (
					<Input
						customClasses="table-checkbox"
						elementType="checkbox"
						changed={(e) => rowSelectAllHandler(e)}
						checked={selectAll}
						elementConfig={{
							type: 'checkbox',
							id: 'row-select-all'
						}}
					/>
				);
			},
			formatter: (value, row, index) =>
				checkFormater({
					value,
					coupon: row,
					ind: index
				})
		},
		{
			dataField: 'fileName',
			text: tableLabels.fileName,
			headerAlign: 'center'
		},
		{
			isDummyField: true,
			dataField: 'numberOfCoupons',
			text: tableLabels.numberOfCoupons,
			headerAlign: 'center',
			align: 'center',
			headerStyle: {
				width: '155px'
			},
			formatter: (value, row, index) => {
				const { customCouponsDetails, generationDetails } = row;
				return (
					generationDetails?.numberOfCoupons ||
					customCouponsDetails?.customCouponList.length
				);
			}
		},
		{
			isDummyField: true,
			dataField: 'actionColumn',
			text: tableLabels.action,
			searchable: false,
			headerAlign: 'center',
			align: 'center',
			headerStyle: {
				width: '55px'
			},
			formatter: (value, row, index) =>
				actionFormatter({
					value,
					row,
					index
				})
		}
	];

	const props = {
		data: couponsList,
		columns,
		keyField: 'ind',
		paginationEnable: true,
		customClasses: 'coupon-list-table',
		searchBarEnable: true
	};

	return (
		<div className="coupons-list">
			<ReactTable {...props} />
		</div>
	);
});

CouponsList.prototype = {
	couponsListData: PropTypes.arrayOf(PropTypes.shape({})),
	downloadCouponsHandler: PropTypes.func
};

export default CouponsList;
