import axios from './axios';

export const postData = (url, data, notABaseUrl, extraOptions) => {
	return axios({
		method: 'post',
		url: url,
		data: data,
		baseURL: notABaseUrl ? notABaseUrl : undefined,
		...extraOptions
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.error('Error : ' + error.status);
			return Promise.reject(error);
		});
};

export const putData = (url, data, notABaseUrl, extraOptions) => {
	return axios({
		method: 'put',
		url: url,
		data: data,
		baseURL: notABaseUrl ? notABaseUrl : undefined,
		...extraOptions
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.error('Error : ' + error.status);
			return Promise.reject(error);
		});
};

export const deleteData = (url, data, notABaseUrl, extraOptions) => {
	return axios({
		method: 'delete',
		url: url,
		data: data,
		baseURL: notABaseUrl ? notABaseUrl : undefined,
		...extraOptions
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.error('Error : ' + error.status);
			return Promise.reject(error);
		});
};

export const getData = (url) => {
	return axios
		.get(url)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.error('Error : ' + error.status);
			return Promise.reject(error);
		});
};

export const fetchAll = (promise = []) => {
	return Promise.all([...promise])
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.error('Error : ' + error.status);
			return Promise.reject(error);
		});
};
