import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../BasicDetails.scss';
import { useField } from 'formik';

const CustomSelect = ({
	label,
	required,
	options,
	smallText,
	isBottomScroll,
	multiple,
	...props
}) => {
	const [field, meta] = useField(props);

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	let formattedName = '';
	let newJson = '';
	let basicOrEligibilityJson = '';
	if (isAuditFlw) {
		let newCustName = JSON.parse(JSON.stringify(props.name));
		// eslint-disable-next-line no-unused-vars
		newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
		basicOrEligibilityJson = newCustName.includes('promotionPolicy')
			? 'newJson'
			: 'newJson.promotionPolicy.priceDerivationRuleEligibility';
		let arrayOfName = newCustName.split('.');

		for (let i = 0; i < arrayOfName.length; i++) {
			if (isNaN(arrayOfName[i])) {
				formattedName = formattedName + '?.' + arrayOfName[i];
			} else {
				formattedName = formattedName + '?.[' + arrayOfName[i] + ']';
			}
		}
	}

	return (
		<div className="">
			<div
				className={
					isAuditFlw &&
					eval(basicOrEligibilityJson + formattedName) === field.value &&
					props?.flag != 'show'
						? 'hideForAudit '
						: ''
				}
			>
				<div className="">
					<label htmlFor="forprogramID" className="	col-form-label">
						{label}

						{required && label ? <span className="requiredRed">*</span> : null}
					</label>
				</div>
				<div className="">
					<div className="">
						<div className={isAuditFlw ? 'hideForAudit' : ''}>
							<select
								{...field}
								{...props}
								className={`form-control ${
									meta.touched && meta.error ? 'is-invalid' : ''
								}`}
								title="Select"
								onClick={
									isBottomScroll
										? (e) => {
												var s = e.target.parentElement;
												s.scrollIntoView();
										  }
										: undefined
								}
							>
								{options}
							</select>
						</div>

						{isAuditFlw ? (
							<div>
								<div className="d-flex gap70 replacedText">
									<select
										{...field}
										{...props}
										className={`form-control lightGray ${
											meta.touched && meta.error ? 'is-invalid' : ''
										}`}
										title="Select"
										onClick={
											isBottomScroll
												? (e) => {
														var s = e.target.parentElement;
														s.scrollIntoView();
												  }
												: undefined
										}
									>
										{options}
									</select>
									{isAuditFlw &&
									eval(basicOrEligibilityJson + formattedName) ===
										field.value ? undefined : (
										<select
											value={eval(basicOrEligibilityJson + formattedName)}
											className="form-control lightBlue"
											title="Select"
											onClick={
												isBottomScroll
													? (e) => {
															var s = e.target.parentElement;
															s.scrollIntoView();
													  }
													: undefined
											}
										>
											{options}
										</select>
									)}
								</div>
							</div>
						) : null}
						{smallText && (
							<small id="emailHelp" className="form-text pl-2">
								<div className="tooltipimg">
									<button className="tooltip-button btn br-50" type="button">
										<span className="tooltip-icon">?</span>
									</button>
									<span className="tooltiptext"> {smallText}</span>
								</div>
							</small>
						)}

						{meta.touched && meta.error ? (
							<div className="invalid-feedback">{meta.error}</div>
						) : null}
					</div>

					{/* <small
            id="emailHelp"
            className="form-text text-muted col-md-9 justify-content-start flex1"
          >
            {smallText}
          </small> */}
				</div>
			</div>
		</div>
	);
};

export default CustomSelect;
