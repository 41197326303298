import React, { useEffect, useState } from 'react';
import { useField, FieldArray, useFormikContext, ErrorMessage } from 'formik';
import 'bootstrap/dist/css/bootstrap.css';
import '../BasicDetails.scss';
import CustomSelect from '../customfields/CustomSelect';
import MerchTableBootstrapSCALE from '../MerchTableBootstrapSCALE';
import ProductTableBoostrap from '../ProductTableBootstrap';
import AuditProductTablePackage from '../Audit/AuditProductTablePackage';
import AuditSHTablePackage from '../Audit/AuditSHTablePackage';
import CustomInputText from '../customfields/CustomInputText';
import CustomCheckbox1 from '../customfields/CustomCheckbox1';

let PKGProductTableData;
const keys = ['productID', 'minimumPrice', 'maximumPrice'];
const keysMerch = ['merchandizeGroupId', 'minimumPrice', 'maximumPrice'];
const packageType = [
	{
		value: 'PRODUCT',
		name: 'Product'
	},
	{
		value: 'SH',
		name: 'Selling Hierarchy'
	}
];
const defaultValueForAddPP = {
	maximumPrice: '9999999.99',
	minimumPrice: '0.01'
	// trigger: 'Y',
	// getReward: 'Y',
};

const defaultValueForAddPM = {
	maximumPrice: '9999999.99',
	minimumPrice: '0.01'
	// trigger: 'Y',
	// getReward: 'Y',
};

let errMsg = '';

const getThresholdDropdown = (thresholdTypeCodeOptions) => {
	return thresholdTypeCodeOptions.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const GroupArray = ({ ...props }) => {
	let { customArrayName } = props;
	const fieldPKG = props.fieldPKG;
	const [meta] = useField(props);
	const [showTable, setshowTable] = useState(true);
	const { setFieldValue } = useFormikContext();
	// const [tempProductID, setTempProductID] = useState('');

	if (props.dataPKGtable && Object.keys(props.dataPKGtable).length != 0) {
		PKGProductTableData = props.dataPKGtable;
	} else {
		if (!PKGProductTableData) {
			PKGProductTableData = {
				group: [
					{
						multibuyProduct: [],
						merchandizeHierarchyGroup: []
					}
				]
			};
		}
	}

	useEffect(() => {
		if (
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			fieldPKG[customArrayName][0].memberGroupThresholdQuantity = undefined;
			if (
				!fieldPKG[customArrayName][0].memberGroupThresholdAmount &&
				fieldPKG[customArrayName][0].trigger == true
			) {
				fieldPKG[customArrayName][0].memberGroupThresholdAmount = '0.01';
			}
			if (
				!fieldPKG[customArrayName][0].memberGroupRewardQuantity &&
				fieldPKG[customArrayName][0].getReward == true
			) {
				fieldPKG[customArrayName][0].memberGroupRewardQuantity = '1';
			}
		}
		if (
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD'
		) {
			fieldPKG[customArrayName][0].memberGroupThresholdAmount = undefined;
			if (
				!fieldPKG[customArrayName][0].memberGroupThresholdQuantity &&
				fieldPKG[customArrayName][0].trigger == true
			) {
				fieldPKG[customArrayName][0].memberGroupThresholdQuantity = '1';
			}
			if (
				!fieldPKG[customArrayName][0].memberGroupRewardQuantity &&
				fieldPKG[customArrayName][0].getReward == true
			) {
				fieldPKG[customArrayName][0].memberGroupRewardQuantity = '1';
			}
		}

		if (
			fieldPKG.thresholdTypeCode == 'PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'PACKAGE_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'PACKAGE_RANGE_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'PACKAGE_RANGE_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'PACKAGE_LIMIT_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'PACKAGE_AMOUNT_THRESHOLD'
		) {
			if (fieldPKG[customArrayName]) {
				fieldPKG[customArrayName].map((grp) => {
					if (grp?.memberGroupThresholdQuantity)
						grp.memberGroupThresholdQuantity = undefined;
					if (grp?.memberGroupThresholdAmount) grp.memberGroupThresholdAmount = undefined;
					if (grp?.memberGroupRewardQuantity) grp.memberGroupRewardQuantity = undefined;
				});
			}
		}
	}, [fieldPKG.thresholdTypeCode]);
	useEffect(() => {
		return () => {
			PKGProductTableData = undefined;
		};
	}, []);

	const onProductTableChange = (val, j) => {
		//debugger;
		// MTMBProductTableData.packageMultibuyEligibility[i].group[j] = val
		if (val.length != 0) {
			PKGProductTableData[customArrayName][j].multibuyProduct = val;
		} else {
			PKGProductTableData[customArrayName][j].multibuyProduct = [];
		}

		props.onPKGProductTableChange(PKGProductTableData);
	};

	const onMerchTableChange = (val, j) => {
		//debugger
		if (val.length != 0) {
			PKGProductTableData[customArrayName][j].merchandizeHierarchyGroup = val;
		} else {
			PKGProductTableData[customArrayName][j].merchandizeHierarchyGroup = [];
		}

		props.onPKGProductTableChange(PKGProductTableData);
		//props.onMerchTableDataChange(PKGProductTableData)
	};

	const triggerValueChange = (i) => {
		if (
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD'
		) {
			if (!fieldPKG[customArrayName][i].trigger == true) {
				fieldPKG[customArrayName][i].memberGroupThresholdQuantity = '1';
				fieldPKG[customArrayName][i].memberGroupThresholdAmount = '';
			} else {
				fieldPKG[customArrayName][i].memberGroupThresholdQuantity = '';
				fieldPKG[customArrayName][i].memberGroupThresholdAmount = '';
			}
		} else if (
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			if (!fieldPKG[customArrayName][i].trigger == true) {
				fieldPKG[customArrayName][i].memberGroupThresholdAmount = '0.01';
				fieldPKG[customArrayName][i].memberGroupThresholdQuantity = '';
			} else {
				fieldPKG[customArrayName][i].memberGroupThresholdAmount = '';
				fieldPKG[customArrayName][i].memberGroupThresholdQuantity = '';
			}
		}
	};

	const getRewardValueChange = (i) => {
		if (
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			//fieldPKG.group[i].memberGroupRewardQuantity = ''
			if (!fieldPKG[customArrayName][i].getReward == true) {
				fieldPKG[customArrayName][i].memberGroupRewardQuantity = '1';
			} else {
				fieldPKG[customArrayName][i].memberGroupRewardQuantity = '';
			}
		}
		setTimeout(() => {
			setFieldValue('', '');
		}, 500);
	};

	const customValidateMemberGroupThresholdQuantity = (value, i) => {
		let error;
		if (
			(fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD') &&
			fieldPKG[customArrayName][i].trigger
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}$/)) {
					error = 'Group Threshold Quantity value should be between 1 to 999999';
				}
			} else {
				error = 'Please define a Group Threshold Quantity value';
			}
		}
		return error;
	};

	//amount
	const customValidateMemberGroupThresholdAmount = (value, i) => {
		let error;
		if (
			(fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD') &&
			fieldPKG[customArrayName][i].trigger
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/)) {
					error = 'Group Threshold Amount value should be between 0.01 to 999999.99';
				}
			} else {
				error = 'Please define a Group Threshold Amount value';
			}
		}
		return error;
	};

	//reward
	const customValidateMemberGroupRewardQuantity = (value, i) => {
		let error;
		if (
			(fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD') &&
			fieldPKG[customArrayName][i].getReward
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}$/)) {
					error = 'Group Reward Quantity value should be between 1 to 999999';
				}
			} else {
				error = 'Please define a Group Reward Quantity value';
			}
		}
		return error;
	};

	const packageTypeChange = (e, i) => {
		if (fieldPKG[customArrayName][i].packageType == 'PRODUCT')
			fieldPKG[customArrayName][i].packageType = 'SH';
		else fieldPKG[customArrayName][i].packageType = 'PRODUCT';
		fieldPKG[customArrayName][i].multibuyProduct = [];
		fieldPKG[customArrayName][i].merchandizeHierarchyGroup = [];
		//debugger
		/* PKGProductTableData.group[i]=
                 {
                   "multibuyProduct": [],
                   "merchandizeHierarchyGroup": [],    
                 } */
		PKGProductTableData.group[i] = fieldPKG[customArrayName][i];

		setFieldValue('', '');
	};
	const customValidateTrigger = (value, i) => {
		let error;
		if (value || fieldPKG[customArrayName][i]?.getReward) {
			error = undefined;
		} else {
			error = 'Please select Trigger or Reward value as both cannot be false';
		}
		return error;
	};

	return (
		<div className="Container">
			<FieldArray name={`packageMultibuyEligibility.${customArrayName}`}>
				{({ remove }) => (
					<>
						<div
							className="accordion mtmb-acrdn mr-2 p-3 mb-4"
							id="accordionGroupExample"
						>
							{fieldPKG &&
								fieldPKG[customArrayName] &&
								fieldPKG[customArrayName].map((item, i) => (
									<>
										{props.submitCnt > 0 &&
										meta.error?.packageMultibuyEligibility &&
										meta.error?.packageMultibuyEligibility[customArrayName]?.[
											i
										] ? (
											<div className="text-danger ml-2">
												Please fill in all the mandatory fields to save the
												promotion.
											</div>
										) : null}
										<div className="d-flex">
											<div
												className={
													customArrayName.indexOf('Added') !== -1
														? 'card mtmb-card m-2 borderForAdded'
														: customArrayName.indexOf('Removed') !== -1
														? 'card mtmb-card m-2 borderForRemoved'
														: 'card mtmb-card m-2'
												}
												style={{ width: 'calc(100% - 50px)' }}
											>
												<div
													className={
														i + 1 == fieldPKG[customArrayName]?.length
															? 'card-head mtmb-crd-head'
															: 'card-head mtmb-crd-head collapsed'
													}
													data-toggle="collapse"
													data-target={`#pkgcollapsePackageMultibuyPROD${i}`}
												>
													<h2 className="mb-0">
														<div
															className={
																props.submitCnt > 0 &&
																meta.error
																	?.packageMultibuyEligibility &&
																meta.error
																	?.packageMultibuyEligibility[
																	customArrayName
																]?.[i]
																	? 'item-before text-danger pointer-events-auto'
																	: 'item-before pointer-events-auto'
															}
														>
															{`Group ${i + 1}`}
														</div>
													</h2>
												</div>
												<div
													id={`pkgcollapsePackageMultibuyPROD${i}`}
													className={
														i + 1 == fieldPKG[customArrayName]?.length
															? 'collapse show mtmb-collapse'
															: 'collapse mtmb-collapse'
													}
													data-parent="#accordionExample"
												>
													<div
														className="card-body m-2"
														style={{
															'border-radius': '5px'
														}}
													>
														<div className="container-fluid cardStyle PkgGrpStyle">
															<div className="text-center mt-3">
																<CustomSelect
																	name={`packageMultibuyEligibility.${customArrayName}.${i}.packageType`}
																	style={{
																		'margin-bottom': '20px'
																	}}
																	required={true}
																	label="Package Type"
																	options={getThresholdDropdown(
																		packageType
																	)}
																	onChange={(e) =>
																		packageTypeChange(e, i)
																	}
																/>
																<div className="container d-flex justify-content-center align-items-center">
																	<span>
																		{/* <label>Trigger</label>
                                        <span className="requiredRed pr-2">*</span> */}
																		<CustomCheckbox1
																			required={true}
																			name={`packageMultibuyEligibility.${customArrayName}.${i}.trigger`}
																			className="p-3"
																			label="Trigger"
																			onClick={() =>
																				triggerValueChange(
																					i
																				)
																			}
																			validate={(value) =>
																				customValidateTrigger(
																					value,
																					i
																				)
																			}
																		/>
																	</span>
																	<span>
																		{/* <label>Get Reward</label>
                                        <span className="requiredRed pr-2">*</span> */}
																		<CustomCheckbox1
																			required={true}
																			label="Get Reward"
																			name={`packageMultibuyEligibility.${customArrayName}.${i}.getReward`}
																			className="p-3"
																			onClick={() =>
																				getRewardValueChange(
																					i
																				)
																			}
																		/>
																	</span>
																</div>
																<div className={'text-center'}>
																	<ErrorMessage
																		name={`packageMultibuyEligibility.${customArrayName}.${i}.trigger`}
																		component="div"
																		className="field-error"
																	/>
																</div>
															</div>

															{fieldPKG.thresholdTypeCode ==
																'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
															fieldPKG.thresholdTypeCode ==
																'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
															fieldPKG.thresholdTypeCode ==
																'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
															fieldPKG.thresholdTypeCode ==
																'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ? (
																<div className="purchaseContainer">
																	<div className="purchaseRow align-self-start d-block">
																		{fieldPKG.thresholdTypeCode ==
																			'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
																		fieldPKG.thresholdTypeCode ==
																			'HIERARCHY_PACKAGE_QTY_THRESHOLD' ? (
																			<CustomInputText
																				name={`packageMultibuyEligibility.${customArrayName}.${i}.memberGroupThresholdQuantity`}
																				required={false}
																				validate={(value) =>
																					customValidateMemberGroupThresholdQuantity(
																						value,
																						i
																					)
																				}
																				label={
																					'Group Threshold Quantity'
																				}
																				smallText=""
																				disabled={
																					!fieldPKG[
																						customArrayName
																					][i].trigger
																				}
																			/>
																		) : (
																			<CustomInputText
																				name={`packageMultibuyEligibility.${customArrayName}.${i}.memberGroupThresholdAmount`}
																				validate={(value) =>
																					customValidateMemberGroupThresholdAmount(
																						value,
																						i
																					)
																				}
																				required={false}
																				label={
																					'Group Threshold Amount'
																				}
																				smallText=""
																				disabled={
																					!fieldPKG[
																						customArrayName
																					][i].trigger
																				}
																			/>
																		)}
																	</div>
																	<div className="purchaseRow align-self-start d-block">
																		<CustomInputText
																			name={`packageMultibuyEligibility.${customArrayName}.${i}.memberGroupRewardQuantity`}
																			required={false}
																			validate={(value) =>
																				customValidateMemberGroupRewardQuantity(
																					value,
																					i
																				)
																			}
																			label={
																				'Group Reward Quantity'
																			}
																			smallText=""
																			disabled={
																				!fieldPKG[
																					customArrayName
																				][i].getReward
																			}
																		/>
																	</div>
																</div>
															) : null}

															<div>
																<div className="text-center text-danger">
																	{errMsg ==
																	'Product ID:' +
																		fieldPKG[customArrayName][i]
																			?.productID +
																		' already added'
																		? errMsg
																		: null}
																</div>

																<div className="margin10">
																	{/* <AuditProductTablePackage /> */}
																	{customArrayName.indexOf(
																		'Added'
																	) !== -1 ||
																	customArrayName.indexOf(
																		'Removed'
																	) !== -1 ? (
																		<>
																			{fieldPKG[
																				customArrayName
																			] &&
																			fieldPKG[
																				customArrayName
																			][i].packageType ==
																				'PRODUCT' ? (
																				<div
																					className={
																						customArrayName.indexOf(
																							'Added'
																						) !== -1
																							? 'dataAdded'
																							: 'dataRemoved'
																					}
																				>
																					<ProductTableBoostrap
																						keys={keys}
																						data={
																							fieldPKG[
																								customArrayName
																							][i]
																								?.multibuyProduct
																						}
																						defaultValueForAdd={
																							defaultValueForAddPP
																						}
																						onProductTableChange={() => {}}
																						eligibility={
																							'packageMultibuyEligibility'
																						}
																						i={i}
																						group={
																							fieldPKG[
																								customArrayName
																							]
																						}
																					/>
																				</div>
																			) : (
																				<div
																					className={
																						customArrayName.indexOf(
																							'Added'
																						) !== -1
																							? 'dataAdded'
																							: 'dataRemoved'
																					}
																				>
																					<MerchTableBootstrapSCALE
																						keys={
																							keysMerch
																						} //data={data}
																						data={
																							fieldPKG[
																								customArrayName
																							][i]
																								?.merchandizeHierarchyGroup
																						}
																						defaultValueForAdd={
																							defaultValueForAddPM
																						}
																						onMerchTableChange={() => {}}
																						eligibility={
																							'packageSH'
																						}
																						group={
																							fieldPKG[
																								customArrayName
																							]
																						}
																						PKGMerchTableData={
																							fieldPKG
																						}
																						isMerchTableRepetative={
																							true
																						}
																						currentGroupIndex={
																							i
																						}
																					/>
																				</div>
																			)}
																		</>
																	) : JSON.parse(
																			sessionStorage.getItem(
																				'typeOfOffersFlow'
																			)
																	  ).isAuditFlow &&
																	  customArrayName == 'group' ? (
																		fieldPKG[customArrayName] &&
																		fieldPKG[customArrayName][i]
																			.packageType ==
																			'PRODUCT' ? (
																			<AuditProductTablePackage
																				customArrayName="group"
																				PKGProductTableData={
																					PKGProductTableData
																				}
																				oldJson={
																					props
																						.dataPKGtable
																						?.group[i]
																						?.multibuyProduct
																				}
																				i={i}
																				newJson={
																					JSON.parse(
																						sessionStorage.getItem(
																							'NewPolicy'
																						)
																					)
																						.promotionPolicy
																						?.priceDerivationRuleEligibility
																						?.packageMultibuyEligibility
																						?.group[i]
																						?.multibuyProduct
																				}
																			/>
																		) : (
																			<AuditSHTablePackage
																				customArrayName="group"
																				PKGProductTableData={
																					PKGProductTableData
																				}
																				oldJson={
																					props
																						.dataPKGtable
																						?.group[i]
																						?.merchandizeHierarchyGroup
																				}
																				i={i}
																				newJson={
																					JSON.parse(
																						sessionStorage.getItem(
																							'NewPolicy'
																						)
																					)
																						.promotionPolicy
																						?.priceDerivationRuleEligibility
																						?.packageMultibuyEligibility
																						?.group[i]
																						?.merchandizeHierarchyGroup
																				}
																			/>
																		)
																	) : (
																		<>
																			{fieldPKG?.group[i]
																				.packageType ==
																			'PRODUCT' ? (
																				showTable ? (
																					<ProductTableBoostrap
																						keys={keys}
																						data={
																							PKGProductTableData[
																								customArrayName
																							][i]
																								?.multibuyProduct
																						}
																						defaultValueForAdd={
																							defaultValueForAddPP
																						}
																						onProductTableChange={(
																							val
																						) =>
																							onProductTableChange(
																								val,
																								i
																							)
																						}
																						eligibility={
																							'packageMultibuyEligibility'
																						}
																						i={i}
																						group={
																							PKGProductTableData[
																								customArrayName
																							]
																						}
																						brandEligibility={
																							JSON.parse(
																								props.fullValue
																							)
																								.promotionPolicy
																								.promotionApplicableEligibility
																								.brandEligibility
																						}
																					/>
																				) : null
																			) : showTable ? (
																				<MerchTableBootstrapSCALE
																					keys={keysMerch} //data={data}
																					data={
																						PKGProductTableData[
																							customArrayName
																						][i]
																							?.merchandizeHierarchyGroup
																					}
																					defaultValueForAdd={
																						defaultValueForAddPM
																					}
																					onMerchTableChange={(
																						val
																					) =>
																						onMerchTableChange(
																							val,
																							i
																						)
																					}
																					eligibility={
																						'packageSH'
																					}
																					group={
																						PKGProductTableData[
																							customArrayName
																						]
																					}
																					PKGMerchTableData={
																						PKGProductTableData
																					}
																					isMerchTableRepetative={
																						true
																					}
																					currentGroupIndex={
																						i
																					}
																				/>
																			) : null}
																		</>
																	)}
																	{/*  {JSON.parse(sessionStorage.getItem("typeOfOffersFlow")).isAuditFlow && customArrayName=="group"?
                                          <AuditProductTablePackage customArrayName="group" PKGProductTableData={PKGProductTableData} oldJson={props.dataPKGtable} i={i} newJson={JSON.parse(sessionStorage.getItem("NewPolicy"))}/>
                                          :undefined} */}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											{fieldPKG[customArrayName]?.length > 1 ? (
												<button
													type="button"
													className="addDeleteBtn mt-3"
													style={{ 'background-color': 'unset' }}
													onClick={() => {
														remove(i);
														PKGProductTableData[customArrayName].splice(
															i,
															1
														);
														props.onPKGProductTableChange(
															PKGProductTableData
														);
														setshowTable(false);
														setTimeout(() => {
															setshowTable(true);
														}, 50);
														setFieldValue('', '');
													}}
												></button>
											) : null}
										</div>
										{i + 1 == fieldPKG[customArrayName]?.length ? (
											<div className="d-flex justify-content-end mt-2 mr-5">
												<button
													type="button"
													className="createGroupBtn mt-1"
													onClick={() => {
														//debugger;
														PKGProductTableData[customArrayName].push({
															multibuyProduct: [],
															merchandizeHierarchyGroup: []
														});

														fieldPKG[customArrayName].push({
															multibuyProduct: [],
															merchandizeHierarchyGroup: [],
															packageType: 'PRODUCT',
															trigger: true,
															getReward: true,
															memberGroupThresholdAmount:
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
																	? '0.01'
																	: undefined,
															memberGroupThresholdQuantity:
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_QTY_THRESHOLD'
																	? '1'
																	: undefined,
															memberGroupRewardQuantity:
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_QTY_THRESHOLD'
																	? '1'
																	: undefined
														});

														// setTempProductID('');
														setFieldValue('', '');
													}}
												>
													<span className="pl-2 pr-3">+</span> Group
												</button>
											</div>
										) : null}
									</>
								))}
						</div>
					</>
				)}
			</FieldArray>
		</div>
	);
};

export default GroupArray;
