import React from 'react';
import { Form, useField } from 'formik';
import CollapseHeaderBar from './CollapseHeaderBar';
import CollapseBody from './CollapseBody';
import BenefitSettings from './BenefitSettings';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import PaymentCondition from './PaymentCondition';
import CouponCondition from './CouponCondition';
import ExclusionSetting from './ExclusionSetting';
import SellingHierarchy from '../components/SellingHierarchy/SellingHierarchy';
import InclusionsType from '../components/SellingHierarchy/InclusionsType';
import MerchTableBootstrap from './MerchTableBootstrapSCALE';
import ProductTableBoostrap from './ProductTableBootstrap';
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import AuditProductTable from './Audit/AuditProductTable';
import AuditSHTable from './Audit/AuditSHTable';

/* const eligibilitySchema = Yup.object().shape({
  thresholdTypeCode: Yup.string().required("Required"),
  groupTriggerCombinationCode: Yup.string().required("Required"),
  groupRewardCombinationCode: Yup.string().required("Required"),
  groupThresholdQuantity: Yup.string().required("Required"),
  maximumThresholdAmount: Yup.string().required("Required"),
}); */

const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	triggerCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Determines the combination code to be used between the trigger items for a promotion',
	thresholdQuantity: 'Threshold Quantity',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	rewardQuantity: 'Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	PaymentConditions: 'Payment Conditions',
	CouponConditions: 'Coupon Conditions',
	ExclusionsSetting: 'Exclusion Settings',
	SellingHierarchySettings: 'Selling Hierarchy Settings',
	InclusionsSettings: 'Inclusions Settings'
};
const keys = ['productID', 'trigger', 'getReward', 'minimumPrice', 'maximumPrice'];
const defaultValueForAddSH = {
	maximumPrice: '9999999.99',
	minimumPrice: '0.01',
	trigger: true,
	getReward: true
};

let ScaleMerchTableData = [];
const keysMerch = ['merchandizeGroupId', 'trigger', 'getReward', 'minimumPrice', 'maximumPrice'];

const defaultValueForAddMG = {
	maximumPrice: '9999999.99',
	minimumPrice: '0.01',
	trigger: true,
	getReward: true
};

const EligibilityForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [field] = useField(props);

	const onProductTableChange = (val) => {
		props.onProductTableChanged(val);
	};
	const onMerchTableChange = (val) => {
		ScaleMerchTableData = val;

		props.onMerchTableDataChange(ScaleMerchTableData);
	};

	const onMouseEnterHandler = (evt, mouseIsON) => {
		if (mouseIsON != props.curntMousePlace) {
			props.setMousePlace(mouseIsON);
		}
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = offerFlow.isAuditFlow;
	return (
		<div>
			<Form className={offerFlow.type == 'view-offer-flow' ? 'disabledEvent' : ''}>
				{/*  <Field name="merchandizeHierarchyEligibility" component={SellingHierarchy} /> */}
				<div name="PurchaseandRewardBlock" onMouseEnter={(e) => onMouseEnterHandler(e, '')}>
					<SellingHierarchy />
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToSellingHierarchySettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) =>
							onMouseEnterHandler(e, 'scrollToSellingHierarchySettings')
						}
					>
						<CollapseHeaderBar
							text={localConst.SellingHierarchySettings}
							headingId={'headingSH'}
							dataTarget={'#collapseSH'}
						/>
						<CollapseBody id="collapseSH" headingId={'headingSH'}>
							{/*   <Field name="SellingHierarchySettings" component={ProductSetting} /> */}
							{/*  <SellingHierarchyTable data={props.merdata} onmerchandizeTableChange={onmerchandizeTableChange} /> */}
							{isAuditFlw ? (
								<div className="container">
									<AuditSHTable
										oldJson={props.data1}
										newJson={
											JSON.parse(sessionStorage.getItem('NewPolicy'))
												.promotionPolicy.priceDerivationRuleEligibility
												.merchandizeHierarchyEligibility
												.merchandizeHierarchyGroup
										}
									/>
								</div>
							) : (
								<MerchTableBootstrap
									data={props.data1}
									keys={keysMerch}
									defaultValueForAdd={defaultValueForAddMG}
									onMerchTableChange={(val) => onMerchTableChange(val)}
									eligibility={'merchandizeHierarchyEligibility'}
									ScaleMerchTableData={ScaleMerchTableData}
									brandEligibility={
										JSON.parse(props.fullValue).promotionPolicy
											.promotionApplicableEligibility.brandEligibility
									}
								/>
							)}
						</CollapseBody>
					</Element>
				</div>
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToInclusionsSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToInclusionsSettings')}
					>
						<CollapseHeaderBar
							text={localConst.InclusionsSettings}
							headingId={'headingIS'}
							dataTarget={'#collapseIS'}
						/>
						<CollapseBody id="collapseIS" headingId={'headingIS'}>
							{/*  <Field name="inclusions" component={InclusionsType} /> */}
							<InclusionsType onProductTableChange={onProductTableChange} />
						</CollapseBody>
					</Element>
				</div>

				{field.value.InclusionsType == 'PRODUCT_INCLUSION_VALUE' || isAuditFlw ? (
					<div className="accordion" id="accordionExample">
						<Element
							name="scrollToProductSettings"
							class="card"
							style={{ border: 'none' }}
							onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToProductSettings')}
						>
							<CollapseHeaderBar
								text={
									isAuditFlw ? 'Included Product(s)' : localConst.ProductSetting
								}
								headingId={'headingThree'}
								dataTarget={'#collapseThree'}
							/>
							<CollapseBody id="collapseThree" headingId={'headingThree'}>
								{/*  <Field name="multibuyProduct" component={ProductSetting} /> */}
								{/*  <MultiBuyProductTable data={props.data} onProductTableChange={onProductTableChange} /> */}

								{isAuditFlw ? (
									<div className="container">
										<AuditProductTable
											oldJson={props.data}
											newJson={
												JSON.parse(sessionStorage.getItem('NewPolicy'))
													.promotionPolicy.priceDerivationRuleEligibility
													.merchandizeHierarchyEligibility.inclusions
													.includedItemIDList.multibuyProduct
											}
										/>
									</div>
								) : (
									<ProductTableBoostrap
										data={props.data}
										keys={keys}
										defaultValueForAdd={defaultValueForAddSH}
										onProductTableChange={onProductTableChange}
										eligibility={'MultiBuyGroup'}
										brandEligibility={
											JSON.parse(props.fullValue).promotionPolicy
												.promotionApplicableEligibility.brandEligibility
										}
									/>
								)}
							</CollapseBody>
						</Element>
					</div>
				) : null}
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToExclusionSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToExclusionSettings')}
					>
						<CollapseHeaderBar
							text={localConst.ExclusionsSetting}
							headingId={'headingES'}
							dataTarget={'#collapseES'}
						/>
						<CollapseBody id="collapseES" headingId={'headingES'}>
							<ExclusionSetting
								customName="merchandizeHierarchyEligibility"
								fullValue={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToPaymentConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToPaymentConditions')}
					>
						<CollapseHeaderBar
							text={localConst.PaymentConditions}
							headingId={'headingFour'}
							dataTarget={'#collapseFour'}
						/>
						<CollapseBody id="collapseFour" headingId={'headingFour'}>
							{/*  <Field name="paymentEligibility" component={PaymentCondition} /> */}
							<PaymentCondition
								customName="merchandizeHierarchyEligibility"
								fullVal={props.fullValue}
								loggedInBrand={props.loggedInBrand}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToCouponConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToCouponConditions')}
					>
						<CollapseHeaderBar
							text={localConst.CouponConditions}
							headingId={'headingFive'}
							dataTarget={'#collapseFive'}
						/>
						<CollapseBody id="collapseFive" headingId={'headingFive'}>
							<CouponCondition
								customName="merchandizeHierarchyEligibility"
								fullVal={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToBenefitSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToBenefitSettings')}
					>
						<CollapseHeaderBar
							text={localConst.benefitSettings}
							headingId={'headingTwo'}
							dataTarget={'#collapseTwo'}
						/>
						<CollapseBody id="collapseTwo" headingId={'headingTwo'}>
							<BenefitSettings
								{...props}
								customName="merchandizeHierarchyEligibility"
							/>
						</CollapseBody>
					</Element>
				</div>
			</Form>
		</div>
	);
};

/* const EligibilitySubForm = withSubForm(EligibilityForm, eligibilitySchema); */

//export default EligibilityForm;

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
