import './StyleText.css';
import React from 'react';
export const itemHelp = {
	SubTriggerCombinationCode:
		'Determines the combination code to be used between the trigger items for a promotion',
	SubRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	SubApplicableOn:
		'Determines if the promotion is applicable on the payment made through the tendered value or on the transaction condition',
	SubPaymentThresholdCode: 'Determines the type of threshold for the payment',
	SubPaymentThresholdAmount:
		'Determines the minimum threshold amount for the Payment Mode specified',
	SubPaymentMode: 'Defines the Payment eligibility Mode for which the promotion is applicable',
	SubEMIType: 'Defines the types of EMI',
	SubProvider: 'Defines the Payment eligibility Provider for which the promotion is applicable',
	SubPaymentType: 'Defines the Payment eligibility Type for which the promotion is applicable',
	SubPaymentSubType:
		'Defines the Payment eligibility SubType for which the promotion is applicable',
	SubCouponType: 'Determines if the coupon is Generic or personalized coupon',
	SubCouponThresholdQuantity:
		'Specifies number of coupons to be scanned to trigger promotion , by default it is 1 coupon per promotion',
	SubCouponID: 'Specifies the coupon ID',
	SubCouponExpiryMonths:
		'Specifies the period the coupon provided to the customer will be expired in months',
	SubCouponExpiryDays:
		'Specifies the period the coupon provided to the customer will be expired in days',
	SubCouponExpiryHours:
		'Specifies the period the coupon provided to the customer will be expired in hours',
	SubCouponExpiryDate: 'Specifies the period the coupon provided to the customer will be expired',
	SubRedemptionLimit: 'Determines the count of redemption limit of a coupon across transaction',
	SubPriceModificationPercent: 'Determines the benefit percent for the promotion',
	SubPriceModificationAmount: 'Determines the benefit amount for the promotion',
	SubNewPriceAmount: 'Determines the new price for the product/sku in the promotion',
	SubPriceModificationQuanity: 'Determines the benefit quantity for the promotion',
	SubCashBackCreditedByMonths:
		'Specifies the period the cashback credited to the customer will be expired in months',
	SubCashBackCreditedByDays:
		'Specifies the period the cashback credited to the customer will be expired in days',
	SubCashBackCreditedByHours:
		'Specifies the period the cashback credited to the customer will be expired in hours',
	SubCashBackCreditedByDate:
		'Specifies the period the cashback credited to the customer will be expired',
	SubCashBackExpireOnMonths:
		'Specifies the period the cashback expired to the customer will be expired in months',
	SubCashBackExpireOnDays:
		'Specifies the period the cashback expired to the customer will be expired in days',
	SubCashBackExpireOnHours:
		'Specifies the period the cashback expired to the customer will be expired in hours',
	SubCashBackExpireOnDate:
		'Specifies the period the cashback expired to the customer will be expired',
	SubLoyaltyAdditionalPoint: 'Determines the additional point as benefit for the promotion',
	SubLoyaltyMultiplier: 'Determines the additional point as benefit for the promotion',
	SubApplyBaseEarn: 'Determines whether the Base earn Point should be applied for the promotion',
	SubConsiderBaseRateForItem: 'Determines whether to Consider Base Rate For Item',
	SubLoyalityPointsExpireOnMonths:
		'Specifies the period the loyality points expired to the customer will be expired in months',
	SubLoyalityPointsExpireOnDays:
		'Specifies the period the loyality points expired to the customer will be expired in days',
	SubLoyalityPointsExpireOnHours:
		'Specifies the period the loyality points expired to the customer will be expired in hours',
	SubLoyalityPointsExpireOnDate:
		'Specifies the period the loyality points expired to the customer will be expired',

	promotionType: (
		<div className="item-help">
			<div className="item-description-title">
				Users will be able to define various promotion types :
			</div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description-text">
						Promotion configured for a single item :
					</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">Buy Dell Laptop and get 10% off</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">
						Promotion configured for a group of items :
					</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">
						Buy Pen or Pencil or Rubber or Notebook and get $15 off
					</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">
						Promotion configured for a Category/Selling Hierarchy :
					</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">
						Buy from Men's Apparel Section and get 10% off
					</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">
						Promotion configured for different groups :
					</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">
						Buy every 1 qty from Group 1(Teakwood Table/Rubber or Wood Table)and 3 qty
						from Group 2(Teakwood Normal Chairs or Teakwood Design Chairs)and get 10%
						off
					</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">Transaction Level :</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">Spend 5000 or more and get 10% off</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">Scale Item :</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">
						<ol className="offer-subcontent" type="a">
							<li>Buy 3 or more Socks and get 10% off</li>
							<li>Buy 5 or more Socks and get 12% off</li>
							<li>Buy 7 or more Socks and get 15% off</li>
						</ol>
					</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">Scale Transaction :</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">
						<ol type="a" className="offer-subcontent">
							<li>Spend 5000 or more and get 15% off</li>
							<li>Spend 8000 or more and get 20% off</li>
							<li>Spend 12000 or more and get 25% off</li>
						</ol>{' '}
					</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">
						MTMB : Multiple Benefits enabled for a Promotion
					</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">
						<ol type="a" className="offer-subcontent">
							<li>
								Buy 1 quantity from Lenovo Laptop and Get 1 quantity of (Bosch
								Headset/Lenovo Headset/Dell Headset ) for 10% off - AND Get 1
								quantity of (VIP Trolley Bag/Lenovo Laptop Bag/Dell Laptop Bag ) @
								Rs999
							</li>
							<li>
								Buy 1 quantity from Laptop Category and Get 2 quantities from Group
								Mouse Category at Rs 600 - OR Get 2 items from Group Pendrive
								Categroy for Amount off of Rs.500
							</li>
						</ol>{' '}
					</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">Scale Hierarchy :</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">
						<ol type="a" className="offer-subcontent">
							<li>Buy 3 or more from Accessories category and get 10% off</li>
							<li>Buy 5 or more Accessories category and get 17% off</li>
							<li>Buy 7 or more Accessories category and get 25% off</li>
						</ol>{' '}
					</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">
						Simple promotion : Different items with different benefit value in a Single
						Promotion
					</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">
						<ol type="a" className="offer-subcontent">
							<li>Buy Marker and get 15% off</li>
							<li>Buy Slate and get 20% off</li>
							<li>Buy Notebook and get 25% off</li>
						</ol>{' '}
					</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">Earn Burn Product</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">
						<ol type="a" className="offer-subcontent">
							<li>
								Buy Washing Machine and get Voucher worth Rs 1000 which can be used
								on next purchase of a Stabilizer
							</li>
						</ol>{' '}
					</span>
				</li>

				<li className="list-row">
					<div className="item-help-description-text">Earn Burn Selling Hierarchy</div>
					<span className="item-help-description-text">Example :</span>
					<span className="item-offer">
						<ol type="a" className="offer-subcontent">
							<li>
								Buy from Refrigerator Category and get Voucher worth Rs 500 which
								can be used on next purchase any item from the Kitchen Category
							</li>
						</ol>{' '}
					</span>
				</li>
			</ol>
		</div>
	),

	ThresholdTypeCodeMBG: (
		<div className="item-help-ttp">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>GROUP_INTERVAL_QTY_THRESHOLD</strong> - For Every quantity of
						item(s) purchased (Example : For every 2 quantity of notebook or pencil
						purchased )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>GROUP_QTY_THRESHOLD</strong> - At least X or more quantity of
						item(s) purchased (Example : Atleast 2 or more quantity of water bottles or
						lunch box purchased)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>GROUP_INTERVAL_AMOUNT_THRESHOLD </strong>- For Every amount of the
						item(s) purchased (Example : For every Rs 50 worth of notebook or pen or
						pencils purchased )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>GROUP_AMOUNT_THRESHOLD </strong>- At least X or more amount of the
						item(s) purchased (Example : At least Rs 100 or more worth of Box or stapler
						purchased )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>GROUP_RANGE_QTY_THRESHOLD</strong> - Between a Range of quantity of
						item(s) purchased (Example : Between 3 to 5 quantity of notebook or pencil
						purchased
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>GROUP_RANGE_AMOUNT_THRESHOLD</strong> - Between a Range of amount of
						the item(s) purchased (Example : Between Rs 100 to Rs 200 worth of sketch
						pens or crayons purchased)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>GROUP_LIMIT_QTY_THRESHOLD</strong> -Maximum quantity limit allowed
						for in that promotion
					</div>
				</li>
			</ol>
		</div>
	),

	BenifitTypeMBG: (
		<div className="item-help">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>Instant Discount</strong> - Benefit gets applied immediately in the
						transaction
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Cashback</strong> - Benefit gets applied post the transaction
						completion on the respective payment mode specified
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Loyalty Points</strong> - Benefit is rewarded as Loyalty Points post
						the transaction completion on the customer loyalty wallet
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Freebie</strong> - Benefit is rewarded as free item in the
						transaction
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Free Text</strong> - Benefit is rewarded post the transaction
						completion (i.e a Free Pencil Box set)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>EMI</strong> - Benefit is rewarded as EMI in the transaction
					</div>
				</li>
			</ol>
		</div>
	),

	BenifitTypeMTMB: (
		<div className="item-help">
			<div className="item-description-title"> </div>
			<div className="item-help-description">
				<strong>Instant Discount</strong> - Benefit gets applied immediately in the
				transaction
			</div>
		</div>
	),

	InstantPMMC_MBG: (
		<div className="item-help">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>Amount Off</strong> - Amount(Rs) off each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Apportioned Amount Off</strong> - Amount (Rs) apportioned among the
						total of reward items
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Percentage Off</strong> - Percentage (%) off each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Apportioned Percentage Off</strong> -Percentage (%) apportioned
						among the total of reward items
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Markdown</strong>- New price (Rs) for each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Kit Markdown</strong> - Combined new price (Rs) for the reward items
					</div>
				</li>
			</ol>
		</div>
	),

	InstantPMMC_MBG1: (
		<div className="item-help">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>Amount Off</strong> - Amount(Rs) off each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Apportioned Amount Off</strong> - Amount (Rs) apportioned among the
						total of reward items
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Percentage Off</strong> - Percentage (%) off each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Apportioned Percentage Off</strong> -Percentage (%) apportioned
						among the total of reward items
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Markdown</strong>- New price (Rs) for each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Kit Markdown</strong> - Combined new price (Rs) for the reward items
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Quantity pricing</strong> - Buy 3 for 2
					</div>
				</li>
			</ol>
		</div>
	),

	CashBackPMMC_MBG: (
		<div className="item-help">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						Amount Off - Amount(Rs) off each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						Apportioned Amount Off - Amount (Rs) apportioned among the total of reward
						items
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						Percentage Off - Percentage (%) off each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						Apportioned Percentage Off - Percentage (%) apportioned among the total of
						reward items
					</div>
				</li>
			</ol>
		</div>
	),

	loyaltyPMMC_MBG: (
		<div className="item-help">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>LOYALTY_ADDITIONAL_POINTS</strong>- Additional points off each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>LOYALTY_POINTS_MULTIPLIER</strong>- Multiplier points off each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS</strong>- Additional
						points apportioned off among the total of the reward items depending on the
						base points
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE</strong>
						-Additional points apportioned off among the total of the reward items
						depending on the unit price
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS</strong>- Multiplier points
						apportioned off among the total of the reward items depending on the base
						points
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE-Multiplier</strong>{' '}
						points apportioned off among the total of the reward items depending on the
						unit price
					</div>
				</li>
			</ol>
		</div>
	),

	FreeBiePMMC_MBG: (
		<div className="item-help">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>PERCENTAGE_OFF</strong> - Percentage (%) off each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>APPORTIONED_PERCENTAGE_OFF</strong> -Percentage (%) apportioned
						among the total of reward items
					</div>
				</li>
			</ol>
		</div>
	),

	EMI_PMMC_MBG: (
		<div className="item-help">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>EMI PERCENTAGE_OFF</strong> - Percentage (%) off each item
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>EMI</strong> APPORTIONED_PERCENTAGE_OFF -Percentage (%) apportioned
						among the total of reward items
					</div>
				</li>
			</ol>
		</div>
	),

	ThresholdTypeCodeMBP: (
		<div className="item-help-ttp">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>INTERVAL_QTY_THRESHOLD</strong> - For Every quantity of item(s)
						purchased (Example : For every 2 quantity of notebook purchased )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>QTY_THRESHOLD</strong> - At least X or more quantity of item(s)
						purchased (Example : At least 2 or more quantity of water bottles purchased)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>INTERVAL_AMOUNT_THRESHOLD</strong> - For Every amount of the item(s)
						purchased (Example : For every Rs 50 worth of notebook purchased )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>AMOUNT_THRESHOLD</strong> - At least X or more amount of the item(s)
						purchased (Example : At least Rs 100 or more worth of pens purchased )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>RANGE_QTY_THRESHOLD</strong> - Between a Range of quantity of
						item(s) purchased (Example : Between 3 to 5 quantity of pencils purchased
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>RANGE_AMOUNT_THRESHOLD</strong> - Between a Range of amount of the
						item(s) purchased (Example : Between Rs 100 to Rs 200 worth of sketch pens
						purchased)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>LIMIT_QTY_THRESHOLD</strong> -Maximum quantity limit allowed for in
						that promotion
					</div>
				</li>
			</ol>
		</div>
	),

	ThresholdTypeCodeTXN: (
		<div className="item-help-ttp">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>BASKET_VALUE_THRESHOLD</strong> -For Every amount of the item(s)
						purchased (Example : For every Rs 50 worth of notebook purchased )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>BASKET_INTERVAL_VALUE_THRESHOLD</strong> - At least X or more amount
						of the item(s) purchased (Example : At least Rs 100 or more worth of pens
						purchased )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>BASKET_INTERVAL_QTY_THRESHOLD</strong> - For Every quantity of
						item(s) purchased (Example : For every 2 quantity of notebook purchased )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>BASKET_QTY_THRESHOLD</strong> - At least X or more quantity of
						item(s) purchased (Example : At least 2 or more quantity of water bottles
						purchased)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>BASKET_RANGE_QTY_THRESHOLD</strong> - Between a Range of quantity of
						item(s) purchased (Example : Between 3 to 5 quantity of pencils purchased
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>BASKET_RANGE_VALUE_THRESHOLD</strong> - Between a Range of amount of
						the item(s) purchased (Example : Between Rs 100 to Rs 200 worth of sketch
						pens purchased)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>BASKET_LIMIT_QTY_THRESHOLD</strong> -Maximum quantity limit allowed
						for in that promotion
					</div>
				</li>
			</ol>
		</div>
	),

	ThresholdTypeCodeSelling: (
		<div className="item-help-ttp">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>SELLING_HIERARCHY_INTERVAL_QTY_THRESHOLD</strong> - For Every
						quantity of item(s) purchased (Example : For every 2 quantity purchased from
						Womens category )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>SELLING_HIERARCHY_QTY_THRESHOLD</strong> - At least X or more
						quantity of item(s) purchased (Example : At least 2 or more quantity
						purchased from Pants category)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>SELLING_HIERARCHY_INTERVAL_AMOUNT_THRESHOLD</strong> - For Every
						amount of the item(s) purchased (Example : For every Rs 500 worth purchased
						from Kids category)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>SELLING_HIERARCHY_AMOUNT_THRESHOLD</strong> - At least X or more
						amount of the item(s) purchased (Example : At least Rs 1000 or more worth
						purchased from Mens category )
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>SELLING_HIERARCHY_RANGE_QTY_THRESHOLD</strong> - Between a Range of
						quantity of item(s) purchased (Example : Between 3 to 5 quantity purchased
						from Kids Boys category
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>SELLING_HIERARCHY_RANGE_AMOUNT_THRESHOLD</strong> - Between a Range
						of amount of the item(s) purchased (Example : Between Rs 100 to Rs 200 worth
						purchased from Kids Girls category)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>SELLING_HIERARCHY_LIMIT_QTY_THRESHOLD</strong> -Maximum quantity
						limit allowed for in that promotion
					</div>
				</li>
			</ol>
		</div>
	),

	ThresholdTypeCodePkgProd: (
		<div className="item-help-ttp">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>Package Interval Quantity Threshold</strong>: For Every Quantity Of
						Item(s) Purchased Example: For every 2 quantity of item purchased from group
						A and/or Group B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Package Quantity Threshold</strong>: At Least X Or More Quantity Of
						Item(s) Purchased Example: At least 2 or more quantity of item purchased
						from group A and/or Group B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Package Interval Amount Threshold</strong>: For Every Amount Of The
						Item(s) Purchased Example: For every Rs 50 worth of item purchased from
						group A and/or Group B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Package Amount Threshold</strong>: At Least X Or More Amount Of The
						Item(s) Purchased Example: At least Rs 100 or more worth of Items purchased
						from Group A and/or Group B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Package Range Quantity Threshold</strong>: Between A Range Of
						Quantity Of Item(s) Purchased Example: Between 3 to 5 quantity of of Items
						purchased from Group A and/or Group B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Package Range Amount Threshold</strong>: Between a Range Of Amount
						Of The Item(s) Purchased Example: Between Rs 100 to Rs 200 worth of Items
						purchased from Group A and/or Group B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Package Limit Quantity Threshold</strong>: At Least X Or More
						Quantity Of Item(s) Purchased upto a limit of Y Item(s) Example: Buy 3 or
						more of Items purchased from Group A and/or Group B (and get reward on upto
						a limit of 10 items for the transaction)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Hierarchy Package Interval Quantity Threshold</strong>: For Every
						Quantity Of Item(s) Purchased Example: For every 2 quantity of item
						purchased from group A and/or every 3 quantity of item purchased from Group
						B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Hierarchy Package Quantity Threshold</strong>: At Least X Or More
						Quantity Of Item(s) Purchased Example: At least 2 or more quantity of item
						purchased from group A and/or 4 or more quantity of item purchased fromGroup
						B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Package Interval Amount Threshold</strong>: For Every Amount Of The
						Item(s) Purchased Example: For every Rs 50 worth of item purchased from
						group A and/or Rs 100 worth of item purchased fromGroup B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Package Amount Threshold</strong>: At Least X Or More Amount Of The
						Item(s) Purchased Example: At least Rs 100 or more worth of Items purchased
						from Group A and/or Rs 200 or more worth of Items purchased from Group B
					</div>
				</li>
			</ol>
		</div>
	),

	ThresholdTypeCodeEarnBurn: (
		<div className="item-help-ttp">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>EarnBurn Interval Quantity Threshold</strong>: For Every Quantity Of
						Item(s) Purchased Example: For every 2 quantity of item purchased from group
						A and/or Group B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>EarnBurn Quantity Threshold</strong>: At Least X Or More Quantity Of
						Item(s) Purchased Example: At least 2 or more quantity of item purchased
						from group A and/or Group B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>EarnBurn Interval Amount Threshold</strong>: For Every Amount Of The
						Item(s) Purchased Example: For every Rs 50 worth of item purchased from
						group A and/or Group B
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>EarnBurn Amount Threshold</strong>: At Least X Or More Amount Of The
						Item(s) Purchased Example: At least Rs 100 or more worth of Items purchased
						from Group A and/or Group B
					</div>
				</li>
			</ol>
		</div>
	),

	ThresholdTypeCodeScale: (
		<div className="item-help-ttp">
			<div className="item-description-title"> </div>
			<ol className="text-dark">
				<li className="list-row">
					<div className="item-help-description">
						<strong>Scale Interval Quantity Threshold</strong> - For Every Quantity Of
						Item(s) Purchased Example: Buy every 3 quantity of items from
						item(s)/group(s) get 10% off, Buy every 6 quantities get 20% off
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong> Scale Quantity Threshold</strong> - At Least X Or More Quantity Of
						Item(s) Purchased Example: Buy 3 or more quantity of items from
						item(s)/group(s) get 10% off, Buy 6 or more quantities get 20% off
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Scale Limit Quantity Threshold</strong> - At Least X Or More
						Quantity Of Item(s) Purchased upto a limit of Y Item(s) Example: Buy 3 or
						more quantity of items from item(s)/group(s) get 10% off, Buy 6 or more
						quantities get 20% off (and get reward on upto 10 quantities of item in the
						transaction)
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Scale Interval Amount Threshold</strong>: For Every Amount Of The
						Item(s) Purchased Example: Buy every Rs 300 of items from item(s)/group(s)
						get 10% off, Buy every Rs 600 get 20% off
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong>Scale Quantity and Amount Threshold</strong>: At Least X Or More
						Quantity Of Item(s) Purchased and for at least Y or more amount Example: Buy
						3 or more quantity and Rs 1000 or more of items from item(s)/group(s) get
						10% off, Buy 6 or more quantities and Rs 5000 or more get 20% off
					</div>
				</li>
				<li className="list-row">
					<div className="item-help-description">
						<strong> Scale Limit Quantity and Amount Threshold</strong>: At Least X Or
						More Quantity Of Item(s) and Y or more amount purchased upto a limit of Z
						Item(s) Example: Buy 3 or more quantity and Rs 1000 or more of items from
						item(s)/group(s) get 10% off, Buy 6 or more quantities and Rs 5000 or more
						get 20% off (and get reward on upto 20 quantities of item in the
						transaction)
					</div>
				</li>
			</ol>
		</div>
	),

	HappyHours: (
		<div className="item-help">
			<ol className="text-dark">
				<div className="list-row">
					<div className="item-help-description">
						Promotions applicable only during a specific block of time on specific
						day/date
					</div>
				</div>
				<div className="list-row">
					<div className="item-help-description">
						<strong>Example :</strong>Early bird promotion, that starts at 7:00 a.m. and
						ends at 9:00 a.m. on Thursday and Saturday.
					</div>
				</div>
			</ol>
		</div>
	),

	BenifitTypeEarnBurn: (
		<div className="item-help">
			<ol className="text-dark">
				<div className="list-row"></div>
				<div className="list-row">
					<div className="item-help-description">
						<strong>Earn Burn: </strong>User gets a voucher/gift card against the
						benefit which can be used in future transactions
					</div>
				</div>
			</ol>
		</div>
	)

	/*   BenifitTypeMBG: (
    <div class="item-help">
      <div class="item-description-title">
        Specifies the various Benefit Type Supported{" "}
      </div>
      <ol class="text-dark">
        <li class="list-row">
          <div class="item-help-description">
            <strong>Instant Discount</strong>: Benefit Gets Applied Immediately
            in the Transaction
          </div>
        </li>
        <li class="list-row">
          <div class="item-help-description">
            <strong> Cashback</strong>: Benefit gets applied post the
            Transaction completion on the respective payment mode specified
          </div>
        </li>
        <li class="list-row">
          <div class="item-help-description">
            <strong> Loyalty Points</strong>: Benefit is rewarded as loyalty
            points post the Transaction completion on the Customer Loyalty
            Wallet
          </div>
        </li>
        <li class="list-row">
          <div class="item-help-description">
            <strong>Freebie</strong>: Benefit is rewarded as Free Item in the
            Transaction
          </div>
        </li>
      </ol>
    </div>
  ), */
};
