/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from 'react';
import AuditChannel from '../../screens/OffterMaintainance/components/Audit/AuditChannel';

const BulkUploadCompare = ({ ...props }) => {
	const [oldPolicy] = useState(JSON.parse(props.oldJson));
	const [newPolicy] = useState(JSON.parse(props.newJson));

	let eligibilityName;
	if (oldPolicy.promotionPolicy.promotionType == 'MULTIBUYGROUP') {
		eligibilityName = 'groupMultibuyEligbility';
	} else if (oldPolicy.promotionPolicy.promotionType == 'MULTIBUYPACKAGE') {
		eligibilityName = 'packageMultibuyEligibility';
	}
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<>
			<div
				className={
					oldPolicy.promotionPolicy.effectiveStartDate !==
						newPolicy.promotionPolicy.effectiveStartDate ||
					oldPolicy.promotionPolicy.effectiveEndDate !==
						newPolicy.promotionPolicy.effectiveEndDate ||
					JSON.stringify(
						oldPolicy.promotionPolicy.promotionApplicableEligibility.channelEligibility
							.channel
					) !=
						JSON.stringify(
							newPolicy.promotionPolicy.promotionApplicableEligibility
								.channelEligibility.channel
						)
						? 'main-container create-offer-container'
						: 'd-none'
				}
			>
				<div className="flex-mainContent">
					<div id="containerElement" className="right-content">
						<form>
							<div>
								<div className="item-title">Basic Details</div>
								<div className="container">
									<div className="card-head" id="headingTwo">
										<h2
											className="mb-0"
											data-target="#collapseTwo"
											aria-expanded="true"
											aria-controls="collapseTwo"
										>
											<div className="item-before">Promotion Type</div>
										</h2>
									</div>
									{oldPolicy.promotionPolicy.effectiveStartDate !==
									newPolicy.promotionPolicy.effectiveStartDate ? (
										<>
											<div className="container">
												<div className="rowContainer">
													<div className="customLabelContainer">
														<label
															htmlFor="forprogramID"
															className="col	col-form-label"
														>
															Start Date
															<span className="requiredRed">*</span>
														</label>
													</div>
													<div className="customInputContainer dateTime">
														<div className="row">
															<div>
																<div className="d-flex gap70 replacedText">
																	<input
																		type="text"
																		className="form-control lightGray"
																		value={
																			oldPolicy
																				.promotionPolicy
																				.effectiveStartDate
																		}
																	/>
																	<input
																		type="text"
																		className="form-control lightBlue"
																		value={
																			newPolicy
																				.promotionPolicy
																				.effectiveStartDate
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									) : null}
									{oldPolicy.promotionPolicy.effectiveEndDate !==
									newPolicy.promotionPolicy.effectiveEndDate ? (
										<>
											<div className="container">
												<div className="rowContainer">
													<div className="customLabelContainer">
														<label
															htmlFor="forprogramID"
															className="col	col-form-label"
														>
															End Date
															<span className="requiredRed">*</span>
														</label>
													</div>
													<div className="customInputContainer dateTime">
														<div className="row">
															<div>
																<div className="d-flex gap70 replacedText">
																	<input
																		type="text"
																		className="form-control lightGray"
																		value={
																			oldPolicy
																				.promotionPolicy
																				.effectiveEndDate
																		}
																	/>
																	<input
																		type="text"
																		className="form-control lightBlue"
																		value={
																			newPolicy
																				.promotionPolicy
																				.effectiveEndDate
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									) : null}
								</div>
								{/*ApplicableEligibilty*/}
								{JSON.stringify(
									oldPolicy.promotionPolicy.promotionApplicableEligibility
										.channelEligibility.channel
								) !=
								JSON.stringify(
									newPolicy.promotionPolicy.promotionApplicableEligibility
										.channelEligibility.channel
								) ? (
									<>
										<div className="item-title">Applicable Eligibility</div>
										<div className="container">
											<div
												name="scrollToChannelEligibility"
												className="card"
												style={{ border: 'none' }}
											>
												<div className="card-head" id="headingFive">
													<h2 className="mb-0">
														<div
															className="item-before"
															style={{ border: 'none' }}
														>
															Channel Eligibility
														</div>
													</h2>
												</div>
												<div id="collapse8" className="collapse">
													<div className="card-body">
														<AuditChannel
															oldJson={
																oldPolicy.promotionPolicy
																	.promotionApplicableEligibility
																	.channelEligibility.channel
															}
															newJson={newPolicy}
														/>
													</div>
												</div>
											</div>
										</div>
									</>
								) : null}
							</div>
						</form>
					</div>
				</div>
			</div>

			<div
				className={
					oldPolicy.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
						.coupons.displayCoupons !==
					newPolicy.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
						.coupons.displayCoupons
						? 'main-container create-offer-container'
						: 'd-none'
				}
			>
				<div className="flex-mainContent">
					<div id="containerElement" className="right-content">
						<form>
							<div>
								<div className="item-title">Eligibility Settings</div>
								<div className="container">
									<div className="card-head" id="headingTwo">
										<h2
											className="mb-0"
											data-target="#collapseTwo"
											aria-expanded="true"
											aria-controls="collapseTwo"
										>
											<div className="item-before">Coupon Conditions</div>
										</h2>
									</div>

									{oldPolicy.promotionPolicy.priceDerivationRuleEligibility[
										eligibilityName
									].coupons.displayCoupons !==
									newPolicy.promotionPolicy.priceDerivationRuleEligibility[
										[eligibilityName]
									].coupons.displayCoupons ? (
										<>
											<div className="container">
												<div className="rowContainer">
													<div className="customLabelContainer">
														<label
															htmlFor="forprogramID"
															className="col	col-form-label"
														>
															Allow Coupon To Be Displayed
															<span className="requiredRed">*</span>
														</label>
													</div>
													<div className="customInputContainer dateTime">
														<div className="row">
															<div>
																<div className="d-flex gap70 replacedText">
																	<input
																		type="text"
																		className="form-control lightGray"
																		value={
																			oldPolicy
																				.promotionPolicy
																				.priceDerivationRuleEligibility[
																				eligibilityName
																			].coupons.displayCoupons
																		}
																	/>
																	<input
																		type="text"
																		className="form-control lightBlue"
																		value={
																			newPolicy
																				.promotionPolicy
																				.priceDerivationRuleEligibility[
																				eligibilityName
																			].coupons.displayCoupons
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									) : null}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default BulkUploadCompare;
