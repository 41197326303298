import React, { useEffect } from 'react';

import { useField } from 'formik';
import CustomInputText from '../customfields/CustomInputText';
import CustomSelect from '../customfields/CustomSelect';

function ConditionalRederingPKG(props) {
	const { value } = props;
	//PackageMultibuyEligibility[index].mtmbthresholdTypeCode
	const [field] = useField(props);
	const fieldCR = field.value.earnBurnEligibility;

	const localConst = {
		eligibilitySettings: 'Eligibility Settings',
		thresholdCode: 'Threshold Type Code',
		triggerCombinationCode: 'Trigger Combination Code',
		subTriggerCombinationCode:
			'Specifies the combination code to be used between the trigger items for a promotion',
		thresholdQuantity: 'Group Threshold Quantity',
		thresholdAmount: 'Group Threshold Amount',
		rewardCombinationCode: 'Reward Combination Code',
		subRewardCombinationCode:
			'Specifies the combination code to be used between the trigger items for a promotion',
		rewardQuantity: 'Group Reward Quantity',
		benefitSettings: 'Benefit Settings',
		ProductSetting: 'Product Settings',
		quantityLimit: 'Quantity Limit',
		groupMaximumThresholdAmount: 'Group Maximum Threshold Amount',
		groupMaximumThresholdQuantity: 'Group Maximum Threshold Quantity'
	};

	const rewardCombinationDropdown = {
		promotionTypeOr: [
			{
				value: 'OR',
				name: 'OR'
			}
		],
		promotionTypeOrAnd: [
			{
				value: 'OR',
				name: 'OR'
			},
			{
				value: 'AND',
				name: 'AND'
			}
		],
		operator: [
			{
				value: '',
				name: 'Select one'
			},
			{
				value: '<',
				name: '<'
			},
			{
				value: '>',
				name: '>'
			},
			{
				value: '<=',
				name: '<='
			},
			{
				value: '>=',
				name: '>='
			},
			{
				value: '=',
				name: '='
			},
			{
				value: 'BETWEEN',
				name: 'BETWEEN'
			}
		]
	};

	const getThresholdDropdown = (thresholdTypeCodeOptions) => {
		return thresholdTypeCodeOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	useEffect(() => {
		if (!fieldCR.groupTriggerCombinationCode) {
			fieldCR.groupTriggerCombinationCode = 'OR';
		}
		if (!fieldCR.groupRewardCombinationCode) {
			fieldCR.groupRewardCombinationCode = 'OR';
		}

		if (
			value == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
			value == 'EARN_BURN_QTY_THRESHOLD' ||
			value == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
			value == 'EARN_BURN_AMOUNT_THRESHOLD'
		) {
			if (!fieldCR.group) {
				fieldCR.group = [
					{
						multibuyProduct: [],
						trigger: true,
						getReward: true
					}
				];
			}
		}
	}, [value]);

	//newly added
	useEffect(() => {
		if (
			fieldCR.triggerOperator == '<' ||
			fieldCR.triggerOperator == '>' ||
			fieldCR.triggerOperator == '<=' ||
			fieldCR.triggerOperator == '>=' ||
			fieldCR.triggerOperator == '='
		) {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
		} else if (fieldCR.triggerOperator == 'BETWEEN') {
			fieldCR.triggerOperatorValue = undefined;
		} else {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
			fieldCR.triggerOperatorValue = undefined;
		}
	}, [fieldCR.triggerOperator]);

	useEffect(() => {
		if (
			fieldCR.rewardOperator == '<' ||
			fieldCR.rewardOperator == '>' ||
			fieldCR.rewardOperator == '<=' ||
			fieldCR.rewardOperator == '>=' ||
			fieldCR.rewardOperator == '='
		) {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
		} else if (fieldCR.rewardOperator == 'BETWEEN') {
			fieldCR.rewardOperatorValue = undefined;
		} else {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
			fieldCR.rewardOperatorValue = undefined;
		}
	}, [fieldCR.rewardOperator]);

	return (
		<div className="purchaseContainer">
			<div className="purchaseRow">
				<div className="font-weight-bold">Purchase Conditions</div>
				<CustomSelect
					name={`earnBurnEligibility.groupTriggerCombinationCode`}
					required={true}
					label={localConst.triggerCombinationCode}
					options={
						value == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
						value == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD'
							? getThresholdDropdown(rewardCombinationDropdown.promotionTypeOrAnd)
							: getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)
					}
					smallText={localConst.subTriggerCombinationCode}
				/>

				<CustomSelect
					name="earnBurnEligibility.triggerOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{fieldCR.triggerOperator == '<' ||
				fieldCR.triggerOperator == '>' ||
				fieldCR.triggerOperator == '<=' ||
				fieldCR.triggerOperator == '>=' ||
				fieldCR.triggerOperator == '=' ? (
					<CustomInputText
						name="earnBurnEligibility.triggerOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{fieldCR.triggerOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="earnBurnEligibility.triggerOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="earnBurnEligibility.triggerOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>

			<div className="purchaseRow">
				<div className="font-weight-bold">Reward Conditions</div>
				<CustomSelect
					name={`earnBurnEligibility.groupRewardCombinationCode`}
					required={true}
					label={localConst.rewardCombinationCode}
					options={
						value == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
						value == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD'
							? getThresholdDropdown(rewardCombinationDropdown.promotionTypeOrAnd)
							: getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)
					}
					smallText={localConst.subTriggerCombinationCode}
				/>
				<CustomSelect
					name="earnBurnEligibility.rewardOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{fieldCR.rewardOperator == '<' ||
				fieldCR.rewardOperator == '>' ||
				fieldCR.rewardOperator == '<=' ||
				fieldCR.rewardOperator == '>=' ||
				fieldCR.rewardOperator == '=' ? (
					<CustomInputText
						name="earnBurnEligibility.rewardOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{fieldCR.rewardOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="earnBurnEligibility.rewardOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="earnBurnEligibility.rewardOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>
		</div>
	);
}
export default ConditionalRederingPKG;
