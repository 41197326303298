import React, { useEffect, useState } from 'react';
import { FieldArray, Field, ErrorMessage, useField, useFormikContext } from 'formik';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import CustomInputText from './customfields/CustomInputText';
import CustomSelect from './customfields/CustomSelect';
import CustomDateTime from './customfields/CustomDateTime';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import CustomReactMultiSelectComponent from './customfields/CustomReactMultiSelectComponent';
import moment from 'moment';

const localConst = {
	applicableOn: 'Applicable on',
	subApplicableOn:
		'Determines if the promotion is applicable on the payment made through the tendered value or on the transaction condition',
	paymentThresholdCode: 'Payment Threshold Code',
	subPaymentThresholdCode: 'Determines the type of threshold for the payment',
	paymentThresholdAmount: 'Payment Threshold Amount',
	subPaymentThresholdAmount:
		'Determines the minimum threshold amount for the Payment Mode specified',
	paymentMode: 'Payment Mode',
	subPaymentMode: 'Defines the Payment eligibility Mode for which the promotion is applicable',
	emiType: 'EMI Type',
	subEmiType: 'Defines the types of EMI',
	provider: 'Provider',
	subProvider: 'Defines the Payment eligibility Provider for which the promotion is applicable',
	paymentType: 'Payment Type',
	subpaymentType: 'Defines the Payment eligibility Type for which the promotion is applicable',
	paymentSubType: 'Payment Sub Type',
	subPaymentSubType:
		'Defines the Payment eligibility SubType for which the promotion is applicable',
	coolOffPeriod: 'Cool Off Period'
};
const dropdownValues = {
	applicableOn: [
		{
			value: '',
			name: 'Select'
		},
		{
			value: 'TENDER_VALUE',
			name: 'Tender Value'
		},
		{
			value: 'TRANSACTION_VALUE',
			name: 'Transaction Value'
		}
	],
	paymentThreshold: [
		{
			value: '',
			name: 'Select'
		},
		{
			value: 'PAYMENT_AMOUNT_THRESHOLD',
			name: 'Payment Amount Threshold'
		}
	],
	emi: [
		{
			value: '',
			name: 'Select'
		},
		{
			value: 'NO_COST_EMI',
			name: 'NO_COST_EMI'
		}
	],
	coolOff: [
		{
			value: '',
			name: 'Select'
		},
		// {
		//   value: "Date",
		//   name: "Date",
		// },
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Years',
			name: 'Years'
		}
	],
	collectionValidity: [
		{ value: '', name: 'Select one' },
		{
			value: 'Date',
			name: 'Date'
		},
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Hours',
			name: 'Hours'
		}
	]
};

const getApplicableOnDropdown = () => {
	return dropdownValues.applicableOn.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};
const getThresholdDropdown = () => {
	return dropdownValues.paymentThreshold.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const getCollectionValidityDropdown = () => {
	return dropdownValues.collectionValidity.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};
const PaymentConditionForm = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	// useValues(name, props);
	const [field] = useField(props);
	const fieldPayment = field.value.mtmbEligibility.paymentEligibility;
	const { setFieldValue } = useFormikContext();
	const [singlePayment, setSinglePayment] = useState(!fieldPayment?.payment?.paymentGroup);
	const customValidateIssuerPaymentMode = (value) => {
		let error;
		if (fieldPayment.payment.emiType) {
			if (!value) {
				error = 'Please define issuer payment mode';
			}
		}
		return error;
	};
	const customValidateIssuerPaymentType = (value) => {
		let error;
		if (fieldPayment.payment.emiType) {
			if (!value) {
				error = 'Please define issuer payment type';
			}
		}
		return error;
	};
	const customValidateIssuerPaymentSubType = (value) => {
		let error;
		if (fieldPayment.payment.emiType) {
			if (!value) {
				error = 'Please define issuer payment subtype';
			}
		}
		return error;
	};
	const customValidateIssuerPaymentProvider = (value) => {
		let error;
		if (fieldPayment.payment.emiType) {
			if (!value) {
				error = 'Please define issuer payment provider';
			}
		}
		return error;
	};
	const customValidateCashBackCreditByMonths = (value) => {
		let error;
		if (value) {
			/*  if(!value.match(/^(?=.*[1-9])\d{0,3}$/)){
       error="Please enter a value greater than 1 to 999."
     } */
		} else {
			error = 'Please define the months value';
		}
		return error;
	};

	const customValidateCashBackCreditByDays = (value) => {
		let error;
		if (value) {
			/* if(!value.match(/^(?=.*[1-9])\d{0,3}$/)){
      error="Please enter a value greater than 1 to 999."
    } */
		} else {
			error = 'Please define the days value';
		}
		return error;
	};

	const customValidateCashBackCreditByHours = (value) => {
		let error;
		if (!value) {
			error = 'Please define the hours value';
		}
		return error;
	};
	const customValidateCashBackCreditByDate = (value) => {
		let error;
		//debugger
		if (value) {
			if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveStartDate) > moment(value)
			) {
				error = 'Entered date should not be less than promotion start date';
			} else if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate) < moment(value)
			) {
				error = 'Entered date should not be more than promotion end date';
			}
		} else {
			error = 'Please define the date value';
		}
		return error;
	};

	const customValidateCashBackExpireOnDate = (value) => {
		let error;
		//debugger
		if (value) {
			// if (moment(fieldPayment?.payment?.paymentGroup[index]?.issuers[i]?.cashbackInformation?.creditBy?.date) > moment(value)) {
			//   error = "Entered date should not be less than credited by date"
			// } else
			if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveStartDate) > moment(value)
			) {
				error = 'Entered date should not be less than promotion start date';
			} else if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate) < moment(value)
			) {
				error = 'Entered date should not be more than promotion end date';
			}
		} else {
			error = 'Please define the date value';
		}
		return error;
	};

	useEffect(() => {
		if (fieldPayment.applicableOn && !fieldPayment.payment) {
			fieldPayment.payment = { paymentThresholdAmount: '0.01' };
			if (!singlePayment && !fieldPayment.payment.paymentGroup) {
				fieldPayment.payment.paymentGroup = [
					{
						aggregators: [],
						issuers: [
							{
								issuerPaymentMode: '',
								issuerPaymentType: '',
								issuerPaymentSubType: '',
								issuerProvider: '',
								cashbackInformation: {
									isCashbackSame: true,
									paymentInformation: {
										paymentMode: '',
										provider: '',
										paymentSubType: '',
										paymentType: ''
									},
									creditBy: {},
									expireOn: {}
								}
							}
						]
					}
				];
			}
			setFieldValue('', '');
		}
		if (fieldPayment.applicableOn == '' || fieldPayment.applicableOn == undefined) {
			fieldPayment.paymentThresholdTypeCode = undefined;
			setFieldValue('', '');
		} else if (fieldPayment.applicableOn && !fieldPayment.paymentThresholdTypeCode) {
			fieldPayment.paymentThresholdTypeCode = 'PAYMENT_AMOUNT_THRESHOLD';
			setFieldValue('', '');
		}
		if (
			fieldPayment.payment &&
			fieldPayment.payment.paymentInformation &&
			fieldPayment.payment.paymentInformation.emiType == 'NO_COST_EMI'
		) {
			if (fieldPayment.payment.emi == undefined) {
				fieldPayment.payment.emi = { emiScale: [] };
				fieldPayment.payment.emi.emiScale = [
					{
						tenurePeriodInMonths: '',
						interestRate: ''
					}
				];
			} else if (fieldPayment.payment.emi.emiScale == undefined) {
				fieldPayment.payment.emi.emiScale = [
					{
						tenurePeriodInMonths: '',
						interestRate: ''
					}
				];
			}
		}
		if (
			fieldPayment.payment &&
			fieldPayment.payment.paymentInformation &&
			fieldPayment.payment.paymentInformation.emiType != 'NO_COST_EMI' &&
			fieldPayment.payment.emi &&
			fieldPayment.payment.emi.emiScale != undefined
		) {
			if (fieldPayment.payment.emi.coolOffPeriod != undefined) {
				fieldPayment.payment.emi.emiScale = undefined;
			} else {
				fieldPayment.payment.emi = undefined;
			}
		}
	}, [fieldPayment]);
	useEffect(() => {
		if (singlePayment) {
			if (fieldPayment.payment && fieldPayment.payment.paymentGroup) {
				fieldPayment.payment.paymentGroup = undefined;
			}
		} else {
			if (!fieldPayment.payment) {
				fieldPayment.payment = {};
			}
			if (fieldPayment.payment.paymentInformation) {
				fieldPayment.payment.paymentInformation = undefined;
			}
			if (!fieldPayment.payment.paymentGroup) {
				fieldPayment.payment.paymentGroup = [
					{
						aggregators: [],
						issuers: [
							{
								issuerPaymentMode: '',
								issuerPaymentType: '',
								issuerPaymentSubType: '',
								issuerProvider: '',
								cashbackInformation: {
									isCashbackSame: true,
									paymentInformation: {
										paymentMode: '',
										provider: '',
										paymentSubType: '',
										paymentType: ''
									},
									creditBy: {},
									expireOn: {}
								}
							}
						]
					}
				];
			}
		}
		setFieldValue('', '');
	}, [singlePayment]);

	return (
		<div className="main">
			<div className="container d-none">
				<div className="rowContainer">
					<div className="customLabelContainer">
						<label htmlFor="forprogramID" className="col	col-form-label">
							Payment Type<span className="requiredRed">*</span>
						</label>
					</div>
					<div className="customInputContainer">
						<div className="row mg-5">
							<input
								type="radio"
								value={singlePayment}
								checked={singlePayment}
								onChange={() => {
									setSinglePayment(true);
								}}
							/>
							Single Payment
							<input
								type="radio"
								className="radioMargin"
								value={!singlePayment}
								checked={!singlePayment}
								onChange={() => {
									setSinglePayment(false);
								}}
							/>
							MultiPayment
						</div>
					</div>
				</div>
			</div>
			<CustomSelect
				name="mtmbEligibility.paymentEligibility.applicableOn"
				label={localConst.applicableOn}
				options={getApplicableOnDropdown()}
				smallText="Determines if the promotion is applicable on the payment made through the tendered value or on the transaction condition"
			/>

			{fieldPayment.applicableOn &&
			(fieldPayment.applicableOn == 'TENDER_VALUE' ||
				fieldPayment.applicableOn == 'TRANSACTION_VALUE') ? (
				<>
					<CustomSelect
						name="mtmbEligibility.paymentEligibility.paymentThresholdTypeCode"
						required={true}
						label={localConst.paymentThresholdCode}
						options={getThresholdDropdown()}
						smallText="Determines the type of threshold for the payment"
					/>
					<CustomInputText
						name="mtmbEligibility.paymentEligibility.payment.paymentThresholdAmount"
						required={true}
						label={localConst.paymentThresholdAmount}
						smallText="Determines the minimum threshold amount for the Payment Mode specified"
					/>
					{singlePayment ? (
						<>
							<CustomInputText
								name="mtmbEligibility.paymentEligibility.payment.paymentInformation.paymentMode"
								required={false}
								label={localConst.paymentMode}
								smallText="Defines the Payment eligibility Mode for which the promotion is applicable"
							/>
							{/* <CustomSelect
            name="payment.paymentInformation.emiType"
            label={localConst.emiType}
            options={getEmiDropdown()}
            smallText="Defines the types of EMI"
          /> */}
							<CustomInputText
								name="mtmbEligibility.paymentEligibility.payment.paymentInformation.provider"
								required={false}
								label={localConst.provider}
								smallText="Defines the Payment eligibility Provider for which the promotion is applicable"
							/>
							<CustomInputText
								name="mtmbEligibility.paymentEligibility.payment.paymentInformation.paymentType"
								required={false}
								label={localConst.paymentType}
								smallText="Defines the Payment eligibility Type for which the promotion is applicable"
							/>
							<CustomInputText
								name="mtmbEligibility.paymentEligibility.payment.paymentInformation.paymentSubType"
								required={false}
								label={localConst.paymentSubType}
								smallText="Defines the Payment eligibility SubType for which the promotion is applicable"
							/>
							{fieldPayment.payment &&
							fieldPayment.payment.paymentInformation &&
							fieldPayment.payment.paymentInformation.emiType == 'NO_COST_EMI' ? (
								//Table
								<table>
									<tr>
										<th>Tenure Period In Months</th>
										<th>Interest Rate</th>
										<th>
											<button
												className="addPlusBtn"
												onClick={() => {
													fieldPayment.payment.emi &&
														fieldPayment.payment.emi.emiScale.push({
															tenurePeriodInMonths: '',
															interestRate: ''
														});
												}}
											></button>
										</th>
									</tr>

									<FieldArray name="mtmbEligibility.paymentEligibility.payment.emi.emiScale">
										{({ remove }) => (
											<>
												{fieldPayment.payment.emi &&
													fieldPayment.payment.emi.emiScale &&
													fieldPayment.payment.emi.emiScale.map(
														(item, index) => (
															<tr
																key={index}
																className="payment-Conditions"
															>
																<td>
																	<div className="col-md-6">
																		{/* <label htmlFor={`multibuyProduct.${index}.productID`}>Name</label> */}
																		{/* <CustomInputText `payment.emi.emiScale.${index}.tenurePeriodInMonths`} required={false} label=""
                                                            /> */}
																		<Field
																			className="form-control"
																			name={`mtmbEligibility.paymentEligibility.payment.emi.emiScale.${index}.tenurePeriodInMonths`}
																			type="text"
																		/>
																		<ErrorMessage
																			name={`mtmbEligibility.paymentEligibility.payment.emi.emiScale.${index}.tenurePeriodInMonths`}
																			component="div"
																			className="field-error"
																		/>
																	</div>
																</td>

																<td>
																	{/* <CustomInputText name={`payment.emi.emiScale.${index}.interestRate`} required={false} label=""
                                                        /> */}
																	<div className="col-md-6">
																		<Field
																			className="form-control"
																			name={`mtmbEligibility.paymentEligibility.payment.emi.emiScale.${index}.interestRate`}
																			type="text"
																		/>
																		<ErrorMessage
																			name={`mtmbEligibility.paymentEligibility.payment.emi.emiScale.${index}.interestRate`}
																			component="div"
																			className="field-error"
																		/>
																	</div>
																</td>
																<td>
																	<div className="col">
																		<button
																			type="button"
																			// className="secondary"
																			className="addDeleteBtn"
																			onClick={() =>
																				remove(index)
																			}
																		></button>
																	</div>
																</td>
															</tr>
														)
													)}
											</>
										)}
									</FieldArray>
								</table>
							) : fieldPayment.payment ? (
								fieldPayment.payment.emi ? (
									(fieldPayment.payment.emi.emiScale = undefined)
								) : null
							) : null}

							{/* <CustomSelect
            name="payment.emi.coolOffPeriod.coolOffPeriodType"
            label={localConst.coolOffPeriod}
            options={getCoolOffDropdown()}
          /> */}
							{fieldPayment.payment &&
							fieldPayment.payment.emi &&
							fieldPayment.payment.emi.coolOffPeriod &&
							fieldPayment.payment.emi.coolOffPeriod.coolOffPeriodType == 'Date' ? (
								<>
									<CustomDateTime
										name="mtmbEligibility.paymentEligibility.payment.emi.coolOffPeriod.date"
										label="Date(yyyy-mm-dd)"
									/>
								</>
							) : fieldPayment.payment ? (
								fieldPayment.payment.emi ? (
									fieldPayment.payment.emi.coolOffPeriod ? (
										(fieldPayment.payment.emi.coolOffPeriod.date = undefined)
									) : null
								) : null
							) : null}

							{fieldPayment.payment &&
							fieldPayment.payment.emi &&
							fieldPayment.payment.emi.coolOffPeriod &&
							fieldPayment.payment.emi.coolOffPeriod.coolOffPeriodType == 'Months' ? (
								<>
									<CustomInputText
										name="mtmbEligibility.paymentEligibility.payment.emi.coolOffPeriod.months"
										required={true}
										label="Months"
									/>
								</>
							) : fieldPayment.payment ? (
								fieldPayment.payment.emi ? (
									fieldPayment.payment.emi.coolOffPeriod ? (
										(fieldPayment.payment.emi.coolOffPeriod.months = undefined)
									) : null
								) : null
							) : null}

							{fieldPayment.payment &&
							fieldPayment.payment.emi &&
							fieldPayment.payment.emi.coolOffPeriod &&
							fieldPayment.payment.emi.coolOffPeriod.coolOffPeriodType == 'Days' ? (
								<>
									<CustomInputText
										name="mtmbEligibility.paymentEligibility.payment.emi.coolOffPeriod.days"
										required={true}
										label="Days"
									/>
								</>
							) : fieldPayment.payment ? (
								fieldPayment.payment.emi ? (
									fieldPayment.payment.emi.coolOffPeriod ? (
										(fieldPayment.payment.emi.coolOffPeriod.days = undefined)
									) : null
								) : null
							) : null}

							{fieldPayment.payment &&
							fieldPayment.payment.emi &&
							fieldPayment.payment.emi.coolOffPeriod &&
							fieldPayment.payment.emi.coolOffPeriod.coolOffPeriodType == 'Years' ? (
								<>
									<CustomInputText
										name="mtmbEligibility.paymentEligibility.payment.emi.coolOffPeriod.years"
										required={true}
										label="Years"
									/>
								</>
							) : fieldPayment.payment ? (
								fieldPayment.payment.emi ? (
									fieldPayment.payment.emi.coolOffPeriod ? (
										(fieldPayment.payment.emi.coolOffPeriod.years = undefined)
									) : null
								) : null
							) : null}

							{fieldPayment.payment &&
							fieldPayment.payment.emi &&
							fieldPayment.payment.emi.coolOffPeriod &&
							fieldPayment.payment.emi.coolOffPeriod.coolOffPeriodType == 'Hours' ? (
								<>
									<CustomHoursPicker
										name="mtmbEligibility.paymentEligibility.payment.emi.coolOffPeriod.hours"
										required={true}
										label="Hours(HH:mm)"
										valueHour={fieldPayment.payment.emi.coolOffPeriod.hours}
									/>
								</>
							) : fieldPayment.payment ? (
								fieldPayment.payment.emi ? (
									fieldPayment.payment.emi.coolOffPeriod ? (
										(fieldPayment.payment.emi.coolOffPeriod.hours = undefined)
									) : null
								) : null
							) : null}
						</>
					) : (
						<>
							{/* <div className="row">
            <div className="col-md-4 font-weight-bold">
            Aggregators and Issuers
            </div>
            <div className="col-md-7"></div>
            <div className="col-md-1">
            <button class="addPlusBtn" type="button" onClick={()=>{
              if(!fieldPayment.payment.paymentGroup){
                fieldPayment.payment.paymentGroup=[];
              }
              
              fieldPayment.payment.paymentGroup.push(
                  {
                    "aggregators":[],
                    "issuers":[{
                      "issuerPaymentMode":"",
                      "issuerPaymentType":"",
                      "issuerPaymentSubType":"",
                      "issuerProvider":"",
                      "cashbackInformation":{
                        "isCashbackSame":true,
                                        "paymentInformation":{
                                           "paymentMode":"",
                                           "provider":"",
                                           "paymentSubType":"",
                                           "paymentType":""
                                        },
                                        "creditBy":{
                                         
                                        },
                                        "expireOn":{
                                           
                                        }
                                     }
                   }],
                });
              
            
              setFieldValue("", "");
          }}></button>
            </div>
          </div> */}

							<FieldArray
								name={`mtmbEligibility.paymentEligibility.payment.paymentGroup`}
							>
								{({ remove }) => (
									<>
										{fieldPayment.payment &&
											fieldPayment.payment.paymentGroup &&
											fieldPayment.payment.paymentGroup.map((item, index) => (
												<>
													<div className="row">
														<div className="col-md-10">
															<div className="row">
																<div className="col-md-4 font-weight-bold">
																	{index == 0
																		? 'Aggregators and Issuers'
																		: null}
																</div>
																<div className="col-md-6"></div>
															</div>
														</div>
														<div className="col-md-2">
															<div className="row justify-content-end mr-2">
																<div className="">
																	{fieldPayment.payment
																		.paymentGroup.length > 1 ? (
																		<div className="row justify-content-end">
																			<div className="col-1">
																				<button
																					type="button"
																					// className="secondary"
																					className="addDeleteBtn"
																					onClick={() =>
																						remove(
																							index
																						)
																					}
																				></button>
																			</div>
																		</div>
																	) : null}
																</div>
																<div className="">
																	{index == 0 ? (
																		<button
																			className="addPlusBtn"
																			type="button"
																			onClick={() => {
																				if (
																					!fieldPayment
																						.payment
																						.paymentGroup
																				) {
																					fieldPayment.payment.paymentGroup =
																						[];
																				}

																				fieldPayment.payment.paymentGroup.push(
																					{
																						aggregators:
																							[],
																						issuers: [
																							{
																								issuerPaymentMode:
																									'',
																								issuerPaymentType:
																									'',
																								issuerPaymentSubType:
																									'',
																								issuerProvider:
																									'',
																								cashbackInformation:
																									{
																										isCashbackSame: true,
																										paymentInformation:
																											{
																												paymentMode:
																													'',
																												provider:
																													'',
																												paymentSubType:
																													'',
																												paymentType:
																													''
																											},
																										creditBy:
																											{},
																										expireOn:
																											{}
																									}
																							}
																						]
																					}
																				);

																				setFieldValue(
																					'',
																					''
																				);
																			}}
																		></button>
																	) : null}
																</div>
															</div>
														</div>
													</div>
													{/* {fieldPayment.payment.paymentGroup.length>1?
                        <div className="row justify-content-end">
                          <div className="col-1">
                          <button
                          type="button"
                          // className="secondary"
                          className="addDeleteBtn"
                          onClick={() => remove(index)}
                        >
                        </button>
                          </div>

                        </div>:null
                      } */}
													<div className="aggregatorBox">
														{/* <CustomSelect
                                name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.aggregators`}
                                label={"Aggregators"}
                                options={getEmiDropdown()}
                                smallText=""
                              /> */}
														<CustomReactMultiSelectComponent
															name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.aggregators`}
															label={'Aggregators'}
															smallText=""
														/>

														<table className="mt-10 bg-white">
															<tr>
																<th>Issuer Payment Mode</th>
																<th>Issuer Payment Type</th>
																<th>Issuer Payment SubType</th>
																<th>Issuer Provider</th>
																<th className="w-20">
																	Cashback Info
																</th>
																<th>
																	<button
																		className="addPlusBtn"
																		type="button"
																		onClick={() => {
																			fieldPayment.payment
																				.paymentGroup &&
																				fieldPayment.payment.paymentGroup[
																					index
																				].issuers.push({
																					issuerPaymentMode:
																						'',
																					issuerPaymentType:
																						'',
																					issuerPaymentSubType:
																						'',
																					issuerProvider:
																						'',
																					cashbackInformation:
																						{
																							isCashbackSame: true,
																							paymentInformation:
																								{
																									paymentMode:
																										'',
																									provider:
																										'',
																									paymentSubType:
																										'',
																									paymentType:
																										''
																								},
																							creditBy:
																								{},
																							expireOn:
																								{}
																						}
																				});
																			setFieldValue('', '');
																		}}
																	></button>
																</th>
															</tr>

															<FieldArray
																name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers`}
															>
																{({ remove }) => (
																	<>
																		{fieldPayment.payment
																			.paymentGroup &&
																			fieldPayment.payment.paymentGroup[
																				index
																			].issuers.map(
																				(item, i) => (
																					<tr
																						key={i}
																						className="payment-Conditions"
																					>
																						<td>
																							<div className="col-md-12">
																								<Field
																									className="form-control m-2"
																									name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.issuerPaymentMode`}
																									type="text"
																									validate={
																										customValidateIssuerPaymentMode
																									}
																								/>
																								<ErrorMessage
																									name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.issuerPaymentMode`}
																									component="div"
																									className="field-error"
																								/>
																							</div>
																						</td>
																						<td>
																							<div className="col-md-12">
																								<Field
																									className="form-control m-2"
																									name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.issuerPaymentType`}
																									type="text"
																									validate={
																										customValidateIssuerPaymentType
																									}
																								/>
																								<ErrorMessage
																									name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.issuerPaymentType`}
																									component="div"
																									className="field-error"
																								/>
																							</div>
																						</td>
																						<td>
																							<div className="col-md-12">
																								<Field
																									className="form-control m-2"
																									name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.issuerPaymentSubType`}
																									type="text"
																									validate={
																										customValidateIssuerPaymentSubType
																									}
																								/>
																								<ErrorMessage
																									name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.issuerPaymentSubType`}
																									component="div"
																									className="field-error"
																								/>
																							</div>
																						</td>
																						<td>
																							<div className="col-md-12">
																								<Field
																									className="form-control m-2"
																									name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.issuerProvider`}
																									type="text"
																									validate={
																										customValidateIssuerPaymentProvider
																									}
																								/>
																								<ErrorMessage
																									name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.issuerProvider`}
																									component="div"
																									className="field-error"
																								/>
																							</div>
																						</td>
																						<td>
																							<div className="p-2">
																								<div className="">
																									<label>
																										Is
																										Cashback
																										same?
																									</label>
																									<Field
																										type="checkbox"
																										name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.isCashbackSame`}
																										className={
																											'form-check-input m-0 ml-2'
																										}
																									/>
																								</div>
																								{fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation
																									.isCashbackSame ? null : (
																									<>
																										<label>
																											{' '}
																											Payment
																											Mode
																										</label>
																										<Field
																											type="text"
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.paymentInformation.paymentMode`}
																											className="form-control"
																										/>
																										<label>
																											{' '}
																											Provider
																										</label>
																										<Field
																											type="text"
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.paymentInformation.provider`}
																											className="form-control"
																										/>
																										<label>
																											{' '}
																											Payment
																											Type
																										</label>
																										<Field
																											type="text"
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.paymentInformation.paymentType`}
																											className="form-control"
																										/>
																										<label>
																											{' '}
																											Payment
																											Subtype
																										</label>
																										<Field
																											type="text"
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.paymentInformation.paymentSubType`}
																											className="form-control"
																										/>

																										{/* <CustomSelect
              name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.creditBy.cashbackCreditData`}
              required={false}
              label="Credit By"
              options={getCollectionValidityDropdown()}
            /> */}
																									</>
																								)}

																								<label>
																									{
																										'Credit By'
																									}
																								</label>
																								<Field
																									as="select"
																									name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.creditBy.cashbackCreditData`}
																									className={`form-control ${
																										field.touched &&
																										field.error
																											? 'is-invalid'
																											: ''
																									}`}
																									title="Select"
																								>
																									{getCollectionValidityDropdown()}
																								</Field>

																								{fieldPayment.payment &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								] &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation
																									.creditBy
																									.cashbackCreditData ==
																									'Date' ? (
																									<>
																										<CustomDateTime
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.creditBy.date`}
																											required={
																												true
																											}
																											// label="Date(yyyy-mm-dd)"
																											validate={
																												customValidateCashBackCreditByDate
																											}
																											smallText={
																												localConst.subCashBackCreditByDate
																											}
																											valueDate={
																												fieldPayment
																													.payment
																													.paymentGroup[
																													index
																												]
																													.issuers[
																													i
																												]
																													.cashbackInformation
																													.creditBy
																													.date
																											}
																										/>
																									</>
																								) : fieldPayment.payment &&
																								  fieldPayment
																										.payment
																										.paymentGroup[
																										index
																								  ]
																										.issuers[
																										i
																								  ]
																										.cashbackInformation ? (
																									fieldPayment
																										.payment
																										.paymentGroup[
																										index
																									]
																										.issuers[
																										i
																									]
																										.cashbackInformation
																										.creditBy ? (
																										(fieldPayment.payment.paymentGroup[
																											index
																										].issuers[
																											i
																										].cashbackInformation.creditBy.date =
																											undefined)
																									) : null
																								) : null}

																								{fieldPayment.payment &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								] &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation
																									.creditBy
																									.cashbackCreditData ==
																									'Months' ? (
																									<>
																										{/* <CustomInputText
                    name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.creditBy.months`}
                    required={false}
                    // label="Months"
                    validate={customValidateCashBackCreditByMonths}
                    smallText={localConst.subCashBackCreditByMonths}
                  /> */}
																										<Field
																											className="form-control mt-3 mb-3"
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.creditBy.months`}
																											type="text"
																											validate={
																												customValidateCashBackCreditByMonths
																											}
																										/>
																										<ErrorMessage
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.creditBy.months`}
																											component="div"
																											className="field-error"
																										/>
																									</>
																								) : fieldPayment.payment &&
																								  fieldPayment
																										.payment
																										.paymentGroup[
																										index
																								  ]
																										.issuers[
																										i
																								  ]
																										.cashbackInformation ? (
																									fieldPayment
																										.payment
																										.paymentGroup[
																										index
																									]
																										.issuers[
																										i
																									]
																										.cashbackInformation
																										.creditBy ? (
																										(fieldPayment.payment.paymentGroup[
																											index
																										].issuers[
																											i
																										].cashbackInformation.creditBy.months =
																											undefined)
																									) : null
																								) : null}

																								{fieldPayment.payment &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								] &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation
																									.creditBy
																									.cashbackCreditData ==
																									'Days' ? (
																									<>
																										{/* <CustomInputText
                    name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.creditBy.days`}
                    required={true}
                    // label="Days"
                    validate={customValidateCashBackCreditByDays}
                    smallText={localConst.subCashBackCreditByDays}
                  /> */}
																										<Field
																											className="form-control mt-3 mb-3"
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.creditBy.days`}
																											type="text"
																											validate={
																												customValidateCashBackCreditByDays
																											}
																										/>
																										<ErrorMessage
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.creditBy.days`}
																											component="div"
																											className="field-error"
																										/>
																									</>
																								) : fieldPayment.payment &&
																								  fieldPayment
																										.payment
																										.paymentGroup[
																										index
																								  ]
																										.issuers[
																										i
																								  ]
																										.cashbackInformation ? (
																									fieldPayment
																										.payment
																										.paymentGroup[
																										index
																									]
																										.issuers[
																										i
																									]
																										.cashbackInformation
																										.creditBy ? (
																										(fieldPayment.payment.paymentGroup[
																											index
																										].issuers[
																											i
																										].cashbackInformation.creditBy.days =
																											undefined)
																									) : null
																								) : null}

																								{fieldPayment.payment &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								] &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation
																									.creditBy
																									.cashbackCreditData ==
																									'Hours' ? (
																									<>
																										<CustomHoursPicker
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.creditBy.hours`}
																											required={
																												true
																											}
																											// label="Hours(HH:mm)"
																											validate={
																												customValidateCashBackCreditByHours
																											}
																											smallText={
																												localConst.subCashBackCreditByHours
																											}
																											valueHour={
																												fieldPayment
																													.payment
																													.paymentGroup[
																													index
																												]
																													.issuers[
																													i
																												]
																													.cashbackInformation
																													.creditBy
																													.hours
																											}
																										/>
																									</>
																								) : fieldPayment.payment &&
																								  fieldPayment
																										.payment
																										.paymentGroup[
																										index
																								  ]
																										.issuers[
																										i
																								  ]
																										.cashbackInformation ? (
																									fieldPayment
																										.payment
																										.paymentGroup[
																										index
																									]
																										.issuers[
																										i
																									]
																										.cashbackInformation
																										.creditBy ? (
																										(fieldPayment.payment.paymentGroup[
																											index
																										].issuers[
																											i
																										].cashbackInformation.creditBy.hours =
																											undefined)
																									) : null
																								) : null}

																								{/* <CustomSelect
              name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.expireOn.cashbackExpireData`}
              required={false}
              label="Expire On"
              options={getCollectionValidityDropdown()}
            /> */}
																								<label>
																									{
																										'Expire On'
																									}
																								</label>
																								<Field
																									as="select"
																									name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.expireOn.cashbackExpireData`}
																									className={`form-control ${
																										field.touched &&
																										field.error
																											? 'is-invalid'
																											: ''
																									}`}
																									title="Select"
																								>
																									{getCollectionValidityDropdown()}
																								</Field>

																								{fieldPayment.payment &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								] &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation
																									.expireOn
																									.cashbackExpireData ==
																									'Date' ? (
																									<>
																										<CustomDateTime
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.expireOn.date`}
																											required={
																												true
																											}
																											label="Date(yyyy-mm-dd)"
																											validate={
																												customValidateCashBackExpireOnDate
																											}
																											smallText={
																												localConst.subCashBackExpireOnDate
																											}
																											valueDate={
																												fieldPayment
																													.payment
																													.paymentGroup[
																													index
																												]
																													.issuers[
																													i
																												]
																													.cashbackInformation
																													.expireOn
																													.date
																											}
																										/>
																									</>
																								) : fieldPayment.payment &&
																								  fieldPayment
																										.payment
																										.paymentGroup[
																										index
																								  ]
																										.issuers[
																										i
																								  ]
																										.cashbackInformation ? (
																									fieldPayment
																										.payment
																										.paymentGroup[
																										index
																									]
																										.issuers[
																										i
																									]
																										.cashbackInformation
																										.expireOn ? (
																										(fieldPayment.payment.paymentGroup[
																											index
																										].issuers[
																											i
																										].cashbackInformation.expireOn.date =
																											undefined)
																									) : null
																								) : null}

																								{fieldPayment.payment &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								] &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation
																									.expireOn
																									.cashbackExpireData ==
																									'Months' ? (
																									<>
																										{/* <CustomInputText
                    name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.expireOn.months`}
                    required={false}
                    label="Months"
                    validate={customValidateCashBackCreditByMonths}
                    smallText={localConst.subCashBackExpireOnMonths}
                  /> */}
																										<Field
																											className="form-control mt-3 mb-3"
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.expireOn.months`}
																											type="text"
																											validate={
																												customValidateCashBackCreditByMonths
																											}
																										/>
																										<ErrorMessage
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.expireOn.months`}
																											component="div"
																											className="field-error"
																										/>
																									</>
																								) : fieldPayment.payment &&
																								  fieldPayment
																										.payment
																										.paymentGroup[
																										index
																								  ]
																										.issuers[
																										i
																								  ]
																										.cashbackInformation ? (
																									fieldPayment
																										.payment
																										.paymentGroup[
																										index
																									]
																										.issuers[
																										i
																									]
																										.cashbackInformation
																										.expireOn ? (
																										(fieldPayment.payment.paymentGroup[
																											index
																										].issuers[
																											i
																										].cashbackInformation.expireOn.months =
																											undefined)
																									) : null
																								) : null}

																								{fieldPayment.payment &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								] &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation
																									.expireOn
																									.cashbackExpireData ==
																									'Days' ? (
																									<>
																										{/* <CustomInputText
                    name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.expireOn.days`}
                    required={true}
                    label="Days"
                    validate={customValidateCashBackCreditByDays}
                    smallText={localConst.subCashBackExpireOnDays}
                  /> */}
																										<Field
																											className="form-control mt-3 mb-3"
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.expireOn.days`}
																											type="text"
																											validate={
																												customValidateCashBackCreditByDays
																											}
																										/>
																										<ErrorMessage
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.expireOn.days`}
																											component="div"
																											className="field-error"
																										/>
																									</>
																								) : fieldPayment.payment &&
																								  fieldPayment
																										.payment
																										.paymentGroup[
																										index
																								  ]
																										.issuers[
																										i
																								  ]
																										.cashbackInformation ? (
																									fieldPayment
																										.payment
																										.paymentGroup[
																										index
																									]
																										.issuers[
																										i
																									]
																										.cashbackInformation
																										.expireOn ? (
																										(fieldPayment.payment.paymentGroup[
																											index
																										].issuers[
																											i
																										].cashbackInformation.expireOn.days =
																											undefined)
																									) : null
																								) : null}

																								{fieldPayment.payment &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								] &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation &&
																								fieldPayment
																									.payment
																									.paymentGroup[
																									index
																								]
																									.issuers[
																									i
																								]
																									.cashbackInformation
																									.expireOn
																									.cashbackExpireData ==
																									'Hours' ? (
																									<>
																										<CustomHoursPicker
																											name={`mtmbEligibility.paymentEligibility.payment.paymentGroup.${index}.issuers.${i}.cashbackInformation.expireOn.hours`}
																											required={
																												true
																											}
																											label="Hours(HH:mm)"
																											validate={
																												customValidateCashBackCreditByHours
																											}
																											smallText={
																												localConst.subCashBackExpireOnHours
																											}
																											valueHour={
																												fieldPayment
																													.payment
																													.paymentGroup[
																													index
																												]
																													.issuers[
																													i
																												]
																													.cashbackInformation
																													.expireOn
																													.hours
																											}
																										/>
																									</>
																								) : fieldPayment.payment &&
																								  fieldPayment
																										.payment
																										.paymentGroup[
																										index
																								  ]
																										.issuers[
																										i
																								  ]
																										.cashbackInformation ? (
																									fieldPayment
																										.payment
																										.paymentGroup[
																										index
																									]
																										.issuers[
																										i
																									]
																										.cashbackInformation
																										.expireOn ? (
																										(fieldPayment.payment.paymentGroup[
																											index
																										].issuers[
																											i
																										].cashbackInformation.expireOn.hours =
																											undefined)
																									) : null
																								) : null}
																							</div>
																						</td>
																						<td>
																							<div className="col">
																								<button
																									type="button"
																									// className="secondary"
																									className="addDeleteBtn"
																									onClick={() =>
																										remove(
																											i
																										)
																									}
																								></button>
																							</div>
																						</td>
																					</tr>
																				)
																			)}
																	</>
																)}
															</FieldArray>
														</table>
														<br />

														{/* Aggregators block ends */}
													</div>
												</>
											))}
									</>
								)}
							</FieldArray>
						</>
					)}
				</>
			) : null}
		</div>
	);
};

export default PaymentConditionForm;
