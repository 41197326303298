import React, { useEffect, useState } from 'react';
import { Form, useField } from 'formik';
import CustomInputText from './customfields/CustomInputText';
import CustomSelect from './customfields/CustomSelect';
import CollapseHeaderBar from './CollapseHeaderBar';
import CustomDateTime from './customfields/CustomDateTime';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import CollapseBody from './CollapseBody';
import BenefitSettings from './BenefitSettings';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import ExclusionSetting from './ExclusionSetting';
import { itemHelp } from './item-help-text';
import ConditionalRederingEB from './PackageProduct/ConditionalRederingEB';
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actionTypes from '../../../store/actions';
import EarnBurnGroupArray from './EarnBurnComponent/EarnBurnGroupArray';
import $ from 'jquery';

const dropdownValues = {
	collectionValidity: [
		{ value: '', name: 'Select one' },
		{
			value: 'Date',
			name: 'Date'
		},
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Hours',
			name: 'Hours'
		}
	]
};

const thresholdTypeCodeOptions = [
	{
		value: '',
		name: 'Select one...'
	},
	{
		value: 'EARN_BURN_INTERVAL_QTY_THRESHOLD',
		name: 'EarnBurn Interval Quantity Threshold'
	},
	{
		value: 'EARN_BURN_QTY_THRESHOLD',
		name: 'EarnBurn Quantity Threshold'
	},
	{
		value: 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD',
		name: 'EarnBurn Interval Amount Threshold'
	},
	{
		value: 'EARN_BURN_AMOUNT_THRESHOLD',
		name: 'EarnBurn Amount Threshold'
	}
];

let PKGProductTableData;

const getThresholdDropdown = (thresholdTypeCodeOptions) => {
	return thresholdTypeCodeOptions.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	isEarnCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Determines the combination code to be used between the isEarn items for a promotion',
	thresholdQuantity: 'Threshold Quantity',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	rewardQuantity: 'Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	PaymentConditions: 'Payment Conditions',
	CouponConditions: 'Coupon Conditions',
	ExclusionsSetting: 'Exclusion Settings',
	subCashBackCreditByMonths:
		'Specifies the period the cashback will be credited to the customer in months post the transaction',
	subCashBackCreditByDays:
		'Specifies the period the cashback will be credited to the customer in days post the transaction',
	subCashBackCreditByHours:
		'Specifies the period the cashback will be credited to the customer in hours post the transaction',
	subCashBackCreditByDate:
		'Specifies the period the cashback will be credited to the customer post the transaction',
	subCashBackExpireOnMonths:
		'Specifies the period the cashback will be Expired to the customer in months post the transaction',
	subCashBackExpireOnDays:
		'Specifies the period the cashback will be Expired to the customer in days post the transaction',
	subCashBackExpireOnHours:
		'Specifies the period the cashback will be Expired to the customer in hours post the transaction',
	subCashBackExpireOnDate:
		'Specifies the period the cashback will be Expired to the customer post the transaction'
};

const EligibilityForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	//useValues(name, props);
	const [field] = useField(props);

	//const earnBurnEligibility = field.value.earnBurnEligibility;
	const fieldPKG = field.value.earnBurnEligibility;
	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));

	if (Object.keys(props.dataPKGtable).length != 0) {
		PKGProductTableData = props.dataPKGtable;
		if (PKGProductTableData.groupDetail.length == 0) {
			/* PKGProductTableData = {
		"groupDetail": [
		  {
			"multibuyProduct": [],
		 
			"merchandizeHierarchyGroup":[],
		  },
		  
		],
	  }; */
		}
	} else {
		PKGProductTableData = {
			groupDetail: [
				{
					multibuyProduct: [],

					merchandizeHierarchyGroup: []
				}
			]
		};
	}

	const [showExpireOn] = useState(false);

	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
					.thresholdTypeCode != field.value.earnBurnEligibility.thresholdTypeCode
			) {
				let tempName = 'earnBurnEligibility.thresholdTypeCode';

				$('[name="' + tempName + '"]')
					.closest('.card-body')
					.nextAll()
					.addClass('hide-nochange-section-for-audit');
			}
		}
	}, []);

	const getCollectionValidityDropdown = () => {
		return dropdownValues.collectionValidity.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};
	const onMouseEnterHandler = (evt, mouseIsON) => {
		if (mouseIsON != props.curntMousePlace) {
			//props.setMousePlace(mouseIsON);
		}
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

	const customValidateExpiryDate = (value) => {
		let error;

		if (value) {
			if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate) < moment(value)
			) {
				error = 'Expiry On date should not be greater than the promotion end date and time';
			}
		}

		return error;
	};

	const onPKGProductTableChange = (val) => {
		props.onPKGProductTableChange(val);
	};

	const onMerchTableDataChange = (val) => {
		props.onMerchTableDataChange(val);
	};

	return (
		<div>
			<Form className={offerFlow.type == 'view-offer-flow' ? 'disabledEvent' : ''}>
				<Element
					name="scrollToPurchaseandRewardConditions"
					class="card"
					style={{ border: 'none' }}
					onMouseEnter={(e) =>
						onMouseEnterHandler(e, 'scrollToPurchaseandRewardConditions')
					}
				>
					<div className="container-fluid" name="PurchaseandRewardBlock">
						<div className="card-body">
							<div className="d-flex justify-content-center container">
								<CustomSelect
									control="select"
									label="Threshold Type Code"
									name="earnBurnEligibility.thresholdTypeCode"
									options={getThresholdDropdown(thresholdTypeCodeOptions)}
									required={true}
									className="customDropdown inlinePadding"
									smallText={itemHelp.ThresholdTypeCodeEarnBurn}
								/>
							</div>
						</div>
						{fieldPKG.thresholdTypeCode ? (
							<>
								<ConditionalRederingEB
									value={fieldPKG.thresholdTypeCode}
									//index={index}
								/>

								{JSON.parse(sessionStorage.getItem('typeOfOffersFlow'))
									.isAuditFlow ? (
									<div className="packageForAuditContainer lightBlue p-2">
										<div className="happyHourForAudit">
											{fieldPKG?.groupDetailAdded?.length > 0 ? (
												<div className="bg-white m-4 p-3 mt-2">
													<label className="font-weight-bold">
														Added ({fieldPKG?.groupDetailAdded?.length})
													</label>
													<EarnBurnGroupArray
														onPKGProductTableChange={
															onPKGProductTableChange
														}
														onMerchTableDataChange={
															onMerchTableDataChange
														}
														dataPKGtable={props.dataPKGtable}
														merdata={props.merdata}
														customArrayName="groupDetailAdded"
														fieldPKG={fieldPKG}
														fullValue={props.fullValue}
													/>
												</div>
											) : undefined}
											{fieldPKG?.groupDetailRemoved?.length > 0 ? (
												<div className="bg-white m-4 p-3">
													<label className="font-weight-bold">
														Removed (
														{fieldPKG?.groupDetailRemoved?.length})
													</label>
													<EarnBurnGroupArray
														onPKGProductTableChange={
															onPKGProductTableChange
														}
														onMerchTableDataChange={
															onMerchTableDataChange
														}
														dataPKGtable={props.dataPKGtable}
														merdata={props.merdata}
														customArrayName="groupDetailRemoved"
														fieldPKG={fieldPKG}
														fullValue={props.fullValue}
													/>
												</div>
											) : undefined}
										</div>
										{fieldPKG?.groupDetail?.length > 0 ? (
											<div className="bg-white m-4 p-3">
												<label className="font-weight-bold">
													Modified ({fieldPKG?.groupDetail?.length})
												</label>
												<EarnBurnGroupArray
													onPKGProductTableChange={
														onPKGProductTableChange
													}
													onMerchTableDataChange={onMerchTableDataChange}
													dataPKGtable={props.dataPKGtable}
													merdata={props.merdata}
													customArrayName="groupDetail"
													fieldPKG={fieldPKG}
													fullValue={props.fullValue}
												/>
											</div>
										) : undefined}
									</div>
								) : (
									<EarnBurnGroupArray
										onPKGProductTableChange={onPKGProductTableChange}
										onMerchTableDataChange={onMerchTableDataChange}
										dataPKGtable={props.dataPKGtable}
										merdata={props.merdata}
										customArrayName="groupDetail"
										fieldPKG={fieldPKG}
										fullValue={props.fullValue}
									/>
								)}

								{showExpireOn ? (
									<div>
										<CustomSelect
											name="earnBurnEligibility.expireOn.expireOnType"
											required={false}
											label="Expire On"
											options={getCollectionValidityDropdown()}
										/>

										{fieldPKG.expireOn &&
										fieldPKG.expireOn?.expireOnType == 'Date' ? (
											<>
												<CustomDateTime
													name="earnBurnEligibility.expireOn.date"
													required={true}
													label="Date(yyyy-mm-dd)"
													// validate={customValidateCashBackExpireOnDate}
													smallText={localConst.subCashBackExpireOnDate}
													validate={customValidateExpiryDate}
												/>
											</>
										) : fieldPKG.expireOn ? (
											(fieldPKG.expireOn.date = undefined)
										) : undefined}

										{fieldPKG.expireOn &&
										fieldPKG.expireOn?.expireOnType == 'Months' ? (
											<>
												<CustomInputText
													name="earnBurnEligibility.expireOn.months"
													required={true}
													label="Months"
													// validate={customValidateCashBackCreditByMonths}
													smallText={localConst.subCashBackExpireOnMonths}
												/>
											</>
										) : fieldPKG.expireOn ? (
											(fieldPKG.expireOn.months = undefined)
										) : undefined}

										{fieldPKG.expireOn &&
										fieldPKG.expireOn?.expireOnType == 'Days' ? (
											<>
												<CustomInputText
													name="earnBurnEligibility.expireOn.days"
													required={true}
													label="Days"
													// validate={customValidateCashBackCreditByDays}
													smallText={localConst.subCashBackExpireOnDays}
												/>
											</>
										) : fieldPKG.expireOn ? (
											(fieldPKG.expireOn.days = undefined)
										) : undefined}

										{fieldPKG.expireOn &&
										fieldPKG.expireOn?.expireOnType == 'Hours' ? (
											<>
												<CustomHoursPicker
													name="earnBurnEligibility.expireOn.hours"
													required={true}
													label="Hours(HH:mm)"
													// validate={customValidateCashBackCreditByHours}
													smallText={localConst.subCashBackExpireOnHours}
													// valueHour={fieldPKG.expireOn.hours}
												/>
											</>
										) : fieldPKG.expireOn ? (
											(fieldPKG.expireOn.hours = undefined)
										) : undefined}
									</div>
								) : undefined}
							</>
						) : null}
					</div>
				</Element>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToExclusionsSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToExclusionsSettings')}
					>
						<CollapseHeaderBar
							text={localConst.ExclusionsSetting}
							headingId={'headingES'}
							dataTarget={'#collapseES'}
						/>
						<CollapseBody id="collapseES" headingId={'headingES'}>
							<ExclusionSetting
								customName="earnBurnEligibility"
								fullValue={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>

				{/* <div class="accordion" id="accordionExample">
          <Element name="scrollToPaymentConditions" class="card" style={{ border: "none" }}
          onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToPaymentConditions')}
          >
            <CollapseHeaderBar
              text={localConst.PaymentConditions}
              headingId={"headingFour"}
              dataTarget={"#collapseFour"}
            />
            <CollapseBody id="collapseFour" headingId={"headingFour"}>
              <PaymentCondition customName="earnBurnEligibility" fullVal={props.fullValue} loggedInBrand={props.loggedInBrand} />
            </CollapseBody>
          </Element>
        </div> */}
				{/* 
        <div class="accordion" id="accordionExample">
        <Element name="scrollToCouponConditions" class="card" style={{ border: "none" }}>
            <CollapseHeaderBar
              text={localConst.CouponConditions}
              headingId={"headingFive"}
              dataTarget={"#collapseFive"}
            />
            <CollapseBody id="collapseFive" headingId={"headingFive"}>
              <CouponCondition customName="earnBurnEligibility" fullVal={props.fullValue} />
            </CollapseBody>
            </Element>
        </div> */}

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToBenefitSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToBenefitSettings')}
					>
						<CollapseHeaderBar
							text={localConst.benefitSettings}
							headingId={'headingTwo'}
							dataTarget={'#collapseTwo'}
						/>
						<CollapseBody id="collapseTwo" headingId={'headingTwo'}>
							<BenefitSettings {...props} customName="earnBurnEligibility" />
						</CollapseBody>
					</Element>
				</div>
			</Form>
		</div>
	);
};

//export default EligibilityForm;

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
