import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProductTable.css';
// import "bootstrap/less/bootstrap.less";

const perPage = 10;

const ProductTable = (props) => {
	//state and props
	const [productID] = useState('');
	const [selectAll, setSelectAll] = useState(false);
	const [productData, setProductData] = useState([]);
	const [limitedData, setLimitedData] = useState([]);
	const [errorList, setErrorList] = useState([]);
	// const [offset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [field] = useField(props);
	//new
	const [header1, setHeader1] = useState('Threshold Quantity');
	const [header2, setHeader2] = useState('');
	const [header3, setHeader3] = useState('');
	const [header4, setHeader4] = useState('');

	//useEffects
	useEffect(() => {
		//setProductData(props.data);
		const newErrorList = Array.from({ length: perPage }, () => '');
		setErrorList(newErrorList);
	}, []);

	useEffect(() => {
		//for reseting
		//setProductData([]);

		//purchase and reward
		if (field.value?.scaleTransaction?.thresholdTypeCode) {
			if (
				field.value?.scaleTransaction?.thresholdTypeCode ==
					'SCALE_BASKET_LIMIT_QTY_AND_AMOUNT_THRESHOLD' ||
				field.value?.scaleTransaction?.thresholdTypeCode ==
					'SCALE_BASKET_QTY_AND_VALUE_THRESHOLD'
			) {
				setHeader1('Threshold Quantity');
				setHeader2('Threshold Amount');
			} else {
				setHeader1('Threshold Quantity');
				setHeader2('');
			}
		}
		//payment condition
		if (field.value?.scaleTransaction?.paymentEligibility.applicableOn) {
			if (field.value?.scaleTransaction?.paymentEligibility.paymentThresholdTypeCode) {
				setHeader4('Payment Threshold Amount');
			} else {
				setHeader4('');
			}
		} else {
			setHeader4('');
		}

		//benefit type
		if (field.value?.scaleTransaction?.benefit.benefitType) {
			if (field.value?.scaleTransaction?.benefit.benefitType == 'INSTANT') {
				if (field.value?.scaleTransaction?.benefit.priceModificationMethodCode) {
					setHeader3(field.value?.scaleTransaction?.benefit.priceModificationMethodCode);
				} else {
					setHeader3('');
				}
			} else if (field.value?.scaleTransaction?.benefit.benefitType == 'CASHBACK') {
				if (field.value?.scaleTransaction?.benefit.priceModificationMethodCode) {
					setHeader3(field.value?.scaleTransaction?.benefit.priceModificationMethodCode);
				} else {
					setHeader3('');
				}
			} else if (field.value?.scaleTransaction?.benefit.benefitType == 'LOYALTY_POINTS') {
				if (field.value?.scaleTransaction?.benefit.priceModificationMethodCode) {
					setHeader3(field.value?.scaleTransaction?.benefit.priceModificationMethodCode);
				} else {
					setHeader3('');
				}
			} else if (field.value?.scaleTransaction?.benefit.benefitType == 'FREE_TEXT') {
				if (field.value?.scaleTransaction?.benefit.priceModificationMethodCode) {
					setHeader3('Free Text Message');
				} else {
					setHeader3('');
				}
			}
		}
	}, [
		field.value?.scaleTransaction?.benefit.benefitType,
		field.value?.scaleTransaction?.thresholdTypeCode,
		field.value?.scaleTransaction?.paymentEligibility.applicableOn,
		field.value?.scaleTransaction?.paymentEligibility.paymentThresholdTypeCode,
		field.value?.scaleTransaction?.benefit.benefitType,
		field.value?.scaleTransaction?.benefit.priceModificationMethodCode
	]);

	useEffect(() => {
		setProductData([]);
		setErrorList([]);
	}, [
		field.value?.scaleTransaction?.benefit.benefitType,
		field.value?.scaleTransaction?.thresholdTypeCode,
		field.value?.scaleTransaction?.benefit.benefitType,
		field.value?.scaleTransaction?.benefit.priceModificationMethodCode
	]);
	useEffect(() => {
		const newProductData1 = [...productData];
		if (field.value?.scaleTransaction?.paymentEligibility.applicableOn) {
			newProductData1.map((val) => (val['Payment Threshold Amount'] = undefined));
			setProductData(newProductData1);
		}

		// setProductData([]);
	}, [
		field.value?.scaleTransaction?.paymentEligibility.applicableOn,
		field.value?.scaleTransaction?.paymentEligibility.paymentThresholdTypeCode
	]);

	useEffect(() => {
		handlePagination({ selected: currentPage });
		props.onProductTableChange(productData);
	}, [productData]);

	useEffect(() => {
		if (limitedData.length === 0 && currentPage !== 0) {
			handlePagination({ selected: currentPage - 1 });
		}
	}, [productData]);

	//local functions
	const handleChange = (e, i) => {
		const { name, value } = e.target;
		const newlimitedData = [...limitedData];
		const newProductData = [...productData];
		let newErrorList = JSON.parse(JSON.stringify(errorList));
		//debugger;
		newlimitedData[i][name] = value;
		setLimitedData(newlimitedData);
		const productIndex = productData.findIndex((val) => val.id == newlimitedData[i].id);
		newProductData[productIndex][name] = value;
		setProductData(newProductData);
		/* if(newErrorList=="")
    newErrorList = JSON.parse(JSON.stringify(limitedData)); */
		if (value === '') {
			newErrorList[i][name] = '';
			setErrorList(newErrorList);
		} else {
			if (name == 'Threshold Quantity') {
				if (parseInt(value) < 1 || parseInt(value) > 999999) {
					newErrorList[i][name] = `${name} should be between 1 to 999999`;
				} else {
					newErrorList[i][name] = '';
				}
			} else if (
				name == 'Threshold Amount' ||
				name == 'AMOUNT_OFF' ||
				name == 'APPORTIONED_AMOUNT_OFF' ||
				name == 'MARKDOWN' ||
				name == 'KIT_MARKDOWN' ||
				name == 'Payment Threshold Amount' ||
				name == 'LOYALTY_ADDITIONAL_POINTS' ||
				name == 'LOYALTY_POINTS_MULTIPLIER' ||
				name == 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
				name == 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE' ||
				name == 'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
				name == 'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE'
			) {
				if (parseFloat(value) < 0.01 || parseFloat(value) > 999999.99) {
					newErrorList[i][name] = `${name} should be between 0.01 to 999999.99`;
				} else {
					newErrorList[i][name] = '';
				}
			} else if (name == 'PERCENTAGE_OFF' || name == 'APPORTIONED_PERCENTAGE_OFF') {
				if (parseFloat(value) < 0.01 || parseFloat(value) > 100.0) {
					newErrorList[i][name] = `${name} should be between 0.01 to 100.0`;
				} else {
					newErrorList[i][name] = '';
				}
			} else {
				newErrorList[i][name] = '';
			}
			setErrorList(newErrorList);
		}
	};

	const handleCheckBox = (e, i) => {
		const { name } = e.target;
		const newProductData = [...limitedData];
		newProductData[i][name] = !newProductData[i][name];
		setLimitedData(newProductData);
	};

	const removeRow = (i) => {
		const list = [...limitedData];
		const producdIndex = productData.findIndex((val) => val.id == list[i].id);
		// //debugger
		const tempProductData = [...productData];
		tempProductData.splice(producdIndex, 1);
		setProductData(tempProductData);
		list.splice(i, 1);
		setLimitedData(list);
		// //debugger
	};

	const handlePagination = (e) => {
		const { selected } = e;
		const tempOffset = selected * perPage;
		// setOffset(tempOffset);
		setCurrentPage(selected);

		setCurrentPage(selected);
		let limitedData = [...productData];
		limitedData = limitedData.slice(tempOffset, tempOffset + perPage);
		setErrorList(errorList);
		setLimitedData(limitedData);
	};

	const onSelectAllChanges = () => {
		setSelectAll(!selectAll);
		let tempProductData = [...productData];
		tempProductData = tempProductData.map((val) => {
			return { ...val, select: !selectAll };
		});
		setProductData(tempProductData);

		handlePagination({ selected: currentPage });
	};

	const onAdd = () => {
		const obj = {
			id: productData.length !== 0 ? productData[productData.length - 1].id + 1 : 0,
			[header1]: '',
			[header2]: '',
			[header3]: '',
			[header4]: ''
		};
		if (!productData.find((x) => x.productID === productID)) {
			// you can also change `name` to `id`
			setProductData([...productData, obj]);
			setErrorList([...errorList, obj]);
		} else {
			alert('ProductID ' + productID + ' is already present');
		}
	};

	const deleteSelected = () => {
		let tempProductData = [...productData];
		tempProductData = tempProductData.filter((val) => {
			return !val.select;
		});
		setProductData(tempProductData);
		if (tempProductData.length === 0) {
			setSelectAll(!selectAll);
		}
	};

	const getProductData = () => {
		return limitedData.map((val, i) => {
			return (
				<thead key={i + ''}>
					<tr>
						<td>
							<input
								type="checkbox"
								name="select"
								onChange={(e) => handleCheckBox(e, i)}
								checked={val.select == true}
								value={val.select}
							/>
						</td>
						{header1 ? (
							<td>
								<input
									type="text"
									name={header1}
									onChange={(e) => handleChange(e, i)}
									className={`form-control ${
										errorList.length > 0 &&
										errorList[i] != undefined &&
										errorList[i][header1]
											? 'is-invalid'
											: ''
									}`}
									value={val[header1]}
								/>
								{errorList[i] != undefined ? (
									errorList[i][header1] != undefined ? (
										<div className="errorText">{errorList[i][header1]}</div>
									) : undefined
								) : undefined}
							</td>
						) : undefined}
						{header2 ? (
							<td>
								<input
									type="text"
									name={header2}
									onChange={(e) => handleChange(e, i)}
									className={`form-control ${
										errorList.length > 0 &&
										errorList[i] != undefined &&
										errorList[i][header2]
											? 'is-invalid'
											: ''
									}`}
									value={val[header2]}
								/>
								{errorList[i] != undefined ? (
									errorList[i][header2] != undefined ? (
										<div className="errorText">{errorList[i][header2]}</div>
									) : undefined
								) : undefined}
							</td>
						) : undefined}
						{header3 ? (
							<td>
								<input
									type="text"
									name={header3}
									onChange={(e) => handleChange(e, i)}
									className={`form-control ${
										errorList.length > 0 &&
										errorList[i] != undefined &&
										errorList[i][header3]
											? 'is-invalid'
											: ''
									}`}
									value={val[header3]}
								/>
								{errorList[i] != undefined ? (
									errorList[i][header3] != undefined ? (
										<div className="errorText">{errorList[i][header3]}</div>
									) : undefined
								) : undefined}
							</td>
						) : undefined}
						{header4 ? (
							<td>
								<input
									type="text"
									name={header4}
									onChange={(e) => handleChange(e, i)}
									className={`form-control ${
										errorList.length > 0 &&
										errorList[i] != undefined &&
										errorList[i][header4]
											? 'is-invalid'
											: ''
									}`}
									value={val[header4]}
								/>
								{errorList[i] != undefined ? (
									errorList[i][header4] != undefined ? (
										<div className="errorText">{errorList[i][header4]}</div>
									) : undefined
								) : undefined}
							</td>
						) : undefined}
						<button
							type="button"
							className="btn btn-outline-dark br-50 ml-3 mt-3"
							onClick={() => removeRow(i)}
						>
							X
						</button>
					</tr>
				</thead>
			);
		});
	};

	//render
	return (
		<div>
			{/*  {productData.length > 0 && ( */}
			<React.Fragment>
				<table className="table mt-10">
					<thead>
						<tr>
							<th>
								<input
									type="checkbox"
									onChange={onSelectAllChanges}
									checked={selectAll}
								/>
							</th>
							{header1 ? (
								<th>
									{header1}
									<div>{header1 ? 'Range(1 - 999999)' : undefined}</div>
								</th>
							) : undefined}
							{header2 ? (
								<th>
									{header2}
									<div>{header2 ? 'Range(0.01 - 999999.99)' : undefined}</div>
								</th>
							) : undefined}
							{header3 ? (
								<th>
									{header3}
									<div>
										{header3 == 'PERCENTAGE_OFF' ||
										header3 == 'APPORTIONED_PERCENTAGE_OFF'
											? 'Range(0.01 - 100.0)'
											: header3 == 'AMOUNT_OFF' ||
											  header3 == 'APPORTIONED_AMOUNT_OFF' ||
											  header3 == 'MARKDOWN' ||
											  header3 == 'KIT_MARKDOWN'
											? 'Range(0.01 - 999999.99)'
											: undefined}
									</div>
								</th>
							) : undefined}
							{header4 ? (
								<th>
									{header4}
									<div>
										{header4
											? 'Payment Threshold Amount Range (0.01-999999.99)'
											: undefined}
									</div>
								</th>
							) : undefined}
							<th>
								<button
									type="button"
									className="btn btn-outline-dark br-50"
									onClick={onAdd}
								>
									+
								</button>
							</th>
							<th>
								<button
									type="button"
									className="btn btn-outline-dark br-50"
									onClick={deleteSelected}
								>
									X
								</button>
							</th>
						</tr>
					</thead>
					{getProductData()}
				</table>
				<div className="col row   ">
					<ReactPaginate
						previousLabel={'previous'}
						nextLabel={'next'}
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={Math.ceil(productData.length / perPage)}
						marginPagesDisplayed={2}
						pageRangeDisplayed={5}
						onPageChange={handlePagination}
						forcePage={currentPage}
						containerClassName={'pagination'}
						subContainerClassName={'pages pagination'}
						activeClassName={'active'}
					/>
				</div>
			</React.Fragment>
			{/*   )} */}
		</div>
	);
};

export default ProductTable;
