import React, { Component } from 'react';
import Card from './Card';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import './Assign.css';

const update = require('immutability-helper');
class Assign extends Component {
	state = {
		cards: this.props.levels
	};

	moveCard = (dragIndex, hoverIndex) => {
		const { cards } = this.state;
		const dragCard = cards[dragIndex];
		this.setState(
			update(this.state, {
				cards: {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard]
					]
				}
			})
		);
		this.props.setHierarchicalLevel(hoverIndex + 1);
		this.props.updateCards(this.state.cards);
	};

	render() {
		return (
			<div>
				<div className="card-container">
					<div className="hierarchicalLevelDiv">
						<div className="Input">
							<label className="label-through">
								Hierarchical Level<span className="text-danger"> * </span>
							</label>
							<input
								className="InputElement"
								type="text"
								value={this.props.newLevelName}
								readOnly
							/>
						</div>
					</div>
					{this.props.levels.map((card, i) => (
						<div
							key={i}
							className={
								card.levelName === 'Administrator' ||
								this.props.loggedInLevelHierarchy > card.hierarchicalLevel
									? 'd-lg-none d-flex align-items-center ml-3'
									: 'd-flex align-items-center ml-3'
							}
						>
							<div className="round">{i + 1}</div>
							<Card
								key={i}
								index={i}
								id={i}
								text={card.levelName}
								moveCard={this.moveCard}
								newLevelName={this.props.newLevelName}
								loggedInLevelHierarchy={this.props.loggedInLevelHierarchy}
								hierarchicalLevel={card.hierarchicalLevel}
							/>
						</div>
					))}
				</div>
			</div>
		);
	}
}

export default DragDropContext(HTML5Backend)(Assign);
