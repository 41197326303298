// eslint-disable-next-line no-unused-vars
const dummyUrl = 'https://jsonplaceholder.typicode.com/posts';

export const user_maintenance = {
	performLogin: '/auth/performLogin',
	refreshToken: '/auth/accessToken',
	changePwd: '/usermanagement/password/changePassword',
	createUser: '/employee/createUser',
	logout: '/auth/revokeToken',
	userDetails: '/employee/searchOperatorDetail',
	fetchUsers: '/employee/searchByParam',
	updateUser: '/employee/updateUser',
	deleteUser: '/employee/deleteUser',
	fetchEnterprise: '/employee/fetchEnterpriseHierarchy',
	getEnterprise: '/employee/getEnterpriseHierarchy',
	fetchLevelsForDropdown: '/fetchLevelsForDropdown',
	getFunctionNames: '/employee/getFunctionNames',
	getNotification: '/rest/configurator/notifications/getNotificationDetailsForUser',
	getUploadPromoNotificationDetails:
		'/rest/configurator/notifications/fetchAllUploadedPromoFileNames',
	notificationDownloadFile: '/rest/configurator/notifications/downloadFiles'
};

export const create_level_maintenance = {
	fetchLevels: 'fetchAllExistingLevel',
	createLevel: '/createLevel',
	fetchResources: '/resourcesToAssign',
	assignResources: '/assignResourcesToLevel',
	deleteLevel: '/deleteLevel',
	modifyLevel: '/reorderHierarchy',
	fetchLevelsById: '/fetchAllLevelsById',
	createOrModifyLevel: '/createOrModifyLevel'
};

export const offers_maintenance = {
	searchPromotion: '/rest/offers/configurator/searchPromotion',
	getConfiguredPromotionXml: '/rest/offers/configurator/getConfiguredPromotionXml',
	deletePromotion: '/rest/offers/configurator/deletePromotion',
	getCouponsCountDetails: '/rest/offers/configurator/getCouponsCountDetails',
	getUsersForFunctionID: '/employee/getUsersForFunctionId',
	getConfigurationUrl: '/rest/offers/configurator/getConfigurations',
	getConsumablePromotionXml: '/rest/offers/configurator/getPromotionJson',
	getPromoIdConfig: '/rest/offers/configurator/getPromoIdConfig/',
	getSegments: '/rest/offers/configurator/getSegmentDetails/',
	modifyPromoDuration: '/rest/offers/configurator/modifyPromotionDuration',
	getItemDescriptionUrl: '/rest/offers/configurator/getItemDescription',
	pausePromotion: '/rest/offers/configurator/pausePromotion',
	resumePromotion: '/rest/offers/configurator/resumePromotion',
	getPaymentConfigurations: '/rest/offers/configurator/getPaymentConfigurations',
	uploadCustomers: '/rest/configurator/customer/uploadCustomer',
	searchLimitedPromotion: '/rest/offers/configurator/searchLimitedPromotion',
	uploadPromotion: '/rest/configurator/flashSale/uploadPromotion',
	BulkModifyPromotion: '/rest/configurator/flashSale/bulkModifyFlashSalePromotion',
	checkDisplayPriority: '/rest/offers/configurator/checkDisplayPriority', //Commenting this code to remove sku and mtmb feature from release one. OFFERS-2203
	downloadOffers: '/rest/offers/configurator/downloadOffers',
	modifyPromotionTitleDescription: '/rest/offers/configurator/modifyPromotionTitleDescription',
	getParentBrands: '/rest/offers/configurator/parent/', //<business Unit Group Function ID>
	fetchAllCategoryHierarchy: '/fetchAllCategoryHierarchy'
};

export const offers_maintenance_cs = {
	searchPromotionLite: '/rest/offers/configurator/searchPromotionLite',
	offerDetailsLite: '/rest/offers/configurator/getPromotionJsonLite',
	uploadPromotion: '/rest/offers/configurator/uploadPromotionsLite',
	fetchBrandsForCSView: '/rest/offers/configurator/getBrandsForCSView',
	getBrandPromos: '/rest/offers/configurator/getPromotionsForBrandForCSView',
	uploadCustomers: '/rest/configurator/customer/uploadCustomerLite'
};

export const generateCoupon = {
	createCoupon: '/rest/offers/configurator/generateCoupons',
	saveCouponPatterns: '/rest/offers/couponPatterns/save',
	fetchCouponPatterns: '/rest/offers/couponPatterns/fetch',
	deleteCouponPatterns: '/rest/offers/couponPatterns/delete',
	fetchCoupons: '/rest/offers/generateCoupons/fetchGenerateCouponDetailsSummary',
	downloadGenerateCoupons: '/rest/offers/generateCoupons/downloadFile/', // :fileName
	mapCouponsToCustomer: '/rest/offers/configurator/mapCouponsToCustomerId',
	uploadCustomCoupons: '/rest/offers/configurator/uploadCustomCoupons',
	fetchPromotionBatchIdAndCouponCount:
		'/rest/offers/configurator/fetchPromotionBatchIdAndCouponCount'
};

export const save_promotion_end_points = {
	savePromotionJsonUrl: '/rest/offers/configurator/savePromotionJson',
	modifyPromotionUrl: '/rest/offers/configurator/modifyPromotion',
	getPromotionJsonUrl: '/rest/offers/configurator/getPromotionJson'
};

export const coupon_maintenance = {
	// searchCoupon: "/rest/offers/searchCoupon",
	// searchCouponDeatils: "/rest/offers/searchCouponDeatils",
	// downloadCoupon:"/rest/offers/downloadCoupons",
	// getCouponProperties:"auth/getCouponProperites",
	// uploadCoupons:"/rest/offers/uploadCoupons",
	// deleteCoupons:"/rest/offers/deleteCoupons",
	// uploadCouponDisplayFlag:"/rest/offers/uploadCouponDisplayFlag",
	// updateCouponExpiryDate:"/rest/offers/updateCouponExpiryDate",
	// updateCouponLimit:"/rest/offers/updateCouponLimit"

	searchCoupon: '/rest/offers/configurator/searchCoupon',
	searchCouponDeatils: '/rest/offers/configurator/searchCouponDeatils',
	downloadCoupon: '/rest/offers/configurator/downloadCoupons',
	getCouponProperties: 'auth/getCouponProperites',
	uploadCoupons: '/rest/offers/configurator/uploadCoupons',
	deleteCoupons: '/rest/offers/configurator/deleteCoupons',
	uploadCouponDisplayFlag: '/rest/offers/configurator/uploadCouponDisplayFlag',
	updateCouponExpiryDate: '/rest/offers/configurator/updateCouponExpiryDate',
	updateCouponLimit: '/rest/offers/configurator/updateCouponLimit',
	getCpnAuditVersions: '/rest/offers/getCouponAuditVersions',
	getCpnVerDetails: '/rest/offers/getCouponVersionDetails',
	getFlag: '/rest/offers/configurator/getPromotionCouponCount',
	getCouponRedeemDetails: '/rest/offers/configurator/getCouponRedeemDetails'
};

export const bin_setup = {
	// uploadBin: "/rest/offers/uploadBinRange",
	// appendBin: "/rest/offers/appendBinRange",
	// searchWithOfferId: "/rest/offers/searchWithOfferId",
	// deleteBin: "/rest/offers/deleteBinRanges" ,
	// searchBinDetails: "/rest/offers/searchBinRangeDetails",
	// searchWithBinRange:"/rest/offers/searchWithBinRange",
	// binRangeHistory:"/rest/offers/binRangeHistory",
	// promotionBinRangeHistory:"/rest/offers/promotionBinRangeHistory",
	// searchAll: "/rest/offers/viewAll",
	// searchDeletedBinRanges: "/rest/offers/searchDeletedBinRanges",
	// searchByLastModifiedBy: "/rest/offers/searchByLastModifiedBy"

	uploadBin: '/rest/offers/configurator/uploadBinRange',
	appendBin: '/rest/offers/configurator/appendBinRange',
	searchWithOfferId: '/rest/offers/configurator/searchWithOfferId',
	deleteBin: '/rest/offers/configurator/deleteBinRanges',
	searchBinDetails: '/rest/offers/configurator/searchBinRangeDetails',
	searchWithBinRange: '/rest/offers/configurator/searchWithBinRange',
	binRangeHistory: '/rest/offers/configurator/binRangeHistory',
	promotionBinRangeHistory: '/rest/offers/configurator/promotionBinRangeHistory',
	searchAll: '/rest/offers/configurator/viewAll',
	searchDeletedBinRanges: '/rest/offers/configurator/searchDeletedBinRanges',
	searchByLastModifiedBy: '/rest/offers/configurator/searchByLastModifiedBy'
};

export const audit_maintenance = {
	// searchPromotionForAudit: "/rest/offers/configurator/searchPromotionsForAudit",
	searchPromotionForAudit: '/rest/offers/configurator/fetchAudits',
	getPromotionChangeHistory: '/rest/offers/configurator/getPromotionChangeHistory',
	getPromotionVersionJSON: '/rest/offers/configurator/getPromotionVersionJSON'
};

export const segmentEndPoint = {
	submitSegment: '/rest/offers/configurator/segment',
	viewSegment: '/rest/offers/configurator/segment/businessUnitFunctionId', // all /rest/offers/configurator/segment/businessUnitFunctionId/<BUID> user /rest/offers/configurator/segment/businessUnitFunctionId/<BUIID>/user/<USERNAME>,
	deleteSegment: '/rest/offers/configurator/segment'
};
