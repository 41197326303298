import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {
	textFilter,
	Comparator,
	FILTER_TYPES,
	customFilter,
	numberFilter
} from 'react-bootstrap-table2-filter';
import './AuditTable.css';
// import AuditView from './../../assets/images/view.png';
import DateFilter from './DateFilter';
// import ToolkitProvider, { Search } from '../../utils/reactBootstrapTable2Toolkit';

class CouponVersionsTable extends Component {
	constructor() {
		super();
		this.state = {
			// For displaying data
			columns: [
				{
					dataField: 'versionId',
					text: '',
					sort: true,
					headerAlign: 'center',
					// headerFormatter: this.headerFormatter,
					filter: numberFilter({
						placeholder: 'Search Version', // placeholder for number input
						style: { display: 'inline-grid' }, // custom the style on number filter
						comparatorClassName: 'd-none', // custom the class on comparator select
						defaultValue: { number: '', comparator: Comparator.EQ }, // default value
						id: 'id' // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
					}),
					formatter: this.versionFormatter
				},
				// {
				//     dataField: "action",
				//     text: "",
				//     sort: true,
				//     headerAlign: 'center',
				//     formatter:this.actionFormatter
				// },
				{
					dataField: 'modifiedBy',
					text: '',
					headerAlign: 'center',
					filter: textFilter({
						delay: 500, // default is 500ms
						style: {
							//   backgroundColor: 'yellow'
						},
						className: 'test-classname',
						placeholder: 'Search Modified By'
					}),
					formatter: this.modifiedByFormatter
				},
				{
					dataField: 'modifiedOn',
					text: '',
					headerAlign: 'center',
					formatter: this.dateFormatter,
					headerStyle: { width: '400px' },
					// filter: dateFilter({
					//     defaultValue: {comparator: Comparator.GT}
					//     })
					// filter: dateFilter({
					//     delay: 400,
					//     placeholder: 'Search Date',
					//     withoutEmptyComparatorOption: true,
					//     comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
					//     style: { display: 'inline-grid' },
					//     className: 'custom-datefilter-class',
					//     comparatorStyle: { backgroundColor: 'antiquewhite' },
					//     comparatorClassName: 'custom-comparator-class',
					//     dateStyle: { backgroundColor: 'cadetblue', margin: '0px' },
					//     dateClassName: 'custom-date-class'
					//   }),
					filter: customFilter({
						type: FILTER_TYPES.DATE
					}),
					filterRenderer: (onFilter, column) => (
						<DateFilter onFilter={onFilter} column={column} />
					)
				},
				{
					dataField: 'action',
					text: '',
					headerAlign: 'center',
					// filter: textFilter({
					//     delay: 500, // default is 500ms
					//     style: {
					//     //   backgroundColor: 'yellow'
					//     },
					//     className: 'test-classname',
					//     placeholder: 'Search Modified By',
					//   }),
					formatter: this.actionFormatter
				},
				{
					dataField: 'Action',
					text: '',
					formatter: this.linkAction,
					sort: true,
					headerAlign: 'center'
				}
			]
			// vesrionsForSelectedPromo: [
			//     {
			//         "action": "CREATED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "101",
			//         "promotionVersion": 1
			//     },
			//     {
			//         "action": "MODIFIED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "102",
			//         "promotionVersion": 2
			//     },
			//     {
			//         "action": "CREATED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "101",
			//         "promotionVersion": 3
			//     },
			//     {
			//         "action": "MODIFIED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "102",
			//         "promotionVersion": 4
			//     },
			//     {
			//         "action": "MODIFIED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "102",
			//         "promotionVersion": 5
			//     },
			//     {
			//         "action": "MODIFIED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "102",
			//         "promotionVersion": 6
			//     }
			// ]
		};
	}

	headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
		let a = column.text;
		if (a) {
			return (
				<div>
					<div>
						{sortElement}
						{filterElement}
					</div>
					<div>
						<b> {a} </b>
					</div>
				</div>
			);
		}
	};

	linkAction = (cell, row) => {
		return (
			<>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<button
						type="button"
						className=""
						onClick={() => {
							this.props.viewCoupons(row, 'Search');
						}}
					>
						View
					</button>
				</div>
			</>
		);
	};
	modifiedByFormatter(cell, row) {
		return (
			<div>
				<div className="cols">Modified By</div>
				<div> {cell}</div>
			</div>
		);
	}
	actionFormatter(cell, row) {
		return (
			<div>
				<div className="cols">Action</div>
				<div> {cell}</div>
			</div>
		);
	}
	dateFormatter(cell, row) {
		return (
			<div>
				<div className="cols">Date</div>
				<div> {cell}</div>
			</div>
		);
	}
	versionFormatter(cell, row) {
		return (
			<div className="d-flex pt-2">
				<span className="cols pr-1">
					<h6>Version </h6>
				</span>
				<span className="">
					{' '}
					<h6> {cell}</h6>
				</span>
			</div>
		);
	}

	render() {
		const options = {
			// pageStartIndex: 0,
			sizePerPage: 5,
			hideSizePerPage: true,
			hidePageListOnlyOnePage: true
		};

		return (
			<>
				<div className="p-4">
					<div className="row pt-3 pb-1">
						<div className="d-flex ml-3">
							<span>
								<div className="roboto-m-16 font-weight-bold">
									Coupon Revision History
								</div>
							</span>
						</div>
					</div>
					<div className="version_table">
						<BootstrapTable
							keyField="versionId"
							data={this.props.cpnVersions}
							columns={this.state.columns}
							pagination={paginationFactory(options)}
							filter={filterFactory()}
							// selectRow={selectRow}
							// headerClasses="d-none"
						/>
					</div>
				</div>
			</>
		);
	}
}

export default CouponVersionsTable;
