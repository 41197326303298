import React, { useEffect, useState } from 'react';
import { Form, Field, useField, FieldArray, useFormikContext } from 'formik';
import CustomInputText from './customfields/CustomInputText';
import CustomSelect from './customfields/CustomSelect';
import CollapseHeaderBar from './CollapseHeaderBar';
import CustomDateTime from './customfields/CustomDateTime';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import CollapseBody from './CollapseBody';
import BenefitSettings from './BenefitSettings';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import PaymentCondition from './PaymentCondition';
import ExclusionSetting from './ExclusionSetting';
//import PackageProduct from "../components/PackageProduct/PackageProduct";
import { itemHelp } from './item-help-text';
import ConditionalRederingEB from './PackageProduct/ConditionalRederingEB';
import MerchTableBootstrapSCALE from './MerchTableBootstrapSCALE';
//import CustomFieldArrayInputPKG from '../customfields/CustomFieldArrayInputPKG'
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import moment from 'moment';

const dropdownValues = {
	collectionValidity: [
		{ value: '', name: 'Select one' },
		{
			value: 'Date',
			name: 'Date'
		},
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Hours',
			name: 'Hours'
		}
	]
};

const thresholdTypeCodeOptions = [
	{
		value: '',
		name: 'Select one...'
	},
	{
		value: 'EARN_BURN_INTERVAL_QTY_THRESHOLD',
		name: 'EarnBurn Interval Quantity Threshold'
	},
	{
		value: 'EARN_BURN_QTY_THRESHOLD',
		name: 'EarnBurn Quantity Threshold'
	},
	{
		value: 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD',
		name: 'EarnBurn Interval Amount Threshold'
	},
	{
		value: 'EARN_BURN_AMOUNT_THRESHOLD',
		name: 'EarnBurn Amount Threshold'
	}
];

let PKGMerchTableData;
const keys = ['merchandizeGroupId', 'minimumPrice', 'maximumPrice'];
const defaultValueForAddPM = {
	maximumPrice: '999999.99',
	minimumPrice: '0.01'
	// isEarn: 'Y',
	// isBurn: 'Y',
};

let errMsg = '';

const getThresholdDropdown = (thresholdTypeCodeOptions) => {
	return thresholdTypeCodeOptions.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	isEarnCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Determines the combination code to be used between the isEarn items for a promotion',
	thresholdQuantity: 'Threshold Quantity',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	rewardQuantity: 'Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	PaymentConditions: 'Payment Conditions',
	CouponConditions: 'Coupon Conditions',
	ExclusionsSetting: 'Exclusion Settings',
	subCashBackCreditByMonths:
		'Specifies the period the cashback will be credited to the customer in months post the transaction',
	subCashBackCreditByDays:
		'Specifies the period the cashback will be credited to the customer in days post the transaction',
	subCashBackCreditByHours:
		'Specifies the period the cashback will be credited to the customer in hours post the transaction',
	subCashBackCreditByDate:
		'Specifies the period the cashback will be credited to the customer post the transaction',
	subCashBackExpireOnMonths:
		'Specifies the period the cashback will be Expired to the customer in months post the transaction',
	subCashBackExpireOnDays:
		'Specifies the period the cashback will be Expired to the customer in days post the transaction',
	subCashBackExpireOnHours:
		'Specifies the period the cashback will be Expired to the customer in hours post the transaction',
	subCashBackExpireOnDate:
		'Specifies the period the cashback will be Expired to the customer post the transaction'
};

const EligibilityForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [showTable, setshowTable] = useState(true);
	const [field] = useField(props);

	//const earnBurnEligibility = field.value.earnBurnEligibility;
	const fieldPKG = field.value.earnBurnEligibility;

	const { setFieldValue } = useFormikContext();

	if (Object.keys(props.dataMRCHtable).length != 0) {
		PKGMerchTableData = props.dataMRCHtable;

		if (PKGMerchTableData.groupDetail.length == 0) {
			PKGMerchTableData = {
				groupDetail: [
					{
						merchandizeHierarchyGroup: []
					}
				]
			};
		}
	} else {
		PKGMerchTableData = {
			groupDetail: [
				{
					merchandizeHierarchyGroup: []
				}
			]
		};
	}

	const [showExpireOn, setShowExpireOn] = useState(false);

	useEffect(() => {
		let isEarnArray = [];
		fieldPKG.groupDetail.map((grp) => {
			if (grp.isEarn) {
				isEarnArray.push(grp);
			}
		});
		if (isEarnArray.length > 0) {
			setShowExpireOn(true);
		} else {
			setShowExpireOn(false);
			if (fieldPKG.expireOn) {
				fieldPKG.expireOn.expireOnType = undefined;
				fieldPKG.expireOn.date = undefined;
				fieldPKG.expireOn.days = undefined;
				fieldPKG.expireOn.months = undefined;
				fieldPKG.expireOn.hours = undefined;
			}
		}
	}, [fieldPKG.groupDetail]);

	useEffect(() => {
		if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_AMOUNT_THRESHOLD'
		) {
			fieldPKG.groupDetail[0].earnQuantity = undefined;
			fieldPKG.groupDetail[0].burnQuantity = undefined;
			if (!fieldPKG.groupDetail[0].earnQuantity && fieldPKG.groupDetail[0].isEarn == true) {
				if (!fieldPKG.groupDetail[0].earnAmount)
					fieldPKG.groupDetail[0].earnAmount = '0.01';
			}
			if (!fieldPKG.groupDetail[0].earnQuantity && fieldPKG.groupDetail[0].isBurn == true) {
				if (!fieldPKG.groupDetail[0].burnAmount)
					fieldPKG.groupDetail[0].burnAmount = '0.01';
			}
		}
		if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_QTY_THRESHOLD'
		) {
			fieldPKG.groupDetail[0].earnAmount = undefined;
			fieldPKG.groupDetail[0].burnAmount = undefined;
			if (!fieldPKG.groupDetail[0].earnAmount && fieldPKG.groupDetail[0].isEarn == true) {
				if (!fieldPKG.groupDetail[0].earnQuantity)
					fieldPKG.groupDetail[0].earnQuantity = '1';
			}
			if (!fieldPKG.groupDetail[0].earnAmount && fieldPKG.groupDetail[0].isBurn == true) {
				if (!fieldPKG.groupDetail[0].burnQuantity)
					fieldPKG.groupDetail[0].burnQuantity = '1';
			}
		}
	}, [fieldPKG.thresholdTypeCode]);

	const isEarnValueChange = (i) => {
		if (i == 0) {
			if (
				fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'EARN_BURN_AMOUNT_THRESHOLD'
			) {
				fieldPKG.groupDetail[0].earnQuantity = undefined;
				if (!fieldPKG.groupDetail[0].isEarn == true) {
					if (!fieldPKG.groupDetail[0].earnAmount)
						fieldPKG.groupDetail[0].earnAmount = '0.01';
				}
			}
			if (
				fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'EARN_BURN_QTY_THRESHOLD'
			) {
				fieldPKG.groupDetail[0].earnAmount = undefined;
				if (!fieldPKG.groupDetail[0].isEarn == true) {
					if (!fieldPKG.groupDetail[0].earnQuantity)
						fieldPKG.groupDetail[0].earnQuantity = '1';
				}
			}
		}
		if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_QTY_THRESHOLD'
		) {
			if (!fieldPKG.groupDetail[i]?.isEarn == true) {
				if (fieldPKG.groupDetail[i].earnQuantity == '') {
					fieldPKG.groupDetail[i].earnQuantity = '1';
				}
				fieldPKG.groupDetail[i].earnAmount = '';
			} else {
				fieldPKG.groupDetail[i].earnQuantity = '';
				fieldPKG.groupDetail[i].earnAmount = '';
			}
		} else if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_AMOUNT_THRESHOLD'
		) {
			if (!fieldPKG.groupDetail[i]?.isEarn == true) {
				if (fieldPKG.groupDetail[i].earnAmount == '') {
					fieldPKG.groupDetail[i].earnAmount = '0.01';
				}
				fieldPKG.groupDetail[i].earnQuantity = '';
			} else {
				fieldPKG.groupDetail[i].earnAmount = '';
				fieldPKG.groupDetail[i].earnQuantity = '';
			}
		}
	};
	const isBurnValueChange = (i) => {
		if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_QTY_THRESHOLD'
		) {
			if (
				!fieldPKG.groupDetail[i].isBurn == true &&
				fieldPKG.groupDetail[i].burnQuantity == ''
			) {
				fieldPKG.groupDetail[i].burnQuantity = '1';
				fieldPKG.groupDetail[i].burnAmount = '';
			} else {
				fieldPKG.groupDetail[i].burnQuantity = '';
				fieldPKG.groupDetail[i].burnAmount = '';
			}
		} else if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_AMOUNT_THRESHOLD'
		) {
			if (
				!fieldPKG.groupDetail[i].isBurn == true &&
				fieldPKG.groupDetail[i].burnAmount == ''
			) {
				fieldPKG.groupDetail[i].burnAmount = '0.01';
				fieldPKG.groupDetail[i].burnQuantity = '';
			} else {
				fieldPKG.groupDetail[i].burnAmount = '';
				fieldPKG.groupDetail[i].burnQuantity = '';
			}
		}
	};

	const onMerchTableChange = (val, j) => {
		//

		// MTMBProductTableData.packageMultibuyEligibility[i].group[j] = val
		// if(val.length!=0){
		PKGMerchTableData.groupDetail[j].merchandizeHierarchyGroup = val;
		//  }

		props.onPKGMerchTableChange(PKGMerchTableData);
	};

	const customValidateQuantity = (value) => {
		let error;
		if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_QTY_THRESHOLD'
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}$/)) {
					error = 'Quantity value should be between 1 to 999999';
				}
			} else {
				//error = "Please define a Quantity value"
			}
		}
		return error;
	};
	//amount
	const customValidateAmount = (value) => {
		let error;
		if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_AMOUNT_THRESHOLD'
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/)) {
					error = 'Amount value should be between 0.01 to 999999.99';
				}
			} else {
				//error = "Please define a Amount value"
			}
		}
		return error;
	};

	const getCollectionValidityDropdown = () => {
		return dropdownValues.collectionValidity.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};
	const onMouseEnterHandler = (evt, mouseIsON) => {
		if (mouseIsON != props.curntMousePlace) {
			props.setMousePlace(mouseIsON);
		}
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

	const customValidateExpiryDate = (value) => {
		let error;

		if (value) {
			if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate) < moment(value)
			) {
				error = 'Expiry On date should not be greater than the promotion end date and time';
			}
		}

		return error;
	};
	return (
		<div>
			<Form className={offerFlow.type == 'view-offer-flow' ? 'disabledEvent' : ''}>
				<Element
					name="scrollToPurchaseandRewardConditions"
					class="card"
					style={{ border: 'none' }}
					onMouseEnter={(e) =>
						onMouseEnterHandler(e, 'scrollToPurchaseandRewardConditions')
					}
				>
					<div className="container-fluid">
						<div className="d-flex justify-content-center">
							<CustomSelect
								control="select"
								label="Threshold Type Code"
								name="earnBurnEligibility.thresholdTypeCode"
								options={getThresholdDropdown(thresholdTypeCodeOptions)}
								required={true}
								className="customDropdown inlinePadding"
								smallText={itemHelp.ThresholdTypeCodeEarnBurn}
							/>
						</div>

						{fieldPKG.thresholdTypeCode ? (
							<>
								<ConditionalRederingEB
									value={fieldPKG.thresholdTypeCode}
									//index={index}
								/>
								<FieldArray name={`earnBurnEligibility.groupDetail`}>
									{({ remove }) => (
										<>
											<div
												className="accordion mtmb-acrdn mr-2 p-3"
												id="accordionGroupExample"
											>
												{fieldPKG.groupDetail.map((item, i) => (
													<>
														<div className="d-flex">
															<div
																className="card mtmb-card m-2"
																style={{
																	border: 'none',
																	width: 'calc(100% - 50px)'
																}}
															>
																<div
																	className={
																		i + 1 ==
																		fieldPKG.groupDetail.length
																			? 'card-head mtmb-crd-head group'
																			: 'card-head mtmb-crd-head group collapsed'
																	}
																	data-toggle="collapse"
																	data-target={`#mtmbPackageMultibuycollapseGroup${i}`}
																>
																	<h2 className="mb-0">
																		<div className="item-before pointer-events-auto">
																			{`Group ${i + 1}`}
																		</div>
																	</h2>
																</div>

																<div
																	id={`mtmbPackageMultibuycollapseGroup${i}`}
																	className={
																		i + 1 ==
																		fieldPKG.groupDetail.length
																			? 'collapse show mtmb-collapse'
																			: 'collapse mtmb-collapse'
																	}
																	data-parent={`#accordionGroupExample`}
																>
																	<div className="card-body">
																		<div className="row mt-3 mtmbTriggerReward">
																			<span className="col-2 text-right">
																				<label>
																					Is Earn
																				</label>
																				<span className="requiredRed pr-2">
																					*
																				</span>
																				<Field
																					type="checkbox"
																					name={`earnBurnEligibility.groupDetail.${i}.isEarn`}
																					className="p-3"
																					onClick={() =>
																						isEarnValueChange(
																							i
																						)
																					}
																					disabled={
																						fieldPKG
																							.groupDetail[
																							i
																						].isBurn
																					}
																				/>
																			</span>
																			<span className="col-6">
																				<label>
																					Is Burn
																				</label>
																				<span className="requiredRed pr-2">
																					*
																				</span>
																				<Field
																					type="checkbox"
																					name={`earnBurnEligibility.groupDetail.${i}.isBurn`}
																					className="p-3"
																					onClick={() =>
																						isBurnValueChange(
																							i
																						)
																					}
																					disabled={
																						fieldPKG
																							.groupDetail[
																							i
																						].isEarn
																					}
																				/>
																			</span>
																		</div>
																		{/* <MTMBProductTable data={data} onProductTableChange={(val) => onProductTableChange(val, index, i)} /> */}
																		{
																			<div className="purchaseContainer">
																				<div className="purchaseRow align-self-start d-block">
																					{fieldPKG.thresholdTypeCode ==
																						'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
																					fieldPKG.thresholdTypeCode ==
																						'EARN_BURN_QTY_THRESHOLD' ? (
																						<CustomInputText
																							name={`earnBurnEligibility.groupDetail.${i}.earnQuantity`}
																							required={
																								false
																							}
																							validate={(
																								value
																							) =>
																								customValidateQuantity(
																									value,
																									i
																								)
																							}
																							label={
																								'Earn Quantity'
																							}
																							smallText=""
																							disabled={
																								!fieldPKG
																									.groupDetail[
																									i
																								]
																									.isEarn
																							}
																						/>
																					) : (
																						<CustomInputText
																							name={`earnBurnEligibility.groupDetail.${i}.earnAmount`}
																							validate={(
																								value
																							) =>
																								customValidateAmount(
																									value,
																									i
																								)
																							}
																							required={
																								false
																							}
																							label={
																								'Earn Amount'
																							}
																							smallText=""
																							disabled={
																								!fieldPKG
																									.groupDetail[
																									i
																								]
																									.isEarn
																							}
																						/>
																					)}
																				</div>
																				<div className="purchaseRow align-self-start d-block">
																					{fieldPKG.thresholdTypeCode ==
																						'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
																					fieldPKG.thresholdTypeCode ==
																						'EARN_BURN_QTY_THRESHOLD' ? (
																						<CustomInputText
																							name={`earnBurnEligibility.groupDetail.${i}.burnQuantity`}
																							required={
																								false
																							}
																							validate={(
																								value
																							) =>
																								customValidateQuantity(
																									value,
																									i
																								)
																							}
																							label={
																								'Burn Quantity'
																							}
																							smallText=""
																							disabled={
																								!fieldPKG
																									.groupDetail[
																									i
																								]
																									.isBurn
																							}
																						/>
																					) : (
																						<CustomInputText
																							name={`earnBurnEligibility.groupDetail.${i}.burnAmount`}
																							validate={(
																								value
																							) =>
																								customValidateAmount(
																									value,
																									i
																								)
																							}
																							required={
																								false
																							}
																							label={
																								'Burn Amount'
																							}
																							smallText=""
																							disabled={
																								!fieldPKG
																									.groupDetail[
																									i
																								]
																									.isBurn
																							}
																						/>
																					)}
																				</div>
																			</div>
																		}
																		{/* <Field name={`earnBurnEligibility.${index}.groupDetail.${i}.multibuyProduct`} component={ProductSetting} /> */}
																		<div>
																			<div className="text-center text-danger">
																				{errMsg ==
																				'Product ID:' +
																					fieldPKG
																						?.groupDetail[
																						i
																					]?.productID +
																					' already added'
																					? errMsg
																					: null}
																			</div>

																			<div className="margin10">
																				{/* <MTMBProductTable
                                    data={data}
                                    onProductTableChange={(val) =>
                                      onProductTableChange(val, i)
                                    }
                                    groupDetail={PKGProductTableData.groupDetail}
                                  /> */}
																				{showTable ? (
																					<MerchTableBootstrapSCALE
																						keys={keys} //data={data}
																						data={
																							PKGMerchTableData
																								.groupDetail[
																								i
																							]
																								.merchandizeHierarchyGroup
																						}
																						defaultValueForAdd={
																							defaultValueForAddPM
																						}
																						onMerchTableChange={(
																							val
																						) =>
																							onMerchTableChange(
																								val,
																								i
																							)
																						}
																						eligibility={
																							'earnBurnEligibility'
																						}
																						groupDetail={
																							PKGMerchTableData.groupDetail
																						}
																						PKGMerchTableData={
																							PKGMerchTableData
																						}
																						isMerchTableRepetative={
																							true
																						}
																						currentGroupIndex={
																							i
																						}
																					/>
																				) : null}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															{fieldPKG.groupDetail.length > 1 ? (
																<button
																	type="button"
																	className="addDeleteBtn mt-3"
																	style={{
																		'background-color': 'unset'
																	}}
																	onClick={() => {
																		remove(i);
																		PKGMerchTableData.groupDetail.splice(
																			i,
																			1
																		);
																		props.onPKGMerchTableChange(
																			PKGMerchTableData
																		);
																		setshowTable(false);
																		setTimeout(() => {
																			setshowTable(true);
																		}, 50);
																		setFieldValue('', '');
																	}}
																></button>
															) : null}
														</div>
														{i + 1 == fieldPKG?.groupDetail?.length ? (
															<div className="d-flex justify-content-end mt-2">
																<button
																	type="button"
																	className="createGroupBtn"
																	onClick={() => {
																		PKGMerchTableData.groupDetail.push(
																			{
																				merchandizeHierarchyGroup:
																					[]
																			}
																		);

																		fieldPKG.groupDetail.push({
																			merchandizeHierarchyGroup:
																				[],
																			isEarn: false,
																			isBurn: true,
																			earnAmount:
																				fieldPKG.thresholdTypeCode ==
																					'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
																				fieldPKG.thresholdTypeCode ==
																					'EARN_BURN_AMOUNT_THRESHOLD'
																					? ''
																					: undefined,
																			earnQuantity:
																				fieldPKG.thresholdTypeCode ==
																					'EARN_BURN_QTY_THRESHOLD' ||
																				fieldPKG.thresholdTypeCode ==
																					'EARN_BURN_INTERVAL_QTY_THRESHOLD'
																					? ''
																					: undefined,
																			burnAmount:
																				fieldPKG.thresholdTypeCode ==
																					'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
																				fieldPKG.thresholdTypeCode ==
																					'EARN_BURN_AMOUNT_THRESHOLD'
																					? '0.01'
																					: undefined,
																			burnQuantity:
																				fieldPKG.thresholdTypeCode ==
																					'EARN_BURN_QTY_THRESHOLD' ||
																				fieldPKG.thresholdTypeCode ==
																					'EARN_BURN_INTERVAL_QTY_THRESHOLD'
																					? '1'
																					: undefined
																		});

																		setFieldValue('', '');
																	}}
																>
																	<span className="pr-2">+</span>
																	Add Group
																</button>
															</div>
														) : null}
													</>
												))}
											</div>
										</>
									)}
								</FieldArray>
								{showExpireOn ? (
									<div>
										<CustomSelect
											name="earnBurnEligibility.expireOn.expireOnType"
											required={false}
											label="Expire On"
											options={getCollectionValidityDropdown()}
										/>

										{fieldPKG.expireOn &&
										fieldPKG.expireOn?.expireOnType == 'Date' ? (
											<>
												<CustomDateTime
													name="earnBurnEligibility.expireOn.date"
													required={true}
													label="Date(yyyy-mm-dd)"
													// validate={customValidateCashBackExpireOnDate}
													smallText={localConst.subCashBackExpireOnDate}
													validate={customValidateExpiryDate}
												/>
											</>
										) : fieldPKG.expireOn ? (
											(fieldPKG.expireOn.date = undefined)
										) : undefined}

										{fieldPKG.expireOn &&
										fieldPKG.expireOn?.expireOnType == 'Months' ? (
											<>
												<CustomInputText
													name="earnBurnEligibility.expireOn.months"
													required={true}
													label="Months"
													// validate={customValidateCashBackCreditByMonths}
													smallText={localConst.subCashBackExpireOnMonths}
												/>
											</>
										) : fieldPKG.expireOn ? (
											(fieldPKG.expireOn.months = undefined)
										) : undefined}

										{fieldPKG.expireOn &&
										fieldPKG.expireOn?.expireOnType == 'Days' ? (
											<>
												<CustomInputText
													name="earnBurnEligibility.expireOn.days"
													required={true}
													label="Days"
													// validate={customValidateCashBackCreditByDays}
													smallText={localConst.subCashBackExpireOnDays}
												/>
											</>
										) : fieldPKG.expireOn ? (
											(fieldPKG.expireOn.days = undefined)
										) : undefined}

										{fieldPKG.expireOn &&
										fieldPKG.expireOn?.expireOnType == 'Hours' ? (
											<>
												<CustomHoursPicker
													name="earnBurnEligibility.expireOn.hours"
													required={true}
													label="Hours(HH:mm)"
													// validate={customValidateCashBackCreditByHours}
													smallText={localConst.subCashBackExpireOnHours}
													// valueHour={fieldPKG.expireOn.hours}
												/>
											</>
										) : fieldPKG.expireOn ? (
											(fieldPKG.expireOn.hours = undefined)
										) : undefined}
									</div>
								) : undefined}
							</>
						) : null}
					</div>
				</Element>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToExclusionsSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToExclusionsSettings')}
					>
						<CollapseHeaderBar
							text={localConst.ExclusionsSetting}
							headingId={'headingES'}
							dataTarget={'#collapseES'}
						/>
						<CollapseBody id="collapseES" headingId={'headingES'}>
							<ExclusionSetting
								customName="earnBurnEligibility"
								fullValue={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToPaymentConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToPaymentConditions')}
					>
						<CollapseHeaderBar
							text={localConst.PaymentConditions}
							headingId={'headingFour'}
							dataTarget={'#collapseFour'}
						/>
						<CollapseBody id="collapseFour" headingId={'headingFour'}>
							<PaymentCondition
								customName="earnBurnEligibility"
								fullVal={props.fullValue}
								loggedInBrand={props.loggedInBrand}
							/>
						</CollapseBody>
					</Element>
				</div>
				{/* 
        <div class="accordion" id="accordionExample">
        <Element name="scrollToCouponConditions" class="card" style={{ border: "none" }}>
            <CollapseHeaderBar
              text={localConst.CouponConditions}
              headingId={"headingFive"}
              dataTarget={"#collapseFive"}
            />
            <CollapseBody id="collapseFive" headingId={"headingFive"}>
              <CouponCondition customName="earnBurnEligibility" fullVal={props.fullValue} />
            </CollapseBody>
            </Element>
        </div> */}

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToBenefitSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToBenefitSettings')}
					>
						<CollapseHeaderBar
							text={localConst.benefitSettings}
							headingId={'headingTwo'}
							dataTarget={'#collapseTwo'}
						/>
						<CollapseBody id="collapseTwo" headingId={'headingTwo'}>
							<BenefitSettings {...props} customName="earnBurnEligibility" />
						</CollapseBody>
					</Element>
				</div>
			</Form>
		</div>
	);
};

//export default EligibilityForm;
const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
