import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { ErrorMessage } from 'formik';
import CustomDateTime from './customfields/CustomDateTime';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import * as Yup from 'yup';
import moment from 'moment';
import CustomReactSearchableDropdownComponent from './customfields/CustomReactSearchableDropdownComponent';
import CustomInputText2 from './customfields/CustomInputText2';
import CustomSelect2 from './customfields/CustomSelect2';
import CustomCheckbox from './customfields/CustomCheckbox';

const localConst = {
	applicableOn: 'Applicable on',
	subApplicableOn:
		'Determines if the promotion is applicable on the payment made through the tendered value or on the transaction condition',
	paymentThresholdCode: 'Payment Threshold Code',
	subPaymentThresholdCode: 'Determines the type of threshold for the payment',
	paymentThresholdAmount: 'Payment Threshold Amount',
	subPaymentThresholdAmount:
		'Determines the minimum threshold amount for the Payment Mode specified',
	paymentMode: 'Payment Mode',
	subPaymentMode: 'Defines the Payment eligibility Mode for which the promotion is applicable',
	emiType: 'EMI Type',
	subEmiType: 'Defines the types of EMI',
	provider: 'Provider',
	subProvider: 'Defines the Payment eligibility Provider for which the promotion is applicable',
	paymentType: 'Payment Type',
	subpaymentType: 'Defines the Payment eligibility Type for which the promotion is applicable',
	paymentSubType: 'Payment Sub Type',
	subPaymentSubType:
		'Defines the Payment eligibility SubType for which the promotion is applicable',
	coolOffPeriod: 'Cool Off Period'
};
// Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
//   return this.test('unique', message, function (list) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
	return this.test('unique', message, function (list) {
		if (!list) list = [];
		return list.length === new Set(list.map(mapper)).size;
	});
});

const CashbackInfo = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	const index = props.index;
	const i = props.i;
	const filednamepmtelg = props.filednamepmtelg;
	const show = props.show;
	const paymentModeOps = props.paymentModeOps;
	const paymentProviderOps = props.paymentProviderOps;
	const paymentTypeOps = props.paymentTypeOps;
	const fieldPaymentGroup =
		props.pgType == 'paymentGroupAdded'
			? filednamepmtelg?.payment?.paymentGroupAdded
			: props.pgType == 'paymentGroupRemoved'
			? filednamepmtelg?.payment?.paymentGroupRemoved
			: filednamepmtelg?.payment?.paymentGroup;
	const fieldIssuer =
		props.isType == 'issuerAdded'
			? fieldPaymentGroup[index]?.issuersAdded
			: props.isType == 'issuersRemoved'
			? fieldPaymentGroup[index]?.issuersRemoved
			: fieldPaymentGroup[index]?.issuers;
	const fieldCashBackInformation =
		props.cashbackInfoType == 'cashbackInformationAdded'
			? fieldIssuer[i]?.cashbackInformationAdded
			: props.cashbackInfoType == 'cashbackInformationRemoved'
			? fieldIssuer[i]?.cashbackInformationRemoved
			: fieldIssuer[i]?.cashbackInformation;
	const valueEligbility = props.valueEligbility;
	const dropdownValues = {
		applicableOn: [
			{
				value: '',
				name: 'Select'
			},
			{
				value: 'TENDER_VALUE',
				name: 'Tender Value'
			},
			{
				value: 'TRANSACTION_VALUE',
				name: 'Transaction Value'
			}
		],
		paymentThreshold: [
			/*  {
         value: "",
         name: "Select",
       }, */
			{
				value: 'PAYMENT_AMOUNT_THRESHOLD',
				name: 'Payment Amount Threshold'
			}
		],
		emi: [
			{
				value: '',
				name: 'Select'
			},
			{
				value: 'NO_COST_EMI',
				name: 'No Cost EMI'
			},
			{
				value: 'ONE_EMI_FREE',
				name: 'One EMI Free'
			}
		],
		coolOff: [
			{
				value: '',
				name: 'Select'
			},
			// {
			//   value: "Date",
			//   name: "Date",
			// },
			{
				value: 'Months',
				name: 'Months'
			},
			{
				value: 'Days',
				name: 'Days'
			},
			{
				value: 'Years',
				name: 'Years'
			}
		],
		collectionValidity: [
			{ value: '', name: 'Select one' },
			{
				value: 'Date',
				name: 'Date'
			},
			{
				value: 'Months',
				name: 'Months'
			},
			{
				value: 'Days',
				name: 'Days'
			},
			{
				value: 'Hours',
				name: 'Hours'
			}
		]
	};
	const getCollectionValidityDropdown = () => {
		return dropdownValues.collectionValidity.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const customValidateCashBackCreditByMonths = (value) => {
		let error;
		if (value) {
			var patt = new RegExp(/^(?=.*[1-9])\d{0,3}$/);
			if (!patt.test(parseInt(value))) {
				error = 'Please enter a value greater than 1 to 999.';
			}
			/*  if(!value.match(/^(?=.*[1-9])\d{0,3}$/)){
        error="Please enter a value greater than 1 to 999."
      }  */
		} else {
			error = 'Please define the months value';
		}
		return error;
	};

	const customValidateCashBackCreditByDays = (value) => {
		let error;
		if (value) {
			var patt = new RegExp(/^(?=.*[1-9])\d{0,3}$/);
			if (!patt.test(parseInt(value))) {
				error = 'Please enter a value greater than 1 to 999.';
			}
		} else {
			error = 'Please define the days value';
		}
		return error;
	};

	const customValidateCashBackCreditByHours = (value) => {
		let error;
		if (!value) {
			error = 'Please define the hours value';
		}
		return error;
	};
	const customValidateCashBackCreditByDate = (value) => {
		let error;

		if (value) {
			if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveStartDate) > moment(value)
			) {
				error = 'Entered date should not be less than promotion start date';
			} else if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate) < moment(value)
			) {
				error = 'Entered date should not be more than promotion end date';
			}
		} else {
			error = 'Please define the date value';
		}
		return error;
	};

	const customValidateCashBackExpireOnDate = (value) => {
		let error;

		if (value) {
			// if (moment(fieldCashBackInformation?.creditBy?.date) > moment(value)) {
			//   error = "Entered date should not be less than credited by date"
			// } else
			if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveStartDate) > moment(value)
			) {
				error = 'Entered date should not be less than promotion start date';
			} else if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate) < moment(value)
			) {
				error = 'Entered date should not be more than promotion end date';
			}
		} else {
			error = 'Please define the date value';
		}
		return error;
	};

	const customValidatePaymentMode = (value) => {
		let error;
		if (!value) {
			error = 'Please define payment mode';
		}
		return error;
	};
	const customValidatePaymentProvider = (value) => {
		let error;
		if (!value) {
			error = 'Please define payment provider';
		}

		return error;
	};

	useEffect(() => {}, []);

	return (
		<>
			<div className="col-sm-3 mb-4">
				<label className="mt-1 font-weight-bold">
					Cashback Info
					{props.cashbackInfoType == 'cashbackInformationAdded' ? '(Added)' : ''}
				</label>
				<div className="pt-2">
					{/* <Field type="checkbox" name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.isCashbackSame`} className={'form-check-input m-0'} />
                      <label className="ml-3">Is Cashback Same?</label> */}
					<CustomCheckbox
						name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.isCashbackSame`}
						label={'Is Cashback Same?'}
						// flag="show"
						hideBlueBox={props.cashbackInfoType == 'cashbackInformationAdded'}
					/>
				</div>
			</div>
			{fieldCashBackInformation && fieldCashBackInformation.isCashbackSame ? null : (
				<>
					<div className="col-sm-3">
						{/* <label className="mt-1">Payment Mode</label> */}
						{/* <Field type="text" name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.paymentMode`} 
      className="form-control  m-2" validate={customValidatePaymentMode} /> */}
						{show ? (
							<CustomReactSearchableDropdownComponent
								name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.paymentMode`}
								smallText=""
								options={paymentModeOps}
								validate={customValidatePaymentMode}
								label={'Payment Mode'}
								flag="show"
								pgType={props.pgType}
							/>
						) : null}
						<ErrorMessage
							name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.paymentMode`}
							component="div"
							className="field-error"
						/>
					</div>
					<div className="col-sm-3">
						{/* <label className="mt-1"> Provider</label> */}
						{/* <Field type="text" name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.provider`}
       className="form-control m-2" validate={customValidatePaymentProvider} /> */}
						{show ? (
							<CustomReactSearchableDropdownComponent
								name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.provider`}
								smallText=""
								options={paymentProviderOps}
								validate={customValidatePaymentProvider}
								label={'Provider'}
								flag="show"
								pgType={props.pgType}
							/>
						) : null}
						<ErrorMessage
							name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.provider`}
							component="div"
							className="field-error"
						/>
					</div>
					<div className="col-sm-3">
						{/* <label className="mt-1"> Payment Type</label> */}
						{/* <Field type="text" name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.paymentType`}
       className="form-control m-2" validate={customValidatePaymentType} /> */}
						{show ? (
							<CustomReactSearchableDropdownComponent
								name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.paymentType`}
								smallText=""
								options={paymentTypeOps}
								// validate={customValidatePaymentType}
								label={'Payment Type'}
								flag="show"
								pgType={props.pgType}
							/>
						) : null}
						<ErrorMessage
							name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.paymentType`}
							component="div"
							className="field-error"
						/>
					</div>
					<div className="col-sm-3 mb-4">
						{/* <label className="mt-1">Payment Sub Type</label>
                        <Field type="text" name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.paymentSubType`}
                          className="form-control"
                        /> */}
						<CustomInputText2
							name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.paymentSubType`}
							type="text"
							label={'Payment Sub Type'}
						/>
						{/* {show? 
        <CustomReactSearchableDropdownComponent
            name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.paymentSubType`} 
            smallText=""
          options={paymentSubTypeOps}
          />
          :null} */}
						{/* <ErrorMessage
                          name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.paymentInformation.paymentSubType`}
                          component="div"
                          className="field-error"
                        /> */}
					</div>
				</>
			)}
			{fieldCashBackInformation && fieldCashBackInformation.creditBy ? (
				<div className="col-sm-3">
					{/* <label className="mt-1">
                        {"Credit By"}
                      </label>
                      <Field as="select"
                        name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.creditBy.cashbackCreditData`}
                        className="form-control col-md-9"
                        className={`form-control ${field.touched && field.error ? "is-invalid" : ""
                          }`}
                        title="Select">
                        {getCollectionValidityDropdown()}
                      </Field> */}
					<CustomSelect2
						name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.creditBy.cashbackCreditData`}
						label={'Credit By'}
						options={getCollectionValidityDropdown()}
					/>
				</div>
			) : undefined}
			{filednamepmtelg.payment &&
			fieldIssuer[i] &&
			fieldCashBackInformation &&
			fieldCashBackInformation.creditBy.cashbackCreditData == 'Date' ? (
				<div className="col-sm-3">
					<label className="mt-1">Date</label>
					<CustomDateTime
						name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.creditBy.date`}
						required={true}
						// label="Date(yyyy-mm-dd)"
						validate={customValidateCashBackCreditByDate}
						smallText={localConst.subCashBackCreditByDate}
						valueDate={fieldCashBackInformation.creditBy.date}
					/>
				</div>
			) : filednamepmtelg.payment && fieldCashBackInformation ? (
				fieldCashBackInformation.creditBy ? (
					(fieldCashBackInformation.creditBy.date = undefined)
				) : null
			) : null}

			{filednamepmtelg.payment &&
			fieldIssuer[i] &&
			fieldCashBackInformation &&
			fieldCashBackInformation.creditBy.cashbackCreditData == 'Months' ? (
				<div className="col-sm-3">
					{/* <label className="mt-1">Months</label>
                        <Field
                          className="form-control"
                          name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.creditBy.months`}
                          type="text"
                          validate={customValidateCashBackCreditByMonths}
                        /> */}
					<CustomInputText2
						name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.creditBy.months`}
						type="text"
						label={'Months'}
						validate={customValidateCashBackCreditByMonths}
					/>
					{/* <ErrorMessage
                          name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.creditBy.months`}
                          component="div"
                          className="field-error"
                        /> */}
				</div>
			) : filednamepmtelg.payment && fieldCashBackInformation ? (
				fieldCashBackInformation.creditBy ? (
					(fieldCashBackInformation.creditBy.months = undefined)
				) : null
			) : null}

			{filednamepmtelg.payment &&
			fieldIssuer[i] &&
			fieldCashBackInformation &&
			fieldCashBackInformation.creditBy.cashbackCreditData == 'Days' ? (
				<div className="col-sm-3">
					{/* <label className="mt-1">Days</label>
                        <Field
                          className="form-control"
                          name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.creditBy.days`}
                          type="text"
                          validate={customValidateCashBackCreditByDays}
                        /> */}
					<CustomInputText2
						name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.creditBy.days`}
						type="text"
						label={'Days'}
						validate={customValidateCashBackCreditByDays}
					/>
					{/* <ErrorMessage
                          name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.creditBy.days`}
                          component="div"
                          className="field-error"
                        /> */}
				</div>
			) : filednamepmtelg.payment && fieldCashBackInformation ? (
				fieldCashBackInformation.creditBy ? (
					(fieldCashBackInformation.creditBy.days = undefined)
				) : null
			) : null}

			{filednamepmtelg.payment &&
			fieldIssuer[i] &&
			fieldCashBackInformation &&
			fieldCashBackInformation.creditBy.cashbackCreditData == 'Hours' ? (
				<div className="col-sm-3">
					<label className="mt-1">Hours</label>
					<CustomHoursPicker
						name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.creditBy.hours`}
						required={true}
						// label="Hours(HH:mm)"
						validate={customValidateCashBackCreditByHours}
						smallText={localConst.subCashBackCreditByHours}
						valueHour={fieldCashBackInformation.creditBy.hours}
					/>
				</div>
			) : filednamepmtelg.payment && fieldCashBackInformation ? (
				fieldCashBackInformation.creditBy ? (
					(fieldCashBackInformation.creditBy.hours = undefined)
				) : null
			) : null}
			<div className="col-sm-3">
				{/* <label className="mt-1">
                      {"Expire On"}
                    </label>
                    <Field as="select"
                      name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.expireOn.cashbackExpireData`}
                      className="form-control col-md-9"
                      className={`form-control ${field.touched && field.error ? "is-invalid" : ""
                        }`}
                      title="Select">
                      {getCollectionValidityDropdown()}
                    </Field> */}
				<CustomSelect2
					name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.expireOn.cashbackExpireData`}
					label={'Expire On'}
					options={getCollectionValidityDropdown()}
				/>
			</div>
			{filednamepmtelg.payment &&
			fieldIssuer[i] &&
			fieldCashBackInformation &&
			fieldCashBackInformation.expireOn.cashbackExpireData == 'Date' ? (
				<div className="col-sm-3">
					<label className="mt-1">Date</label>
					<CustomDateTime
						name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.expireOn.date`}
						required={true}
						label="Date(yyyy-mm-dd)"
						validate={customValidateCashBackExpireOnDate}
						smallText={localConst.subCashBackExpireOnDate}
						valueDate={fieldCashBackInformation.expireOn.date}
					/>
				</div>
			) : filednamepmtelg.payment && fieldCashBackInformation ? (
				fieldCashBackInformation.expireOn ? (
					(fieldCashBackInformation.expireOn.date = undefined)
				) : null
			) : null}

			{filednamepmtelg.payment &&
			fieldIssuer[i] &&
			fieldCashBackInformation &&
			fieldCashBackInformation.expireOn.cashbackExpireData == 'Months' ? (
				<div className="col-sm-3">
					{/* <label className="mt-1">Months</label>
                        <Field
                          className="form-control"
                          name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.expireOn.months`}
                          type="text"
                          validate={customValidateCashBackCreditByMonths}
                        /> */}
					<CustomInputText2
						name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.expireOn.months`}
						type="text"
						label={'Months'}
						validate={customValidateCashBackCreditByMonths}
					/>
					{/* <ErrorMessage
                          name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.expireOn.months`}
                          component="div"
                          className="field-error"
                        /> */}
				</div>
			) : filednamepmtelg.payment && fieldCashBackInformation ? (
				fieldCashBackInformation.expireOn ? (
					(fieldCashBackInformation.expireOn.months = undefined)
				) : null
			) : null}

			{filednamepmtelg.payment &&
			fieldIssuer[i] &&
			fieldCashBackInformation &&
			fieldCashBackInformation.expireOn.cashbackExpireData == 'Days' ? (
				<div className="col-sm-3">
					{/* <label className="mt-1">Days</label>
                        <Field
                          className="form-control"
                          name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.expireOn.days`}
                          type="text"
                          validate={customValidateCashBackCreditByDays}
                        /> */}
					<CustomInputText2
						name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.expireOn.days`}
						type="text"
						label={'Days'}
						validate={customValidateCashBackCreditByDays}
					/>
					{/* <ErrorMessage
                          name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.expireOn.days`}
                          component="div"
                          className="field-error"
                        /> */}
				</div>
			) : filednamepmtelg.payment && fieldCashBackInformation ? (
				fieldCashBackInformation.expireOn ? (
					(fieldCashBackInformation.expireOn.days = undefined)
				) : null
			) : null}

			{filednamepmtelg.payment &&
			fieldIssuer[i] &&
			fieldCashBackInformation &&
			fieldCashBackInformation.expireOn.cashbackExpireData == 'Hours' ? (
				<div className="col-sm-3">
					<label className="mt-1">Hours</label>
					<CustomHoursPicker
						name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.${props.cashbackInfoType}.expireOn.hours`}
						required={true}
						label="Hours(HH:mm)"
						validate={customValidateCashBackCreditByHours}
						smallText={localConst.subCashBackExpireOnHours}
						valueHour={fieldCashBackInformation.expireOn.hours}
					/>
				</div>
			) : filednamepmtelg.payment && fieldCashBackInformation ? (
				fieldCashBackInformation.expireOn ? (
					(fieldCashBackInformation.expireOn.hours = undefined)
				) : null
			) : null}
		</>
	);
};

export default CashbackInfo;
