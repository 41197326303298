import React, { Component } from 'react';
import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import resetIcon from './../../assets/images/undo.png';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import { withRouter } from 'react-router-dom';
import './couponTable.scss';

export class BinRangeSearch extends Component {
	initialState = {
		searchForm: {
			promotionID: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter Promotion ID',
					labelname: 'Promotion ID'
				},
				value: '',
				validation: {
					// required: true,
					// maxLength: 10
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			},
			binStartRange: {
				elementType: 'input',
				elementConfig: {
					type: 'number',
					placeholder: 'Enter Bin Start Range',
					labelname: 'Bin Start Range'
				},
				value: '',
				validation: {
					// required: true,
					// maxLength: 10
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			},
			binEndRange: {
				elementType: 'input',
				elementConfig: {
					type: 'number',
					placeholder: 'Enter Bin End Range',
					labelname: 'Bin End Range'
				},
				value: '',
				validation: {
					// required: true,
					// maxLength: 10
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			},
			binNumber: {
				elementType: 'input',
				elementConfig: {
					type: 'number',
					placeholder: 'Enter Bin Number',
					labelname: 'Bin Number'
				},
				value: '',
				validation: {
					// required: true,
					// maxLength: 10
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			},

			createdBy: {
				elementType: 'select',
				elementConfig: {
					type: '',
					placeholder: 'Enter Created By',
					labelname: 'Created/Modified By',
					options: [{ value: '', displayValue: 'ALL' }]
				},
				value: '',
				validation: {
					required: false
				},
				validationMessage: '',
				valid: false,
				touched: false
			}
		},
		formIsValid: false
	};

	state = this.initialState;

	componentDidMount = () => {
		//  this.getModifiedByOptions();
		this.getCreatedByOptions();
	};

	handleFormReset = () => {
		this.setState(() => this.initialState);
	};

	inputChangedHandler = (value, isValidObj, inputIdentifier, dateTimeRangeChanged) => {
		const updatedLoginForm = { ...this.state.searchForm };
		const updatedFormElement = { ...updatedLoginForm[inputIdentifier] };

		updatedFormElement.value = value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;

		updatedLoginForm[inputIdentifier] = updatedFormElement;
		if (dateTimeRangeChanged) {
			const updatedFormStartDateTimeElement = {
				...updatedLoginForm['modifiedStartDateTime']
			};
			const updatedFormEndDateTimeElement = { ...updatedLoginForm['couponExpiryDateTime'] };
			if (!moment(value, 'DD-MM-YYYY HH:mm', true).isValid()) {
				updatedFormElement.valid = false;
				updatedFormElement.validationMessage = 'Invalid date format';
			} else {
				updatedFormElement.valid = true;
				updatedFormElement.validationMessage = '';
			}
			if (updatedFormStartDateTimeElement.value && updatedFormEndDateTimeElement.value) {
				if (
					moment(updatedFormStartDateTimeElement.value) >
					moment(updatedFormEndDateTimeElement.value)
				) {
					updatedFormEndDateTimeElement.valid = false;
					updatedFormEndDateTimeElement.validationMessage = 'Invalid date time range';
				} else {
					updatedFormEndDateTimeElement.valid = isValidObj.value;
					updatedFormEndDateTimeElement.validationMessage = isValidObj.message;
				}
			}

			updatedLoginForm['couponExpiryDateTime'] = updatedFormEndDateTimeElement;
		}
		let formIsValid = true;
		for (let inputIdentifier in updatedLoginForm) {
			formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ searchForm: updatedLoginForm, formIsValid: formIsValid });
	};

	onInputChange = (event, definedRules, inputIdentifier) => {
		//debugger
		let value = event.target.value;
		if (inputIdentifier == 'promotionID' && value) {
			let searchForm = { ...this.state.searchForm };
			searchForm.binStartRange.value = '';
			searchForm.binEndRange.value = '';
			searchForm.binNumber.value = '';
			this.setState({
				searchForm
			});
		}
		if (inputIdentifier == 'binNumber' && value) {
			let searchForm = { ...this.state.searchForm };
			searchForm.binStartRange.value = '';
			searchForm.binEndRange.value = '';
			this.setState({
				searchForm
			});
		}
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value.trim() !== '';
				isValid.message = isValid.value ? '' : '';
			}

			if (rules.minLength) {
				isValid.value = value.length >= rules.minLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Minimum length is ' + rules.minLength;
			}

			if (rules.maxLength) {
				isValid.value = value.length <= rules.maxLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Maximum length is ' + rules.maxLength;
			}

			if (rules.isEmail && value) {
				let regEx = new RegExp(
					/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid email';
			}

			if (rules.isPhoneNumber && value) {
				let regEx = new RegExp(
					/^\+?(\d+|(\(\d+\))+)+((([ ]?(\d+|(\(\d+\))+)+)*)|((\-\d+)*)|((\.\d+)*))*$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid phone number';
			}

			if (rules.isAlphabets && value) {
				let regEx = new RegExp('^[a-zA-Z]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets';
			}

			if (rules.isAlphaNumeric && value) {
				let regEx = new RegExp('^[a-zA-Z0-9]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets and numbers';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, false);
	};
	onDateChange = (startDate, definedRules, inputIdentifier) => {
		let value = startDate;
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value !== '';
				isValid.message = isValid.value ? '' : 'Field required';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, true);
	};

	getCreatedByOptions = () => {
		this.props.showLoadingButton(true);
		var dataToSend = {
			getUsersForFunctionIdRequest: {
				functionID: this.props.loggedInBrand.businessUnitGroupFunctionID
			}
		};

		RestSvc.postData(urlTypes.offers_maintenance.getUsersForFunctionID, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				const inputIdentifier = 'createdBy';
				const updatedSearchForm = { ...this.state.searchForm };
				const updatedFormElement = { ...updatedSearchForm[inputIdentifier] };
				updatedSearchForm[inputIdentifier] = updatedFormElement;
				var tempArr = [{ value: '', displayValue: 'ALL' }];
				var Users = response.data.getUsersForFunctionIdResponse.userIds;
				Users.map(function (item) {
					tempArr.push({
						value: item,
						displayValue: item
					});
				});
				updatedFormElement.elementConfig.options = tempArr;
				this.setState({ searchForm: updatedSearchForm });
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.searchForm) {
			formElementsArray.push({
				id: key,
				config: this.state.searchForm[key]
			});
		}
		return (
			<React.Fragment>
				<div className="modal-body">
					<div className="row p-4">
						<div className="col-md-6 row ml-0">
							<input
								type={formElementsArray[0].config.elementConfig.type}
								className={`form_control ${
									!formElementsArray[0].config.valid &&
									formElementsArray[0].config.touched
										? 'Invalid'
										: this.state.searchForm.binStartRange.value ||
										  this.state.searchForm.binEndRange.value ||
										  this.state.searchForm.binNumber.value ||
										  this.state.searchForm.createdBy.value
										? 'disabled1'
										: ''
								}`}
								value={formElementsArray[0].config.value}
								placeholder={formElementsArray[0].config.elementConfig.placeholder}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[0].config.validation,
										formElementsArray[0].id
									)
								}
								{...formElementsArray[0].config.attrValidation}
							/>
							<label
								className={`${
									formElementsArray[0].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[0].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[0].config.validationMessage}
							</p>
						</div>
						<div className="col-md-6 row ml-0">
							{/* <input type={formElementsArray[1].config.elementConfig.type}
                                className={`form_control ${!formElementsArray[1].config.valid && formElementsArray[1].config.touched ? "Invalid" : this.state.searchForm.promotionID.value || this.state.searchForm.binNumber.value ? "disabled1" : ""}`}
                                value={formElementsArray[1].config.value}
                                placeholder={formElementsArray[1].config.elementConfig.placeholder}
                                onChange={(ev) => this.onInputChange(ev, formElementsArray[1].config.validation, formElementsArray[1].id)}
                                {...formElementsArray[1].config.attrValidation} />
                            <label className={`${formElementsArray[1].config.validation.required ? "require" : ""}`}>
                                {formElementsArray[1].config.elementConfig.labelname}</label>
                            <p className="errorMessage">{formElementsArray[1].config.validationMessage}</p> */}
						</div>
					</div>
					<div className="row p-4">
						<div className="col-md-6 row ml-0">
							<input
								type={formElementsArray[1].config.elementConfig.type}
								className={`form_control ${
									!formElementsArray[1].config.valid &&
									formElementsArray[1].config.touched
										? 'Invalid'
										: this.state.searchForm.promotionID.value ||
										  this.state.searchForm.binNumber.value
										? 'disabled1'
										: ''
								}`}
								value={formElementsArray[1].config.value}
								placeholder={formElementsArray[1].config.elementConfig.placeholder}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[1].config.validation,
										formElementsArray[1].id
									)
								}
								{...formElementsArray[1].config.attrValidation}
							/>
							<label
								className={`${
									formElementsArray[1].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[1].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[1].config.validationMessage}
							</p>

							{/* <input type={formElementsArray[2].config.elementConfig.type}
                                className={`form_control ${!formElementsArray[2].config.valid && formElementsArray[2].config.touched ? "Invalid" : this.state.searchForm.promotionID.value || this.state.searchForm.binNumber.value ? "disabled1" : ""}`}
                                value={formElementsArray[2].config.value}
                                placeholder={formElementsArray[2].config.elementConfig.placeholder}
                                onChange={(ev) => this.onInputChange(ev, formElementsArray[2].config.validation, formElementsArray[2].id)}
                                {...formElementsArray[2].config.attrValidation} />
                            <label className={`${formElementsArray[2].config.validation.required ? "require" : ""}`}>
                                {formElementsArray[2].config.elementConfig.labelname}</label>
                            <p className="errorMessage">{formElementsArray[2].config.validationMessage}</p> */}
						</div>
						<div className="col-md-6 row ml-0">
							<input
								type={formElementsArray[2].config.elementConfig.type}
								className={`form_control ${
									!formElementsArray[2].config.valid &&
									formElementsArray[2].config.touched
										? 'Invalid'
										: this.state.searchForm.promotionID.value ||
										  this.state.searchForm.binNumber.value
										? 'disabled1'
										: ''
								}`}
								value={formElementsArray[2].config.value}
								placeholder={formElementsArray[2].config.elementConfig.placeholder}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[2].config.validation,
										formElementsArray[2].id
									)
								}
								{...formElementsArray[2].config.attrValidation}
							/>
							<label
								className={`${
									formElementsArray[2].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[2].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[2].config.validationMessage}
							</p>

							{/* <input type={formElementsArray[3].config.elementConfig.type}
                                className={`form_control ${!formElementsArray[3].config.valid && formElementsArray[3].config.touched ? "Invalid" : this.state.searchForm.promotionID.value ? "disabled1" : ""}`}
                                value={formElementsArray[3].config.value}
                                placeholder={formElementsArray[3].config.elementConfig.placeholder}
                                onChange={(ev) => this.onInputChange(ev, formElementsArray[3].config.validation, formElementsArray[3].id)}
                                {...formElementsArray[3].config.attrValidation} />
                            <label className={`${formElementsArray[3].config.validation.required ? "require" : ""}`}>
                                {formElementsArray[3].config.elementConfig.labelname}</label>
                            <p className="errorMessage">{formElementsArray[3].config.validationMessage}</p> */}
						</div>
					</div>
					<div className="row p-4">
						<div className="col-md-6 row ml-0">
							<input
								type={formElementsArray[3].config.elementConfig.type}
								className={`form_control ${
									!formElementsArray[3].config.valid &&
									formElementsArray[3].config.touched
										? 'Invalid'
										: this.state.searchForm.promotionID.value ||
										  this.state.searchForm.binStartRange.value ||
										  this.state.searchForm.binEndRange.value
										? 'disabled1'
										: ''
								}`}
								value={formElementsArray[3].config.value}
								placeholder={formElementsArray[3].config.elementConfig.placeholder}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[3].config.validation,
										formElementsArray[3].id
									)
								}
								{...formElementsArray[3].config.attrValidation}
							/>
							<label
								className={`${
									formElementsArray[3].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[3].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[3].config.validationMessage}
							</p>
						</div>
						<div className="col-md-6 row ml-0">
							<select
								className={`form_control customSelectIcon ${
									!formElementsArray[4].config.valid &&
									formElementsArray[4].config.touched
										? 'Invalid'
										: this.state.searchForm.promotionID.value
										? 'disabled1'
										: ''
								}`}
								value={formElementsArray[4].config.value}
								onChange={(ev) =>
									this.onInputChange(
										ev,
										formElementsArray[4].config.validation,
										formElementsArray[4].id
									)
								}
							>
								{formElementsArray[4].config.elementConfig.options.map((option) => (
									<option key={option.value} value={option.value}>
										{option.displayValue}
									</option>
								))}
							</select>
							<label
								className={`${
									formElementsArray[4].config.validation.required ? 'require' : ''
								}`}
							>
								{formElementsArray[4].config.elementConfig.labelname}
							</label>
							<p className="errorMessage">
								{formElementsArray[4].config.validationMessage}
							</p>
						</div>
					</div>

					{/* <div className="row p-4">
                    <div className="col-md-6 row ml-0">
                        <span className="channelLink" title="Click to view the recently created/modified BIN promotions"
                        onClick={()=>{this.props.handleViewAll()}}
                        > View All</span>
                    </div>
                    </div> */}
				</div>
				<div className="modal-footer border-0">
					<p
						onClick={() => {
							this.handleFormReset();
						}}
						className="grey-button roboto-b-16"
					>
						<img src={resetIcon} alt="img" /> Reset
					</p>
					<button
						onClick={() => this.props.handleSelection(false)}
						className="cancel-btn roboto-b-16 ml-auto"
					>
						Cancel
					</button>
					<button
						className={
							formElementsArray[0].config.value == '' &&
							formElementsArray[1].config.value == '' &&
							formElementsArray[2].config.value == '' &&
							formElementsArray[3].config.value == '' &&
							formElementsArray[4].config.value == ''
								? 'disabled btnNxtPrev ml-2 roboto-b-16'
								: 'btnNxtPrev ml-2 roboto-b-16'
						}
						onClick={() => {
							this.props.handleSearch(this.state.searchForm);
						}}
					>
						Search
					</button>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BinRangeSearch));
