import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './couponTable.scss';
// import ToolkitProvider, { Search } from '../../utils/reactBootstrapTable2Toolkit';
import * as urlTypes from '../../libraries/offer-service-urls';
import * as RestSvc from '../../libraries/offer-services';

import Toaster from '../../Components/Toaster/Toaster';

const AuditCoupon = ({ ...props }) => {
	const [couponDataList, setCouponDataList] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [maxPage, setMaxPage] = useState(0);
	const [searchInput, setSearchInput] = useState('');

	useEffect(() => {
		//Call the Service
		getCpnVerDetails('Search');
		return () => {};
	}, []);
	useEffect(() => {}, [couponDataList]);
	const getCpnVerDetails = (type, flag) => {
		//HardcodedResponse
		// if (props.action == "COUPONS UPLOADED")
		//     setCouponDataList(couponAddedList);
		// else if (props.action == "COUPONS DELETED")
		//     setCouponDataList(couponDeletedList);
		// else if (props.action == "COUPON LIMIT CHANGED")
		//     setCouponDataList(couponChangedLimitList);
		// else if (props.action == "COUPON EXP DATE CHANGED")
		//     setCouponDataList(couponChangedExpDateList);
		// else if (props.action == "COUPON DISPLAY FLAG CHANGED")
		//     setCouponDataList(couponFlagChangedList);

		let dataToSend;
		if (type == 'Search') {
			dataToSend = {
				getCouponVersionDetailsRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					action: props.action,
					promotionId: props.promoId,
					//    "couponId": ["COUPON_P"],
					versionId: props.versionId,
					pageNumber: 0
				}
			};
		} else if (type == 'CouponSearch') {
			dataToSend = {
				getCouponVersionDetailsRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					action: props.action,
					promotionId: props.promoId,
					couponId: searchInput.split(','),
					versionId: props.versionId,
					pageNumber: 0
				}
			};
		} else {
			dataToSend = {
				getCouponVersionDetailsRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					action: props.action,
					promotionId: props.promoId,
					//    "couponId": ["COUPON_P"],
					versionId: props.versionId,
					pageNumber:
						flag == 'Next'
							? pageNumber
							: flag == 'Previous'
							? pageNumber - 2
							: pageNumber - 1
				}
			};
		}

		RestSvc.postData(urlTypes.coupon_maintenance.getCpnVerDetails, dataToSend)
			.then((response) => {
				var result = response.data.getCouponVersionDetailsReponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.description });
				} else {
					if (result.couponDetails) {
						if (flag == 'Next') setPageNumber(pageNumber + 1);
						else if (flag == 'Previous') setPageNumber(pageNumber - 1);
						setCouponDataList(result.couponDetails);
					}
					if (result.totalRecords && result.maxRecordsPerPage) {
						setMaxPage(
							Math.ceil(
								parseInt(result.totalRecords) / parseInt(result.maxRecordsPerPage)
							)
						);
					}
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
	const onPageChange = (flag) => {
		if (pageNumber >= 1) {
			getCpnVerDetails('Pagination', flag);
		}
	};

	const couponChangedHeading =
		props.action == 'COUPON LIMIT CHANGED' ? 'Coupon Limit' : 'Coupon Expiry Date';
	const couponModifiedFormatter = (cell, row) => {
		return (
			<div>
				<div className="text-center">
					<span className="lightGray p-2 rounded-pill">
						{row.oldValue || row.oldExpDate}
					</span>
					<span className="lightBlue p-2 rounded-pill">
						{row.newValue || row.newExpDate}
					</span>
				</div>
			</div>
		);
	};

	const formatStatus = (value) => {
		return value == 'A' ? 'Active' : 'In Active';
	};

	const columnsCouponAdded = [
		// {
		//     dataField: "promotionId",
		//     text: "Promotion ID",
		//     headerAlign: 'center',
		//     sort: true
		// },
		{
			dataField: 'couponId',
			text: 'Coupon ID',
			headerAlign: 'center',
			searchable: false
		},
		{
			dataField: 'couponStatus',
			text: 'Coupon Status',
			headerAlign: 'center',
			searchable: false,
			formatter: formatStatus
		},
		{
			dataField: 'couponEndDate',
			text: 'Coupon End Date',
			headerAlign: 'center',
			sort: true
		},
		{
			dataField: 'couponLimit',
			text: 'Coupon Limit',
			headerAlign: 'center',
			searchable: false
		}
		// {
		//     dataField: "couponType",
		//     text: "Coupon Type",
		//     headerAlign: 'center',
		//     searchable: false,
		// },
	];
	const columnsCouponChanged = [
		{
			dataField: 'couponId',
			text: 'Coupon ID',
			headerAlign: 'center',
			searchable: false
		},
		// {
		//     dataField: "oldValue",
		//     text: "Old Value",
		//     headerAlign: 'center',
		//     searchable: false,
		// },
		// {
		//     dataField: "newValue",
		//     text: "New Value",
		//     headerAlign: 'center',
		//     sort: true
		// },
		{
			dataField: '',
			text: couponChangedHeading,
			headerAlign: 'center',
			searchable: false,
			formatter: couponModifiedFormatter
		}
	];
	const columnsCouponDeleted = [
		{
			dataField: 'couponId',
			text: 'Coupon ID',
			headerAlign: 'center',
			searchable: false
			// formatter: couponIdFormatter
		}
	];

	return (
		<div className="main-container create-offer-container">
			<div className="flex-mainContent">
				<div id="containerElement" className="right-content pt-1">
					<div className="d-flex">
						<div className="d-flex pb-3">
							<span className="cGray mr-1">Promotion ID: </span>
							<span className="font-weight-bold">{props.promoId}</span>
						</div>
					</div>
					{props.action == 'COUPONS UPLOADED' ? (
						<div>
							<div className="font-weight-bold">Coupons Uploaded</div>
							<div className="d-flex">
								<input
									value={searchInput}
									className="form-control w-25"
									placeholder="Search Coupon Id"
									onChange={(e) => {
										setSearchInput(e.target.value);
									}}
								/>
								<button
									className={
										searchInput
											? 'btn btn-primary'
											: 'disabled1 btn btn-primary'
									}
									onClick={() => {
										getCpnVerDetails('CouponSearch');
									}}
								>
									Search
								</button>
								<button
									className={searchInput ? 'clrBtn' : 'clrBtn disabled1'}
									onClick={() => {
										setSearchInput('');
										getCpnVerDetails('Search');
									}}
								>
									X
								</button>
							</div>
							<div className="pt-2">
								<BootstrapTable
									keyField="couponId"
									// data={couponAddedList}
									data={couponDataList}
									columns={columnsCouponAdded}
								/>
							</div>
							<div className="d-flex">
								<div
									style={{
										'justify-self': 'center',
										flex: 1,
										'text-align': 'center',
										'margin-top': '20px'
									}}
								>
									<button
										className={
											pageNumber == 1
												? 'btn btn-primary m-2 disabled'
												: 'btn btn-primary m-2'
										}
										onClick={() => {
											onPageChange('Previous');
										}}
									>
										Previous
									</button>
									<div className="btn btn-primary m-2">{pageNumber}</div>
									<button
										className={
											maxPage == pageNumber || couponDataList == 1
												? 'disabled btn btn-primary m-2'
												: 'btn btn-primary m-2'
										}
										onClick={() => {
											onPageChange('Next');
										}}
									>
										Next
									</button>
								</div>
							</div>
						</div>
					) : null}
					{props.action == 'COUPONS DELETED' ? (
						<div>
							<div className="font-weight-bold">Coupons Deleted</div>
							<div className="d-flex">
								<input
									value={searchInput}
									className="form-control w-25"
									placeholder="Search Coupon Id"
									onChange={(e) => {
										setSearchInput(e.target.value);
									}}
								/>
								<button
									className={
										searchInput
											? 'btn btn-primary'
											: 'disabled1 btn btn-primary'
									}
									onClick={() => {
										getCpnVerDetails('CouponSearch');
									}}
								>
									Search
								</button>
								<button
									className={searchInput ? 'clrBtn' : 'clrBtn disabled1'}
									onClick={() => {
										setSearchInput('');
										getCpnVerDetails('Search');
									}}
								>
									X
								</button>
							</div>
							<div className="d-flex pt-1">
								Below are the list of coupons that have been Inactivated/Deleted
							</div>
							<div className="pt-2 w-50">
								<BootstrapTable
									keyField="couponId"
									// data={couponDeletedList}
									data={couponDataList}
									columns={columnsCouponDeleted}
								/>
							</div>
							<div className="d-flex w-50">
								<div
									style={{
										'justify-self': 'center',
										flex: 1,
										'text-align': 'center',
										'margin-top': '20px'
									}}
								>
									<button
										className={
											pageNumber == 1
												? 'btn btn-primary m-2 disabled'
												: 'btn btn-primary m-2'
										}
										onClick={() => {
											onPageChange('Previous');
										}}
									>
										Previous
									</button>
									<div className="btn btn-primary m-2">{pageNumber}</div>
									<button
										className={
											maxPage == pageNumber || couponDataList == 1
												? 'disabled btn btn-primary m-2'
												: 'btn btn-primary m-2'
										}
										onClick={() => {
											onPageChange('Next');
										}}
									>
										Next
									</button>
								</div>
							</div>
						</div>
					) : null}
					{props.action == 'COUPON LIMIT CHANGED' ||
					props.action == 'COUPON EXP DATE CHANGED' ? (
						<div>
							<div className="font-weight-bold">Coupons Modified</div>
							<div className="d-flex">
								<input
									value={searchInput}
									className="form-control w-25"
									placeholder="Search Coupon Id"
									onChange={(e) => {
										setSearchInput(e.target.value);
									}}
								/>
								<button
									className={
										searchInput
											? 'btn btn-primary'
											: 'disabled1 btn btn-primary'
									}
									onClick={() => {
										getCpnVerDetails('CouponSearch');
									}}
								>
									Search
								</button>
								<button
									className={searchInput ? 'clrBtn' : 'clrBtn disabled1'}
									onClick={() => {
										setSearchInput('');
										getCpnVerDetails('Search');
									}}
								>
									X
								</button>
							</div>
							<div className="pt-2">
								<BootstrapTable
									keyField="couponId"
									// data={props.action == "COUPON LIMIT CHANGED" ? couponChangedLimitList : couponChangedExpDateList}
									data={couponDataList}
									columns={columnsCouponChanged}
								/>
							</div>
							<div className="d-flex">
								<div
									style={{
										'justify-self': 'center',
										flex: 1,
										'text-align': 'center',
										'margin-top': '20px'
									}}
								>
									<button
										className={
											pageNumber == 1
												? 'btn btn-primary m-2 disabled'
												: 'btn btn-primary m-2'
										}
										onClick={() => {
											onPageChange('Previous');
										}}
									>
										Previous
									</button>
									<div className="btn btn-primary m-2">{pageNumber}</div>
									<button
										className={
											maxPage == pageNumber || couponDataList == 1
												? 'disabled btn btn-primary m-2'
												: 'btn btn-primary m-2'
										}
										onClick={() => {
											onPageChange('Next');
										}}
									>
										Next
									</button>
								</div>
							</div>
						</div>
					) : null}
					{props.action == 'COUPON DISPLAY FLAG CHANGED' ? (
						<div>
							{/* <div className="font-weight-bold">Coupons Modified</div> */}
							<div className="pt-2">
								{couponDataList.map((item, ind) => {
									return (
										<div key={ind} className="container">
											<div className={'rowContainer'}>
												<div className="customLabelContainer">
													<label
														htmlFor="forprogramID"
														className="col	col-form-label"
													>
														{'Allow Coupon To Be Displayed'}
													</label>
												</div>
												<div className="customInputContainer">
													<div className="row mg-5">
														<div>
															<div>
																<div className="d-flex gap70 replacedText">
																	<input
																		type="text"
																		className="form-control lightGray"
																		value={
																			item.oldDisplayFlag.toString() ==
																			'true'
																				? 'Yes'
																				: 'No'
																		}
																	/>
																	<input
																		type="text"
																		className="form-control lightBlue"
																		value={
																			item.newDisplayFlag.toString() ==
																			'true'
																				? 'Yes'
																				: 'No'
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default AuditCoupon;
