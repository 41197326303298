import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import * as _ from 'lodash';
import {
	isObjectEmpty,
	validateMinOverMax,
	validateMaxOverMin,
	validateCSV,
	validateMaxOverMinCsv,
	validateMinOverMaxCsv,
	is_boolean,
	rowClasses
} from '../../../utils/helperFunction';
// import { productData } from './productData';
import { InformationModal } from '../../../MainContent/InformationModal';
import * as RestSvc from '../../../libraries/offer-services';
import * as urlTypes from '../../../libraries/offer-service-urls';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import { CSVReader } from 'react-papaparse';
import { modifiedValueFormatter } from './Audit/auditHelperFunction';
import ToolkitProvider from '../../../utils/reactBootstrapTable2Toolkit';
var itmDescObj = {};
var itmSrcBrandObj = {};

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
	<div className="btn-group" role="group">
		{options.map((option) => {
			const isSelect = currSizePerPage === `${option.page}`;
			return (
				<button
					key={option.text}
					type="button"
					onClick={() => onSizePerPageChange(option.page)}
					className={`btn ${isSelect ? 'btn-primary' : 'btn-secondary'}`}
				>
					{option.text}
				</button>
			);
		})}
	</div>
);

let options = {};

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
	let a = column.text;
	if (a != undefined) {
		a = a.split('$');
		return (
			<div>
				<div>
					{sortElement}
					{filterElement}
				</div>
				<div>
					<b> {a[0]} </b>
				</div>
				<div>
					<b> {a[1]} </b>
				</div>
			</div>
		);
	}
}

const columnsMultiBuyGroup = [
	{
		dataField: 'TableSlNo',
		text: 'Serial No. $ ',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false
	},
	{
		dataField: 'productID',
		text: 'Product ID',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		headerClasses: 'hdrProductId',
		filter: textFilter()
	},
	{
		dataField: 'itemDescription',
		text: 'Description',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false,
		headerClasses: 'hdrProductDesc',
		filter: textFilter()
	},
	{
		dataField: 'trigger',
		text: 'Trigger',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		editable: false,
		editor: {
			type: Type.CHECKBOX,
			value: 'true:false'
		},
		formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
	},
	{
		dataField: 'getReward',
		text: 'Reward',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		editable: false,
		editor: {
			type: Type.CHECKBOX,
			value: 'true:false'
		},
		formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
	},
	{
		dataField: 'minimumPrice',
		text: 'Minimum Price $ Range (0.01-9999999.99)',
		sort: true,
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		validator: (newValue, row, column) => {
			return validateMinOverMax(newValue, row, column);
		},
		formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
	},
	{
		dataField: 'maximumPrice',
		text: 'Maximum Price $ Range (0.01-9999999.99)',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		validator: (newValue, row, column) => {
			return validateMaxOverMin(newValue, row, column);
		},
		formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
	},
	{
		dataField: 'sourceBrand',
		text: 'Source Brand',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false,
		headerClasses: 'hdrProductDesc'
		// filter: textFilter()
	}
];

const columnsScaleProduct = [
	{
		dataField: 'TableSlNo',
		text: 'Serial No. $ ',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false
	},
	{
		dataField: 'merchandizeGroupId',
		text: 'Selling Hierarchy Group Id',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		headerClasses: 'hdrProductId',
		filter: textFilter()
	},
	{
		dataField: 'itemDescription',
		text: 'Description',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false,
		headerClasses: 'hdrProductDesc',
		filter: textFilter()
	},
	{
		dataField: 'trigger',
		text: 'Trigger',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		editable: false,
		editor: {
			type: Type.CHECKBOX,
			value: 'true:false'
		},
		formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
	},
	{
		dataField: 'getReward',
		text: 'Reward',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		editable: false,
		editor: {
			type: Type.CHECKBOX,
			value: 'true:false'
		},
		formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
	},
	{
		dataField: 'minimumPrice',
		text: 'Minimum Price $ Range (0.01-9999999.99)',
		sort: true,
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		validator: (newValue, row, column) => {
			return validateMinOverMax(newValue, row, column);
		},
		formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
	},
	{
		dataField: 'maximumPrice',
		text: 'Maximum Price $ Range (0.01-9999999.99)',
		sort: true,
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		validator: (newValue, row, column) => {
			return validateMaxOverMin(newValue, row, column);
		},
		formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
	},
	{
		dataField: 'sourceBrand',
		text: 'Source Brand',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false,
		headerClasses: 'hdrProductDesc'
		// filter: textFilter()
	}
];

const eligibilityType = {
	MTMB: 'MTMB',
	MultiBuyGroup: 'MultiBuyGroup',
	TXN: 'TXN',
	MultiByProduct: 'MultiByProduct',
	packageMultibuyEligibility: 'packageMultibuyEligibility',
	earnBurnEligibility: 'earnBurnEligibility',
	merchandizeHierarchyEligibility: 'merchandizeHierarchyEligibility'
};

const ProductTableBootstrapNew = (props) => {
	// state and props
	const [products, setProducts] = useState([]);
	const [productID, setProductID] = useState('');
	const [columns, setColumns] = useState('');
	const [eligib, seteligib] = useState('');
	const [selectedProducts, setSelectedProducts] = useState([]);
	// const { SearchBar } = Search;
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState('');
	const [informationTitle] = useState('Information');

	const [merchandizeGroups, setProductsMH] = useState([]);
	const [columnsSH, setColumnsSH] = useState('');
	const [selectedSH, setSelectedSH] = useState([]);
	const [showMHModal, setShowMHModal] = useState(false);

	const tableRef = useRef();
	const tableMerchandiseRef = useRef();

	const getColumns = (type = null) => {
		let col = [];
		if (type === 'multiBuyGroup') {
			col = [...columnsMultiBuyGroup];
			col[3].formatter = (cell, row, rowIndex) =>
				modifiedValueFormatter(cell, row, rowIndex, (obj) => {
					const oldProducts = tableRef?.current?.props?.data || [];
					const newProducts = oldProducts.map((prod) => {
						if (prod.productID == row.productID) {
							return { ...row, trigger: obj.checked };
						}
						return prod;
					});
					setProducts(newProducts);
				});
			col[4].formatter = (cell, row, rowIndex) =>
				modifiedValueFormatter(cell, row, rowIndex, (obj) => {
					const oldProducts = tableRef?.current?.props?.data || [];
					const newProducts = oldProducts.map((prod) => {
						if (prod.productID == row.productID) {
							return { ...row, getReward: obj.checked };
						}
						return prod;
					});
					setProducts(newProducts);
				});
		} else if (type === 'scaleProduct') {
			col = [...columnsScaleProduct];
			col[3].formatter = (cell, row, rowIndex) =>
				modifiedValueFormatter(cell, row, rowIndex, (obj) => {
					const oldProducts = tableMerchandiseRef?.current?.props?.data || [];
					const newProducts = oldProducts.map((prod) => {
						if (prod.merchandizeGroupId[0] == row.merchandizeGroupId[0]) {
							return { ...row, trigger: obj.checked };
						}
						return prod;
					});
					setProductsMH(newProducts);
				});
			col[4].formatter = (cell, row, rowIndex) =>
				modifiedValueFormatter(cell, row, rowIndex, (obj) => {
					const oldProducts = tableMerchandiseRef?.current?.props?.data || [];
					const newProducts = oldProducts.map((prod) => {
						if (prod.merchandizeGroupId[0] == row.merchandizeGroupId[0]) {
							return { ...row, getReward: obj.checked };
						}
						return prod;
					});

					setProductsMH(newProducts);
				});
		}
		return col;
	};

	//useEffects
	useEffect(() => {
		const { eligibility } = props;
		seteligib(eligibility);
		if (eligibility === eligibilityType.MultiBuyGroup) {
			setColumns(getColumns('multiBuyGroup'));
			setColumnsSH(getColumns('scaleProduct'));
		}
		getItemDescription(props.dataProduct, props.dataMer);

		return () => {
			setProducts([]);
			setProductsMH([]);
		};
	}, []);

	useEffect(() => {
		let prdcts = JSON.parse(JSON.stringify(products));

		prdcts = prdcts.map((val) => {
			delete val['TableSlNo'];
			delete val['itemDescription'];
			return val;
		});

		let slngHierarchies = JSON.parse(JSON.stringify(merchandizeGroups));

		slngHierarchies = slngHierarchies.map((val) => {
			delete val['TableSlNo'];
			delete val['itemDescription'];
			return val;
		});

		props.onProductTableChange(prdcts, slngHierarchies);

		let confgrdDataToViewTableRecords = JSON.parse(
			sessionStorage.getItem('configuredDataToViewTableRecords')
		).recordGroupForTable;

		let sizePerPageLst = confgrdDataToViewTableRecords.map((val) => {
			return { text: val, value: val };
		});
		sizePerPageLst.push({
			text: 'All',
			value:
				products.length > merchandizeGroups.length
					? products.length
					: merchandizeGroups.length
		});

		options = {
			sizePerPageRenderer,
			sizePerPageList: sizePerPageLst
		};
	}, [products, merchandizeGroups]);

	// event handlers
	const selectRow = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			let newSelectedProducts = [...selectedProducts];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row.productID);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.productID);
			}
			setSelectedProducts(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			let newSelectedProducts = [...selectedProducts];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.productID;
				});
			}
			setSelectedProducts(newSelectedProducts);
		}
	};
	const selectRowSH = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			let newSelectedProducts = [...selectedSH];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row.merchandizeGroupId);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.merchandizeGroupId);
			}
			setSelectedSH(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			let newSelectedProducts = [...selectedSH];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.merchandizeGroupId;
				});
			}
			setSelectedSH(newSelectedProducts);
		}
	};
	/* const selectRowSH = {
        mode: 'checkbox',
        onSelect: (row, isSelect, rowIndex, e) => {

            let newSelectedProducts = [...selectedSH]
            if (!isSelect) {
                const removeIndex = newSelectedProducts.indexOf(row.merchandizeGroupId)
                newSelectedProducts.splice(removeIndex, 1)
            } else {
                newSelectedProducts.push(row.merchandizeGroupId)
            }
            setSelectedSH(newSelectedProducts)
            
            
            
            
            
        },
        onSelectAll: (isSelect, rows, e) => {
            let newSelectedProducts = [...selectedSH]

            if (!isSelect) {
                newSelectedProducts = []
            } else {
                newSelectedProducts = rows.map(val => {
                    return val.merchandizeGroupId
                })
            }
            setSelectedSH(newSelectedProducts)
            
            
            
        }
    }; */

	const getItemDescription = async (prodcts, merchHiers) => {
		if (prodcts.length) {
			let flatPrdcts = prodcts.map((val) => {
				return val.productID;
			});

			const businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const dataToSend = {
				getItemDescriptionRequest: {
					id: flatPrdcts,
					businessUnitFunctionID: businessUnitFunctionID,
					idType: 'product'
				}
			};

			props.showLoadingButton(true);

			const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
			RestSvc.postData(
				urlTypes.offers_maintenance.getItemDescriptionUrl,
				dataToSend,
				svPromUrl
			)
				.then((response) => {
					props.showLoadingButton(false);
					const { itemDescription } = response.data.getItemDescriptionResponse;

					itemDescription &&
						itemDescription.map((val) => {
							itmDescObj[val.itemID] = val.description;
						});
					itemDescription &&
						itemDescription.map((val) => {
							itmSrcBrandObj[val.itemID] = val.sourceBrand;
						});

					let prdctsWithDesc = prodcts.map((val, index) => {
						return {
							...val,
							TableSlNo: index + 1,
							itemDescription: itmDescObj[val.productID],
							sourceBrand: itmSrcBrandObj[val.productID]
						};
					});
					setProducts(prdctsWithDesc);
					getItemDescriptionSH(merchHiers);
				})
				.catch((error) => {
					props.showLoadingButton(false);
					console.error(error);
				});
		} else {
			setProducts([]);
			getItemDescriptionSH(merchHiers);
		}
	};

	const getItemDescriptionSH = async (prodcts) => {
		if (prodcts.length) {
			let flatPrdcts = prodcts.map((val) => {
				return val?.merchandizeGroupId[0];
			});

			const businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const dataToSend = {
				getItemDescriptionRequest: {
					id: flatPrdcts,
					businessUnitFunctionID: businessUnitFunctionID,
					idType: 'sellingHierarchy'
				}
			};

			props.showLoadingButton(true);

			const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
			RestSvc.postData(
				urlTypes.offers_maintenance.getItemDescriptionUrl,
				dataToSend,
				svPromUrl
			)
				.then((response) => {
					props.showLoadingButton(false);
					const { itemDescription } = response.data.getItemDescriptionResponse;

					itemDescription &&
						itemDescription.map((val) => {
							itmDescObj[val.itemID] = val.description;
						});

					itemDescription &&
						itemDescription.map((val) => {
							itmSrcBrandObj[val.itemID] = val.sourceBrand;
						});

					let prdctsWithDesc = prodcts.map((val, index) => {
						return {
							...val,
							TableSlNo: index + 1,
							itemDescription: itmDescObj[val.merchandizeGroupId[0]],
							sourceBrand: itmSrcBrandObj[val.merchandizeGroupId[0]]
						};
					});
					setProductsMH(prdctsWithDesc);
				})
				.catch((error) => {
					props.showLoadingButton(false);
					console.error(error);
				});
		} else {
			setProductsMH([]);
		}
	};

	const addProduct = async () => {
		let errorMsgs = [];
		if (productID && props.type == 'ITEM_ID') {
			const { eligibility } = props;
			const businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const serviceInput = {
				validateItemRequest: {
					id: [productID],
					businessUnitFunctionID: businessUnitFunctionID,
					idType: 'product'
				}
			};

			if (eligibility === eligibilityType.MultiBuyGroup) {
				if (!products.find((x) => x.productID == productID)) {
					// if (eligibility === eligibilityType.MultiBuyGroup) {

					const serviceResponse = await getValidProductId(serviceInput);
					const { invalidId, itemDescription } =
						serviceResponse.data.validateItemResponse;
					if (invalidId.length == 0) {
						itemDescription &&
							itemDescription.map((val) => {
								itmDescObj[val.itemID] = val.description;
							});

						itemDescription &&
							itemDescription.map((val) => {
								itmSrcBrandObj[val.itemID] = val.sourceBrand;
							});

						setProducts([
							...products,
							{
								TableSlNo: products.length + 1,
								itemDescription: itmDescObj[productID],
								productID: productID,
								maximumPrice: props.defaultValueForAdd.maximumPrice,
								minimumPrice: props.defaultValueForAdd.minimumPrice,
								trigger: props.defaultValueForAdd.trigger,
								getReward: props.defaultValueForAdd.getReward,
								sourceBrand: itmSrcBrandObj[productID]
							}
						]);
					} else {
						// setInformationMsg('Product ' + invalidId.join(', ') + ' is invalid');
						// setInformationModalShow(true);
						errorMsgs.push(
							<>
								<b>Invalid Products</b>
								<p>{`${invalidId.join(',')}`}</p>
							</>
						);
					}
					// }
					// else if (eligibility === eligibilityType.MTMB) {
					//     setProducts([...products, {
					//         // id: state.products[state.products.length - 1].id + 1,
					//         productID: productID,
					//         maximumPrice: props.defaultValueForAdd.maximumPrice,
					//         minimumPrice: props.defaultValueForAdd.minimumPrice,
					//     }])
					// }
				} else {
					// alert('ProductID ' + productID + ' is already present')
					// setInformationMsg('Product ' + productID + ' is already present');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Duplicate Products</b>
							<p>{productID}</p>
						</>
					);
				}
			} else if (eligibility === eligibilityType.MultiByProduct) {
				if (!products.length > 0) {
					if (!products.find((x) => x.productID == productID)) {
						// if (eligibility === eligibilityType.MultiBuyGroup) {

						const serviceResponse = await getValidProductId(serviceInput);
						const { invalidId, itemDescription } =
							serviceResponse.data.validateItemResponse;
						if (invalidId.length == 0) {
							itemDescription &&
								itemDescription.map((val) => {
									itmDescObj[val.itemID] = val.description;
								});

							itemDescription &&
								itemDescription.map((val) => {
									itmSrcBrandObj[val.itemID] = val.sourceBrand;
								});

							setProducts([
								...products,
								{
									TableSlNo: products.length + 1,
									itemDescription: itmDescObj[productID],
									productID: productID,
									maximumPrice: props.defaultValueForAdd.maximumPrice,
									minimumPrice: props.defaultValueForAdd.minimumPrice,
									trigger: props.defaultValueForAdd.trigger,
									getReward: props.defaultValueForAdd.getReward,
									sourceBrand: itmSrcBrandObj[productID]
								}
							]);
						} else {
							// setInformationMsg('Product ' + invalidId.join(', ') + ' is invalid');
							// setInformationModalShow(true);
							errorMsgs.push(
								<>
									<b>Invalid Products</b>
									<p>{`${invalidId.join(',')}`}</p>
								</>
							);
						}
						// }
						// else if (eligibility === eligibilityType.MTMB) {
						//     setProducts([...products, {
						//         // id: state.products[state.products.length - 1].id + 1,
						//         productID: productID,
						//         maximumPrice: props.defaultValueForAdd.maximumPrice,
						//         minimumPrice: props.defaultValueForAdd.minimumPrice,
						//     }])
						// }
					} else {
						// alert('ProductID ' + productID + ' is already present')
						// setInformationMsg('Product ' + productID + ' is already present');
						// setInformationModalShow(true);
						errorMsgs.push(
							<>
								<b>Duplicate Products</b>
								<p>{productID}</p>
							</>
						);
					}
				} else {
					// alert('ProductID ' + productID + ' is already present')
					// setInformationMsg('Product ' + productID + ' is already present');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Cannot add more than one Products</b>
						</>
					);
				}
			} else if (eligibility === eligibilityType.MTMB) {
				let errFlag = false;
				props.MTMBProductTableData.packageMultibuyEligibility.map((pkg) => {
					pkg.group.map((g) => {
						g.multibuyProduct.map((item) => {
							if (item.productID === productID) {
								errFlag = true;
							}
						});
					});
				});
				if (errFlag) {
					// alert('ProductID ' + productID + ' is already present');
					// setInformationMsg('Product ' + productID + ' is already present');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Duplicate Products</b>
							<p>{productID}</p>
						</>
					);
				} else {
					const serviceResponse = await getValidProductId(serviceInput);
					const { invalidId, itemDescription } =
						serviceResponse.data.validateItemResponse;
					if (invalidId.length == 0) {
						itemDescription &&
							itemDescription.map((val) => {
								itmDescObj[val.itemID] = val.description;
							});

						itemDescription &&
							itemDescription.map((val) => {
								itmSrcBrandObj[val.itemID] = val.sourceBrand;
							});

						setProducts([
							...products,
							{
								TableSlNo: products.length + 1,
								itemDescription: itmDescObj[productID],
								productID: productID,
								maximumPrice: props.defaultValueForAdd.maximumPrice,
								minimumPrice: props.defaultValueForAdd.minimumPrice,
								sourceBrand: itmSrcBrandObj[productID]
							}
						]);
					} else {
						// alert('ProductID ' + productID + ' is already present')
						// setInformationMsg('Product ' + productID + ' is invalid');
						// setInformationModalShow(true);
						errorMsgs.push(
							<>
								<b>Invalid Products</b>
								<p>{productID}</p>
							</>
						);
					}
				}
			} else if (eligibility === eligibilityType.packageMultibuyEligibility) {
				let errFlag = false;
				props.group.map((g) => {
					if (g.multibuyProduct != undefined) {
						g.multibuyProduct.map((item) => {
							if (item.productID === productID) {
								errFlag = true;
							}
						});
					}
				});
				if (errFlag) {
					// alert('ProductID ' + productID + ' is already present');
					// setInformationMsg('Product ' + productID + ' is already present');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Duplicate Products</b>
							<p>{productID}</p>
						</>
					);
				} else {
					const serviceResponse = await getValidProductId(serviceInput);
					const { invalidId, itemDescription } =
						serviceResponse.data.validateItemResponse;
					if (invalidId.length == 0) {
						itemDescription &&
							itemDescription.map((val) => {
								itmDescObj[val.itemID] = val.description;
							});
						itemDescription &&
							itemDescription.map((val) => {
								itmSrcBrandObj[val.itemID] = val.sourceBrand;
							});

						setProducts([
							...products,
							{
								TableSlNo: products.length + 1,
								itemDescription: itmDescObj[productID],
								productID: productID,
								maximumPrice: props.defaultValueForAdd.maximumPrice,
								minimumPrice: props.defaultValueForAdd.minimumPrice,
								sourceBrand: itmSrcBrandObj[productID]
							}
						]);
					} else {
						// alert('ProductID ' + productID + ' is already present')
						// setInformationMsg('Product ' + productID + ' is invalid');
						// setInformationModalShow(true);
						errorMsgs.push(
							<>
								<b>Invalid Products</b>
								<p>{productID}</p>
							</>
						);
					}
				}
			} else if (eligibility === eligibilityType.earnBurnEligibility) {
				let errFlag = false;
				props.groupDetail.map((g) => {
					g.multibuyProduct.map((item) => {
						if (item.productID === productID) {
							errFlag = true;
						}
					});
				});
				if (errFlag) {
					// alert('ProductID ' + productID + ' is already present');
					// setInformationMsg('Product ' + productID + ' is already present');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Duplicate Products</b>
							<p>{productID}</p>
						</>
					);
				} else {
					const serviceResponse = await getValidProductId(serviceInput);
					const { invalidId, itemDescription } =
						serviceResponse.data.validateItemResponse;
					if (invalidId.length == 0) {
						itemDescription &&
							itemDescription.map((val) => {
								itmDescObj[val.itemID] = val.description;
							});
						itemDescription &&
							itemDescription.map((val) => {
								itmSrcBrandObj[val.itemID] = val.sourceBrand;
							});
						setProducts([
							...products,
							{
								TableSlNo: products.length + 1,
								itemDescription: itmDescObj[productID],
								productID: productID,
								maximumPrice: props.defaultValueForAdd.maximumPrice,
								minimumPrice: props.defaultValueForAdd.minimumPrice,
								sourceBrand: itmSrcBrandObj[productID]
							}
						]);
					} else {
						// alert('ProductID ' + productID + ' is already present')
						// setInformationMsg('Product ' + productID + ' is invalid');
						// setInformationModalShow(true);
						errorMsgs.push(
							<>
								<b>Invalid Products</b>
								<p>{productID}</p>
							</>
						);
					}
				}
			} else if (eligibility === eligibilityType.TXN) {
				if (!products.find((x) => x.productID == productID)) {
					const serviceResponse = await getValidProductId(serviceInput);
					const { invalidId, itemDescription } =
						serviceResponse.data.validateItemResponse;
					if (invalidId.length == 0) {
						itemDescription &&
							itemDescription.map((val) => {
								itmDescObj[val.itemID] = val.description;
							});
						itemDescription &&
							itemDescription.map((val) => {
								itmSrcBrandObj[val.itemID] = val.sourceBrand;
							});
						setProducts([
							...products,
							{
								TableSlNo: products.length + 1,
								itemDescription: itmDescObj[productID],
								productID: productID,
								maximumPrice: props.defaultValueForAdd.maximumPrice,
								minimumPrice: props.defaultValueForAdd.minimumPrice,
								includeForThreshold: props.defaultValueForAdd.includeForThreshold,
								sourceBrand: itmSrcBrandObj[productID]
							}
						]);
					} else {
						// setInformationMsg('Product ' + invalidId.join(', ') + ' is invalid');
						// setInformationModalShow(true);
						errorMsgs.push(
							<>
								<b>Invalid Products</b>
								<p>{`${invalidId.join(',')}`}</p>
							</>
						);
					}
				} else {
					// alert('ProductID ' + productID + ' is already present')
					// setInformationMsg('Product ' + productID + ' is already present');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Duplicate Products</b>
							<p>{productID}</p>
						</>
					);
				}
			}

			setProductID('');
			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		} else if (productID && props.type == 'MERCHANDIZE') {
			const businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const serviceInput = {
				validateItemRequest: {
					id: [productID],
					businessUnitFunctionID: businessUnitFunctionID,
					idType: 'sellingHierarchy'
				}
			};

			let errFlag = false;

			merchandizeGroups.map((item) => {
				if (item.merchandizeGroupId[0] === productID) {
					errFlag = true;
				}
			});
			if (errFlag) {
				// alert('ProductID ' + merchandizeGroupId + ' is already present');
				// setInformationMsg('Selling Hierarchy Group Id ' + merchandizeGroupId + ' is already present');
				// setInformationModalShow(true);
				errorMsgs.push(
					<>
						<b>Duplicate Selling Hierarchy Group Id</b>
						<p>{productID}</p>
					</>
				);
			} else {
				const serviceResponse = await getValidMHId(serviceInput);
				const { invalidId, itemDescription } = serviceResponse.data.validateItemResponse;
				if (invalidId.length == 0) {
					itemDescription &&
						itemDescription.map((val) => {
							itmDescObj[val.itemID] = val.description;
						});
					itemDescription &&
						itemDescription.map((val) => {
							itmSrcBrandObj[val.itemID] = val.sourceBrand;
						});
					setProductsMH([
						...merchandizeGroups,
						{
							TableSlNo: merchandizeGroups.length + 1,
							itemDescription: itmDescObj[productID],
							merchandizeGroupId: [productID],
							trigger: props.defaultValueForAdd.trigger,
							getReward: props.defaultValueForAdd.getReward,
							maximumPrice: props.defaultValueForAdd.maximumPrice,
							minimumPrice: props.defaultValueForAdd.minimumPrice,
							sourceBrand: itmSrcBrandObj[productID]
						}
					]);
				} else {
					// setInformationMsg('Selling Hierarchy Group Id ' + invalidId.join(', ') + ' is invalid');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Invalid Selling Hierarchy Group Id</b>
							<p>{`${invalidId.join(',')}`}</p>
						</>
					);
				}
			}

			setProductID('');
			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		}
	};

	const getValidMHId = (dataToSend) => {
		const {
			showLoadingButton,
			brandEligibility: { brand }
		} = props;
		showLoadingButton(true);

		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		return RestSvc.postData('/rest/offers/configurator/validateItems', dataToSend, svPromUrl)
			.then((response) => {
				showLoadingButton(false);
				const res = JSON.parse(JSON.stringify(response));
				if (brand) {
					const { invalidId = [], itemDescription } = res.data.validateItemResponse || {};
					let _invalidId = [...invalidId],
						_itemDescription = [],
						_validId = [];

					itemDescription?.forEach((item) => {
						if (item.sourceBrand == brand) {
							_itemDescription.push(item);
							_validId.push(item.itemID);
						} else {
							_invalidId.push(item.itemID);
						}
					});

					res.data.validateItemResponse = {
						validId: _validId,
						invalidId: _invalidId,
						itemDescription: _itemDescription
					};
				}
				return res;
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	const buttonRef = React.createRef();

	const handleOpenDialog = (e) => {
		// Note that the ref is set async, so it might be null at some point
		if (buttonRef.current) {
			buttonRef.current.open(e);
		}
	};

	const handleOnFileLoad = async (data) => {
		let errorMsgs = [];
		// let csvUploadStatus = validateCSV(props.keys, data);
		let csvUploadStatus = validateCSV(
			props.type == 'ITEM_ID' ? props.keys : props.keysSH,
			data
		);

		if (csvUploadStatus == 'CORRECT') {
			let csvHeaders = data[0].data;
			let tmpData = [];

			for (let i = 1; i < data.length - 1; i++) {
				let tmpObj = {};

				for (let j = 0; j < csvHeaders.length; j++) {
					tmpObj[csvHeaders[j]] = data[i].data[j]
						? data[i].data[j].toString().trim()
						: data[i].data[j];
				}

				tmpData.push(tmpObj);
			}

			const { eligibility } = props;
			let parsedData = tmpData;
			let duplicateValues;
			let Failedcnt = 0;
			let alreadyAddedProducts = [];
			let alreadyAdded = [];
			if (props.type == 'ITEM_ID') {
				let productFlatArray = [...parsedData];

				productFlatArray = productFlatArray.map((val) => {
					return val.productID;
				});

				let businessUnitFunctionID = JSON.parse(
					sessionStorage.getItem('taggedBrandToLoggedInUser')
				).businessUnitGroupFunctionID;
				const serviceInput = {
					validateItemRequest: {
						id: productFlatArray,
						businessUnitFunctionID: businessUnitFunctionID,
						idType: 'product'
					}
				};

				const serviceResponse = await getValidProductId(serviceInput);

				const { invalidId, duplicateId, validId, itemDescription } =
					serviceResponse.data.validateItemResponse;

				itemDescription &&
					itemDescription.map((val) => {
						itmDescObj[val.itemID] = val.description;
					});
				itemDescription &&
					itemDescription.map((val) => {
						itmSrcBrandObj[val.itemID] = val.sourceBrand;
					});
				let tmpParsData = [];

				validId.map((val) => {
					for (let i = 0; i < parsedData.length; i++) {
						if (val == parsedData[i].productID) {
							tmpParsData.push(parsedData[i]);
						}
					}
				});

				parsedData = tmpParsData;

				if (eligibility === eligibilityType.MTMB) {
					props.MTMBProductTableData.packageMultibuyEligibility.map((pkg) => {
						pkg.group.map((g) => {
							g.multibuyProduct.map((item) => {
								alreadyAddedProducts.push(item);
							});
						});
					});
				}
				if (eligibility === eligibilityType.MTMB) {
					duplicateValues = _.intersectionBy(
						alreadyAddedProducts,
						parsedData,
						'productID'
					);
					//  parsedData = parsedData.filter(val => !alreadyAddedProducts.includes((a)=>{a.productID==val.productID}));
					parsedData = _.reject(parsedData, function (e1) {
						return _.find(duplicateValues, function (e2) {
							return e1.productID == e2.productID;
						});
					});
				}
				//  else {
				//     duplicateValues = _.intersectionBy(products, parsedData, 'productID');
				// }
				else if (eligibility === eligibilityType.packageMultibuyEligibility) {
					props.group.map((g) => {
						if (g.multibuyProduct != undefined) {
							g.multibuyProduct.map((item) => {
								alreadyAddedProducts.push(item);
							});
						}
					});

					duplicateValues = _.intersectionBy(
						alreadyAddedProducts,
						parsedData,
						'productID'
					);
					//  parsedData = parsedData.filter(val => !alreadyAddedProducts.includes((a)=>{a.productID==val.productID}));
					parsedData = _.reject(parsedData, function (e1) {
						return _.find(duplicateValues, function (e2) {
							return e1.productID == e2.productID;
						});
					});
				} else if (eligibility === eligibilityType.earnBurnEligibility) {
					props.groupDetail.map((g) => {
						g.multibuyProduct.map((item) => {
							alreadyAddedProducts.push(item);
						});
					});

					duplicateValues = _.intersectionBy(
						alreadyAddedProducts,
						parsedData,
						'productID'
					);
					//  parsedData = parsedData.filter(val => !alreadyAddedProducts.includes((a)=>{a.productID==val.productID}));
					parsedData = _.reject(parsedData, function (e1) {
						return _.find(duplicateValues, function (e2) {
							return e1.productID == e2.productID;
						});
					});
				} else {
					duplicateValues = _.intersectionBy(products, parsedData, 'productID');
				}

				duplicateValues = duplicateValues.map((val) => {
					return val.productID;
				});

				/* let productFlatArray = [...parsedData]
            productFlatArray = productFlatArray.map(val => {
                return val.productID
            })
            let businessUnitFunctionID = JSON.parse(sessionStorage.getItem("taggedBrandToLoggedInUser")).businessUnitGroupFunctionID
            const serviceInput = {
                "validateItemRequest": {
                    "id": productFlatArray,
                    "businessUnitFunctionID": businessUnitFunctionID,
                    "idType": "product"
                }
            }
            
            // getValidProductId(serviceInput);
            const serviceResponse = await getValidProductId(serviceInput);
            
            const { invalidId } = serviceResponse.data.validateItemResponse
            const { duplicateId } = serviceResponse.data.validateItemResponse */
				Failedcnt = productFlatArray.length - validId.length;
				if (duplicateId && duplicateId.length > 0) {
					// duplicateValues=duplicateValues.concat(duplicateId);
					// duplicateValues= duplicateValues.filter((it, i, ar) => ar.indexOf(it) === i);
					//Failedcnt=Failedcnt+duplicateId.length;
					errorMsgs.push(
						<>
							<b>Duplicate product id(s) :{duplicateId.length}</b>
							<p>{`${duplicateId.join(', ')}`}</p>
						</>
					);
				}
				if (duplicateValues.length > 0) {
					// alert('Duplicate product ID are removed ' + duplicateValues.join(', '))
					Failedcnt = Failedcnt + duplicateValues.length;
					errorMsgs.push(
						<>
							<b>Product id(s) already added:{duplicateValues.length}</b>
							<p>{`${duplicateValues.join(', ')}`}</p>
						</>
					);
				}
				if (invalidId.length > 0) {
					//Failedcnt=Failedcnt+invalidId.length;
					// errorMsgs = `${errorMsgs} Products  ${invalidId.join(', ')} are invalid. <br>`
					errorMsgs.push(
						<>
							<b>Invalid product id(s) : {invalidId.length}</b>
							<p>{`${invalidId.join(', ')}`}</p>
						</>
					);
				}

				// parsedData = parsedData.filter(val => {
				//     return !val.productID.includes(invalidId)
				// })
				parsedData = parsedData.filter((val) => !invalidId.includes(val.productID));

				//minimum maximum validation

				let removedmaxvalRow = parsedData.filter((val) => {
					let maxvaliatin = validateMaxOverMinCsv(val.maximumPrice, val);
					return !maxvaliatin;
				});
				removedmaxvalRow = removedmaxvalRow.map((val) => {
					return val.productID;
				});
				if (removedmaxvalRow.length > 0) {
					Failedcnt = Failedcnt + removedmaxvalRow.length;
					// errorMsgs = `${errorMsgs} Products ${removedRow.join(', ')} are removed, trigger and rewared columns were empty.`
					errorMsgs.push(
						<>
							<b>
								Entered maximum unit price value should be greater than minimum unit
								price and must be between (0.01 to 999999.99) and only accepting two
								decimal value :{removedmaxvalRow.length}{' '}
							</b>
							<p>{` ${removedmaxvalRow.join(', ')}`}</p>
						</>
					);
				}

				parsedData = parsedData.filter((val) =>
					validateMaxOverMinCsv(val.maximumPrice, val)
				);
				//minimum validation
				let removedminvalRow = parsedData.filter((val) => {
					let maxvaliatin = validateMinOverMaxCsv(val.minimumPrice, val);
					return !maxvaliatin;
				});
				removedminvalRow = removedminvalRow.map((val) => {
					return val.productID;
				});
				if (removedminvalRow.length > 0) {
					Failedcnt = Failedcnt + removedminvalRow.length;

					errorMsgs.push(
						<>
							<b>
								Entered minimum unit price value should be less than maximum unit
								price and must be between (0.01 to 9999999.99) and only accepting
								two decimal value :{removedminvalRow.length}{' '}
							</b>
							<p>{` ${removedminvalRow.join(', ')}`}</p>
						</>
					);
				}

				parsedData = parsedData.filter((val) =>
					validateMinOverMaxCsv(val.minimumPrice, val)
				);

				if (parsedData.length >= 0) {
					let mergedData = [...products, ...parsedData];
					mergedData = _.uniqBy(mergedData, function (e) {
						return e.productID;
					});
					if (eligibility === eligibilityType.MultiBuyGroup) {
						let removedRow = mergedData.filter((val) => {
							return val.trigger == '' && val.getReward == '';
						});
						removedRow = removedRow.map((val) => {
							return val.productID;
						});

						// alert('product IDs ' + removedRow.join(', ') + ' are removed, trigger and rewared columns were empty');
						if (removedRow.length > 0) {
							Failedcnt = Failedcnt + removedRow.length;
							// errorMsgs = `${errorMsgs} Products ${removedRow.join(', ')} are removed, trigger and rewared columns were empty.`
							errorMsgs.push(
								<>
									<b>
										Trigger/Reward not defined for the product id(s) :{' '}
										{removedRow.length}
									</b>
									<p>{` ${removedRow.join(', ')}`}</p>
								</>
							);
						}
						mergedData = mergedData.filter((val) => {
							return val.trigger !== '' || val.getReward !== '';
						});

						mergedData = mergedData.map((val) => {
							if (val.trigger == '' || val.getReward == '') {
								return val.trigger == ''
									? { ...val, trigger: 'false' }
									: { ...val, getReward: 'false' };
							}
							return val;
						});
						//validation for MS office issue
						mergedData = mergedData.map((val) => {
							if (val.trigger && val.getReward) {
								return {
									...val,
									trigger: val.trigger.toString().toLowerCase().trim(),
									getReward: val.getReward.toString().toLowerCase().trim()
								};
							} else if (val.trigger) {
								return {
									...val,
									trigger: val.trigger.toString().toLowerCase().trim()
								};
							} else if (val.getReward) {
								return {
									...val,
									getReward: val.getReward.toString().toLowerCase().trim()
								};
							} else if (val.includeForThreshold) {
								return {
									...val,
									includeForThreshold: val.includeForThreshold
										.toString()
										.toLowerCase()
										.trim()
								};
							}

							return { ...val };
						});

						//validation on true and false
						let returntriggerdata = mergedData.filter((val) => {
							let checktriggerval = is_boolean(val.trigger);
							return checktriggerval;
						});
						returntriggerdata = returntriggerdata.map((val) => {
							return val.productID;
						});
						if (returntriggerdata.length > 0) {
							Failedcnt = Failedcnt + returntriggerdata.length;

							errorMsgs.push(
								<>
									<b>
										Trigger must be eaither true or false for the product id(s)
										: {returntriggerdata.length}
									</b>
									<p>{` ${returntriggerdata.join(', ')}`}</p>
								</>
							);
						}

						mergedData = mergedData.filter((val) => {
							let checktriggerval1 = is_boolean(val.trigger);
							return !checktriggerval1;
						});

						//validation on reward true and false
						let returnrewarddata = mergedData.filter((val) => {
							let checkrewardval = is_boolean(val.getReward);
							return checkrewardval;
						});
						returnrewarddata = returnrewarddata.map((val) => {
							return val.productID;
						});
						if (returnrewarddata.length > 0) {
							Failedcnt = Failedcnt + returnrewarddata.length;

							errorMsgs.push(
								<>
									<b>
										Reward must be eaither true or false for the product id(s) :{' '}
										{returnrewarddata.length}
									</b>
									<p>{` ${returnrewarddata.join(', ')}`}</p>
								</>
							);
						}

						mergedData = mergedData.filter((val) => {
							let checkrewardval1 = is_boolean(val.getReward);
							return !checkrewardval1;
						});
					}
					if (eligibility === eligibilityType.TXN) {
						mergedData = mergedData.map((val) => {
							//if (val.includeForThreshold){

							return {
								...val,
								includeForThreshold: val.includeForThreshold
									.toString()
									.toLowerCase()
									.trim()
							};
							//}
						});

						let returntriggerdata = mergedData.filter((val) => {
							let checktriggerval = is_boolean(val.includeForThreshold);
							return checktriggerval;
						});
						returntriggerdata = returntriggerdata.map((val) => {
							return val.productID;
						});
						if (returntriggerdata.length > 0) {
							Failedcnt = Failedcnt + returntriggerdata.length;

							errorMsgs.push(
								<>
									<b>
										Include For Threshold must be either true or false for the
										product id(s) : {returntriggerdata.length}
									</b>
									<p>{` ${returntriggerdata.join(', ')}`}</p>
								</>
							);
						}

						mergedData = mergedData.filter((val) => {
							let checktriggerval1 = is_boolean(val.includeForThreshold);
							return !checktriggerval1;
						});
					}

					errorMsgs.push(
						<>
							<b>Product id(s) uploaded successfully: </b>
							<p>{` ${tmpData.length - Failedcnt}`}</p>
							<b>Product id(s) failed to upload: </b>
							<p>{` ${Failedcnt}`}</p>
						</>
					);

					mergedData = mergedData.map((val, index) => {
						return {
							...val,
							TableSlNo: index + 1,
							itemDescription: itmDescObj[val.productID],
							sourceBrand: itmSrcBrandObj[val.productID]
						};
					});

					setProducts(mergedData);
				}
			} else if (props.type == 'MERCHANDIZE') {
				let mHFlatArray = [...parsedData];
				mHFlatArray = mHFlatArray.map((val) => {
					return val.merchandizeGroupId;
				});
				let businessUnitFunctionID = JSON.parse(
					sessionStorage.getItem('taggedBrandToLoggedInUser')
				).businessUnitGroupFunctionID;
				const serviceInput = {
					validateItemRequest: {
						id: mHFlatArray,
						businessUnitFunctionID: businessUnitFunctionID,
						idType: 'sellingHierarchy'
					}
				};

				const serviceResponse = await getValidMHId(serviceInput);

				const { invalidId, duplicateId, validId, itemDescription } =
					serviceResponse.data.validateItemResponse;

				itemDescription &&
					itemDescription.map((val) => {
						itmDescObj[val.itemID] = val.description;
					});
				itemDescription &&
					itemDescription.map((val) => {
						itmSrcBrandObj[val.itemID] = val.sourceBrand;
					});
				let tmpParsData = [];

				validId.map((val) => {
					for (let i = 0; i < parsedData.length; i++) {
						if (val == parsedData[i].merchandizeGroupId) {
							tmpParsData.push(parsedData[i]);
						}
					}
				});

				parsedData = tmpParsData;

				if (eligibility === eligibilityType.packageMultibuyEligibility) {
					props.ScaleMerchTableData.packageMultibuyEligibility.map((pkg) => {
						pkg.group.map((g) => {
							g.merchandizeHierarchyGroup.map((item) => {
								alreadyAdded.push(item);
							});
						});
					});
				} else if (eligibility === eligibilityType.packageSH) {
					props.group.map((g) => {
						if (g.merchandizeHierarchyGroup != undefined) {
							g.merchandizeHierarchyGroup.map((item) => {
								alreadyAdded.push(item);
							});
						}
					});
				} else if (eligibility === eligibilityType.earnBurnEligibility) {
					props.groupDetail.map((g) => {
						g.merchandizeHierarchyGroup.map((item) => {
							alreadyAdded.push(item);
						});
					});
				}
				if (
					eligibility === eligibilityType.packageMultibuyEligibility ||
					eligibility === eligibilityType.earnBurnEligibility ||
					eligibility === eligibilityType.packageSH
				) {
					parsedData = parsedData.map((val) => {
						return { ...val, merchandizeGroupId: [val.merchandizeGroupId] };
					});
					//  duplicateValues = _.intersectionBy(alreadyAdded, parsedData, 'merchandizeGroupId');
					duplicateValues = intersectionBy(
						alreadyAdded,
						parsedData,
						(x) => x.merchandizeGroupId[0]
					);
					parsedData = _.reject(parsedData, function (e1) {
						return _.find(duplicateValues, function (e2) {
							return e1.merchandizeGroupId[0] == e2.merchandizeGroupId[0];
						});
					});
					parsedData = parsedData.map((val) => {
						return { ...val, merchandizeGroupId: val.merchandizeGroupId[0] };
					});
				} else {
					const merchandizeGroupsFlat = merchandizeGroups.map((val) => {
						return { ...val, merchandizeGroupId: val.merchandizeGroupId[0] };
					});
					duplicateValues = _.intersectionBy(
						merchandizeGroupsFlat,
						parsedData,
						'merchandizeGroupId'
					);
					parsedData = _.reject(parsedData, function (e1) {
						return _.find(duplicateValues, function (e2) {
							return e1.merchandizeGroupId == e2.merchandizeGroupId;
						});
					});
				}
				duplicateValues = duplicateValues.map((val) => {
					return val.merchandizeGroupId;
				});

				/* let mHFlatArray = [...parsedData]
            mHFlatArray = mHFlatArray.map(val => {
                return val.merchandizeGroupId
            })
            let businessUnitFunctionID = JSON.parse(sessionStorage.getItem("taggedBrandToLoggedInUser")).businessUnitGroupFunctionID
            const serviceInput = {
                "validateItemRequest": {
                    "id": mHFlatArray,
                    "businessUnitFunctionID": businessUnitFunctionID,
                    "idType": "sellingHierarchy"
                }
            }

            const serviceResponse = await getValidMHId(serviceInput);

            const { invalidId } = serviceResponse.data.validateItemResponse
            const { duplicateId } = serviceResponse.data.validateItemResponse */

				Failedcnt = mHFlatArray.length - validId.length;

				if (duplicateId && duplicateId.length > 0) {
					// duplicateValues=duplicateValues.concat(duplicateId);
					// duplicateValues= duplicateValues.filter((it, i, ar) => ar.indexOf(it) === i);
					//Failedcnt=Failedcnt+duplicateId.length;
					errorMsgs.push(
						<>
							<b>Duplicate selling hierarchy id(s) :{duplicateId.length}</b>
							<p>{`${duplicateId.join(', ')}`}</p>
						</>
					);
				}
				if (duplicateValues.length > 0) {
					// alert('Duplicate product ID are removed ' + duplicateValues.join(', '))
					Failedcnt = Failedcnt + duplicateValues.length;
					errorMsgs.push(
						<>
							<b>Selling hierarchy id(s) already added:{duplicateValues.length}</b>
							<p>{`${duplicateValues.join(', ')}`}</p>
						</>
					);
				}
				if (invalidId.length > 0) {
					//Failedcnt=Failedcnt+invalidId.length;
					// errorMsgs = `${errorMsgs} Products  ${invalidId.join(', ')} are invalid. <br>`
					errorMsgs.push(
						<>
							<b>Invalid selling hierarchy id(s) : {invalidId.length}</b>
							<p>{`${invalidId.join(', ')}`}</p>
						</>
					);
				}

				parsedData = parsedData.filter(
					(val) => !invalidId.includes(val.merchandizeGroupId)
				);

				//minimum maximum validation

				let removedmaxvalRow = parsedData.filter((val) => {
					let maxvaliatin = validateMaxOverMinCsv(val.maximumPrice, val);
					return !maxvaliatin;
				});
				removedmaxvalRow = removedmaxvalRow.map((val) => {
					return val.merchandizeGroupId;
				});
				if (removedmaxvalRow.length > 0) {
					Failedcnt = Failedcnt + removedmaxvalRow.length;
					// errorMsgs = `${errorMsgs} Products ${removedRow.join(', ')} are removed, trigger and rewared columns were empty.`
					errorMsgs.push(
						<>
							<b>
								Entered maximum unit price value should be greater than minimum unit
								price and must be between (0.01 to 999999.99) and only accepting two
								decimal value :{removedmaxvalRow.length}{' '}
							</b>
							<p>{` ${removedmaxvalRow.join(', ')}`}</p>
						</>
					);
				}

				parsedData = parsedData.filter((val) =>
					validateMaxOverMinCsv(val.maximumPrice, val)
				);
				//minimum validation
				let removedminvalRow = parsedData.filter((val) => {
					let maxvaliatin = validateMinOverMaxCsv(val.minimumPrice, val);
					return !maxvaliatin;
				});
				removedminvalRow = removedminvalRow.map((val) => {
					return val.merchandizeGroupId;
				});
				if (removedminvalRow.length > 0) {
					Failedcnt = Failedcnt + removedminvalRow.length;

					errorMsgs.push(
						<>
							<b>
								Entered minimum unit price value should be less than maximum unit
								price and must be between (0.01 to 9999999.99) and only accepting
								two decimal value :{removedminvalRow.length}{' '}
							</b>
							<p>{` ${removedminvalRow.join(', ')}`}</p>
						</>
					);
				}

				parsedData = parsedData.filter((val) =>
					validateMinOverMaxCsv(val.minimumPrice, val)
				);

				if (parsedData.length >= 0) {
					let mergedData = [...merchandizeGroups, ...parsedData];
					mergedData = _.uniqBy(mergedData, function (e) {
						return e.merchandizeGroupId;
					});

					let removedRow = mergedData.filter((val) => {
						return val.trigger == '' && val.getReward == '';
					});
					removedRow = removedRow.map((val) => {
						return val.merchandizeGroupId;
					});

					if (removedRow.length > 0) {
						Failedcnt = Failedcnt + removedRow.length;
						// errorMsgs = `${errorMsgs} Selling Hierarchy Group Ids ${removedRow.join(', ')} are removed, trigger and rewared columns were empty.`
						errorMsgs.push(
							<>
								<b>
									Trigger/Reward not defined for the Selling hierarchy id(s) :
									{removedRow.length}
								</b>
								<p>{` ${removedRow.join(', ')}`}</p>
							</>
						);
					}

					mergedData = mergedData.filter((val) => {
						return val.trigger !== '' || val.getReward !== '';
					});

					mergedData = mergedData.map((val) => {
						if (val.trigger == '' || val.getReward == '') {
							return val.trigger == ''
								? { ...val, trigger: 'false' }
								: { ...val, getReward: 'false' };
						}
						return val;
					});

					//validation for MS office issue
					mergedData = mergedData.map((val) => {
						if (val.trigger && val.getReward) {
							return {
								...val,
								trigger: val.trigger.toString().toLowerCase().trim(),
								getReward: val.getReward.toString().toLowerCase().trim()
							};
						} else if (val.trigger) {
							return { ...val, trigger: val.trigger.toString().toLowerCase().trim() };
						} else if (val.getReward) {
							return {
								...val,
								getReward: val.getReward.toString().toLowerCase().trim()
							};
						} else if (val.includeForThreshold) {
							return {
								...val,
								includeForThreshold: val.includeForThreshold
									.toString()
									.toLowerCase()
									.trim()
							};
						}

						return { ...val };
					});

					//validation on true and false
					if (
						eligibility === eligibilityType.packageMultibuyEligibility ||
						eligibility === eligibilityType.earnBurnEligibility ||
						eligibility === eligibilityType.packageSH
					) {
						//add condiction
					} else {
						let returntriggerdata = mergedData.filter((val) => {
							let checktriggerval = is_boolean(val.trigger);
							return checktriggerval;
						});
						returntriggerdata = returntriggerdata.map((val) => {
							return val.merchandizeGroupId;
						});
						if (returntriggerdata.length > 0) {
							Failedcnt = Failedcnt + returntriggerdata.length;

							errorMsgs.push(
								<>
									<b>
										Trigger must be eaither true or false for the Selling
										hierarchy id(s) : {returntriggerdata.length}
									</b>
									<p>{` ${returntriggerdata.join(', ')}`}</p>
								</>
							);
						}

						mergedData = mergedData.filter((val) => {
							let checktriggerval1 = is_boolean(val.trigger);
							return !checktriggerval1;
						});

						//validation on reward true and false
						let returnrewarddata = mergedData.filter((val) => {
							let checkrewardval = is_boolean(val.getReward);
							return checkrewardval;
						});
						returnrewarddata = returnrewarddata.map((val) => {
							return val.merchandizeGroupId;
						});
						if (returnrewarddata.length > 0) {
							Failedcnt = Failedcnt + returnrewarddata.length;

							errorMsgs.push(
								<>
									<b>
										Reward must be eaither true or false for the Selling
										hierarchy id(s) : {returnrewarddata.length}
									</b>
									<p>{` ${returnrewarddata.join(', ')}`}</p>
								</>
							);
						}

						mergedData = mergedData.filter((val) => {
							let checkrewardval1 = is_boolean(val.getReward);
							return !checkrewardval1;
						});
					}

					mergedData = mergedData.map((val) => {
						return {
							...val,
							merchandizeGroupId:
								val.merchandizeGroupId instanceof Array
									? val.merchandizeGroupId
									: [val.merchandizeGroupId]
						};
					});

					errorMsgs.push(
						<>
							<b>Selling hierarchy id(s) uploaded successfully: </b>
							<p>{` ${tmpData.length - Failedcnt}`}</p>
							<b>Selling hierarchy id(s) failed to upload: </b>
							<p>{` ${Failedcnt}`}</p>
						</>
					);

					mergedData = mergedData.map((val, index) => {
						return {
							...val,
							TableSlNo: index + 1,
							itemDescription: itmDescObj[val.merchandizeGroupId[0]],
							sourceBrand: itmSrcBrandObj[val.merchandizeGroupId[0]]
						};
					});

					setProductsMH(mergedData);
				}
			}
			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		} else {
			errorMsgs.push(
				<>
					<b>The uploaded CSV format is incorrect. The colum headers must be: </b>
					<p>{csvUploadStatus}</p>
				</>
			);

			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		}
	};

	const handleOnError = () => {};

	const handleOnRemoveFile = () => {};

	const getValidProductId = (dataToSend) => {
		const { showLoadingButton } = props;
		const brand = props?.brandEligibility?.brand;
		showLoadingButton(true);

		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		return RestSvc.postData('/rest/offers/configurator/validateItems', dataToSend, svPromUrl)
			.then((response) => {
				showLoadingButton(false);
				const res = JSON.parse(JSON.stringify(response));
				if (brand) {
					const { invalidId = [], itemDescription } = res.data.validateItemResponse || {};
					let _invalidId = [...invalidId],
						_itemDescription = [],
						_validId = [];

					itemDescription?.forEach((item) => {
						if (item.sourceBrand == brand) {
							_itemDescription.push(item);
							_validId.push(item.itemID);
						} else {
							_invalidId.push(item.itemID);
						}
					});

					res.data.validateItemResponse = {
						validId: _validId,
						invalidId: _invalidId,
						itemDescription: _itemDescription
					};
				}
				return res;
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	const deleteSelected = () => {
		let newProducts = [...products];
		let newMerchandize = [...merchandizeGroups];

		if (selectedProducts.length > 0) {
			newProducts = newProducts.filter((val) => {
				return !selectedProducts.includes(val.productID);
			});

			newProducts = newProducts.map((val, index) => {
				return { ...val, TableSlNo: index + 1 };
			});

			setProducts(newProducts);
			props.onProductTableChange(newProducts, merchandizeGroups);
			setSelectedProducts([]);
		}
		if (selectedSH.length > 0) {
			newMerchandize = newMerchandize.filter((val) => {
				return !selectedSH.includes(val.merchandizeGroupId);
			});

			newMerchandize = newMerchandize.map((val, index) => {
				return { ...val, TableSlNo: index + 1 };
			});

			setProductsMH(newMerchandize);
			props.onProductTableChange(products, newMerchandize);
			setSelectedSH([]);
		}
	};

	/* 
    const deleteSelected = () => {
        let newProducts = [...products]
        let newMerchandize = [...merchandizeGroups];

        
        if (selectedProducts.length > 0) {
            newProducts = newProducts.filter((val, index) => {
                return !selectedProducts.includes(val.productID)
            })
            
            newProducts = newProducts.map((val, index, arrayobj) => {
                return { ...val, TableSlNo: index+1 };
            })

            setProducts(newProducts)       
            props.onProductTableChange(newProducts,merchandizeGroups);
            setSelectedProducts([])
            if (selectedSH.length > 0) {
                newMerchandize = newMerchandize.filter((val, index) => {
                    return !selectedSH.includes(val.merchandizeGroupId)
                })
                
                newMerchandize = newMerchandize.map((val, index, arrayobj) => {
                    return { ...val, TableSlNo: index+1 };
                })
                
                setProductsMH(newMerchandize)
                props.onProductTableChange(products,newMerchandize);
                setSelectedSH([])
            }
        }
    } */

	/* todo: this function is not required */
	const handleTableChange = (oldValue, newValue, row) => {
		let newProducts = [...products];
		newProducts.map((val) => {
			if (val.productID == row.productID) {
				return row;
			}
			return val;
		});
		setProducts(newProducts);
	};
	//for search
	const toggleMHPopup = (flag) => {
		setShowMHModal(flag);
		if (flag) {
			var selectedMerchList = merchandizeGroups.map((val) => {
				return val.merchandizeGroupId[0];
			});
			document
				.getElementById(
					`modalMHFrm${props.currentPkgIndex ? props.currentPkgIndex : ''}${
						props.currentGroupIndex ? props.currentGroupIndex : ''
					}`
				)
				.contentWindow.loadTreeStructure(selectedMerchList);
		}
	};

	/* const updatedSelectedMH = () => {
    const { eligibility } = props
    let errorMsgs = [];
    let catsPopUp;
    catsPopUp = document.getElementById(`modalMHFrm${props.currentPkgIndex ? props.currentPkgIndex : ''}${props.currentGroupIndex ? props.currentGroupIndex : ''}`).contentWindow["selectedCatIds"];

    let uniqCatsPopUp = catsPopUp.filter((x, i, a) => a.indexOf(x) == i);

    let duplicates = catsPopUp.reduce(function (acc, el, i, arr) {
        if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
    }, []);

    let alreadyAdded = [];

    if (eligibility === eligibilityType.packageMultibuyEligibility) {
        props.ScaleMerchTableData.packageMultibuyEligibility.map((pkg, x) => {

            pkg.group.map((g, i) => {
                if (!(i == props.currentGroupIndex
                    && x == props.currentPkgIndex
                )) {
                    g.merchandizeHierarchyGroup.map((item) => {
                        alreadyAdded.push(item.merchandizeGroupId[0]);
                    })
                }
            })

        })
        duplicates = intersectionBy(alreadyAdded, uniqCatsPopUp, x => x);
        if (duplicates.length > 0) {
            uniqCatsPopUp = _.reject(uniqCatsPopUp, function (e1) {
                return _.find(duplicates, function (e2) { return e1 == e2 });
            });
        }
    }

    if (eligibility === eligibilityType.packageSH) {

        props.group.map((g, i) => {
            if (!(i == props.currentGroupIndex)) {
                if(g.merchandizeHierarchyGroup != undefined){
                g.merchandizeHierarchyGroup.map((item) => {
                    alreadyAdded.push(item.merchandizeGroupId[0]);
                })
            }
            }
        })
        duplicates = intersectionBy(alreadyAdded, uniqCatsPopUp, x => x);
        if (duplicates.length > 0) {
            uniqCatsPopUp = _.reject(uniqCatsPopUp, function (e1) {
                return _.find(duplicates, function (e2) { return e1 == e2 });
            });
        }
    }
    if (eligibility === eligibilityType.earnBurnEligibility) {

        props.groupDetail.map((g, i) => {
            if (!(i == props.currentGroupIndex)) {
                g.merchandizeHierarchyGroup.map((item) => {
                    alreadyAdded.push(item.merchandizeGroupId[0]);
                })
            }
        })
        duplicates = intersectionBy(alreadyAdded, uniqCatsPopUp, x => x);
        if (duplicates.length > 0) {
            uniqCatsPopUp = _.reject(uniqCatsPopUp, function (e1) {
                return _.find(duplicates, function (e2) { return e1 == e2 });
            });
        }
    }

    if (duplicates.length > 0) {
        // setInformationMsg('Selling Hierarchy Group Ids ' + duplicates.join(', ') + ' are removed');
        // setInformationModalShow(true);
        errorMsgs.push(
            <>
                <b>Duplicate Selling Hierarchy Group Ids</b>
                <p>{`${duplicates.join(', ')}`}</p>
            </>
        )
    }

    const initLst = [];
    const selectedMerchIdList = merchandizeGroups.map((val, i) => {
        return val.merchandizeGroupId[0];
    });


    for (var i = 0; i < uniqCatsPopUp.length; i++) {

        let obj = {};
        let indexOfIthCat = selectedMerchIdList.indexOf(uniqCatsPopUp[i]);

        if (indexOfIthCat != -1) {

            if (eligibility === eligibilityType.packageMultibuyEligibility || eligibility === eligibilityType.earnBurnEligibility ||
                eligibility === eligibilityType.packageSH) {

                obj = {
                    merchandizeGroupId: [uniqCatsPopUp[i]],
                    maximumPrice: merchandizeGroups[indexOfIthCat].maximumPrice,
                    minimumPrice: merchandizeGroups[indexOfIthCat].minimumPrice,
                }
            } else if (eligibility === eligibilityType.merchandizeHierarchyEligibility ||eligibility === eligibilityType.MultiBuyGroup   || eligibility === eligibilityType.scaledPriceEligibility) {

                obj = {
                    merchandizeGroupId: [uniqCatsPopUp[i]],
                    trigger: merchandizeGroups[indexOfIthCat].trigger,
                    getReward: merchandizeGroups[indexOfIthCat].getReward,
                    maximumPrice: merchandizeGroups[indexOfIthCat].maximumPrice,
                    minimumPrice: merchandizeGroups[indexOfIthCat].minimumPrice,
                }
            }
        } else {

            if (eligibility === eligibilityType.packageMultibuyEligibility || eligibility === eligibilityType.earnBurnEligibility
                || eligibility === eligibilityType.packageSH) {

                obj = {
                    merchandizeGroupId: [uniqCatsPopUp[i]],
                    maximumPrice: props.defaultValueForAdd.maximumPrice,
                    minimumPrice: props.defaultValueForAdd.minimumPrice,
                }
            } else if (eligibility === eligibilityType.merchandizeHierarchyEligibility || eligibility === eligibilityType.scaledPriceEligibility) {

                obj = {
                    merchandizeGroupId: [uniqCatsPopUp[i]],
                    trigger: props.defaultValueForAdd.trigger,
                    getReward: props.defaultValueForAdd.getReward,
                    maximumPrice: props.defaultValueForAdd.maximumPrice,
                    minimumPrice: props.defaultValueForAdd.minimumPrice,
                }
            }
        }

        initLst.push(obj);
    }

  

        getItemDescriptionSH(initLst);

    toggleMHPopup(false);
    if (errorMsgs != '') {
        setInformationMsg(errorMsgs);
        setInformationModalShow(true);
    }
}; */

	const updatedSelectedMH = () => {
		const { eligibility } = props;
		let errorMsgs = [];
		const catsPopUp = document.getElementById(
			`modalMHFrm${props.currentPkgIndex ? props.currentPkgIndex : ''}${
				props.currentGroupIndex ? props.currentGroupIndex : ''
			}`
		).contentWindow['selectedCatIds'];

		let uniqCatsPopUp = catsPopUp.filter((x, i, a) => a.indexOf(x) == i);

		let duplicates = catsPopUp.reduce(function (acc, el, i, arr) {
			if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
			return acc;
		}, []);

		let alreadyAdded = [];

		if (eligibility === eligibilityType.packageMultibuyEligibility) {
			props.ScaleMerchTableData.packageMultibuyEligibility.map((pkg, x) => {
				pkg.group.map((g, i) => {
					if (!(i == props.currentGroupIndex && x == props.currentPkgIndex)) {
						g.merchandizeHierarchyGroup.map((item) => {
							alreadyAdded.push(item.merchandizeGroupId[0]);
						});
					}
				});
			});
			duplicates = intersectionBy(alreadyAdded, uniqCatsPopUp, (x) => x);
			if (duplicates.length > 0) {
				uniqCatsPopUp = _.reject(uniqCatsPopUp, function (e1) {
					return _.find(duplicates, function (e2) {
						return e1 == e2;
					});
				});
			}
		}

		if (eligibility === eligibilityType.packageSH) {
			props.group.map((g, i) => {
				if (!(i == props.currentGroupIndex)) {
					if (g.merchandizeHierarchyGroup != undefined) {
						g.merchandizeHierarchyGroup.map((item) => {
							alreadyAdded.push(item.merchandizeGroupId[0]);
						});
					}
				}
			});
			duplicates = intersectionBy(alreadyAdded, uniqCatsPopUp, (x) => x);
			if (duplicates.length > 0) {
				uniqCatsPopUp = _.reject(uniqCatsPopUp, function (e1) {
					return _.find(duplicates, function (e2) {
						return e1 == e2;
					});
				});
			}
		}
		if (eligibility === eligibilityType.earnBurnEligibility) {
			props.groupDetail.map((g, i) => {
				if (!(i == props.currentGroupIndex)) {
					g.merchandizeHierarchyGroup.map((item) => {
						alreadyAdded.push(item.merchandizeGroupId[0]);
					});
				}
			});
			duplicates = intersectionBy(alreadyAdded, uniqCatsPopUp, (x) => x);
			if (duplicates.length > 0) {
				uniqCatsPopUp = _.reject(uniqCatsPopUp, function (e1) {
					return _.find(duplicates, function (e2) {
						return e1 == e2;
					});
				});
			}
		}

		if (duplicates.length > 0) {
			// setInformationMsg('Selling Hierarchy Group Ids ' + duplicates.join(', ') + ' are removed');
			// setInformationModalShow(true);
			errorMsgs.push(
				<>
					<b>Duplicate Selling Hierarchy Group Ids</b>
					<p>{`${duplicates.join(', ')}`}</p>
				</>
			);
		}

		const initLst = [];
		const selectedMerchIdList = merchandizeGroups.map((val) => {
			return val.merchandizeGroupId[0];
		});

		for (var i = 0; i < uniqCatsPopUp.length; i++) {
			let obj = {};
			let indexOfIthCat = selectedMerchIdList.indexOf(uniqCatsPopUp[i]);

			if (indexOfIthCat != -1) {
				if (
					eligibility === eligibilityType.packageMultibuyEligibility ||
					eligibility === eligibilityType.earnBurnEligibility ||
					eligibility === eligibilityType.packageSH
				) {
					obj = {
						merchandizeGroupId: [uniqCatsPopUp[i]],
						maximumPrice: merchandizeGroups[indexOfIthCat].maximumPrice,
						minimumPrice: merchandizeGroups[indexOfIthCat].minimumPrice
					};
				} else if (
					eligibility === eligibilityType.merchandizeHierarchyEligibility ||
					eligibility === eligibilityType.MultiBuyGroup ||
					eligibility === eligibilityType.scaledPriceEligibility
				) {
					obj = {
						merchandizeGroupId: [uniqCatsPopUp[i]],
						trigger: merchandizeGroups[indexOfIthCat].trigger,
						getReward: merchandizeGroups[indexOfIthCat].getReward,
						maximumPrice: merchandizeGroups[indexOfIthCat].maximumPrice,
						minimumPrice: merchandizeGroups[indexOfIthCat].minimumPrice
					};
				}
			} else {
				if (
					eligibility === eligibilityType.packageMultibuyEligibility ||
					eligibility === eligibilityType.earnBurnEligibility ||
					eligibility === eligibilityType.packageSH
				) {
					obj = {
						merchandizeGroupId: [uniqCatsPopUp[i]],
						maximumPrice: props.defaultValueForAdd.maximumPrice,
						minimumPrice: props.defaultValueForAdd.minimumPrice
					};
				} else if (
					eligibility === eligibilityType.merchandizeHierarchyEligibility ||
					eligibility === eligibilityType.MultiBuyGroup ||
					eligibility === eligibilityType.scaledPriceEligibility
				) {
					obj = {
						merchandizeGroupId: [uniqCatsPopUp[i]],
						trigger: props.defaultValueForAdd.trigger,
						getReward: props.defaultValueForAdd.getReward,
						maximumPrice: props.defaultValueForAdd.maximumPrice,
						minimumPrice: props.defaultValueForAdd.minimumPrice
					};
				}
			}

			initLst.push(obj);
		}

		/* let newProducts = [...initLst]

    newProducts = newProducts.map((val, index, arrayobj) => {
        return { ...val, TableSlNo: index+1 };
    })

    //if (initLst.length > 0)
        setProducts(newProducts); */

		getItemDescriptionSH(initLst);

		toggleMHPopup(false);
		if (errorMsgs != '') {
			setInformationMsg(errorMsgs);
			setInformationModalShow(true);
		}
	};

	const intersectionBy = (a, b, fn) => {
		const s = new Set(b.map(fn));
		return a.filter((x) => s.has(fn(x)));
	};

	const FuncEnter = (e) => {
		if (e.keyCode == 13) {
			e.preventDefault();
			addProduct();
		}
	};

	//render
	return (
		<div>
			{/* <div>
                <input type='text' value={productID} onChange={handleProductIDChange} />
                <button onClick={addProduct}>+</button>
                <CsvParse
                    keys={props.keys}
                    onDataUploaded={(data) => handleCSVData(data)}
                    onError={() => { }}
                    render={onChange => <input type="file" onChange={onChange} />}
                />
                <button onClick={deleteSelected}>X</button>
            </div> */}
			<div className="justify-content-flex-start align-items-center mb-3 hideForAudit">
				<div className="container">
					<div className="rowContainer">
						<div className="customLabelContainer">
							<label htmlFor="forprogramID" className="col	col-form-label">
								Enter ID
							</label>
						</div>
						<div className="customInputContainer">
							<div className="row">
								<input
									type="text"
									className={
										props.type ? 'form-control' : 'form-control disabled'
									}
									onKeyDown={(e) => FuncEnter(e)}
									onChange={(e) => setProductID(e.target.value)}
									value={productID}
								/>
								<button
									type="button"
									className={
										props.type ? 'addPlusBtn ml-2' : 'addPlusBtn ml-2 disabled'
									}
									onClick={addProduct}
								></button>
								<button
									type="button"
									className={
										props.SelectIDProp == 'MERCHANDIZE'
											? 'addSearchBtn ml-2'
											: 'addSearchBtn ml-2 disabled'
									}
									onClick={() => {
										toggleMHPopup(true);
									}}
								></button>
								{/* <CsvParse
                                    keys={props.keys}
                                    onDataUploaded={(data) => handleCSVData(data)}
                                    onError={() => { }}
                                    render={onChange => <>

                                        <input
                                            type="file"
                                            // id="idLoadProductFromFile"
                                            id={`idLoadProductFromFile${props.i ? props.i : ''}${props.j ? props.j : ''}`}
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                                onChange(e)
                                                e.target.value = ''
                                            }}
                                        />
                                        <label className={`mb-0 uploadBtn ml-1 ${props.eligibility == 'MultiByProduct' ? 'd-none' : ''}`}
                                            for={`idLoadProductFromFile${props.i ? props.i : ''}${props.j ? props.j : ''}`}> </label>
                                    </>}
                                /> */}

								<CSVReader
									ref={buttonRef}
									onFileLoad={handleOnFileLoad}
									onError={handleOnError}
									noClick
									noDrag
									onRemoveFile={handleOnRemoveFile}
								>
									{() => (
										<aside
											style={{
												display: 'flex',
												flexDirection: 'row'
											}}
										>
											<label
												className={
													props.type
														? 'mb-0 uploadBtn ml-1'
														: 'mb-0 uploadBtn ml-1 disabled'
												}
												onClick={handleOpenDialog}
											></label>
											{/* <button
                                                type='button'
                                                onClick={handleOpenDialog}
                                                style={{
                                                    borderRadius: 0,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                    width: '40%',
                                                    paddingLeft: 0,
                                                    paddingRight: 0
                                                }}
                                            >
                                                Browe file
                                            </button>
                                            <div
                                                style={{
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    borderColor: '#ccc',
                                                    height: 45,
                                                    lineHeight: 2.5,
                                                    marginTop: 5,
                                                    marginBottom: 5,
                                                    paddingLeft: 13,
                                                    paddingTop: 3,
                                                    width: '60%'
                                                }}
                                            >
                                                {file && file.name}
                                            </div>
                                            <button
                                                style={{
                                                    borderRadius: 0,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                    paddingLeft: 20,
                                                    paddingRight: 20
                                                }}
                                                onClick={handleRemoveFile}
                                            >
                                                Remove
                                            </button> */}
										</aside>
									)}
								</CSVReader>
								<button
									type="button"
									className="addDeleteBtn ml-1"
									onClick={deleteSelected}
								></button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{products.length > 0 && !isObjectEmpty(columns) && (
				<ToolkitProvider keyField="productID" data={products} columns={columns} search>
					{(props) => (
						<>
							{/* <SearchBar className="text-left pointer-events-auto" {...props.searchProps} /> */}
							<div className="pt-3 pb-4 pl-2 border-1-lightgray">
								<b>Filter by:</b>
							</div>

							{eligib === 'MultiByProduct' ? (
								<BootstrapTable
									ref={tableRef}
									// onTableChange={onTableChange}
									{...props.baseProps}
									keyField="productID"
									data={products}
									columns={columns}
									cellEdit={cellEditFactory({
										mode: 'click',
										blurToSave: true,
										afterSaveCell: handleTableChange
									})}
									// pagination={paginationFactory(options)}
									filter={filterFactory()}
									selectRow={selectRow}
									rowClasses={rowClasses}
								/>
							) : (
								<BootstrapTable
									ref={tableRef}
									// onTableChange={onTableChange}
									{...props.baseProps}
									keyField="productID"
									data={products}
									columns={columns}
									cellEdit={cellEditFactory({
										mode: 'click',
										blurToSave: true,
										afterSaveCell: handleTableChange
									})}
									pagination={paginationFactory(options)}
									filter={filterFactory()}
									selectRow={selectRow}
									rowClasses={rowClasses}
								/>
							)}
						</>
					)}
				</ToolkitProvider>
			)}

			{merchandizeGroups.length > 0 && !isObjectEmpty(columns) && (
				<ToolkitProvider
					keyField="merchandizeGroupId"
					data={merchandizeGroups}
					columns={columns}
					search
				>
					{(props) => (
						<>
							{/* <SearchBar className="text-center pointer-events-auto" {...props.searchProps} /> */}
							<div className="pt-3 pb-4 pl-2 border-1-lightgray">
								<b>Filter by:</b>
							</div>
							<BootstrapTable
								ref={tableMerchandiseRef}
								// onTableChange={onTableChange}
								{...props.baseProps}
								keyField="merchandizeGroupId"
								data={merchandizeGroups}
								columns={columnsSH}
								cellEdit={cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: handleTableChange
								})}
								pagination={paginationFactory(options)}
								filter={filterFactory()}
								selectRow={selectRowSH}
								rowClasses={rowClasses}
							/>
						</>
					)}
				</ToolkitProvider>
			)}

			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
			<div className={showMHModal ? 'modal in-right d-block' : 'modal'}>
				<div className="modal-dialog m-0 modal-bottom-right" role="document">
					<div className="modal-content h-100">
						<div className="modal-header modal-title-custom p-2 b-radius-none">
							<p className="modal-title ml-2 roboto-r-18" id="exampleModalLabel">
								Add From Catalog
							</p>
						</div>
						<iframe
							title="iFrame"
							id={`modalMHFrm${props.currentPkgIndex ? props.currentPkgIndex : ''}${
								props.currentGroupIndex ? props.currentGroupIndex : ''
							}`}
							className="modalMHCls"
							src="../../MerchandiseHierarchy/index.html"
							width="100%"
							height="85%"
							frameBorder="0"
						></iframe>
						<div className="modalDiv-footer">
							<div
								className="buttonClose"
								id="buttonCloseStorePopup"
								onClick={() => {
									toggleMHPopup(false);
								}}
							>
								{' '}
								Cancel
							</div>
							<div
								className="buttonAdd"
								id="buttonAddStorePopup"
								onClick={() => {
									updatedSelectedMH();
								}}
							>
								{' '}
								Add
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTableBootstrapNew);
