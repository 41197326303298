import React from 'react';

const CollapseBody = ({ children, id, headingId }) => {
	return (
		<div
			id={id}
			className="collapse"
			aria-labelledby={headingId + ''}
			data-parent="#accordionExample"
		>
			<div className="card-body">{children}</div>
		</div>
	);
};

export default CollapseBody;
