import React, { useEffect } from 'react';
import { useField } from 'formik';

import ConditionalRedering from './ConditionalRedering';
import { itemHelp } from '../item-help-text';
import CustomSelect from '../customfields/CustomSelect';
import $ from 'jquery';

function MultiByGroup(props) {
	const thresholdTypeCodeOptions = [
		{
			value: '',
			name: 'Select one...'
		},
		{
			value: 'GROUP_INTERVAL_QTY_THRESHOLD',
			name: 'Group Interval Quantity Threshold'
		},
		{
			value: 'GROUP_QTY_THRESHOLD',
			name: 'Group Quantity Threshold'
		},
		{
			value: 'GROUP_INTERVAL_AMOUNT_THRESHOLD',
			name: 'Group Interval Amount Threshold'
		},
		{
			value: 'GROUP_AMOUNT_THRESHOLD',
			name: 'Group Amount Threshold'
		},
		{
			value: 'GROUP_RANGE_QTY_THRESHOLD',
			name: 'Group Range Quantity Threshold'
		},
		{
			value: 'GROUP_RANGE_AMOUNT_THRESHOLD',
			name: 'Group Range Amount Threshold'
		},
		{
			value: 'GROUP_LIMIT_QTY_THRESHOLD',
			name: 'Group Limit Quantity Threshold'
		}
	];

	const getThresholdDropdown = (thresholdTypeCodeOptions) => {
		return thresholdTypeCodeOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	//Local const

	const [field] = useField(props);

	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));

	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
					.thresholdTypeCode != field.value.groupMultibuyEligbility.thresholdTypeCode
			) {
				let tempName = 'groupMultibuyEligbility.thresholdTypeCode';
				//
				$('[name="' + tempName + '"]')
					.closest('.container')
					.parent()
					.nextAll()
					.addClass('d-none');
			}
		}
	}, []);

	return (
		<div className="container-fluid">
			{/* <div class="accordion" id="accordionExample">
        <div class="card" style={{ border: "none" }}>
          <CollapseHeaderBar
            text={localConst.eligibilitySettings}
            headingId={"headingOne"}
            dataTarget={"#collapseOne"}
          />
          <CollapseBody id="collapseOne" headingId={"headingOne"}> */}
			<div className="d-flex justify-content-center">
				<CustomSelect
					name="groupMultibuyEligbility.thresholdTypeCode"
					required={true}
					label="Threshold Type Code"
					options={getThresholdDropdown(thresholdTypeCodeOptions)}
					className="customDropdown inlinePadding bg-position"
					smallText={itemHelp.ThresholdTypeCodeMBG}
				/>
			</div>
			{field.value.groupMultibuyEligbility.thresholdTypeCode ? (
				<ConditionalRedering
					value={field.value.groupMultibuyEligbility.thresholdTypeCode}
				/>
			) : null}
			{/* </CollapseBody>
        </div>
      </div> */}
			{/* ProductSetting */}
			{/*  <div class="accordion" id="accordionExample">
            <div class="card" style={{ border: "none" }}>
              <CollapseHeaderBar
                text={localConst.ProductSetting}
                headingId={"headingThree"}
                dataTarget={"#collapseThree"}
              />
              <CollapseBody id="collapseThree" headingId={"headingThree"}>
                <Field name="multibuyProduct" component={ProductSetting} />
              </CollapseBody>
            </div>
          </div>
          <div class="accordion" id="accordionExample">
            <div class="card" style={{ border: "none" }}>
              <CollapseHeaderBar
                text={localConst.benefitSettings}
                headingId={"headingTwo"}
                dataTarget={"#collapseTwo"}
              />
              <CollapseBody id="collapseTwo" headingId={"headingTwo"}>
                <Field name="benefit" component={BenefitSettings} />
              </CollapseBody>
            </div>
          </div> */}
		</div>
	);
}

export default MultiByGroup;
