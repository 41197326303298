import React, { useEffect } from 'react';
import { useField } from 'formik';

import CustomInputText from '../customfields/CustomInputText';
import CustomSelect from '../customfields/CustomSelect';

function ConditionalRedering(props) {
	const [field] = useField(props);

	const localConst = {
		eligibilitySettings: 'Eligibility Settings',
		thresholdCode: 'Threshold Type Code',
		triggerCombinationCode: 'Trigger Combination Code',
		subTriggerCombinationCode:
			'Determines the combination code to be used between the trigger items for a promotion',
		thresholdQuantity: 'Threshold Quantity',
		thresholdAmount: 'Threshold Amount',
		rewardCombinationCode: 'Reward Combination Code',
		subRewardCombinationCode:
			'Determines the combination code to be used between the reward item for a promotion',
		rewardQuantity: 'Reward Quantity',
		benefitSettings: 'Benefit Settings',
		ProductSetting: 'Product Settings',
		quantityLimit: 'Quantity Limit',
		maximumThresholdAmount: 'Maximum Threshold Amount',
		maximumThresholdQuantity: 'Maximum Threshold Quantity'
	};

	const rewardCombinationDropdown = {
		promotionTypeOr: [
			{
				value: 'OR',
				name: 'OR'
			}
		],
		promotionTypeOrAndEqual: [
			{
				value: 'OR',
				name: 'OR'
			},
			{
				value: 'AND',
				name: 'AND'
			},
			{
				value: 'EQUAL',
				name: 'EQUAL'
			}
		],
		promotionTypeOrAnd: [
			{
				value: 'OR',
				name: 'OR'
			},
			{
				value: 'AND',
				name: 'AND'
			}
		],
		operator: [
			{
				value: '',
				name: 'Select one'
			},
			{
				value: '<',
				name: '<'
			},
			{
				value: '>',
				name: '>'
			},
			{
				value: '<=',
				name: '<='
			},
			{
				value: '>=',
				name: '>='
			},
			{
				value: '=',
				name: '='
			},
			{
				value: 'BETWEEN',
				name: 'BETWEEN'
			}
		]
	};
	const simplePromotionEligibility = field.value.simplePromotionEligibility;
	useEffect(() => {
		if (!simplePromotionEligibility.triggerCombinationCode)
			simplePromotionEligibility.triggerCombinationCode = 'OR';
		if (!simplePromotionEligibility.rewardCombinationCode)
			simplePromotionEligibility.rewardCombinationCode = 'OR';
		if (!simplePromotionEligibility.rewardQuantity)
			simplePromotionEligibility.rewardQuantity = 1;
		if (!simplePromotionEligibility.thresholdQuantity)
			simplePromotionEligibility.thresholdQuantity = 1;
	}, [field.value.simplePromotionEligibility.thresholdTypeCode]);
	useEffect(() => {
		if (
			simplePromotionEligibility.triggerOperator == '<' ||
			simplePromotionEligibility.triggerOperator == '>' ||
			simplePromotionEligibility.triggerOperator == '<=' ||
			simplePromotionEligibility.triggerOperator == '>=' ||
			simplePromotionEligibility.triggerOperator == '='
		) {
			simplePromotionEligibility.triggerOperatorValueTo = undefined;
			simplePromotionEligibility.triggerOperatorValueFrom = undefined;
		} else if (simplePromotionEligibility.triggerOperator == 'BETWEEN') {
			simplePromotionEligibility.triggerOperatorValue = undefined;
		} else {
			simplePromotionEligibility.triggerOperatorValueTo = undefined;
			simplePromotionEligibility.triggerOperatorValueFrom = undefined;
			simplePromotionEligibility.triggerOperatorValue = undefined;
		}
	}, [simplePromotionEligibility.triggerOperator]);

	useEffect(() => {
		if (
			simplePromotionEligibility.rewardOperator == '<' ||
			simplePromotionEligibility.rewardOperator == '>' ||
			simplePromotionEligibility.rewardOperator == '<=' ||
			simplePromotionEligibility.rewardOperator == '>=' ||
			simplePromotionEligibility.rewardOperator == '='
		) {
			simplePromotionEligibility.rewardOperatorValueTo = undefined;
			simplePromotionEligibility.rewardOperatorValueFrom = undefined;
		} else if (simplePromotionEligibility.rewardOperator == 'BETWEEN') {
			simplePromotionEligibility.rewardOperatorValue = undefined;
		} else {
			simplePromotionEligibility.rewardOperatorValueTo = undefined;
			simplePromotionEligibility.rewardOperatorValueFrom = undefined;
			simplePromotionEligibility.rewardOperatorValue = undefined;
		}
	}, [simplePromotionEligibility.rewardOperator]);

	const getThresholdDropdown = (dropdownOptions) => {
		return dropdownOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};
	//html
	return (
		<div className="purchaseContainer">
			<div className="purchaseRow">
				Purchase Conditions
				<CustomSelect
					name="simplePromotionEligibility.triggerCombinationCode"
					required={true}
					label={localConst.triggerCombinationCode}
					options={getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)}
					smallText={localConst.subTriggerCombinationCode}
					className="customDropdown inlinePadding bg-position"
					disabled={true}
				/>
				<CustomInputText
					name="simplePromotionEligibility.thresholdQuantity"
					required={true}
					label={localConst.thresholdQuantity}
					smallText=""
					disabled={true}
				/>
				<CustomSelect
					name="simplePromotionEligibility.triggerOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{simplePromotionEligibility.triggerOperator == '<' ||
				simplePromotionEligibility.triggerOperator == '>' ||
				simplePromotionEligibility.triggerOperator == '<=' ||
				simplePromotionEligibility.triggerOperator == '>=' ||
				simplePromotionEligibility.triggerOperator == '=' ? (
					<CustomInputText
						name="simplePromotionEligibility.triggerOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{simplePromotionEligibility.triggerOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="simplePromotionEligibility.triggerOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="simplePromotionEligibility.triggerOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>

			<div className="purchaseRow">
				Reward Conditions
				<CustomSelect
					name="simplePromotionEligibility.rewardCombinationCode"
					required={true}
					label={localConst.rewardCombinationCode}
					options={getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)}
					smallText={localConst.subRewardCombinationCode}
					className="customDropdown inlinePadding bg-position"
					disabled={true}
				/>
				<CustomInputText
					name="simplePromotionEligibility.rewardQuantity"
					required={true}
					label={localConst.rewardQuantity}
					smallText=""
					disabled={true}
				/>
				<CustomSelect
					name="simplePromotionEligibility.rewardOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{simplePromotionEligibility.rewardOperator == '<' ||
				simplePromotionEligibility.rewardOperator == '>' ||
				simplePromotionEligibility.rewardOperator == '<=' ||
				simplePromotionEligibility.rewardOperator == '>=' ||
				simplePromotionEligibility.rewardOperator == '=' ? (
					<CustomInputText
						name="simplePromotionEligibility.rewardOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{simplePromotionEligibility.rewardOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="simplePromotionEligibility.rewardOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="simplePromotionEligibility.rewardOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>
		</div>
	);
}
export default ConditionalRedering;
