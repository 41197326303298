import React from 'react';
import { withRouter } from 'react-router-dom';
import Input from '../../Components/Input/Input';
import * as RestSvc from '../../libraries/offer-services';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import * as urlTypes from '../../libraries/offer-service-urls';
import moment from 'moment';
import DropdownIcon from './../../assets/images/dropdownIcon.png';
import Filter from './../../assets/images/Filter.png';
import GreenDot from './../../assets/images/GreenDot.png';
import Assign from './Assign';
import toaster from 'toasted-notes';

import PreviewScreen from './PreviewScreen';
import CheckBox from './CheckBox';
import cancelIcon from './../../assets/images/plus-circle-cancel.png';
import resetIcon from './../../assets/images/undo.png';
import { ConfirmationModal } from '../ConfirmationModal';
import leftArrow from './../../assets/images/left-arrow.png';
import rightArrow from './../../assets/images/right-arrow.png';
import Datetime from 'react-datetime';

class LevelMaintenance extends React.Component {
	initialState = {
		createLevelForm: {
			levelName: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter Level Name',
					labelname: 'Level Name'
				},
				value: '',
				validation: {
					required: true,
					maxLength: 10
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			},
			status: {
				elementType: 'select',
				elementConfig: {
					type: '',
					placeholder: 'Select',
					labelname: 'Status',
					options: [
						{ value: 'A', displayValue: 'Active' },
						{ value: 'I', displayValue: 'Inactive' }
					]
				},
				value: 'A',
				validation: {
					required: true
				},
				validationMessage: '',
				valid: true,
				touched: false
			},
			levelDescription: {
				elementType: 'textarea',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter Level Description',
					labelname: 'Description'
				},
				value: '',
				validation: {
					required: false,
					maxLength: 20
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			}
		},
		formIsValid: false,
		changePwdModalShow: false,
		Levels: [],
		status: 'Select',
		startDate: new Date(),
		endDate: '',
		tempendDate: '',
		tempstartDate: '',
		editScreen: true,
		showCreateLevel: false,
		basicDetails: true,
		assignHierarchy: false,
		assignPermission: false,
		showPreview: false,
		Create: false,
		Approve: false,
		Reject: false,
		Modify: false,
		Cancel: false,
		hierarchicalLevel: '',
		TodayDate: new Date(),
		updatedCards: [],
		newCardAddedFlag: false,
		isThisExistingLevel: false,
		displayLevelName: '',
		dateError: '',
		permissionsArray: [],
		createLevelResource: '',
		existingLevelID: '',
		clickPreview: false,
		confirmationModalShow: false,
		confirmMsg: '',
		confirmType: '',
		levelNameError: '',
		createdOn: '',
		LevelsForHierarchy: [],
		endDateSet: false,
		showEnd: true,
		endDateTouched: false,
		startDateTouched: false
	};
	state = this.initialState;
	componentDidMount() {
		this.fetchAllLevels();

		window.dispatchEvent(new CustomEvent('navigationhandler'));
	}
	fetchAllLevels() {
		var dataToSend = {
			fetchAllExistingLevelRequest: {
				level: {
					levelID:
						this.props.loggedInLevelHierarchy === 1 ? null : this.props.loggedInLevelID
				}
			}
		};

		this.props.showLoadingButton(true);

		RestSvc.postData(urlTypes.create_level_maintenance.fetchLevelsById, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				this.setState({ Levels: response.data.fetchAllExistingLevelResponse.allLevels });
				// //Call for Levels based on hierarchy
				var dataToSend2 = {
					fetchAllExistingLevelRequest: {
						level: {
							levelID: null
						}
					}
				};
				this.props.showLoadingButton(true);
				RestSvc.postData(urlTypes.create_level_maintenance.fetchLevelsById, dataToSend2)
					.then((response2) => {
						this.props.showLoadingButton(false);
						let tempArray = JSON.parse(
							JSON.stringify(response2.data.fetchAllExistingLevelResponse.allLevels)
						);
						tempArray.sort((a, b) => {
							if (a['hierarchicalLevel'] > b['hierarchicalLevel']) {
								return 1;
							} else if (a['hierarchicalLevel'] < b['hierarchicalLevel']) {
								return -1;
							}
							return 0;
						});
						this.setState({ LevelsForHierarchy: tempArray }, () => {
							if (this.state.Levels.length != 0) {
								this.showCardDetails(this.state.Levels[0]);
							}
						});
					})
					.catch((error) => {
						this.props.showLoadingButton(false);

						console.error(error);
					});
				// //Call for Levels based on hierarchy end
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	}

	fetchPermissions = () => {
		//Call for Permissions
		this.props.showLoadingButton(true);
		const dataToSend = {
			getAllResourcesForAssignmentRequest: {
				operatorID: this.props.userFullName.operatorID,
				level: {
					levelID: 0
				},
				resource: {
					applicationCode: 'OFFER_CONFIGURATOR'
				}
			}
		};
		RestSvc.postData(urlTypes.create_level_maintenance.fetchResources, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				let allResources =
					response.data.getAllResourcesForAssignmentResponse.resource.resources;
				this.setState({
					permissionsArray: allResources,
					createLevelResource: allResources
				});
			})
			.catch((error) => {
				this.props.showLoadingButton(false);

				console.error(error);
			});
		//End Call for Permissions
	};

	handleFormReset = () => {
		this.setState(() => this.initialState);
	};

	onSelectChange = (event) => {
		this.setState({ status: event.target.value });
	};

	onSubmit = (event) => {
		event.preventDefault();
		// this.setState({ editScreen: false });
	};

	createLevel = () => {
		if (this.state.isThisExistingLevel) {
			var dataToSend = {
				createOrModifyLevelRequest: {
					operatorID: this.props.userFullName.operatorID,
					level: {
						parentLevelID: 1,
						description: this.state.createLevelForm.levelDescription.value,
						levelName: this.state.createLevelForm.levelName.value,
						status: this.state.createLevelForm.status.value,
						effectiveStartDate: moment(this.state.startDate).format(
							'YYYY-MM-DD HH:mm:ss'
						),
						effectiveEndDate: this.state.endDate
							? moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss')
							: '',
						createdBy: this.props.userFullName.fullName,
						hierarchicalLevel: this.state.hierarchicalLevel,
						levelID: this.state.existingLevelID
					},
					resource: {
						resourceID: 1,
						description: 'Offer Configurator',
						readAccess: 'y',
						writeAccess: 'y',
						resourceCode: 'OFFER_CONFIGURATOR',
						isAssigned: true,
						resources: this.state.permissionsArray
					}
				}
			};
			this.props.showLoadingButton(true);
			RestSvc.postData(urlTypes.create_level_maintenance.createOrModifyLevel, dataToSend)
				.then((response) => {
					this.props.showLoadingButton(false);

					if (response.data.offersConfigHeader.offersConfigException) {
						toaster.notify(
							({ onClose }) => (
								<div className="Toaster__message-wrapper">
									<div>
										<div
											id="1"
											className="toasterRed Toaster__alert Toaster__alert_error"
										>
											<div className="Toaster__alert_text">
												{
													response.data.offersConfigHeader
														.offersConfigException.message
												}
											</div>
											<button
												className="Toaster__alert_close"
												type="button"
												aria-label="Close"
												onClick={onClose}
											>
												<span aria-hidden="true">×</span>
											</button>
										</div>
									</div>
								</div>
							),
							{
								duration: 10000
							}
						);
					}
					if (
						response.data.offersConfigHeader.operationStatus ===
						'Level Modified successfully.'
					) {
						toaster.notify(
							({ onClose }) => (
								<div className="Toaster__message-wrapper">
									<div>
										<div
											id="1"
											className="bg-success Toaster__alert Toaster__alert_error"
										>
											<div className="Toaster__alert_text">
												{response.data.offersConfigHeader.operationStatus}
											</div>
											<button
												className="Toaster__alert_close"
												type="button"
												aria-label="Close"
												onClick={onClose}
											>
												<span aria-hidden="true">×</span>
											</button>
										</div>
									</div>
								</div>
							),
							{
								duration: 10000
							}
						);

						const dataToSend = {
							getOperatorDetailRequest: {
								operatorID: this.props.userFullName.operatorID
							}
						};
						RestSvc.postData(urlTypes.user_maintenance.userDetails, dataToSend)
							.then((response) => {
								this.props.showLoadingButton(false);
								this.props.setLevelID(
									response.data.SearchOperatorResponse.level.levelID
								);
								this.props.setLevelHierarchy(
									response.data.SearchOperatorResponse.level.hierarchicalLevel
								);
								sessionStorage.setItem(
									'taggedBrandToLoggedInUser',
									JSON.stringify(
										response.data.SearchOperatorResponse
											.businessUnitGroupFunctionList[0]
									)
								);
								this.props.setLoggedInBrand(
									response.data.SearchOperatorResponse
										.businessUnitGroupFunctionList[0]
								);
							})

							.catch((error) => {
								this.props.showLoadingButton(false);
								console.error(error);
							});

						this.props.setDisableItem(false);
						this.fetchAllLevels();
						this.handleFormReset();
					}
				})
				.catch((error) => {
					this.props.showLoadingButton(false);
					console.error(error);
				});
		} else {
			dataToSend = {
				createOrModifyLevelRequest: {
					operatorID: this.props.userFullName.operatorID,
					level: {
						parentLevelID: 1,
						description: this.state.createLevelForm.levelDescription.value,
						levelName: this.state.createLevelForm.levelName.value,
						status: this.state.createLevelForm.status.value,
						effectiveStartDate: moment(this.state.startDate).format(
							'YYYY-MM-DD HH:mm:ss'
						),
						effectiveEndDate: this.state.endDate
							? moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss')
							: '',
						createdBy: this.props.userFullName.fullName,
						hierarchicalLevel: this.state.hierarchicalLevel
					},
					resource: {
						resourceID: 1,
						description: 'Offer Configurator',
						readAccess: 'y',
						writeAccess: 'y',
						resourceCode: 'OFFER_CONFIGURATOR',
						isAssigned: true,
						resources: this.state.permissionsArray
					}
				}
			};
			this.props.showLoadingButton(true);
			RestSvc.postData(urlTypes.create_level_maintenance.createOrModifyLevel, dataToSend)
				.then((response) => {
					this.props.showLoadingButton(false);

					if (response.data.offersConfigHeader.offersConfigException) {
						toaster.notify(
							({ onClose }) => (
								<div className="Toaster__message-wrapper">
									<div>
										<div
											id="1"
											className="toasterRed Toaster__alert Toaster__alert_error"
										>
											<div className="Toaster__alert_text">
												{
													response.data.offersConfigHeader
														.offersConfigException.message
												}
											</div>
											<button
												className="Toaster__alert_close"
												type="button"
												aria-label="Close"
												onClick={onClose}
											>
												<span aria-hidden="true">×</span>
											</button>
										</div>
									</div>
								</div>
							),
							{
								duration: 10000
							}
						);
					}
					if (
						response.data.offersConfigHeader.operationStatus ===
						'Level created successfully.'
					) {
						toaster.notify(
							({ onClose }) => (
								<div className="Toaster__message-wrapper">
									<div>
										<div
											id="1"
											className="bg-success Toaster__alert Toaster__alert_error"
										>
											<div className="Toaster__alert_text">
												{response.data.offersConfigHeader.operationStatus}
											</div>
											<button
												className="Toaster__alert_close"
												type="button"
												aria-label="Close"
												onClick={onClose}
											>
												<span aria-hidden="true">×</span>
											</button>
										</div>
									</div>
								</div>
							),
							{
								duration: 10000
							}
						);
						this.props.setDisableItem(false);
						this.fetchAllLevels();
						this.handleFormReset();
					}
				})
				.catch((error) => {
					this.props.showLoadingButton(false);
					console.error(error);
				});
		}
	};
	inputChangedHandler = (event, isValidObj, inputIdentifier) => {
		if (
			this.state.newCardAddedFlag &&
			inputIdentifier === 'levelName' &&
			!this.state.isThisExistingLevel
		) {
			if (this.state.updatedCards.length !== 0)
				// eslint-disable-next-line react/no-direct-mutation-state
				this.state.updatedCards[this.state.hierarchicalLevel - 1].levelName =
					event.target.value;
		}
		if (inputIdentifier === 'levelName' && this.state.isThisExistingLevel) {
			if (this.state.updatedCards.length !== 0)
				// eslint-disable-next-line react/no-direct-mutation-state
				this.state.updatedCards[this.state.hierarchicalLevel - 1].levelName =
					event.target.value;
		}
		const updatedLoginForm = { ...this.state.createLevelForm };
		const updatedFormElement = { ...updatedLoginForm[inputIdentifier] };
		updatedFormElement.value = event.target.value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;
		updatedLoginForm[inputIdentifier] = updatedFormElement;

		let formIsValid = true;
		for (let inputIdentifier in updatedLoginForm) {
			formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ createLevelForm: updatedLoginForm, formIsValid: formIsValid });
		if (inputIdentifier === 'levelName') {
			this.setState({ levelNameError: '' });
			this.state.Levels.map((level) => {
				if (level.levelName.toUpperCase() === event.target.value.toUpperCase()) {
					this.setState({ levelNameError: 'Level name already exists.' });
				}
			});
		}
	};

	resetHandler = () => {
		this.setState((prevState) => {
			let previousState = { ...prevState };
			previousState.createLevelForm.levelName.value = '';
			previousState.createLevelForm.levelName.touched = false;
			previousState.createLevelForm.levelDescription.value = '';
			previousState.createLevelForm.levelDescription.touched = false;
			previousState.createLevelForm.levelDescription.validationMessage = '';
			previousState.createLevelForm.levelName.validationMessage = '';
			previousState.dateError = '';
			previousState.startDate = new Date();
			previousState.endDate = '';
			previousState.newCardAddedFlag = false;
			previousState.createLevelForm.status.value = 'A';
			previousState.endDateSet = false;
			previousState.endDateTouched = false;
			previousState.startDateTouched = false;

			return previousState;
		});
		this.setState(
			{ endDateSet: false, endDate: '', showEnd: false, dateError: '', levelNameError: '' },
			() => {
				this.setState({ showEnd: true });
			}
		);
	};

	onChangeStartDate = (startDate) => {
		const tempstartDate = moment(startDate).format('YYYY-MM-DD hh:mm:ss');
		this.setState({ tempstartDate: tempstartDate, startDateTouched: true });
		this.setState({ startDate: startDate }, () => {});
		if (moment(startDate).isAfter(this.state.endDate, 'minute')) {
			this.setState({ dateError: 'Start Date cannot be greater than the End Date' });
		} else {
			this.setState({ dateError: '' });
		}
	};

	onChangeEndDate = (endDate) => {
		const tempendDate = moment(endDate).format('YYYY-MM-DD hh:mm:ss');
		this.setState({ tempendDate: tempendDate, endDateTouched: true });
		this.setState({ endDate: endDate });
		if (moment(this.state.startDate).isAfter(endDate, 'minute')) {
			this.setState({ dateError: 'Start Date cannot be greater than the End Date' });
		} else {
			this.setState({ dateError: '' });
		}
	};
	setHierarchicalLevel = (val) => {
		this.setState({ hierarchicalLevel: val });
	};
	updateCards = (cards) => {
		this.setState({ updatedCards: cards });
	};

	showCardDetails = (existingLevel) => {
		this.setState({
			existingLevelID: existingLevel.levelID,
			clickPreview: true,
			createdOn: existingLevel.modifiedOn,
			formIsValid: true
		});
		//Call for Permissions
		this.props.showLoadingButton(true);
		const dataToSend = {
			getAllResourcesForAssignmentRequest: {
				operatorID: this.props.userFullName.operatorID,
				level: {
					levelID: existingLevel.levelID
				},
				resource: {
					applicationCode: 'OFFER_CONFIGURATOR'
				}
			}
		};
		RestSvc.postData(urlTypes.create_level_maintenance.fetchResources, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				let allResources =
					response.data.getAllResourcesForAssignmentResponse.resource.resources;
				this.setState({
					permissionsArray: allResources,
					createLevelResource: allResources
				});
			})
			.catch((error) => {
				this.props.showLoadingButton(false);

				console.error(error);
			});
		//End Call for Permissions
		this.setState(
			(previousState) => {
				previousState.isThisExistingLevel = true;
				previousState.hierarchicalLevel = existingLevel.hierarchicalLevel;
				previousState.createLevelForm.levelName.value = existingLevel.levelName;
				previousState.createLevelForm.status.value = existingLevel.status;
				previousState.createLevelForm.levelDescription.value = existingLevel.description;
				previousState.startDate = new Date(existingLevel.effectiveStartDate);
				previousState.endDate = existingLevel.effectiveEndDate
					? new Date(existingLevel.effectiveEndDate)
					: null;
				previousState.newLevelName = existingLevel.levelName;
				previousState.updatedCards = JSON.parse(
					JSON.stringify(this.state.LevelsForHierarchy)
				);
				previousState.showPreview = true;
				previousState.createLevelForm.levelDescription.valid = true;
				previousState.createLevelForm.levelName.valid = true;
				previousState.endDateSet = existingLevel.effectiveEndDate ? true : false;
				return previousState;
			},
			() => {}
		);
	};

	resetContent = () => {
		let { basicDetails, assignHierarchy } = this.state;
		if (basicDetails) {
			this.resetHandler();
			let cardsTemp = this.state.LevelsForHierarchy.slice();
			this.updateCards(cardsTemp);
		} else if (assignHierarchy && !this.state.isThisExistingLevel) {
			let cardsTemp = this.state.LevelsForHierarchy.slice();
			cardsTemp.push({
				levelName: this.state.createLevelForm.levelName.value,
				hierarchicalLevel: this.state.Levels.length
			});
			this.setState({
				updatedCards: cardsTemp,
				hierarchicalLevel: this.state.Levels.length + 1
			});
		} else {
			let permissionsArray = this.state.permissionsArray;
			permissionsArray.forEach((permission) => {
				permission.isAssigned = false;
			});
			this.setState({ permissionsArray: permissionsArray });
		}
	};

	cancelCreatingLevel = () => {
		this.props.setDisableItem(false);
		this.resetHandler();
		let cardsTemp = this.state.LevelsForHierarchy.slice();
		this.updateCards(cardsTemp);
		this.setState((prevState) => {
			prevState.Create = false;
			prevState.Approve = false;
			prevState.Reject = false;
			prevState.Modify = false;
			prevState.Cancel = false;
			prevState.basicDetails = true;
			prevState.assignHierarchy = false;
			prevState.assignPermission = false;
			prevState.levelNameError = '';
			return prevState;
		});
		this.setState({ showCreateLevel: false, showPreview: false, displayLevelName: '' });
	};
	handleCheckFieldElement = (event) => {
		let permissionsArray = this.state.permissionsArray;
		permissionsArray.forEach((permission) => {
			if (permission.description === event.target.value)
				permission.isAssigned = event.target.checked;
		});
		this.setState({ permissionsArray: permissionsArray });
	};
	editBasicDetails = () => {
		this.setState({ showPreview: false, showCreateLevel: true, basicDetails: true });
		this.props.setDisableItem(true);
	};
	editAssignHierarchy = () => {
		this.setState({
			showPreview: false,
			showCreateLevel: true,
			assignHierarchy: true,
			basicDetails: false
		});
		this.props.setDisableItem(true);
	};
	editAssignPermissions = () => {
		this.setState({
			showPreview: false,
			showCreateLevel: true,
			assignPermission: true,
			basicDetails: false
		});
		this.props.setDisableItem(true);
	};
	deleteLevel = () => {
		//Call for Delete Level
		this.props.showLoadingButton(true);
		const dataToSend = {
			deleteLevelRequest: {
				level: {
					levelID: this.state.existingLevelID
				}
			}
		};
		RestSvc.postData(urlTypes.create_level_maintenance.deleteLevel, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				if (response.data.offersConfigHeader.offersConfigException) {
					toaster.notify(
						({ onClose }) => (
							<div className="Toaster__message-wrapper">
								<div>
									<div
										id="1"
										className="toasterRed Toaster__alert Toaster__alert_error"
									>
										<div className="Toaster__alert_text">
											{
												response.data.offersConfigHeader
													.offersConfigException.message
											}
										</div>
										<button
											className="Toaster__alert_close"
											type="button"
											aria-label="Close"
											onClick={onClose}
										>
											<span aria-hidden="true">×</span>
										</button>
									</div>
								</div>
							</div>
						),
						{
							duration: 10000
						}
					);
				} else if (
					response.data.offersConfigHeader.operationStatus ===
					'Level Deleted successfully.'
				) {
					toaster.notify(
						({ onClose }) => (
							<div className="Toaster__message-wrapper">
								<div>
									<div
										id="1"
										className="bg-success Toaster__alert Toaster__alert_error"
									>
										<div className="Toaster__alert_text">
											{response.data.offersConfigHeader.operationStatus}
										</div>
										<button
											className="Toaster__alert_close"
											type="button"
											aria-label="Close"
											onClick={onClose}
										>
											<span aria-hidden="true">×</span>
										</button>
									</div>
								</div>
							</div>
						),
						{
							duration: 10000
						}
					);
					this.setState({ showPreview: false });
					this.handleFormReset();
					this.fetchAllLevels();
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
		//End Call for Delete
	};

	confirmationModalClose = () => {
		this.setState({ confirmationModalShow: false });
	};

	proceedToOperation = () => {
		this.setState({ confirmationModalShow: false });
		if (this.state.confirmType === 'Cancel') {
			this.cancelCreatingLevel();
			this.showCardDetails(this.state.Levels[0]);
		} else if (this.state.confirmType === 'Delete') {
			this.deleteLevel();
		}
	};

	showCancelConfirmation = () => {
		if (
			this.state.createLevelForm.levelDescription.touched == true ||
			this.state.createLevelForm.levelName.touched == true ||
			this.state.createLevelForm.status.touched == true ||
			this.state.startDateTouched == true ||
			this.state.endDateTouched == true
		) {
			this.setState({
				confirmationModalShow: true,
				confirmType: 'Cancel',
				confirmMsg:
					'Are you sure you want to navigate away? The changes you made will be lost.'
			});
		} else {
			this.cancelCreatingLevel();
			this.showCardDetails(this.state.Levels[0]);
		}
	};

	showDeleteConfirmation = () => {
		this.setState({
			confirmationModalShow: true,
			confirmType: 'Delete',
			confirmMsg: 'Are you sure you want to delete this level?'
		});
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.createLevelForm) {
			formElementsArray.push({
				id: key,
				config: this.state.createLevelForm[key]
			});
		}
		return (
			<React.Fragment>
				<h1 className="roboto-b-24 m-0">Level Management</h1>
				<p className="my-2 roboto-r-14 grey">{this.state.Levels.length} Levels Available</p>
				<div className=" ">
					<div className="m-0 row">
						<div className="col-md-3 createLevel p-0 m-0">
							<div className="levelBlock">
								<span
									className={
										this.props.disableItem ||
										!(this.props.assignedResources.indexOf('OC007') != -1)
											? 'createBox disabled'
											: 'createBox'
									}
									onClick={() => {
										this.cancelCreatingLevel();
										this.setState({
											showCreateLevel: true,
											showPreview: false,
											isThisExistingLevel: false,
											createdOn: '',
											existingLevelID: ''
										});
										this.props.setDisableItem(true);
										this.fetchPermissions();
									}}
								>
									Create Level
								</span>
								<span className="dropdownImage">
									<img src={DropdownIcon} alt="img" />
								</span>
								<span className="filterImage">
									<img src={Filter} alt="img" />
								</span>
							</div>
							<ul
								className={
									this.props.disableItem
										? 'createdUsers disabled'
										: 'createdUsers'
								}
							>
								{this.state.Levels.map((level) => (
									<li
										id="eachRow"
										onClick={() => this.showCardDetails(level)}
										key={level.levelID}
										className={`user-list p-3 ${
											level.levelID === this.state.existingLevelID
												? 'selectedBox'
												: ''
										}`}
									>
										<div className="m-0 row rowlevel ml-0 pl-0 pb-2">
											<div className="five">{level.hierarchicalLevel}</div>
											<div className="levelstextColumn col-md-3 text-nowrap">
												{level.levelName}
											</div>
											<div
												className={
													level.status === 'Active' ||
													level.status === 'A'
														? 'greenDot col-md-2'
														: 'greenDot col-md-2 greyscale'
												}
											>
												<img src={GreenDot} alt="img" />
											</div>
										</div>
										<div className="m-0 row seniormanagerRow ml-0 pl-0 pb-2">
											{level.description ? (
												<div className="descriptionBox">
													{level.description}
												</div>
											) : null}
										</div>
										<div className="m-0 row loremRow ml-0"></div>
										<section>
											<p className="created-date roboto-r-11">
												Created on
												{level.effectiveStartDate.split(' ')[0]}|{' '}
												{level.effectiveEndDate
													? 'Ends on' +
													  level.effectiveEndDate.split(' ')[0]
													: null}
											</p>
										</section>
									</li>
								))}
							</ul>
						</div>
						{/* Create Level Part */}
						{this.state.showCreateLevel ? (
							<div className="col-md-9 position-relative">
								<div className="">
									<p className="textcreateLevel">
										{this.state.isThisExistingLevel
											? 'Modify Level'
											: 'Create Level'}
									</p>
									<div className="m-0 row main-body">
										<div className="col-md-3 mt-55 pr-0">
											<div
												onClick={() => {
													this.setState({
														basicDetails: true,
														assignHierarchy: false,
														assignPermission: false
													});
												}}
												className={
													this.state.basicDetails
														? 'parentDivSelected d-flex flex-column parentDiv'
														: 'd-flex flex-column parentDiv'
												}
											>
												<span className="span1">Basic Details</span>
												<span className="span2">
													{this.state.displayLevelName}
												</span>
											</div>
											<div
												onClick={() => {
													this.setState({
														basicDetails: false,
														assignHierarchy: true,
														assignPermission: false
													});
													if (
														this.state.updatedCards.length === 0 ||
														this.state.newCardAddedFlag === false
													) {
														if (!this.state.isThisExistingLevel) {
															let cardsTemp =
																this.state.LevelsForHierarchy.slice();
															cardsTemp.push({
																levelName:
																	this.state.createLevelForm
																		.levelName.value,
																hierarchicalLevel:
																	this.state.Levels.length + 1
															});
															this.setState({
																updatedCards: cardsTemp,
																newCardAddedFlag: true,
																hierarchicalLevel:
																	this.state.Levels.length + 1
															});
														}
													}
													this.setState({
														displayLevelName:
															this.state.createLevelForm.levelName
																.value
													});
												}}
												className={
													this.state.createLevelForm.levelName.value ===
														'' ||
													this.state.createLevelForm.status.value ===
														'' ||
													this.state.startDate === '' ||
													this.state.dateError !== '' ||
													this.state.levelNameError !== ''
														? 'd-flex flex-column parentDiv disabled'
														: this.state.assignHierarchy
														? 'parentDivSelected d-flex flex-column parentDiv'
														: 'd-flex flex-column parentDiv'
												}
											>
												<span className="span1">Assign Hierarchy</span>
												<span className="span2">
													{this.state.displayLevelName}
												</span>
											</div>
											<div
												onClick={() => {
													this.setState({
														basicDetails: false,
														assignHierarchy: false,
														assignPermission: true
													});
												}}
												className={
													this.state.createLevelForm.levelName.value ===
														'' ||
													this.state.createLevelForm.status.value ===
														'' ||
													this.state.startDate === '' ||
													this.state.dateError !== '' ||
													this.state.levelNameError !== ''
														? 'd-flex flex-column parentDiv disabled'
														: this.state.assignPermission
														? 'parentDivSelected d-flex flex-column parentDiv'
														: 'd-flex flex-column parentDiv'
												}
											>
												<span className="span1">Assign Permissions</span>
												<span className="span2">
													{this.state.displayLevelName}
												</span>
											</div>
										</div>
										<div className="col-md-9 pl-0">
											<form method="POST" onSubmit={this.onSubmit}>
												{this.state.basicDetails ? (
													<React.Fragment>
														<div className="createuserform pl-3 pb-3">
															<div
																key={formElementsArray[0].id}
																className="w300px"
															>
																<Input
																	key={formElementsArray[0].id}
																	elementType={
																		formElementsArray[0].config
																			.elementType
																	}
																	elementConfig={
																		formElementsArray[0].config
																			.elementConfig
																	}
																	value={
																		formElementsArray[0].config
																			.value
																	}
																	invalid={
																		!formElementsArray[0].config
																			.valid
																	}
																	shouldValidate={
																		formElementsArray[0].config
																			.validation
																	}
																	attrValidation={
																		formElementsArray[0].config
																			.attrValidation
																	}
																	touched={
																		formElementsArray[0].config
																			.touched
																	}
																	changed={(ev, isValidObj) =>
																		this.inputChangedHandler(
																			ev,
																			isValidObj,
																			formElementsArray[0].id
																		)
																	}
																/>
																<p className="errorMessage">
																	{this.state.levelNameError}
																</p>
																<p className="errorMessage">
																	{
																		formElementsArray[0].config
																			.validationMessage
																	}
																</p>
															</div>
															<div
																key={formElementsArray[1].id}
																className="w300px"
															>
																<Input
																	key={formElementsArray[1].id}
																	elementClass="customSelectIcon"
																	elementType={
																		formElementsArray[1].config
																			.elementType
																	}
																	elementConfig={
																		formElementsArray[1].config
																			.elementConfig
																	}
																	value={
																		formElementsArray[1].config
																			.value
																	}
																	invalid={
																		!formElementsArray[1].config
																			.valid
																	}
																	shouldValidate={
																		formElementsArray[1].config
																			.validation
																	}
																	touched={
																		formElementsArray[1].config
																			.touched
																	}
																	changed={(ev, isValidObj) =>
																		this.inputChangedHandler(
																			ev,
																			isValidObj,
																			formElementsArray[1].id
																		)
																	}
																/>
															</div>

															<div
																key={formElementsArray[2].id}
																className="w620px"
															>
																<Input
																	key={formElementsArray[2].id}
																	elementType={
																		formElementsArray[2].config
																			.elementType
																	}
																	elementConfig={
																		formElementsArray[2].config
																			.elementConfig
																	}
																	value={
																		formElementsArray[2].config
																			.value
																	}
																	invalid={
																		!formElementsArray[2].config
																			.valid
																	}
																	shouldValidate={
																		formElementsArray[2].config
																			.validation
																	}
																	attrValidation={
																		formElementsArray[2].config
																			.attrValidation
																	}
																	touched={
																		formElementsArray[2].config
																			.touched
																	}
																	changed={(ev, isValidObj) =>
																		this.inputChangedHandler(
																			ev,
																			isValidObj,
																			formElementsArray[2].id
																		)
																	}
																/>
															</div>
															<div className="maxWords">
																Maximum 20 characters
															</div>
															<p className="errorMessage">
																{
																	formElementsArray[2].config
																		.validationMessage
																}
															</p>

															<div className="lsDate">
																<label className="labelStyle">
																	Start Date & Time
																	<span className="text-danger">
																		*
																	</span>
																</label>
																<br />

																<Datetime
																	className={
																		this.state.dateError === ''
																			? ''
																			: 'Invalid'
																	}
																	locale="en-GB"
																	onChange={
																		this.onChangeStartDate
																	}
																	//For min and max date
																	isValidDate={(current) => {
																		return current.isAfter(
																			moment().subtract(
																				1,
																				'days'
																			)
																		);
																	}}
																	dateFormat="DD-MM-YYYY"
																	inputProps={{
																		placeholder:
																			'DD-MM-YYYY HH:mm'
																	}}
																	value={this.state.startDate}
																	closeOnSelect={true}
																	onViewModeChange={() => {
																		if (
																			this.state.startDate ==
																			''
																		) {
																			this.setState({
																				startDate:
																					new Date()
																			});
																		}
																	}}
																	timeFormat="HH:mm"
																/>
															</div>
															<div className="">
																<label className="labelStyle">
																	End Date & Time
																</label>
																<br />

																<div className="row pl-3">
																	<Datetime
																		className={
																			this.state.dateError ===
																			''
																				? 'w300px'
																				: ' w300px Invalid'
																		}
																		locale="en-GB"
																		onChange={
																			this.onChangeEndDate
																		}
																		//For min and max date
																		isValidDate={(current) => {
																			return current.isAfter(
																				moment(
																					this.state
																						.startDate
																				).subtract(
																					1,
																					'days'
																				)
																			);
																		}}
																		dateFormat="DD-MM-YYYY"
																		inputProps={{
																			placeholder:
																				'DD-MM-YYYY HH:mm'
																		}}
																		value={this.state.endDate}
																		closeOnSelect={true}
																		onViewModeChange={() => {
																			if (
																				this.state
																					.endDate == ''
																			) {
																				this.onChangeEndDate(
																					new Date()
																				);
																			}
																		}}
																		timeFormat="HH:mm"
																	/>
																	<button
																		className="clrBtn"
																		onClick={() => {
																			this.setState({
																				endDate: '',
																				dateError: ''
																			});
																		}}
																	>
																		X
																	</button>
																</div>
															</div>
															<p className="errorMessage float-left alignLeftDateErr">
																{this.state.dateError}
															</p>
														</div>
													</React.Fragment>
												) : null}
												{this.state.assignHierarchy ? (
													<Assign
														levels={this.state.updatedCards}
														newLevelName={
															this.state.createLevelForm.levelName
																.value
														}
														newHierarchicalLevel={
															this.state.hierarchicalLevel
														}
														setHierarchicalLevel={
															this.setHierarchicalLevel
														}
														updateCards={this.updateCards}
														loggedInLevelHierarchy={
															this.props.loggedInLevelHierarchy
														}
													/>
												) : null}
												{this.state.assignPermission ? (
													<React.Fragment>
														<div className="createuserform pl-3 pb-3">
															<div>
																<p className="font-weight-bold pt-2 roboto-b-14">
																	Assign permissions to:
																</p>
																<ul className="list-unstyled">
																	{this.state.permissionsArray.map(
																		(permission, index) => {
																			return (
																				<CheckBox
																					key={index}
																					handleCheckFieldElement={
																						this
																							.handleCheckFieldElement
																					}
																					{...permission}
																				/>
																			);
																		}
																	)}
																</ul>
															</div>
														</div>
													</React.Fragment>
												) : null}
											</form>
										</div>
									</div>
								</div>

								<div className="m-0 row footer">
									<div className="col-md-3 offset-md-2">
										{this.state.assignHierarchy ? null : (
											<div
												className="grey-button roboto-b-16"
												onClick={this.resetContent}
											>
												<img src={resetIcon} alt="img" /> Reset
											</div>
										)}
										<div
											className="grey-button roboto-b-16"
											onClick={this.showCancelConfirmation}
										>
											<img src={cancelIcon} alt="img" /> Cancel
										</div>
									</div>
									<div className="col-md-4 offset-md-1">
										<button
											disabled={this.state.basicDetails}
											className="btnNxtPrev op100 roboto-b-16"
											onClick={() => {
												if (this.state.assignHierarchy) {
													this.setState({
														basicDetails: true,
														assignHierarchy: false
													});
												} else {
													this.setState({
														assignPermission: false,
														assignHierarchy: true
													});
												}
											}}
										>
											<img src={leftArrow} alt="img" /> Previous
										</button>
										<button
											disabled={
												this.state.createLevelForm.levelName.value === '' ||
												this.state.createLevelForm.status.value === '' ||
												this.state.startDate === '' ||
												this.state.dateError !== '' ||
												this.state.levelNameError !== '' ||
												this.state.formIsValid === false
											}
											className="btnNxtPrev ml-2 roboto-b-16"
											onClick={() => {
												if (this.state.basicDetails) {
													this.setState({
														basicDetails: false,
														assignHierarchy: true
													});
													if (this.state.endDate !== '') {
														this.setState({ endDateSet: true });
													}
													if (
														this.state.updatedCards.length === 0 ||
														this.state.newCardAddedFlag === false
													) {
														if (!this.state.isThisExistingLevel) {
															let cardsTemp =
																this.state.LevelsForHierarchy.slice();
															cardsTemp.push({
																levelName:
																	this.state.createLevelForm
																		.levelName.value,
																hierarchicalLevel:
																	this.state.LevelsForHierarchy
																		.length + 1
															});
															this.setState({
																updatedCards: cardsTemp,
																newCardAddedFlag: true,
																hierarchicalLevel:
																	this.state.LevelsForHierarchy
																		.length + 1
															});
														}
													}
													this.setState({
														displayLevelName:
															this.state.createLevelForm.levelName
																.value
													});
												} else if (this.state.assignHierarchy) {
													this.setState({
														assignPermission: true,
														assignHierarchy: false
													});
												} else if (this.state.assignPermission) {
													this.setState({
														showCreateLevel: false,
														showPreview: true,
														assignPermission: false,
														clickPreview: false
													});
												}
											}}
										>
											Next <img src={rightArrow} alt="img" />
										</button>
									</div>
								</div>
							</div>
						) : null}
						{this.state.showPreview ? (
							<PreviewScreen
								formElements={{ ...this.state }}
								createLevel={this.createLevel}
								cancel={this.showCancelConfirmation}
								editBasic={this.editBasicDetails}
								editHierarchy={this.editAssignHierarchy}
								editPermissions={this.editAssignPermissions}
								deleteLevel={this.showDeleteConfirmation}
								createdOn={this.state.createdOn}
								isExistingLevel={this.state.isThisExistingLevel}
							/>
						) : null}
					</div>
				</div>
				{this.state.confirmationModalShow ? (
					<ConfirmationModal
						show={this.state.confirmationModalShow}
						onHide={this.confirmationModalClose}
						onProceed={this.proceedToOperation}
						confirmmsg={this.state.confirmMsg}
					/>
				) : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		disableItem: state.disableItem,
		assignedResources: state.assignedResources,
		loggedInLevelID: state.loggedInLevelID,
		loggedInLevelHierarchy: state.loggedInLevelHierarchy
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setLevelID: (val) => dispatch({ type: actionTypes.LOGGEDIN_LEVELID, value: val }),
		setLevelHierarchy: (val) =>
			dispatch({ type: actionTypes.LOGGEDIN_LEVELHIERARCHY, value: val }),
		setLoggedInBrand: (val) => dispatch({ type: actionTypes.LOGGEDIN_BRAND, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LevelMaintenance));
