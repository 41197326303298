import React from 'react';
import { Form } from 'formik';

import CollapseHeaderBar from './CollapseHeaderBar';
import CollapseBody from './CollapseBody';
import BenefitSettings from './BenefitSettings';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import SimplePromotion from '../components/SimplePromotion/SimplePromotion';
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';

let ScaleMerchTableData = [];
const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	triggerCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Determines the combination code to be used between the trigger items for a promotion',
	thresholdQuantity: 'Threshold Quantity',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	rewardQuantity: 'Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	PaymentConditions: 'Payment Conditions',
	CouponConditions: 'Coupon Conditions',
	ExclusionsSetting: 'Exclusion Settings'
};
const EligibilityForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	const onProductTableChange = (val) => {
		props.onProductTableChanged(val);
	};
	const onMerchTableChange = (val) => {
		ScaleMerchTableData = val;

		props.onMerchTableDataChange(ScaleMerchTableData);
	};
	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	const onMouseEnterHandler = (evt, mouseIsON) => {
		if (mouseIsON != props.curntMousePlace) {
			props.setMousePlace(mouseIsON);
		}
	};

	return (
		<div>
			<Form className={offerFlow.type == 'view-offer-flow' ? 'disabledEvent' : ''}>
				<div name="PurchaseandRewardBlock">
					<SimplePromotion />
				</div>
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToBenefitSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToBenefitSettings')}
					>
						<CollapseHeaderBar
							text={localConst.benefitSettings}
							headingId={'headingTwo'}
							dataTarget={'#collapseTwo'}
						/>
						<CollapseBody id="collapseTwo" headingId={'headingTwo'}>
							<BenefitSettings
								onProductTableChanged={(val) => onProductTableChange(val)}
								onMerchTableDataChange={(val) => onMerchTableChange(val)}
								ScaleMerchTableData={ScaleMerchTableData}
								prodData={props.dataMTMBtable}
								data={props.data1}
								customName="simplePromotionEligibility"
							/>
						</CollapseBody>
					</Element>
				</div>
			</Form>
		</div>
	);
};

//const EligibilitySubForm = withSubForm(EligibilityForm, eligibilitySchema);
//export default EligibilitySubForm;
//export default EligibilityForm;

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
