import React, { useEffect } from 'react';
import { Form, useField } from 'formik';
import CustomSelect from './customfields/CustomSelect';
import CollapseHeaderBar from './CollapseHeaderBar';
import CollapseBody from './CollapseBody';
import BenefitSettings from './BenefitSettings';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import PaymentCondition from './PaymentCondition';
import CouponCondition from './CouponCondition';
import ExclusionSetting from './ExclusionSetting';
import { itemHelp } from './item-help-text';
import ConditionalRederingPKG from './PackageProduct/ConditionalRederingPKG';
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import GroupArray from './PackageComponent/GroupArray';
import $ from 'jquery';

const thresholdTypeCodeOptions = [
	{
		value: '',
		name: 'Select one...'
	},
	{
		value: 'PACKAGE_INTERVAL_QTY_THRESHOLD',
		name: 'Package Interval Quantity Threshold'
	},
	{
		value: 'PACKAGE_QTY_THRESHOLD',
		name: 'Package Quantity Threshold'
	},
	{
		value: 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
		name: 'Package Interval Amount Threshold'
	},
	{
		value: 'PACKAGE_AMOUNT_THRESHOLD',
		name: 'Package Amount Threshold'
	},
	{
		value: 'PACKAGE_RANGE_QTY_THRESHOLD',
		name: 'Package Range Quantity Threshold'
	},
	{
		value: 'PACKAGE_RANGE_AMOUNT_THRESHOLD',
		name: 'Package Range Amount Threshold'
	},
	{
		value: 'PACKAGE_LIMIT_QTY_THRESHOLD',
		name: 'Package Limit Quantity Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD',
		name: 'Hierarchy Package Interval Quantity Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_QTY_THRESHOLD',
		name: 'Hierarchy Package  Quantity Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
		name: 'Hierarchy Package Interval Amount Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD',
		name: 'Hierarchy Package Amount Threshold'
	}
];

const getThresholdDropdown = (thresholdTypeCodeOptions) => {
	return thresholdTypeCodeOptions.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	triggerCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Determines the combination code to be used between the trigger items for a promotion',
	thresholdQuantity: 'Threshold Quantity',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	rewardQuantity: 'Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	PaymentConditions: 'Payment Conditions',
	CouponConditions: 'Coupon Conditions',
	ExclusionsSetting: 'Exclusion Settings'
};

const EligibilityForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [field] = useField(props);

	const fieldPKG = field.value.packageMultibuyEligibility;
	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));

	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
					.thresholdTypeCode != field.value.packageMultibuyEligibility.thresholdTypeCode
			) {
				let tempName = 'packageMultibuyEligibility.thresholdTypeCode';

				$('[name="' + tempName + '"]')
					.closest('.card-body')
					.nextAll()
					.addClass('hide-nochange-section-for-audit');
			}
		}
	}, []);

	const onPKGProductTableChange = (val) => {
		props.onPKGProductTableChange(val);
	};

	const onMerchTableDataChange = (val) => {
		props.onMerchTableDataChange(val);
	};

	const onMouseEnterHandler = (evt, mouseIsON) => {
		if (mouseIsON != props.curntMousePlace) {
			props.setMousePlace(mouseIsON);
		}
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

	return (
		<div>
			<Form className={offerFlow.type == 'view-offer-flow' ? 'disabledEvent' : ''}>
				<Element
					name="scrollToPurchaseandRewardConditions"
					class="card"
					style={{ border: 'none' }}
					onMouseEnter={(e) =>
						onMouseEnterHandler(e, 'scrollToPurchaseandRewardConditions')
					}
				>
					<div className="container-fluid" name="PurchaseandRewardBlock">
						<div className="card-body">
							<div className="d-flex justify-content-center container">
								<CustomSelect
									control="select"
									label="Threshold Type Code"
									name="packageMultibuyEligibility.thresholdTypeCode"
									options={getThresholdDropdown(thresholdTypeCodeOptions)}
									required={true}
									className="customDropdown inlinePadding"
									smallText={itemHelp.ThresholdTypeCodePkgProd}
								/>
							</div>
						</div>

						{fieldPKG.thresholdTypeCode ? (
							<>
								<ConditionalRederingPKG value={fieldPKG.thresholdTypeCode} />

								{JSON.parse(sessionStorage.getItem('typeOfOffersFlow'))
									.isAuditFlow ? (
									<div className="packageForAuditContainer lightBlue p-2">
										<div className="happyHourForAudit">
											{fieldPKG?.groupAdded?.length > 0 ? (
												<div className="bg-white m-4 p-3 mt-2">
													<label className="font-weight-bold">
														Added ({fieldPKG?.groupAdded?.length})
													</label>
													<GroupArray
														onPKGProductTableChange={
															onPKGProductTableChange
														}
														onMerchTableDataChange={
															onMerchTableDataChange
														}
														dataPKGtable={props.dataPKGtable}
														merdata={props.merdata}
														customArrayName="groupAdded"
														fieldPKG={fieldPKG}
														submitCnt={props.submitCnt}
														fullValue={props.fullValue}
													/>
												</div>
											) : undefined}
											{fieldPKG?.groupRemoved?.length > 0 ? (
												<div className="bg-white m-4 p-3">
													<label className="font-weight-bold">
														Removed ({fieldPKG?.groupRemoved?.length})
													</label>
													<GroupArray
														onPKGProductTableChange={
															onPKGProductTableChange
														}
														onMerchTableDataChange={
															onMerchTableDataChange
														}
														dataPKGtable={props.dataPKGtable}
														merdata={props.merdata}
														customArrayName="groupRemoved"
														fieldPKG={fieldPKG}
														submitCnt={props.submitCnt}
														fullValue={props.fullValue}
													/>
												</div>
											) : undefined}
										</div>
										{fieldPKG?.group?.length > 0 ? (
											<div className="bg-white m-4 p-3">
												<label className="font-weight-bold">
													Modified ({fieldPKG?.group?.length})
												</label>
												<GroupArray
													onPKGProductTableChange={
														onPKGProductTableChange
													}
													onMerchTableDataChange={onMerchTableDataChange}
													dataPKGtable={props.dataPKGtable}
													merdata={props.merdata}
													customArrayName="group"
													fieldPKG={fieldPKG}
													submitCnt={props.submitCnt}
													fullValue={props.fullValue}
												/>
											</div>
										) : undefined}
									</div>
								) : (
									<GroupArray
										onPKGProductTableChange={onPKGProductTableChange}
										onMerchTableDataChange={onMerchTableDataChange}
										dataPKGtable={props.dataPKGtable}
										merdata={props.merdata}
										customArrayName="group"
										fieldPKG={fieldPKG}
										submitCnt={props.submitCnt}
										fullValue={props.fullValue}
									/>
								)}
							</>
						) : null}
					</div>
				</Element>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToExclusionsSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToExclusionsSettings')}
					>
						<CollapseHeaderBar
							text={localConst.ExclusionsSetting}
							headingId={'headingES'}
							dataTarget={'#collapseES'}
						/>
						<CollapseBody id="collapseES" headingId={'headingES'}>
							<ExclusionSetting
								customName="packageMultibuyEligibility"
								fullValue={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToPaymentConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToPaymentConditions')}
					>
						<CollapseHeaderBar
							text={localConst.PaymentConditions}
							headingId={'headingFour'}
							dataTarget={'#collapseFour'}
						/>
						<CollapseBody id="collapseFour" headingId={'headingFour'}>
							<PaymentCondition
								customName="packageMultibuyEligibility"
								fullVal={props.fullValue}
								loggedInBrand={props.loggedInBrand}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToCouponConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToCouponConditions')}
					>
						<CollapseHeaderBar
							text={localConst.CouponConditions}
							headingId={'headingFive'}
							dataTarget={'#collapseFive'}
						/>
						<CollapseBody id="collapseFive" headingId={'headingFive'}>
							<CouponCondition
								customName="packageMultibuyEligibility"
								fullVal={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToBenefitSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToBenefitSettings')}
					>
						<CollapseHeaderBar
							text={localConst.benefitSettings}
							headingId={'headingTwo'}
							dataTarget={'#collapseTwo'}
						/>
						<CollapseBody id="collapseTwo" headingId={'headingTwo'}>
							<BenefitSettings {...props} customName="packageMultibuyEligibility" />
						</CollapseBody>
					</Element>
				</div>
			</Form>
		</div>
	);
};

//export default EligibilityForm;

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
