import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { useField, FieldArray, useFormikContext } from 'formik';
import CustomDateTime from './customfields/CustomDateTime';
import * as Yup from 'yup';
import moment from 'moment';

// Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
//   return this.test('unique', message, function (list) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
	return this.test('unique', message, function (list) {
		if (!list) list = [];
		return list.length === new Set(list.map(mapper)).size;
	});
});

const BlackOutPeriod = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();
	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

	const fieldBlackOut =
		props.blackOutType == 'blackOutAdded'
			? field.value.promotionPolicy.promotionApplicableEligibility
					.happyHoursApplicableEligibility.blackOutAdded
			: props.blackOutType == 'blackOutRemoved'
			? field.value.promotionPolicy.promotionApplicableEligibility
					.happyHoursApplicableEligibility.blackOutRemoved
			: field.value.promotionPolicy.promotionApplicableEligibility
					.happyHoursApplicableEligibility.blackOutPeriod;

	const customValidateBlackoutStart = (value) => {
		let error;

		if (value) {
			if (offerFlow.type != 'view-offer-flow') {
				if (moment(field.value.promotionPolicy.effectiveStartDate) > moment(value)) {
					error = 'Entered date should not be less than the promotion start date';
				} else if (moment(field.value.promotionPolicy.effectiveEndDate) < moment(value)) {
					error = 'Entered date should not be greater than the promotion end date';
				}
			}
		}

		return error;
	};
	const customValidateBlackoutEnd = (value) => {
		let error;

		if (value) {
			if (offerFlow.type != 'view-offer-flow') {
				if (moment(field.value.promotionPolicy.effectiveStartDate) > moment(value)) {
					error = 'Entered date should not be greater than the promotion start date';
				} else if (moment(field.value.promotionPolicy.effectiveEndDate) < moment(value)) {
					error = 'Entered date should not be greater than the promotion end date';
				}
			}
		}

		return error;
	};

	useEffect(() => {}, []);

	return (
		<>
			<FieldArray
				name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.${props.blackOutType}`}
			>
				{({ insert, remove, push }) => (
					<>
						{fieldBlackOut?.map((item, index) => (
							<div key={index} className="d-flex w-auto">
								<CustomDateTime
									name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.${props.blackOutType}.${index}.blackOutStartDate`}
									label={index == 0 ? 'Blackout Start Date' : ''}
									validate={customValidateBlackoutStart}
									index={index}
									valueDate={fieldBlackOut[index].blackOutStartDate}
									flag="show"
								/>
								<CustomDateTime
									name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.${props.blackOutType}.${index}.blackOutEndDate`}
									label={index == 0 ? 'Blackout End Date' : ''}
									validate={customValidateBlackoutEnd}
									index={index}
									valueDate={fieldBlackOut[index].blackOutEndDate}
									flag="show"
								/>
								{index == 0 ? (
									<>
										<div className="mt-36 hideForAudit">
											{' '}
											<button
												type="button"
												className="addPlusBtn bg-color mr-3 "
												onClick={() => {
													if (
														Object.keys(fieldBlackOut[0]).length !==
															0 &&
														fieldBlackOut[0].hasOwnProperty(
															'blackOutStartDate'
														) &&
														fieldBlackOut[0].hasOwnProperty(
															'blackOutEndDate'
														)
													) {
														if (
															fieldBlackOut[0].blackOutStartDate !=
																'' &&
															fieldBlackOut[0].blackOutEndDate != ''
														) {
															fieldBlackOut.push({
																blackOutStartDate: '',
																blackOutEndDate: ''
															});
															setFieldValue('', '');
														}
													}
												}}
											></button>
											<button
												type="button"
												className="addDeleteBtn bg-color"
												onClick={() => {
													if (fieldBlackOut.length > 1) {
														fieldBlackOut.splice(index, 1);
														setFieldValue('', '');
													} else {
														setFieldValue(
															`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.${
																props.blackOutType
															}.${0}.blackOutStartDate`,
															''
														);
														setFieldValue(
															`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.${
																props.blackOutType
															}.${0}.blackOutEndDate`,
															''
														);
													}
												}}
											></button>
										</div>
									</>
								) : (
									''
								)}
								{index != 0 ? (
									<button
										type="button"
										disabled={index === 0}
										className="addDeleteBtn bg-color mt-3 hideForAudit"
										onClick={() => {
											fieldBlackOut.splice(index, 1);
											setFieldValue('', '');
										}}
									></button>
								) : (
									''
								)}
							</div>
						))}
					</>
				)}
			</FieldArray>
		</>
	);
};

export default BlackOutPeriod;
