import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { FieldArray } from 'formik';
import * as Yup from 'yup';
import CustomInputText2 from './customfields/CustomInputText2';

// Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
//   return this.test('unique', message, function (list) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
	return this.test('unique', message, function (list) {
		if (!list) list = [];
		return list.length === new Set(list.map(mapper)).size;
	});
});

const EmiScale = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const index = props.index;
	const i = props.i;
	const filednamepmtelg = props.filednamepmtelg;
	const fieldPaymentGroup =
		props.pgType == 'paymentGroupAdded'
			? filednamepmtelg?.payment?.paymentGroupAdded
			: props.pgType == 'paymentGroupRemoved'
			? filednamepmtelg?.payment?.paymentGroupRemoved
			: filednamepmtelg?.payment?.paymentGroup;
	const fieldIssuer =
		props.isType == 'issuerAdded'
			? fieldPaymentGroup[index]?.issuersAdded
			: props.isType == 'issuersRemoved'
			? fieldPaymentGroup[index]?.issuersRemoved
			: fieldPaymentGroup[index]?.issuers;
	const fieldEmiScale =
		props.emiScaleType == 'emiScaleAdded'
			? fieldIssuer[i]?.emi?.emiScaleAdded
			: props.emiScaleType == 'emiScaleRemoved'
			? fieldIssuer[i]?.emi?.emiScaleRemoved
			: fieldIssuer[i]?.emi?.emiScale;
	const valueEligbility = props.valueEligbility;

	const customValidateTenureInIssuers = (value) => {
		let error;
		//debugger
		if (value) {
			let tenureArray = fieldEmiScale.map((val) => val.tenurePeriodInMonths);

			var count = tenureArray.reduce(function (n, val) {
				return n + (val === value);
			}, 0);
			if (count > 1) error = 'Tenure Period In Months cannot be same';
		}
		return error;
	};
	const customValidateInterestRateInIssuers = (value) => {
		let error;
		//debugger
		if (value) {
			let interestArray = fieldEmiScale.map((val) => val.interestRate);

			var count = interestArray.reduce(function (n, val) {
				return n + (val === value);
			}, 0);
			if (count > 1) error = 'Interest Rate cannot be same';
		}
		return error;
	};

	useEffect(() => {}, []);

	return (
		<>
			<FieldArray
				name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.${props.emiScaleType}`}
			>
				{({ remove }) => (
					<div className={isAuditFlw ? 'w-auto' : 'issuerOuterBox w-auto'}>
						{fieldIssuer[i].emi &&
							fieldEmiScale &&
							fieldEmiScale.map((item, j) => (
								<div key={j} className="aggregatorBox1">
									{fieldEmiScale.length > 1 ? (
										<div className="d-flex justify-content-end">
											{' '}
											<button
												type="button"
												className="addDeleteBtn"
												onClick={() => remove(j)}
											></button>
										</div>
									) : null}

									<div className="row">
										<div className="col-sm-3">
											{/* <label className="mb-0 ml-2">
                                                        Tenure Period In Months
                          </label>
                                                      <Field
                                                        className="form-control m-2"
                                                        name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.${props.emiScaleType}.${j}.tenurePeriodInMonths`}
                                                        type="text"
                                                        validate={(val) => customValidateTenureInIssuers(val, index, i)}
                                                      /> */}
											<CustomInputText2
												name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.${props.emiScaleType}.${j}.tenurePeriodInMonths`}
												type="text"
												label={'Tenure Period In Months'}
												validate={(val) =>
													customValidateTenureInIssuers(val, index, i)
												}
											/>
											{/* <ErrorMessage
                                                        name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.${props.emiScaleType}.${j}.tenurePeriodInMonths`}
                                                        component="div"
                                                        className="field-error"
                                                      /> */}
										</div>
										<div className="col-sm-3">
											{/* <label className="mb-0 ml-2">
                                                        Interest Rate
                          </label>
                                                      <Field
                                                        className="form-control m-2"
                                                        name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.${props.emiScaleType}.${j}.interestRate`}
                                                        type="text"
                                                        validate={(val) => customValidateInterestRateInIssuers(val, index, i)}
                                                      /> */}
											<CustomInputText2
												name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.${props.emiScaleType}.${j}.interestRate`}
												type="text"
												label={'Interest Rate'}
												validate={(val) =>
													customValidateInterestRateInIssuers(
														val,
														index,
														i
													)
												}
											/>
											{/* <ErrorMessage
                                                        name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.${props.emiScaleType}.${j}.interestRate`}
                                                        component="div"
                                                        className="field-error"
                                                      /> */}
										</div>
										<div className="col-sm-3">
											{/* <label className="mb-0 ml-2">
                                                        Amount Capping
                          </label>
                                                      <Field
                                                        className="form-control m-2"
                                                        name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.${props.emiScaleType}.${j}.cappedAmount`}
                                                        type="text"
                                                        disabled={JSON.parse(props.fullVal).promotionPolicy.rewardLimit}
                                                      /> */}
											<CustomInputText2
												name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.${props.emiScaleType}.${j}.cappedAmount`}
												type="text"
												label={' Amount Capping'}
												disabled={
													JSON.parse(props.fullVal).promotionPolicy
														.rewardLimit
												}
											/>
											{/* <ErrorMessage
                                                        name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.${props.emiScaleType}.${j}.cappedAmount`}
                                                        component="div"
                                                        className="field-error"
                                                      /> */}
										</div>
									</div>
								</div>
							))}
					</div>
				)}
			</FieldArray>
		</>
	);
};

export default EmiScale;
