import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Datetime from 'react-datetime';
import moment from 'moment';

import './notificationScreen.scss';
// import '../../MainContent/Components/scroll.scss';
import viewOfferIcon from '../../assets/images/viewOffer.png';
import path from '../../assets/images/path.png';

import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import GroupInformationModal from '../GroupInformationModal';
import * as actionTypes from '../../store/actions';
import ReactTable, { headerFormatter, textFilter } from '../../Components/ReactTable/ReactTable';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { utcToLocalDate } from '../../screens/OffterMaintainance/components/Audit/auditHelperFunction';
import ToggleSwitch from '../../Components/ToggleSwitch/ToggleSwitch';
import Toaster from '../../Components/Toaster/Toaster';
import { downloadFile, stringReplacer } from '../../utils/helperFunction';
import appLabels from '../../utils/appLabels';
import appConstants from '../../utils/appConstants';

const {
	eventType: {
		GENERATE_COUPON,
		CUSTOM_COUPON,
		UPLOAD_PROMOTION,
		UPDATE_PROMOTION,
		DOWNLOAD_OFFERS,
		DOWNLOAD_COUPONS,
		PROMO_CUSTOMER_UPDATE,
		AUTOMATIC_COUPON_EXPIRY,
		CUSTOMERHASH_COUPON_MAP,
		COUPON_UPLOAD,
		UPLOAD_PROMOTIONS_LITE
	}
} = appConstants;

const {
	common: commonLabels,
	table: tableLabels,
	page: { notification: labels }
} = appLabels;

const pageConst = {
	FILE_NOT_FOUND: 'The file is not found in storage'
};

const downloadableEvent = [
	GENERATE_COUPON,
	CUSTOM_COUPON,
	UPLOAD_PROMOTION,
	UPDATE_PROMOTION,
	DOWNLOAD_OFFERS,
	DOWNLOAD_COUPONS
];

const downloadableEventStatus = ['SUCCESS', 'PARTIALLY SUCCESS'];

const NotificationScreen = (props) => {
	const [notificationsList, setNotificationsList] = useState([]);
	const [groupInformationModalShow, setGrpInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState();
	const [informationTitle, setInformationMsgTitle] = useState();
	const [rowErrorMsg, setRowErrorMsg] = useState('');
	// const [pageNumber, setPageNum] = useState(1);
	const informationModalClose = () => {
		setGrpInformationModalShow(false);
	};
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	const [fromDateError, setFromDateError] = useState('');
	const [toDateError, setToDateError] = useState('');
	const [maxSearchPeriod, setMaxSearchPeriod] = useState(0);
	const [showUploadedOfferFiles, setShowUploadedOfferFiles] = useState(false);

	useEffect(() => {
		setNotificationsList([]);
		viewNotificationDetails(1, showUploadedOfferFiles);
	}, [showUploadedOfferFiles]);

	useEffect(() => {
		validateFromDate();
		if (toDateError && toDateError !== '' && !(fromDateError && fromDateError !== '')) {
			validateToDate();
		}
	}, [fromDate]);

	useEffect(() => {
		validateToDate();
		if (fromDateError && fromDateError !== '' && !(toDateError && toDateError !== '')) {
			validateFromDate();
		}
	}, [toDate]);

	useEffect(() => {
		if (fromDateError && fromDateError !== '' && !(toDateError && toDateError !== '')) {
			validateFromDate();
		}
	}, [toDateError]);

	useEffect(() => {
		if (toDateError && toDateError !== '' && !(fromDateError && fromDateError !== '')) {
			validateToDate();
		}
	}, [fromDateError]);

	const viewNotificationDetails = (pageNumber, showUploadedOfferFile) => {
		let dataToSend;
		if (showUploadedOfferFile) {
			dataToSend = {
				couponNotificationDetailsRequest: {
					userId: sessionStorage.getItem('loggedInUserId'),
					pageNumber: pageNumber ? pageNumber - 1 : undefined,
					fromDate:
						fromDate && fromDate != ''
							? moment(fromDate, 'DD-MM-YYYY', true).format('YYYY-MM-DD HH:mm:ss')
							: undefined,
					toDate:
						toDate && toDate != ''
							? moment(toDate, 'DD-MM-YYYY', true).format('YYYY-MM-DD HH:mm:ss')
							: undefined,
					eventType: 'ALL USER EVENTS',
					programID: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId
				}
			};
		} else {
			dataToSend = {
				couponNotificationDetailsRequest: {
					userId: sessionStorage.getItem('loggedInUserId'),
					pageNumber: pageNumber ? pageNumber - 1 : undefined,
					fromDate:
						fromDate && fromDate != ''
							? moment(fromDate, 'DD-MM-YYYY', true).format('YYYY-MM-DD HH:mm:ss')
							: undefined,
					toDate:
						toDate && toDate != ''
							? moment(toDate, 'DD-MM-YYYY', true).format('YYYY-MM-DD HH:mm:ss')
							: undefined
				}
			};
		}
		props.showLoadingButton(true);
		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		RestSvc.postData(urlTypes.user_maintenance.getNotification, dataToSend, svPromUrl)
			.then((response) => {
				props.showLoadingButton(false);
				if (response?.data) {
					var result = response.data?.CouponNotificationDetailsResponse;
					if (result?.businessError) {
						// setPageNum(pageNumber - 1);
						Toaster({ message: result.businessError.description });
					}
					if (result) {
						let notifications = result?.notificationDetailsList;
						if (notifications) {
							const _notifications = notifications.map((notification) => {
								const temp = notification;
								temp.status = getStatus(
									notification.status,
									notification.eventType,
									notification.errorMessage
								);
								return temp;
							});
							setNotificationsList([..._notifications]);
						}
						if (result?.fromDate) {
							let fromDateTime = moment(result.fromDate, 'YYYY-MM-DD HH:mm:ss', true);
							setFromDate(fromDateTime.format('DD-MM-YYYY'));
						}
						if (result?.toDate) {
							let toDateTime = moment(result.toDate, 'YYYY-MM-DD HH:mm:ss', true);
							setToDate(toDateTime.format('DD-MM-YYYY'));
						}
						if (result.maxSearchPeriod > 0) {
							setMaxSearchPeriod(result.maxSearchPeriod);
						}
					}
				}
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const getStatus = (status, eventType, error) => {
		if (status === 'P') {
			return labels.pending;
		} else if (status === 'S') {
			if (error && error != null && eventType !== PROMO_CUSTOMER_UPDATE) {
				return labels.partiallySuccess;
			}
			return labels.success;
		} else if (status === 'F') {
			return labels.failure;
		} else if (status === 'R') {
			return labels.retrying;
		} else if (status === 'Q') {
			return labels.inQueue;
		}
		return status;
	};

	const downloadFileHandler = async (filename, uuId, eventType, row) => {
		props.showLoadingButton(true);
		try {
			let response = {};
			let dataToSend = {
				couponNotificationDetailsRequest: {
					userId: sessionStorage.getItem('loggedInUserId'),
					filename,
					uuId,
					eventType
				}
			};
			const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
			response = await RestSvc.postData(
				urlTypes.user_maintenance.notificationDownloadFile,
				dataToSend,
				svPromUrl
			);
			props.showLoadingButton(false);
			if (response?.status == 200) {
				if (response.headers?.['content-type']?.startsWith('application/json')) {
					let errorMsg = response.data;
					if (errorMsg === pageConst.FILE_NOT_FOUND && row.status === 'PENDING') {
						errorMsg = labels.downloadPending;
					}
					Toaster({ message: errorMsg });
					return;
				}
				Toaster({
					message: labels.downloadSuccess
				});
			} else {
				Toaster({ message: commonLabels.downloadsFailed });
			}
			downloadFile(response.data, filename);
		} catch (error) {
			props.showLoadingButton(false);
			console.error(error);
		}
	};

	const formatFileNameCell = (cell, row) => {
		let cellText = '';
		const cellTextSplit = cell?.split('|').filter((v) => v);
		cellText = cellTextSplit?.reduce((pre, cur, i) => {
			let newReducer = [...pre];
			let current = cur;
			pre.length >= 1 && newReducer.push(<br key={i} />);

			const temp = current?.split('##');
			const tempCurrent = temp[temp.length - 1];
			/** e.g current = test 1 ## test 2 it become # test2  */
			if (tempCurrent) {
				current = tempCurrent; /* replace the text with last ## */
			}
			newReducer.push(current);
			return newReducer;
		}, []);

		if (
			props.assignedResources.indexOf('OC011') !== -1 &&
			downloadableEvent.includes(row.eventType) &&
			downloadableEventStatus.includes(row.status)
		) {
			return (
				<button
					className="btn btn-link download-file"
					onClick={() => downloadFileHandler(row.fileName, row.uuId, row.eventType, row)}
				>
					{cellText}
				</button>
			);
		} else {
			return cellText;
		}
	};

	const linkAction = (cell, row) => {
		return (
			<>
				<div
					style={{ width: '40px', height: '40px', display: 'flex', flexDirection: 'row' }}
				>
					<span
						style={{ padding: 5 }}
						onClick={() => {
							setRowErrorMsg(row.errorMessage || '');
							let infoMsg = row.statusMessage;
							if (
								row.status === 'PENDING' &&
								row.eventType != AUTOMATIC_COUPON_EXPIRY
							) {
								infoMsg = labels.fileUploadProgress;
							} else if (
								row.status === 'IN QUEUE' &&
								[
									GENERATE_COUPON,
									CUSTOM_COUPON,
									CUSTOMERHASH_COUPON_MAP,
									COUPON_UPLOAD,
									UPLOAD_PROMOTIONS_LITE
								].includes(row.eventType)
							) {
								infoMsg = labels.requestInProgress;
							}
							setInformationMsg(infoMsg);
							setGrpInformationModalShow(true);
							setInformationMsgTitle(commonLabels.information);
						}}
					>
						<img
							src={viewOfferIcon}
							title={labels.viewStatusTitle}
							alt={labels.viewStatusTitle}
							className="imageView highlight"
						/>
					</span>
					<CopyToClipboard
						text={row.uuId}
						onCopy={() => {
							Toaster({ message: commonLabels.copyToClipboardSussesMsg });
						}}
					>
						<span className="pointer" style={{ padding: 5 }}>
							<img
								src={path}
								title={commonLabels.copyIconTitle}
								alt={commonLabels.copyIconTitle}
								className="imagePath highlight"
							/>
						</span>
					</CopyToClipboard>
				</div>
			</>
		);
	};

	const columns = [
		{
			dataField: 'createdOn',
			text: tableLabels.createdOn,
			headerStyle: { width: '150px' },
			headerAlign: 'center',
			formatter: utcToLocalDate
		},
		{
			dataField: 'userId',
			text: tableLabels.userID,
			headerFormatter,
			headerStyle: { width: '200px' },
			headerAlign: 'center',
			filter: textFilter()
		},
		{
			dataField: 'uuId',
			text: tableLabels.requestID,
			headerFormatter,
			headerStyle: { width: '200px' },
			headerAlign: 'center',
			filter: textFilter()
		},
		{
			dataField: 'fileName',
			text: tableLabels.fileName,
			headerAlign: 'center',
			headerFormatter,
			style: { wordBreak: 'break-all' },
			formatter: (cell, row, rowIndex) => formatFileNameCell(cell, row, rowIndex),
			filter: textFilter()
		},
		{
			dataField: 'eventType',
			text: tableLabels.eventType,
			headerAlign: 'center',
			headerFormatter,
			style: { wordBreak: 'break-all' },
			filter: textFilter()
		},
		{
			dataField: 'status',
			text: tableLabels.status,
			headerFormatter,
			headerStyle: { width: '150px' },
			headerAlign: 'center',
			filter: textFilter()
		},
		{
			dataField: 'processedCouponsCount',
			text: tableLabels.processedCount,
			headerStyle: { width: '150px' },
			headerAlign: 'center'
		},
		{
			dataField: 'errorMessage',
			text: tableLabels.status_ErrorMessage,
			headerStyle: { width: '150px' },
			hidden: true,
			headerAlign: 'center'
		},
		{
			dataField: '',
			text: tableLabels.action,
			headerAlign: 'center',
			formatter: linkAction,
			headerStyle: { width: '70px' },
			searchable: false
		}
	];

	const validateFromDate = () => {
		if (fromDate && fromDate !== '') {
			if (moment(fromDate, 'DD-MM-YYYY', true).isValid()) {
				let fromDateTime = moment(fromDate, 'DD-MM-YYYY', true);
				let toDateTime = moment(toDate, 'DD-MM-YYYY', true);
				if (fromDateTime.isBefore(moment())) {
					if (
						toDateTime &&
						toDateTime !== '' &&
						fromDateTime.isAfter(moment(toDateTime))
					) {
						setFromDateError(labels.fromDateBeforeErrText);
					} else {
						setFromDateError('');
					}
				} else {
					setFromDateError(labels.fromDateBtwErrText);
				}
			} else {
				setFromDateError(labels.dateInvalidText);
			}
		} else {
			if (toDateError && toDateError !== '') {
				validateToDate();
			}
		}
	};

	const validateToDate = () => {
		if (toDate && toDate !== '') {
			let fromDateTime = moment(fromDate, 'DD-MM-YYYY', true);
			let toDateTime = moment(toDate, 'DD-MM-YYYY', true);
			if (moment(toDate, 'DD-MM-YYYY', true).isValid()) {
				if (toDateTime.isBefore(moment())) {
					if (
						fromDateTime &&
						fromDateTime !== '' &&
						fromDateTime.isAfter(moment(toDateTime))
					) {
						setToDateError('To Date cannot be before From Date');
					} else {
						setToDateError('');
					}
				} else {
					setToDateError('To date must be on or before current date');
				}
			}
		} else {
			if (fromDateError && fromDateError !== '') {
				validateFromDate();
			}
		}
	};

	return (
		<div className="notification-screen">
			<h1 className="roboto-b-24 m-0">{labels.pageTile}</h1>
			<hr />
			<br />

			<div className="row">
				<div className="col-3 d-flex">
					<Datetime
						className={
							fromDateError && fromDateError != '' ? 'Invalid w333px' : 'w333px'
						}
						locale="en-GB"
						dateFormat="DD-MM-YYYY"
						inputProps={{ placeholder: 'DD-MM-YYYY' }}
						onChange={(current) => {
							setFromDate(current);
						}}
						value={fromDate}
						closeOnSelect={true}
						timeFormat={false}
					/>
					<button
						className="clrBtn "
						onClick={() => {
							setFromDate('');
							setFromDateError('');
						}}
					>
						X
					</button>
					<label className={`labelStyle dateLabel`}>{labels.fromDateText}</label>
				</div>
				<div className="col-3 d-flex">
					<Datetime
						className={toDateError && toDateError != '' ? 'Invalid w333px' : 'w333px'}
						locale="en-GB"
						dateFormat="DD-MM-YYYY"
						inputProps={{ placeholder: 'DD-MM-YYYY' }}
						onChange={(current) => {
							setToDate(current);
						}}
						value={toDate}
						closeOnSelect={true}
						timeFormat={false}
					/>
					<button
						className="clrBtn "
						onClick={() => {
							setToDate('');
							setToDateError('');
						}}
					>
						X
					</button>
					<label className={`labelStyle dateLabel`}>{labels.toDateText}</label>
				</div>
				<div className="col-3 d-flex">
					<button
						className={
							(fromDateError && fromDateError !== '') ||
							(toDateError && toDateError !== '')
								? 'disabled btnNxtPrev ml-2 roboto-b-16'
								: 'btnNxtPrev ml-2 roboto-b-16'
						}
						onClick={() => {
							viewNotificationDetails(1, showUploadedOfferFiles);
						}}
					>
						{commonLabels.search}
					</button>
				</div>
			</div>

			<div className="row">
				<div className="col-3 d-flex">
					<p className="errorMessage">{fromDateError}</p>
				</div>
				<div className="col-4 d-flex">
					<p className="errorMessage">{toDateError}</p>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-10">
					{maxSearchPeriod > 0 && (
						<>
							{stringReplacer(
								labels.maxSearchPeriodText,
								'@maxSearchPeriod',
								maxSearchPeriod
							)}
						</>
					)}
				</div>
				<div className="col-md-2">
					<ToggleSwitch
						initialValue={showUploadedOfferFiles}
						toggleHandler={(isChecked) => {
							setShowUploadedOfferFiles(isChecked);
						}}
						className="float-right"
						onValue={labels.toggleOn}
						offValue={labels.toggleOff}
						customStyle={{ width: '164px' }}
					/>
				</div>
			</div>
			<hr />

			<div className="scrollableTable">
				<ReactTable
					columns={columns}
					data={notificationsList}
					keyField="uuId"
					paginationEnable={true}
					sizePerPageList={[
						{ text: 20, value: 20 },
						{ text: 50, value: 50 },
						{ text: 100, value: 100 }
					]}
				/>

				<hr />

				{groupInformationModalShow && (
					<GroupInformationModal
						show={groupInformationModalShow}
						onHide={informationModalClose}
						infoMsg={informationMsg}
						infoTitle={informationTitle}
						errorMsg={rowErrorMsg}
						splitErrorMsg
						addFilter
					/>
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationScreen);
