import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ModalPopup from '../../../Components/ModalPopup/ModalPopup';
import ReactTable, { textFilter } from '../../../Components/ReactTable/ReactTable';
import './updateOffersReview.scss';
import Input from '../../../Components/Input/Input';
import appLabels from '../../../utils/appLabels';

const { common: commonLabels, updateModalOffers: labels, table: tableLabels } = appLabels;

const TableInput = ({ defaultValue = '', onBlur, name, inputType = 'text', placeholder = '' }) => {
	const [value, setValue] = useState(defaultValue);
	const [isEditable, setIsEditable] = useState(false);

	if (isEditable) {
		return (
			<Input
				elementType={inputType}
				changed={(e, isValid) => {
					setValue(e.target.value);
				}}
				value={value}
				touched={true}
				invalid={value === ''}
				elementConfig={{
					type: inputType,
					name: name,
					placeholder: placeholder,
					onBlur: (e) => {
						setIsEditable(false);
						onBlur(e.target.value.trim());
					},
					autoFocus: true
				}}
				shouldValidate={{
					required: true
				}}
			/>
		);
	}

	return (
		<button
			className={`read-only-cell ${value === '' ? 'Invalid' : ''}`}
			onClick={() => {
				setIsEditable(true);
			}}
		>
			{value}
		</button>
	);
};

const formater = ({ value, row, index, list, dataField, blurHandler, inputType, placeholder }) => {
	/* value is not updated after 2nd edit */
	const { promotionId } = row;
	let val = list[promotionId][dataField];

	const onBlur = (value) => {
		blurHandler({ row, newValue: value, dataField });
	};

	return (
		<TableInput
			defaultValue={val}
			onBlur={onBlur}
			placeholder={placeholder}
			inputType={inputType}
			name={`table-input-${promotionId}-${dataField}`}
		/>
	);
};

const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
	let text = column.text;
	if (text) {
		return (
			<div>
				<div>
					{sortElement}
					{filterElement}
				</div>
				<div>{text}</div>
			</div>
		);
	}
};

const rowClasses = (row, rowIndex) => {
	const { title, description } = row;
	let classes = title === '' || description === '' ? 'invalid' : '';
	return classes;
};

const getTableData = ({ data, list, blurHandler, showInvalidatePromo }) => {
	let noDataInfo = commonLabels.nothingToShow;
	if (showInvalidatePromo && data.length === 0) {
		noDataInfo = labels.noInvalidTableMsg;
	}

	const columns = [
		{
			dataField: 'promotionId',
			text: tableLabels.promotionId,
			headerAlign: 'center',
			headerFormatter,
			filter: textFilter()
		},
		{
			dataField: 'title',
			text: tableLabels.title,
			headerAlign: 'center',
			formatter: (value, row, index) =>
				formater({
					value,
					row,
					index,
					list,
					dataField: 'title',
					blurHandler,
					inputType: 'text',
					placeholder: `${tableLabels.promotion} ${tableLabels.title}`
				})
		},
		{
			dataField: 'description',
			text: tableLabels.description,
			headerAlign: 'center',
			formatter: (value, row, index) =>
				formater({
					value,
					row,
					index,
					list,
					dataField: 'description',
					blurHandler,
					inputType: 'textarea',
					placeholder: `${tableLabels.promotion} ${tableLabels.description}`
				})
		}
	];

	return {
		columns: [...columns],
		data,
		keyField: 'promotionId',
		noDataInfo,
		paginationEnable: true,
		sizePerPageList: [
			{ text: 10, value: 10 },
			{ text: 20, value: 20 },
			{ text: 50, value: 50 }
		],
		customClasses: 'update-offers',
		rowClasses
	};
};

const getOfferData = (list = {}, showInvalidatePromo) => {
	let data = [];
	let allOffers = [];
	let invalidOffers = [];
	let totalInvalidOffer = 0;
	let isAllOffersValid = true;

	for (let prop in list) {
		const { promotionId, title, description } = list[prop];
		const isInvalidOffer = title === '' || description === '';
		const tempOffer = {
			promotionId,
			title,
			description
		};

		if (isInvalidOffer) {
			invalidOffers.push({ ...tempOffer });
		}

		allOffers.push({ ...tempOffer });
	}

	totalInvalidOffer = invalidOffers.length;
	isAllOffersValid = totalInvalidOffer === 0;

	if (showInvalidatePromo) {
		data = [...invalidOffers];
	}

	if (!showInvalidatePromo || isAllOffersValid) {
		data = [...allOffers];
	}

	return {
		data,
		totalOffer: allOffers.length,
		totalInvalidOffer,
		isAllOffersValid
	};
};

const UpdateOffersReview = ({ submitHandler, list = {}, closeHandler }) => {
	const [showModal, setShowModal] = useState(false);
	const [offers, setOffers] = useState({});
	const [showInvalidatePromo, setShowInvalidatePromo] = useState(false);
	const buttonDisabled = !list.some((offer, i) => {
		const { select, status } = offer;
		if (select && status !== 'Expired') {
			return true;
		}
	});

	const submitBtn = (isAllOffersValid) => (
		<button
			className="btn btn-primary"
			disabled={!isAllOffersValid}
			onClick={() => {
				submitHandler?.({ offers });
				setOffers({});
				setShowModal(false);
			}}
		>
			{labels.submitBtn}
		</button>
	);

	const modalCloseHandler = () => {
		closeHandler && closeHandler({ offers });
		setOffers({});
		setShowModal(false);
		setShowInvalidatePromo(false);
	};

	const blurHandler = ({ newValue, row, dataField }) => {
		const { promotionId } = row;
		const oldValue = row[dataField];
		if (oldValue !== newValue) {
			let _updateOffers = { ...offers };
			_updateOffers[promotionId][dataField] = newValue;
			setOffers({ ..._updateOffers });
		}
	};

	const {
		data: tableData,
		totalOffer,
		totalInvalidOffer,
		isAllOffersValid
	} = getOfferData(offers, showInvalidatePromo);

	return (
		<div className="update-offers-review">
			<button
				className="update-offer-btn btnNxtPrev roboto-b-16 advSearch"
				disabled={buttonDisabled}
				onClick={() => {
					const data = {};
					/* todo: try to work with array as right now it is not working */
					list.forEach((offer, ind) => {
						const {
							promotionId,
							titleE: title,
							descriptionE: description,
							select,
							status
						} = offer;

						if (select && status !== 'Expired') {
							data[promotionId] = {
								promotionId,
								title,
								description
							};
						}
					});
					setOffers(JSON.parse(JSON.stringify(data)));
					setShowModal(true);
				}}
			>
				{labels.updateOfferBtn}
			</button>

			<ModalPopup
				title={labels.title}
				customClasses="update-offers-review-popup"
				closeHandler={modalCloseHandler}
				additionalActionBtn={submitBtn(isAllOffersValid)}
				showModal={showModal}
				size="xl"
			>
				<div className="btn-container">
					<button
						className="btnNxtPrev-danger roboto-b-16 advSearch toggle-valid-btn"
						disabled={isAllOffersValid}
						onClick={() => {
							setShowInvalidatePromo(true);
						}}
					>
						{`${labels.showInvalidPromoBtn} (${totalInvalidOffer})`}
					</button>
					<button
						className="btnNxtPrev roboto-b-16 advSearch toggle-valid-btn"
						disabled={isAllOffersValid}
						onClick={() => {
							setShowInvalidatePromo(!showInvalidatePromo);
							setShowInvalidatePromo(false);
						}}
					>
						{`${labels.showAllPromoBtn} (${totalOffer})`}
					</button>
				</div>
				<hr />
				{showModal && (
					<ReactTable
						{...getTableData({
							data: tableData,
							list: offers,
							blurHandler,
							showInvalidatePromo
						})}
					/>
				)}
			</ModalPopup>
		</div>
	);
};

UpdateOffersReview.propTypes = {
	submitHandler: PropTypes.func,
	list: PropTypes.arrayOf(PropTypes.shape({})),
	closeHandler: PropTypes.func
};

export default UpdateOffersReview;
