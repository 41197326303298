import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import CustomSelect from '../customfields/CustomSelect';

function InclusionsType(props) {
	const { setFieldValue } = useFormikContext();
	const inclusionTypeOptions = [
		{
			value: '',
			name: 'Select one'
		},
		{
			value: 'PRODUCT_INCLUSION_VALUE',
			name: 'Product Inclusions'
		}
	];

	//Local const

	const [field] = useField(props);

	const getInclusionsTypeDropdown = (thresholdTypeCodeOptions) => {
		return thresholdTypeCodeOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	useEffect(() => {
		if (field.value?.InclusionsType == '') {
			setFieldValue('merchandizeHierarchyEligibility.inclusions', {
				includedItemIDList: {
					multibuyProduct: []
				}
			});

			props.onProductTableChange([]);
		}
	}, [field.value?.InclusionsType]);

	return (
		<div className="">
			<div className="d-flex justify-content-center">
				{/*  <FormController
          control="select"
          lable="Inclusion Type"
          name="InclusionsType"
          options={inclusionTypeOptions}
          required={false}
          className="customDropdown inlinePadding"
        /> */}
				<CustomSelect
					name="InclusionsType"
					required={false}
					label="Inclusion Type"
					options={getInclusionsTypeDropdown(inclusionTypeOptions)}
					className="customDropdown inlinePadding bg-position"
					//smallText={itemHelp.ThresholdTypeCodeMBG}
				/>
			</div>
		</div>
	);
}

export default InclusionsType;
