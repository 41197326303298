import React, { useEffect, useState } from 'react';
// import { connect } from "react-redux";
// import * as actionTypes from "../../../store/actions";
import { useField } from 'formik';
import ScaleProductTableBootstrapSPSSH from '../ScaleProductTableBootstrapSPSSH';

const keys = ['productID', 'trigger', 'getReward', 'minimumPrice', 'maximumPrice'];
const defaultValueForAddMG = {
	maximumPrice: '9999999.99',
	minimumPrice: '0.01',
	trigger: true,
	getReward: true
};

const AuditScaleTable = ({ ...props }) => {
	const [field] = useField(props);
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	// let productsAdded=[];
	const getAdded = (products, products1) => {
		var added = [];
		for (var i = 0; i < products.length; i++) {
			var id = products[i].uuid;
			var flag = false;
			for (var j = 0; j < products1.length; j++) {
				var id2 = products1[j].uuid;
				if (id === id2) {
					flag = true;
					// products1[j].contained = true;
				}
			}
			if (!flag) {
				added.push(products[i]);
			}
		}
		return added;
	};
	const benefitTypeDropdownValues = {
		type1: 'INSTANT',
		type2: 'CASHBACK',
		type3: 'LOYALTY_POINTS',
		type4: 'FREE_TEXT'
	};
	const priceModificationMethodCodeDropdownValues = {
		type1: 'PERCENTAGE_OFF',
		type2: 'APPORTIONED_PERCENTAGE_OFF',
		type3: 'AMOUNT_OFF',
		type4: 'APPORTIONED_AMOUNT_OFF',
		type5: 'MARKDOWN',
		type6: 'KIT_MARKDOWN'
	};
	const priceModificationMethodCodeDropdownValuesForLoyalty = {
		type1: 'LOYALTY_ADDITIONAL_POINTS',
		type2: 'LOYALTY_POINTS_MULTIPLIER',
		type3: 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS',
		type4: 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE',
		type5: 'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS',
		type6: 'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE'
	};

	const { benefitType, priceModificationMethodCode } = field.value?.scaledPriceEligibility || {};

	const benefitTypeValue =
		benefitType == benefitTypeDropdownValues.type1
			? priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type1 ||
			  priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type2
				? 'benefit.priceModificationPercent'
				: priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type3 ||
				  priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type4
				? 'benefit.priceModificationAmount'
				: 'benefit.newPriceAmount'
			: benefitType == benefitTypeDropdownValues.type2
			? priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type1 ||
			  priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type2
				? 'benefit.priceModificationPercent'
				: priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type3 ||
				  priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type4
				? 'benefit.priceModificationAmount'
				: undefined
			: benefitType == benefitTypeDropdownValues.type3
			? priceModificationMethodCode ==
					priceModificationMethodCodeDropdownValuesForLoyalty.type1 ||
			  priceModificationMethodCode ==
					priceModificationMethodCodeDropdownValuesForLoyalty.type3 ||
			  priceModificationMethodCode ==
					priceModificationMethodCodeDropdownValuesForLoyalty.type4
				? 'benefit.loyalty.loyaltyAdditionalPoint'
				: priceModificationMethodCode ==
						priceModificationMethodCodeDropdownValuesForLoyalty.type2 ||
				  priceModificationMethodCode ==
						priceModificationMethodCodeDropdownValuesForLoyalty.type5 ||
				  priceModificationMethodCode ==
						priceModificationMethodCodeDropdownValuesForLoyalty.type6
				? 'benefit.loyalty.loyaltyMultiplier'
				: undefined
			: benefitType == benefitTypeDropdownValues.type4
			? 'benefit.freeTextMessage'
			: undefined;

	const getReplaced = (newProds, oldProds, added, removed) => {
		let newProdsModified = [],
			oldProdsModifed = [],
			replaced = [];
		newProdsModified = getAdded(newProds, added); //Remove added from new json
		oldProdsModifed = getAdded(oldProds, removed); //Remove deleted from old json

		newProdsModified.map((item) => {
			oldProdsModifed.map((item1) => {
				if (item.uuid == item1.uuid) {
					const { id, ...newObj } = item1;
					if (JSON.stringify(item) != JSON.stringify(newObj)) {
						if (item.hasOwnProperty('thresholdQuantity')) {
							//  let temp=item.hasOwnProperty("thresholdQuantity") ? "thresholdQuantity" : "thresholdAmount";
							let temp = 'thresholdQuantity';
							if (item[temp] != item1[temp]) {
								// item[temp] = 'Old value:' + item1[temp] + ', New value:' + item[temp]
								item[temp] = JSON.stringify({
									oldValue: item1[temp],
									newValue: item[temp]
								});
							}
						}
						if (item.hasOwnProperty('thresholdAmount')) {
							//  let temp=item.hasOwnProperty("thresholdQuantity") ? "thresholdQuantity" : "thresholdAmount";
							let temp = 'thresholdAmount';
							if (item[temp] != item1[temp]) {
								//   item[temp] = 'Old value:' + item1[temp] + ', New value:' + item[temp]
								item[temp] = JSON.stringify({
									oldValue: item1[temp],
									newValue: item[temp]
								});
							}
						}
						if (eval('item.' + benefitTypeValue) != eval('item1.' + benefitTypeValue)) {
							//  item[benefitTypeValue] = 'Old value:' + eval("item1."+benefitTypeValue) + ', New value:' + eval("item."+benefitTypeValue)
							item[benefitTypeValue] = JSON.stringify({
								oldValue: eval('item1.' + benefitTypeValue),
								newValue: eval('item.' + benefitTypeValue)
							});
						}
						replaced.push(item);
					}
				}
			});
		});

		return replaced;
	};
	const [productsAdded, setProductsAdded] = useState([]);
	const [productsRemoved, setProductsRemoved] = useState([]);
	const [productsReplaced, setProductsReplaced] = useState([]);

	useEffect(() => {
		if (isAuditFlw) {
			let OldProds = props.oldJson;
			// let NewProds = props.newJson.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.multibuyProduct ? props.newJson.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.multibuyProduct : [];
			let NewProds = props.newJson ? props.newJson : [];

			let tempRemoved = getAdded(OldProds, NewProds);
			setProductsRemoved(tempRemoved);
			let tempAdded = getAdded(NewProds, OldProds);
			setProductsAdded(tempAdded);
			let tempReplaced = getReplaced(NewProds, OldProds, tempAdded, tempRemoved);
			setProductsReplaced(tempReplaced);
		}
		return () => {};
	}, []);

	let oldApplicableOn = field.value?.scaledPriceEligibility.paymentEligibility;
	let newApplicableOn = JSON.parse(sessionStorage.getItem('NewPolicy')).promotionPolicy
		.priceDerivationRuleEligibility.scaledPriceEligibility.paymentEligibility;

	return (
		<div
			className={
				productsAdded.length == 0 &&
				productsRemoved.length == 0 &&
				productsReplaced.length == 0
					? 'd-none'
					: 'box-blackout-period lightBlue container pl-2'
			}
		>
			{productsAdded.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">Added ({productsAdded.length})</label>
					</div>
					<div className="w-auto dataAdded">
						<ScaleProductTableBootstrapSPSSH
							data={productsAdded}
							keys={keys}
							defaultValueForAdd={defaultValueForAddMG}
							onProductTableChange={props.onProductTableChange}
							eligibility={'ScaleTransaction'}
							applicableOn={newApplicableOn}
						/>
					</div>
				</div>
			) : undefined}
			{productsRemoved.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">
							Removed ({productsRemoved.length})
						</label>
					</div>
					<div className="w-auto dataRemoved">
						<ScaleProductTableBootstrapSPSSH
							data={productsRemoved}
							keys={keys}
							defaultValueForAdd={defaultValueForAddMG}
							onProductTableChange={props.onProductTableChange}
							eligibility={'ScaleTransaction'}
							applicableOn={oldApplicableOn}
						/>
					</div>
				</div>
			) : undefined}
			{productsReplaced.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">
							Modified ({productsReplaced.length})
						</label>
					</div>
					<div className="w-auto">
						<ScaleProductTableBootstrapSPSSH
							data={productsReplaced}
							keys={keys}
							defaultValueForAdd={defaultValueForAddMG}
							onProductTableChange={props.onProductTableChange}
							eligibility={'ScaleTransaction'}
							applicableOn={field.value?.scaledPriceEligibility.paymentEligibility}
						/>
					</div>
				</div>
			) : undefined}
		</div>
	);
};

export default AuditScaleTable;
