import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import BasicDetailsFormik from './components/BasicDetailsFormik';
import { isObjectEmpty, trimDate, appendDate, getOfferFlowType } from '../../utils/helperFunction';
import './createOffer.scss';

import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import moment from 'moment';
import * as Yup from 'yup';
import chevronDown from '../../assets/images/chevron-down.png';
import { Link, Element } from 'react-scroll';
import { ConfirmationModal } from '../../MainContent/ConfirmationModal';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import appLabels from '../../utils/appLabels';
import appConfig from '../../utils/appConfig';

var programID;
var brandName;

let initialValues = {
	promotionPolicy: {
		expiration: 0,
		promotionID: '',
		promotionTypeID: 0,
		brandName: '',
		promotionType: '',
		operatorDisplayText: '',
		customerDisplayText: '',
		receiptPrintText: '',
		priceDerivationRuleEligibility: {},
		effectiveStartDate: '',
		effectiveEndDate: '',
		allowOverlap: false,
		giftVoucherTenderedPromotion: false,
		priority: 99,
		displayPriority: '', //Commenting this code to remove sku and mtmb feature from release one. OFFERS-2203
		allowCouponToBeAppliedOnPromotionItems: true,
		allowPromotionOnMarkdown: false,
		includeNonDiscountableItems: true,
		promotionApplicableEligibility: {
			employeeEligibility: {
				allowEmployeeSale: false
			},
			loyaltyCustomerEligibility: {
				allowLoyaltyCustomerSale: false
			},
			customerSegmentEligibility: {
				allowSegmentCustomerSale: false
			},
			loyaltyCardCustomerEligibility: {
				allowLoyaltyCardCustomerSale: false
			},
			tsssEligibility: {
				allowTsssSegment: false
			},
			brandEligibility: {
				category: '',
				brand: ''
			},
			channelEligibility: {
				channel: []
			},
			loggedInLoyaltyEligibility: {
				loyaltyOffer: false
			},
			promotionActivationEligibility: {
				multimapPromotion: false,
				customerActivationRequired: false
				// "activationValidity": {
				//   "date": ""
				// }
			},
			allowAnonymousCustomerSale: true,
			happyHoursApplicableEligibility: {
				eligibleDaysOfTheWeek: {
					includedDaysOfTheWeek: {
						includedDay: [
							// {
							//   "day":"01",
							//   "effectiveDuration": [{
							//     "effectiveStartTime": "00:00",
							//     "effectiveEndTime": "23:59"
							//   }]
							// }
						]
					},
					excludedDaysOfTheWeek: {
						excludedDay: [
							// {
							//   "day":"01",
							//   "effectiveDuration": [{
							//     "effectiveStartTime": "00:00",
							//     "effectiveEndTime": "23:59"
							//   }]
							// }
						]
					}
				},
				eligibleDatesOfTheMonth: {
					includedDatesOfTheMonth: {
						includedDate: [
							// {
							//   "date":"01",
							//   "effectiveDuration": [{
							//     "effectiveStartTime": "00:00",
							//     "effectiveEndTime": "23:59"
							//   }]
							// }
						]
					},
					excludedDatesOfTheMonth: {
						excludedDate: [
							// {
							//   "date":"01",
							//   "effectiveDuration": [{
							//     "effectiveStartTime": "00:00",
							//     "effectiveEndTime": "23:59"
							//   }]
							// }
						]
					}
				},
				blackOutPeriod: [
					{
						// "blackOutStartDate":"",
						// "blackOutEndDate":""
					}
				]
			},
			allowPrivilegeCustomerSale: false
		},
		redirectionUrl: '',
		offerImageUrl: [''],
		termsAndConditions: [''],
		supplementaryDetails: '',
		customField1: '',
		customField2: '',
		discountAppliesTo: '',
		sharability: {
			isPromotionSharable: false
		},
		programID: '',
		limitedUse: false
	}
};

const isSameOrBefore = (startTime, endTime) => {
	return moment(startTime, 'HH:mm').isSameOrBefore(moment(endTime, 'HH:mm'));
};

const isSameOrBeforeDateTime = (startDateTime, endDateTime) => {
	return !moment(startDateTime, 'YYYY/MM/DD H:mm').isSame(moment(endDateTime, 'YYYY/MM/DD H:mm'));
};
const isSameOrBeforeDate = (startTime, endTime) => {
	if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).type != 'view-offer-flow') {
		return moment(startTime, 'YYYY/MM/DD H:mm').isSameOrBefore(
			moment(endTime, 'YYYY/MM/DD H:mm')
		);
	}
	return true;
};

const validationSchema = ({ isTSSSEligible }) => {
	return Yup.object().shape({
		promotionPolicy: Yup.object().shape({
			programID: Yup.string().required('Please define a program id value'),
			brandName: Yup.string().required('Please define a brand name value'),
			//promotionID: Yup.string().required("Please define a promotion id value"),
			promotionType: Yup.string().required('Please define a promotion type value'),
			/* effectiveStartDate: Yup.date()
      .required("Please define a start date value")
      .min(
        moment().format("YYYY/MM/DD"),
        "start date should not be less than the current date"
      ), */
			effectiveEndDate: Yup.date()
				.required('Please define a end date value')
				.when('effectiveStartDate', (effectiveStartDate, schema) => {
					const startDate = moment(effectiveStartDate).format('YYYY/MM/DD H:mm');
					return (
						effectiveStartDate &&
						schema.min(startDate, appLabels.errorLabel.endDateNotLessStart)
					);
				})
				.test('End_time_test', appLabels.errorLabel.endStartDateNotSame, function (value) {
					const { effectiveStartDate } = this.parent;
					return isSameOrBeforeDateTime(effectiveStartDate, value);
				})
				.test(
					'End_time_test2',
					appLabels.errorLabel.endDateNotLessCurrentDate,
					function (value) {
						const currDate = moment().format('YYYY/MM/DD H:mm');
						return isSameOrBeforeDate(currDate, value);
					}
				),

			operatorDisplayText: Yup.string().required('Please define a offer title value'),
			customerDisplayText: Yup.string().required('Please define a offer description value'),

			promotionApplicableEligibility: Yup.object().shape({
				/* channelEligibility: Yup.object().shape({
                channel: Yup.array()
                .min(1, 'Please enter Channel data.')
            }), */
				happyHoursApplicableEligibility: Yup.object().shape({
					blackOutPeriod: Yup.array().of(
						Yup.object().shape({
							blackOutStartDate: Yup.date(),
							blackOutEndDate: Yup.date().when(
								'blackOutStartDate',
								(blackOutStartDate, schema) =>
									blackOutStartDate &&
									schema.min(
										blackOutStartDate,
										'Blackout end date and time should be greater than the Blackout start date and time'
									)
							)
						})
					),

					eligibleDaysOfTheWeek: Yup.object().shape({
						includedDaysOfTheWeek: Yup.object().shape({
							includedDay: Yup.array()
								.of(
									Yup.object().shape({
										day: Yup.string(),
										effectiveDuration: Yup.array().of(
											Yup.object().shape({
												effectiveStartTime: Yup.string(),
												effectiveEndTime: Yup.string().test(
													'End_time_test',
													'End time should not less than the start time',
													function (value) {
														const { effectiveStartTime } = this.parent;
														return isSameOrBefore(
															effectiveStartTime,
															value
														);
													}
												)
											})
										)
									})
								)
								.unique('Weekday already in use', (a) => a.day)
						}),
						excludedDaysOfTheWeek: Yup.object().shape({
							excludedDay: Yup.array()
								.of(
									Yup.object().shape({
										day: Yup.string(),
										effectiveDuration: Yup.array().of(
											Yup.object().shape({
												effectiveStartTime: Yup.string(),
												effectiveEndTime: Yup.string().test(
													'End_time_test',
													'End time should not less than the start time',
													function (value) {
														const { effectiveStartTime } = this.parent;
														return isSameOrBefore(
															effectiveStartTime,
															value
														);
													}
												)
											})
										)
									})
								)
								.unique('Weekday already in use', (a) => a.day)
						})
					}),

					eligibleDatesOfTheMonth: Yup.object().shape({
						includedDatesOfTheMonth: Yup.object().shape({
							includedDate: Yup.array()
								.of(
									Yup.object().shape({
										date: Yup.string(),
										effectiveDuration: Yup.array().of(
											Yup.object().shape({
												effectiveStartTime: Yup.string(),
												effectiveEndTime: Yup.string().test(
													'End_time_test',
													'End time should not less than the start time',
													function (value) {
														const { effectiveStartTime } = this.parent;
														return isSameOrBefore(
															effectiveStartTime,
															value
														);
													}
												)
											})
										)
									})
								)
								.unique('Day of month already in use', (a) => a.date)
						}),
						excludedDatesOfTheMonth: Yup.object().shape({
							excludedDate: Yup.array()
								.of(
									Yup.object().shape({
										date: Yup.string(),
										effectiveDuration: Yup.array().of(
											Yup.object().shape({
												effectiveStartTime: Yup.string(),
												effectiveEndTime: Yup.string().test(
													'End_time_test',
													'End time should not less than the start time',
													function (value) {
														const { effectiveStartTime } = this.parent;
														return isSameOrBefore(
															effectiveStartTime,
															value
														);
													}
												)
											})
										)
									})
								)
								.unique('Day of month already in use', (a) => a.date)
						})
					})
				}),
				promotionActivationEligibility: Yup.object().shape({
					activationValidity: Yup.object().when('customerActivationRequired', {
						is: true,
						then: Yup.object().shape({
							promotionCollectionValidityData: Yup.string().required(
								'Please define a collection validity value'
							),
							date: Yup.date().when('promotionCollectionValidityData', {
								is: 'Date',
								then: Yup.date().required('Please define a date value')
							}),
							months: Yup.string().when('promotionCollectionValidityData', {
								is: 'Months',
								then: Yup.string()
									.required('Please define a months value')
									.matches(
										/^(?=.*[1-9])\d{0,6}$/,
										'Months value should be between 1 to 999999'
									)
							}),
							days: Yup.string().when('promotionCollectionValidityData', {
								is: 'Days',
								then: Yup.string()
									.required('Please define a days value')
									.matches(
										/^(?=.*[1-9])\d{0,6}$/,
										'Days value should be between 1 to 999999'
									)
							}),
							hours: Yup.string().when('promotionCollectionValidityData', {
								is: 'Hours',
								then: Yup.string().required('Please define a hours value')
							})
						})
					})
				}),
				brandEligibility: Yup.object().shape({
					brand: Yup.string().test(
						'brandEligibilityBrand',
						'Please define Brand',
						function (value) {
							/* setting this for global feature: */
							// if (initialValues.promotionPolicy.brandName === buBrand.TSSS.name)
							if (isTSSSEligible) {
								return !!value;
							}
							return true;
						}
					)
					// category: Yup.string().when('BUBrand', {
					// 	is: buBrand.TSSS.name,
					// 	then: Yup.string().required('Please define Brand category')
					// })
				})
			}),
			sharability: Yup.object().shape({
				totalShares: Yup.string().when('promotionShareType', {
					is: 'LIMITED',
					then: Yup.string()
						.required('Please define a total share value')
						.matches(
							/^(?=.*[1-9])\d{0,9}$/,
							'Total share value must be between 1 and nine 9s (both inclusive)'
						)
				})
			}),
			promotionRedemptionLimit: Yup.string().matches(
				/^(?=.*[1-9])\d{0,9}$/,
				'Redemption limit across transaction value must be between 1 and nine 9s (both inclusive)'
			),
			redeemptionLimitPerUser: Yup.string().matches(
				/^(?=.*[1-9])\d{0,9}$/,
				'Redemption Limit across Transaction value must be between 1 and nine 9s (both inclusive)'
			),
			maximumTrigger: Yup.string().matches(
				/^(?=.*[1-9])\d{0,6}$/,
				'Maximum triggers per transaction value must be between 1 and six 9s (both inclusive)'
			),
			rewardLimit: Yup.string().matches(
				/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
				'Reward limit amount must be between 0.01 to 999999.99'
			),
			redeemptionLimitPerCard: Yup.string().matches(
				/^(?=.*[1-9])\d{0,6}$/,
				'Redemption Limit Per Card value must be between 1 and six 9s (both inclusive)'
			),
			maxBenefitPerCardAcrossTransaction: Yup.string().matches(
				/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
				'Maximum Benefit Per Card Across Transaction must be between 0.01 to 999999.99'
			),
			maxBenefitPerCustomerAcrossTransaction: Yup.string().matches(
				/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
				'Maximum Benefit Per Customer Across Transaction must be between 0.01 to 999999.99'
			),
			maxRedeemAmountAcrossTransaction: Yup.string().matches(
				/^(?=.*[1-9])\d{0,9}(?:\.\d{0,2})?$/,
				'Maximum Benefit for Offer Across Transaction must be between 0.01 to 999999999.99'
			),
			priority: Yup.string()
				.required('Please define a priority value')
				.matches(
					/^(?=.*[1-9])\d{0,2}$/,
					'Priority value must be between 1 and 99 (both inclusive)'
				),
			displayPriority: Yup.string()
				// .matches(
				//   /^[0-9\b]+$/,
				//   "Display Priority value must be integer"
				// )
				.test(
					'promotionPolicy.displayPriority',
					'DisplayPriority error',
					async function (value) {
						if (value) {
							let regEx = new RegExp(/^[0-9\b]+$/);
							const isInteger = regEx.test(value);
							if (!isInteger) {
								return this.createError({
									message: 'Display Priority value must be integer'
								});
							}
							const valid = await checkDisplayPriority({
								promotionPolicy: this.parent
							});
							if (!valid.status) {
								return this.createError({ message: valid.msg });
							}
							return true;
						}
						// WHEN THE VALUE IS EMPTY RETURN `true` by default
						return true;
					}
				)
		})
	});
};

const checkDisplayPriority = (values, props = null) => {
	// var programID = values.promotionPolicy.programID;
	// var promotionId = values.promotionPolicy.promotionID;
	// var displayPriority = values.promotionPolicy.displayPriority;
	const { programID, promotionID, displayPriority } = values.promotionPolicy;

	const fnIdJson = JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'));
	const fnId = fnIdJson.businessUnitGroupFunctionID;

	const dataToSend = {
		checkDisplayPriorityRequest: {
			programId: programID,
			promotionId: promotionID,
			displayPriority: displayPriority,
			brandId: fnId
		}
	};

	props?.showLoadingButton(true);

	const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;

	return RestSvc.postData(urlTypes.offers_maintenance.checkDisplayPriority, dataToSend, svPromUrl)
		.then((response) => {
			props?.showLoadingButton(false);
			var result = response.data.checkDisplayPriorityResponse;
			if (result.businessError != undefined) {
				return { status: false, msg: result.businessError.errorMessage };
				// return result.businessError;
			} else {
				return { status: true };
			}
			//  Commenting this code to remove sku and mtmb feature from release one. OFFERS-2203
		})
		.catch((error) => {
			props?.showLoadingButton(false);
			console.error(error);
		});
};

const OfferForm = (props) => {
	// state and props
	const location = useLocation();
	let history = useHistory();
	let myparam = {};
	if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
		myparam = props.auditDetails;
	} else {
		myparam = location.state?.params;
	}
	const [initialData, setInitialData] = useState({});
	// let myparam2 = location.state?.MTMBProdTableData;
	// let myparam3 = location.state?.ScaleBenifitTableData;
	// let myparam4 = location.state?.SellingHierarchyTableData;
	/* false means initial value does't change in basic form by promotion type or brand eligibility select 
	null means value changed from eligibility type or promotion type select input*/
	const [myparam2, setMyparam2] = useState(location.state?.MTMBProdTableData || false);
	const [myparam3, setMyparam3] = useState(location.state?.ScaleBenifitTableData || false);
	const [myparam4, setMyparam4] = useState(location.state?.SellingHierarchyTableData || false);
	const [confirmationModalShow, setCnfrmatnModalShow] = useState(false);
	const [isTSSSEligible, setTSSSIsEligible] =
		useState(false); /* this check use for both tss and geoFenced user for brandEligibility */

	useEffect(() => {
		//updateFlag ? getInitialData() : setInitialData(initialValues);
		const _isTSSSEligible =
			/* setting this for global feature: */
			// props.loggedInBrand.businessUnitGroupFunctionID === appConstants.buBrand.TSSS.BUID;
			appConfig.createOfferBrandEligibility.includes(
				props.loggedInBrand.businessUnitGroupFunctionID
			);
		setTSSSIsEligible(_isTSSSEligible);
		const taggedBrandToLoggedInUser = JSON.parse(sessionStorage.taggedBrandToLoggedInUser);

		if (myparam) {
			let parsedMyparam = JSON.parse(myparam);
			if (
				parsedMyparam?.promotionPolicy?.promotionApplicableEligibility
					?.promotionActivationEligibility?.activationValidity != undefined
			) {
				parsedMyparam.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility =
					appendDate(
						parsedMyparam.promotionPolicy.promotionApplicableEligibility
							.promotionActivationEligibility,
						'activationValidity',
						'promotionCollectionValidityData'
					);
			}
			setInitialData(parsedMyparam);
		} else {
			programID = taggedBrandToLoggedInUser.programId;
			brandName = taggedBrandToLoggedInUser.brandName;
			initialValues.promotionPolicy.programID = programID;
			initialValues.promotionPolicy.brandName = brandName;

			initialValues.promotionPolicy.effectiveStartDate =
				moment().format('YYYY-MM-DD') + ' 00:00';
			initialValues.promotionPolicy.effectiveStartDate = moment(
				initialValues.promotionPolicy.effectiveStartDate,
				'YYYY-MM-DD HH:mm'
			).format('YYYY-MM-DD HH:mm:ss');

			setInitialData(initialValues);
		}
		if (_isTSSSEligible) {
			sessionStorage.removeItem('brandEligibilityBrand');
		}
	}, []);

	//Local functions
	const resetTables = () => {
		// myparam2 = undefined;
		// myparam3 = undefined;
		// myparam4 = undefined;
		/* null means initial value changed in basic form by promotion type or brand eligibility select*/
		setMyparam2(null);
		setMyparam3(null);
		setMyparam4(null);
	};

	const checkPromoId = (values) => {
		if (
			!values.promotionPolicy.promotionID ||
			getOfferFlowType() == 'Modify Offer' ||
			getOfferFlowType() == 'View Offer'
		) {
			return true;
		} else {
			// var programID = values.promotionPolicy.programID;
			// var promotionId = values.promotionPolicy.promotionID;
			const { programID, promotionID } = values.promotionPolicy;

			const dataToSend = {
				searchPromotionRequest: {
					programId: programID,
					promotionId: promotionID
				}
			};

			props.showLoadingButton(true);

			const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;

			return RestSvc.postData(
				urlTypes.offers_maintenance.searchPromotion,
				dataToSend,
				svPromUrl
			)
				.then((response) => {
					props.showLoadingButton(false);
					let result = response.data.searchPromotionResponse;
					if (result.promotionPolicyDetails != undefined) {
						return false;
					} else {
						return true;
					}
				})
				.catch((error) => {
					this.props.showLoadingButton(false);
					console.error(error);
				});
		}
	};

	const gotoEligibility = async (values) => {
		const isPromoIdNotExists = await checkPromoId(values);
		if (isPromoIdNotExists) {
			let eligibilityLink;
			if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
				eligibilityLink =
					'/MainPage/auditMaintenance/EligibilitySettings' +
					(values.promotionPolicy.promotionType == 'MULTIBUYGROUP'
						? ''
						: values.promotionPolicy.promotionType);
			} else {
				eligibilityLink =
					'/MainPage/offerconfigurator/EligibilitySettings' +
					(values.promotionPolicy.promotionType == 'MULTIBUYGROUP'
						? ''
						: values.promotionPolicy.promotionType);
			}

			let newValues = values;

			if (
				newValues.promotionPolicy.promotionApplicableEligibility
					.promotionActivationEligibility.activationValidity != undefined
			) {
				newValues.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility =
					trimDate(
						newValues.promotionPolicy.promotionApplicableEligibility
							.promotionActivationEligibility,
						'activationValidity',
						'promotionCollectionValidityData'
					);
			}
			isTSSSEligible &&
				sessionStorage.setItem(
					'brandEligibilityBrand',
					newValues.promotionPolicy.promotionApplicableEligibility.brandEligibility.brand
				);
			await history.push(eligibilityLink, {
				params: JSON.stringify(newValues),
				MTMBProdTableDatafromBasicDetails: myparam2,
				ScaleProdTableDatafromBasicDetails: myparam3,
				SellingHierarchyTableDatafromBasicDetails: myparam4
			});
		}
	};

	const showCancelConfirmation = () => {
		setCnfrmatnModalShow(true);
	};

	const confirmationModalClose = () => {
		setCnfrmatnModalShow(false);
	};

	const proceedToOperation = () => {
		props.setDisableItem(false);
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			history.push('/MainPage/auditMaintenance');
		} else history.push('/MainPage/offerconfigurator');
	};

	const linkText = {
		basicdetails: [
			'Promotion Type',
			'Description',
			'Combination With Other Promotions',
			'Discount Application',
			'Redemption Settings',
			'Shares',
			'Others'
		],
		applicableEligibility: [
			'Channel Eligibility',
			'Privilege Customer Eligibility',
			'Anonymous Customer Eligibility',
			'Employee Eligibility',
			'Loyalty Customer Eligibility',
			'Customer Segments Eligibility',
			'Loyalty Card Customer Eligibility',
			'Happy Hours',
			'Collection Required'
		]
	};

	isTSSSEligible && linkText.applicableEligibility.splice(7, 0, 'Brand Eligibility');

	const onMouseLeaveHandler = () => {
		props.setMousePlace('');
	};

	const goToAuditMgt = () => {
		history.push('/MainPage/auditMaintenance', {
			viewHistoryOfOffer: true,
			promotionID: initialData?.promotionPolicy.promotionID
		});
	};

	return (
		<div
			className={
				getOfferFlowType() == 'View Offer'
					? 'main-container create-offer-container viewOfferContainer'
					: 'main-container create-offer-container'
			}
		>
			<div className="header-flex">
				<div className="back-div" onClick={showCancelConfirmation}>
					<div className="pr-2">
						<img src={chevronDown} alt="" />
					</div>
					<div>Back</div>
				</div>

				<div className="header-text">{getOfferFlowType()}</div>
			</div>
			<div className="flex-mainContent">
				<div className="left-navigation br-right">
					<div className="text-header">Basic Details</div>
					{linkText.basicdetails.map((val, ind) => (
						<div key={ind} className="text-content">
							<Link
								activeClass=""
								className={
									'scrollTo' + val.replace(/\s/g, '') == props.curntMousePlace
										? 'activePromotionSection'
										: ''
								}
								to={'scrollTo' + val.replace(/\s/g, '')}
								spy={true}
								smooth={true}
								duration={250}
								containerId="containerElement"
							>
								{val}
							</Link>
						</div>
					))}
					<div className="text-header">Applicable Eligibility</div>
					{linkText.applicableEligibility.map((val, ind) => (
						<div key={ind} className="text-content">
							<Link
								activeClass=""
								className={
									'scrollTo' + val.replace(/\s/g, '') == props.curntMousePlace
										? 'activePromotionSection'
										: ''
								}
								to={'scrollTo' + val.replace(/\s/g, '')}
								spy={true}
								smooth={true}
								duration={250}
								containerId="containerElement"
							>
								{val}
							</Link>
						</div>
					))}
					{getOfferFlowType() == 'View Offer' || getOfferFlowType() == 'Modify Offer' ? (
						<div
							className={`text-header channelLink ${
								!(props.assignedResources.indexOf('OC010') != -1) ? 'disabled' : ''
							}`}
							role="button"
							onClick={goToAuditMgt}
						>
							History
						</div>
					) : null}
				</div>
				<Element
					id="containerElement"
					className="right-content"
					onMouseLeave={onMouseLeaveHandler}
				>
					<>
						{!isObjectEmpty(initialData) && (
							<Formik
								initialValues={initialData}
								onSubmit={async (values) => {
									await new Promise((resolve) => setTimeout(resolve, 500));
									await gotoEligibility(values);
								}}
								validationSchema={validationSchema({ isTSSSEligible })}
								validateOnChange={true}
							>
								{(props) => {
									const { errors, isSubmitting, handleSubmit } = props;
									return (
										<form onSubmit={handleSubmit}>
											<BasicDetailsFormik
												resetAllTables={resetTables}
												isTSSSEligible={isTSSSEligible}
											/>

											<div className="footer">
												{!isObjectEmpty(errors) && props.submitCount > 0 ? (
													<div className="text-danger text-center pr-5">
														The form is not yet complete. Check through
														the form for error messages
													</div>
												) : null}
												<button
													type="button"
													className="cancel mr-3"
													onClick={showCancelConfirmation}
												>
													Cancel
												</button>
												<button
													type="submit"
													className="addEligibility"
													disabled={isSubmitting}
												>
													{getOfferFlowType() == 'View Offer'
														? 'View Eligibility'
														: 'Add Eligibility'}
												</button>
											</div>
										</form>
									);
								}}
							</Formik>
						)}
					</>
				</Element>
			</div>
			{confirmationModalShow ? (
				<ConfirmationModal
					show={confirmationModalShow}
					onHide={confirmationModalClose}
					onProceed={proceedToOperation}
					confirmmsg={'Are you sure you want to navigate away?'}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferForm);
