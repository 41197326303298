import React, { useEffect } from 'react';
import { useField } from 'formik';
import ConditionalRedering from './ConditionalRedering';
import { itemHelp } from '../item-help-text';
import CustomSelect from '../customfields/CustomSelect';
import $ from 'jquery';

function MultiByProduct(props) {
	const thresholdTypeCodeOptions = [
		{
			value: '',
			name: 'Select one...'
		},
		{
			value: 'INTERVAL_QTY_THRESHOLD',
			name: 'Interval Quantity Threshold'
		},
		{
			value: 'QTY_THRESHOLD',
			name: 'Quantity Threshold'
		},
		{
			value: 'INTERVAL_AMOUNT_THRESHOLD',
			name: 'Interval Amount Threshold'
		},
		{
			value: 'AMOUNT_THRESHOLD',
			name: 'Amount Threshold'
		},
		{
			value: 'RANGE_QTY_THRESHOLD',
			name: 'Range Quantity Threshold'
		},
		{
			value: 'RANGE_AMOUNT_THRESHOLD',
			name: 'Range Amount Threshold'
		},
		{
			value: 'LIMIT_QTY_THRESHOLD',
			name: 'Limit Quantity Threshold'
		}
	];

	//Local const

	/*  const multiByGroupSchema = Yup.object({
    thresholdTypeCode: Yup.string().required("Required"),
  }); */
	const [field] = useField(props);

	const getThresholdDropdown = (dropdownOptions) => {
		return dropdownOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};
	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));

	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
					.thresholdTypeCode != field.value.multibuyEligbility.thresholdTypeCode
			) {
				let tempName = 'multibuyEligbility.thresholdTypeCode';

				$('[name="' + tempName + '"]')
					.closest('.container')
					.parent()
					.nextAll()
					.addClass('d-none');
			}
		}
	}, []);

	return (
		<div className="container-fluid">
			{/* <div class="accordion" id="accordionExample">
        <div class="card" style={{ border: "none" }}>
          <CollapseHeaderBar
            text={localConst.eligibilitySettings}
            headingId={"headingOne"}
            dataTarget={"#collapseOne"}
          />
          <CollapseBody id="collapseOne" headingId={"headingOne"}> */}
			<div className="d-flex justify-content-center">
				<CustomSelect
					name="multibuyEligbility.thresholdTypeCode"
					required={true}
					label="Threshold Type Code"
					options={getThresholdDropdown(thresholdTypeCodeOptions)}
					className="customDropdown inlinePadding bg-position"
					smallText={itemHelp.ThresholdTypeCodeMBP}
				/>
			</div>
			{field.value.multibuyEligbility.thresholdTypeCode ? (
				<ConditionalRedering value={field.value.multibuyEligbility.thresholdTypeCode} />
			) : null}
			{/* </CollapseBody>
        </div>
      </div> */}
			{/* ProductSetting */}
			{/*  <div class="accordion" id="accordionExample">
            <div class="card" style={{ border: "none" }}>
              <CollapseHeaderBar
                text={localConst.ProductSetting}
                headingId={"headingThree"}
                dataTarget={"#collapseThree"}
              />
              <CollapseBody id="collapseThree" headingId={"headingThree"}>
                <Field name="multibuyProduct" component={ProductSetting} />
              </CollapseBody>
            </div>
          </div>
          <div class="accordion" id="accordionExample">
            <div class="card" style={{ border: "none" }}>
              <CollapseHeaderBar
                text={localConst.benefitSettings}
                headingId={"headingTwo"}
                dataTarget={"#collapseTwo"}
              />
              <CollapseBody id="collapseTwo" headingId={"headingTwo"}>
                <Field name="benefit" component={BenefitSettings} />
              </CollapseBody>
            </div>
          </div> */}
		</div>
	);
}

export default MultiByProduct;
