import React, { useEffect, useState } from 'react';
import { useField, FieldArray, useFormikContext, ErrorMessage } from 'formik';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import CustomInputText from './customfields/CustomInputText';
import ProductTableBoostrap from './ProductTableBootstrap';
import MerchTableBootstrapSCALE from './MerchTableBootstrapSCALE';
import AuditProductTable from './Audit/AuditProductTable';
import AuditSHTable from './Audit/AuditSHTable';
import CustomCheckbox1 from './customfields/CustomCheckbox1';

const keys = ['productID', 'minimumPrice', 'maximumPrice'];
const keysMerch = ['merchandizeGroupId', 'minimumPrice', 'maximumPrice'];
const defaultValueForAddMTMB = {
	maximumPrice: '9999999.99',
	minimumPrice: '0.01'
	// trigger: 'Y',
	// getReward: 'Y',
};
// let MTMBProductTableData = {
//   "packageMultibuyEligibility": [
//     {
//       "group": [
//       ]
//     }
//   ]
// }

let errMsg = '';

const MtmbGroup = ({ ...props }) => {
	let { customName, groupName } = props;
	let { index } = props;
	let { MTMBProductTableData } = props;
	const [field, meta] = useField(props);
	const fieldMTMB = field.value.mtmbEligibility;

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const fieldMTMBPackage =
		customName == 'packageMultibuyEligibilityAdded'
			? fieldMTMB.packageMultibuyEligibilityAdded
			: customName == 'packageMultibuyEligibilityRemoved'
			? fieldMTMB.packageMultibuyEligibilityRemoved
			: fieldMTMB.packageMultibuyEligibility;
	const fieldMTMBGroup =
		groupName == 'groupAdded'
			? fieldMTMBPackage[index].groupAdded
			: groupName == 'groupRemoved'
			? fieldMTMBPackage[index].groupRemoved
			: fieldMTMBPackage[index].group;
	const [showTable, setshowTable] = useState(true);

	// if (Object.keys(props.dataMTMBtable).length != 0) {
	//   MTMBProductTableData = props.dataMTMBtable;
	// }
	// else {
	//   if (!MTMBProductTableData) {
	//     MTMBProductTableData = {
	//       "packageMultibuyEligibility": [
	//         {
	//           "group": [
	//             {
	//               "multibuyProduct": [
	//               ],
	//               "merchandizeHierarchyGroup": [
	//               ],
	//             }
	//           ]
	//         }
	//       ]
	//     }
	//   }
	// }
	// data=MTMBProductTableData.packageMultibuyEligibility[0].group[0].multibuyProduct;

	const { setFieldValue, values } = useFormikContext();

	// const [productID, setProductID] = useState([{ name: '' }])
	// const [tempProductID, setTempProductID] = useState('');
	// const updateFieldChanged = myindex =>e => {
	//   let newArr = productID.slice();
	//   newArr[myindex].name = e.target.value;
	//   setProductID(newArr);
	// }
	const getOccurrence = (array, value) => {
		var count = 0;
		array.forEach((v) => v === value && count++);
		return count;
	};

	useEffect(() => {
		fieldMTMBPackage.map((item, index) => {
			// const {thresholdTypeCode} = fieldMTMBPackage[index]
			if (item.thresholdTypeCode == '') {
				item.group = [
					{
						multibuyProduct: [],
						merchandizeHierarchyGroup: [],
						trigger: true,
						getReward: true
						/*   memberGroupThresholdAmount:  fieldMTMBPackage[index].thresholdTypeCode == 
              "HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD" || fieldMTMBPackage[index].thresholdTypeCode == "HIERARCHY_PACKAGE_AMOUNT_THRESHOLD"?0.01 : undefined,
              memberGroupThresholdQuantity:  fieldMTMBPackage[index].thresholdTypeCode == 
              "HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD" || fieldMTMBPackage[index].thresholdTypeCode == "HIERARCHY_PACKAGE_QTY_THRESHOLD"? 1 : undefined,
              */
					}
				];
			}
			if (
				item.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
				item.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
			) {
				item.group[0].memberGroupThresholdQuantity = undefined;
				/* if(!item.group[0].memberGroupThresholdAmount
          && item.group[0].trigger==true){
        item.group[0].memberGroupThresholdAmount = "0.01";
        }
        if(!item.group[0].memberGroupRewardQuantity
          &&  item.group[0].getReward==true){
          item.group[0].memberGroupRewardQuantity = "1"
        } */
			}
			if (
				item.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
				item.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD'
			) {
				item.group[0].memberGroupThresholdAmount = undefined;
				/*  if(!item.group[0].memberGroupThresholdQuantity
           &&  item.group[0].trigger==true){
         item.group[0].memberGroupThresholdQuantity = "1"
         }
         if(!item.group[0].memberGroupRewardQuantity
           && item.group[0].getReward==true){
         item.group[0].memberGroupRewardQuantity = "1"
       } */
			}

			let elVal = [];
			item.group.map((g) => {
				if (g.trigger) {
					elVal.push('Trigger');
				}
				if (g.getReward) {
					elVal.push('Reward');
				}
			});
			const oldEligibilityType = values.mtmbEligibility[customName][index].eligibilityType;
			let newEligibilityType = '';
			if (getOccurrence(elVal, 'Trigger') == 0 && getOccurrence(elVal, 'Reward') != 0) {
				// 	setFieldValue(
				// 		`mtmbEligibility.${customName}.${index}.eligibilityType`,
				// 		'REWARD'
				// 	);
				newEligibilityType = 'REWARD';
			} else if (
				getOccurrence(elVal, 'Reward') == 0 &&
				getOccurrence(elVal, 'Trigger') != 0
			) {
				// 	setFieldValue(
				// 		`mtmbEligibility.${customName}.${index}.eligibilityType`,
				// 		'TRIGGER'
				// 	);
				newEligibilityType = 'TRIGGER';
			} else if (
				(getOccurrence(elVal, 'Reward') != 0 && getOccurrence(elVal, 'Trigger') != 0) ||
				elVal.length == 0
			) {
				// 	setFieldValue(
				// 		`mtmbEligibility.${customName}.${index}.eligibilityType`,
				// 		'TRIGGER_AND_REWARD'
				// 	);
				newEligibilityType = 'TRIGGER_AND_REWARD';
			}
			/* hotfix/mtmb-offer-browser-hang */
			if (oldEligibilityType !== newEligibilityType) {
				setFieldValue(
					`mtmbEligibility.${customName}.${index}.eligibilityType`,
					newEligibilityType
				);
			}
		});
	}, [fieldMTMBPackage]);
	// useEffect(() => {
	//   return () => {
	//     MTMBProductTableData = undefined;
	//   }
	// }, [])
	// useEffect(() => {
	//   fieldMTMBPackage.map((item) => {

	//     item.group.map((g)=>{
	//       if(g.trigger){
	//         g.groupThresholdQuantity=1;
	//       }
	//       else{
	//         g.groupThresholdQuantity=undefined;
	//       }
	//       if(g.getReward){
	//         g.groupRewardQuantity=1;
	//       }
	//       else{
	//         g.groupRewardQuantity=undefined;
	//       }
	//     })
	//   });
	// }, [fieldMTMB]);

	const onProductTableChange = (val, i, j) => {
		//debugger
		// MTMBProductTableData.packageMultibuyEligibility[i].group[j] = val
		//debugger
		if (MTMBProductTableData.packageMultibuyEligibility[i].group[j])
			MTMBProductTableData.packageMultibuyEligibility[i].group[j].multibuyProduct = val;

		props.onMTMBProductTableChange(MTMBProductTableData);
	};
	const onMerchTableChange = (val, i, j) => {
		//debugger
		if (
			MTMBProductTableData.packageMultibuyEligibility[i] &&
			MTMBProductTableData.packageMultibuyEligibility[i].group[j]
		)
			MTMBProductTableData.packageMultibuyEligibility[i].group[j].merchandizeHierarchyGroup =
				val;
		//
		//
		props.onMTMBProductTableChange(MTMBProductTableData);
	};

	const triggerValueChange = (index, i) => {
		if (
			fieldMTMBPackage[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			fieldMTMBPackage[index].thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD'
		) {
			if (!fieldMTMBGroup[i].trigger == true) {
				fieldMTMBGroup[i].memberGroupThresholdQuantity = '1';
				fieldMTMBGroup[i].memberGroupThresholdAmount = '';
			} else {
				fieldMTMBGroup[i].memberGroupThresholdQuantity = '';
				fieldMTMBGroup[i].memberGroupThresholdAmount = '';
			}
		} else if (
			fieldMTMBPackage[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldMTMBPackage[index].thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			if (!fieldMTMBGroup[i].trigger == true) {
				fieldMTMBGroup[i].memberGroupThresholdAmount = '0.01';
				fieldMTMBGroup[i].memberGroupThresholdQuantity = '';
			} else {
				fieldMTMBGroup[i].memberGroupThresholdAmount = '';
				fieldMTMBGroup[i].memberGroupThresholdQuantity = '';
			}
		}
	};

	const getRewardValueChange = (index, i) => {
		if (
			fieldMTMBPackage[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			fieldMTMBPackage[index].thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
			fieldMTMBPackage[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldMTMBPackage[index].thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			//fieldMTMBGroup[i].memberGroupRewardQuantity = ''
			if (!fieldMTMBGroup[i].getReward == true) {
				fieldMTMBGroup[i].memberGroupRewardQuantity = '1';
			} else {
				fieldMTMBGroup[i].memberGroupRewardQuantity = '';
			}
		}
	};

	const customValidateMemberGroupThresholdQuantity = (value, index, i) => {
		let error;
		if (
			(fieldMTMBPackage[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				fieldMTMBPackage[index].thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD') &&
			fieldMTMBGroup[i].trigger
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}$/)) {
					error = 'Group Threshold Quantity value should be between 1 to 999999';
				}
			} else {
				error = 'Please define a Group Threshold Quantity value';
			}
		}
		return error;
	};
	//amount
	const customValidateMemberGroupThresholdAmount = (value, index, i) => {
		let error;
		if (
			(fieldMTMBPackage[index].thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
				fieldMTMBPackage[index].thresholdTypeCode ==
					'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD') &&
			fieldMTMBGroup[i].trigger
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/)) {
					error = 'Group Threshold Amount value should be between 0.01 to 9999999.99';
				}
			} else {
				error = 'Please define a Group Threshold Amount value';
			}
		}
		return error;
	};

	//reward
	const customValidateMemberGroupRewardQuantity = (value, index, i) => {
		let error;
		if (
			(fieldMTMBPackage[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				fieldMTMBPackage[index].thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
				fieldMTMBPackage[index].thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
				fieldMTMBPackage[index].thresholdTypeCode ==
					'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD') &&
			fieldMTMBGroup[i].getReward
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}$/)) {
					error = 'Group Reward Quantity value should be between 1 to 999999';
				}
			} else {
				error = 'Please define a Group Reward Quantity value';
			}
		}
		return error;
	};
	const customValidateTrigger = (value, index, i) => {
		let error;
		if (value || fieldMTMBGroup[i].getReward) {
			error = undefined;
		} else {
			error = 'Please select Trigger or Reward value as both cannot be false';
		}
		return error;
	};
	// const customValidateReward = (value, index, i) => {
	//   let error;
	//     if (value || fieldMTMBGroup[i].trigger) {
	//       error = undefined
	//     } else {
	//       error = "Please select Trigger or Reward value as both cannot be false"
	//     }
	//   return error;
	// }

	return (
		<>
			<FieldArray name={`mtmbEligibility.${customName}.${index}.${groupName}`}>
				{({ remove }) => (
					<div
						className="accordion mtmb-acrdn mr-2 p-3"
						id={`accordionGroupExample${index}`}
					>
						{fieldMTMBGroup.map((item, i) => (
							<div key={`div-${index}`}>
								{/* {i == 0 ? (
                                              <div className="d-flex justify-content-end m-2">
                                                <button
                                                  type="button"
                                                  className="addPlusBtn bg-color"
                                                  onClick={() => {
                                                    //debugger
                                                    MTMBProductTableData.packageMultibuyEligibility[index].group.push({
                                                      "multibuyProduct": [
                                                      ],
                                                      "merchandizeHierarchyGroup": [
                                                      ],
                                                    })

                                                    fieldMTMBPackage[
                                                      index
                                                    ].group.push({
                                                      multibuyProduct: [],
                                                      trigger: true,
                                                      getReward: true,
                                                      memberGroupThresholdAmount:  fieldMTMBPackage[index].thresholdTypeCode == 
                                                      "HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD" || fieldMTMBPackage[index].thresholdTypeCode == "HIERARCHY_PACKAGE_AMOUNT_THRESHOLD"?"0.01" : undefined,
                                                      memberGroupThresholdQuantity:  fieldMTMBPackage[index].thresholdTypeCode == 
                                                      "HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD" || fieldMTMBPackage[index].thresholdTypeCode == "HIERARCHY_PACKAGE_QTY_THRESHOLD"? "1 ": undefined,
                                                      memberGroupRewardQuantity: "1"
                                                    });
                                                    // setProductID('');
                                                    // this.state.
                                                    // setProductID({
                                                    //   productID: this.state.productID.concat([{ name: "" }])
                                                    // });
                                                    // setProductID(productID => [...productID, { name: '' }]);

                                                    // let newArr1 = productID.slice();
                                                    // newArr1.push({name:""});
                                                    // setProductID(newArr1); //
                                                    // let newArr2 = productID.slice();
                                                    // newArr2.push({name:""});
                                                    // setTempProductID(newArr2); //

                                                    setTempProductID("");
                                                    setFieldValue("", "");

                                                    // setTempProductID('');
                                                  }}
                                                >
                                                  
                                              </button>
                                              </div>
                                            ) : (
                                                <div className="d-flex justify-content-end m-2">
                                                  <button
                                                    type="button"
                                                    className="addDeleteBtn bg-color"
                                                    onClick={() => {
                                                      MTMBProductTableData.packageMultibuyEligibility[index].group[i].multibuyProduct = []
                                                      remove(i);
                                                      setFieldValue("", "")

                                                      //setFieldValue(MTMBProductTableData.packageMultibuyEligibility[index].group[i].multibuyProduct, [])
                                                    }}
                                                  >
                                                    
                                              </button>
                                                </div>
                                              )} */}

								<div className="d-flex">
									<div
										className={
											groupName.indexOf('Added') !== -1
												? 'card mtmb-card m-2 borderForAdded'
												: groupName.indexOf('Removed') !== -1
												? 'card mtmb-card m-2 borderForRemoved'
												: 'card mtmb-card m-2'
										}
										style={{ border: 'none', width: 'calc(100% - 50px)' }}
									>
										{/* <div className="d-flex mt-2">
                                                  <div style={{"width": "calc(100% - 35px)"}}><b className="pl-1">Group</b></div>
                                                  <div>
                                                    {fieldMTMBGroup.length > 1 ? (
                                                      <button
                                                          type="button"
                                                          className="addDeleteBtn bg-color"
                                                          onClick={() => {
                                                            remove(i);
                                                            MTMBProductTableData.packageMultibuyEligibility[index].group.splice(i,1);
                                                            props.onMTMBProductTableChange(MTMBProductTableData)
                                                            setshowTable(false);
                                                            setTimeout(() => {
                                                              setshowTable(true);
                                                            }, 50);
                                                            setFieldValue("", "")
                                                          }}
                                                        >
                                                          
                                                    </button>
                                                    )
                                                    : null}
                                                  </div>
                                                </div> */}
										<div
											className={
												i + 1 == fieldMTMBGroup.length
													? 'card-head mtmb-crd-head group'
													: 'card-head mtmb-crd-head group collapsed'
											}
											data-toggle="collapse"
											data-target={`#mtmbPackageMultibuy${index}collapseGroup${i}`}
										>
											<h2 className="mb-0">
												<div
													className={
														props.submitCnt > 0 &&
														meta.error?.mtmbEligibility
															?.packageMultibuyEligibility?.[index]
															?.group?.[i]
															? 'item-before text-danger pointer-events-auto'
															: 'item-before pointer-events-auto'
													}
												>
													{`Group ${i + 1}`}
												</div>
											</h2>
										</div>
										<div
											id={`mtmbPackageMultibuy${index}collapseGroup${i}`}
											className={
												i + 1 == fieldMTMBGroup.length
													? 'collapse show mtmb-collapse'
													: 'collapse mtmb-collapse'
											}
											data-parent={`#accordionGroupExample${index}`}
										>
											<div className="card-body">
												<div className="row mt-3 mtmbTriggerReward">
													<span className="col-2 text-right">
														{/* <label>Trigger</label>
                              <span className="requiredRed pr-2">
                                *
                                                          </span>
                              <Field
                                type="checkbox"
                                name={`mtmbEligibility.${customName}.${index}.${groupName}.${i}.trigger`}
                                className="p-3"
                                onClick={() => triggerValueChange(index, i)}
                              /> */}
														<CustomCheckbox1
															required={true}
															name={`mtmbEligibility.${customName}.${index}.${groupName}.${i}.trigger`}
															className="p-3"
															label="Trigger"
															onClick={() =>
																triggerValueChange(index, i)
															}
															validate={(value) =>
																customValidateTrigger(
																	value,
																	index,
																	i
																)
															}
														/>
													</span>
													<span className="col-2">
														{/* <label>Get Reward</label>
                              <span className="requiredRed pr-2">
                                *
                                                          </span>
                              <Field
                                type="checkbox"
                                name={`mtmbEligibility.${customName}.${index}.${groupName}.${i}.getReward`}
                                className="p-3"
                                onClick={() => getRewardValueChange(index, i)}
                              /> */}
														<CustomCheckbox1
															required={true}
															name={`mtmbEligibility.${customName}.${index}.${groupName}.${i}.getReward`}
															className="p-3"
															label="Get Reward"
															onClick={() =>
																getRewardValueChange(index, i)
															}
															// validate={(value) => customValidateReward(value, index, i)}
														/>
													</span>
												</div>
												<div className={'text-center'}>
													<ErrorMessage
														name={`mtmbEligibility.${customName}.${index}.${groupName}.${i}.trigger`}
														component="div"
														className="field-error"
													/>
												</div>
												{/* <MTMBProductTable data={data} onProductTableChange={(val) => onProductTableChange(val, index, i)} /> */}
												{fieldMTMBPackage[index].thresholdTypeCode ==
													'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
												fieldMTMBPackage[index].thresholdTypeCode ==
													'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
												fieldMTMBPackage[index].thresholdTypeCode ==
													'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
												fieldMTMBPackage[index].thresholdTypeCode ==
													'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ? (
													<div className="purchaseContainer">
														<div className="purchaseRow align-self-start d-block group-threshold-reward">
															{fieldMTMBPackage[index]
																.thresholdTypeCode ==
																'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
															fieldMTMBPackage[index]
																.thresholdTypeCode ==
																'HIERARCHY_PACKAGE_QTY_THRESHOLD' ? (
																<CustomInputText
																	name={`mtmbEligibility.${customName}.${index}.${groupName}.${i}.memberGroupThresholdQuantity`}
																	required={false}
																	label={
																		'Group Threshold Quantity'
																	}
																	validate={(value) =>
																		customValidateMemberGroupThresholdQuantity(
																			value,
																			index,
																			i
																		)
																	}
																	smallText=""
																	disabled={
																		!fieldMTMBGroup[i].trigger
																	}
																/>
															) : (
																<CustomInputText
																	name={`mtmbEligibility.${customName}.${index}.${groupName}.${i}.memberGroupThresholdAmount`}
																	required={false}
																	label={'Group Threshold Amount'}
																	validate={(value) =>
																		customValidateMemberGroupThresholdAmount(
																			value,
																			index,
																			i
																		)
																	}
																	smallText=""
																	disabled={
																		!fieldMTMBGroup[i].trigger
																	}
																/>
															)}
														</div>
														<div className="purchaseRow align-self-start d-block group-threshold-reward">
															<CustomInputText
																name={`mtmbEligibility.${customName}.${index}.${groupName}.${i}.memberGroupRewardQuantity`}
																required={false}
																label={'Group Reward Quantity'}
																validate={(value) =>
																	customValidateMemberGroupRewardQuantity(
																		value,
																		index,
																		i
																	)
																}
																smallText=""
																disabled={
																	!fieldMTMBGroup[i].getReward
																}
															/>
														</div>
													</div>
												) : null}
												{/* <Field name={`packageMultibuyEligibility.${index}.${groupName}.${i}.multibuyProduct`} component={ProductSetting} /> */}
												<div>
													{/* <div className="row justify-content-center">
                                                            <label className="p-2">
                                                              Product ID
                                                          </label>
                                                            <Field
                                                              className="form-control col-md-3"
                                                              name={`mtmbEligibility.${customName}.${index}.${groupName}.${i}.productID`}
                                                              type="text"
                                                            />
                                                            <button
                                                              type="button"
                                                              className="btn btn-outline-secondary ml-3 h-25"

                                                              onClick={() => {
                                                                let errFlag = false;
                                                                errMsg = "";
                                                                fieldMTMBPackage[
                                                                  index
                                                                ].group.map((g) => {
                                                                  g.multibuyProduct.map(
                                                                    (item) => {
                                                                      if (
                                                                        item.productID ==
                                                                        fieldMTMBPackage[
                                                                          index
                                                                        ].group[i].productID
                                                                      ) {
                                                                        errFlag = true;
                                                                      }
                                                                    }
                                                                  );
                                                                })
                                                                if (errFlag) {
                                                                  errMsg =
                                                                    "Product ID:" +
                                                                    fieldMTMBPackage[
                                                                      index
                                                                    ].group[i].productID +
                                                                    " already added";
                                                                } else {
                                                                  fieldMTMBPackage[
                                                                    index
                                                                  ].group[
                                                                    i
                                                                  ].multibuyProduct.push({
                                                                    productID:
                                                                      fieldMTMBPackage[
                                                                        index
                                                                      ].group[i]
                                                                        .productID,
                                                                    maximumPrice: 999999.99,
                                                                    minimumPrice: 0.01,
                                                                  });
                                                                  fieldMTMBPackage[
                                                                    index
                                                                  ].group[i].productID =
                                                                    "";
                                                                  setFieldValue("", "")
                                                                }
                                                              }}
                                                            >
                                                              +
                                                          </button>
                                                          </div> */}
													<div className="text-center text-danger">
														{errMsg ==
														'Product ID:' +
															fieldMTMBGroup[i].productID +
															' already added'
															? errMsg
															: null}
													</div>

													<div className="margin10">
														{/* <MTMBProductTable data={data} onProductTableChange={(val) => onProductTableChange(val, index, i) } group={MTMBProductTableData.packageMultibuyEligibility[index].group} /> */}
														{isAuditFlw ? (
															groupName == 'group' &&
															customName ==
																'packageMultibuyEligibility' ? (
																fieldMTMBPackage[index].mtmbType ==
																'PRODUCT' ? (
																	<AuditProductTable
																		oldJson={
																			fieldMTMBGroup[i]
																				.multibuyProduct
																				.length != 0
																				? fieldMTMBGroup[i]
																						.multibuyProduct
																				: MTMBProductTableData
																						.packageMultibuyEligibility[
																						index
																				  ].group[i]
																						?.multibuyProduct
																		}
																		newJson={
																			JSON.parse(
																				sessionStorage.getItem(
																					'NewPolicy'
																				)
																			).promotionPolicy
																				.priceDerivationRuleEligibility
																				.mtmbEligibility
																				.packageMultibuyEligibility[
																				index
																			].group[i]
																				.multibuyProduct
																		}
																		MTMBtable={true}
																	/>
																) : (
																	<AuditSHTable
																		// oldJson={MTMBProductTableData.packageMultibuyEligibility[index].group[i]?.merchandizeHierarchyGroup}
																		oldJson={
																			fieldMTMBGroup[i]
																				.merchandizeHierarchyGroup
																				.length != 0
																				? fieldMTMBGroup[i]
																						.merchandizeHierarchyGroup
																				: MTMBProductTableData
																						.packageMultibuyEligibility[
																						index
																				  ].group[i]
																						?.merchandizeHierarchyGroup
																		}
																		newJson={
																			JSON.parse(
																				sessionStorage.getItem(
																					'NewPolicy'
																				)
																			).promotionPolicy
																				.priceDerivationRuleEligibility
																				.mtmbEligibility
																				.packageMultibuyEligibility[
																				index
																			].group[i]
																				.merchandizeHierarchyGroup
																		}
																		keysMTMBMerch={keysMerch}
																		MTMBtable={true}
																	/>
																)
															) : (
																//To show Prods or SH under Group/Pkg Added/Removed
																<>
																	{fieldMTMBPackage[index]
																		.mtmbType == 'PRODUCT' ? (
																		showTable ? (
																			<ProductTableBoostrap
																				data={
																					fieldMTMBPackage[
																						index
																					][groupName][i]
																						.multibuyProduct
																				}
																				keys={keys}
																				defaultValueForAdd={
																					defaultValueForAddMTMB
																				}
																				onProductTableChange={(
																					val
																				) =>
																					onProductTableChange(
																						val,
																						index,
																						i
																					)
																				}
																				eligibility={'MTMB'}
																				group={
																					MTMBProductTableData
																						.packageMultibuyEligibility[
																						index
																					]?.group
																				}
																				i={index}
																				j={i}
																				MTMBProductTableData={
																					MTMBProductTableData
																				}
																				brandEligibility={
																					JSON.parse(
																						props.fullValue
																					)
																						.promotionPolicy
																						.promotionApplicableEligibility
																						.brandEligibility
																				}
																			/>
																		) : null
																	) : showTable ? (
																		<MerchTableBootstrapSCALE
																			data={
																				fieldMTMBPackage[
																					index
																				][groupName][i]
																					.merchandizeHierarchyGroup
																			}
																			keys={keysMerch}
																			defaultValueForAdd={
																				defaultValueForAddMTMB
																			}
																			onMerchTableChange={(
																				val
																			) =>
																				onMerchTableChange(
																					val,
																					index,
																					i
																				)
																			}
																			eligibility={
																				'packageMultibuyEligibility'
																			}
																			group={
																				MTMBProductTableData
																					.packageMultibuyEligibility[
																					index
																				]?.group
																			}
																			isMerchTableRepetative={
																				true
																			}
																			currentGroupIndex={i}
																			currentPkgIndex={index}
																			ScaleMerchTableData={
																				MTMBProductTableData
																			}
																		/>
																	) : null}
																</>
															)
														) : (
															<>
																{fieldMTMBPackage[index].mtmbType ==
																'PRODUCT' ? (
																	showTable ? (
																		<ProductTableBoostrap
																			data={
																				MTMBProductTableData
																					.packageMultibuyEligibility[
																					index
																				].group[i]
																					?.multibuyProduct ||
																				[]
																			}
																			keys={keys}
																			defaultValueForAdd={
																				defaultValueForAddMTMB
																			}
																			onProductTableChange={(
																				val
																			) =>
																				onProductTableChange(
																					val,
																					index,
																					i
																				)
																			}
																			eligibility={'MTMB'}
																			group={
																				MTMBProductTableData
																					.packageMultibuyEligibility[
																					index
																				]?.group
																			}
																			i={index}
																			j={i}
																			MTMBProductTableData={
																				MTMBProductTableData
																			}
																			brandEligibility={
																				JSON.parse(
																					props.fullValue
																				).promotionPolicy
																					.promotionApplicableEligibility
																					.brandEligibility
																			}
																		/>
																	) : null
																) : showTable ? (
																	<MerchTableBootstrapSCALE
																		data={
																			MTMBProductTableData
																				.packageMultibuyEligibility[
																				index
																			].group[i]
																				?.merchandizeHierarchyGroup
																		}
																		keys={keysMerch}
																		defaultValueForAdd={
																			defaultValueForAddMTMB
																		}
																		onMerchTableChange={(val) =>
																			onMerchTableChange(
																				val,
																				index,
																				i
																			)
																		}
																		eligibility={
																			'packageMultibuyEligibility'
																		}
																		group={
																			MTMBProductTableData
																				.packageMultibuyEligibility[
																				index
																			]?.group
																		}
																		isMerchTableRepetative={
																			true
																		}
																		currentGroupIndex={i}
																		currentPkgIndex={index}
																		ScaleMerchTableData={
																			MTMBProductTableData
																		}
																		brandEligibility={
																			JSON.parse(
																				props.fullValue
																			).promotionPolicy
																				.promotionApplicableEligibility
																				.brandEligibility
																		}
																	/>
																) : null}
															</>
														)}

														{/* <CustomFieldArrayInputMTMB
                                                              productID={
                                                                fieldMTMBPackage[
                                                                  index
                                                                ].group[i].productID
                                                              }
                                                              value={
                                                                fieldMTMBPackage[
                                                                  index
                                                                ].group[i].multibuyProduct
                                                              }
                                                              index={index}
                                                              i={i}
                                                            /> */}
													</div>
												</div>
											</div>
										</div>
									</div>
									{fieldMTMBGroup.length > 1 ? (
										<button
											type="button"
											className="addDeleteBtn mt-3"
											style={{ 'background-color': 'unset' }}
											onClick={() => {
												remove(i);
												MTMBProductTableData.packageMultibuyEligibility[
													index
												].group.splice(i, 1);
												props.onMTMBProductTableChange(
													MTMBProductTableData
												);
												setshowTable(false);
												setTimeout(() => {
													setshowTable(true);
												}, 50);
												setFieldValue('', '');
											}}
										></button>
									) : null}
								</div>
								{i + 1 == fieldMTMBGroup.length ? (
									<div className="d-flex justify-content-end mt-2 mr-5">
										<button
											style={{ paddingRight: 0 }}
											type="button"
											className="createGroupBtn"
											onClick={() => {
												//debugger
												MTMBProductTableData.packageMultibuyEligibility[
													index
												].group.push({
													multibuyProduct: [],
													merchandizeHierarchyGroup: []
												});

												fieldMTMBGroup.push({
													multibuyProduct: [],
													trigger: true,
													getReward: true,
													memberGroupThresholdAmount:
														fieldMTMBPackage[index].thresholdTypeCode ==
															'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
														fieldMTMBPackage[index].thresholdTypeCode ==
															'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
															? '0.01'
															: undefined,
													memberGroupThresholdQuantity:
														fieldMTMBPackage[index].thresholdTypeCode ==
															'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
														fieldMTMBPackage[index].thresholdTypeCode ==
															'HIERARCHY_PACKAGE_QTY_THRESHOLD'
															? '1 '
															: undefined,
													memberGroupRewardQuantity: '1'
												});
												// setTempProductID('');
												setFieldValue('', '');
											}}
										>
											<span className="pr-2">+</span>Add Group
										</button>
									</div>
								) : null}
							</div>
						))}
					</div>
				)}
			</FieldArray>
		</>
	);
};

export default MtmbGroup;
