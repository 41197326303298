import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import appLabels from '../../utils/appLabels';

import './modalPopup.scss';

const {
	common: { close }
} = appLabels;

const ModalPopup = ({
	title = 'Information',
	launchBtnVariant = 'info',
	launchBtnText = 'Launch modal',
	closeHandler = null,
	showHandler = null,
	children,
	size = 'sm',
	showModal,
	centered = true,
	customClasses = '',
	additionalActionBtn,
	scrollableEnable = false,
	closeBtnText = close,
	hideCloseBtn = false,
	fullscreen = false,
	modalSide = '',
	modalVariant = ''
}) => {
	const [show, setShow] = useState(false);

	const luncherBtn = () => {
		if (showModal !== undefined) {
			return false;
		}
		return (
			<button className={`btn ${launchBtnVariant}`} onClick={handleShow}>
				{launchBtnText}
			</button>
		);
	};

	const handleShow = () => {
		setShow(true);
		showHandler && showHandler();
	};

	const handleClose = () => {
		setShow(false);
		closeHandler && closeHandler();
	};

	const isModalShow = showModal ?? show;
	const _modalSide = (modalSide && `modal-direction ${modalSide}-side`) || '';

	const modalVariantClass = `bg-${modalVariant}`;

	return (
		<>
			{luncherBtn()}
			<Modal
				fullscreen={fullscreen || undefined}
				dialogClassName={`modal-popup ${customClasses} ${_modalSide}`}
				show={isModalShow}
				onHide={handleClose}
				size={size}
				aria-labelledby={title}
				centered={centered}
				scrollable={scrollableEnable}
				// bsPrefix='right in modal'
			>
				<Modal.Header closeButton className={`${modalVariantClass}`}>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{children}</Modal.Body>
				{hideCloseBtn && additionalActionBtn == undefined ? null : (
					<Modal.Footer>
						{additionalActionBtn}
						{!hideCloseBtn && (
							<button className="btn btn-primary" onClick={handleClose}>
								{closeBtnText}
							</button>
						)}
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
};

ModalPopup.propTypes = {
	title: PropTypes.string,
	launchBtnVariant: PropTypes.oneOf([
		'primary',
		'secondary',
		'success',
		'warning',
		'danger',
		'info',
		'light',
		'dark',
		'link'
	]),
	launchBtnText: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
	size: PropTypes.oneOf(['sm', 'lg', 'xl']),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	closeHandler: PropTypes.func,
	showHandler: PropTypes.func,
	showModal: PropTypes.bool,
	centered: PropTypes.bool,
	customClasses: PropTypes.string,
	additionalActionBtn: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	scrollableEnable: PropTypes.bool,
	closeBtnText: PropTypes.string,
	hideCloseBtn: PropTypes.bool,
	fullscreen: PropTypes.bool,
	modalSide: PropTypes.oneOf(['right', 'left']),
	modalVariant: PropTypes.oneOf([
		'primary',
		'secondary',
		'success',
		'warning',
		'danger',
		'info',
		'light',
		'dark',
		'link'
	])
};

export default ModalPopup;
