import React from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import _ from 'lodash';

import './downloadExlBtn.scss';
import Toaster from '../Toaster/Toaster';
import appLabels from '../../utils/appLabels';

const { downloadExlBtn: labels } = appLabels;

const DownloadExlBtn = ({ dataToExport = [], saveAsFileName = labels.saveAsFileName }) => {
	const clickHandler = _.debounce(() => {
		let data = typeof dataToExport === 'function' ? dataToExport?.() : dataToExport;
		if (data?.length === 0) {
			Toaster({ message: labels.dataNotFound });
			return;
		}

		const worksheet = XLSX.utils.json_to_sheet(data);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

		// Buffer to store the generated Excel file
		const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		const blob = new Blob([excelBuffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		});

		saveAs(blob, `${saveAsFileName}.xlsx`);
	}, 300);

	return (
		<button
			type="button"
			className="download-exl-btn"
			title={labels.buttonTitle}
			onClick={clickHandler}
		>
			{labels.buttonLabel}
			<span className="icon" />
		</button>
	);
};

export default DownloadExlBtn;

DownloadExlBtn.propTypes = {
	dataToExport: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.shape({}))]),
	saveAsFileName: PropTypes.string
};
