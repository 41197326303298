import React, { useEffect } from 'react';
import { useField } from 'formik';

import CustomInputText from '../customfields/CustomInputText';
import CustomSelect from '../customfields/CustomSelect';

function ConditionalRedering(props) {
	const [field] = useField(props);

	const localConst = {
		eligibilitySettings: 'Eligibility Settings',
		thresholdCode: 'Threshold Type Code',
		triggerCombinationCode: 'Trigger Combination Code',
		subTriggerCombinationCode:
			'Determines the combination code to be used between the trigger items for a promotion',
		thresholdQuantity: 'Threshold Quantity',
		thresholdAmount: 'Threshold Amount',
		rewardCombinationCode: 'Reward Combination Code',
		subRewardCombinationCode:
			'Determines the combination code to be used between the reward item for a promotion',
		rewardQuantity: 'Reward Quantity',
		benefitSettings: 'Benefit Settings',
		ProductSetting: 'Product Settings',
		quantityLimit: 'Quantity Limit',
		maximumThresholdAmount: 'Maximum Threshold Amount',
		maximumThresholdQuantity: 'Maximum Threshold Quantity'
	};

	const rewardCombinationDropdown = {
		promotionTypeOr: [
			{
				value: 'OR',
				name: 'OR'
			}
		],
		promotionTypeOrAndEqual: [
			{
				value: 'OR',
				name: 'OR'
			},
			{
				value: 'AND',
				name: 'AND'
			},
			{
				value: 'EQUAL',
				name: 'EQUAL'
			}
		],
		promotionTypeOrAnd: [
			{
				value: 'OR',
				name: 'OR'
			},
			{
				value: 'AND',
				name: 'AND'
			}
		],
		operator: [
			{
				value: '',
				name: 'Select one'
			},
			{
				value: '<',
				name: '<'
			},
			{
				value: '>',
				name: '>'
			},
			{
				value: '<=',
				name: '<='
			},
			{
				value: '>=',
				name: '>='
			},
			{
				value: '=',
				name: '='
			},
			{
				value: 'BETWEEN',
				name: 'BETWEEN'
			}
		]
	};
	const multibuyEligbility = field.value.multibuyEligbility;
	useEffect(() => {
		if (!multibuyEligbility.triggerCombinationCode) {
			multibuyEligbility.triggerCombinationCode = 'OR';
		}
		if (!multibuyEligbility.rewardCombinationCode) {
			multibuyEligbility.rewardCombinationCode = 'OR';
		}
		if (!multibuyEligbility.rewardQuantity) {
			multibuyEligbility.rewardQuantity = 1;
		}
		if (
			multibuyEligbility.thresholdTypeCode == 'INTERVAL_QTY_THRESHOLD' ||
			multibuyEligbility.thresholdTypeCode == 'QTY_THRESHOLD' ||
			multibuyEligbility.thresholdTypeCode == 'RANGE_QTY_THRESHOLD'
		) {
			if (!multibuyEligbility.thresholdQuantity) {
				multibuyEligbility.thresholdQuantity = 1;
			}
			multibuyEligbility.thresholdAmount = undefined;
			multibuyEligbility.quantityLimit = undefined;
			if (multibuyEligbility.thresholdTypeCode != 'RANGE_QTY_THRESHOLD') {
				multibuyEligbility.maximumThresholdQuantity = undefined;
			}
			multibuyEligbility.maximumThresholdAmount = undefined;
		}
		if (multibuyEligbility.thresholdTypeCode == 'LIMIT_QTY_THRESHOLD') {
			if (!multibuyEligbility.thresholdQuantity) {
				multibuyEligbility.thresholdQuantity = 1;
			}
			if (!multibuyEligbility.quantityLimit) {
				multibuyEligbility.quantityLimit = 1;
			}
			multibuyEligbility.thresholdAmount = undefined;
			// multibuyEligbility.quantityLimit = undefined;
			multibuyEligbility.maximumThresholdQuantity = undefined;
			multibuyEligbility.maximumThresholdAmount = undefined;
		}
		if (
			multibuyEligbility.thresholdTypeCode == 'INTERVAL_AMOUNT_THRESHOLD' ||
			multibuyEligbility.thresholdTypeCode == 'AMOUNT_THRESHOLD' ||
			multibuyEligbility.thresholdTypeCode == 'RANGE_AMOUNT_THRESHOLD'
		) {
			if (!multibuyEligbility.thresholdAmount) {
				multibuyEligbility.thresholdAmount = 0.01;
			}
			multibuyEligbility.thresholdQuantity = undefined;
			multibuyEligbility.quantityLimit = undefined;
			multibuyEligbility.maximumThresholdQuantity = undefined;
			if (multibuyEligbility.thresholdTypeCode !== 'RANGE_AMOUNT_THRESHOLD')
				multibuyEligbility.maximumThresholdAmount = undefined;
		}
		/* if (multibuyEligbility.thresholdTypeCode == "LIMIT_QTY_THRESHOLD") {
      if (!multibuyEligbility.quantityLimit) {
        multibuyEligbility.quantityLimit = 1;
      }
    } */
		if (multibuyEligbility.thresholdTypeCode == 'RANGE_QTY_THRESHOLD') {
			if (!multibuyEligbility.maximumThresholdQuantity) {
				multibuyEligbility.maximumThresholdQuantity = 999999;
			}
		}
		if (multibuyEligbility.thresholdTypeCode == 'RANGE_AMOUNT_THRESHOLD') {
			if (!multibuyEligbility.maximumThresholdAmount) {
				multibuyEligbility.maximumThresholdAmount = 999999;
			}
		}
	}, [multibuyEligbility.thresholdTypeCode]);
	useEffect(() => {
		if (
			multibuyEligbility.triggerOperator == '<' ||
			multibuyEligbility.triggerOperator == '>' ||
			multibuyEligbility.triggerOperator == '<=' ||
			multibuyEligbility.triggerOperator == '>=' ||
			multibuyEligbility.triggerOperator == '='
		) {
			multibuyEligbility.triggerOperatorValueTo = undefined;
			multibuyEligbility.triggerOperatorValueFrom = undefined;
		} else if (multibuyEligbility.triggerOperator == 'BETWEEN') {
			multibuyEligbility.triggerOperatorValue = undefined;
		} else {
			multibuyEligbility.triggerOperatorValueTo = undefined;
			multibuyEligbility.triggerOperatorValueFrom = undefined;
			multibuyEligbility.triggerOperatorValue = undefined;
		}
	}, [multibuyEligbility.triggerOperator]);

	useEffect(() => {
		if (
			multibuyEligbility.rewardOperator == '<' ||
			multibuyEligbility.rewardOperator == '>' ||
			multibuyEligbility.rewardOperator == '<=' ||
			multibuyEligbility.rewardOperator == '>=' ||
			multibuyEligbility.rewardOperator == '='
		) {
			multibuyEligbility.rewardOperatorValueTo = undefined;
			multibuyEligbility.rewardOperatorValueFrom = undefined;
		} else if (multibuyEligbility.rewardOperator == 'BETWEEN') {
			multibuyEligbility.rewardOperatorValue = undefined;
		} else {
			multibuyEligbility.rewardOperatorValueTo = undefined;
			multibuyEligbility.rewardOperatorValueFrom = undefined;
			multibuyEligbility.rewardOperatorValue = undefined;
		}
	}, [multibuyEligbility.rewardOperator]);
	const getThresholdDropdown = (dropdownOptions) => {
		return dropdownOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};
	//html
	return (
		<div className="purchaseContainer">
			<div className="purchaseRow">
				Purchase Conditions
				<CustomSelect
					name="multibuyEligbility.triggerCombinationCode"
					required={true}
					label={localConst.triggerCombinationCode}
					options={getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)}
					smallText={localConst.subTriggerCombinationCode}
					className="customDropdown inlinePadding bg-position"
				/>
				{multibuyEligbility.thresholdTypeCode == 'INTERVAL_QTY_THRESHOLD' ||
				multibuyEligbility.thresholdTypeCode == 'QTY_THRESHOLD' ||
				multibuyEligbility.thresholdTypeCode == 'RANGE_QTY_THRESHOLD' ||
				multibuyEligbility.thresholdTypeCode == 'LIMIT_QTY_THRESHOLD' ? (
					<CustomInputText
						name="multibuyEligbility.thresholdQuantity"
						required={true}
						label={localConst.thresholdQuantity}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				{multibuyEligbility.thresholdTypeCode == 'LIMIT_QTY_THRESHOLD' ? (
					<CustomInputText
						name="multibuyEligbility.quantityLimit"
						required={true}
						label={localConst.quantityLimit}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				{multibuyEligbility.thresholdTypeCode == 'INTERVAL_AMOUNT_THRESHOLD' ||
				multibuyEligbility.thresholdTypeCode == 'AMOUNT_THRESHOLD' ||
				multibuyEligbility.thresholdTypeCode == 'RANGE_AMOUNT_THRESHOLD' ? (
					<CustomInputText
						name="multibuyEligbility.thresholdAmount"
						required={true}
						label={localConst.thresholdAmount}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				{multibuyEligbility.thresholdTypeCode == 'RANGE_QTY_THRESHOLD' ||
				multibuyEligbility.thresholdTypeCode == 'RANGE_AMOUNT_THRESHOLD' ? (
					multibuyEligbility.thresholdTypeCode == 'RANGE_QTY_THRESHOLD' ? (
						<CustomInputText
							name="multibuyEligbility.maximumThresholdQuantity"
							required={true}
							label={localConst.maximumThresholdQuantity}
							smallText=""
							disabled={false}
						/>
					) : (
						<CustomInputText
							name="multibuyEligbility.maximumThresholdAmount"
							required={true}
							label={localConst.maximumThresholdAmount}
							smallText=""
							disabled={false}
						/>
					)
				) : undefined}{' '}
				<CustomSelect
					name="multibuyEligbility.triggerOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{multibuyEligbility.triggerOperator == '<' ||
				multibuyEligbility.triggerOperator == '>' ||
				multibuyEligbility.triggerOperator == '<=' ||
				multibuyEligbility.triggerOperator == '>=' ||
				multibuyEligbility.triggerOperator == '=' ? (
					<CustomInputText
						name="multibuyEligbility.triggerOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{multibuyEligbility.triggerOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="multibuyEligbility.triggerOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="multibuyEligbility.triggerOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>

			<div className="purchaseRow">
				Reward Conditions
				<CustomSelect
					name="multibuyEligbility.rewardCombinationCode"
					required={true}
					label={localConst.rewardCombinationCode}
					options={getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)}
					smallText={localConst.subRewardCombinationCode}
					className="customDropdown inlinePadding bg-position"
				/>
				<CustomInputText
					name="multibuyEligbility.rewardQuantity"
					required={true}
					label={localConst.rewardQuantity}
					smallText=""
					disabled={false}
				/>
				<CustomSelect
					name="multibuyEligbility.rewardOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{multibuyEligbility.rewardOperator == '<' ||
				multibuyEligbility.rewardOperator == '>' ||
				multibuyEligbility.rewardOperator == '<=' ||
				multibuyEligbility.rewardOperator == '>=' ||
				multibuyEligbility.rewardOperator == '=' ? (
					<CustomInputText
						name="multibuyEligbility.rewardOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{multibuyEligbility.rewardOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="multibuyEligbility.rewardOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="multibuyEligbility.rewardOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>
		</div>
	);
}
export default ConditionalRedering;
