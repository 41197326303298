import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../BasicDetails.scss';
import { useField } from 'formik';

const CustomInputText = ({
	label,
	required,
	prepend,
	smallText,
	type,
	readonly,
	flag,
	...props
}) => {
	const [field, meta] = useField(props);

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	let formattedName = '';
	let newJson = '';
	let basicOrEligibilityJson = '';
	if (isAuditFlw) {
		let newCustName = JSON.parse(JSON.stringify(props.name));
		// eslint-disable-next-line no-unused-vars
		newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
		basicOrEligibilityJson = newCustName.includes('promotionPolicy')
			? 'newJson'
			: 'newJson.promotionPolicy.priceDerivationRuleEligibility';
		let arrayOfName = newCustName.split('.');

		for (let i = 0; i < arrayOfName.length; i++) {
			if (isNaN(arrayOfName[i])) {
				formattedName = formattedName + '?.' + arrayOfName[i];
			} else {
				formattedName = formattedName + '?.[' + arrayOfName[i] + ']';
			}
		}
	}

	return (
		<div
			className={
				label == 'Maximum Threshold Quantity' ||
				label == 'Maximum Threshold Amount' ||
				label == 'Reward Operator Value From' ||
				label == 'Trigger Combination Code' ||
				label == 'Reward Combination Code' ||
				label == 'Group Maximum Threshold Amount' ||
				label == 'Group Maximum Threshold Quantity' ||
				label == 'Trigger Operator Value From' ||
				label == 'Reward Operator Value To'
					? ''
					: ''
			}
		>
			<div
				className={
					isAuditFlw &&
					eval(basicOrEligibilityJson + formattedName) === field.value &&
					flag != 'show'
						? 'hideForAudit'
						: ''
				}
			>
				<div className="">
					<label
						htmlFor="forprogramID"
						className={
							label == 'Maximum Threshold Quantity' ||
							label == 'Maximum Threshold Amount'
								? 'col	col-form-label'
								: 'col-form-label'
						}
					>
						{label}

						{required ? <span className="requiredRed">*</span> : null}
					</label>
				</div>
				<div className="">
					<div className="">
						{prepend != undefined ? (
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroup-sizing-sm">
									{prepend}
								</span>
							</div>
						) : null}
						{type == 'textarea' && readonly == false ? (
							<textarea
								rows="4"
								{...field}
								{...props}
								className={`form-control ${
									meta.touched && meta.error ? 'is-invalid' : ''
								}`}
							/>
						) : type == 'textarea' && readonly == true ? (
							<>
								<textarea
									readOnly
									rows="4"
									{...field}
									{...props}
									className={`form-control ${
										meta.touched && meta.error ? 'is-invalid' : ''
									}`}
								/>
								{isAuditFlw ? (
									<textarea
										rows="4"
										className="form-control lightBlue ml-3"
										name="currentValue"
										value={eval(basicOrEligibilityJson + formattedName)}
									/>
								) : null}
							</>
						) : (
							<>
								<input
									{...field}
									{...props}
									className={`form-control ${
										meta.touched && meta.error ? 'is-invalid' : ''
									}`}
								/>
								{isAuditFlw ? (
									<input
										type="text"
										className={
											eval(basicOrEligibilityJson + formattedName) ===
											field.value
												? 'd-none'
												: 'form-control lightBlue mt-2'
										}
										name="currentValue"
										value={eval(basicOrEligibilityJson + formattedName)}
									/>
								) : null}
							</>
						)}

						{smallText && (
							<small id="emailHelp" className="form-text pl-2">
								<div className="tooltipimg">
									<button className="tooltip-button btn br-50" type="button">
										<span className="tooltip-icon">?</span>
									</button>
									<span className="tooltiptext"> {smallText}</span>
								</div>
							</small>
						)}

						{meta.touched && meta.error ? (
							<div className="invalid-feedback">{meta.error}</div>
						) : null}
					</div>
					{/* <small
            id="emailHelp"
            className="form-text text-muted col-md-9 justify-content-start flex1"
          >
            {smallText}
          </small> */}
				</div>
			</div>
		</div>
	);
};

export default CustomInputText;
