import React, { useEffect, useState } from 'react';
import { Form, Field, useField, FieldArray, useFormikContext } from 'formik';
import CustomInputText from './customfields/CustomInputText';
import CustomSelect from './customfields/CustomSelect';
import CollapseHeaderBar from './CollapseHeaderBar';
import CollapseBody from './CollapseBody';
import BenefitSettings from './BenefitSettings';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import PaymentCondition from './PaymentCondition';
import CouponCondition from './CouponCondition';
import ExclusionSetting from './ExclusionSetting';
import { itemHelp } from './item-help-text';
import ConditionalRederingPKG from './PackageProduct/ConditionalRederingPKG';
import { Element } from 'react-scroll';
import MerchTableBootstrapSCALE from './MerchTableBootstrapSCALE';
import { connect } from 'react-redux';

const thresholdTypeCodeOptions = [
	{
		value: '',
		name: 'Select one...'
	},
	{
		value: 'PACKAGE_INTERVAL_QTY_THRESHOLD',
		name: 'Package Interval Quantity Threshold'
	},
	{
		value: 'PACKAGE_QTY_THRESHOLD',
		name: 'Package Quantity Threshold'
	},
	{
		value: 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
		name: 'Package Interval Amount Threshold'
	},
	{
		value: 'PACKAGE_AMOUNT_THRESHOLD',
		name: 'Package Amount Threshold'
	},
	{
		value: 'PACKAGE_RANGE_QTY_THRESHOLD',
		name: 'Package Range Quantity Threshold'
	},
	{
		value: 'PACKAGE_RANGE_AMOUNT_THRESHOLD',
		name: 'Package Range Amount Threshold'
	},
	{
		value: 'PACKAGE_LIMIT_QTY_THRESHOLD',
		name: 'Package Limit Quantity Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD',
		name: 'Hierarchy Package Interval Quantity Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_QTY_THRESHOLD',
		name: 'Hierarchy Package  Quantity Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
		name: 'Hierarchy Package Interval Amount Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD',
		name: 'Hierarchy Package Amount Threshold'
	}
];

let PKGMerchTableData;

const keys = ['merchandizeGroupId', 'minimumPrice', 'maximumPrice', 'trigger', 'getReward'];
const defaultValueForAddPM = {
	maximumPrice: '999999.99',
	minimumPrice: '0.01'
	// trigger: 'Y',
	// getReward: 'Y',
};

let errMsg = '';

const getThresholdDropdown = (thresholdTypeCodeOptions) => {
	return thresholdTypeCodeOptions.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	triggerCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Determines the combination code to be used between the trigger items for a promotion',
	thresholdQuantity: 'Threshold Quantity',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	rewardQuantity: 'Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	PaymentConditions: 'Payment Conditions',
	CouponConditions: 'Coupon Conditions',
	ExclusionsSetting: 'Exclusion Settings'
};

const EligibilityForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [showTable, setshowTable] = useState(true);
	const [field] = useField(props);

	const fieldPKG = field.value.packageMultibuyEligibility;

	const { setFieldValue } = useFormikContext();

	if (Object.keys(props.dataMRCHtable).length != 0) {
		PKGMerchTableData = props.dataMRCHtable;
	} else {
		PKGMerchTableData = {
			group: [
				{
					merchandizeHierarchyGroup: []
				}
			]
		};
	}

	useEffect(() => {
		if (
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			fieldPKG.group[0].memberGroupThresholdQuantity = undefined;
			if (
				!fieldPKG.group[0].memberGroupThresholdAmount &&
				fieldPKG.group[0].trigger == true
			) {
				fieldPKG.group[0].memberGroupThresholdAmount = '0.01';
			}
			if (
				!fieldPKG.group[0].memberGroupRewardQuantity &&
				fieldPKG.group[0].getReward == true
			) {
				fieldPKG.group[0].memberGroupRewardQuantity = '1';
			}
		}
		if (
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD'
		) {
			fieldPKG.group[0].memberGroupThresholdAmount = undefined;
			if (
				!fieldPKG.group[0].memberGroupThresholdQuantity &&
				fieldPKG.group[0].trigger == true
			) {
				fieldPKG.group[0].memberGroupThresholdQuantity = '1';
			}
			if (
				!fieldPKG.group[0].memberGroupRewardQuantity &&
				fieldPKG.group[0].getReward == true
			) {
				fieldPKG.group[0].memberGroupRewardQuantity = '1';
			}
		}
	}, [fieldPKG.thresholdTypeCode]);

	const onMerchTableChange = (val, j) => {
		PKGMerchTableData.group[j].merchandizeHierarchyGroup = val;

		props.onPKGMerchTableChange(PKGMerchTableData);
	};

	const triggerValueChange = (i) => {
		if (
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD'
		) {
			if (!fieldPKG.group[i].trigger == true) {
				fieldPKG.group[i].memberGroupThresholdQuantity = '1';
				fieldPKG.group[i].memberGroupThresholdAmount = '';
			} else {
				fieldPKG.group[i].memberGroupThresholdQuantity = '';
				fieldPKG.group[i].memberGroupThresholdAmount = '';
			}
		} else if (
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			if (!fieldPKG.group[i].trigger == true) {
				fieldPKG.group[i].memberGroupThresholdAmount = '0.01';
				fieldPKG.group[i].memberGroupThresholdQuantity = '';
			} else {
				fieldPKG.group[i].memberGroupThresholdAmount = '';
				fieldPKG.group[i].memberGroupThresholdQuantity = '';
			}
		}
	};

	const getRewardValueChange = (i) => {
		if (
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			//fieldPKG.group[i].memberGroupRewardQuantity = ''
			if (!fieldPKG.group[i].getReward == true) {
				fieldPKG.group[i].memberGroupRewardQuantity = '1';
			} else {
				fieldPKG.group[i].memberGroupRewardQuantity = '';
			}
		}
	};

	const customValidateMemberGroupThresholdQuantity = (value, i) => {
		let error;
		if (
			(fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD') &&
			fieldPKG.group[i].trigger
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}$/)) {
					error = 'Group Threshold Quantity value should be between 1 to 999999';
				}
			} else {
				error = 'Please define a Group Threshold Quantity value';
			}
		}
		return error;
	};
	//amount
	const customValidateMemberGroupThresholdAmount = (value, i) => {
		let error;
		if (
			(fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD') &&
			fieldPKG.group[i].trigger
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/)) {
					error = 'Group Threshold Amount value should be between 0.01 to 999999.99';
				}
			} else {
				error = 'Please define a Group Threshold Amount value';
			}
		}
		return error;
	};

	//reward
	const customValidateMemberGroupRewardQuantity = (value, i) => {
		let error;
		if (
			(fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD') &&
			fieldPKG.group[i].getReward
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}$/)) {
					error = 'Group Reward Quantity value should be between 1 to 999999';
				}
			} else {
				error = 'Please define a Group Reward Quantity value';
			}
		}
		return error;
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	return (
		<div>
			<Form className={offerFlow.type == 'view-offer-flow' ? 'disabledEvent' : ''}>
				<Element
					name="scrollToPurchaseandRewardConditions"
					class="card"
					style={{ border: 'none' }}
				>
					<div className="container-fluid">
						<div className="d-flex justify-content-center">
							<CustomSelect
								control="select"
								label="Threshold Type Code"
								name="packageMultibuyEligibility.thresholdTypeCode"
								options={getThresholdDropdown(thresholdTypeCodeOptions)}
								required={true}
								className="customDropdown inlinePadding"
								smallText={itemHelp.ThresholdTypeCodePkgProd}
							/>
						</div>
						{/* {field.value.thresholdTypeCode ? (
          <ConditionalRedering value={field.value.thresholdTypeCode} />
           ) : null}  */}

						{fieldPKG.thresholdTypeCode ? (
							<>
								<ConditionalRederingPKG
									value={fieldPKG.thresholdTypeCode}
									//index={index}
								/>
								<FieldArray name={`packageMultibuyEligibility.group`}>
									{({ remove }) => (
										<>
											{fieldPKG.group.map((item, i) => (
												<>
													<div
														className="accordion"
														id="accordionExample"
													>
														<div
															className="card ml-2"
															style={{ border: 'none' }}
														>
															<div className="d-flex mt-2">
																<div
																	style={{
																		width: 'calc(100% - 35px)'
																	}}
																>
																	<b className="pl-1">Group</b>
																</div>
																<div>
																	{fieldPKG?.group?.length > 1 ? (
																		<button
																			type="button"
																			className="addDeleteBtn"
																			onClick={() => {
																				remove(i);
																				PKGMerchTableData.group.splice(
																					i,
																					1
																				);
																				props.onPKGMerchTableChange(
																					PKGMerchTableData
																				);
																				setshowTable(false);
																				setTimeout(() => {
																					setshowTable(
																						true
																					);
																				}, 50);
																				setFieldValue(
																					'',
																					''
																				);
																			}}
																		></button>
																	) : null}
																</div>
															</div>

															<div
																className="container-fluid cardStyle"
																style={{
																	border: '1px solid #E6EBF1',
																	'border-radius': '5px'
																}}
															>
																<div className="text-center mt-3">
																	<span className="col-6">
																		<label>Trigger</label>
																		<span className="requiredRed pr-2">
																			*
																		</span>
																		<Field
																			type="checkbox"
																			name={`packageMultibuyEligibility.group.${i}.trigger`}
																			className="p-3"
																			onClick={() =>
																				triggerValueChange(
																					i
																				)
																			}
																		/>
																	</span>
																	<span className="col-6">
																		<label>Get Reward</label>
																		<span className="requiredRed pr-2">
																			*
																		</span>
																		<Field
																			type="checkbox"
																			name={`packageMultibuyEligibility.group.${i}.getReward`}
																			className="p-3"
																			onClick={() =>
																				getRewardValueChange(
																					i
																				)
																			}
																		/>
																	</span>
																</div>
																{/* <MTMBProductTable data={data} onProductTableChange={(val) => onProductTableChange(val, index, i)} /> */}
																{fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
																fieldPKG.thresholdTypeCode ==
																	'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ? (
																	<div className="purchaseContainer">
																		<div className="purchaseRow align-self-start d-block">
																			{fieldPKG.thresholdTypeCode ==
																				'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
																			fieldPKG.thresholdTypeCode ==
																				'HIERARCHY_PACKAGE_QTY_THRESHOLD' ? (
																				<CustomInputText
																					name={`packageMultibuyEligibility.group.${i}.memberGroupThresholdQuantity`}
																					required={false}
																					validate={(
																						value
																					) =>
																						customValidateMemberGroupThresholdQuantity(
																							value,
																							i
																						)
																					}
																					label={
																						'Group Threshold Quantity'
																					}
																					smallText=""
																					disabled={
																						!fieldPKG
																							.group[
																							i
																						].trigger
																					}
																				/>
																			) : (
																				<CustomInputText
																					name={`packageMultibuyEligibility.group.${i}.memberGroupThresholdAmount`}
																					validate={(
																						value
																					) =>
																						customValidateMemberGroupThresholdAmount(
																							value,
																							i
																						)
																					}
																					required={false}
																					label={
																						'Group Threshold Amount'
																					}
																					smallText=""
																					disabled={
																						!fieldPKG
																							.group[
																							i
																						].trigger
																					}
																				/>
																			)}
																		</div>
																		<div className="purchaseRow align-self-start d-block">
																			<CustomInputText
																				name={`packageMultibuyEligibility.group.${i}.memberGroupRewardQuantity`}
																				validate={(value) =>
																					customValidateMemberGroupRewardQuantity(
																						value,
																						i
																					)
																				}
																				required={false}
																				label={
																					'Group Reward Quantity'
																				}
																				smallText=""
																				disabled={
																					!fieldPKG.group[
																						i
																					].getReward
																				}
																			/>
																		</div>
																	</div>
																) : null}
																{/* <Field name={`packageMultibuyEligibility.${index}.group.${i}.merchandizeHierarchyGroup`} component={ProductSetting} /> */}
																<div>
																	<div className="text-center text-danger">
																		{errMsg ==
																		'Product ID:' +
																			fieldPKG.group[i]
																				.productID +
																			' already added'
																			? errMsg
																			: null}
																	</div>

																	<div className="margin10">
																		{/* <SellingHierarchyTablePKGMERCH data={props.merdata} onmerchandizeTableChange={onmerchandizeTableChange} /> */}
																		{showTable ? (
																			<MerchTableBootstrapSCALE
																				keys={keys} //data={data}
																				data={
																					PKGMerchTableData
																						.group[i]
																						.merchandizeHierarchyGroup
																				}
																				defaultValueForAdd={
																					defaultValueForAddPM
																				}
																				onMerchTableChange={(
																					val
																				) =>
																					onMerchTableChange(
																						val,
																						i
																					)
																				}
																				eligibility={
																					'packageSH'
																				}
																				group={
																					PKGMerchTableData.group
																				}
																				PKGMerchTableData={
																					PKGMerchTableData
																				}
																				isMerchTableRepetative={
																					true
																				}
																				currentGroupIndex={
																					i
																				}
																			/>
																		) : null}
																	</div>
																</div>
															</div>
														</div>
														{i + 1 == fieldPKG?.group?.length ? (
															<div className="d-flex justify-content-end mt-2">
																<button
																	type="button"
																	className="createGroupBtn"
																	onClick={() => {
																		PKGMerchTableData.group.push(
																			{
																				merchandizeHierarchyGroup:
																					[]
																			}
																		);

																		fieldPKG.group.push({
																			merchandizeHierarchyGroup:
																				[],
																			trigger: true,
																			getReward: true,
																			memberGroupThresholdAmount:
																				fieldPKG.thresholdTypeCode ==
																					'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
																				fieldPKG.thresholdTypeCode ==
																					'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
																					? '0.01'
																					: undefined,
																			memberGroupThresholdQuantity:
																				fieldPKG.thresholdTypeCode ==
																					'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
																				fieldPKG.thresholdTypeCode ==
																					'HIERARCHY_PACKAGE_QTY_THRESHOLD'
																					? '1'
																					: undefined,
																			memberGroupRewardQuantity:
																				'1'
																		});

																		setFieldValue('', '');
																	}}
																>
																	<span className="pr-2">+</span>
																	Add Group
																</button>
															</div>
														) : null}
													</div>
												</>
											))}
										</>
									)}
								</FieldArray>
							</>
						) : null}
					</div>
				</Element>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToExclusionsSettings"
						class="card"
						style={{ border: 'none' }}
					>
						<CollapseHeaderBar
							text={localConst.ExclusionsSetting}
							headingId={'headingES'}
							dataTarget={'#collapseES'}
						/>
						<CollapseBody id="collapseES" headingId={'headingES'}>
							<ExclusionSetting
								customName="packageMultibuyEligibility"
								fullValue={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToPaymentConditions"
						class="card"
						style={{ border: 'none' }}
					>
						<CollapseHeaderBar
							text={localConst.PaymentConditions}
							headingId={'headingFour'}
							dataTarget={'#collapseFour'}
						/>
						<CollapseBody id="collapseFour" headingId={'headingFour'}>
							<PaymentCondition
								customName="packageMultibuyEligibility"
								fullVal={props.fullValue}
								loggedInBrand={props.loggedInBrand}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToCouponConditions"
						class="card"
						style={{ border: 'none' }}
					>
						<CollapseHeaderBar
							text={localConst.CouponConditions}
							headingId={'headingFive'}
							dataTarget={'#collapseFive'}
						/>
						<CollapseBody id="collapseFive" headingId={'headingFive'}>
							<CouponCondition
								customName="packageMultibuyEligibility"
								fullVal={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element name="scrollToBenefitSettings" class="card" style={{ border: 'none' }}>
						<CollapseHeaderBar
							text={localConst.benefitSettings}
							headingId={'headingTwo'}
							dataTarget={'#collapseTwo'}
						/>
						<CollapseBody id="collapseTwo" headingId={'headingTwo'}>
							<BenefitSettings {...props} customName="packageMultibuyEligibility" />
						</CollapseBody>
					</Element>
				</div>
			</Form>
		</div>
	);
};

//const EligibilitySubForm = withSubForm(EligibilityForm, eligibilitySchema);

const mapStateToProps = (state) => {
	return {
		loggedInBrand: state.loggedInBrand
	};
};

export default connect(mapStateToProps, {})(EligibilityForm);
