import React from 'react';
import { useField } from 'formik';

const CustomCheckbox = ({
	label,
	required,
	prepend,
	smallText,
	type,
	flag,
	hideBlueBox,
	...props
}) => {
	const [field] = useField(props);

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	let formattedName = '';
	let newJson = '';
	let basicOrEligibilityJson = '';
	if (isAuditFlw) {
		let newCustName = JSON.parse(JSON.stringify(props.name));
		// eslint-disable-next-line no-unused-vars
		newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
		basicOrEligibilityJson = newCustName.includes('promotionPolicy')
			? 'newJson'
			: 'newJson.promotionPolicy.priceDerivationRuleEligibility';
		let arrayOfName = newCustName.split('.');

		for (let i = 0; i < arrayOfName.length; i++) {
			if (isNaN(arrayOfName[i])) {
				formattedName = formattedName + '?.' + arrayOfName[i];
			} else {
				formattedName = formattedName + '?.[' + arrayOfName[i] + ']';
			}
		}
	}

	return (
		//  <div className="container">
		//    <div className="rowContainer">
		//      <div className="customLabelContainer">
		//        <label htmlFor="forprogramID" className="col	col-form-label">
		//          {label}

		//          {required ? <span className="requiredRed">*</span> : null}
		//        </label>
		//      </div>
		//      <div className={"customInputContainer"}>
		//        <div className="row">
		isAuditFlw ? (
			<>
				<label
					className={
						eval(basicOrEligibilityJson + formattedName) === field.value &&
						flag != 'show'
							? 'd-none'
							: 'ml-3'
					}
				>
					{label}
				</label>

				<div
					className={
						eval(basicOrEligibilityJson + formattedName) === field.value &&
						flag != 'show'
							? 'd-none'
							: 'd-flex gap70 replacedText'
					}
				>
					{/* <input type="text" className="form-control lightGray" 
                        value={field.value} />
                      <input type="text" className={eval(basicOrEligibilityJson+formattedName)===field.value?"d-none":"form-control lightBlue"}
                        value={eval(basicOrEligibilityJson + formattedName)} /> */}
					<div className="m-2 oldCheckboxContainer">
						<input
							className="oldCheckbox"
							type="checkbox"
							{...field}
							{...props}
							style={{ bottom: '2px' }}
							checked={field.value}
						/>
					</div>
					<div className="m-2 newCheckboxContainer">
						<input
							style={{ bottom: '4px' }}
							type="checkbox"
							className={
								eval(basicOrEligibilityJson + formattedName) === field.value ||
								hideBlueBox
									? 'd-none'
									: 'lightBlue'
							}
							{...field}
							{...props}
							checked={eval(basicOrEligibilityJson + formattedName)}
						/>
					</div>
				</div>
			</>
		) : (
			<>
				<input type="checkbox" {...field} {...props} checked={field.value} />
				<label className="ml-3">{label}</label>
			</>
		)

		//                    {smallText &&
		//                      <small
		//                        id="emailHelp"
		//                        className="form-text pl-2">
		//                        <div class="tooltipimg">
		//                            <button className="tooltip-button btn br-50" type="button">
		//                                <span className="tooltip-icon">?</span></button>
		//                            <span class="tooltiptext"> {smallText}</span>
		//                        </div>
		//                    </small>}
		//                    {
		//                        meta.touched &&
		//                            meta.error ? (
		//                                <div className="invalid-feedback d-block">{meta.error}</div>
		//                            ) : null}
		//                    <div className="invalid-feedback d-block">{errMsg}</div>
		//        </div>

		//      </div>
		//    </div>
		//  </div>
	);
};

export default CustomCheckbox;
