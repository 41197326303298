import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './createOffer.scss';
import { Formik } from 'formik';
import EligibilityComponent from './components/EligibilityComponentTXN';

import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import toaster from 'toasted-notes';
import { isObjectEmpty, trimDate, getOfferFlowType } from '../../utils/helperFunction';
import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import chevronDown from '../../assets/images/chevron-down.png';
import { Link, Element } from 'react-scroll';
import { ConfirmationModal } from '../../MainContent/ConfirmationModal';
import { InformationModal } from '../../MainContent/InformationModal';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';

//Local const
const localConst = {
	offerMaintainance: 'Offer Maintenance',
	basicDetails: 'Basic Details',
	PromotionType: 'Promotion Type',
	description: 'Description',
	combinationPromotion: 'Combination with other Promotions',
	shares: 'Shares',
	redemptionSettings: 'Redemption Settings',
	others: 'Others',
	applicationEligibility: 'Applicable Eligibility',
	channelEligibility: 'Channel Eligibility',
	privilegeCustomer: 'Privilege Customer Eligibility',
	anonymousCostomer: 'Anonymous Customer Eligibility',
	employeeEligibility: 'Employee Eligibility',
	loyaltyCustomer: 'Loyalty Customer Eligibility',
	customerSegments: 'Customer Segments Eligibility',
	loyaltyCard: 'LoyaltyCard Customer Eligibility',
	happyHours: 'Happy Hours',
	collectionRequired: 'Collection Required',
	eligibilitySettings: 'Eligibility Settings'
};
var txnTable = [];

let initialValues = {
	totalPurchaseMarketBasedPriceDerivationRuleEligibiity: {
		transactionRewardProduct: [],

		exclusions: {
			excludedItemIDList: {
				excludedItemID: []
			},
			merchandizeHierarchyGroup: []
		},
		paymentEligibility: {
			allowAggAndIssuer: false,
			applicableOn: ''
		},
		coupons: {},
		thresholdTypeCode: '',
		transactionRewardQuantity: '',
		thresholdQuantity: '',
		maximumThresholdQuantity: '',
		maximumThresholdAmount: '',
		quantityLimit: ''
	},
	promotionalOfferReward: {
		itemPriceDerivationRule: {
			benefit: {
				priceModificationMethodCode: '',
				priceModificationPercent: '',
				benefitType: ''
			}
		}
	}
};
const SignupSchema = Yup.object().shape({
	totalPurchaseMarketBasedPriceDerivationRuleEligibiity: Yup.object().shape({
		thresholdTypeCode: Yup.string().required('Please provide a Threshold Type value'),

		quantityLimit: Yup.string().when('thresholdTypeCode', {
			is: 'BASKET_LIMIT_QTY_THRESHOLD',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}$/,
					'Quantity Limit value should be between 1 to 999999'
				)
				.test(
					'val_test',
					'Quantity Limit value should be between 1 to 999999',
					function (value) {
						return 1 <= parseInt(value) && parseInt(value) <= 999999;
					}
				)
		}),
		transactionRewardQuantity: Yup.string().matches(
			/^(?=.*[1-9])\d{0,6}$/,
			'Reward quantity must be between 1 and 999999'
		),

		//add conditonal render
		thresholdQuantity: Yup.string().when('thresholdTypeCode', {
			is: (thresholdTypeCode) => {
				if (
					thresholdTypeCode == 'BASKET_INTERVAL_QTY_THRESHOLD' ||
					thresholdTypeCode == 'BASKET_QTY_THRESHOLD' ||
					thresholdTypeCode == 'BASKET_LIMIT_QTY_THRESHOLD'
				) {
					return thresholdTypeCode;
				}
			},
			then: Yup.string()
				.required('Please define purchased quantity value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Purchased quantity must be between 1 and 999999'
				)
				.test(
					'val_test',
					'Purchased quantity must be between 1 and 999999',
					function (value) {
						return 0.01 <= parseFloat(value) && parseFloat(value) <= 999999.99;
					}
				)
		}),
		thresholdAmount: Yup.lazy(() =>
			Yup.string().when(
				['thresholdTypeCode', 'maximumThresholdAmount'],
				(thresholdTypeCode, maximumThresholdAmount, schema) => {
					if (
						thresholdTypeCode == 'BASKET_INTERVAL_VALUE_THRESHOLD' ||
						thresholdTypeCode == 'BASKET_VALUE_THRESOLD' ||
						thresholdTypeCode == 'BASKET_RANGE_VALUE_THRESHOLD'
					) {
						if (thresholdTypeCode == 'BASKET_RANGE_VALUE_THRESHOLD') {
							return schema
								.matches(
									/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
									'Amount Purchased must be between 0.01 to 999999.99'
								)
								.required('Please define purchased amount value')
								.test(
									'val_test',
									'Maximum amount threshold specified should be greater than minimum amount purchased',
									function (value) {
										return (
											parseFloat(value) < maximumThresholdAmount ||
											parseFloat(value) == maximumThresholdAmount
										);
									}
								);
						} else {
							return schema
								.matches(
									/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
									'Amount Purchased must be between 0.01 to 999999.99'
								)
								.test(
									'val_test',
									'Amount Purchased must be between 0.01 to 999999.99',
									function (value) {
										return (
											0.01 <= parseFloat(value) &&
											parseFloat(value) <= 999999.99
										);
									}
								);
						}
					}
				}
			)
		),

		maximumThresholdAmount: Yup.lazy(() =>
			Yup.string().when(
				['thresholdTypeCode', 'thresholdAmount'],
				(thresholdTypeCode, thresholdAmount, schema) => {
					if (thresholdTypeCode == 'BASKET_RANGE_VALUE_THRESHOLD') {
						return schema
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Maximum Amount Purchased must be between 0.01 to 999999.99'
							)
							.required('Please define maximum purchased amount value')
							.test(
								'val_test',
								'Maximum amount threshold specified should be greater than minimum amount purchased',
								function (value) {
									return (
										parseFloat(value) > thresholdAmount ||
										parseFloat(value) == thresholdAmount
									);
								}
							);
					}
				}
			)
		),

		maximumThresholdQuantity: Yup.string().when('thresholdTypeCode', {
			is: 'BASKET_RANGE_QTY_THRESHOLD',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}$/,
					'Maximum Purchased quantity must be between 1 and 999999'
				)
				.required('Please define maximum purchased quantity value')
				.test(
					'val_test',
					'Maximum Purchased quantity must be between 1 and 999999',
					function (value) {
						return 1 <= parseInt(value) && parseInt(value) <= 999999;
					}
				)
		}),

		coupons: Yup.object().shape({
			couponThresholdQuantity: Yup.string().when('couponType', {
				is: (couponType) => {
					if (
						couponType == 'GENERIC' ||
						couponType == 'PERSONALIZED' ||
						couponType == 'TARGETED'
					) {
						return couponType;
					}
				},
				then: Yup.string()
					.matches(
						/^[0-9]+$/,
						'Required Coupon Quantity must be between 1 and six 9s (both inclusive)'
					)
					.test(
						'val_test',
						'Required Coupon Quantity must be between 1 and six 9s (both inclusive)',
						function (value) {
							return 1 <= parseInt(value) && parseInt(value) <= 999999;
						}
					)
			}),

			coupon: Yup.array()
				.of(
					Yup.object().shape({
						couponId: Yup.string()
							.matches(
								/^.[a-zA-Z0-9]+$/,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.min(
								2,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.max(
								50,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.required(
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							),
						couponLimit: Yup.string()
							.matches(
								/^[0-9]+$/,
								'Required: Redemption Limit per coupon must be between 1 and nine 9s (both inclusive)'
							)
							.test(
								'val_test',
								'Required: Redemption Limit per coupon must be between 1 and nine 9s (both inclusive)',
								function (value) {
									return 1 <= parseInt(value) && parseInt(value) <= 999999999;
								}
							),
						expireOn: Yup.object().shape({
							date: Yup.date(),
							/*  .min(
              new Date(),
              "Entered validity date and time cannot be lesser than the promotion start date and time"
            ), */
							days: Yup.string().when('couponExpiry', {
								is: 'Days',
								then: Yup.string()
									.required('Please define a days value')
									.matches(
										/^(?=.*[1-9])\d{0,3}$/,
										'Please enter a value greater than 1 to 999.'
									)
							}),
							months: Yup.string().when('couponExpiry', {
								is: 'Months',
								then: Yup.string()
									.required('Please define a months value')
									.matches(
										/^(?=.*[1-9])\d{0,3}$/,
										'Please enter a value greater than 1 to 999.'
									)
							}),
							hours: Yup.string().when('couponExpiry', {
								is: 'Hours',
								then: Yup.string().required('Please define a hours value')
							})
						})
					})
				)
				.unique('Coupon Id Already Exists.', (a) => a.couponId)
		}),

		paymentEligibility: Yup.object().shape({
			applicableOn: Yup.string(),
			paymentThresholdTypeCode: Yup.string(),
			/* .when("applicableOn",
          {
          is: applicableOn => {
            if (applicableOn == "TENDER_VALUE" ||
            applicableOn == "TRANSACTION_VALUE") {
            return applicableOn;
            }
          },
          then: Yup.string()
            .required("Please define a payment threshold type code value")
          }
        ), */
			payment:
				/* Yup.object().when("paymentThresholdTypeCode", {
           is: "PAYMENT_AMOUNT_THRESHOLD", */
				Yup.object().when('applicableOn', {
					is: (applicableOn) => {
						if (applicableOn == 'TENDER_VALUE' || applicableOn == 'TRANSACTION_VALUE') {
							return applicableOn;
						}
					},
					then: Yup.object().shape({
						paymentThresholdAmount: Yup.string()
							.required('Please define a payment threshold amount value')
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Payment Threshold Amount should be between 0.01 to 999999.99'
							),
						paymentInformation: Yup.string(),
						emiType: Yup.string(),
						paymentGroup: Yup.array().of(
							Yup.object().shape({
								// aggregators: Yup.array().required("Please define an aggregator"),
								issuers: Yup.array().of(
									Yup.object().shape({
										emi: Yup.object().shape({
											emiScale: Yup.array().of(
												Yup.object().shape({
													tenurePeriodInMonths: Yup.string()
														.required(
															'Please define a tenure period in months value'
														)
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Tenure Period in Months should be between 1 to 999'
														),
													interestRate: Yup.string()
														.required(
															'Please define a value for Interest rates'
														)
														.matches(
															/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
															'Tenure Percentage should be between 0.01 to 100.00'
														)
														.test(
															'percent_test',
															'Tenure Percentage should be between 0.01 to 100.00',
															function (value) {
																return parseFloat(value) < 100.01;
															}
														),
													cappedAmount: Yup.string().matches(
														/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
														'Amount Capping should be between 0.01 to 999999.99'
													)
												})
											),
											coolOffPeriod: Yup.object().shape({
												coolOffPeriodType: Yup.string(),
												months: Yup.string().when('coolOffPeriodType', {
													is: 'Months',
													then: Yup.string()
														.required('Please define months value')
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Months should be between 1 to 999'
														)
												}),
												days: Yup.string().when('coolOffPeriodType', {
													is: 'Days',
													then: Yup.string()
														.required('Please define days value')
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Days should be between 1 to 999'
														)
												}),
												years: Yup.string().when('coolOffPeriodType', {
													is: 'Years',
													then: Yup.string()
														.required('Please define years value')
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Years should be between 1 to 999'
														)
												})
											})
										})
									})
								)
							})
						),
						emi: Yup.object().shape({
							emiScale: Yup.array().of(
								Yup.object().shape({
									tenurePeriodInMonths: Yup.string()
										.required('Please define a tenure period in months value')
										.matches(
											/^(?=.*[1-9])\d{0,3}$/,
											'Tenure Period in Months should be between 1 to 999'
										),
									interestRate: Yup.string()
										.required('Please define a value for Interest rates')
										.matches(
											/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
											'Tenure Percentage should be between 0.01 to 100.00'
										)
										.test(
											'percent_test',
											'Tenure Percentage should be between 0.01 to 100.00',
											function (value) {
												return parseFloat(value) < 100.01;
											}
										),
									cappedAmount: Yup.string().matches(
										/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
										'Amount Capping should be between 0.01 to 999999.99'
									)
								})
							),
							coolOffPeriod: Yup.object().shape({
								coolOffPeriodType: Yup.string(),
								months: Yup.string().when('coolOffPeriodType', {
									is: 'Months',
									then: Yup.string()
										.required('Please define months value')
										.matches(
											/^(?=.*[1-9])\d{0,3}$/,
											'Months should be between 1 to 999'
										)
								}),
								days: Yup.string().when('coolOffPeriodType', {
									is: 'Days',
									then: Yup.string()
										.required('Please define days value')
										.matches(
											/^(?=.*[1-9])\d{0,3}$/,
											'Days should be between 1 to 999'
										)
								}),
								years: Yup.string().when('coolOffPeriodType', {
									is: 'Years',
									then: Yup.string()
										.required('Please define years value')
										.matches(
											/^(?=.*[1-9])\d{0,3}$/,
											'Years should be between 1 to 999'
										)
								})
							})
						})
					})
				})
		}),
		triggerOperatorValue: Yup.string().when('triggerOperator', {
			//  is: triggerOperator => ("<" || ">" || ">=" || "<=" || "="),
			is: (triggerOperator) => {
				if (
					triggerOperator == '<' ||
					triggerOperator == '=' ||
					triggerOperator == '>' ||
					triggerOperator == '>=' ||
					triggerOperator == '<='
				) {
					return triggerOperator;
				}
			},
			then: Yup.string()
				.required('Please define price range value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price range value should be between 0.01 to 999999.99'
				)
		}),
		triggerOperatorValueFrom: Yup.string().when('triggerOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value From should be between 0.01 to 999999.99'
				)
				.required('Please define price range value from')
				.test(
					'triggerOperatorValueFrom_test',
					'Price Range Value From should be less than the Price Range Value To',
					function (value) {
						const { triggerOperatorValueTo } = this.parent;
						return (
							parseFloat(value) < triggerOperatorValueTo ||
							parseFloat(value) == triggerOperatorValueTo
						);
					}
				)
		}),
		triggerOperatorValueTo: Yup.string().when('triggerOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value To should be between 0.01 to 999999.99'
				)
				.required('Please define price range value to')
				.test(
					'triggerOperatorValueTo_test',
					'Price Range Value To should not less than the Price Range Value From',
					function (value) {
						const { triggerOperatorValueFrom } = this.parent;
						return (
							parseFloat(value) > triggerOperatorValueFrom ||
							parseFloat(value) == triggerOperatorValueFrom
						);
					}
				)
		}),
		rewardOperatorValue: Yup.string().when('rewardOperator', {
			// is: rewardOperator => ("<" || ">" || ">=" || "<=" || "="),
			is: (rewardOperator) => {
				if (
					rewardOperator == '<' ||
					rewardOperator == '=' ||
					rewardOperator == '>' ||
					rewardOperator == '>=' ||
					rewardOperator == '<='
				) {
					return rewardOperator;
				}
			},
			then: Yup.string()
				.required('Please define Price range value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price range value should be between 0.01 to 999999.99'
				)
		}),
		rewardOperatorValueFrom: Yup.string().when('rewardOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value From should be between 0.01 to 999999.99'
				)
				.required('Please define price range value from')
				.test(
					'RewardOperatorValueFrom_test',
					'Price Range Value From should be less than the Price Range Value To',
					function (value) {
						const { rewardOperatorValueTo } = this.parent;
						return (
							parseFloat(value) < rewardOperatorValueTo ||
							parseFloat(value) == rewardOperatorValueTo
						);
					}
				)
		}),

		rewardOperatorValueTo: Yup.string().when('rewardOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value To should be between 0.01 to 999999.99'
				)
				.required('Please define price range value to')
				.test(
					'rewardOperatorValueTo_test',
					'Price Range Value To should not less than the Price Range Value From',
					function (value) {
						const { rewardOperatorValueFrom } = this.parent;
						return (
							parseFloat(value) > rewardOperatorValueFrom ||
							parseFloat(value) == rewardOperatorValueFrom
						);
					}
				)
		})
	}),
	promotionalOfferReward: Yup.object().shape({
		itemPriceDerivationRule: Yup.object().shape({
			benefit: Yup.object().shape({
				benefitType: Yup.string().required('Required: Benefit Type must be selected'),
				priceModificationMethodCode: Yup.string().when('benefitType', {
					is: (benefitType) => {
						if (
							benefitType == 'INSTANT' ||
							benefitType == 'CASHBACK' ||
							benefitType == 'LOYALTY_POINTS' ||
							benefitType == 'FREEBIE' ||
							benefitType == 'EMI'
						) {
							return benefitType;
						}
					},
					then: Yup.string().required('Please define price modification percent value')
				}),

				priceModificationPercent: Yup.string().when('benefitType', {
					//is: benefitType =>  ("INSTANT" || "CASHBACK" || "FREEBIE" ),
					is: (benefitType) => {
						if (
							benefitType == 'INSTANT' ||
							benefitType == 'CASHBACK' ||
							benefitType == 'LOYALTY_POINTS' ||
							benefitType == 'EMI'
						) {
							return benefitType;
						}
					},
					then: Yup.string().when('priceModificationMethodCode', {
						is: (priceModificationMethodCode) => {
							if (
								priceModificationMethodCode == 'PERCENTAGE_OFF' ||
								priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF'
							) {
								return priceModificationMethodCode;
							}
						},
						//  priceModificationMethodCode => ("PERCENTAGE_OFF" || "APPORTIONED_PERCENTAGE_OFF"),
						then: Yup.string()
							.required('Please define price modification amount value')
							.matches(
								/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
								'Price Modification Percent should be between 0.01 to 100.00'
							)
							.test(
								'percent_test',
								'Price Modification Percent should be between 0.01 to 100.00',
								function (value) {
									return parseFloat(value) < 100.01;
								}
							)
					})
				}),
				priceModificationAmount: Yup.string().when('priceModificationMethodCode', {
					// is:  priceModificationMethodCode => ("AMOUNT_OFF" || "APPORTIONED_AMOUNT_OFF"),
					is: (priceModificationMethodCode) => {
						if (
							priceModificationMethodCode == 'AMOUNT_OFF' ||
							priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF'
						) {
							return priceModificationMethodCode;
						}
					},
					then: Yup.string()
						.required('Please define price modification amount value')
						.matches(
							/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
							'Price Modification Amount should be between 0.01 to 999999.99'
						)
				}),
				//MARKDOWN KIT_MARKDOWN
				newPriceAmount: Yup.string().when('priceModificationMethodCode', {
					// is:  priceModificationMethodCode => ("MARKDOWN" || "KIT_MARKDOWN"),
					is: (priceModificationMethodCode) => {
						if (
							priceModificationMethodCode == 'MARKDOWN' ||
							priceModificationMethodCode == 'KIT_MARKDOWN'
						) {
							return priceModificationMethodCode;
						}
					},
					then: Yup.string()
						.required('Please define new price amount value')
						.matches(
							/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
							'Price Modification Amount should be between 0.01 to 999999.99'
						)
				}),
				cashback: Yup.object().shape({
					creditBy: Yup.object().shape({
						date: Yup.date().min(
							new Date(),
							'Date should not be less than the Current Date'
						),
						days: Yup.string().matches(
							/^(?=.*[1-9])\d{0,3}$/,
							'Please enter a value greater than 1 to 999.'
						),
						months: Yup.string().matches(
							/^(?=.*[1-9])\d{0,3}$/,
							'Please enter a value greater than 1 to 999.'
						)
					}),
					expireOn: Yup.object().shape({
						date: Yup.date().min(
							new Date(),
							'Date should not be less than the Current Date'
						),
						days: Yup.string().matches(
							/^(?=.*[1-9])\d{0,3}$/,
							'Please enter a value greater than 1 to 999.'
						),
						months: Yup.string().matches(
							/^(?=.*[1-9])\d{0,3}$/,
							'Please enter a value greater than 1 to 999.'
						)
					})
				}),
				loyalty: Yup.string().when('priceModificationMethodCode', {
					is: !{},
					then: Yup.object().shape({
						loyaltyMultiplier: Yup.string()
							.required('Please define loyalty multiplier point value')
							.matches(
								/^(?=.*[1-9])\d{0,6}$/,
								'Loyalty Multiplier Point should be between 1 to 999999'
							),
						expireOn: Yup.object().shape({
							date: Yup.date().min(
								new Date(),
								'Date should not be less than the Current Date'
							),
							days: Yup.string().matches(
								/^(?=.*[1-9])\d{0,3}$/,
								'Please enter a value greater than 1 to 999.'
							),
							months: Yup.string().matches(
								/^(?=.*[1-9])\d{0,3}$/,
								'Please enter a value greater than 1 to 999.'
							)
						})
					})
				})
			})
		})
	})
});

const EligibilityForm = ({ ...props }) => {
	//state and props
	const [productTableData, setProductTableData] = useState([]);
	const location = useLocation();

	let history = useHistory();
	// const myparam = location.state.params;
	let myparam = {};
	if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
		myparam = props.auditDetails;
	} else {
		myparam = location.state?.params;
	}
	const myparam2 = location.state?.MTMBProdTableDatafromBasicDetails;

	const [initialData, setInitialData] = useState({});
	const [confirmationModalShow, setCnfrmatnModalShow] = useState(false);
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState('');
	const [informationTitle] = useState('Error');

	useEffect(() => {
		if (myparam) {
			if (
				JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility
					.totalPurchaseMarketBasedPriceDerivationRuleEligibiity != undefined
			) {
				const prevData = JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility;
				//--
				//Transaction Data reengaging

				//---
				const multibyProductData =
					prevData.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
						.transactionRewardProduct;
				if (multibyProductData?.length) {
					txnTable = multibyProductData;
					prevData.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.transactionRewardProduct =
						[];
					// resetting to avoid the mismatch, when user goes to basic details and again comes back to eligibility during modify flow
				}
				if (myparam2 === null) {
					/* null means brand eligibility select input change */
					prevData.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions.excludedItemIDList.excludedItemID =
						[];
					prevData.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions.merchandizeHierarchyGroup =
						[];
				}
				setInitialData(prevData);
			} else {
				initialValues.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions.excludedItemIDList.excludedItemID =
					[];
				initialValues.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions.merchandizeHierarchyGroup =
					[];
				setInitialData(initialValues);
			}
		}
		if (myparam2) {
			setProductTableData(JSON.parse(myparam2));
			txnTable = JSON.parse(myparam2);
		}
		return () => {
			setProductTableData([]);
			txnTable = [];
		};
	}, []);

	const validateFormikExcludedData = (values) => {
		let isNonFormikDataValid = true;
		const validateData = JSON.parse(JSON.stringify(values));
		// chk requred if reward qunty value
		if (
			!validateData.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
				.transactionRewardQuantity == ''
		)
			if (!productTableData.length) {
				setInformationMsg('Please enter the product data');
				setInformationModalShow(true);

				isNonFormikDataValid = false;
			}

		return isNonFormikDataValid;
	};

	const validatePaymentData = (consolidatedData) => {
		let isNonFormikDataValid1 = true;
		const Pay =
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility;

		if (!(Object.keys(Pay).length === 0) && Pay.constructor === Object) {
			const { applicableOn } =
				consolidatedData.promotionPolicy.priceDerivationRuleEligibility
					.totalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility ||
				{};
			if (applicableOn) {
				const { paymentInformation } =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.totalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility
						?.payment || {};
				const { paymentGroup } =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.totalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility
						?.payment || {};
				if (paymentInformation == undefined && paymentGroup == undefined) {
					setInformationMsg('Please enter payment details');
					setInformationModalShow(true);
					isNonFormikDataValid1 = false;
				} else if (
					paymentInformation &&
					!(Object.keys(paymentInformation).length === 0) &&
					paymentInformation.constructor === Object
				) {
					const { paymentMode, provider, paymentType, paymentSubType } =
						consolidatedData.promotionPolicy.priceDerivationRuleEligibility
							.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
							.paymentEligibility?.payment?.paymentInformation || {};

					let Arr = [];
					if (paymentMode) Arr.push(paymentMode);
					if (provider) Arr.push(provider);
					if (paymentType) Arr.push(paymentType);
					if (paymentSubType) Arr.push(paymentSubType);
					//debugger
					if (Arr.length < 1) {
						setInformationMsg('Please enter payment details');
						setInformationModalShow(true);
						isNonFormikDataValid1 = false;
					}
				}
			}
		}
		return isNonFormikDataValid1;
	};

	const submitConsolidatedForm = (values) => {
		const consolidatedData = getConsolidatedData(JSON.parse(JSON.stringify(values)));
		if (validateFormikExcludedData(values)) {
			if (validatePaymentData(consolidatedData)) {
				let businessUnitFunctionID = JSON.parse(
					sessionStorage.getItem('taggedBrandToLoggedInUser')
				).businessUnitGroupFunctionID;
				const newConsolidatedData = omitEmpty(consolidatedData);

				const svPromUrl = JSON.parse(
					sessionStorage.getItem('applctnUrls')
				).savePromotionsUrl;

				let offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

				if (offerFlow.type == 'modify-offer-flow') {
					let dataToSend = {
						modifyPromotionRequest: {
							user: {
								userID: sessionStorage.getItem('loggedInUserId')
							},
							promotionId: offerFlow.slctdPromotionId,
							inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
						}
					};
					props.showLoadingButton(true);
					RestSvc.postData(
						urlTypes.save_promotion_end_points.modifyPromotionUrl,
						dataToSend,
						svPromUrl
					)
						.then((response) => {
							props.showLoadingButton(false);
							var result = response.data.modifyPromotionResponse;
							if (result.businessError) {
								toaster.notify(
									({ onClose }) => (
										<div className="Toaster__message-wrapper">
											<div>
												<div
													id="1"
													className="toasterRed Toaster__alert Toaster__alert_error"
												>
													<div className="Toaster__alert_text">
														{result.businessError.errorMessage}
													</div>
													<button
														className="Toaster__alert_close"
														type="button"
														aria-label="Close"
														onClick={onClose}
													>
														<span aria-hidden="true">x</span>
													</button>
												</div>
											</div>
										</div>
									),
									{
										duration: 10000
									}
								);
							} else {
								if (
									consolidatedData?.promotionPolicy.priceDerivationRuleEligibility
										?.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
										?.paymentEligibility?.isBinRangePromotion
								) {
									if (
										JSON.parse(offerFlow.data).promotionPolicy
											.priceDerivationRuleEligibility
											?.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
											?.paymentEligibility?.isBinRangePromotion
									) {
										toaster.notify(
											({ onClose }) => (
												<div className="Toaster__message-wrapper">
													<div>
														<div
															id="1"
															className="bg-warning Toaster__alert Toaster__alert_error"
														>
															<div className="Toaster__alert_text text-dark p-0">
																{
																	'Please remember you have modified a BIN promotion, if required revisit your BIN setup.'
																}
															</div>
															<button
																className="Toaster__alert_close"
																type="button"
																aria-label="Close"
																onClick={onClose}
															>
																<span aria-hidden="true">x</span>
															</button>
														</div>
													</div>
												</div>
											),
											{
												duration: 10000
											}
										);
									} else {
										toaster.notify(
											({ onClose }) => (
												<div className="Toaster__message-wrapper">
													<div>
														<div
															id="1"
															className="bg-warning Toaster__alert Toaster__alert_error"
														>
															<div className="Toaster__alert_text text-dark p-0">
																{
																	'Please ensure to upload BINs as the promotion is modified into BIN promotion.'
																}
															</div>
															<button
																className="Toaster__alert_close"
																type="button"
																aria-label="Close"
																onClick={onClose}
															>
																<span aria-hidden="true">x</span>
															</button>
														</div>
													</div>
												</div>
											),
											{
												duration: 10000
											}
										);
									}
								}
								const promotionSuccessMsg =
									"Promotion '" +
									result.promotionId +
									"' is modified successfully.";
								toaster.notify(
									({ onClose }) => (
										<div className="Toaster__message-wrapper">
											<div>
												<div
													id="1"
													className="bg-success Toaster__alert Toaster__alert_error"
												>
													<div className="Toaster__alert_text">
														{promotionSuccessMsg}
													</div>
													<button
														className="Toaster__alert_close"
														type="button"
														aria-label="Close"
														onClick={onClose}
													>
														<span aria-hidden="true">x</span>
													</button>
												</div>
											</div>
										</div>
									),
									{
										duration: 10000
									}
								);
								props.setDisableItem(false);
								history.push('/MainPage/offerconfigurator');
							}
						})
						.catch((error) => {
							props.showLoadingButton(false);
							console.error(error);
						});
				} else {
					let dataToSend = {
						savePromotionJsonRequest: {
							user: {
								userID: sessionStorage.getItem('loggedInUserId')
							},
							businessUnitGroupFunctionID: businessUnitFunctionID,
							inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
							//"inputJson": inputJson
						}
					};
					props.showLoadingButton(true);
					RestSvc.postData(
						urlTypes.save_promotion_end_points.savePromotionJsonUrl,
						dataToSend,
						svPromUrl
					)
						.then((response) => {
							props.showLoadingButton(false);
							var result = response.data.savePromotionJsonResponse;
							if (result.businessError) {
								toaster.notify(
									({ onClose }) => (
										<div className="Toaster__message-wrapper">
											<div>
												<div
													id="1"
													className="toasterRed Toaster__alert Toaster__alert_error"
												>
													<div className="Toaster__alert_text">
														{result.businessError.errorMessage}
													</div>
													<button
														className="Toaster__alert_close"
														type="button"
														aria-label="Close"
														onClick={onClose}
													>
														<span aria-hidden="true">x</span>
													</button>
												</div>
											</div>
										</div>
									),
									{
										duration: 10000
									}
								);
							} else {
								if (
									consolidatedData?.promotionPolicy
										?.priceDerivationRuleEligibility
										?.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
										?.paymentEligibility?.isBinRangePromotion
								) {
									toaster.notify(
										({ onClose }) => (
											<div className="Toaster__message-wrapper">
												<div>
													<div
														id="1"
														className="bg-warning Toaster__alert Toaster__alert_error"
													>
														<div className="Toaster__alert_text text-dark p-0">
															{
																'Please ensure to upload BINs for the BIN based promotion created. '
															}
														</div>
														<button
															className="Toaster__alert_close"
															type="button"
															aria-label="Close"
															onClick={onClose}
														>
															<span aria-hidden="true">x</span>
														</button>
													</div>
												</div>
											</div>
										),
										{
											duration: 10000
										}
									);
								}
								const promotionSuccessMsg =
									"Promotion '" + result.promotionId + "' is saved successfully.";
								toaster.notify(
									({ onClose }) => (
										<div className="Toaster__message-wrapper">
											<div>
												<div
													id="1"
													className="bg-success Toaster__alert Toaster__alert_error"
												>
													<div className="Toaster__alert_text">
														{promotionSuccessMsg}
													</div>
													<button
														className="Toaster__alert_close"
														type="button"
														aria-label="Close"
														onClick={onClose}
													>
														<span aria-hidden="true">x</span>
													</button>
												</div>
											</div>
										</div>
									),
									{
										duration: 10000
									}
								);
								props.setDisableItem(false);

								history.push('/MainPage/offerconfigurator');
							}
						})
						.catch((error) => {
							props.showLoadingButton(false);
							console.error(error);
						});
				}
			}
		}
	};
	const getConsolidatedData = (values) => {
		let consolidatedData = JSON.parse(myparam);

		consolidatedData.promotionPolicy.priceDerivationRuleEligibility = values;
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.transactionRewardProduct =
			productTableData;

		consolidatedData.promotionPolicy.promotionID = consolidatedData.promotionPolicy.promotionID
			? consolidatedData.promotionPolicy.programID +
			  ':' +
			  consolidatedData.promotionPolicy.promotionID
			: '';

		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons.coupon != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons.coupon =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons.coupon,
					'expireOn',
					'couponExpiry'
				);
		}
		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility?.payment
				?.paymentGroup != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								item1.cashbackInformation = trimDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = trimDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = trimDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			!consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
				?.applicableOn
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.allowAggAndIssuer =
				undefined;
		}

		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward
				.itemPriceDerivationRule.benefit.cashback != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward.itemPriceDerivationRule.benefit.cashback =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.promotionalOfferReward.itemPriceDerivationRule.benefit.cashback,
					'creditBy',
					'cashbackCreditData'
				);
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward.itemPriceDerivationRule.benefit.cashback =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.promotionalOfferReward.itemPriceDerivationRule.benefit.cashback,
					'expireOn',
					'cashbackExpireData'
				);
		}
		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward
				.itemPriceDerivationRule.benefit.loyalty != undefined
		) {
			// consolidatedData.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.benefit.loyalty =
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward.itemPriceDerivationRule.benefit.loyalty =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.promotionalOfferReward.itemPriceDerivationRule.benefit.loyalty,
					'expireOn',
					'loyaltyExpireData'
				);
		}

		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility?.payment
				?.emi != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.payment.emi =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
						.payment.emi,
					'coolOffPeriod',
					'coolOffPeriodType'
				);
		}

		//Change for

		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.promotionalOfferReward != undefined
		) {
			consolidatedData.promotionPolicy.promotionalOfferReward =
				consolidatedData.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward;
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward =
				undefined;
		}

		consolidatedData = JSON.parse(
			JSON.stringify(consolidatedData, function (key, value) {
				return key !== 'selectedCoupon' ? value : undefined;
			})
		);

		return consolidatedData;
	};
	/*  const gotoCreateOffer = async () => {
    const consolidatedData = getConsolidatedData();
    await history.push("/MainPage/offerconfigurator/offerBasicDetails", {
      params: JSON.stringify(consolidatedData),
    });
  }; */
	const gotoCreateOffer = async (values) => {
		const dataFromBasicDetails = JSON.parse(myparam);
		dataFromBasicDetails.promotionPolicy.priceDerivationRuleEligibility = values;

		const consolidatedData = dataFromBasicDetails;

		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			await history.push('/MainPage/auditMaintenance/offerBasicDetails', {
				params: JSON.stringify(consolidatedData),
				MTMBProdTableData: JSON.stringify(productTableData)
			});
		} else {
			await history.push('/MainPage/offerconfigurator/offerBasicDetails', {
				params: JSON.stringify(consolidatedData),
				MTMBProdTableData: JSON.stringify(productTableData)
			});
		}
	};
	const onProductTableChanged = (val) => {
		setProductTableData(val);
		txnTable = val;
	};
	const showCancelConfirmation = () => {
		setCnfrmatnModalShow(true);
	};

	const confirmationModalClose = () => {
		setCnfrmatnModalShow(false);
	};

	const proceedToOperation = () => {
		props.setDisableItem(false);
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			history.push('/MainPage/auditMaintenance');
		} else {
			history.push('/MainPage/offerconfigurator');
		}
	};

	const onMouseLeaveHandler = () => {
		props.setMousePlace('');
	};

	const linkText = {
		elibilitySettings: [
			'Product Settings',
			'Exclusion Settings',
			'Payment Conditions',
			'Coupon Conditions',
			'Benefit Settings'
		]
	};
	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	return (
		<div
			className={
				getOfferFlowType() == 'View Offer'
					? 'main-container create-offer-container viewOfferContainer'
					: 'main-container create-offer-container'
			}
		>
			<div className="header-flex">
				<div className="back-div" onClick={showCancelConfirmation}>
					<div className="pr-2">
						<img src={chevronDown} alt="" />
					</div>
					<div>Back</div>
				</div>

				<div className="header-text">{getOfferFlowType()}</div>
			</div>
			<div className="flex-mainContent">
				<div className="left-navigation br-right">
					<div className="text-header">Transaction</div>
					{linkText.elibilitySettings.map((val, ind) => (
						<div key={ind} className="text-content">
							<Link
								// activeClass="activePromotionSection"
								activeClass=""
								className={
									'scrollTo' + val.replace(/\s/g, '') == props.curntMousePlace
										? 'activePromotionSection'
										: ''
								}
								to={'scrollTo' + val.replace(/\s/g, '')}
								spy={true}
								smooth={true}
								duration={250}
								containerId="containerElement"
							>
								{val}
							</Link>
						</div>
					))}
				</div>
				<Element
					id="containerElement"
					className="right-content"
					onMouseLeave={onMouseLeaveHandler}
				>
					<>
						{!isObjectEmpty(initialData) && (
							<Formik
								initialValues={initialData}
								onSubmit={async (values) => {
									await new Promise((resolve) => setTimeout(resolve, 500));
									await submitConsolidatedForm(values);
								}}
								validateOnChange={
									false
								} /* transaction for PaymentConditionForm browser hang prevent */
								validationSchema={SignupSchema}
							>
								{(props) => {
									const { isSubmitting, handleSubmit } = props;
									return (
										<form onSubmit={handleSubmit}>
											<div className="item-title">
												{localConst.eligibilitySettings}
											</div>
											<EligibilityComponent
												data={txnTable}
												onProductTableChanged={onProductTableChanged}
												fullValue={myparam}
												dataMTMBtable={productTableData}
											/>

											<div className="footer">
												<button
													type="button"
													className="goTo-basicDetails mr-3"
													onClick={async () => {
														await gotoCreateOffer(props.values);
													}}
												>
													Go To Basic Details
												</button>
												{offerFlow.type == 'view-offer-flow' ? null : (
													<button
														type="submit"
														className="submit-class"
														disabled={isSubmitting}
													>
														Submit
													</button>
												)}
											</div>
										</form>
									);
								}}
							</Formik>
						)}
					</>
				</Element>
			</div>
			{confirmationModalShow ? (
				<ConfirmationModal
					show={confirmationModalShow}
					onHide={confirmationModalClose}
					onProceed={proceedToOperation}
					confirmmsg={'Are you sure you want to navigate away?'}
				/>
			) : null}
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
