import React, { useEffect } from 'react';
import { Form, useField, useFormikContext } from 'formik';
import CustomInputText from './customfields/CustomInputText';
import CustomInputRadio from './customfields/CustomInputRadio';
import CustomDateTime from './customfields/CustomDateTime';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import CustomSelect from './customfields/CustomSelect';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { itemHelp } from './item-help-text';
import $ from 'jquery';

const dropdownValues = {
	benefitTypes: [
		{ value: '', name: 'Select one' },
		{ value: 'INSTANT', name: 'Instant Discount' },
		// { value: 'CASHBACK', name: 'Cashback' },
		// { value: 'LOYALTY_POINTS', name: 'Loyalty Points' },
		{ value: 'FREEBIE', name: 'Freebies' }
		// { value: 'FREE_TEXT', name: 'Free text' },
		// { value: 'EMI', name: 'EMI' }
	],
	collectionValidity: [
		{ value: '', name: 'Select one' },
		{
			value: 'Date',
			name: 'Date'
		},
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Hours',
			name: 'Hours'
		}
	]
};

const priceMethodCodeDropdown = {
	INSTANT: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' },
		{ value: 'APPORTIONED_PERCENTAGE_OFF', name: 'Apportioned Percentage Off' },
		{ value: 'AMOUNT_OFF', name: 'Amount Off' },
		{ value: 'APPORTIONED_AMOUNT_OFF', name: 'Apportioned Amount Off' },
		{ value: 'MARKDOWN', name: 'Markdown' },
		{ value: 'KIT_MARKDOWN', name: 'Kit Markdown' }
	],
	CASHBACK: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' },
		{ value: 'APPORTIONED_PERCENTAGE_OFF', name: 'Apportioned Percentage Off' },
		{ value: 'AMOUNT_OFF', name: 'Amount Off' },
		{ value: 'APPORTIONED_AMOUNT_OFF', name: 'Apportioned Amount Off' }
	],
	LOYALTY_POINTS: [
		{ value: '', name: 'Select one' },
		{ value: 'LOYALTY_ADDITIONAL_POINTS', name: 'Loyalty Additional Points' },
		{ value: 'LOYALTY_POINTS_MULTIPLIER', name: 'Loyalty Multiplier Points' },
		{
			value: 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS',
			name: 'Apportioned Loyalty Additional Points on Points'
		},
		{
			value: 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE',
			name: 'Apportioned Loyalty Additional Points on Unit Price'
		},
		{
			value: 'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS',
			name: 'Apportioned Loyalty Multiplier on Points'
		},
		{
			value: 'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE',
			name: 'Apportioned Loyalty Multiplier on Unit Price'
		}
	],
	FREEBIE: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' }
		// { value: 'APPORTIONED_PERCENTAGE_OFF', name: 'Apportioned Percentage Off' },
	],
	EMI: [
		{ value: '', name: 'Select one' },
		{ value: 'EMI_PERCENTAGE_OFF', name: 'EMI Percentage Off' },
		{ value: 'EMI_APPORTIONED_PERCENTAGE_OFF', name: 'EMI Apportioned Percentage off' }
	]
};

const localConst = {
	benefitType: 'Benefit Type',
	priceMethodCode: 'Price Modification Method Code',
	priceModificationPercent: 'Price Modification Percent',
	priceModificationAmount: 'Price Modification Amount',
	newPriceAmount: 'New Price Amount',
	subPriceModificationPercent: 'Determines the benefit percent for the promotion',
	provider: 'Provider',
	subProvider: 'Defines the Payment eligibility Provider for which the promotion is applicable',
	paymentType: 'Payment Type',
	subpaymentType: 'Defines the Payment eligibility Type for which the promotion is applicable',
	paymentSubType: 'Payment Sub Type',
	paymentMode: 'Payment Mode',
	subCashBackCreditByMonths:
		'Specifies the period the cashback will be credited to the customer in months post the transaction',
	subCashBackCreditByDays:
		'Specifies the period the cashback will be credited to the customer in days post the transaction',
	subCashBackCreditByHours:
		'Specifies the period the cashback will be credited to the customer in hours post the transaction',
	subCashBackCreditByDate:
		'Specifies the period the cashback will be credited to the customer post the transaction',
	subCashBackExpireOnMonths:
		'Specifies the period the cashback will be Expired to the customer in months post the transaction',
	subCashBackExpireOnDays:
		'Specifies the period the cashback will be Expired to the customer in days post the transaction',
	subCashBackExpireOnHours:
		'Specifies the period the cashback will be Expired to the customer in hours post the transaction',
	subCashBackExpireOnDate:
		'Specifies the period the cashback will be Expired to the customer post the transaction'
};

const BenefitSettings = ({ name, errors, touched, getFieldProps, ...props }) => {
	// useValues(name, props);
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const [field] = useField(props);
	let { customName } = props;
	const fieldBenefit =
		customName == 'packageMultibuyEligibilityAdded'
			? field.value.mtmbEligibility.packageMultibuyEligibilityAdded[props.index].benefit
			: customName == 'packageMultibuyEligibilityRemoved'
			? field.value.mtmbEligibility.packageMultibuyEligibilityRemoved[props.index].benefit
			: field.value.mtmbEligibility.packageMultibuyEligibility[props.index].benefit;
	const { setFieldValue } = useFormikContext();
	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
	useEffect(() => {
		if (
			JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow &&
			customName == 'packageMultibuyEligibility'
		) {
			//
			//
			//
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
					.packageMultibuyEligibility[props.index]?.benefit?.benefitType !=
				fieldBenefit.benefitType
			) {
				let tempName =
					`mtmbEligibility.packageMultibuyEligibility.${props.index}` +
					'.benefit.benefitType';
				//
				$('[name="' + tempName + '"]')
					.closest('.container')
					.nextAll()
					.addClass('d-none');
			} else if (
				newJson.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
					.packageMultibuyEligibility[props.index]?.benefit
					?.priceModificationMethodCode != fieldBenefit.priceModificationMethodCode
			) {
				let tempName =
					`mtmbEligibility.packageMultibuyEligibility.${props.index}` +
					'.benefit.priceModificationMethodCode';

				$('[name="' + tempName + '"]')
					.closest('.container')
					.nextAll()
					.addClass('d-none');
			}
			//   else if (fieldbenefit.benefitType == "CASHBACK" && newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.benefit?.cashback?.creditBy?.cashbackCreditData != fieldbenefit?.cashback?.creditBy?.cashbackCreditData) {
			//     let tempName = valueEligbility + ".benefit.cashback.creditBy.cashbackCreditData"
			//
			//     $('[name="' + tempName + '"]').closest(".container").next().addClass("d-none")
			//   }
			//   //.benefit.cashback.expireOn.cashbackExpireData
			//   else if (fieldbenefit.benefitType == "CASHBACK" && newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.benefit?.cashback?.expireOn?.cashbackExpireData != fieldbenefit?.cashback?.expireOn.cashbackExpireData) {
			//     let tempName = valueEligbility + ".benefit.cashback.expireOn.cashbackExpireData"
			//
			//     $('[name="' + tempName + '"]').closest(".container").next().addClass("d-none")
			//   }
			//   //groupMultibuyEligbility.benefit.loyalty.expireOn.loyaltyExpireData
			//   else if (fieldbenefit.benefitType == "LOYALTY_POINTS" && newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.benefit?.loyalty?.expireOn?.loyaltyExpireData != fieldbenefit?.loyalty?.expireOn?.loyaltyExpireData) {
			//     let tempName = valueEligbility + ".benefit.loyalty.expireOn.loyaltyExpireData"
			//
			//     $('[name="' + tempName + '"]').closest(".container").next().addClass("d-none")
			//   }
		}
	}, []);

	useEffect(() => {
		if (fieldBenefit.benefitType == 'INSTANT') {
			if (!fieldBenefit.priceModificationMethodCode) {
				setFieldValue(
					`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationMethodCode`,
					''
				);
			}
		}
		if (fieldBenefit.benefitType == 'FREEBIE') {
			if (
				!fieldBenefit.priceModificationMethodCode ||
				fieldBenefit.priceModificationMethodCode == 'AMOUNT_OFF' ||
				fieldBenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF' ||
				fieldBenefit.priceModificationMethodCode == 'KIT_MARKDOWN' ||
				fieldBenefit.priceModificationMethodCode == 'MARKDOWN'
			) {
				//   setFieldValue(`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationMethodCode`, "")
			}
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationMethodCode`,
				'PERCENTAGE_OFF'
			);
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`,
				undefined
			);
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationAmount`,
				undefined
			);
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.newPriceAmount`,
				undefined
			);
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationQuantity`,
				undefined
			);
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`,
				'100'
			);
		}
	}, [fieldBenefit?.benefitType]);

	useEffect(() => {
		if (fieldBenefit.benefitType == 'INSTANT') {
			if (fieldBenefit.priceModificationMethodCode == 'PERCENTAGE_OFF') {
				if (!fieldBenefit.priceModificationPercent) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`,
						'0.01'
					);
				}
			} else if (fieldBenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF') {
				if (!fieldBenefit.priceModificationPercent) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`,
						'0.01'
					);
				}
			} else if (fieldBenefit.priceModificationMethodCode == 'AMOUNT_OFF') {
				if (!fieldBenefit.priceModificationAmount) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationAmount`,
						'0.01'
					);
				}
			} else if (fieldBenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF') {
				if (!fieldBenefit.priceModificationAmount) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationAmount`,
						'0.01'
					);
				}
			} else if (fieldBenefit.priceModificationMethodCode == 'MARKDOWN') {
				if (!fieldBenefit.newPriceAmount) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.newPriceAmount`,
						'0.01'
					);
				}
			} else if (fieldBenefit.priceModificationMethodCode == 'KIT_MARKDOWN') {
				if (!fieldBenefit.newPriceAmount) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.newPriceAmount`,
						'0.01'
					);
				}
			}
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty`,
				undefined
			);
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.cashback`,
				undefined
			);
		} else if (fieldBenefit.benefitType == 'CASHBACK') {
			if (fieldBenefit.priceModificationMethodCode == 'PERCENTAGE_OFF') {
				if (!fieldBenefit.priceModificationPercent) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`,
						'0.01'
					);
				}
			} else if (fieldBenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF') {
				if (!fieldBenefit.priceModificationPercent) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`,
						'0.01'
					);
				}
			} else if (fieldBenefit.priceModificationMethodCode == 'AMOUNT_OFF') {
				if (!fieldBenefit.priceModificationAmount) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationAmount`,
						'0.01'
					);
				}
			} else if (fieldBenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF') {
				if (!fieldBenefit.priceModificationAmount) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationAmount`,
						'0.01'
					);
				}
			}
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty`,
				undefined
			);
		} else if (fieldBenefit.benefitType == 'LOYALTY_POINTS') {
			// if (fieldBenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS') {
			if (!fieldBenefit.Loyalty.BaseEarnPointEligibility) {
				setFieldValue(
					`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.BaseEarnPointEligibility`,
					true
				);
			}
			if (!fieldBenefit.Loyalty.ConsiderBaseRateForItem) {
				setFieldValue(
					`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ConsiderBaseRateForItem`,
					true
				);
			}
			// }
			if (
				fieldBenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
				fieldBenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
				fieldBenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE'
			) {
				if (!fieldBenefit.Loyalty.LoyaltyAdditionalPoint) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.LoyaltyAdditionalPoint`,
						'0.01'
					);
				}
				setFieldValue(
					`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.loyaltyMultiplier`,
					undefined
				);
			} else if (
				fieldBenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
				fieldBenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
				fieldBenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE'
			) {
				if (!fieldBenefit.Loyalty.loyaltyMultiplier) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.loyaltyMultiplier`,
						'1'
					);
				}
				setFieldValue(
					`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.LoyaltyAdditionalPoint`,
					undefined
				);
			}
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.cashback`,
				undefined
			);
		} else if (fieldBenefit.benefitType == 'FREEBIE') {
			if (fieldBenefit.priceModificationMethodCode == 'PERCENTAGE_OFF') {
				if (!fieldBenefit.priceModificationPercent) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`,
						'100'
					);
				}
			} else if (fieldBenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF') {
				if (!fieldBenefit.priceModificationPercent) {
					setFieldValue(
						`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`,
						'100'
					);
				}
			}
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty`,
				undefined
			);
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.cashback`,
				undefined
			);
		} else if (fieldBenefit.benefitType == 'FREE_TEXT' || fieldBenefit.benefitType == 'EMI') {
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty`,
				undefined
			);
			setFieldValue(
				`mtmbEligibility.${customName}.${props.index}.benefit.cashback`,
				undefined
			);
		}
	}, [fieldBenefit?.priceModificationMethodCode]);

	const getBenefitTypeDropdown = () => {
		return dropdownValues.benefitTypes.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const getCollectionValidityDropdown = () => {
		return dropdownValues.collectionValidity.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const getPriceMethodCodeDropdown = () => {
		if (fieldBenefit.benefitType == 'INSTANT') {
			return priceMethodCodeDropdown.INSTANT.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldBenefit.benefitType == 'CASHBACK') {
			return priceMethodCodeDropdown.CASHBACK.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldBenefit.benefitType == 'LOYALTY_POINTS') {
			return priceMethodCodeDropdown.LOYALTY_POINTS.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldBenefit.benefitType == 'FREEBIE') {
			return priceMethodCodeDropdown.FREEBIE.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldBenefit.benefitType == 'EMI') {
			return priceMethodCodeDropdown.EMI.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		}
	};

	const getContentBelowToBenefitType = () => {
		if (fieldBenefit.benefitType == 'INSTANT') {
			return (
				<>
					<CustomSelect
						name={`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw && customName == 'packageMultibuyEligibility' ? (
								<>
									(For Instant Discount)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.InstantPMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldBenefit.benefitType == 'CASHBACK') {
			return (
				<>
					<CustomSelect
						name={`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw && customName == 'packageMultibuyEligibility' ? (
								<>
									(For Cashback)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.CashBackPMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldBenefit.benefitType == 'LOYALTY_POINTS') {
			return (
				<>
					<CustomSelect
						name={`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw && customName == 'packageMultibuyEligibility' ? (
								<>
									(For Loyalty Points)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.LoyaltyPMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldBenefit.benefitType == 'FREEBIE') {
			return (
				<>
					<CustomSelect
						name={`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw && customName == 'packageMultibuyEligibility' ? (
								<>
									(For Freebies)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.FreeBiePMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldBenefit.benefitType == 'FREE_TEXT') {
			return (
				<>
					<CustomInputText
						name={`mtmbEligibility.${customName}.${props.index}.benefit.FreeTextType`}
						required={false}
						label={'Free Text Type'}
						smallText=""
					/>
					<CustomInputText
						name={`mtmbEligibility.${customName}.${props.index}.benefit.FreeTextMessage`}
						required={false}
						label={'Free Text Message'}
						smallText=""
					/>
				</>
			);
		}

		if (fieldBenefit.benefitType == 'EMI') {
			return (
				<>
					<CustomSelect
						name={`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationMethodCode`}
						required={false}
						label={
							isAuditFlw && customName == 'packageMultibuyEligibility' ? (
								<>
									(For EMI)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.EMI_PMMC_MBG}
					/>
				</>
			);
		}
	};

	const getContentBelowToPMMC = () => {
		if (
			fieldBenefit.priceModificationMethodCode == 'PERCENTAGE_OFF' ||
			fieldBenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF'
		) {
			if (fieldBenefit.benefitType == 'INSTANT') {
				return (
					<CustomInputText
						name={`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`}
						required={true}
						label={
							isAuditFlw && customName == 'packageMultibuyEligibility' ? (
								<>
									(For{' '}
									{fieldBenefit.benefitType == 'INSTANT'
										? 'Instant Discount'
										: 'Earn Burn'}{' '}
									&gt;{' '}
									{fieldBenefit.priceModificationMethodCode == 'PERCENTAGE_OFF'
										? 'Percentage Off'
										: 'Apportioned Percentage Off'}
									)<br />
									{localConst.priceModificationPercent}{' '}
								</>
							) : (
								localConst.priceModificationPercent
							)
						}
						smallText="Determines the benefit percent for the promotion"
						disabled={false}
					/>
				);
			} else if (fieldBenefit.benefitType == 'CASHBACK') {
				return (
					<>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`}
							required={true}
							label={
								isAuditFlw && customName == 'packageMultibuyEligibility' ? (
									<>
										(For Cashback &gt;{' '}
										{fieldBenefit.priceModificationMethodCode ==
										'PERCENTAGE_OFF'
											? 'Percentage Off'
											: 'Apportioned Percentage Off'}
										)<br />
										{localConst.priceModificationPercent}{' '}
									</>
								) : (
									localConst.priceModificationPercent
								)
							}
							smallText="Determines the benefit percent for the promotion"
							disabled={false}
						/>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.paymentInformation.paymentMode`}
							required={false}
							label={localConst.paymentMode}
							smallText="Defines the Payment eligibility Mode for which the promotion is applicable"
						/>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.paymentInformation.provider`}
							required={false}
							label={localConst.provider}
							smallText="Defines the Payment eligibility Provider for which the promotion is applicable"
						/>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.paymentInformation.paymentType`}
							required={false}
							label={localConst.paymentType}
							smallText="Defines the Payment eligibility Type for which the promotion is applicable"
						/>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.paymentInformation.paymentSubType`}
							required={false}
							label={localConst.paymentSubType}
							smallText="Defines the Payment eligibility SubType for which the promotion is applicable"
						/>

						<CustomSelect
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.creditBy.cashbackCreditData`}
							required={false}
							label="Credit By"
							options={getCollectionValidityDropdown()}
						/>

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.creditBy &&
						fieldBenefit.cashback.creditBy.cashbackCreditData == 'Date' ? (
							<>
								<CustomDateTime
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.creditBy.date`}
									required={true}
									label="Date(yyyy-mm-dd)"
									smallText={localConst.subCashBackCreditByDate}
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.creditBy ? (
								(fieldBenefit.cashback.creditBy.date = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.creditBy &&
						fieldBenefit.cashback.creditBy.cashbackCreditData == 'Months' ? (
							<>
								<CustomInputText
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.creditBy.months`}
									required={false}
									label="Months"
									smallText={localConst.subCashBackCreditByMonths}
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.creditBy ? (
								(fieldBenefit.cashback.creditBy.months = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.creditBy &&
						fieldBenefit.cashback.creditBy.cashbackCreditData == 'Days' ? (
							<>
								<CustomInputText
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.creditBy.days`}
									required={true}
									label="Days"
									smallText={localConst.subCashBackCreditByDays}
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.creditBy ? (
								(fieldBenefit.cashback.creditBy.days = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.creditBy &&
						fieldBenefit.cashback.creditBy.cashbackCreditData == 'Hours' ? (
							<>
								<CustomHoursPicker
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.creditBy.hours`}
									required={true}
									label="Hours(HH:mm)"
									smallText={localConst.subCashBackCreditByHours}
									valueHour={fieldBenefit.cashback.creditBy.hours}
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.creditBy ? (
								(fieldBenefit.cashback.creditBy.hours = undefined)
							) : null
						) : null}

						<CustomSelect
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.expireOn.cashbackExpireData`}
							required={false}
							label="Expire On"
							options={getCollectionValidityDropdown()}
						/>

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.expireOn &&
						fieldBenefit.cashback.expireOn.cashbackExpireData == 'Date' ? (
							<>
								<CustomDateTime
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.expireOn.date`}
									required={true}
									label="Date(yyyy-mm-dd)"
									smallText={localConst.subCashBackExpireOnDate}
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.expireOn ? (
								(fieldBenefit.cashback.expireOn.date = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.ExpireOn &&
						fieldBenefit.cashback.expireOn.cashbackExpireData == 'Months' ? (
							<>
								<CustomInputText
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.expireOn.months`}
									required={true}
									label="Months"
									smallText={localConst.subCashBackExpireOnMonths}
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.ExpireOn ? (
								(fieldBenefit.cashback.expireOn.months = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.expireOn &&
						fieldBenefit.cashback.expireOn.cashbackExpireData == 'Days' ? (
							<>
								<CustomInputText
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.expireOn.days`}
									required={true}
									label="Days"
									smallText={localConst.subCashBackExpireOnDays}
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.expireOn ? (
								(fieldBenefit.cashback.expireOn.days = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.expireOn &&
						fieldBenefit.cashback.expireOn.cashbackExpireData == 'Hours' ? (
							<>
								<CustomHoursPicker
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.expireOn.hours`}
									required={true}
									label="Hours(HH:mm)"
									smallText={localConst.subCashBackExpireOnHours}
									valueHour={fieldBenefit.cashback.expireOn.hours}
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.expireOn ? (
								(fieldBenefit.cashback.expireOn.hours = undefined)
							) : null
						) : null}
					</>
				);
			} else if (fieldBenefit.benefitType == 'FREEBIE') {
				return (
					<CustomInputText
						name={`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationPercent`}
						required={true}
						label={
							isAuditFlw && customName == 'packageMultibuyEligibility' ? (
								<>
									(For Freebies &gt; Percentage Off)
									<br />
									{localConst.priceModificationPercent}{' '}
								</>
							) : (
								localConst.priceModificationPercent
							)
						}
						smallText="Determines the benefit percent for the promotion"
						disabled={false}
						readOnly={true}
					/>
				);
			}
		} else {
			fieldBenefit.priceModificationPercent = undefined;
		}

		if (
			fieldBenefit.priceModificationMethodCode == 'AMOUNT_OFF' ||
			fieldBenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF'
		) {
			if (fieldBenefit.benefitType == 'INSTANT') {
				return (
					<CustomInputText
						name={`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationAmount`}
						required={true}
						label={
							isAuditFlw && customName == 'packageMultibuyEligibility' ? (
								<>
									(For{' '}
									{fieldBenefit.benefitType == 'INSTANT'
										? 'Instant Discount'
										: 'Earn Burn'}{' '}
									&gt;{' '}
									{fieldBenefit.priceModificationMethodCode == 'AMOUNT_OFF'
										? 'Amount Off'
										: 'Apportioned Amount Off'}
									)<br />
									{localConst.priceModificationAmount}{' '}
								</>
							) : (
								localConst.priceModificationAmount
							)
						}
						smallText="Define the benefit amount for the promotion"
						disabled={false}
					/>
				);
			} else if (fieldBenefit.benefitType == 'CASHBACK') {
				return (
					<>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${props.index}.benefit.priceModificationAmount`}
							required={true}
							label={
								isAuditFlw && customName == 'packageMultibuyEligibility' ? (
									<>
										(For Cashback &gt;{' '}
										{fieldBenefit.priceModificationMethodCode == 'AMOUNT_OFF'
											? 'Amount Off'
											: 'Apportioned Amount Off'}
										)<br />
										{localConst.priceModificationAmount}{' '}
									</>
								) : (
									localConst.priceModificationAmount
								)
							}
							smallText="Define the benefit amount for the promotion"
							disabled={false}
						/>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.paymentInformation.paymentMode`}
							required={false}
							label={localConst.paymentMode}
							smallText="Defines the Payment eligibility Mode for which the promotion is applicable"
						/>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.paymentInformation.provider`}
							required={false}
							label={localConst.provider}
							smallText="Defines the Payment eligibility Provider for which the promotion is applicable"
						/>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.paymentInformation.paymentType`}
							required={false}
							label={localConst.paymentType}
							smallText="Defines the Payment eligibility Type for which the promotion is applicable"
						/>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.paymentInformation.paymentSubType`}
							required={false}
							label={localConst.paymentSubType}
							smallText="Defines the Payment eligibility SubType for which the promotion is applicable"
						/>

						<CustomSelect
							name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.creditBy.cashbackCreditData`}
							required={false}
							label="Credit By"
							options={getCollectionValidityDropdown()}
						/>

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.creditBy &&
						fieldBenefit.cashback.creditBy.cashbackCreditData == 'Date' ? (
							<>
								<CustomDateTime
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.creditBy.date`}
									required={true}
									label="Date(yyyy-mm-dd)"
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.creditBy ? (
								(fieldBenefit.cashback.creditBy.date = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.creditBy &&
						fieldBenefit.cashback.creditBy.cashbackCreditData == 'Months' ? (
							<>
								<CustomInputText
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.creditBy.months`}
									required={true}
									label="Months"
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.creditBy ? (
								(fieldBenefit.cashback.creditBy.months = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.creditBy &&
						fieldBenefit.cashback.creditBy.cashbackCreditData == 'Days' ? (
							<>
								<CustomInputText
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.creditBy.days`}
									required={true}
									label="Days"
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.creditBy ? (
								(fieldBenefit.cashback.creditBy.days = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.creditBy &&
						fieldBenefit.cashback.creditBy.cashbackCreditData == 'Hours' ? (
							<>
								<CustomHoursPicker
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.creditBy.hours`}
									required={true}
									label="Hours(HH:mm)"
									valueHour={fieldBenefit.cashback.creditBy.hours}
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.creditBy ? (
								(fieldBenefit.cashback.creditBy.hours = undefined)
							) : null
						) : null}

						<CustomSelect
							name="cashback.expireOn.cashbackExpireData"
							required={false}
							label="Expire On"
							options={getCollectionValidityDropdown()}
						/>

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.expireOn &&
						fieldBenefit.cashback.expireOn.cashbackExpireData == 'Date' ? (
							<>
								<CustomDateTime
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.expireOn.date`}
									required={true}
									label="Date(yyyy-mm-dd)"
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.expireOn ? (
								(fieldBenefit.cashback.expireOn.date = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.ExpireOn &&
						fieldBenefit.cashback.expireOn.cashbackExpireData == 'Months' ? (
							<>
								<CustomInputText
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.expireOn.months`}
									required={true}
									label="Months"
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.ExpireOn ? (
								(fieldBenefit.cashback.expireOn.months = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.expireOn &&
						fieldBenefit.cashback.expireOn.cashbackExpireData == 'Days' ? (
							<>
								<CustomInputText
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.expireOn.days`}
									required={true}
									label="Days"
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.expireOn ? (
								(fieldBenefit.cashback.expireOn.days = undefined)
							) : null
						) : null}

						{fieldBenefit.cashback &&
						fieldBenefit.cashback.expireOn &&
						fieldBenefit.cashback.expireOn.cashbackExpireData == 'Hours' ? (
							<>
								<CustomHoursPicker
									name={`mtmbEligibility.${customName}.${props.index}.benefit.cashback.expireOn.hours`}
									required={true}
									label="Hours(HH:mm)"
									valueHour={fieldBenefit.cashback.expireOn.hours}
								/>
							</>
						) : fieldBenefit.cashback ? (
							fieldBenefit.cashback.expireOn ? (
								(fieldBenefit.cashback.expireOn.hours = undefined)
							) : null
						) : null}
					</>
				);
			}
		} else {
			fieldBenefit.priceModificationAmount = undefined;
		}

		if (
			fieldBenefit.priceModificationMethodCode == 'MARKDOWN' ||
			fieldBenefit.priceModificationMethodCode == 'KIT_MARKDOWN'
		) {
			return (
				<CustomInputText
					name={`mtmbEligibility.${customName}.${props.index}.benefit.newPriceAmount`}
					required={true}
					label={
						isAuditFlw && customName == 'packageMultibuyEligibility' ? (
							<>
								(For{' '}
								{fieldBenefit.benefitType == 'INSTANT'
									? 'Instant Discount'
									: 'Earn Burn'}{' '}
								&gt;{' '}
								{fieldBenefit.priceModificationMethodCode == 'MARKDOWN'
									? 'Markdown'
									: 'Kit Markdown'}
								)<br />
								{localConst.newPriceAmount}{' '}
							</>
						) : (
							localConst.newPriceAmount
						)
					}
					smallText="Determines the new price for the product/sku in the promotion"
					disabled={false}
				/>
			);
		} else {
			fieldBenefit.newPriceAmount = undefined;
		}

		if (
			fieldBenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
			fieldBenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
			fieldBenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE'
		) {
			return (
				<>
					<CustomInputText
						name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.LoyaltyAdditionalPoint`}
						required={true}
						label={'Loyalty Additional Point'}
						smallText="Determines the additional point as benefit for the promotion"
					/>
					<CustomInputRadio
						name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.BaseEarnPointEligibility`}
						label={'Apply Base Earn'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={
							'Determines whether the Base earn Point should be applied for the promotion'
						}
					/>
					<CustomInputRadio
						name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ConsiderBaseRateForItem`}
						label={'Consider Base Rate For Item'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={'Determines whether to Consider Base Rate For Item'}
					/>

					<CustomSelect
						name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ExpireOn.LoyaltyExpireData`}
						required={false}
						label="Expire On"
						options={getCollectionValidityDropdown()}
					/>

					{fieldBenefit.Loyalty &&
					fieldBenefit.Loyalty.ExpireOn &&
					fieldBenefit.Loyalty.ExpireOn.LoyaltyExpireData == 'Date' ? (
						<>
							<CustomDateTime
								name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ExpireOn.date`}
								required={true}
								label="Date(yyyy-mm-dd)"
							/>
						</>
					) : fieldBenefit.Loyalty ? (
						fieldBenefit.Loyalty.ExpireOn ? (
							(fieldBenefit.Loyalty.ExpireOn.date = undefined)
						) : null
					) : null}

					{fieldBenefit.Loyalty &&
					fieldBenefit.Loyalty.ExpireOn &&
					fieldBenefit.Loyalty.ExpireOn.LoyaltyExpireData == 'Months' ? (
						<>
							<CustomInputText
								name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ExpireOn.months`}
								required={true}
								label="Months"
							/>
						</>
					) : fieldBenefit.Loyalty ? (
						fieldBenefit.Loyalty.ExpireOn ? (
							(fieldBenefit.Loyalty.ExpireOn.months = undefined)
						) : null
					) : null}

					{fieldBenefit.Loyalty &&
					fieldBenefit.Loyalty.ExpireOn &&
					fieldBenefit.Loyalty.ExpireOn.LoyaltyExpireData == 'Days' ? (
						<>
							<CustomInputText
								name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ExpireOn.dayst`}
								required={true}
								label="Days"
							/>
						</>
					) : fieldBenefit.Loyalty ? (
						fieldBenefit.Loyalty.ExpireOn ? (
							(fieldBenefit.Loyalty.ExpireOn.days = undefined)
						) : null
					) : null}

					{fieldBenefit.Loyalty &&
					fieldBenefit.Loyalty.ExpireOn &&
					fieldBenefit.Loyalty.ExpireOn.LoyaltyExpireData == 'Hours' ? (
						<>
							<CustomHoursPicker
								name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ExpireOn.hours`}
								required={true}
								label="Hours(HH:mm)"
								valueHour={fieldBenefit.Loyalty.ExpireOn.hours}
							/>
						</>
					) : fieldBenefit.Loyalty ? (
						fieldBenefit.Loyalty.ExpireOn ? (
							(fieldBenefit.Loyalty.ExpireOn.hours = undefined)
						) : null
					) : null}
				</>
			);
		} else {
			fieldBenefit.newPriceAmount = undefined;
		}

		if (
			fieldBenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
			fieldBenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
			fieldBenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE'
		) {
			return (
				<>
					<CustomInputText
						name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.loyaltyMultiplier`}
						required={true}
						label={'Loyalty Multiplier Point'}
						smallText="Determines the multiplier point as benefit for the promotion"
					/>
					<CustomInputRadio
						name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.BaseEarnPointEligibility`}
						label={'Apply Base Earn'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={
							'Determines whether the Base earn Point should be applied for the promotion'
						}
					/>
					<CustomInputRadio
						name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ConsiderBaseRateForItem`}
						label={'Consider Base Rate For Item'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={'Determines whether to Consider Base Rate For Item'}
					/>

					<CustomSelect
						name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ExpireOn.LoyaltyExpireData`}
						required={false}
						label="Expire On"
						options={getCollectionValidityDropdown()}
					/>

					{fieldBenefit.Loyalty &&
					fieldBenefit.Loyalty.ExpireOn &&
					fieldBenefit.Loyalty.ExpireOn.LoyaltyExpireData == 'Date' ? (
						<>
							<CustomDateTime
								name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ExpireOn.date`}
								required={true}
								label="Date(yyyy-mm-dd)"
							/>
						</>
					) : fieldBenefit.Loyalty ? (
						fieldBenefit.Loyalty.ExpireOn ? (
							(fieldBenefit.Loyalty.ExpireOn.date = undefined)
						) : null
					) : null}

					{fieldBenefit.Loyalty &&
					fieldBenefit.Loyalty.ExpireOn &&
					fieldBenefit.Loyalty.ExpireOn.LoyaltyExpireData == 'Months' ? (
						<>
							<CustomInputText
								name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ExpireOn.months`}
								required={true}
								label="Months"
							/>
						</>
					) : fieldBenefit.Loyalty ? (
						fieldBenefit.Loyalty.ExpireOn ? (
							(fieldBenefit.Loyalty.ExpireOn.months = undefined)
						) : null
					) : null}

					{fieldBenefit.Loyalty &&
					fieldBenefit.Loyalty.ExpireOn &&
					fieldBenefit.Loyalty.ExpireOn.LoyaltyExpireData == 'Days' ? (
						<>
							<CustomInputText
								name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ExpireOn.dayst`}
								required={true}
								label="Days"
							/>
						</>
					) : fieldBenefit.Loyalty ? (
						fieldBenefit.Loyalty.ExpireOn ? (
							(fieldBenefit.Loyalty.ExpireOn.days = undefined)
						) : null
					) : null}

					{fieldBenefit.Loyalty &&
					fieldBenefit.Loyalty.ExpireOn &&
					fieldBenefit.Loyalty.ExpireOn.LoyaltyExpireData == 'Hours' ? (
						<>
							<CustomHoursPicker
								name={`mtmbEligibility.${customName}.${props.index}.benefit.Loyalty.ExpireOn.hours`}
								required={true}
								label="Hours(HH:mm)"
								valueHour={fieldBenefit.Loyalty.ExpireOn.hours}
							/>
						</>
					) : fieldBenefit.Loyalty ? (
						fieldBenefit.Loyalty.ExpireOn ? (
							(fieldBenefit.Loyalty.ExpireOn.hours = undefined)
						) : null
					) : null}
				</>
			);
		} else {
			fieldBenefit.newPriceAmount = undefined;
		}
	};

	return (
		<div>
			<Form>
				<CustomSelect
					name={`mtmbEligibility.${customName}.${props.index}.benefit.benefitType`}
					required={false}
					label={localConst.benefitType}
					options={getBenefitTypeDropdown()}
					smallText={itemHelp.BenifitTypeMTMB}
				/>

				{getContentBelowToBenefitType()}
			</Form>
		</div>
	);
};

export default BenefitSettings;
