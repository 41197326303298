import React from 'react';
import CustomSelect from './customfields/CustomSelect';

function InclusionsTypeSCALE(props) {
	const inclusionTypeOptions = [
		{
			value: '',
			name: 'Select one...'
		},
		{
			value: 'PRODUCT_INCLUSION_VALUE',
			name: 'Product Inclusions'
		}
	];

	//Local const

	const getThresholdDropdown = (inclusionTypeOptions) => {
		return inclusionTypeOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	return (
		<div className="container-fluid">
			<div className="d-flex justify-content-center">
				<CustomSelect
					name="scaledPriceEligibility.inclusionType"
					required={true}
					label="Inclusion Type"
					options={getThresholdDropdown(inclusionTypeOptions)}
					className="customDropdown inlinePadding bg-position"
				/>
			</div>
		</div>
	);
}

export default InclusionsTypeSCALE;
