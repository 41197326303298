import React, { useEffect } from 'react';

import { useField } from 'formik';

import CustomInputText from '../customfields/CustomInputText';
import CustomSelect from '../customfields/CustomSelect';
import '../BasicDetails.scss';

function ConditionalRedering(props) {
	const [field] = useField(props);

	const localConst = {
		eligibilitySettings: 'Eligibility Settings',
		thresholdCode: 'Threshold Type Code',
		triggerCombinationCode: 'Trigger Combination Code',
		subTriggerCombinationCode:
			'Determines the combination code to be used between the trigger items for a promotion',
		thresholdQuantity: 'Threshold Quantity',
		thresholdAmount: 'Threshold Amount',
		rewardCombinationCode: 'Reward Combination Code',
		subRewardCombinationCode:
			'Determines the combination code to be used between the reward item for a promotion',
		rewardQuantity: 'Reward Quantity',
		benefitSettings: 'Benefit Settings',
		ProductSetting: 'Product Settings',
		quantityLimit: 'Quantity Limit',
		groupMaximumThresholdAmount: 'Maximum Threshold Amount',
		groupMaximumThresholdQuantity: 'Maximum Threshold Quantity'
	};

	const rewardCombinationDropdown = {
		promotionTypeOr: [
			{
				value: 'OR',
				name: 'OR'
			}
		],
		promotionTypeOrAndEqual: [
			{
				value: 'OR',
				name: 'OR'
			},
			{
				value: 'AND',
				name: 'AND'
			},
			{
				value: 'EQUAL',
				name: 'EQUAL'
			}
		],
		promotionTypeOrAnd: [
			{
				value: 'OR',
				name: 'OR'
			},
			{
				value: 'AND',
				name: 'AND'
			}
		],
		operator: [
			{
				value: '',
				name: 'Select one'
			},
			{
				value: '<',
				name: '<'
			},
			{
				value: '>',
				name: '>'
			},
			{
				value: '<=',
				name: '<='
			},
			{
				value: '>=',
				name: '>='
			},
			{
				value: '=',
				name: '='
			},
			{
				value: 'BETWEEN',
				name: 'BETWEEN'
			}
		]
	};

	const groupMultibuyEligbility = field.value.groupMultibuyEligbility;

	const getThresholdDropdown = (dropdownOptions) => {
		return dropdownOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	useEffect(() => {
		if (!groupMultibuyEligbility.groupTriggerCombinationCode) {
			groupMultibuyEligbility.groupTriggerCombinationCode = 'OR';
		}
		if (!groupMultibuyEligbility.groupRewardCombinationCode) {
			groupMultibuyEligbility.groupRewardCombinationCode = 'OR';
		}
		if (!groupMultibuyEligbility.groupRewardQuantity) {
			groupMultibuyEligbility.groupRewardQuantity = 1;
		}

		if (
			groupMultibuyEligbility.thresholdTypeCode == 'GROUP_INTERVAL_QTY_THRESHOLD' ||
			groupMultibuyEligbility.thresholdTypeCode == 'GROUP_QTY_THRESHOLD'
		) {
			if (!groupMultibuyEligbility.groupThresholdQuantity) {
				groupMultibuyEligbility.groupThresholdQuantity = 1;
			}
			groupMultibuyEligbility.quantityLimit = undefined;
			//groupMultibuyEligbility.groupMaximumThresholdQuantity = undefined;
			groupMultibuyEligbility.groupMaximumThresholdAmount = undefined;
			groupMultibuyEligbility.groupThresholdAmount = undefined;
		}
		if (groupMultibuyEligbility.thresholdTypeCode == 'GROUP_LIMIT_QTY_THRESHOLD') {
			if (!groupMultibuyEligbility.groupThresholdQuantity) {
				groupMultibuyEligbility.groupThresholdQuantity = 1;
			}
			// groupMultibuyEligbility.quantityLimit = undefined;
			groupMultibuyEligbility.groupMaximumThresholdQuantity = undefined;
			groupMultibuyEligbility.groupMaximumThresholdAmount = undefined;
			groupMultibuyEligbility.groupThresholdAmount = undefined;
		}

		if (groupMultibuyEligbility.thresholdTypeCode == 'GROUP_RANGE_QTY_THRESHOLD') {
			if (!groupMultibuyEligbility.groupThresholdQuantity) {
				groupMultibuyEligbility.groupThresholdQuantity = 1;
			}
			groupMultibuyEligbility.quantityLimit = undefined;
			//groupMultibuyEligbility.groupMaximumThresholdQuantity = undefined;
			groupMultibuyEligbility.groupMaximumThresholdAmount = undefined;
		}
		if (
			groupMultibuyEligbility.thresholdTypeCode == 'GROUP_INTERVAL_AMOUNT_THRESHOLD' ||
			groupMultibuyEligbility.thresholdTypeCode == 'GROUP_AMOUNT_THRESHOLD'
		) {
			if (!groupMultibuyEligbility.groupThresholdAmount) {
				groupMultibuyEligbility.groupThresholdAmount = 0.01;
			}
			groupMultibuyEligbility.quantityLimit = undefined;
			groupMultibuyEligbility.groupMaximumThresholdQuantity = undefined;
			groupMultibuyEligbility.groupMaximumThresholdAmount = undefined;
			groupMultibuyEligbility.groupThresholdQuantity = undefined;
		}
		if (groupMultibuyEligbility.thresholdTypeCode == 'GROUP_RANGE_AMOUNT_THRESHOLD') {
			if (!groupMultibuyEligbility.groupThresholdAmount) {
				groupMultibuyEligbility.groupThresholdAmount = 0.01;
			}
			groupMultibuyEligbility.quantityLimit = undefined;
			groupMultibuyEligbility.groupMaximumThresholdQuantity = undefined;
			//groupMultibuyEligbility.groupMaximumThresholdAmount = undefined;
			groupMultibuyEligbility.groupThresholdQuantity = undefined;
		}
		if (groupMultibuyEligbility.thresholdTypeCode == 'GROUP_LIMIT_QTY_THRESHOLD') {
			//debugger
			if (!groupMultibuyEligbility.quantityLimit) {
				//debugger
				groupMultibuyEligbility.quantityLimit = 1;
			}
		}
		if (groupMultibuyEligbility.thresholdTypeCode == 'GROUP_RANGE_QTY_THRESHOLD') {
			if (!groupMultibuyEligbility.groupMaximumThresholdQuantity) {
				groupMultibuyEligbility.groupMaximumThresholdQuantity = 999999;
			}
		}
		if (groupMultibuyEligbility.thresholdTypeCode == 'GROUP_RANGE_AMOUNT_THRESHOLD') {
			if (!groupMultibuyEligbility.groupMaximumThresholdAmount) {
				groupMultibuyEligbility.groupMaximumThresholdAmount = 999999.99;
			}
		}
	}, [groupMultibuyEligbility.thresholdTypeCode]);
	useEffect(() => {
		if (
			groupMultibuyEligbility.triggerOperator == '<' ||
			groupMultibuyEligbility.triggerOperator == '>' ||
			groupMultibuyEligbility.triggerOperator == '<=' ||
			groupMultibuyEligbility.triggerOperator == '>=' ||
			groupMultibuyEligbility.triggerOperator == '='
		) {
			groupMultibuyEligbility.triggerOperatorValueTo = undefined;
			groupMultibuyEligbility.triggerOperatorValueFrom = undefined;
		} else if (groupMultibuyEligbility.triggerOperator == 'BETWEEN') {
			groupMultibuyEligbility.triggerOperatorValue = undefined;
		} else {
			groupMultibuyEligbility.triggerOperatorValueTo = undefined;
			groupMultibuyEligbility.triggerOperatorValueFrom = undefined;
			groupMultibuyEligbility.triggerOperatorValue = undefined;
		}
	}, [groupMultibuyEligbility.triggerOperator]);

	useEffect(() => {
		if (
			groupMultibuyEligbility.rewardOperator == '<' ||
			groupMultibuyEligbility.rewardOperator == '>' ||
			groupMultibuyEligbility.rewardOperator == '<=' ||
			groupMultibuyEligbility.rewardOperator == '>=' ||
			groupMultibuyEligbility.rewardOperator == '='
		) {
			groupMultibuyEligbility.rewardOperatorValueTo = undefined;
			groupMultibuyEligbility.rewardOperatorValueFrom = undefined;
		} else if (groupMultibuyEligbility.rewardOperator == 'BETWEEN') {
			groupMultibuyEligbility.rewardOperatorValue = undefined;
		} else {
			groupMultibuyEligbility.rewardOperatorValueTo = undefined;
			groupMultibuyEligbility.rewardOperatorValueFrom = undefined;
			groupMultibuyEligbility.rewardOperatorValue = undefined;
		}
	}, [groupMultibuyEligbility.rewardOperator]);
	//html
	return (
		<div className="purchaseContainer">
			<div className="purchaseRow">
				Purchase Conditions
				<CustomSelect
					name="groupMultibuyEligbility.groupTriggerCombinationCode"
					required={true}
					label={localConst.triggerCombinationCode}
					className="customDropdown inlinePadding bg-position"
					options={
						groupMultibuyEligbility.thresholdTypeCode ==
							'GROUP_INTERVAL_QTY_THRESHOLD' ||
						groupMultibuyEligbility.thresholdTypeCode == 'GROUP_RANGE_QTY_THRESHOLD'
							? getThresholdDropdown(
									rewardCombinationDropdown.promotionTypeOrAndEqual
							  )
							: getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)
					}
					smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{groupMultibuyEligbility.thresholdTypeCode == 'GROUP_INTERVAL_AMOUNT_THRESHOLD' ||
				groupMultibuyEligbility.thresholdTypeCode == 'GROUP_AMOUNT_THRESHOLD' ||
				groupMultibuyEligbility.thresholdTypeCode == 'GROUP_RANGE_AMOUNT_THRESHOLD' ? (
					<CustomInputText
						name="groupMultibuyEligbility.groupThresholdAmount"
						required={true}
						label={localConst.thresholdAmount}
						smallText=""
						disabled={false}
					/>
				) : (
					<CustomInputText
						name="groupMultibuyEligbility.groupThresholdQuantity"
						required={true}
						label={localConst.thresholdQuantity}
						smallText=""
						disabled={false}
					/>
				)}
				{groupMultibuyEligbility.thresholdTypeCode == 'GROUP_LIMIT_QTY_THRESHOLD' ||
				groupMultibuyEligbility.thresholdTypeCode == 'GROUP_RANGE_QTY_THRESHOLD' ||
				groupMultibuyEligbility.thresholdTypeCode == 'GROUP_RANGE_AMOUNT_THRESHOLD' ? (
					groupMultibuyEligbility.thresholdTypeCode == 'GROUP_LIMIT_QTY_THRESHOLD' ? (
						<CustomInputText
							name="groupMultibuyEligbility.quantityLimit"
							required={true}
							label={localConst.quantityLimit}
							smallText=""
							disabled={false}
						/>
					) : groupMultibuyEligbility.thresholdTypeCode == 'GROUP_RANGE_QTY_THRESHOLD' ? (
						<CustomInputText
							name="groupMultibuyEligbility.groupMaximumThresholdQuantity"
							required={true}
							label={localConst.groupMaximumThresholdQuantity}
							smallText=""
							disabled={false}
						/>
					) : (
						<CustomInputText
							name="groupMultibuyEligbility.groupMaximumThresholdAmount"
							required={true}
							label={localConst.groupMaximumThresholdAmount}
							smallText=""
							disabled={false}
						/>
					)
				) : undefined}
				<CustomSelect
					name="groupMultibuyEligbility.triggerOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{groupMultibuyEligbility.triggerOperator == '<' ||
				groupMultibuyEligbility.triggerOperator == '>' ||
				groupMultibuyEligbility.triggerOperator == '<=' ||
				groupMultibuyEligbility.triggerOperator == '>=' ||
				groupMultibuyEligbility.triggerOperator == '=' ? (
					<CustomInputText
						name="groupMultibuyEligbility.triggerOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{groupMultibuyEligbility.triggerOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="groupMultibuyEligbility.triggerOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="groupMultibuyEligbility.triggerOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>

			<div className="purchaseRow">
				Reward Conditions
				<CustomSelect
					name="groupMultibuyEligbility.groupRewardCombinationCode"
					required={true}
					label={localConst.rewardCombinationCode}
					options={
						groupMultibuyEligbility.thresholdTypeCode ==
							'GROUP_INTERVAL_QTY_THRESHOLD' ||
						groupMultibuyEligbility.thresholdTypeCode == 'GROUP_RANGE_QTY_THRESHOLD'
							? getThresholdDropdown(
									rewardCombinationDropdown.promotionTypeOrAndEqual
							  )
							: groupMultibuyEligbility.thresholdTypeCode ==
									'GROUP_RANGE_AMOUNT_THRESHOLD' ||
							  groupMultibuyEligbility.thresholdTypeCode ==
									'GROUP_INTERVAL_AMOUNT_THRESHOLD'
							? getThresholdDropdown(rewardCombinationDropdown.promotionTypeOrAnd)
							: getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)
					}
					smallText={localConst.subRewardCombinationCode}
					className="customDropdown inlinePadding"
				/>
				<CustomInputText
					name="groupMultibuyEligbility.groupRewardQuantity"
					required={true}
					label={localConst.rewardQuantity}
					smallText=""
					disabled={false}
				/>
				<CustomSelect
					name="groupMultibuyEligbility.rewardOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{groupMultibuyEligbility.rewardOperator == '<' ||
				groupMultibuyEligbility.rewardOperator == '>' ||
				groupMultibuyEligbility.rewardOperator == '<=' ||
				groupMultibuyEligbility.rewardOperator == '>=' ||
				groupMultibuyEligbility.rewardOperator == '=' ? (
					<CustomInputText
						name="groupMultibuyEligbility.rewardOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{groupMultibuyEligbility.rewardOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="groupMultibuyEligbility.rewardOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="groupMultibuyEligbility.rewardOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>
		</div>
	);
}
export default ConditionalRedering;
