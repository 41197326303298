import React, { Component } from 'react';
import resetIcon from './../../assets/images/undo.png';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import { withRouter } from 'react-router-dom';
import './couponTable.scss';
import appLabels from '../../utils/appLabels';
import { FormInput } from './SearchOffer';

const { common: commonLabels } = appLabels;

export class CouponSearch extends Component {
	initialState = {
		searchForm: {
			promotionID: {
				elementType: 'text',
				elementConfig: {
					name: 'promotionID',
					type: 'text',
					placeholder: 'Enter Promotion ID',
					labelname: 'Promotion ID'
				},
				value: '',
				validation: {
					// required: true
					// maxLength: 10
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			},
			couponID: {
				elementType: 'text',
				elementConfig: {
					name: 'couponID',
					type: 'text',
					placeholder: 'Enter Coupon ID',
					labelname: 'Coupon ID'
				},
				value: '',
				validation: {
					// required: true
					// maxLength: 10
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			},
			couponType: {
				elementType: 'select',
				elementConfig: {
					name: 'couponType',
					type: '',
					placeholder: 'Enter Coupon Type',
					labelname: 'Coupon Type',
					options: [
						{ value: 'ALL', displayValue: 'ALL' },
						{ value: 'GENERIC', displayValue: 'Generic' },
						{
							value: 'PERSONALIZED',
							displayValue: 'Personalized'
						},
						{
							value: 'TARGETED',
							displayValue: 'Targeted'
						}
					]
				},
				value: 'GENERIC',
				validation: {
					required: false
				},

				validationMessage: '',
				valid: false,
				touched: false
			},
			couponStatus: {
				elementType: 'select',
				elementConfig: {
					name: 'couponStatus',
					type: '',
					placeholder: 'Enter Coupon Status',
					labelname: 'Coupon Status',
					options: [
						{ value: 'ALL', displayValue: 'ALL' },
						{ value: 'A', displayValue: 'Active' },
						{ value: 'D', displayValue: 'In Active' }
					]
				},
				value: 'ALL',
				validation: {
					required: false
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			couponExpiryDateTime: {
				elementType: 'dateTime',
				elementConfig: {
					name: 'couponExpiryDateTime',
					type: 'text',
					placeholder: 'Enter Coupon Expiry Date & Time',
					labelname: 'Coupon Expiry Date & Time'
				},
				value: '',
				validation: {
					required: false
				},
				validationMessage: '',
				valid: false,
				touched: false
			}
		},
		formIsValid: false
	};

	state = this.initialState;

	handleFormReset = () => {
		this.setState(() => this.initialState);
		this.setState((prevState) => {
			let previousState = { ...prevState };
			previousState.searchForm.couponType.value = 'GENERIC';
			return previousState;
		});
	};

	inputChangedHandler = (value, isValidObj, inputIdentifier, dateTimeRangeChanged) => {
		const updatedLoginForm = { ...this.state.searchForm };
		const updatedFormElement = { ...updatedLoginForm[inputIdentifier] };

		updatedFormElement.value = value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;

		updatedLoginForm[inputIdentifier] = updatedFormElement;
		if (dateTimeRangeChanged) {
			// const updatedFormStartDateTimeElement = {
			// 	...updatedLoginForm['modifiedStartDateTime']
			// };
			const updatedExpiryDateTime = { ...updatedLoginForm['couponExpiryDateTime'] };
			if (!moment(value, 'DD-MM-YYYY HH:mm', true).isValid()) {
				updatedExpiryDateTime.valid = false;
				updatedExpiryDateTime.validationMessage = 'Invalid date format';
			} else {
				updatedExpiryDateTime.valid = true;
				updatedExpiryDateTime.validationMessage = '';
			}
			// if (updatedFormStartDateTimeElement.value && updatedFormEndDateTimeElement.value) {
			// 	if (
			// 		moment(updatedFormStartDateTimeElement.value) >
			// 		moment(updatedFormEndDateTimeElement.value)
			// 	) {
			// 		updatedFormEndDateTimeElement.valid = false;
			// 		updatedFormEndDateTimeElement.validationMessage = 'Invalid date time range';
			// 	} else {
			// 		updatedFormEndDateTimeElement.valid = isValidObj.value;
			// 		updatedFormEndDateTimeElement.validationMessage = isValidObj.message;
			// 	}
			// }

			updatedLoginForm['couponExpiryDateTime'] = updatedExpiryDateTime;
		}
		// let formIsValid = true;
		// for (let inputIdentifier in updatedLoginForm) {
		// 	formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
		// }
		this.setState({ searchForm: updatedLoginForm });
	};

	onInputChange = (event, definedRules, inputIdentifier, validCondition = null) => {
		let value = event.target.value;
		if (inputIdentifier == 'couponID' && value) {
			let searchForm = { ...this.state.searchForm };
			searchForm.couponType.value = 'ALL';
			this.setState({
				searchForm
			});
		}
		if (inputIdentifier == 'couponID' && !value) {
			let searchForm = { ...this.state.searchForm };
			searchForm.couponType.value = 'GENERIC';
			this.setState({
				searchForm
			});
		}
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value.trim() !== '';
				isValid.message = isValid.value ? '' : 'Required';
				/* this is validCondition of the dependent input */
				if (validCondition && isValid.value === false) {
					if (validCondition.value.trim() !== '') {
						isValid = { value: true, message: '' };
					} else {
						isValid = { value: false, message: 'Required' };
					}
				}
			}

			if (rules.minLength) {
				isValid.value = value.length >= rules.minLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Minimum length is ' + rules.minLength;
			}

			if (rules.maxLength) {
				isValid.value = value.length <= rules.maxLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Maximum length is ' + rules.maxLength;
			}

			if (rules.isEmail && value) {
				let regEx = new RegExp(
					/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid email';
			}

			if (rules.isPhoneNumber && value) {
				let regEx = new RegExp(
					/^\+?(\d+|(\(\d+\))+)+((([ ]?(\d+|(\(\d+\))+)+)*)|((\-\d+)*)|((\.\d+)*))*$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid phone number';
			}

			if (rules.isAlphabets && value) {
				let regEx = new RegExp('^[a-zA-Z]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets';
			}

			if (rules.isAlphaNumeric && value) {
				let regEx = new RegExp('^[a-zA-Z0-9]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets and numbers';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, false);
	};

	onDateChange = (startDate, definedRules, inputIdentifier) => {
		let value = startDate;
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value !== '';
				isValid.message = isValid.value ? '' : 'Field required';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, true);
	};

	// isFormValid = (formElementsArray) => {
	// 	return formElementsArray[0].config.valid || formElementsArray[1].config.valid;
	// };

	render() {
		const formElementsArray = [];
		for (let key in this.state.searchForm) {
			formElementsArray.push({
				id: key,
				config: this.state.searchForm[key]
			});
		}
		return (
			<form
				className="flex-grow-1 d-flex flex-column"
				onSubmit={(event) => {
					event.preventDefault();
				}}
				// onChange={(event) => {
					// setTimeout(() => {
					// 	const eventName = event.target.name;
					// 	const searchCouponForm = { ...this.state.searchForm };
					// 	const { promotionID, couponID } = searchCouponForm;
					// 	/* setting the other filed while change on eventName */
					// 	if (eventName === 'promotionID') {
					// 		if (promotionID.valid) {
					// 			couponID.valid = true;
					// 			couponID.validationMessage = '';
					// 		} else if (couponID.value.trim() === '') {
					// 			/* if promotionID is invalid check the value of couponID */
					// 			couponID.valid = false;
					// 			couponID.validationMessage = 'Required';
					// 		}
					// 		searchCouponForm['couponID'] = couponID;
					// 	}

					// 	if (eventName === 'couponID') {
					// 		if (couponID.valid) {
					// 			promotionID.valid = true;
					// 			promotionID.validationMessage = '';
					// 		} else if (promotionID.value.trim() === '') {
					// 			/* if couponID is invalid check the value of promotionID */
					// 			promotionID.valid = false;
					// 			promotionID.validationMessage = 'Required';
					// 		}
					// 		searchCouponForm['promotionID'] = promotionID;
					// 	}

					// 	this.setState({ searchForm: searchCouponForm });
					// });
				// }}
				>
				<div className="modal-body">
					<div className="row p-4">
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[0]}
								onInputChange={(event, definedRules, inputIdentifier) => {
									this.onInputChange(
										event,
										definedRules,
										inputIdentifier,
										formElementsArray[1].config
									);
								}}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[1]}
								onInputChange={(event, definedRules, inputIdentifier) => {
									this.onInputChange(
										event,
										definedRules,
										inputIdentifier,
										formElementsArray[0].config
									);
								}}
							/>
						</div>
					</div>
					<div className="row p-4">
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[2]}
								onInputChange={this.onInputChange}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[3]}
								onInputChange={this.onInputChange}
							/>
						</div>
					</div>
					<div className="row p-4">
						<div className="col-md-6 d-flex flex-column">
							<FormInput
								formElementsArray={formElementsArray[4]}
								onInputChange={this.onDateChange}
								onViewModeChange={() => {
									if (formElementsArray[4].config.value == '') {
										formElementsArray[4].config.value = new Date();
									}
								}}
								clearBtnClick={() => {
									this.setState((prevState) => {
										let previousState = { ...prevState };
										previousState.searchForm.couponExpiryDateTime.value = '';
										previousState.searchForm.couponExpiryDateTime.valid = true;
										previousState.searchForm.couponExpiryDateTime.validationMessage =
											'';
										return previousState;
									});
								}}
							/>
						</div>
					</div>
				</div>
				<div className="modal-footer border-0">
					<p
						onClick={() => {
							this.handleFormReset();
						}}
						className="grey-button roboto-b-16">
						<img src={resetIcon} alt="img" /> {commonLabels.reset}
					</p>
					<button
						onClick={() => this.props.handleSelection(false)}
						className="cancel-btn roboto-b-16 ml-auto">
						{commonLabels.cancel}
					</button>
					<button
						className='btnNxtPrev ml-2 roboto-b-16'
						onClick={() => {
							this.props.handleSearch(this.state.searchForm);
						}}>
						{commonLabels.search}
					</button>
				</div>
			</form>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val })
	};
};

export default connect(null, mapDispatchToProps)(withRouter(CouponSearch));
