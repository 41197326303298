import React from 'react';
import { Modal } from 'react-bootstrap';

export class InformationModal extends React.Component {
	render() {
		return (
			<div>
				<Modal
					show={this.props.show}
					onHide={this.props.onHide}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					{/*  <Modal.Header closeButton > */}
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							{this.props.infoTitle}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>{this.props.infoMsg}</p>
					</Modal.Body>
					<Modal.Footer>
						<button className="btnNxtPrev ml-2 roboto-b-16" onClick={this.props.onHide}>
							Ok
						</button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default InformationModal;
