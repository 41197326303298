import React, { useEffect } from 'react';
import { useField, Form } from 'formik';

import CustomInputText from './customfields/CustomInputText';
import CustomSelect from './customfields/CustomSelect';
import CollapseHeaderBar from './CollapseHeaderBar';
import CollapseBody from './CollapseBody';
import BenefitSettingsSCALE from './BenefitSettingsSCALE';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import PaymentCondition from './PaymentCondition';
import CouponCondition from './CouponCondition';
import ExclusionSetting from './ExclusionSetting';
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import $ from 'jquery';

const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	triggerCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Determines the combination code to be used between the trigger items for a promotion',
	thresholdQuantity: 'Threshold Quantity',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	rewardQuantity: 'Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	PaymentConditions: 'Payment Conditions',
	CouponConditions: 'Coupon Conditions',
	ExclusionsSetting: 'Exclusion Settings',
	PurchaseandRewardConditions: 'Purchase and Reward Conditions',
	quantityLimit: 'Quantity Limit'
};

const EligibilityForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [field] = useField(props);
	const thresholdTypeCodeOptions = [
		{
			value: '',
			name: 'Select one...'
		},
		{
			value: 'SCALE_BASKET_QTY_THRESHOLD',
			name: 'Scale Basket Quantity Threshold'
		},
		{
			value: 'SCALE_BASKET_LIMIT_QTY_THRESHOLD',
			name: 'Scale Basket Limit Quantity Threshold'
		},
		{
			value: 'SCALE_BASKET_VALUE_THRESHOLD',
			name: 'Scale Basket Value Threshold'
		},
		{
			value: 'SCALE_BASKET_LIMIT_QTY_AND_AMOUNT_THRESHOLD',
			name: 'Scale Basket Limit Quantity And Amount Threshold'
		},
		{
			value: 'SCALE_BASKET_QTY_AND_VALUE_THRESHOLD',
			name: 'Scale Basket Quantity And Amount Threshold'
		}
	];

	const rewardCombinationDropdown = {
		operator: [
			{
				value: '',
				name: 'Select one'
			},
			{
				value: '<',
				name: '<'
			},
			{
				value: '>',
				name: '>'
			},
			{
				value: '<=',
				name: '<='
			},
			{
				value: '>=',
				name: '>='
			},
			{
				value: '=',
				name: '='
			},
			{
				value: 'BETWEEN',
				name: 'BETWEEN'
			}
		]
	};

	const getThresholdDropdown = (thresholdTypeCodeOptions) => {
		return thresholdTypeCodeOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	useEffect(() => {
		if (
			field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
				.thresholdTypeCode
		) {
			if (
				field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
					.thresholdTypeCode == 'SCALE_BASKET_LIMIT_QTY_THRESHOLD' ||
				field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
					.thresholdTypeCode == 'SCALE_BASKET_LIMIT_QTY_AND_AMOUNT_THRESHOLD'
			) {
				if (
					!field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
						.quantityLimit
				)
					field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.quantityLimit = 1;
			} else {
				field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.quantityLimit =
					undefined;
			}
		}
	}, [field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.thresholdTypeCode]);

	const fieldCR = field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity;
	//newly added

	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility
					.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
					.thresholdTypeCode !=
				field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
					.thresholdTypeCode
			) {
				let tempName =
					'scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.thresholdTypeCode';

				$('[name="' + tempName + '"]')
					.closest('.container')
					.parent()
					.parent()
					.nextAll()
					.addClass('hide-nochange-section-for-audit');

				let tempName2 = 'scrollToBenefitSettings';
				$('[name="' + tempName2 + '"]').addClass('hide-nochange-section-for-audit');
			}
		}
	}, []);

	useEffect(() => {
		if (
			fieldCR.triggerOperator == '<' ||
			fieldCR.triggerOperator == '>' ||
			fieldCR.triggerOperator == '<=' ||
			fieldCR.triggerOperator == '>=' ||
			fieldCR.triggerOperator == '='
		) {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
		} else if (fieldCR.triggerOperator == 'BETWEEN') {
			fieldCR.triggerOperatorValue = undefined;
		} else {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
			fieldCR.triggerOperatorValue = undefined;
		}
	}, [fieldCR.triggerOperator]);

	useEffect(() => {
		if (
			fieldCR.rewardOperator == '<' ||
			fieldCR.rewardOperator == '>' ||
			fieldCR.rewardOperator == '<=' ||
			fieldCR.rewardOperator == '>=' ||
			fieldCR.rewardOperator == '='
		) {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
		} else if (fieldCR.rewardOperator == 'BETWEEN') {
			fieldCR.rewardOperatorValue = undefined;
		} else {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
			fieldCR.rewardOperatorValue = undefined;
		}
	}, [fieldCR.rewardOperator]);

	const onMouseEnterHandler = (evt, mouseIsON) => {
		if (mouseIsON != props.curntMousePlace) {
			props.setMousePlace(mouseIsON);
		}
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

	return (
		<div>
			<Form className={offerFlow.type == 'view-offer-flow' ? 'disabledEvent' : ''}>
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToPaymentConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToPaymentConditions')}
					>
						<CollapseHeaderBar
							text={localConst.PaymentConditions}
							headingId={'headingFour'}
							dataTarget={'#collapseFour'}
						/>
						<CollapseBody id="collapseFour" headingId={'headingFour'}>
							<PaymentCondition
								customName="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity"
								fullVal={props.fullValue}
								loggedInBrand={props.loggedInBrand}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToPurchaseandRewardConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) =>
							onMouseEnterHandler(e, 'scrollToPurchaseandRewardConditions')
						}
					>
						<CollapseHeaderBar
							text={localConst.PurchaseandRewardConditions}
							headingId={'headingES'}
							dataTarget={'#collapseES'}
						/>
						<CollapseBody id="collapseES" headingId={'headingES'}>
							<div className="container-fluid">
								<div className="d-flex justify-content-center">
									<CustomSelect
										name="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.thresholdTypeCode"
										required={true}
										label="Threshold Type Code"
										options={getThresholdDropdown(thresholdTypeCodeOptions)}
										className="customDropdown inlinePadding bg-position"
									/>
								</div>
							</div>
							{field.value
								?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
								?.thresholdTypeCode ? (
								field.value
									?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
									?.thresholdTypeCode == 'SCALE_BASKET_LIMIT_QTY_THRESHOLD' ||
								field.value
									?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
									?.thresholdTypeCode ==
									'SCALE_BASKET_LIMIT_QTY_AND_AMOUNT_THRESHOLD' ? (
									<CustomInputText
										name="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.quantityLimit"
										required={false}
										label={localConst.quantityLimit}
									/>
								) : undefined
							) : undefined}
							<div className="d-flex">
								<div className="purchaseRow">
									<CustomSelect
										name="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.triggerOperator"
										label="Price Range Indicator"
										className="customDropdown inlinePadding bg-position"
										options={getThresholdDropdown(
											rewardCombinationDropdown.operator
										)}
										// smallText={localConst.subTriggerCombinationCode}
										/*  className="customDropdown inlinePadding" */
									/>
									{fieldCR.triggerOperator == '<' ||
									fieldCR.triggerOperator == '>' ||
									fieldCR.triggerOperator == '<=' ||
									fieldCR.triggerOperator == '>=' ||
									fieldCR.triggerOperator == '=' ? (
										<CustomInputText
											name="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.triggerOperatorValue"
											required={true}
											label="Price Range Value"
											smallText=""
											disabled={false}
										/>
									) : null}
									{fieldCR.triggerOperator == 'BETWEEN' ? (
										<>
											<CustomInputText
												name="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.triggerOperatorValueFrom"
												required={true}
												label="Price Range Value From"
												smallText=""
												disabled={false}
											/>
											<CustomInputText
												name="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.triggerOperatorValueTo"
												required={true}
												label="Price Range Value To"
												smallText=""
												disabled={false}
											/>{' '}
										</>
									) : null}
								</div>
								<div className="purchaseRow">
									<CustomSelect
										name="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.rewardOperator"
										label="Price Range Indicator"
										className="customDropdown inlinePadding bg-position"
										options={getThresholdDropdown(
											rewardCombinationDropdown.operator
										)}
										// smallText={localConst.subTriggerCombinationCode}
										/*  className="customDropdown inlinePadding" */
									/>
									{fieldCR.rewardOperator == '<' ||
									fieldCR.rewardOperator == '>' ||
									fieldCR.rewardOperator == '<=' ||
									fieldCR.rewardOperator == '>=' ||
									fieldCR.rewardOperator == '=' ? (
										<CustomInputText
											name="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.rewardOperatorValue"
											required={true}
											label="Price Range Value"
											smallText=""
											disabled={false}
										/>
									) : null}
									{fieldCR.rewardOperator == 'BETWEEN' ? (
										<>
											<CustomInputText
												name="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.rewardOperatorValueFrom"
												required={true}
												label="Price Range Value From"
												smallText=""
												disabled={false}
											/>
											<CustomInputText
												name="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.rewardOperatorValueTo"
												required={true}
												label="Price Range Value To"
												smallText=""
												disabled={false}
											/>{' '}
										</>
									) : null}
								</div>
							</div>
						</CollapseBody>
					</Element>
				</div>

				{field.value?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
					.thresholdTypeCode ? (
					<div className="accordion" id="accordionExample">
						<Element
							name="scrollToBenefitSettings"
							class="card"
							style={{ border: 'none' }}
							onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToBenefitSettings')}
						>
							<CollapseHeaderBar
								text={localConst.benefitSettings}
								headingId={'headingTwo'}
								dataTarget={'#collapseTwo'}
							/>
							<CollapseBody id="collapseTwo" headingId={'headingTwo'}>
								<BenefitSettingsSCALE
									{...props}
									customName="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity"
								/>
							</CollapseBody>
						</Element>
					</div>
				) : undefined}

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToExclusionsSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToExclusionsSettings')}
					>
						<CollapseHeaderBar
							text={localConst.ExclusionsSetting}
							headingId={'headingES'}
							dataTarget={'#collapseES'}
						/>
						<CollapseBody id="collapseES" headingId={'headingES'}>
							<ExclusionSetting
								customName="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity"
								fullValue={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToCouponConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToCouponConditions')}
					>
						<CollapseHeaderBar
							text={localConst.CouponConditions}
							headingId={'headingFive'}
							dataTarget={'#collapseFive'}
						/>
						<CollapseBody id="collapseFive" headingId={'headingFive'}>
							<CouponCondition
								customName="scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity"
								fullVal={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>
			</Form>
		</div>
	);
};

//const EligibilitySubForm = withSubForm(EligibilityForm, eligibilitySchema);
//export default EligibilitySubForm;
//export default EligibilityForm;

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
