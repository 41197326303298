import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import CouponSearch from './CouponSearch';
import * as urlTypes from '../../libraries/offer-service-urls';
import * as RestSvc from '../../libraries/offer-services';
import moment from 'moment';
import CouponTable from './CouponTable';
import { isObjectEmpty } from '../../utils/helperFunction';
import $ from 'jquery';

import './couponTable.scss';
import './couponManagement.scss';
import couponUploadNew from '../../assets/images/couponUploadNew.png';
import couponUpload from '../../assets/images/couponUpload.png';
import plusCircle from '../../assets/images/plus-circle.png';
import couponEdit from '../../assets/images/couponEdit.png';

import { ConfirmationModal } from '../../MainContent/ConfirmationModal';
import { InformationModal } from '../../MainContent/InformationModal';
import GroupInformationModal from '../../MainContent/GroupInformationModal';
import CouponVersionsTable from './CouponVersionsTable';
import chevronDown from '../../assets/images/chevron-down.png';
import AuditCoupon from './AuditCoupon';
import CONSTANT from '../../utils/appConstants';
import Toaster from '../../Components/Toaster/Toaster';
import appLabels from '../../utils/appLabels';
import GenerateCoupon from '../GenerateCoupon/GenerateCoupon';
import CouponPattern from '../CouponPattern/CouponPattern';
import ButtonDropdown from '../../Components/ButtonDropdown/ButtonDropdown';
import './couponTableBtnContainer.scss';
import appConfig from '../../utils/appConfig';
import DownloadExlBtn from '../../Components/DownloadExlBtn/DownloadExlBtn';

const {
	page: { couponManagement: pageLabel, couponPattern }
} = appLabels;

let genericCouponTableData = [];
let genericUnLimitedCouponTableData = [];
let targetedCouponTableData = [];
let personalizedCouponTableData = [];

const {
	COUPON: {
		ALL,
		GENERIC,
		TARGETED,
		PERSONALIZED,
		VIEW,
		GENERIC_LIMITED,
		GENERIC_UNLIMITED,
		GENERIC__UNLIMITED,
		GENERIC_UNLIMITED_COUPON_DATA
	},
	SEARCH,
	PREVIOUS,
	NEXT,
	buBrand
} = CONSTANT;

const {
	page: { generateCoupon },
	table: tableLabel
} = appLabels;

const pageLabels = {
	...generateCoupon
};

const formateDataToExcel = ({ data, couponType, isTSSSBu }) => {
	const column = data.map((val, ind) => {
		let col = {
			[`${tableLabel.promotionId}`]: val.promotionId,
			[`${tableLabel.promotionEndDateAndTime}`]: val.endDate,
			[`${tableLabel.status}`]: val.promotionStatus,
			[`${tableLabel.allowCouponToBeDisplayed}`]: val.displayCoupons ? 'Y' : 'N',
			[`${tableLabel.couponCount}`]: val.couponCount
		};

		if ([TARGETED, PERSONALIZED].includes(couponType)) {
			col[`${tableLabel.customerMappedCount}`] = val.customerMappedCount;
		}

		if (isTSSSBu) {
			col = {
				...col,
				[`${tableLabel.couponNotMappedToCustomerCount}`]:
					val.couponNotMappedToCustomerCount ?? '-',
				[`${tableLabel.totalCouponRedeemCount}`]: val.totalCouponRedeemCount ?? ''
			};
		}

		return col;
	});

	return column;
};

export class CouponManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			confirmationModalShow: false,
			confirmMsg: '',
			informationModalShow: false,
			informationMsg: '',
			searchOffersForm: {
				offerId: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'Search Offer'
					},
					value: '',
					validation: {
						required: false
					},
					attrValidation: {},
					validationMessage: '',
					valid: false,
					touched: false
				}
			},
			couponVal: '',
			searchOffersFormIsValid: false,
			showPromotionSearch: false,
			searchedOffers: [],
			filteredOffer: [],
			filterPageCount: 0,
			filteredArray: [],
			CopyOfSearchedOffer: [],
			searchPopup: false,
			searchForm: '',
			selectedOffer: '',
			itemsCountPerPage: 10,
			activePage: 1,
			searchedOffersToDisplay: [],
			informationTitle: '',
			selectAll: false,
			startDate: '',
			endDate: '',
			dropdownOpen: false,
			selectedOffers: [],
			modifyStartError: '',
			modifyEndError: '',
			showSearchedCouponTable: false,
			viewCouponTable: false,
			genericCouponData: [],
			genericCouponDataCurrentPage: 1,
			targetedCouponDataCurrentPage: 1,
			personalizedCouponDataCurrentPage: 1,
			viewCouponDataCurrentPage: 1,
			genericUnLimitedCouponData: [],
			targetedCouponData: [],
			personalizedCouponData: [],
			viewCouponData: [],
			clickedPromotion: [],
			selectedGeneric: [],
			selectedTargeted: [],
			selectedPersonalized: [],
			errorMsg: '',
			groupInformationModalShow: false,
			showVersionHistory: false,
			cpnVersionsData: [],
			auditCouponPopup: false,
			couponVerRow: {},
			couponActionType: null,
			couponView: true /* default coupon view screen */
		};
	}

	isTSSSBu = this.props.loggedInBrand.businessUnitGroupFunctionID === buBrand.TSSS.BUID;

	toggleSearchPopup = (flag) => {
		this.setState({
			searchPopup: flag
		});
	};

	handleSearch = (searchForm) => {
		this.setState(
			{
				searchForm,
				showSearchedCouponTable: true
			},
			() => {
				this.couponSearch(SEARCH, 0);
			}
		);
	};

	confirmationModalClose = () => {
		this.setState({ confirmationModalShow: false });
	};

	informationModalClose = () => {
		this.setState({ informationModalShow: false, groupInformationModalShow: false });
	};

	downloadCoupon = (couponType) => {
		let promotionIds = [];

		if (couponType === GENERIC) {
			if (this.state.selectedGeneric.length === 0)
				promotionIds = this.state.genericCouponData.map((item) => item.promotionId);
			else promotionIds = this.state.selectedGeneric.map((item) => item);
		}
		if (couponType === GENERIC_UNLIMITED_COUPON_DATA) {
			promotionIds = this.state.genericUnLimitedCouponData.map((item) => item.promotionId);
		}
		if (couponType === TARGETED) {
			if (this.state.selectedTargeted.length === 0)
				promotionIds = this.state.targetedCouponData.map((item) => item.promotionId);
			else promotionIds = this.state.selectedTargeted.map((item) => item);
		}
		if (couponType === PERSONALIZED) {
			if (this.state.selectedPersonalized.length === 0)
				promotionIds = this.state.personalizedCouponData.map((item) => item.promotionId);
			else promotionIds = this.state.selectedPersonalized.map((item) => item);
		}
		if (couponType === VIEW) {
			promotionIds.push(this.state.clickedPromotion.promotionId);
		}

		const dataToSend = {
			downloadCouponCsvRequest: {
				programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
					.programId,
				couponId: this.state.searchForm.couponID.value
					? this.state.searchForm.couponID.value
					: undefined,
				couponType: this?.state?.searchForm?.promotionID?.value
					? undefined
					: this.state.searchForm.couponType.value
					? this.state.searchForm.couponType.value
					: undefined,
				couponStatus: this.state.searchForm.couponStatus.value
					? this.state.searchForm.couponStatus.value
					: undefined,
				expiryDate: this.state.searchForm.couponExpiryDateTime.value
					? moment(this.state.searchForm.couponExpiryDateTime.value).format(
							'YYYY-MM-DD HH:mm:ss'
					  )
					: undefined,
				promotionIds: promotionIds ? promotionIds : undefined,
				userId: sessionStorage.getItem('loggedInUserId')
			}
		};
		this.props.showLoadingButton(true);
		// const svPromUrl = JSON.parse(sessionStorage.getItem("applctnUrls")).savePromotionsUrl;
		const cpnUrl = sessionStorage.getItem('couponServiceUrl');

		RestSvc.postData(urlTypes.coupon_maintenance.downloadCoupon, dataToSend, cpnUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				if (response?.status == 200) {
					let result = response.data?.uploadCouponResponse;
					const msg =
						result?.businessError || result?.status || pageLabel.downloadDefaultMsg;
					const msgType = result?.businessError ? 'error' : 'info';
					Toaster({ message: msg, type: msgType });
					return;
				} else {
					Toaster({ message: pageLabel.downloadsFailed });
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				this.toggleSearchPopup(false);
				console.error(error);
			});
	};

	couponSearch = (couponType, pageNumber) => {
		const {
			showLoadingButton,
			loggedInBrand: { businessUnitGroupFunctionID }
		} = this.props;

		showLoadingButton(true);

		let dataToSend;
		const programId = JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser')).programId;

		const {
			promotionID: { value: promoID },
			couponID: { value: _couponID },
			couponType: { value: _couponType },
			couponStatus: { value: _couponStatus },
			couponExpiryDateTime: { value: _couponExpiryDateTime }
		} = this.state.searchForm;

		let _promoID = promoID;

		if ([buBrand.CROMA.BUID].includes(businessUnitGroupFunctionID)) {
			/* CROMA user case in-sensitive*/
			_promoID = _promoID?.toUpperCase();
		}

		let _promotionId = _promoID ? `${programId}:${_promoID}` : undefined;
		if (couponType === SEARCH) {
			dataToSend = {
				searchCouponRequest: {
					programId: programId,
					promotionId: _promotionId,
					couponId: _couponID || undefined,
					couponType: promoID ? undefined : _couponType || undefined,
					couponStatus: _couponStatus || undefined,
					endDate: _couponExpiryDateTime
						? moment(_couponExpiryDateTime).format('YYYY-MM-DD HH:mm:ss')
						: undefined,
					pageNumber: 0
				}
			};
		} else {
			dataToSend = {
				searchCouponRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					couponType: couponType || undefined,
					pageNumber: pageNumber ? pageNumber - 1 : undefined,
					promotionId: promoID
						? `${
								JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
									.programId
						  }:${promoID}`
						: undefined,
					couponId: _couponID || undefined,
					endDate: _couponExpiryDateTime
						? moment(_couponExpiryDateTime).format('YYYY-MM-DD HH:mm:ss')
						: undefined,
					couponStatus: _couponStatus || undefined
				}
			};
		}
		// const svPromUrl = JSON.parse(sessionStorage.getItem("applctnUrls")).savePromotionsUrl;
		const cpnUrl = sessionStorage.getItem('couponServiceUrl');

		if (couponType === SEARCH) {
			genericCouponTableData = [];
			genericUnLimitedCouponTableData = [];
			targetedCouponTableData = [];
			personalizedCouponTableData = [];
		}

		RestSvc.postData(urlTypes.coupon_maintenance.searchCoupon, dataToSend, cpnUrl)
			.then((response) => {
				showLoadingButton(false);
				var result = response.data.searchCouponResponse;

				if (result.businessError) {
					if (couponType !== SEARCH) {
						if (couponType === GENERIC) {
							this.setState((previousState) => ({
								genericCouponDataCurrentPage:
									previousState.genericCouponDataCurrentPage - 1
							}));
						} else if (couponType === TARGETED) {
							this.setState((previousState) => ({
								targetedCouponDataCurrentPage:
									previousState.targetedCouponDataCurrentPage - 1
							}));
						} else if (couponType === PERSONALIZED) {
							this.setState((previousState) => ({
								personalizedCouponDataCurrentPage:
									previousState.personalizedCouponDataCurrentPage - 1
							}));
						}
					}
					Toaster({ message: result.businessError.errorMessage });
				}
				if (result?.promotionCouponDataList) {
					result.promotionCouponDataList.forEach((obj) => {
						const { couponType, basicCouponDetails } = obj;
						if (basicCouponDetails) {
							if (couponType === GENERIC) {
								genericCouponTableData = basicCouponDetails;
							}
							if (couponType === GENERIC__UNLIMITED) {
								genericUnLimitedCouponTableData = basicCouponDetails;
							}
							if (couponType === TARGETED) {
								targetedCouponTableData = basicCouponDetails;
							}
							if (couponType === PERSONALIZED) {
								personalizedCouponTableData = basicCouponDetails;
							}
						}
					});
				}

				if (couponType === SEARCH) {
					this.setState({
						genericCouponData: genericCouponTableData,
						genericUnLimitedCouponData: genericUnLimitedCouponTableData,
						targetedCouponData: targetedCouponTableData,
						personalizedCouponData: personalizedCouponTableData
					});
				} else if (couponType === GENERIC && genericCouponTableData) {
					this.setState({
						genericCouponData: genericCouponTableData
					});
				} else if (couponType === TARGETED && targetedCouponTableData) {
					this.setState({
						targetedCouponData: targetedCouponTableData
					});
				} else if (couponType === PERSONALIZED && personalizedCouponTableData) {
					this.setState({
						personalizedCouponData: personalizedCouponTableData
					});
				}

				this.toggleSearchPopup(false);
			})
			.catch((error) => {
				showLoadingButton(false);
				this.toggleSearchPopup(false);
				console.error(error);
			});
	};

	viewCouponDetails = ({ promotionId }, couponType, pageNumber) => {
		this.props.showLoadingButton(true);
		let dataToSend;
		if (couponType === SEARCH) {
			dataToSend = {
				searchCouponDetailsRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					promotionId: promotionId,
					couponId: this.state.searchForm.couponID?.value || undefined,
					couponStatus: this.state.searchForm.couponStatus?.value || undefined,
					endDate: this.state.searchForm.couponExpiryDateTime.value
						? moment(this.state.searchForm.couponExpiryDateTime.value).format(
								'YYYY-MM-DD HH:mm:ss'
						  )
						: undefined,
					pageNumber: 0
				}
			};
		} else {
			dataToSend = {
				searchCouponDetailsRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					promotionId: promotionId,
					couponId: this.state.searchForm.couponID.value || undefined,
					couponStatus: this.state.searchForm.couponStatus.value || undefined,
					endDate: this.state.searchForm.couponExpiryDateTime.value
						? moment(this.state.searchForm.couponExpiryDateTime.value).format(
								'YYYY-MM-DD HH:mm:ss'
						  )
						: undefined,
					pageNumber: pageNumber ? pageNumber - 1 : undefined
				}
			};
		}
		if (couponType === SEARCH) {
			this.setState({
				viewCouponData: []
			});
		}
		const cpnUrl = sessionStorage.getItem('couponServiceUrl');
		RestSvc.postData(urlTypes.coupon_maintenance.searchCouponDeatils, dataToSend, cpnUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.SearchCouponDetailsResponse;
				if (result.businessError) {
					this.setState((previousState) => ({
						viewCouponDataCurrentPage: previousState.viewCouponDataCurrentPage - 1
					}));
					Toaster({ message: result.businessError.errorMessage });
				}

				const { couponDataList } = result;
				if (couponDataList) {
					this.setState({
						viewCouponData: couponDataList
					});
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	couponActionHandler = (row, couponType) => {
		this.setState(
			{
				viewCouponTable: true,
				showSearchedCouponTable: false,
				viewCouponDataCurrentPage: 1,
				clickedPromotion: row,
				couponActionType: couponType
			},
			() => {
				this.viewCouponDetails(row, SEARCH, 0);
			}
		);
	};

	gotoCouponSearch = () => {
		this.setState({
			viewCouponData: [],
			viewCouponTable: false,
			showSearchedCouponTable: true,
			couponActionType: null
		});
	};

	onInputChange = (event, definedRules, inputIdentifier) => {
		let value = event.target.value;
		let rules = definedRules;
		let isValid = { value: true, message: '' };
		if (rules) {
			if (rules.required) {
				isValid.value = value.trim() !== '';
				isValid.message = isValid.value ? '' : 'Field required';
			}
			if (rules.minLength) {
				isValid.value = value.length >= rules.minLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Minimum length is ' + rules.minLength;
			}
			if (rules.maxLength) {
				isValid.value = value.length <= rules.maxLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Maximum length is ' + rules.maxLength;
			}
			if (rules.isEmail && value) {
				let regEx = new RegExp(
					/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid email';
			}
			if (rules.isPhoneNumber && value) {
				let regEx = new RegExp(
					/^\+?(\d+|(\(\d+\))+)+((([ ]?(\d+|(\(\d+\))+)+)*)|((\-\d+)*)|((\.\d+)*))*$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid phone number';
			}
			if (rules.isAlphabets && value) {
				let regEx = new RegExp('^[a-zA-Z]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets';
			}
			if (rules.isAlphaNumeric && value) {
				let regEx = new RegExp('^[a-zA-Z0-9]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets and numbers';
			}
		}
		this.inputChangedHandler(value, isValid, inputIdentifier, false);
	};

	inputChangedHandler = (value, isValidObj, inputIdentifier, dateTimeRangeChanged) => {
		const updatedSearchOffersForm = { ...this.state.searchOffersForm };
		const updatedFormElement = { ...updatedSearchOffersForm[inputIdentifier] };
		updatedFormElement.value = value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;
		updatedSearchOffersForm[inputIdentifier] = updatedFormElement;
		if (dateTimeRangeChanged) {
			const updatedFormStartDateTimeElement = {
				...updatedSearchOffersForm['userStartDateTime']
			};
			const updatedFormEndDateTimeElement = { ...updatedSearchOffersForm['userEndDateTime'] };
			if (updatedFormStartDateTimeElement.value && updatedFormEndDateTimeElement.value) {
				if (
					moment(updatedFormStartDateTimeElement.value) >
					moment(updatedFormEndDateTimeElement.value)
				) {
					updatedFormEndDateTimeElement.valid = false;
					updatedFormEndDateTimeElement.validationMessage = 'Invalid date time range';
				} else {
					updatedFormEndDateTimeElement.valid = isValidObj.value;
					updatedFormEndDateTimeElement.validationMessage = isValidObj.message;
				}
			}

			updatedSearchOffersForm['userEndDateTime'] = updatedFormEndDateTimeElement;
		}
		let formIsValid = true;
		for (let inputIdentifier in updatedSearchOffersForm) {
			formIsValid = updatedSearchOffersForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({
			searchOffersForm: updatedSearchOffersForm,
			searchOffersFormIsValid: formIsValid
		});
	};

	onSearchOfferSubmit = (event) => {
		event.preventDefault();
		var dataToSend = {
			searchPromotionRequest: {
				programId: this.props.loggedInBrand.programId,
				promotionId: this.state.searchOffersForm.offerId.value
			}
		};
		this.setState({ searchedOffers: [] });
		this.props.showLoadingButton(true);
		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		RestSvc.postData(urlTypes.offers_maintenance.searchPromotion, dataToSend, svPromUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.searchPromotionResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
				} else {
					this.setState({ selectedOffers: [] });
					this.setState((previousState) => {
						previousState.searchOffersForm.offerId.value = '';
						return previousState;
					});
					this.setState(
						{
							searchedOffers: result.promotionPolicyDetails,
							showPromotionSearch: true
						} /* ,
                        () => { this.handlePageChange(1);; this.resetCheckbox() } */
					);
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	couponActionFormatter = (cell, row, couponType) => {
		return (
			<>
				<div style={{ display: 'flex', width: '20px', height: '20px' }}>
					<span
						className="viewCoupon"
						onClick={() => {
							this.couponActionHandler(row, couponType);
						}}
						style={{
							color: 'blue',
							textDecoration: 'underline'
						}}
						/* eslint-disable react/no-unknown-property */
						variant="link"
					></span>
				</div>
			</>
		);
	};

	getItemsPerPageForSearchedOffers = () => {
		this.props.showLoadingButton(true);

		RestSvc.getData(urlTypes.offers_maintenance.getConfigurationUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.getConfigurationResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });

					this.setState({ itemsCountPerPage: 10 });
				} else {
					let p = result.configurationList.findIndex(
						(i) => i.configurationType === 'SEARCH_RESULTS_PER_PAGE_COUPON'
					);
					if (p !== -1) {
						this.setState({ itemsCountPerPage: result.configurationList[p].value });
					}

					let tableViewValueList = [];
					result.configurationList.map((obj) => {
						if (obj.configurationType.includes('TABLE_VIEW_VALUE_COUPON_')) {
							tableViewValueList.push(parseInt(obj.value));
						}
					});

					tableViewValueList.sort(function (a, b) {
						return a - b;
					});

					sessionStorage.setItem(
						'configuredDataToViewTableRecords',
						JSON.stringify({
							resultPerPage: this.state.itemsCountPerPage,
							recordGroupForTable: tableViewValueList.length
								? tableViewValueList
								: [50]
						})
					);
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	onSelectedGenericChange = (newList) => {
		this.setState({
			selectedGeneric: newList
		});
	};
	onSelectedTargetedChange = (newList) => {
		this.setState({
			selectedTargeted: newList
		});
	};
	onSelectedPersonalizedChange = (newList) => {
		this.setState({
			selectedPersonalized: newList
		});
	};

	onPageChange = (couponType, flag) => {
		if (couponType === GENERIC && flag === PREVIOUS) {
			if (this.state.genericCouponDataCurrentPage > 1) {
				this.setState(
					(previousState) => ({
						genericCouponDataCurrentPage: previousState.genericCouponDataCurrentPage - 1
					}),
					() => {
						this.couponSearch(couponType, this.state.genericCouponDataCurrentPage);
					}
				);
			}
		}
		if (couponType === GENERIC && flag === NEXT) {
			if (this.state.genericCouponDataCurrentPage >= 1) {
				this.setState(
					(previousState) => ({
						genericCouponDataCurrentPage: previousState.genericCouponDataCurrentPage + 1
					}),
					() => {
						this.couponSearch(couponType, this.state.genericCouponDataCurrentPage);
					}
				);
			}
		}
		//targeted
		if (couponType === TARGETED && flag === PREVIOUS) {
			if (this.state.targetedCouponDataCurrentPage > 1) {
				this.setState(
					(previousState) => ({
						targetedCouponDataCurrentPage:
							previousState.targetedCouponDataCurrentPage - 1
					}),
					() => {
						this.couponSearch(couponType, this.state.targetedCouponDataCurrentPage);
					}
				);
			}
		}
		if (couponType === TARGETED && flag === NEXT) {
			if (this.state.targetedCouponDataCurrentPage >= 1) {
				this.setState(
					(previousState) => ({
						targetedCouponDataCurrentPage:
							previousState.targetedCouponDataCurrentPage + 1
					}),
					() => {
						this.couponSearch(couponType, this.state.targetedCouponDataCurrentPage);
					}
				);
			}
		}
		//Personalized
		if (couponType === PERSONALIZED && flag === PREVIOUS) {
			if (this.state.personalizedCouponDataCurrentPage > 1) {
				this.setState(
					(previousState) => ({
						personalizedCouponDataCurrentPage:
							previousState.personalizedCouponDataCurrentPage - 1
					}),
					() => {
						this.couponSearch(couponType, this.state.personalizedCouponDataCurrentPage);
					}
				);
			}
		}
		if (couponType === PERSONALIZED && flag === NEXT) {
			if (this.state.personalizedCouponDataCurrentPage >= 1) {
				this.setState(
					(previousState) => ({
						personalizedCouponDataCurrentPage:
							previousState.personalizedCouponDataCurrentPage + 1
					}),
					() => {
						this.couponSearch(couponType, this.state.personalizedCouponDataCurrentPage);
					}
				);
			}
		}

		//view
		if (couponType === VIEW && flag === PREVIOUS) {
			if (this.state.viewCouponDataCurrentPage > 1) {
				this.setState(
					(previousState) => ({
						viewCouponDataCurrentPage: previousState.viewCouponDataCurrentPage - 1
					}),
					() => {
						this.viewCouponDetails(
							this.state.clickedPromotion,
							couponType,
							this.state.viewCouponDataCurrentPage
						);
					}
				);
			}
		}
		if (couponType === VIEW && flag === NEXT) {
			if (this.state.viewCouponDataCurrentPage >= 1) {
				this.setState(
					(previousState) => ({
						viewCouponDataCurrentPage: previousState.viewCouponDataCurrentPage + 1
					}),
					() => {
						this.viewCouponDetails(
							this.state.clickedPromotion,
							couponType,
							this.state.viewCouponDataCurrentPage
						);
					}
				);
			}
		}
		/* this.setState({
			genericCouponData:data
		}) */
	};

	componentDidMount() {
		this.getItemsPerPageForSearchedOffers();
	}
	getCouponVersions = (promotionID) => {
		const dataToSend = {
			getCouponVersionDetailsRequest: {
				programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
					.programId,
				promotionId: promotionID
			}
		};

		this.props.showLoadingButton(true);
		// const svPromUrl = JSON.parse(sessionStorage.getItem("applctnUrls")).savePromotionsUrl;

		RestSvc.postData(urlTypes.coupon_maintenance.getCpnAuditVersions, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.getCouponAuditVersionsResponse;
				let errorMessage = [];
				if (result.businessExceptions) {
					result.businessExceptions.map((item) => {
						if (!isObjectEmpty(item)) {
							errorMessage.push(
								<>
									<div>
										<b>{item.description}</b>
									</div>
								</>
							);
						}
					});
					this.setState({
						informationModalShow: true,
						informationMsg: errorMessage,
						informationTitle: 'Information'
					});
					this.setState({ showVersionHistory: false });
				} else if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
				} else {
					if (result.couponAuditVersionDetails) {
						let sortedVersions = result.couponAuditVersionDetails.sort(function (a, b) {
							return b.versionId - a.versionId;
						});
						// this.setState({cpnVersionsData:result.couponAuditVersionDetails})
						this.setState({ cpnVersionsData: sortedVersions });
						this.setState({ showVersionHistory: true });
						setTimeout(function () {
							// document
							// 	.getElementById('scrollToCpnHistoryTable')
							// 	.scrollIntoView({ behavior: 'smooth' });
							const btnHeight = document.querySelector('.btn-container').offsetHeight;
							const couponViewTableHeight = document.querySelector(
								'.coupon-view-table-section > .couponTableContainer'
							).offsetHeight;
							const hrHeight = 40;
							const bufferHeight = 24;
							const scrollHeight =
								btnHeight + couponViewTableHeight + hrHeight + bufferHeight;
							document
								.querySelector('.main-section-container')
								.scroll(0, scrollHeight);
						}, 500);
					}
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				// this.toggleSearchPopup(false);
				console.error(error);
			});
	};
	viewCoupons = (row) => {
		this.setState({ auditCouponPopup: true });
		this.setState({ couponVerRow: row });
	};

	generateCouponHandler = () => {
		this.setState({
			couponView: false
		});
		this.props.history.push('/MainPage/couponManagement/generateCoupon');
	};

	couponPatternHandler = () => {
		this.setState({
			couponView: false
		});
		this.props.history.push('/MainPage/couponManagement/couponPattern');
	};

	mapCustomerHasHHandler = async (event) => {
		const fileList = event.target.files;
		const { userFullName, showLoadingButton } = this.props;

		const progId = JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser')).programId;

		try {
			const formData = new FormData();
			formData.append('file', fileList[0]);
			formData.append('user', `${userFullName.operatorID}:${progId}`);

			showLoadingButton(true);

			const response = await RestSvc.postData(
				urlTypes.generateCoupon.mapCouponsToCustomer,
				formData
			);

			showLoadingButton(false);

			var result = response.data.uploadCouponResponse;
			let errorMessage = '';

			if (result.modifyPromotionResult) {
				result.modifyPromotionResult.map((item) => {
					if (!isObjectEmpty(item)) {
						errorMessage = item.message;
					}
				});
			}
			if (result.businessExceptions) {
				result.businessExceptions.map((item) => {
					if (!isObjectEmpty(item)) {
						errorMessage = errorMessage.concat('|', item.description);
					}
				});
			}

			this.setState({
				groupInformationModalShow: true,
				informationMsg: result.status,
				informationTitle: 'Information',
				errorMsg: errorMessage
			});

			$('#mapCustomerHashFile').val('');
		} catch (error) {
			showLoadingButton(false);
			console.error(error);
		}
	};

	generateCouponCloseHandler = (historyChange = true) => {
		this.setState({
			couponView: true
		});
		historyChange && this.props.history.push('/MainPage/couponManagement');
	};

	renderMainBtnGroup = () => {
		return (
			<>
				{appConfig.feature.enableGenerateCoupon && (
					<ButtonDropdown
						headerClassName="generate-btn roboto-b-16"
						headerLabel={pageLabels.generateCoupon}
						icon={<img className="mr-2" alt="img" src={plusCircle} />}
						dropdownMenu={
							<>
								<button
									className="dropdown-item"
									type="button"
									onClick={this.generateCouponHandler}
								>
									{pageLabels.generateCoupon}
								</button>
								<button
									className="dropdown-item map-customer-hash-btn"
									onClick={() => {
										document.getElementById('mapCustomerHashFile').click();
									}}
								>
									{pageLabels.mapCustomerHashBtn}
									<img
										className="ml-2"
										alt="img"
										src={couponUpload}
										style={{ height: '23px' }}
									/>
									<input
										type="file"
										id="mapCustomerHashFile"
										className="d-none"
										onChange={this.mapCustomerHasHHandler}
									/>
								</button>
								<button
									className="dropdown-item"
									type="button"
									onClick={this.couponPatternHandler}
								>
									{couponPattern.couponPattern}
								</button>
							</>
						}
					/>
				)}
				<button
					type="button"
					className="btnNxtPrev roboto-b-16"
					onClick={() => {
						document.getElementById('idLoadCouponsFromFile').click();
					}}
				>
					Upload Coupons
					<span className="ml-2">
						<img alt="img" src={couponUploadNew} />
					</span>
					<input
						type="file"
						id="idLoadCouponsFromFile"
						style={{ display: 'none' }}
						onChange={(event) => {
							const fileList = event.target.files;
							const url = urlTypes.coupon_maintenance.uploadCoupons;
							const cpnUrl = sessionStorage.getItem('couponServiceUrl');
							const progId = JSON.parse(
								sessionStorage.getItem('taggedBrandToLoggedInUser')
							).programId;
							const formData = new FormData();
							formData.append('file', fileList[0]);
							formData.append(
								'user',
								this.props.userFullName.operatorID + ':' + progId
							);
							this.props.showLoadingButton(true);
							RestSvc.postData(url, formData, cpnUrl)
								.then((response) => {
									this.props.showLoadingButton(false);
									var result = response.data.uploadCouponResponse;
									let errorMessage = '';

									if (result.modifyPromotionResult) {
										result.modifyPromotionResult.map((item) => {
											if (!isObjectEmpty(item)) {
												errorMessage = item.message;
											}
										});
									}

									if (result.businessExceptions) {
										result.businessExceptions.map((item) => {
											if (!isObjectEmpty(item)) {
												errorMessage = errorMessage.concat(
													'|',
													item.description
												);
											}
										});
									}
									this.setState({
										groupInformationModalShow: true,
										informationMsg: result.status,
										informationTitle: 'Information',
										errorMsg: errorMessage
									});
								})
								.catch((error) => {
									this.props.showLoadingButton(false);
									console.error(error);
								});
							$('#idLoadCouponsFromFile').val('');
						}}
					/>
				</button>

				<button
					type="button"
					className="btnNxtPrev roboto-b-16"
					onClick={() => {
						this.toggleSearchPopup(true);
						this.setState((previousState) => {
							previousState.searchOffersForm.offerId.value = '';
							previousState.searchedOffers = [];
							previousState.showSearchedCouponTable = false;
							previousState.genericCouponData = [];
							previousState.genericUnLimitedCouponData = [];
							previousState.personalizedCouponData = [];
							previousState.targetedCouponData = [];
							previousState.viewCouponData = [];
							previousState.genericCouponDataCurrentPage = 1;
							previousState.targetedCouponDataCurrentPage = 1;
							previousState.personalizedCouponDataCurrentPage = 1;
						});
					}}
				>
					View Coupons{' '}
					<span className="ml-2">
						<img alt="img" src={couponEdit} />
					</span>
				</button>

				<span className="align-items-center channelLink roboto-b-16">
					<a
						href="../coupon-management-templates.7z"
						title="Click to download the applicable templates for Coupon Management"
						download
					>
						Download Coupon Templates
					</a>
				</span>
			</>
		);
	};

	renderCouponSearchFilter = () => {
		return (
			<div className={`modal ${this.state.searchPopup ? 'in-right d-block' : ''}`}>
				<div className="modal-dialog m-0 searchOffer-modal-bottom-right" role="document">
					<div className="modal-content h-100">
						<div className="modal-header modal-title-custom p-2 b-radius-none d-flex">
							<p
								className="modal-title ml-2 roboto-r-18"
								style={{ flex: '1' }}
								id="exampleModalLabel"
							>
								Search Coupon
							</p>
							<span className="xSCloseButtonSpan">
								<button
									className="xSCloseButton"
									onClick={() => {
										this.toggleSearchPopup();
									}}
								>
									X
								</button>
							</span>
						</div>

						<CouponSearch
							handleSelection={this.toggleSearchPopup}
							handleSearch={this.handleSearch}
						/>
					</div>
				</div>
			</div>
		);
	};

	renderSecondaryBtnGroup = () => {
		if (this.state.viewCouponTable) {
			return null;
		}
		return (
			<div className="d-flex coupon-table-btn-container">
				<button
					type="button"
					className="couponBtn d-flex align-items-center"
					style={{
						fontSize: '13px',
						outline: 'none',
						border: '0px'
					}}
					onClick={() => {
						document.getElementById('idCoupons').click();
					}}
				>
					<span className="btn font-weight-bold" style={{ fontSize: '13px' }}>
						Coupons
					</span>
					<span className="couponDelete icon ml-2"></span>
					<input
						type="file"
						id="idCoupons"
						style={{ display: 'none' }}
						onChange={(event) => {
							const fileList = event.target.files;
							const url = urlTypes.coupon_maintenance.deleteCoupons;
							const cpnUrl = sessionStorage.getItem('couponServiceUrl');
							const progId = JSON.parse(
								sessionStorage.getItem('taggedBrandToLoggedInUser')
							).programId;
							const formData = new FormData();
							formData.append('file', fileList[0]);
							formData.append(
								'user',
								this.props.userFullName.operatorID + ':' + progId
							);
							this.props.showLoadingButton(true);
							RestSvc.postData(url, formData, cpnUrl)
								.then((response) => {
									this.props.showLoadingButton(false);
									var result = response.data.uploadCouponResponse;

									let errorMessage = '';

									if (result.modifyPromotionResult) {
										result.modifyPromotionResult.map((item) => {
											if (!isObjectEmpty(item)) {
												errorMessage = item.message;
											}
										});
									}

									if (result.businessExceptions) {
										result.businessExceptions.map((item) => {
											if (!isObjectEmpty(item)) {
												errorMessage = errorMessage.concat(
													'|',
													item.description
												);
											}
										});
									}
									this.setState({
										groupInformationModalShow: true,
										informationMsg: result.status,
										informationTitle: 'Information',
										errorMsg: errorMessage
									});
								})
								.catch((error) => {
									this.props.showLoadingButton(false);
									console.error(error);
								});
							$('#idCoupons').val('');
						}}
					/>
				</button>

				<button
					type="button"
					className="couponBtn d-flex align-items-center"
					style={{
						fontSize: '13px',
						outline: 'none',
						border: '0px'
					}}
					onClick={() => {
						document.getElementById('idAllowCoupons').click();
					}}
				>
					<span className="btn font-weight-bold" style={{ fontSize: '13px' }}>
						Allow coupons To be displayed
					</span>
					<span className="couponUpload icon ml-2"></span>
					<input
						type="file"
						id="idAllowCoupons"
						style={{ display: 'none' }}
						onChange={(event) => {
							const fileList = event.target.files;
							const url = urlTypes.coupon_maintenance.uploadCouponDisplayFlag;
							const cpnUrl = sessionStorage.getItem('couponServiceUrl');
							const progId = JSON.parse(
								sessionStorage.getItem('taggedBrandToLoggedInUser')
							).programId;
							const formData = new FormData();
							formData.append('file', fileList[0]);
							formData.append(
								'user',
								this.props.userFullName.operatorID + ':' + progId
							);
							this.props.showLoadingButton(true);
							RestSvc.postData(url, formData, cpnUrl)
								.then((response) => {
									this.props.showLoadingButton(false);
									var result = response.data.uploadCouponDisplayFlagResponse;
									let errorMessage = '';
									let successMessage = [];
									if (result.status) {
										successMessage.push(result.status);
									}
									if (result.modifyPromotionResult) {
										result.modifyPromotionResult.map((item) => {
											if (!isObjectEmpty(item)) {
												// errorMessage.push(item.message)
												successMessage.push(
													item.message +
														'. ' +
														item.promotionIds.toString()
												);
											}
										});
									}

									if (result.businessExceptions) {
										successMessage = undefined;
										result.businessExceptions.map((item) => {
											if (!isObjectEmpty(item)) {
												errorMessage = item.description;
											}
										});
									}
									this.setState({
										groupInformationModalShow: true,
										informationMsg: successMessage,
										informationTitle: 'Information',
										errorMsg: errorMessage
									});
								})
								.catch((error) => {
									this.props.showLoadingButton(false);
									console.error(error);
								});
							$('#idAllowCoupons').val('');
						}}
					/>
				</button>

				<button
					type="button"
					className="couponBtn d-flex align-items-center"
					style={{
						fontSize: '13px',
						outline: 'none',
						border: '0px'
					}}
					onClick={() => {
						document.getElementById('idExpiryDateAndTime').click();
					}}
				>
					<span className="btn font-weight-bold" style={{ fontSize: '13px' }}>
						Expiry Date/Time
					</span>
					<span className="couponUpload icon ml-2"></span>
					<input
						type="file"
						id="idExpiryDateAndTime"
						style={{ display: 'none' }}
						onChange={(event) => {
							const fileList = event.target.files;
							const url = urlTypes.coupon_maintenance.updateCouponExpiryDate;
							const cpnUrl = sessionStorage.getItem('couponServiceUrl');
							const progId = JSON.parse(
								sessionStorage.getItem('taggedBrandToLoggedInUser')
							).programId;
							const formData = new FormData();
							formData.append('file', fileList[0]);
							formData.append(
								'user',
								this.props.userFullName.operatorID + ':' + progId
							);
							this.props.showLoadingButton(true);
							RestSvc.postData(url, formData, cpnUrl)
								.then((response) => {
									this.props.showLoadingButton(false);
									var result = response.data.uploadCouponResponse;
									let errorMessage = '';

									if (result.modifyPromotionResult) {
										result.modifyPromotionResult.map((item) => {
											if (!isObjectEmpty(item)) {
												errorMessage = item.message;
											}
										});
									}

									if (result.businessExceptions) {
										result.businessExceptions.map((item) => {
											if (!isObjectEmpty(item)) {
												errorMessage = errorMessage.concat(
													'|',
													item.description
												);
											}
										});
									}
									this.setState({
										groupInformationModalShow: true,
										informationMsg: result.status,
										informationTitle: 'Information',
										errorMsg: errorMessage
									});
								})
								.catch((error) => {
									this.props.showLoadingButton(false);
									console.error(error);
								});
							$('#idExpiryDateAndTime').val('');
						}}
					/>
				</button>

				<button
					type="button"
					className="couponBtn d-flex align-items-center"
					style={{
						fontSize: '13px',
						outline: 'none',
						border: '0px'
					}}
					onClick={() => {
						document.getElementById('idCouponLimit').click();
					}}
				>
					<span className="btn font-weight-bold" style={{ fontSize: '13px' }}>
						Coupon Limit
					</span>
					<span className="couponUpload icon ml-2"></span>
					<input
						type="file"
						id="idCouponLimit"
						style={{ display: 'none' }}
						onChange={(event) => {
							const fileList = event.target.files;
							const url = urlTypes.coupon_maintenance.updateCouponLimit;
							const cpnUrl = sessionStorage.getItem('couponServiceUrl');
							const progId = JSON.parse(
								sessionStorage.getItem('taggedBrandToLoggedInUser')
							).programId;
							const formData = new FormData();
							formData.append('file', fileList[0]);
							formData.append(
								'user',
								this.props.userFullName.operatorID + ':' + progId
							);
							this.props.showLoadingButton(true);
							RestSvc.postData(url, formData, cpnUrl)
								.then((response) => {
									this.props.showLoadingButton(false);
									var result = response.data.uploadCouponResponse;
									let errorMessage = [];

									if (result.modifyPromotionResult) {
										result.modifyPromotionResult.map((item) => {
											if (!isObjectEmpty(item)) {
												try {
													errorMessage.push(item.message.split('|'));
												} catch (e) {
													errorMessage.push(item.message);
												}
											}
										});
									}

									if (result.businessExceptions) {
										result.businessExceptions.map((item) => {
											if (!isObjectEmpty(item)) {
												try {
													errorMessage.push(item.description.split('|'));
												} catch (e) {
													errorMessage.push(item.description);
												}
											}
										});
									}
									this.setState({
										groupInformationModalShow: true,
										informationMsg: result.status,
										informationTitle: 'Information',
										errorMsg: errorMessage
									});
								})
								.catch((error) => {
									this.props.showLoadingButton(false);
									console.error(error);
								});
							$('#idCouponLimit').val('');
						}}
					/>
				</button>
			</div>
		);
	};

	renderSearchTags = () => {
		if (
			this.state?.genericCouponData.length === 0 &&
			this.state?.genericUnLimitedCouponData.length === 0 &&
			this.state?.targetedCouponData.length === 0 &&
			this.state?.personalizedCouponData.length === 0
		) {
			return null;
		}
		return (
			<>
				<div className="mt-3 d-flex">
					{this.state.searchForm.promotionID.value ? (
						<div className="d-flex couponCloseSpan">
							<span className="mr-2">{`Promotion ID: ${this.state.searchForm?.promotionID.value}`}</span>
						</div>
					) : undefined}
					{this.state.searchForm.couponID.value ? (
						<div className="d-flex couponCloseSpan">
							<span className="mr-2">{`Coupon ID: ${this.state.searchForm?.couponID.value}`}</span>
						</div>
					) : undefined}

					{this.state.searchForm.couponType.value &&
					!this.state.searchForm?.promotionID.value ? (
						<div className="d-flex couponCloseSpan">
							<span className="mr-2">
								{`Coupon Type:  ${
									this.state.searchForm.couponType.value === ALL
										? 'All'
										: this.state.searchForm.couponType.value === PERSONALIZED
										? 'Personalized'
										: this.state.searchForm.couponType.value === TARGETED
										? 'Targeted'
										: this.state.searchForm.couponType.value === GENERIC
										? 'Generic'
										: undefined
								}`}
							</span>
							{/* <span class="cBlue">X</span> */}
						</div>
					) : undefined}
					{this.state.searchForm?.couponStatus.value ? (
						<div className="d-flex couponCloseSpan">
							<span className="mr-2">
								{`Coupon Status: ${
									this.state.searchForm?.couponStatus.value === ALL
										? 'All'
										: this.state.searchForm?.couponStatus.value === 'A'
										? 'Active'
										: this.state.searchForm?.couponStatus.value === 'D'
										? 'In Active'
										: undefined
								}`}
							</span>
							{/* <span class="cBlue">X</span> */}
						</div>
					) : undefined}
					{/* moment(this.state.searchForm.couponExpiryDateTime.value).format('YYYY-MM-DD HH:mm:ss') */}
					{this.state.searchForm?.couponExpiryDateTime.value ? (
						<div className="d-flex couponCloseSpan">
							<span className="mr-2">{`Coupon Expiry Date & Time: ${moment(
								this.state.searchForm.couponExpiryDateTime.value
							).format('YYYY-MM-DD HH:mm:ss')}`}</span>
						</div>
					) : undefined}
				</div>

				<span
					className="font-weight-bold"
					style={{
						margin: '10px 15px',
						display: 'block'
					}}
				>
					Search Results
				</span>
			</>
		);
	};

	render() {
		return (
			<div className="coupon-management-container">
				<div className="coupon-management-child">
					<Switch>
						<Route path="/MainPage/couponManagement/generateCoupon">
							<GenerateCoupon
								generateCouponCloseHandler={this.generateCouponCloseHandler}
								loggedInBrand={this.props.loggedInBrand}
							/>
						</Route>
						<Route path="/MainPage/couponManagement/couponPattern">
							<CouponPattern
								onCloseHandler={this.generateCouponCloseHandler}
								loggedInBrand={this.props.loggedInBrand}
							/>
						</Route>
					</Switch>
				</div>
				{this.state.couponView && (
					<div className="coupon-management">
						<h1 className="roboto-b-24 m-0">Coupon Management</h1>
						<hr />
						<div
							className="main-section-container"
							style={{ height: '81.6vh', overflowY: 'auto' }}
						>
							<div className="btn-container">
								{this.renderMainBtnGroup()}
								{this.renderCouponSearchFilter()}
							</div>
							<hr />
							{this.renderSecondaryBtnGroup()}
							<div
								className={`row ml-1 coupon-table-section ${
									this.state.showSearchedCouponTable ? 'd-block' : 'd-none'
								}`}
							>
								<hr />
								<div className="row" style={{ width: '99%' }}>
									<div className="couponTableContainer ">
										<>
											{this.renderSearchTags()}

											{this.state.genericCouponData?.length > 0 ? (
												<>
													<div className="table-header">
														<strong className="title"> Generic </strong>
														<span className="cGray">{`${this.state.genericCouponData.length} Promotions Found`}</span>
														<DownloadExlBtn
															dataToExport={() =>
																formateDataToExcel({
																	data: this.state
																		.genericCouponData,
																	couponType: GENERIC,
																	isTSSSBu: this.isTSSSBu
																})
															}
															saveAsFileName="GenericCoupon"
														/>
													</div>

													<CouponTable
														data={this.state.genericCouponData}
														keyField="promotionId"
														placeholder="Search Promotion Id"
														couponActionFormatter={
															this.couponActionFormatter
														}
														pageNumber={
															this.state.genericCouponDataCurrentPage
														}
														onPageChange={this.onPageChange}
														selectedGeneric={this.state.selectedGeneric}
														onSelectedGenericChange={
															this.onSelectedGenericChange
														}
														couponType={GENERIC}
														downloadCoupon={this.downloadCoupon}
														isTSSSBu={this.isTSSSBu}
													/>
												</>
											) : null}

											{this.state.targetedCouponData?.length > 0 ? (
												<>
													<div className="table-header">
														<strong className="title">Targeted</strong>
														<span className="cGray">{`${this.state.targetedCouponData.length} Promotions Found`}</span>
														<DownloadExlBtn
															dataToExport={() =>
																formateDataToExcel({
																	data: this.state
																		.targetedCouponData,
																	couponType: TARGETED,
																	isTSSSBu: this.isTSSSBu
																})
															}
															saveAsFileName="TargetedCoupon"
														/>
													</div>

													<CouponTable
														data={this.state.targetedCouponData}
														keyField="promotionId"
														placeholder="Search Promotion Id"
														couponActionFormatter={
															this.couponActionFormatter
														}
														onPageChange={this.onPageChange}
														pageNumber={
															this.state.targetedCouponDataCurrentPage
														}
														selectedTargeted={
															this.state.selectedTargeted
														}
														onSelectedTargetedChange={
															this.onSelectedTargetedChange
														}
														downloadCoupon={this.downloadCoupon}
														couponType={TARGETED}
														isTSSSBu={this.isTSSSBu}
													/>
												</>
											) : undefined}

											{this.state.personalizedCouponData?.length > 0 ? (
												<>
													<div className="table-header">
														<strong className="title">
															Personalized
														</strong>
														<span className="cGray">{`${this.state.personalizedCouponData.length} Promotions Found`}</span>
														<DownloadExlBtn
															dataToExport={() =>
																formateDataToExcel({
																	data: this.state
																		.personalizedCouponData,
																	couponType: PERSONALIZED,
																	isTSSSBu: this.isTSSSBu
																})
															}
															saveAsFileName="PersonalizedCoupon"
														/>
													</div>

													<CouponTable
														data={this.state.personalizedCouponData}
														keyField="promotionId"
														placeholder="Search Promotion Id"
														couponActionFormatter={
															this.couponActionFormatter
														}
														onPageChange={this.onPageChange}
														selectedPersonalized={
															this.state.selectedPersonalized
														}
														onSelectedPersonalizedChange={
															this.onSelectedPersonalizedChange
														}
														pageNumber={
															this.state
																.personalizedCouponDataCurrentPage
														}
														couponType={PERSONALIZED}
														downloadCoupon={this.downloadCoupon}
														isTSSSBu={this.isTSSSBu}
													/>
												</>
											) : undefined}
										</>
									</div>
								</div>{' '}
							</div>

							<div
								className={`row justify-content-center coupon-view-table-section ${
									this.state?.viewCouponTable ? 'd-block' : 'd-none'
								}`}
								style={{ width: '99%' }}
							>
								<div className="couponTableContainer ">
									{this.state?.couponSearchMan ||
									this.state?.viewCouponData?.length > 0 ? (
										<>
											<div className="d-flex">
												<div
													className="d-flex align-items-center mb-2"
													style={{ padding: '0px 15px' }}
												>
													<span className="cGray mr-1">
														Promotion ID:{' '}
													</span>
													<span className="font-weight-bold">
														{this.state.clickedPromotion.promotionId}
													</span>
													<span>
														&nbsp; (
														<span
															className="channelLink roboto-r-14 font-weight-normal"
															onClick={() => {
																this.getCouponVersions(
																	this.state.clickedPromotion
																		.promotionId
																);
															}}
														>
															Revisions in Coupons
														</span>
														)
													</span>
												</div>
												<div
													className="d-flex"
													style={{ padding: '0px 15px 15px' }}
												>
													<span className="cGray mr-1">
														Promotion End Date:{' '}
													</span>
													<span className="font-weight-bold">
														{moment(
															this.state.clickedPromotion.endDate
														).format('YYYY-MM-DD HH:mm:ss')}
													</span>
												</div>
												<div
													className="d-flex"
													style={{ padding: '0px 15px 15px' }}
												>
													<span className="cGray mr-1">
														Allow Coupon To Be Displayed:{' '}
													</span>
													<span className="font-weight-bold">
														{this.state.clickedPromotion.displayCoupons
															? 'Y'
															: 'N'}
													</span>
												</div>
											</div>
											<div className="d-flex">
												<span
													className="font-weight-bold mr-1"
													style={{ padding: '0px 0px 0px 15px' }}
												>
													{this.state?.viewCouponData[0]?.couponType ===
													ALL
														? 'All'
														: this.state?.viewCouponData[0]
																?.couponType === GENERIC_LIMITED
														? 'Generic Coupons'
														: this.state?.viewCouponData[0]
																?.couponType === GENERIC_UNLIMITED
														? 'Generic UnLimited Coupons'
														: this.state?.viewCouponData[0]
																?.couponType === PERSONALIZED
														? 'Personalized'
														: this.state?.viewCouponData[0]
																?.couponType === TARGETED
														? 'Targeted'
														: this.state?.viewCouponData[0]
																?.couponType === GENERIC
														? 'Generic'
														: null}
												</span>
												<span className="cGray">{`${this.state?.viewCouponData.length} Coupons Found`}</span>
											</div>
											<div
												style={{ padding: '5px 15px 15px 15px' }}
												className="couponTable"
											>
												<div
													className="col-4 d-flex"
													style={{ padding: '5px 15px 15px 15px' }}
												>
													<input
														type="text"
														className="form-control"
														id="searchText"
														placeholder="Search Coupon Id"
														value={this.state.couponVal}
														onChange={(evt) => {
															this.setState({
																couponVal: evt.target.value
															});
														}}
													/>
													<button
														className="clrBtn "
														onClick={() => {
															this.setState({
																couponVal: '',
																couponSearchMan: false
															});

															this.viewCouponDetails(
																this.state.clickedPromotion,
																VIEW,
																this.state
																	.targetedCouponDataCurrentPage
															);
															//   {this.onPageChange}
														}}
													>
														X
													</button>
													<button
														type="submit"
														className="btnNxtPrev ml-2 roboto-b-16"
														disabled={
															this.state.couponVal === undefined ||
															this.state.couponVal === ''
																? true
																: false
														}
														onClick={() => {
															this.setState({
																couponSearchMan: true
															});
															// this.state.searchForm.couponID.value = this.state.couponVal;
															let searchFormCouponID =
																this.state.searchForm.couponID;
															let _searchFormCouponID = {
																...searchFormCouponID,
																value: this.state.couponVal
															};
															let searchForm = {
																...this.state.searchForm,
																couponID: _searchFormCouponID
															};
															this.setState(
																{
																	searchForm
																},
																() => {
																	this.viewCouponDetails(
																		this.state.clickedPromotion,
																		SEARCH,
																		0
																	);
																	/* reset the search form coupon id */
																	// searchFormCouponID = this.state.searchForm.couponID;
																	_searchFormCouponID = {
																		...searchFormCouponID,
																		value: undefined
																	};
																	searchForm = {
																		...this.state.searchForm,
																		couponID:
																			_searchFormCouponID
																	};
																	this.setState({
																		searchForm
																	});
																}
															);
															// eslint-disable-next-line react/no-direct-mutation-state
															// this.state.searchForm.couponID.value = undefined;
														}}
													>
														Search
													</button>
												</div>
												<CouponTable
													data={this.state?.viewCouponData}
													keyField="couponId"
													placeholder="Search Coupon Id"
													pageNumber={
														this.state.viewCouponDataCurrentPage
													}
													couponType={VIEW}
													onPageChange={this.onPageChange}
													downloadCoupon={this.downloadCoupon}
													gotoCouponSearch={this.gotoCouponSearch}
													searchForm={this.state.searchForm}
													couponActionType={this.state.couponActionType}
													showLoadingButton={this.props.showLoadingButton}
													clickedPromotion={this.state.clickedPromotion}
													isTSSSBu={this.isTSSSBu}
												/>
											</div>
										</>
									) : (
										<div>
											<div style={{ textAlign: 'center' }}>
												No Data available
											</div>
											<div style={{ float: 'right' }}>
												<button
													className="cancel-btn1 roboto-b-16 "
													onClick={() => {
														this.gotoCouponSearch();
													}}
												>
													Cancel
												</button>
											</div>
										</div>
									)}
								</div>
								{this.state.showVersionHistory ? (
									<div id="scrollToCpnHistoryTable">
										<CouponVersionsTable
											cpnVersions={this.state.cpnVersionsData}
											viewCoupons={this.viewCoupons}
										/>
									</div>
								) : null}
							</div>
						</div>

						{this.state.confirmationModalShow ? (
							<ConfirmationModal
								show={this.state.confirmationModalShow}
								onHide={this.confirmationModalClose}
								onProceed={this.proceedToOperation}
								confirmmsg={this.state.confirmMsg}
							/>
						) : null}

						{this.state.informationModalShow ? (
							<InformationModal
								show={this.state.informationModalShow}
								onHide={this.informationModalClose}
								infoMsg={this.state.informationMsg}
								infoTitle={this.state.informationTitle}
							/>
						) : null}
						{this.state.groupInformationModalShow ? (
							<GroupInformationModal
								show={this.state.groupInformationModalShow}
								onHide={this.informationModalClose}
								infoMsg={this.state.informationMsg}
								infoTitle={this.state.informationTitle}
								errorMsg={this.state.errorMsg}
								splitErrorMsg
								addFilter
							/>
						) : null}
						{/* AuditCoupon */}
						{this.state.auditCouponPopup ? (
							<div
								className={
									this.state.auditCouponPopup
										? 'modal in-right d-block auditContainer'
										: 'modal'
								}
							>
								<div
									className="modal-dialog m-0 auditOffer-modal-bottom-right"
									role="document"
								>
									<div className="modal-content h-100">
										<div className="header-flex">
											<div
												className="back-div"
												onClick={() => {
													this.setState({ auditCouponPopup: false });
												}}
											>
												<div className="pr-2">
													<img src={chevronDown} alt="" />
												</div>
												<div>Back</div>
											</div>
											<div className="header-text">{`View Coupons - V${this.state.couponVerRow.versionId}`}</div>
										</div>
										<div
											className="container-fluid"
											style={{
												overflowY: 'scroll',
												height: 'calc(100% - 45px)'
											}}
										>
											<div className="row auditDetailsBody">
												<div className="col-md-12 pl-0 rightAudit">
													<div>
														<div className="d-flex pt-4">
															<div
																className={
																	this.state.couponVerRow
																		.action ===
																		'COUPON LIMIT CHANGED' ||
																	this.state.couponVerRow
																		.action ===
																		'COUPON EXP DATE CHANGED' ||
																	this.state.couponVerRow
																		.action ===
																		'COUPON DISPLAY FLAG CHANGED'
																		? 'legendContainer d-flex ml-auto'
																		: 'd-none'
																}
															>
																<div className="d-flex mb-2 align-items-center">
																	<div className="circle bgColorGray mr-2"></div>
																	<div>{`Old Value(s)`}</div>
																</div>
																<div className="d-flex mb-2 align-items-center pl-4">
																	<div className="circle bgColorBlue  mr-2"></div>
																	<div>{`New Value(s)`}</div>
																</div>
															</div>
														</div>
														<AuditCoupon
															action={this.state.couponVerRow.action}
															promoId={
																this.state.clickedPromotion
																	.promotionId
															}
															versionId={
																this.state.couponVerRow.versionId
															}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : null}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		diffJsonReplace: state.diffJsonReplace,
		diffJsonAdd: state.diffJsonAdd,
		diffJsonRemove: state.diffJsonRemove
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CouponManagement));
