import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import './customerManagement.scss';

import couponUploadNew from '../../assets/images/couponUploadNew.png';

import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import * as actionTypes from '../../store/actions';
import GroupInformationModal from '../GroupInformationModal';
import Toaster from '../../Components/Toaster/Toaster';
import appConstants from '../../utils/appConstants';

const { buBrand } = appConstants;

const CustomerManagement = (props) => {
	const [OffersDropdown, setDropdownVal] = useState([]);
	const [promoIdsOps, setPromoIdsOps] = useState('');
	const [groupInformationModalShow, setGrpInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState();
	const [informationTitle, setInformationMsgTitle] = useState();
	const [errorMsg, setErrMsg] = useState('');
	const [selectedValues, setSelectedValues] = useState('');
	const [brandList, setBrandList] = useState([]);
	const [programIdMap, setProgramIdMap] = useState([]);
	const [selectedBrand, setSelectBrand] = useState('');
	const [isCustomerService, setIsCustomerService] = useState(false);
	const $promoID = useRef();

	useEffect(() => {
		const {
			loggedInBrand: { programId, businessUnitGroupFunctionID },
			showLoadingButton
		} = props;
		const isCustomerService = businessUnitGroupFunctionID === buBrand.CUSTOMER_SERVICE.BUID;
		setIsCustomerService(isCustomerService);

		if (isCustomerService) {
			fetchBrandList({ showLoadingButton });
			return;
		}
		fetchLimitedPromotion({ programId, showLoadingButton });
	}, []);

	useEffect(() => {
		if (!selectedBrand.length) {
			return;
		}
		fetchBrandPromo(selectedBrand);
	}, [selectedBrand]);

	const fetchLimitedPromotion = ({ programId, showLoadingButton }) => {
		const payload = {
			searchPromotionRequest: {
				programId
				// "promotionId": searchOffersForm.offerId.value
			}
		};

		showLoadingButton(true);
		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		RestSvc.postData(urlTypes.offers_maintenance.searchLimitedPromotion, payload, svPromUrl)
			.then((response) => {
				const result = response.data.searchLimitedPromotionResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}
				setDropdownVal(result.promotionPolicyDetails || []);

				showLoadingButton(false);
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	const fetchBrandList = ({ showLoadingButton }) => {
		showLoadingButton(true);

		RestSvc.getData(urlTypes.offers_maintenance_cs.fetchBrandsForCSView)
			.then((response) => {
				const result = response.data.brandsProgramIdMapCsViewRes;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}

				setBrandList(result?.brandsList);
				setProgramIdMap(result?.prefixProgIdMap);
				showLoadingButton(false);
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	const fetchBrandPromo = (brand) => {
		const { showLoadingButton } = props;

		const payload = {
			getPromotionsForBrandForCSViewRequest: {
				brandName: brand
			}
		};

		showLoadingButton(true);
		RestSvc.postData(urlTypes.offers_maintenance_cs.getBrandPromos, payload)
			.then((response) => {
				const result = response.data.searchLimitedPromotionResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}
				const dropOptions = result.promotionPolicyDetails.map(({label,value}) => {
					return ({
						label: label.replace(/_/g, ' '),
						value
					})
				});
				setDropdownVal(dropOptions || []);
				showLoadingButton(false);
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	const uploadFileHandler = (event) => {
		const fileList = event.target.files;
		const { userFullName, loggedInBrand, showLoadingButton } = props;
		const payload = {
			NotificationDetailsRequestUI: {
				user: userFullName.operatorID,
				programId: loggedInBrand.programId,
				businessUnitGroupFunctionID: loggedInBrand.businessUnitGroupFunctionID,
				promotionId: promoIdsOps
			}
		};

		let url = urlTypes.offers_maintenance.uploadCustomers;
		if (isCustomerService) {
			payload.NotificationDetailsRequestUI.brand = selectedBrand;
			payload.NotificationDetailsRequestUI.programId = programIdMap[selectedBrand];
			url = urlTypes.offers_maintenance_cs.uploadCustomers;
		}

		const metaDataToSend = JSON.stringify(payload);
		const formData = new FormData();
		formData.append('user', metaDataToSend);
		formData.append('file', fileList[0]);

		showLoadingButton(true);

		RestSvc.postData(url, formData)
			.then((response) => {
				const result = response.data.NotificationDetailsResponse;
				setErrMsg(result?.businessError?.description || '');
				setInformationMsg(result.status);
				setGrpInformationModalShow(true);
				setInformationMsgTitle('Information');
				showLoadingButton(false);
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
		document.getElementById('idLoadCustomerFromFile').value = '';
	};

	const getBrandList = () => {
		let options = [
			<option key="opt-0" value="">
				Select Brand
			</option>
		];

		for (let brand of brandList) {
			options.push(
				<option key={brand} value={brand}>
					{brand.replace(/_/g, ' ')}
				</option>
			);
		}
		return options;
	};

	return (
		<div className="customer-management-screen">
			<h1 className="roboto-b-24 m-0">Promotion Customer Mapping</h1>
			<hr />
			<div style={{ height: '81.6vh' }}>
				<div className="row">
					{isCustomerService && (
						<div className="col-3">
							<label className="require">Brand</label>
							<select
								className="form-control remove_customSelectIcon"
								value={selectedBrand}
								onChange={(event) => {
									const val = event.target.value;
									setSelectBrand(val);

									/* searchable controller */
									setSelectedValues('');
									setPromoIdsOps('');
									$promoID.current.clearValue();
								}}
							>
								{getBrandList()}
							</select>
						</div>
					)}

					<div className="col-3">
						{/* dropdown for offer ids */}
						<div className="searchable-section">
							<label className="require">Promotion Id</label>
							 <Select
							 	ref={$promoID}
								className='form-control input-field promo-select p-0'
								name='promoID'
								options={OffersDropdown}
								placeholder='Select Promotion Id'
								defaultValue={selectedValues}
								// isClearable={true}
								isDisabled={props.disabled}
								// menuIsOpen={true}
								components={{
									IndicatorSeparator: () => null,
									NoOptionsMessage: () => (<div className="p-2">No result found</div>)
								  }}
								onChange={(value) => {
									let fieldValue = value?.value;
									setPromoIdsOps(fieldValue);
									
								}}
							/>
							{/* <Searchable
									value={selectedValues}
									selectStyle="btn-light"
									setSelectedValues={setSelectedValues}
									placeholder="Select Promotion Id" // by default "Search"
									notFoundText="No result found" // by default "No result found"
									options={OffersDropdown}
									onSelect={(value) => {
										setPromoIdsOps(value);
									}}
									listMaxHeight={200} //by default 140
									disabled={props.disabled}
							/> */}
						</div>
					</div>
					<div className="col d-flex align-items-end">
						<button
							style={{height:'38px'}}
							type="button"
							className="btnNxtPrev roboto-b-16 advSearch m-0"
							onClick={() => {
								document.getElementById('idLoadCustomerFromFile').click();
							}}
							disabled={!promoIdsOps}
							>
							Upload Customer
							<span className="ml-2">
								<img alt="Upload Customer" src={couponUploadNew} />
							</span>
							<input
								type="file"
								id="idLoadCustomerFromFile"
								className="d-none"
								onChange={uploadFileHandler}
							/>
						</button>
					</div>
				</div>
			</div>
			{/* Added by hesahghi for augment offer test */}
			{groupInformationModalShow ? (
				<GroupInformationModal
					show={groupInformationModalShow}
					onHide={() => {
						setGrpInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
					errorMsg={errorMsg}
					splitErrorMsg
					addFilter
				/>
			) : null}
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val })
	};
};
const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		loggedInBrand: state.loggedInBrand
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagement);
