import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './couponTable.scss';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from '../../utils/reactBootstrapTable2Toolkit';

/* let options = {} */
class BinTable extends Component {
	constructor(props) {
		super(props);

		function formatStatus(value, row, index) {
			return value == 'A' ? 'Active' : 'In Active';
		}

		function formatYesNo(value, row, index) {
			return value ? 'Y' : 'N';
		}
		function checkNull(value, row, index) {
			return value ? value : 0;
		}

		const columnsForSearchedCouponGeneric = [
			{
				dataField: 'promotionId',
				text: 'Promotion Id',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'binRangeCount',
				text: 'Number of BINs',
				headerAlign: 'center',
				/*  formatter: formatEndDate, */
				searchable: false
			},
			// {
			//     dataField: "displayCoupons",
			//     text: "Allow Coupon To Be Displayed",
			//     headerAlign: 'center',
			//     searchable: false,
			//     formatter: formatYesNo,
			// },
			// {
			//     dataField: "couponCount",
			//     text: "Coupon Count",
			//     headerAlign: 'center',
			//     align: "right",
			//     searchable: false
			// }
			{
				dataField: '',
				text: 'Actions',
				headerAlign: 'center',
				formatter: this.props.linkAction,
				headerStyle: { width: '70px' },

				searchable: false
			}
		];
		const columnsForSearchedCouponTargeted = [
			{
				dataField: 'promotionId',
				text: 'Promotion Id',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'endDate',
				text: 'Promotion EndDate And Time',
				headerAlign: 'center',
				/*  formatter: formatEndDate, */
				searchable: false
			},
			{
				dataField: 'displayCoupons',
				text: 'Allow Coupon To Be Displayed',
				headerAlign: 'center',
				searchable: false,
				formatter: formatYesNo
			},
			{
				dataField: 'couponCount',
				text: 'Coupon Count',
				headerAlign: 'center',
				align: 'right',
				searchable: false
			},
			{
				dataField: 'customerMappedCount',
				text: 'Customer mapped count',
				headerAlign: 'center',
				align: 'right',
				searchable: false
			},
			{
				dataField: '',
				text: 'Actions',
				headerAlign: 'center',
				formatter: this.props.linkAction,
				headerStyle: { width: '70px' },

				searchable: false
			}
		];
		const columnsForViewCoupon = [
			{
				dataField: 'couponId',
				text: 'Coupon Id',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'couponStatus',
				text: 'Coupon Status',
				headerAlign: 'center',
				// hidden: true,
				searchable: false,
				formatter: formatStatus
			},
			{
				dataField: 'couponEndDate',
				text: 'Coupon Expiry Date And Time',
				headerAlign: 'center',
				/*  formatter: formatEndDate, */
				searchable: false
			},
			{
				dataField: 'couponLimit',
				text: 'Coupon Limit',
				headerAlign: 'center',
				align: 'right',
				searchable: false
			},

			{
				dataField: 'couponRedemptionLimit',
				text: 'Coupon Redeemed Count',
				headerAlign: 'center',
				align: 'right',
				formatter: checkNull,
				//formatter: this.linkAction,

				searchable: false
			}
		];
		this.state = {
			// For displaying data
			data: props.data,
			columns:
				props.columns == 'searchedCouponGeneric'
					? columnsForSearchedCouponGeneric
					: props.columns == 'searchedCouponTargeted'
					? columnsForSearchedCouponTargeted
					: columnsForViewCoupon,
			isFollow: true,
			placeholder: props.placeholder,
			sizePerPage: 8,
			selectedProducts: []
		};

		this.options = {
			onPageChange: this.props.onPageChange,
			sizePerPage: this.state.sizePerPage,
			prePageText: 'Previous',
			nextPageText: 'Next',
			paginationSize: 1,
			alwaysShowAllBtns: true, // Always show next and previous button
			withFirstAndLast: false,
			hidePageListOnlyOnePage: true
		};

		//this.linkAction = this.linkAction.bind(this);
	}

	selectRow = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			//debugger
			let newSelectedProducts = [...this.props.selectedGeneric];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row?.promotionId);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.promotionId);
			}
			/*  this.setState({
         selectedProducts:newSelectedProducts
       },()=>{
       }) */
			this.props.onSelectedGenericChange(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			//debugger
			let newSelectedProducts = [...this.props.selectedGeneric];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.promotionId;
				});
			}
			this.props.onSelectedGenericChange(newSelectedProducts);
			/* setSelectedProducts(newSelectedProducts); */
			/* this.setState({
        selectedProducts:newSelectedProducts
      }) */
		}
	};
	selectRowTargeted = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			//debugger
			let newSelectedProducts = [...this.props.selectedTargeted];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row?.promotionId);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.promotionId);
			}
			/*  this.setState({
         selectedProducts:newSelectedProducts
       },()=>{
		   
       }) */
			this.props.onSelectedTargetedChange(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			//debugger
			let newSelectedProducts = [...this.props.selectedTargeted];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.promotionId;
				});
			}
			this.props.onSelectedTargetedChange(newSelectedProducts);
			/* setSelectedProducts(newSelectedProducts); */
			/* this.setState({
        selectedProducts:newSelectedProducts
      }) */
		}
	};
	selectRowPersonalised = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			//debugger
			let newSelectedProducts = [...this.props.selectedPersonalised];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row?.promotionId);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.promotionId);
			}
			/*  this.setState({
         selectedProducts:newSelectedProducts
       },()=>{
       }) */
			this.props.onSelectedPersonalisedChange(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			//debugger
			let newSelectedProducts = [...this.props.selectedPersonalised];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.promotionId;
				});
			}
			this.props.onSelectedPersonalisedChange(newSelectedProducts);
			/* setSelectedProducts(newSelectedProducts); */
			/* this.setState({
        selectedProducts:newSelectedProducts
      }) */
		}
	};

	render() {
		/*  const options = {
         // pageStartIndex: 0,
         sizePerPage: 5,
         hideSizePerPage: true,
         hidePageListOnlyOnePage: true
     }; */

		return (
			<ToolkitProvider
				keyField="id"
				data={this.state.data}
				columns={this.state.columns}
				search
			>
				{(props) => (
					<div
						style={{ padding: '0px 15px 15px 15px', width: '50%' }}
						className="couponTable"
					>
						{/*  <h1 className="h2">Products</h1> */}
						{/* <SearchBar {...props.searchProps}  placeholder={this.state.placeholder}/> */}
						<BootstrapTable
							{...props.baseProps}
							/*  keyField= {props.columns == "searchedCouponGeneric" ? "promotionId" : props.columns == "searchedCouponTargeted" ? "promotionId" : "couponId"} */
							/* keyField="promotionId" */
							keyField="promotionId"
							noDataIndication={() => <div>No Data available</div>}
							data={this.props.data}
							columns={this.state.columns}
							filter={filterFactory()}
							// selectRow={this.props.type=="GENERIC"?this.selectRow:this.props.type=="TARGETED"?this.selectRowTargeted:this.props.type=="PERSONALIZED"?this.selectRowPersonalised:undefined}
							/*  remote={true} */
							//  pagination={paginationFactory(this.options)}
						/>
						<div
							className={
								this.props.pageNumber == 1 && this.props.data.length == 1
									? 'd-none'
									: 'd-flex'
							}
						>
							<div
								style={{
									'justify-self': 'center',
									flex: 1,
									'text-align': 'center',
									'margin-top': '20px'
								}}
							>
								<button
									className={
										this.props.pageNumber == 1
											? 'btn btn-primary m-2 disabled'
											: 'btn btn-primary m-2'
									}
									onClick={() =>
										this.props.onPageChange(this.props.type, 'Previous')
									}
								>
									Previous
								</button>
								<div className="btn btn-primary m-2">{this.props.pageNumber}</div>
								<button
									className={
										this.props.maxPage == this.props.pageNumber
											? 'disabled btn btn-primary m-2'
											: 'btn btn-primary m-2'
									}
									onClick={() => this.props.onPageChange(this.props.type, 'Next')}
								>
									Next
								</button>
							</div>
							{/* {this.props.type == "VIEW" ? 
                             <div style={{
                              "margin-top": "20px"
                          }} class="mr-4">
                             <button className="cancel-btn1 roboto-b-16 " onClick={() => {
                           
                              this.props.gotoCouponSearch();

                          }} >Cancel</button></div>: undefined} */}

							{/* <div style={{
    "margin-top": "20px"
}}>
                             <button type="button" className="cancel-btn1 roboto-b-16" onClick={()=>this.props.downloadCoupon(this.props.type)}>Download</button>
                             </div>  */}
						</div>
					</div>
				)}
			</ToolkitProvider>
		);
	}
}

export default BinTable;
