import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
import CustomReactMultiSelectComponent from './customfields/CustomReactMultiSelectComponent';
import { InformationModal } from '../../../MainContent/InformationModal';
import Issuers from './Issuers';

// Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
//   return this.test('unique', message, function (list) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
	return this.test('unique', message, function (list) {
		if (!list) list = [];
		return list.length === new Set(list.map(mapper)).size;
	});
});

const PaymentGroup = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	const { setFieldValue } = useFormikContext();
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;

	const filednamepmtelg = props.filednamepmtelg;
	let fieldPaymentGroup =
		props.pgType == 'paymentGroupAdded'
			? filednamepmtelg?.payment?.paymentGroupAdded
			: props.pgType == 'paymentGroupRemoved'
			? filednamepmtelg?.payment?.paymentGroupRemoved
			: filednamepmtelg?.payment?.paymentGroup;
	const valueEligbility = props.valueEligbility;
	const show = props.show;
	const setShow = props.setShow;
	const aggregatorOps = props.aggregatorOps;
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState('');
	const [informationTitle, setInformationTitle] = useState('');

	const informationModalClose = () => {
		setInformationModalShow(false);
	};
	useEffect(() => {}, []);

	return (
		<div className={isAuditFlw ? 'container' : ''}>
			<FieldArray name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}`}>
				{({ remove }) => (
					<>
						{filednamepmtelg.payment &&
							fieldPaymentGroup &&
							fieldPaymentGroup.map((item, index) => (
								<>
									<div className={isAuditFlw ? 'd-none' : 'row'}>
										<div className="col-md-10">
											<div className="row">
												<div className="col-md-4 font-weight-bold">
													{index == 0 ? 'Aggregators and Issuers' : null}
												</div>
												<div className="col-md-6"></div>
											</div>
										</div>
										<div className="col-md-2">
											<div className="row justify-content-end mr-2">
												<div className="">
													{fieldPaymentGroup.length > 1 ? (
														<div className="row justify-content-end mr-7rem">
															<button
																type="button"
																// className="secondary"
																className="addDeleteBtn"
																onClick={() => {
																	remove(index);
																	setTimeout(() => {
																		setShow(false);
																	}, 500);
																	setTimeout(() => {
																		setShow(true);
																	}, 500);
																}}
															></button>
														</div>
													) : null}
												</div>
												<div className="">
													{index == 0 ? (
														<button
															className={
																'addPlusBtn float-right mr-2'
															}
															type="button"
															onClick={() => {
																if (!fieldPaymentGroup) {
																	fieldPaymentGroup = [];
																}
																if (fieldPaymentGroup?.length < 5) {
																	if (
																		filednamepmtelg.payment
																			.emiType
																	) {
																		fieldPaymentGroup.push({
																			aggregators: [],
																			issuers: [
																				{
																					issuerPaymentMode:
																						filednamepmtelg.isBinRangePromotion
																							? 'CARD'
																							: '',
																					issuerPaymentType:
																						'',
																					issuerPaymentSubType:
																						'',
																					issuerProvider:
																						'',
																					cashbackInformation:
																						{
																							isCashbackSame: true,
																							paymentInformation:
																								{
																									paymentMode:
																										'',
																									provider:
																										'',
																									paymentSubType:
																										'',
																									paymentType:
																										''
																								},
																							creditBy:
																								{},
																							expireOn:
																								{}
																						},
																					emi: {
																						emiScale: [
																							{
																								tenurePeriodInMonths:
																									'',
																								interestRate:
																									'',
																								cappedAmount:
																									JSON.parse(
																										props.fullVal
																									)
																										.promotionPolicy
																										.rewardLimit
																										? JSON.parse(
																												props.fullVal
																										  )
																												.promotionPolicy
																												.rewardLimit
																										: ''
																							}
																						]
																					}
																				}
																			]
																		});
																	} else {
																		fieldPaymentGroup.push({
																			aggregators: [],
																			issuers: [
																				{
																					issuerPaymentMode:
																						filednamepmtelg.isBinRangePromotion
																							? 'CARD'
																							: '',
																					issuerPaymentType:
																						'',
																					issuerPaymentSubType:
																						'',
																					issuerProvider:
																						'',
																					cashbackInformation:
																						{
																							isCashbackSame: true,
																							paymentInformation:
																								{
																									paymentMode:
																										'',
																									provider:
																										'',
																									paymentSubType:
																										'',
																									paymentType:
																										''
																								},
																							creditBy:
																								{},
																							expireOn:
																								{}
																						}
																				}
																			]
																		});
																	}
																	setTimeout(() => {
																		setShow(false);
																	}, 500);
																	setTimeout(() => {
																		setShow(true);
																	}, 500);
																	setFieldValue('', '');
																} else {
																	setInformationTitle(
																		'Information'
																	);
																	setInformationMsg(
																		'User cannot add more than 5 payment blocks or more than 10 issuers in each payment block under the “Aggregator Issuer section“'
																	);
																	setInformationModalShow(true);
																}
															}}
														></button>
													) : null}
												</div>
											</div>
										</div>
									</div>
									<div className="aggregatorBox">
										{/* {fieldPaymentGroup.length>1?
                        <div className="row justify-content-end mr-7rem">
                          
                          <button
                          type="button"
                          // className="secondary"
                          className="addDeleteBtn"
                          onClick={() => {
                            remove(index)
                            setTimeout(() => {
                              setShow(false);
                            }, 500); 
                            setTimeout(() => {
                            setShow(true);
                            }, 500);
                          }}
                        >
                        </button>
                          

                        </div>:null
                      } */}
										{/* <CustomSelect
                                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.aggregators`}
                                label={"Aggregators"}
                                options={getEmiDropdown()}
                                smallText=""
                              /> */}
										{isAuditFlw && props.pgType == 'paymentGroup' ? (
											<>
												<div
													className={
														fieldPaymentGroup[index].aggregatorsAdded
															?.length > 0 ||
														fieldPaymentGroup[index].aggregatorsRemoved
															?.length > 0
															? 'box-blackout-period-new lightBlue'
															: 'd-none'
													}
												>
													<label className="font-weight-bold">
														Aggregators
													</label>
													{fieldPaymentGroup[index].aggregatorsAdded
														?.length > 0 ? (
														<>
															<div className="issuerOuterBox mb-3">
																<div className="pl-2">
																	<label className="font-weight-bold">
																		Added (
																		{
																			fieldPaymentGroup[index]
																				.aggregatorsAdded
																				?.length
																		}
																		)
																	</label>
																</div>
																<div className="row w-auto">
																	{fieldPaymentGroup[
																		index
																	].aggregatorsAdded.map(
																		(item, ind) => {
																			return (
																				<span
																					key={ind}
																					className="channelNodeAudit mb-2"
																				>
																					{
																						item.aggregatorName
																					}
																				</span>
																			);
																		}
																	)}
																</div>
															</div>
														</>
													) : undefined}
													{fieldPaymentGroup[index].aggregatorsRemoved
														?.length > 0 ? (
														<>
															<div className="issuerOuterBox mb-3">
																<div className="pl-2">
																	<label className="font-weight-bold">
																		Removed (
																		{
																			fieldPaymentGroup[index]
																				.aggregatorsRemoved
																				?.length
																		}
																		)
																	</label>
																</div>
																<div className="row w-auto">
																	{fieldPaymentGroup[
																		index
																	].aggregatorsRemoved.map(
																		(item, i) => {
																			return (
																				<span
																					key={i}
																					className="channelNodeAudit mb-2 text-danger"
																				>
																					{
																						item.aggregatorName
																					}
																				</span>
																			);
																		}
																	)}
																</div>
															</div>
														</>
													) : undefined}
												</div>
											</>
										) : show ? (
											<CustomReactMultiSelectComponent
												name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.aggregators`}
												label={'Aggregators'}
												smallText=""
												aggregatorOps={aggregatorOps}
												// validate={(val)=>customValidateAggregator(val,index)}
											/>
										) : null}

										{/* <table className="mt-10 bg-white"> */}
										{/* <tr>
                <th>Issuer Payment Mode</th>
                <th>Issuer Payment Type</th>
                <th>Issuer Payment SubType</th>
                <th>Issuer Provider</th>
                {fieldbenefit?.benefitType=="CASHBACK"?<th className="w-20">Cashback Info</th>:null}
                <th>
                  <button
                    className="addPlusBtn"
                    type="button"
                    onClick={() => {
                      
                      fieldPaymentGroup &&
                        fieldPaymentGroup[index].issuers.push({
                          "issuerPaymentMode":"",
                          "issuerPaymentType":"",
                          "issuerPaymentSubType":"",
                          "issuerProvider":"",
                          "cashbackInformation":{
                            "isCashbackSame":true,
                                            "paymentInformation":{
                                               "paymentMode":"",
                                               "provider":"",
                                               "paymentSubType":"",
                                               "paymentType":""
                                            },
                                            "creditBy":{
                                             
                                            },
                                            "expireOn":{
                                               
                                            }
                                         }
                       });
                      setFieldValue("","");
                    }}
                  >
                    
                  </button>
                </th>
              </tr> */}
										<div className={'d-flex justify-content-end mr-7rem'}>
											<button
												className="addPlusBtn"
												type="button"
												onClick={() => {
													if (
														fieldPaymentGroup[index]?.issuers?.length <
														10
													) {
														if (filednamepmtelg.payment.emiType) {
															fieldPaymentGroup &&
																fieldPaymentGroup[
																	index
																].issuers.push({
																	issuerPaymentMode:
																		filednamepmtelg.isBinRangePromotion
																			? 'CARD'
																			: '',
																	issuerPaymentType: '',
																	issuerPaymentSubType: '',
																	issuerProvider: '',
																	cashbackInformation: {
																		isCashbackSame: true,
																		paymentInformation: {
																			paymentMode: '',
																			provider: '',
																			paymentSubType: '',
																			paymentType: ''
																		},
																		creditBy: {},
																		expireOn: {}
																	},
																	emi: {
																		emiScale: [
																			{
																				tenurePeriodInMonths:
																					'',
																				interestRate: '',
																				cappedAmount:
																					JSON.parse(
																						props.fullVal
																					)
																						.promotionPolicy
																						.rewardLimit
																						? JSON.parse(
																								props.fullVal
																						  )
																								.promotionPolicy
																								.rewardLimit
																						: ''
																			}
																		]
																	}
																});
														} else {
															fieldPaymentGroup &&
																fieldPaymentGroup[
																	index
																].issuers.push({
																	issuerPaymentMode:
																		filednamepmtelg.isBinRangePromotion
																			? 'CARD'
																			: '',
																	issuerPaymentType: '',
																	issuerPaymentSubType: '',
																	issuerProvider: '',
																	cashbackInformation: {
																		isCashbackSame: true,
																		paymentInformation: {
																			paymentMode: '',
																			provider: '',
																			paymentSubType: '',
																			paymentType: ''
																		},
																		creditBy: {},
																		expireOn: {}
																	}
																});
														}
														setTimeout(() => {
															setShow(false);
														}, 500);
														setTimeout(() => {
															setShow(true);
														}, 500);
														setFieldValue('', '');
													} else {
														setInformationTitle('Information');
														setInformationMsg(
															'User cannot add more than 5 payment blocks or more than 10 issuers in each payment block under the “Aggregator Issuer section“'
														);
														setInformationModalShow(true);
													}
												}}
											></button>
										</div>
										{isAuditFlw ? (
											<>
												<div
													className={
														fieldPaymentGroup[index].issuersAdded
															?.length > 0 ||
														fieldPaymentGroup[index].issuersRemoved
															?.length > 0 ||
														fieldPaymentGroup[index].issuers?.length > 0
															? 'box-blackout-period-new lightBlue'
															: 'd-none'
													}
												>
													<div
														className={
															fieldPaymentGroup[index].issuersAdded
																?.length > 0
																? 'issuerOuterBox mb-3 auditHide addedSection'
																: 'd-none'
														}
													>
														<label className="font-weight-bold">
															Added (
															{
																fieldPaymentGroup[index]
																	.issuersAdded?.length
															}
															)
														</label>
														<Issuers
															filednamepmtelg={props.filednamepmtelg}
															valueEligbility={props.valueEligbility}
															fieldbenefit={props.fieldbenefit}
															show={props.show}
															setShow={props.setShow}
															paymentProviderOps={
																props.paymentProviderOps
															}
															paymentModeOps={props.paymentModeOps}
															paymentTypeOps={props.paymentTypeOps}
															aggregatorOps={props.aggregatorOps}
															fullVal={props.fullVal}
															index={index}
															pgType={props.pgType}
															isType="issuersAdded"
														/>
													</div>
													<div
														className={
															fieldPaymentGroup[index].issuersRemoved
																?.length > 0
																? 'issuerOuterBox mb-3 auditHide removedSection'
																: 'd-none'
														}
													>
														<label className="font-weight-bold">
															Removed (
															{
																fieldPaymentGroup[index]
																	.issuersRemoved?.length
															}
															)
														</label>
														<Issuers
															filednamepmtelg={props.filednamepmtelg}
															valueEligbility={props.valueEligbility}
															fieldbenefit={props.fieldbenefit}
															show={props.show}
															setShow={props.setShow}
															paymentProviderOps={
																props.paymentProviderOps
															}
															paymentModeOps={props.paymentModeOps}
															paymentTypeOps={props.paymentTypeOps}
															aggregatorOps={props.aggregatorOps}
															fullVal={props.fullVal}
															index={index}
															pgType={props.pgType}
															isType="issuersRemoved"
														/>
													</div>
													<div
														className={
															fieldPaymentGroup[index].issuers
																?.length > 0
																? 'issuerOuterBox mb-3'
																: 'd-none'
														}
													>
														<label
															className={
																props.pgType == 'paymentGroup'
																	? 'font-weight-bold'
																	: 'd-none'
															}
														>
															Modified (
															{
																fieldPaymentGroup[index].issuers
																	?.length
															}
															)
														</label>
														<Issuers
															filednamepmtelg={props.filednamepmtelg}
															valueEligbility={props.valueEligbility}
															fieldbenefit={props.fieldbenefit}
															show={props.show}
															setShow={props.setShow}
															paymentProviderOps={
																props.paymentProviderOps
															}
															paymentModeOps={props.paymentModeOps}
															paymentTypeOps={props.paymentTypeOps}
															aggregatorOps={props.aggregatorOps}
															fullVal={props.fullVal}
															index={index}
															pgType={props.pgType}
															isType="issuers"
														/>
													</div>
												</div>
											</>
										) : (
											<Issuers
												filednamepmtelg={props.filednamepmtelg}
												valueEligbility={props.valueEligbility}
												fieldbenefit={props.fieldbenefit}
												show={props.show}
												setShow={props.setShow}
												paymentProviderOps={props.paymentProviderOps}
												paymentModeOps={props.paymentModeOps}
												paymentTypeOps={props.paymentTypeOps}
												aggregatorOps={props.aggregatorOps}
												fullVal={props.fullVal}
												index={index}
												pgType={props.pgType}
												isType="issuers"
											/>
										)}

										{/* {filednamepmtelg.payment &&
          (filednamepmtelg.payment.emiType) ? (
            <table className="mt-10 bg-white">
              <tr>
                <th>Tenure Period In Months</th>
                <th>Interest Rate</th>
                <th>Amount Capping</th>
                <th>
                  <button
                    className="addPlusBtn"
                    type="button"
                    onClick={() => {
                      
                      fieldPaymentGroup[index].emi &&
                      fieldPaymentGroup[index].emi.emiScale.push({
                          tenurePeriodInMonths: "",
                          interestRate: "",
                          cappedAmount:JSON.parse(props.fullVal).promotionPolicy.rewardLimit?JSON.parse(props.fullVal).promotionPolicy.rewardLimit:""
                        });
                      setFieldValue("","");
                    }}
                  >
                    
                  </button>
                </th>
              </tr>

              <FieldArray
                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.emi.emiScale`}
              >
                {({ insert, remove, push }) => (
                  <>
                    {fieldPaymentGroup[index].emi &&
                      fieldPaymentGroup[index].emi.emiScale &&
                      fieldPaymentGroup[index].emi.emiScale.map((item, i) => (
                        <tr className="payment-Conditions">
                          <td>
                            <div className="col-md-12">
                              
                              <Field
                                className="form-control m-2"
                                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.emi.emiScale.${i}.tenurePeriodInMonths`}
                                type="text"
                              />
                              <ErrorMessage
                                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.emi.emiScale.${i}.tenurePeriodInMonths`}
                                component="div"
                                className="field-error"
                              />
                            </div>
                          </td>

                          <td>
                            <div className="col-md-12">
                              <Field
                                className="form-control m-2"
                                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.emi.emiScale.${i}.interestRate`}
                                type="text"
                              />
                              <ErrorMessage
                                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.emi.emiScale.${i}.interestRate`}
                                component="div"
                                className="field-error"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="col">
                              <button
                                type="button"
                                className="addDeleteBtn"
                                onClick={() => remove(index)}
                              >
                                
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
					)}
                  </>
                )}
              </FieldArray>
            </table>
          ) : filednamepmtelg.payment ? (
            fieldPaymentGroup[index].emi ? (
              (fieldPaymentGroup[index].emi.emiScale = undefined)
            ) : null
          ) : null} */}

										{/* Aggregators block ends */}
									</div>
								</>
							))}
					</>
				)}
			</FieldArray>
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={informationModalClose}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

export default PaymentGroup;
