import React, { useRef, useState } from 'react';

import './segmentManagement.scss';
import { Form, Formik } from 'formik';
import { FormikGroup } from '../../Components/Input/FormikControl';
import { fieldValidator } from '../GenerateCoupon/GenerateCouponForm';
import appLabels from '../../utils/appLabels';
import { deleteData, getData, postData } from '../../libraries/offer-services';
import { segmentEndPoint } from '../../libraries/offer-service-urls';
import * as actionTypes from '../../store/actions';
import { connect } from 'react-redux';
import ModalPopup from '../../Components/ModalPopup/ModalPopup';
import Toaster from '../../Components/Toaster/Toaster';
import ReactTable from '../../Components/ReactTable/ReactTable';
import moment from 'moment';
import Input from '../../Components/Input/Input';
import deleteIcon from '../../assets/images/delete.png';
import Delete from '../../assets/images/deleteIcon.png';

const { common: commonLabels, segmentManagement: pageLabels } = appLabels;

const lengthLimitValidation = ({ min = 1, max = 100 }) => {
	return (value) => {
		let error = null;
		if (value == null || value.length === 0) {
			return null;
		}
		if (value.length < min || value.length > max) {
			error = `Maximum characters limit is ${max}`;
		}
		return error;
	};
};

const characterValidation = () => {
	return (value) => {
		let error = null;
		if (value == null || value.length === 0) {
			return null;
		}
		const regx = /^[a-zA-Z0-9_]*$/;
		if (!regx.test(value)) {
			error = `Character not allowed`;
		}
		return error;
	};
};

const segmentTypeOptions = [
	{
		value: '',
		label: 'Segment Type'
	},
	{
		value: 'Customer',
		label: 'Customer'
	},
	{
		value: 'Employee',
		label: 'Employee'
	},
	{
		value: 'TSSS',
		label: 'TSSS'
	}
];

const initFormValues = () => {
	const initialValues = {
		segmentType: 'Customer',
		segmentName: '',
		segmentDescription: ''
	};
	return initialValues;
};

const AddSegmentForm = ({ initFormValues, onSubmit, segmentSubmitRef }) => {
	const formControls = (formik) => {
		const inputs = [
			[
				{
					control: 'select',
					...pageLabels.formLabels.segmentType,
					options: segmentTypeOptions,
					name: 'segmentType',
					controlClass: 'vertical-group',
					readOnly: true
				}
			],
			[
				{
					control: 'input',
					...pageLabels.formLabels.segmentName,
					name: 'segmentName',
					controlClass: 'vertical-group',
					required: true,
					validate: fieldValidator([
						'require',
						lengthLimitValidation({
							min: 1,
							max: 100
						}),
						characterValidation()
					])
				}
			],
			[
				{
					control: 'input',
					...pageLabels.formLabels.segmentDescription,
					name: 'segmentDescription',
					controlClass: 'vertical-group',
					required: true,
					validate: fieldValidator([
						'require',
						lengthLimitValidation({
							min: 1,
							max: 150
						})
					])
				}
			]
		];

		return <FormikGroup inputs={inputs} />;
	};
	return (
		<Formik initialValues={initFormValues()} onSubmit={onSubmit}>
			{(formik) => {
				return (
					<Form>
						{formControls(formik)}
						<button
							ref={segmentSubmitRef}
							type="submit"
							className="btn btn-primary mt-3 d-none"
							disabled={!formik.isValid || formik.isSubmitting}
						>
							{commonLabels.add}
						</button>
					</Form>
				);
			}}
		</Formik>
	);
};

const SearchSegmentForm = ({ initFormValues, onSubmit, submitRef }) => {
	const formControls = (formik) => {
		const inputs = [
			[
				{
					control: 'select',
					label: 'Created By',
					placeholder: 'Created By',
					options: [
						{
							value: 'all',
							label: 'All'
						},
						{
							value: 'me',
							label: 'Me'
						}
					],
					name: 'createdBy',
					controlClass: 'vertical-group'
				}
			]
		];
		return <FormikGroup inputs={inputs} />;
	};

	return (
		<Formik initialValues={initFormValues} onSubmit={onSubmit}>
			{(formik) => {
				return (
					<Form>
						{formControls(formik)}
						<button
							ref={submitRef}
							type="submit"
							disabled={
								!formik.isValid ||
								formik.isSubmitting ||
								formik.values.patterns?.length === 0
							}
							className="btnNxtPrev roboto-b-16 d-none"
						>
							{commonLabels.submit}
						</button>
					</Form>
				);
			}}
		</Formik>
	);
};

const SegmentManagement = ({ loggedInBrand, showLoadingButton, assignedResources }) => {
	const segmentSubmitRef = useRef();
	const searchSegmentSubmitRef = useRef();

	const [addSegmentView, setAddSegmentView] = useState(false);
	const [infoModal, setInfoModal] = useState({ infoShow: false, infoMsg: '' });
	const [deleteConfirmModal, setDeleteConfirmModal] = useState({ infoShow: false, infoMsg: '' });
	const [segmentList, setSegmentList] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [selectedSegmentList, setSelectedSegmentList] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [searchSegmentForm, setSearchSegmentForm] = useState({ createdBy: 'me' });

	const deleteSegmentAllowed = assignedResources.includes('OC012');

	const resetSelectedSegmentList = () => {
		setSelectAll(false);
		setSelectedSegmentList([]);
	};

	const rowSelectAllHandler = () => {
		const _selectAll = !selectAll;

		setSelectAll(_selectAll);
		setSegmentList((segments) => {
			let _segments = [...segments];
			_segments = _segments.map((val) => {
				let _val = { ...val, isSelected: _selectAll };
				return _val;
			});
			return _segments;
		});
		let newSelectedSegments = _selectAll ? [...segmentList] : [];
		setSelectedSegmentList(newSelectedSegments);
	};

	const rowSelectHandler = (e, ind, segment) => {
		setSegmentList((segmentList) => {
			const isRowSelected = e.target.checked;
			let segments = [...segmentList];
			const segmentInd = segments.findIndex((v) => v.ind === segment.ind);
			segments[segmentInd] = {
				...segment,
				isSelected: isRowSelected
			};

			let newSelectedSegments = segments.filter((v) => v.isSelected);
			setSelectedSegmentList(newSelectedSegments);
			setSelectAll(newSelectedSegments.length === segments.length);
			return segments;
		});
	};

	const deleteHandler = (data) => {
		setSelectedSegmentList(data);
		const segmentNames = data.map((val, i) => val.customerSegmentName);
		const segmentLength = segmentNames.length;
		setDeleteConfirmModal({
			infoShow: true,
			infoMsg: `Are you sure to delete ${segmentLength} segment${
				segmentLength > 1 ? 's' : ''
			} ${segmentNames.join(', ')}`
		});
	};

	const deleteSegment = (data, callBack = null) => {
		const payload = {
			List: data
		};

		showLoadingButton(true);
		deleteData(segmentEndPoint.deleteSegment, payload)
			.then((response) => {
				const result = response.data.segmentResponse;
				Toaster({ message: result.statusMessage });
				showLoadingButton(false);
				callBack?.();
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	const deleteConfirmHandler = (type) => {
		setDeleteConfirmModal({ infoShow: false, infoMsg: '' });
		if (type === 'yes') {
			const segmentsId = selectedSegmentList.map((val, i) => val.customerSegmentId);
			deleteSegment(segmentsId, () => {
				resetSelectedSegmentList();
				viewSegmentHandler(searchSegmentForm, { setSubmitting: null });
			});
		}
	};

	const actionFormatter = ({ value, row, index }) => {
		return (
			<div
				className="action-container"
				title={`${
					deleteSegmentAllowed
						? pageLabels.deleteSegmentTitle
						: pageLabels.notAllowedTitle
				}`}
			>
				<span
					className={`pointer ${deleteSegmentAllowed ? '' : 'disabled'}`}
					onClick={() => {
						deleteHandler([row]);
					}}
				>
					<img alt="Delete" src={deleteIcon} className="highlight" />
				</span>
			</div>
		);
	};

	const checkFormatter = ({ value, pattern, ind, isSelected, key }) => {
		return (
			<Input
				customClasses="table-checkbox"
				elementType="checkbox"
				changed={(e) => {
					rowSelectHandler(e, ind, pattern);
				}}
				checked={pattern.isSelected}
				elementConfig={{
					type: 'checkbox',
					id: `row-select-${ind}`
				}}
			/>
		);
	};

	const getTableData = () => {
		const columns = [
			{
				isDummyField: true,
				dataField: 'selectColumn',
				text: 'Select ',
				headerAlign: 'center',
				align: 'center',
				headerStyle: {
					width: '50px'
				},
				style: {
					width: '50px'
				},
				headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
					return (
						<Input
							customClasses="table-checkbox"
							elementType="checkbox"
							changed={rowSelectAllHandler}
							checked={selectAll}
							elementConfig={{
								type: 'checkbox',
								id: 'row-select-all'
							}}
						/>
					);
				},
				formatter: (value, row, index) =>
					checkFormatter({
						value,
						pattern: row,
						ind: index
					})
			},
			{
				dataField: 'customerSegmentName',
				text: pageLabels.table.segmentName
			},
			{
				dataField: 'customerSegmentDescription',
				text: pageLabels.table.segmentDescription
			},
			{
				dataField: 'createdBy',
				text: pageLabels.table.createdBy
			},
			{
				dataField: 'createdOn',
				text: pageLabels.table.createdOn,
				formatter: (value, row, index) => moment(value).format('DD-MM-YYYY HH:mm')
			},
			{
				isDummyField: true,
				dataField: 'actionColumn',
				text: pageLabels.table.action,
				searchable: false,
				headerAlign: 'center',
				align: 'center',
				headerStyle: {
					width: '55px'
				},
				formatter: (value, row, index) =>
					actionFormatter({
						value,
						row,
						index
					})
			}
		];

		return {
			columns,
			data: segmentList,
			keyField: 'ind',
			noDataInfo: commonLabels.nothingToShow,
			paginationEnable: true,
			customClasses: 'segment-table'
		};
	};

	const viewSegmentHandler = (values, { setSubmitting = null }) => {
		const { createdBy } = values;
		let url = `${segmentEndPoint.viewSegment}/${loggedInBrand.businessUnitGroupFunctionID}`;
		if (createdBy === 'me') {
			url = `${url}/user/${sessionStorage.getItem('loggedInUserId')}`;
		}

		setSearchSegmentForm(values);
		setSubmitting?.(false);
		showLoadingButton(true);
		getData(url)
			.then((response) => {
				showLoadingButton(false);
				resetSelectedSegmentList();
				const result = response.data?.List;
				const list = result.map((val, ind) => ({
					...val,
					ind: ind + 1,
					isSelected: false
				}));
				setSegmentList(list);
				if (list.length === 0) {
					Toaster({ message: 'No Segment found' });
				}

				if (result?.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}
				setShowModal(false);
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	const addSegmentHandler = (values, { setSubmitting }) => {
		setSubmitting(false);
		showLoadingButton(true);

		const payload = {
			segmentRequest: {
				userName: sessionStorage.getItem('loggedInUserId'),
				businessFunctionId: loggedInBrand.businessUnitGroupFunctionID,
				segmentType: values.segmentType,
				segmentName: values.segmentName,
				segmentDescription: values.segmentDescription
			}
		};

		postData(segmentEndPoint.submitSegment, payload, null, {
			validateStatus(status) {
				if ([409, 200].includes(status)) {
					return true;
				}
			}
		})
			.then((response) => {
				showLoadingButton(false);
				const result = response.data.segmentResponse;
				if (result?.businessError) {
					let msg = result.businessError.errorMessage;
					if (result.businessError.code == 409) {
						msg = result.businessError.description;
					}
					Toaster({ message: msg });
					return;
				}
				setAddSegmentView(false);
				setInfoModal({ infoShow: true, infoMsg: result.statusMessage });
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	return (
		<div className="segment-management">
			<div className="btn-container">
				<button
					type="button"
					className="btnNxtPrev roboto-b-16"
					onClick={() => {
						setShowModal(true);
					}}
				>
					{pageLabels.viewSegment}
				</button>
				<button
					type="button"
					className="btnNxtPrev roboto-b-16"
					onClick={() => {
						setAddSegmentView(true);
					}}
				>
					{pageLabels.addSegment}
				</button>
			</div>
			<hr />

			{!!segmentList.length && (
				<div className="segment-list">
					<div className="row d-flex pb-2 align-items-baseline">
						<div className="col-sm-11">
							<p className="my-2 roboto-r-14 grey">
								{segmentList.length} Segment
								{segmentList.length > 1 ? 's' : ''} found.
								{selectedSegmentList.length != 0 &&
									` ${selectedSegmentList.length} selected.`}
							</p>
						</div>
						<div className="col-sm-1 d-flex justify-content-end">
							<div
								title={`${
									deleteSegmentAllowed
										? pageLabels.bulkDeleteTitle
										: pageLabels.notAllowedTitle
								}`}
							>
								<div
									className={`pointer ${
										deleteSegmentAllowed && selectedSegmentList.length
											? ''
											: 'disabled'
									}`}
									onClick={() => {
										deleteHandler(selectedSegmentList);
									}}
								>
									<img alt="" src={Delete} className="imageDelete highlight" />
								</div>
							</div>
						</div>
					</div>
					<ReactTable {...getTableData()} />
				</div>
			)}

			<ModalPopup
				customClasses="search-segment-modal-popup"
				title={`${pageLabels.searchSegment}`}
				showModal={showModal}
				closeHandler={() => {
					setShowModal(false);
				}}
				// centered={false}
				// modalSide="right"
				hideCloseBtn
				additionalActionBtn={
					<>
						<button
							type="button"
							className="btnCancel roboto-b-16"
							onClick={() => {
								setShowModal(false);
							}}
						>
							{commonLabels.cancel}
						</button>

						<button
							type="button"
							className="btnNxtPrev roboto-b-16"
							onClick={() => {
								searchSegmentSubmitRef.current.click();
							}}
						>
							{commonLabels.search}
						</button>
					</>
				}
			>
				<SearchSegmentForm
					initFormValues={{ createdBy: 'me' }}
					onSubmit={viewSegmentHandler}
					submitRef={searchSegmentSubmitRef}
				/>
			</ModalPopup>

			<ModalPopup
				title={pageLabels.addSegment}
				customClasses="add-segment-modal-popup"
				closeHandler={() => {
					setAddSegmentView(false);
				}}
				showModal={addSegmentView}
				hideCloseBtn={true}
				additionalActionBtn={
					<button
						type="button"
						className="btnNxtPrev btn btn-primary"
						onClick={() => {
							segmentSubmitRef.current.click();
						}}
					>
						{commonLabels.add}
					</button>
				}
			>
				<AddSegmentForm
					initFormValues={initFormValues}
					onSubmit={addSegmentHandler}
					segmentSubmitRef={segmentSubmitRef}
				/>
			</ModalPopup>

			<ModalPopup
				title="Warning"
				modalVariant="warning"
				closeHandler={() => {
					setDeleteConfirmModal({ infoShow: false });
				}}
				showModal={deleteConfirmModal.infoShow}
				hideCloseBtn={true}
				additionalActionBtn={
					<>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
								deleteConfirmHandler('yes');
							}}
						>
							{commonLabels.yes}
						</button>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
								deleteConfirmHandler('no');
							}}
						>
							{commonLabels.no}
						</button>
					</>
				}
			>
				{deleteConfirmModal.infoMsg}
			</ModalPopup>

			<ModalPopup
				title={infoModal.infoTitle}
				modalVariant="light"
				closeHandler={() => {
					setInfoModal({ infoShow: false });
				}}
				showModal={infoModal.infoShow}
				hideCloseBtn
				additionalActionBtn={
					<>
						<button
							type="button"
							className="btnNxtPrev roboto-b-16"
							onClick={() => {
								setAddSegmentView(true);
								setInfoModal({ infoShow: false });
							}}
						>
							{commonLabels.addMore}
						</button>
						<button
							type="button"
							className="btnCancel roboto-b-16"
							onClick={() => {
								setInfoModal({ infoShow: false });
							}}
						>
							{commonLabels.close}
						</button>
					</>
				}
			>
				{infoModal.infoMsg}
			</ModalPopup>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedInBrand: state.loggedInBrand,
		assignedResources: state.assignedResources
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentManagement);
