import React from 'react';
import { Form, useField } from 'formik';

import CollapseHeaderBar from './CollapseHeaderBar';
import CollapseBody from './CollapseBody';
import BenefitSettings from './BenefitSettings';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import PaymentCondition from './PaymentCondition';
import CouponCondition from './CouponCondition';
import ExclusionSetting from './ExclusionSetting';
import Txn from '../components/Txn/Txn';
import TxnTable from './ProductTableBootstrap';
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import AuditProductTable from './Audit/AuditProductTable';

const keys = ['productID', 'includeForThreshold', 'minimumPrice', 'maximumPrice'];
const defaultValueForAddTXN = {
	maximumPrice: '9999999.99',
	minimumPrice: '0.01',
	includeForThreshold: true
};
const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	triggerCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Determines the combination code to be used between the trigger items for a promotion',
	thresholdQuantity: 'Threshold Quantity',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	rewardQuantity: 'Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	PaymentConditions: 'Payment Conditions',
	CouponConditions: 'Coupon Conditions',
	ExclusionsSetting: 'Exclusion Settings'
};

const EligibilityForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [field] = useField(props);

	const onProductTableChange = (val) => {
		props.onProductTableChanged(val);
	};
	const onMouseEnterHandler = (evt, mouseIsON) => {
		if (mouseIsON != props.curntMousePlace) {
			props.setMousePlace(mouseIsON);
		}
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = offerFlow.isAuditFlow;
	return (
		<div>
			<Form className={offerFlow.type == 'view-offer-flow' ? 'disabledEvent' : ''}>
				{/*    <Field name="TransactionEligbility" component={Txn} /> */}
				<div name="PurchaseandRewardBlock" onMouseEnter={(e) => onMouseEnterHandler(e, '')}>
					<Txn resetProductTable={onProductTableChange} />
				</div>
				{field.value.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
					.transactionRewardQuantity || isAuditFlw ? (
					<div className="accordion" id="accordionExample">
						<Element
							name="scrollToProductSettings"
							class="card"
							style={{ border: 'none' }}
							onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToProductSettings')}
						>
							<CollapseHeaderBar
								text={localConst.ProductSetting}
								headingId={'headingThree'}
								dataTarget={'#collapseThree'}
							/>
							<CollapseBody id="collapseThree" headingId={'headingThree'}>
								{/* <Field name="multibuyProduct" component={TxnTable} /> */}
								{isAuditFlw ? (
									<div className="container">
										<AuditProductTable
											oldJson={props.data}
											newJson={
												JSON.parse(sessionStorage.getItem('NewPolicy'))
													.promotionPolicy.priceDerivationRuleEligibility
													.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
													.transactionRewardProduct
											}
											txnTable={true}
										/>
									</div>
								) : (
									<TxnTable
										data={props.data}
										onProductTableChange={onProductTableChange}
										eligibility={'TXN'}
										defaultValueForAdd={defaultValueForAddTXN}
										keys={keys}
									/>
								)}
							</CollapseBody>
						</Element>
					</div>
				) : null}
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToExclusionSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToExclusionSettings')}
					>
						<CollapseHeaderBar
							text={localConst.ExclusionsSetting}
							headingId={'headingES'}
							dataTarget={'#collapseES'}
						/>
						<CollapseBody id="collapseES" headingId={'headingES'}>
							<ExclusionSetting
								customName="totalPurchaseMarketBasedPriceDerivationRuleEligibiity"
								fullValue={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToPaymentConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToPaymentConditions')}
					>
						<CollapseHeaderBar
							text={localConst.PaymentConditions}
							headingId={'headingFour'}
							dataTarget={'#collapseFour'}
						/>
						<CollapseBody id="collapseFour" headingId={'headingFour'}>
							<PaymentCondition
								customName="totalPurchaseMarketBasedPriceDerivationRuleEligibiity"
								fullVal={props.fullValue}
								loggedInBrand={props.loggedInBrand}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToCouponConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToCouponConditions')}
					>
						<CollapseHeaderBar
							text={localConst.CouponConditions}
							headingId={'headingFive'}
							dataTarget={'#collapseFive'}
						/>
						<CollapseBody id="collapseFive" headingId={'headingFive'}>
							<CouponCondition
								customName="totalPurchaseMarketBasedPriceDerivationRuleEligibiity"
								fullVal={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToBenefitSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToBenefitSettings')}
					>
						<CollapseHeaderBar
							text={localConst.benefitSettings}
							headingId={'headingTwo'}
							dataTarget={'#collapseTwo'}
						/>
						<CollapseBody id="collapseTwo" headingId={'headingTwo'}>
							<BenefitSettings
								{...props}
								customName="promotionalOfferReward.itemPriceDerivationRule"
							/>
						</CollapseBody>
					</Element>
				</div>
			</Form>
		</div>
	);
};

//export default EligibilityForm;

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
