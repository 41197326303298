import { appendDate } from '../../../../utils/helperFunction';
import '../BasicDetails.scss';
import React from 'react';
import moment from 'moment';

export function blackOutAuditChanges(oldJson, newJson) {
	let eligibilityName;
	if (oldJson.promotionPolicy.promotionType == 'MULTIBUYGROUP') {
		eligibilityName = 'groupMultibuyEligbility';
	} else if (oldJson.promotionPolicy.promotionType == 'MULTIBUYPACKAGE') {
		eligibilityName = 'packageMultibuyEligibility';
	} else if (oldJson.promotionPolicy.promotionType == 'MULTIBUYPRODUCT') {
		eligibilityName = 'multibuyEligbility';
	} else if (oldJson.promotionPolicy.promotionType == 'MERCHANDIZE') {
		eligibilityName = 'merchandizeHierarchyEligibility';
	} else if (oldJson.promotionPolicy.promotionType == 'TXN') {
		eligibilityName = 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity';
	} else if (oldJson.promotionPolicy.promotionType == 'EARN_BURN') {
		eligibilityName = 'earnBurnEligibility';
	} else if (oldJson.promotionPolicy.promotionType == 'MTMB') {
		eligibilityName = 'mtmbEligibility';
	} else if (oldJson.promotionPolicy.promotionType == 'MULTIBUYSCALE') {
		eligibilityName = 'scaledPriceEligibility';
	} else if (oldJson.promotionPolicy.promotionType == 'SIMPLE') {
		eligibilityName = 'simplePromotionEligibility';
	} else if (oldJson.promotionPolicy.promotionType == 'SCALETXN') {
		eligibilityName = 'scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity';
	}

	let OldBO =
		oldJson.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility
			.blackOutPeriod;
	let NewBO =
		newJson.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility
			.blackOutPeriod;
	OldBO = OldBO.filter((val) => val.blackOutStartDate != undefined);
	NewBO = NewBO.filter((val) => val.blackOutStartDate != undefined);
	let tempAdded = getAdded(NewBO, OldBO);
	let tempRemoved = getAdded(OldBO, NewBO);

	tempAdded = tempAdded.filter((value) => Object.keys(value).length !== 0);
	tempRemoved = tempRemoved.filter((value) => Object.keys(value).length !== 0);
	//Remove Added from New Json
	let newBOModified = getAdded(NewBO, tempAdded);
	//Remove Deleted from old json
	let oldBOModified = getAdded(OldBO, tempRemoved);
	newBOModified = newBOModified.filter((value) => Object.keys(value).length !== 0);
	oldBOModified = oldBOModified.filter((value) => Object.keys(value).length !== 0);
	let newBOModifiedOnlyDiff = [],
		oldBOModifiedOnlyDiff = [];

	newBOModified.map((item) => {
		oldBOModified.map((item1) => {
			if (item.uuid == item1.uuid) {
				if (JSON.stringify(item) != JSON.stringify(item1)) {
					newBOModifiedOnlyDiff.push(item);
					oldBOModifiedOnlyDiff.push(item1);
				}
			}
		});
	});
	let OldJsonReturn = oldJson;
	OldJsonReturn.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod =
		oldBOModifiedOnlyDiff;
	OldJsonReturn.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutAdded =
		tempAdded;
	OldJsonReturn.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutRemoved =
		tempRemoved;
	let NewJsonReturn = newJson;
	NewJsonReturn.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod =
		newBOModifiedOnlyDiff;

	///////////////////////////PaymentGroup
	let OldPG = oldJson.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
		?.paymentEligibility?.payment?.paymentGroup
		? oldJson.promotionPolicy.priceDerivationRuleEligibility[eligibilityName].paymentEligibility
				.payment.paymentGroup
		: [];
	let NewPG = newJson.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
		?.paymentEligibility?.payment?.paymentGroup
		? newJson.promotionPolicy.priceDerivationRuleEligibility[eligibilityName].paymentEligibility
				.payment.paymentGroup
		: [];
	//Remove cashbackInformation if not there in both jsons
	// NewPG.map((pg,i)=>{
	//   pg.issuers.map((is,j)=>{
	//     if (is.cashbackInformation!==undefined&&oldJson.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]?.benefit.benefitType!="CASHBACK"){
	//         is.cashbackInformation= undefined
	//     }
	//   })
	// })
	// OldPG.map((pg,i)=>{
	//   pg.issuers.map((is,j)=>{
	//     if (is.cashbackInformation!==undefined&&newJson.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]?.benefit.benefitType!="CASHBACK"){
	//         is.cashbackInformation= undefined
	//     }
	//   })
	// })
	//Add IsBenefitCashback
	if (eligibilityName == 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity') {
		if (
			OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward
				.itemPriceDerivationRule?.benefit?.benefitType == 'CASHBACK'
		) {
			if (
				OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
					.paymentEligibility?.payment
			)
				OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
					eligibilityName
				].paymentEligibility.payment.IsBenefitCashback = true;
		} else {
			if (
				OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
					?.paymentEligibility?.payment
			)
				OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
					eligibilityName
				].paymentEligibility.payment.IsBenefitCashback = false;
		}
		if (
			NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward
				.itemPriceDerivationRule.benefit.benefitType == 'CASHBACK'
		) {
			if (
				NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
					.paymentEligibility?.payment
			)
				NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
					eligibilityName
				].paymentEligibility.payment.IsBenefitCashback = true;
		} else {
			if (
				NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
					?.paymentEligibility?.payment
			)
				NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
					eligibilityName
				].paymentEligibility.payment.IsBenefitCashback = false;
		}
	} else if (eligibilityName != 'mtmbEligibility') {
		if (
			OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]?.benefit
				?.benefitType == 'CASHBACK'
		) {
			if (
				OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
					.paymentEligibility?.payment
			)
				OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
					eligibilityName
				].paymentEligibility.payment.IsBenefitCashback = true;
		} else {
			if (
				OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
					?.paymentEligibility?.payment
			)
				OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
					eligibilityName
				].paymentEligibility.payment.IsBenefitCashback = false;
		}
		if (
			NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]?.benefit
				?.benefitType == 'CASHBACK'
		) {
			if (
				NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
					.paymentEligibility?.payment
			)
				NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
					eligibilityName
				].paymentEligibility.payment.IsBenefitCashback = true;
		} else {
			if (
				NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
					?.paymentEligibility?.payment
			)
				NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
					eligibilityName
				].paymentEligibility.payment.IsBenefitCashback = false;
		}
	}

	let tempRemovedPG = getAdded(OldPG, NewPG);
	let tempAddedPG = getAdded(NewPG, OldPG);
	//Remove Added from New Json
	let newPGModified = getAdded(NewPG, tempAddedPG);
	//Remove Deleted from old json
	let oldPGModified = getAdded(OldPG, tempRemovedPG);
	let newPGModifiedOnlyDiff = [],
		oldPGModifiedOnlyDiff = [];

	newPGModified.map((item) => {
		oldPGModified.map((item1) => {
			if (item.uuid == item1.uuid) {
				if (JSON.stringify(item) != JSON.stringify(item1)) {
					newPGModifiedOnlyDiff.push(item);
					oldPGModifiedOnlyDiff.push(item1);
				}
			}
		});
	});

	// OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName].paymentEligibility.payment.paymentGroup = oldPGModifiedOnlyDiff;
	// OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName].paymentEligibility.payment.paymentGroupAdded = tempAdded;
	// OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName].paymentEligibility.payment.paymentGroupRemoved = tempRemoved;
	// NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName].paymentEligibility.payment.paymentGroup = newPGModifiedOnlyDiff;
	oldPGModifiedOnlyDiff.map((item) => {
		newPGModifiedOnlyDiff.map((item1) => {
			if (item.uuid == item1.uuid) {
				if (item.aggregators || item1.aggregators) {
					if (JSON.stringify(item.aggregators) != JSON.stringify(item1.aggregators)) {
						let aggregatorsAdded = getAddedAgg(item1.aggregators, item.aggregators);
						let aggregatorsRemoved = getAddedAgg(item.aggregators, item1.aggregators);

						item.aggregatorsAdded = aggregatorsAdded;
						item.aggregatorsRemoved = aggregatorsRemoved;
					}
				}
				if (JSON.stringify(item.issuers) != JSON.stringify(item1.issuers)) {
					let issuersAdded = getAdded(item1.issuers, item.issuers);
					let issuersRemoved = getAdded(item.issuers, item1.issuers);
					item.issuersAdded = issuersAdded;
					item.issuersRemoved = issuersRemoved;
					let issuerOldMod = getAdded(item.issuers, issuersRemoved);
					let issuerNewMod = getAdded(item1.issuers, issuersAdded);
					let issuerOldModOnlyDiff = [];
					let issuerNewModOnlyDiff = [];
					issuerNewMod.map((a) => {
						issuerOldMod.map((a1) => {
							if (a.uuid == a1.uuid) {
								if (JSON.stringify(a) != JSON.stringify(a1)) {
									issuerNewModOnlyDiff.push(a);
									issuerOldModOnlyDiff.push(a1);
								}
							}
						});
					});
					//check for emiScale
					issuerOldModOnlyDiff.map((i) => {
						issuerNewModOnlyDiff.map((i1) => {
							if (i.uuid == i1.uuid) {
								if (i.emi && i1.emi) {
									let oldEmiScale = i.emi?.emiScale ? i.emi.emiScale : [];
									let newEmiScale = i1.emi?.emiScale ? i1.emi.emiScale : [];
									let emiScaleAdded = getAdded(newEmiScale, oldEmiScale);
									let emiScaleRemoved = getAdded(oldEmiScale, newEmiScale);
									i.emi.emiScaleAdded = emiScaleAdded;
									i.emi.emiScaleRemoved = emiScaleRemoved;
									let emiScaleOldMod = getAdded(oldEmiScale, emiScaleRemoved);
									let emiScaleNewMod = getAdded(newEmiScale, emiScaleAdded);
									let emiScaleOldModOnlyDiff = [];
									let emiScaleNewModOnlyDiff = [];
									emiScaleNewMod.map((b) => {
										emiScaleOldMod.map((b1) => {
											if (b.uuid == b1.uuid) {
												if (JSON.stringify(b) != JSON.stringify(b1)) {
													emiScaleNewModOnlyDiff.push(b);
													emiScaleOldModOnlyDiff.push(b1);
												}
											}
										});
									});
									i1.emi.emiScale = emiScaleNewModOnlyDiff;
									i.emi.emiScale = emiScaleOldModOnlyDiff;
								}
								if (i.cashbackInformation || i1.cashbackInformation) {
									if (
										i.cashbackInformation == undefined &&
										i1.cashbackInformation
									) {
										i.cashbackInformationAdded = i1.cashbackInformation;
									} else if (
										i.cashbackInformation &&
										i1.cashbackInformation == undefined
									) {
										i.cashbackInformationRemoved =
											'Cashback Info has been removed';
									}
								}
							}
						});
					});
					item.issuers = issuerOldModOnlyDiff;
					item1.issuers = issuerNewModOnlyDiff;
				} else {
					item.issuers = [];
				}
			}
		});
	});
	if (
		OldJsonReturn?.promotionPolicy?.priceDerivationRuleEligibility[eligibilityName]
			?.paymentEligibility &&
		OldJsonReturn?.promotionPolicy?.priceDerivationRuleEligibility[eligibilityName]
			?.paymentEligibility.payment
	) {
		OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
			eligibilityName
		].paymentEligibility.payment.paymentGroup = oldPGModifiedOnlyDiff;
		OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
			eligibilityName
		].paymentEligibility.payment.paymentGroupAdded = tempAddedPG;
		OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
			eligibilityName
		].paymentEligibility.payment.paymentGroupRemoved = tempRemovedPG;
		if (
			NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
				.paymentEligibility &&
			NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
				.paymentEligibility.payment
		)
			NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
				eligibilityName
			].paymentEligibility.payment.paymentGroup = newPGModifiedOnlyDiff;
	}

	//Single Payment EMI
	let oldEmiScale2 = oldJson.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
		?.paymentEligibility?.payment?.emi?.emiScale
		? oldJson.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
				?.paymentEligibility?.payment?.emi?.emiScale
		: [];
	let newEmiScale2 = newJson.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
		?.paymentEligibility?.payment?.emi?.emiScale
		? newJson.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
				?.paymentEligibility?.payment?.emi?.emiScale
		: [];

	let emiScaleAdded2 = getAdded(newEmiScale2, oldEmiScale2);
	let emiScaleRemoved2 = getAdded(oldEmiScale2, newEmiScale2);
	let emiScaleOldMod2 = getAdded(oldEmiScale2, emiScaleRemoved2);
	let emiScaleNewMod2 = getAdded(newEmiScale2, emiScaleAdded2);
	let emiScaleOldModOnlyDiff2 = [];
	let emiScaleNewModOnlyDiff2 = [];
	emiScaleNewMod2.map((b) => {
		emiScaleOldMod2.map((b1) => {
			if (b.uuid == b1.uuid) {
				if (JSON.stringify(b) != JSON.stringify(b1)) {
					emiScaleNewModOnlyDiff2.push(b);
					emiScaleOldModOnlyDiff2.push(b1);
				}
			}
		});
	});
	if (
		OldJsonReturn?.promotionPolicy?.priceDerivationRuleEligibility[eligibilityName]
			?.paymentEligibility &&
		OldJsonReturn?.promotionPolicy?.priceDerivationRuleEligibility[eligibilityName]
			?.paymentEligibility?.payment?.emi
	) {
		OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
			eligibilityName
		].paymentEligibility.payment.emi.emiScale = emiScaleOldModOnlyDiff2;
		OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
			eligibilityName
		].paymentEligibility.payment.emi.emiScaleAdded = emiScaleAdded2;
		OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
			eligibilityName
		].paymentEligibility.payment.emi.emiScaleRemoved = emiScaleRemoved2;
	}
	if (
		NewJsonReturn?.promotionPolicy?.priceDerivationRuleEligibility[eligibilityName]
			?.paymentEligibility &&
		NewJsonReturn?.promotionPolicy?.priceDerivationRuleEligibility[eligibilityName]
			?.paymentEligibility?.payment?.emi
	) {
		NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
			eligibilityName
		].paymentEligibility.payment.emi.emiScale = emiScaleNewModOnlyDiff2;
	}

	if (
		OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
			.paymentEligibility == undefined
	) {
		OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
			eligibilityName
		].paymentEligibility = {
			allowAggAndIssuer: false
		};
	}
	if (
		NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[eligibilityName]
			.paymentEligibility == undefined
	) {
		NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility[
			eligibilityName
		].paymentEligibility = {
			allowAggAndIssuer: false
		};
	}
	if (oldJson.promotionPolicy.promotionType == 'SIMPLE') {
		NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility.simplePromotionEligibility.rewardQuantity = 1;
		NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility.simplePromotionEligibility.thresholdQuantity = 1;
	}
	return [OldJsonReturn, NewJsonReturn];
}

export function getDifferenceOfParentAndChild(oldJson, newJson, parentArrayPath, childArrayName) {
	//debugger
	let oldJsonParentArray = eval('oldJson.' + parentArrayPath);
	let newJsonParentArray = eval('newJson.' + parentArrayPath);

	if (
		parentArrayPath ==
		'promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility'
	) {
		//MTMB Type Changed Hide Below
		newJsonParentArray.map((item, i) => {
			oldJsonParentArray.map((item1, j) => {
				if (item.uuid == item1.uuid && item.mtmbType != item1.mtmbType) {
					let temp = item.mtmbType;
					newJsonParentArray[i] = { ...item1 };
					newJsonParentArray[i].mtmbType = temp;
				}
			});
		});
	}
	let OldBO = oldJsonParentArray ? oldJsonParentArray : [];
	let NewBO = newJsonParentArray ? newJsonParentArray : [];
	let parentArrayAdded = getAdded(NewBO, OldBO);
	let parentArrayRemoved = getAdded(OldBO, NewBO);

	//Remove Added from New Json
	let newBOModified = getAdded(NewBO, parentArrayAdded);
	//Remove Deleted from old json
	let oldBOModified = getAdded(OldBO, parentArrayRemoved);
	let newJsonParentArrayModified = [],
		oldJsonParentArrayModified = [];

	newBOModified.map((item) => {
		oldBOModified.map((item1) => {
			if (item.uuid == item1.uuid) {
				if (JSON.stringify(item) != JSON.stringify(item1)) {
					newJsonParentArrayModified.push(item);
					oldJsonParentArrayModified.push(item1);
				}
			}
		});
	});
	let OldJsonReturn = oldJson;
	let NewJsonReturn = newJson;

	var parentArrayPathModified = parentArrayPath.split('.');
	var arrayName = parentArrayPathModified[parentArrayPathModified.length - 1];
	parentArrayPathModified.pop();
	var tempOldJson = eval('OldJsonReturn.' + parentArrayPathModified.join('.'));
	var tempNewJson = eval('NewJsonReturn.' + parentArrayPathModified.join('.'));

	tempOldJson[arrayName + 'Added'] = parentArrayAdded;
	tempOldJson[arrayName + 'Removed'] = parentArrayRemoved;

	tempOldJson[arrayName] = oldJsonParentArrayModified;
	tempNewJson[arrayName] = newJsonParentArrayModified;
	// sessionStorage.setItem("NewPolicy",JSON.stringify(NewJsonReturn));
	//for nested Array
	//debugger
	oldJsonParentArrayModified.map((item) => {
		newJsonParentArrayModified.map((item1) => {
			if (item.uuid == item1.uuid) {
				if (JSON.stringify(item[childArrayName]) != JSON.stringify(item1[childArrayName])) {
					let childArrayAdded = getAdded(item1[childArrayName], item[childArrayName]);
					let childArrayRemoved = getAdded(item[childArrayName], item1[childArrayName]);

					item[childArrayName + 'Added'] = childArrayAdded;
					item[childArrayName + 'Removed'] = childArrayRemoved;

					let childArrayOldMod = getAdded(item[childArrayName], childArrayRemoved);
					let childArrayNewMod = getAdded(item1[childArrayName], childArrayAdded);
					let childArrayOldModOnlyDiff = [];
					let childArrayNewModOnlyDiff = [];
					childArrayNewMod.map((a) => {
						childArrayOldMod.map((a1) => {
							if (a.uuid == a1.uuid) {
								if (JSON.stringify(a) != JSON.stringify(a1)) {
									childArrayNewModOnlyDiff.push(a);
									childArrayOldModOnlyDiff.push(a1);
								}
							}
						});
					});
					// item[childArrayName]=childArrayNewModOnlyDiff;
					// item1[childArrayName]=childArrayOldModOnlyDiff;
					item[childArrayName] = childArrayOldModOnlyDiff;
					item1[childArrayName] = childArrayNewModOnlyDiff;
				} else {
					item[childArrayName] = [];
					item1[childArrayName] = [];
				}
			}
		});
	});

	OldJsonReturn[parentArrayPath] = oldJsonParentArrayModified;
	NewJsonReturn[parentArrayPath] = newJsonParentArrayModified;
	if (
		parentArrayPath ==
		'promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility'
	) {
		if (
			OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
				.packageMultibuyEligibility.length == 0
		) {
			OldJsonReturn.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility =
				[
					{
						benefit: {
							benefitType: ''
						},
						eligibilityType: 'TRIGGER_AND_REWARD',
						group: [
							{
								multibuyProduct: [],
								merchandizeHierarchyGroup: [],
								trigger: true,
								getReward: true
							}
						],
						thresholdTypeCode: '',
						applyBenefitOn: 'SOURCE',
						multiTargetCondition: 'OR',
						mtmbType: 'PRODUCT'
					}
				];
		}
		if (
			NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
				.packageMultibuyEligibility.length == 0
		) {
			NewJsonReturn.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility =
				[
					{
						benefit: {
							benefitType: ''
						},
						eligibilityType: 'TRIGGER_AND_REWARD',
						group: [
							{
								multibuyProduct: [],
								merchandizeHierarchyGroup: [],
								trigger: true,
								getReward: true
							}
						],
						thresholdTypeCode: '',
						applyBenefitOn: 'SOURCE',
						multiTargetCondition: 'OR',
						mtmbType: 'PRODUCT'
					}
				];
		}
	}
	//for nested Array

	return [OldJsonReturn, NewJsonReturn];
	// send back jsons

	//Set Added & Removed Seperately in formik json
}

export function getDifferenceOfParent(oldJson, newJson, parentArrayPath) {
	//debugger
	let oldJsonParentArray = eval('oldJson.' + parentArrayPath);
	let newJsonParentArray = eval('newJson.' + parentArrayPath);

	let OldBO = oldJsonParentArray ? oldJsonParentArray : [];
	let NewBO = newJsonParentArray ? newJsonParentArray : [];
	// package type got changed hide below section
	NewBO.map((item, i) => {
		OldBO.map((item1, j) => {
			if (item.uuid == item1.uuid && item.packageType != item1.packageType) {
				let temp = item.packageType;
				NewBO[i] = { ...item1 };
				NewBO[i].packageType = temp;
			}
		});
	});

	let parentArrayAdded = getAdded(NewBO, OldBO);
	let parentArrayRemoved = getAdded(OldBO, NewBO);

	//Remove Added from New Json
	let newBOModified = getAdded(NewBO, parentArrayAdded);
	//Remove Deleted from old json
	let oldBOModified = getAdded(OldBO, parentArrayRemoved);
	let newJsonParentArrayModified = [],
		oldJsonParentArrayModified = [];

	newBOModified.map((item) => {
		oldBOModified.map((item1) => {
			if (item.uuid == item1.uuid) {
				if (JSON.stringify(item) != JSON.stringify(item1)) {
					newJsonParentArrayModified.push(item);
					oldJsonParentArrayModified.push(item1);
				}
			}
		});
	});
	let OldJsonReturn = oldJson;
	let NewJsonReturn = newJson;

	var parentArrayPathModified = parentArrayPath.split('.');
	var arrayName = parentArrayPathModified[parentArrayPathModified.length - 1];
	parentArrayPathModified.pop();
	var tempOldJson = eval('OldJsonReturn.' + parentArrayPathModified.join('.'));
	var tempNewJson = eval('NewJsonReturn.' + parentArrayPathModified.join('.'));

	tempOldJson[arrayName + 'Added'] = parentArrayAdded;
	tempOldJson[arrayName + 'Removed'] = parentArrayRemoved;

	tempOldJson[arrayName] = oldJsonParentArrayModified;
	tempNewJson[arrayName] = newJsonParentArrayModified;

	OldJsonReturn[parentArrayPathModified.join('.')] = tempOldJson;
	NewJsonReturn[parentArrayPathModified.join('.')] = tempNewJson;

	return [OldJsonReturn, NewJsonReturn];
	// send back jsons

	//Set Added & Removed Seperately in formik json
}

export function getAdded(products, products1) {
	var added = [];
	for (var i = 0; i < products.length; i++) {
		var id = products[i].uuid;
		var flag = false;
		for (var j = 0; j < products1.length; j++) {
			var id2 = products1[j].uuid;
			if (id === id2) {
				flag = true;
			}
		}
		if (!flag) {
			added.push(products[i]);
		}
	}
	return added;
}

export function getAddedAgg(products, products1) {
	if (products == undefined) products = [];
	if (products1 == undefined) products1 = [];
	var added = [];
	for (var i = 0; i < products.length; i++) {
		var id = products[i].aggregatorId;
		var flag = false;
		for (var j = 0; j < products1.length; j++) {
			var id2 = products1[j].aggregatorId;
			if (id === id2) {
				flag = true;
			}
		}
		if (!flag) {
			added.push(products[i]);
		}
	}
	return added;
}

export function processorFunction(policy) {
	//Basic details changes
	const taggedBrandToLoggedInUser = JSON.parse(sessionStorage.taggedBrandToLoggedInUser);
	policy.promotionPolicy.promotionID = policy.promotionPolicy.promotionID.replace(
		taggedBrandToLoggedInUser.programId + ':',
		''
	);

	if (!policy.promotionPolicy.promotionApplicableEligibility.channelEligibility) {
		policy.promotionPolicy.promotionApplicableEligibility.channelEligibility = {
			channel: []
		};
	}

	if (!policy.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility) {
		policy.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility = {
			eligibleDaysOfTheWeek: {
				includedDaysOfTheWeek: {
					includedDay: []
				},
				excludedDaysOfTheWeek: {
					excludedDay: []
				}
			},
			eligibleDatesOfTheMonth: {
				includedDatesOfTheMonth: {
					includedDate: []
				},
				excludedDatesOfTheMonth: {
					excludedDate: []
				}
			},
			blackOutPeriod: [{}]
		};
	} else {
		if (
			policy.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility
				?.blackOutPeriod &&
			!policy.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility
				.blackOutPeriod.length
		) {
			policy.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod =
				[{}];
		}
	}

	if (!policy.promotionPolicy.offerImageUrl) {
		policy.promotionPolicy.offerImageUrl = [''];
	}

	if (!policy.promotionPolicy.termsAndConditions) {
		policy.promotionPolicy.termsAndConditions = [''];
	}

	if (
		policy?.promotionPolicy?.promotionApplicableEligibility?.promotionActivationEligibility
			?.activationValidity != undefined
	) {
		policy.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility =
			appendDate(
				policy.promotionPolicy.promotionApplicableEligibility
					.promotionActivationEligibility,
				'activationValidity',
				'promotionCollectionValidityData'
			);
	}

	//For MultibuyGroup
	if (policy.promotionPolicy.promotionType == 'MULTIBUYGROUP') {
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
				.paymentEligibility
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.paymentEligibility =
				{};
		} else {
			if (
				policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
					.paymentEligibility.payment?.emi &&
				!policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
					.paymentEligibility.payment.emi.coolOffPeriod
			) {
				policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.paymentEligibility.payment.emi.coolOffPeriod =
					{
						coolOffPeriodType: ''
					};
			}
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.coupons
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.coupons =
				{};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
				.exclusions
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.exclusions =
				{
					excludedItemIDList: {
						excludedItemID: []
					},
					merchandizeHierarchyGroup: []
				};
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility?.benefit
				?.loyalty
		) {
			if (
				!policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
					?.benefit?.loyalty?.expireOn
			) {
				policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.benefit.loyalty[
					'expireOn'
				] = {};
			}
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility?.coupons
				?.coupon != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.coupons.coupon =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
						.coupons.coupon,
					'expireOn',
					'couponExpiry'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility?.benefit
				?.cashback != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.benefit.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
						.benefit.cashback,
					'creditBy',
					'cashbackCreditData'
				);
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.benefit.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
						.benefit.cashback,
					'expireOn',
					'cashbackExpireData'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility?.benefit
				?.loyalty != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.benefit.loyalty =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
						.benefit.loyalty,
					'expireOn',
					'loyaltyExpireData'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
				?.paymentEligibility?.payment?.emi != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.paymentEligibility.payment.emi =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
						.paymentEligibility.payment.emi,
					'coolOffPeriod',
					'coolOffPeriodType'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
				?.paymentEligibility?.payment?.paymentGroup != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
					item.issuersAdded &&
						item.issuersAdded.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
					item.issuersRemoved &&
						item.issuersRemoved.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
				?.paymentEligibility &&
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility
				?.paymentEligibility?.allowAggAndIssuer == undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.paymentEligibility.allowAggAndIssuer = false;
		}
	}
	//For Package
	else if (policy.promotionPolicy.promotionType == 'MULTIBUYPACKAGE') {
		policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.group.map(
			(val, i) => {
				if (val?.multibuyProduct?.length > 0) val['packageType'] = 'PRODUCT';
				else if (val?.merchandizeHierarchyGroup?.length > 0) val['packageType'] = 'SH';
			}
		);

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				.paymentEligibility
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.paymentEligibility =
				{};
		} else {
			if (
				policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
					.paymentEligibility.payment?.emi &&
				!policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
					.paymentEligibility.payment.emi.coolOffPeriod
			) {
				policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.paymentEligibility.payment.emi.coolOffPeriod =
					{
						coolOffPeriodType: ''
					};
			}
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				.coupons
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.coupons =
				{};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				.exclusions
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.exclusions =
				{
					excludedItemIDList: {
						excludedItemID: []
					},
					merchandizeHierarchyGroup: []
				};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				.paymentEligibility
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.paymentEligibility =
				{ applicableOn: '' };
		}
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				.coupons
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.coupons =
				{ couponType: '' };
		}

		//loyalty.expireOn modification flow when loyalty.expireOn is not selected
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				?.benefit?.loyalty
		) {
			if (
				!policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
					?.benefit?.loyalty?.expireOn
			) {
				policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.benefit.loyalty[
					'expireOn'
				] = {};
			}
		}

		/*   if (!policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility?.benefit?.loyalty?.expireOn?.loyaltyExpireData) {
   policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.benefit.loyalty.expireOn.loyaltyExpireData=""
 }   */

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				?.coupons?.coupon != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.coupons.coupon =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
						.coupons.coupon,
					'expireOn',
					'couponExpiry'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				?.benefit?.cashback != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.benefit.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
						.benefit.cashback,
					'creditBy',
					'cashbackCreditData'
				);
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.benefit.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
						.benefit.cashback,
					'expireOn',
					'cashbackExpireData'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				?.benefit?.loyalty != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.benefit.loyalty =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
						.benefit.loyalty,
					'expireOn',
					'loyaltyExpireData'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				?.paymentEligibility?.payment?.emi != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.paymentEligibility.payment.emi =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
						.paymentEligibility.payment.emi,
					'coolOffPeriod',
					'coolOffPeriodType'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				?.paymentEligibility?.payment?.paymentGroup != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
					//ForAudit
					item.issuersAdded &&
						item.issuersAdded.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
					item.issuersRemoved &&
						item.issuersRemoved.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				?.paymentEligibility &&
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility
				?.paymentEligibility?.allowAggAndIssuer == undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.paymentEligibility.allowAggAndIssuer = false;
		}
	}
	//For MultibuyProduct
	if (policy.promotionPolicy.promotionType == 'MULTIBUYPRODUCT') {
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.multibuyProduct
				.length > 0
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.rewardQuantity =
				policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
					.multibuyProduct[0].rewardQuantity
					? policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
							.multibuyProduct[0].rewardQuantity
					: '';
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.thresholdQuantity =
				policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
					.multibuyProduct[0].thresholdQuantity
					? policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
							.multibuyProduct[0].thresholdQuantity
					: '';
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.maximumThresholdQuantity =
				policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
					.multibuyProduct[0].maximumThresholdQuantity
					? policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
							.multibuyProduct[0].maximumThresholdQuantity
					: '';
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.maximumThresholdAmount =
				policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
					.multibuyProduct[0].maximumThresholdAmount
					? policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
							.multibuyProduct[0].maximumThresholdAmount
					: '';
			// policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.quantityLimit =policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.multibuyProduct[0].quantityLimit?
			//  policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.multibuyProduct[0].quantityLimit:'';
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.thresholdAmount =
				policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
					.multibuyProduct[0].thresholdAmount
					? policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
							.multibuyProduct[0].thresholdAmount
					: '';
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.multibuyProduct[0].rewardQuantity =
				undefined;
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.multibuyProduct[0].maximumThresholdQuantity =
				undefined;
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.multibuyProduct[0].maximumThresholdAmount =
				undefined;
			//  policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.multibuyProduct[0].quantityLimit = undefined;
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.multibuyProduct[0].thresholdQuantity =
				undefined;
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.multibuyProduct[0].thresholdAmount =
				undefined;
		}
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
				.paymentEligibility
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.paymentEligibility =
				{};
		} else {
			if (
				policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
					.paymentEligibility.payment?.emi &&
				!policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
					.paymentEligibility.payment.emi.coolOffPeriod
			) {
				policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.paymentEligibility.payment.emi.coolOffPeriod =
					{
						coolOffPeriodType: ''
					};
			}
		}

		if (!policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.coupons) {
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.coupons = {};
		}

		/*  if (!policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.exclusions) {
	   policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.exclusions = {
		 excludedItemIDList: {
		   excludedItemID: [],
		 },
		 merchandizeHierarchyGroup: [],
	   };
	 } */

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility?.coupons
				?.coupon != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.coupons.coupon =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.coupons
						.coupon,
					'expireOn',
					'couponExpiry'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility?.benefit
				?.cashback != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.benefit.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.benefit
						.cashback,
					'creditBy',
					'cashbackCreditData'
				);
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.benefit.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.benefit
						.cashback,
					'expireOn',
					'cashbackExpireData'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility?.benefit
				?.loyalty != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.benefit.loyalty =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.benefit
						.loyalty,
					'expireOn',
					'loyaltyExpireData'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
				?.paymentEligibility?.payment?.emi != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.paymentEligibility.payment.emi =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
						.paymentEligibility.payment.emi,
					'coolOffPeriod',
					'coolOffPeriodType'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
				?.paymentEligibility?.payment?.paymentGroup != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
				?.paymentEligibility &&
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility
				?.paymentEligibility?.allowAggAndIssuer == undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.multibuyEligbility.paymentEligibility.allowAggAndIssuer = false;
		}
	}
	//For MERCHANDIZE
	if (policy.promotionPolicy.promotionType == 'MERCHANDIZE') {
		const producttabaledata =
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				.inclusions;
		if (!producttabaledata) {
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.inclusions =
				{
					includedItemIDList: {
						multibuyProduct: []
					}
				};
		} else {
			policy.promotionPolicy.priceDerivationRuleEligibility.InclusionsType =
				'PRODUCT_INCLUSION_VALUE';
			//multiBuyGroupTable=policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.inclusions.includedItemIDList.multibuyProduct;
			/*  policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.inclusions=
	  {
		"includedItemIDList": {
		  "multibuyProduct": []
		}
	  } */
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				.paymentEligibility
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.paymentEligibility =
				{};
		} else {
			if (
				policy.promotionPolicy.priceDerivationRuleEligibility
					.merchandizeHierarchyEligibility.paymentEligibility.payment?.emi &&
				!policy.promotionPolicy.priceDerivationRuleEligibility
					.merchandizeHierarchyEligibility.paymentEligibility.payment.emi.coolOffPeriod
			) {
				policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.paymentEligibility.payment.emi.coolOffPeriod =
					{
						coolOffPeriodType: ''
					};
			}
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				.coupons
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.coupons =
				{};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				.exclusions
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.exclusions =
				{
					excludedItemIDList: {
						excludedItemID: []
					},
					merchandizeHierarchyGroup: []
				};
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				?.coupons?.coupon != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.coupons.coupon =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.merchandizeHierarchyEligibility.coupons.coupon,
					'expireOn',
					'couponExpiry'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				?.benefit?.cashback != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.benefit.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.merchandizeHierarchyEligibility.benefit.cashback,
					'creditBy',
					'cashbackCreditData'
				);
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.benefit.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.merchandizeHierarchyEligibility.benefit.cashback,
					'expireOn',
					'cashbackExpireData'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				?.benefit?.loyalty != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.benefit.loyalty =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.merchandizeHierarchyEligibility.benefit.loyalty,
					'expireOn',
					'loyaltyExpireData'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				?.paymentEligibility?.payment?.emi != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.paymentEligibility.payment.emi =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.merchandizeHierarchyEligibility.paymentEligibility.payment.emi,
					'coolOffPeriod',
					'coolOffPeriodType'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				?.paymentEligibility?.payment?.paymentGroup != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				?.paymentEligibility &&
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility
				?.paymentEligibility?.allowAggAndIssuer == undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.merchandizeHierarchyEligibility.paymentEligibility.allowAggAndIssuer = false;
		}
	}

	//For TXN
	if (policy.promotionPolicy.promotionType == 'TXN') {
		if (policy.promotionPolicy.promotionalOfferReward != undefined) {
			policy.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward =
				policy.promotionPolicy.promotionalOfferReward;
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility =
				{};
		} else {
			if (
				policy.promotionPolicy.priceDerivationRuleEligibility
					.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
					.payment?.emi &&
				!policy.promotionPolicy.priceDerivationRuleEligibility
					.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
					.payment.emi.coolOffPeriod
			) {
				policy.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.payment.emi.coolOffPeriod =
					{
						coolOffPeriodType: ''
					};
			}
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons =
				{};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions =
				{
					excludedItemIDList: {
						excludedItemID: []
					},
					merchandizeHierarchyGroup: []
				};
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity?.coupons?.coupon != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons.coupon =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons.coupon,
					'expireOn',
					'couponExpiry'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward
				?.itemPriceDerivationRule?.benefit?.cashback != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward.itemPriceDerivationRule.benefit.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward
						.itemPriceDerivationRule.benefit.cashback,
					'creditBy',
					'cashbackCreditData'
				);
			policy.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward.itemPriceDerivationRule.benefit.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward
						.itemPriceDerivationRule.benefit.cashback,
					'expireOn',
					'cashbackExpireData'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward
				?.itemPriceDerivationRule?.benefit?.loyalty != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward.itemPriceDerivationRule.benefit.loyalty =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.promotionalOfferReward
						.itemPriceDerivationRule.benefit.loyalty,
					'expireOn',
					'loyaltyExpireData'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility?.payment
				?.emi != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.payment.emi =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
						.payment.emi,
					'coolOffPeriod',
					'coolOffPeriodType'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility?.payment
				?.paymentGroup != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility &&
			policy.promotionPolicy.priceDerivationRuleEligibility
				.totalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility
				?.allowAggAndIssuer == undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.allowAggAndIssuer = false;
		}
	}
	//for EarnBurn
	if (policy.promotionPolicy.promotionType == 'EARN_BURN') {
		policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility &&
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.groupDetail &&
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.groupDetail.map(
				(val, i) => {
					if (val?.multibuyProduct?.length > 0) val['packageType'] = 'PRODUCT';
					else if (val?.merchandizeHierarchyGroup?.length > 0) val['packageType'] = 'SH';
				}
			);

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility?.expireOn !=
			undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility = appendDate(
				policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility,
				'expireOn',
				'expireOnType'
			);
		}

		policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.groupDetail.map(
			(val, j) => {
				if (
					policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
						.groupDetail[j]
				) {
					if (
						!policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
							?.groupDetail[j]?.multibuyProduct
					) {
						policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.groupDetail[
							j
						].multibuyProduct = [];
					}
				}
			}
		);

		// Modify Settings

		if (!policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.exclusions) {
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.exclusions = {
				excludedItemIDList: {
					excludedItemID: []
				},
				merchandizeHierarchyGroup: []
			};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
				.paymentEligibility
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.paymentEligibility =
				{ applicableOn: '' };
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
				?.paymentEligibility?.payment?.paymentGroup != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
				?.paymentEligibility &&
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
				?.paymentEligibility?.allowAggAndIssuer == undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.paymentEligibility.allowAggAndIssuer = false;
		}
	}

	//For MTMB
	if (policy.promotionPolicy.promotionType == 'MTMB') {
		policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility.map(
			(item, i) => {
				if (item.group[0].multibuyProduct && item.group[0].multibuyProduct.length > 0) {
					policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility[
						i
					].mtmbType = 'PRODUCT';
				} else if (
					item.group[0].merchandizeHierarchyGroup &&
					item.group[0].merchandizeHierarchyGroup.length > 0
				) {
					policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility[
						i
					].mtmbType = 'SH';
				} else {
					policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility[
						i
					].mtmbType = 'PRODUCT';
				}
			}
		);

		policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility.map(
			(item, i) => {
				if (
					!policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
						.packageMultibuyEligibility[i].benefit
				) {
					policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility[
						i
					].benefit = {
						benefitType: ''
					};
					policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility[
						i
					].group.map((val, j) => {
						if (
							!policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
								.packageMultibuyEligibility[i].group[j].multibuyProduct
						) {
							policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility[
								i
							].group[j].multibuyProduct = [];
						}
					});
				}
				if (item.group[0].multibuyProduct && item.group[0].multibuyProduct.length > 0) {
					policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility[
						i
					].mtmbType = 'PRODUCT';
				} else if (
					item.group[0].merchandizeHierarchyGroup &&
					item.group[0].merchandizeHierarchyGroup.length > 0
				) {
					policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility[
						i
					].mtmbType = 'SH';
				}
			}
		);

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
				.paymentEligibility
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.paymentEligibility =
				{ applicableOn: '' };
		}
		if (!policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.coupons) {
			policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.coupons = {
				couponType: ''
			};
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility?.coupons
				?.coupon != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.coupons.coupon =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.coupons
						.coupon,
					'expireOn',
					'couponExpiry'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
				?.paymentEligibility?.payment?.paymentGroup != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
				?.paymentEligibility &&
			policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
				?.paymentEligibility?.allowAggAndIssuer == undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.paymentEligibility.allowAggAndIssuer = false;
		}
	}
	if (policy.promotionPolicy.promotionType == 'MULTIBUYSCALE') {
		//Loyalty Expire On
		if (!policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.loyalty) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.loyalty =
				{};
		}
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.loyalty
				.expireOn
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.loyalty.expireOn =
				{
					loyaltyExpireData: ''
				};
		}

		//CashBack Credit By
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.cashback
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.cashback =
				{};
		}
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.cashback
				.creditBy
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.cashback.creditBy =
				{
					cashbackCreditData: ''
				};
		}

		//CashBack Expiry On
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.cashback
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.cashback =
				{};
		}
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.cashback
				.expireOn
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.cashback.expireOn =
				{
					cashbackExpireData: ''
				};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility
				.paymentEligibility
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.paymentEligibility =
				{};
		}

		if (!policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.coupons) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.coupons =
				{};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.exclusions
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.exclusions =
				{
					excludedItemIDList: {
						excludedItemID: []
					},
					merchandizeHierarchyGroup: []
				};
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility?.coupons
				?.coupon != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.coupons.coupon =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility
						.coupons.coupon,
					'expireOn',
					'couponExpiry'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility
				?.cashback != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility
						.cashback,
					'creditBy',
					'cashbackCreditData'
				);
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility
						.cashback,
					'expireOn',
					'cashbackExpireData'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.loyalty &&
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility?.loyalty !=
				undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.loyalty =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility
						.loyalty,
					'expireOn',
					'loyaltyExpireData'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility
				?.paymentEligibility?.payment?.paymentGroup != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility
				?.paymentEligibility &&
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility
				?.paymentEligibility?.allowAggAndIssuer == undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility.paymentEligibility.allowAggAndIssuer = false;
		}
	}
	if (policy.promotionPolicy.promotionType == 'SCALETXN') {
		//Loyalty Expire On
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.loyalty
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.loyalty =
				{};
		}
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.loyalty.expireOn
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.loyalty.expireOn =
				{
					loyaltyExpireData: ''
				};
		}

		//CashBack Credit By
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback =
				{};
		}
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback.creditBy
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback.creditBy =
				{
					cashbackCreditData: ''
				};
		}

		//CashBack Expiry On
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback =
				{};
		}
		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback.expireOn
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback.expireOn =
				{
					cashbackExpireData: ''
				};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility =
				{};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons =
				{};
		}

		if (
			!policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions =
				{
					excludedItemIDList: {
						excludedItemID: []
					},
					merchandizeHierarchyGroup: []
				};
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity?.coupons?.coupon !=
			undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons.coupon =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons.coupon,
					'expireOn',
					'couponExpiry'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity?.cashback != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback,
					'creditBy',
					'cashbackCreditData'
				);
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback,
					'expireOn',
					'cashbackExpireData'
				);
		}

		if (
			policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.loyalty &&
			policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity?.loyalty != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.loyalty =
				appendDate(
					policy.promotionPolicy.priceDerivationRuleEligibility
						.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.loyalty,
					'expireOn',
					'loyaltyExpireData'
				);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility
				?.payment?.paymentGroup != undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								if (item1.cashbackInformation.creditBy == undefined) {
									item1.cashbackInformation.creditBy = {};
								}
								if (item1.cashbackInformation.expireOn == undefined) {
									item1.cashbackInformation.expireOn = {};
								}
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = appendDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = appendDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility &&
			policy.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility
				?.allowAggAndIssuer == undefined
		) {
			policy.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.allowAggAndIssuer = false;
		}
	}

	return policy;
}

export function modifiedValueFormatter(cell, row, rowIndex, callback = null) {
	if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
		if (typeof cell.valueOf() === 'string') {
			if (
				(cell != undefined && cell.indexOf('oldValue') != -1) ||
				cell.indexOf('newValue') != -1
			) {
				return (
					<>
						{/* {JSON.parse(cell).oldValue} */}
						<div className="d-flex flex-wrap">
							{JSON.parse(cell).oldValue == 'true' ||
							JSON.parse(cell).oldValue == 'false' ? (
								<div className="mx-2 mb-2 oldCheckboxContainer">
									<input
										className="oldCheckbox"
										type="checkbox"
										checked={JSON.parse(cell).oldValue == 'true' ? true : false}
									/>
								</div>
							) : (
								<span className="modifiedOldValue mx-2 mb-2">
									{JSON.parse(cell).oldValue}
								</span>
							)}

							{JSON.parse(cell).newValue == 'true' ||
							JSON.parse(cell).newValue == 'false' ? (
								<div className="mx-2 mb-2 newCheckboxContainer">
									<input
										className={
											JSON.parse(cell).newValue == 'true'
												? 'newCheckbox b21'
												: 'newCheckbox b2'
										}
										type="checkbox"
										checked={JSON.parse(cell).newValue == 'true' ? true : false}
									/>
								</div>
							) : (
								<span className="modifiedNewValue mx-2 mb-2">
									{JSON.parse(cell).newValue}
								</span>
							)}
						</div>
					</>
				);
			} else {
				return cell;
			}
		} else {
			return cell;
		}
	}

	if (typeof cell === 'boolean' && (cell === true || cell === false)) {
		return (
			<input
				type="checkbox"
				id="customCheck"
				checked={cell == true}
				onChange={(e) => {
					callback && callback({ cell, row, checked: e.target.checked });
				}}
			/>
		);
	} else {
		return cell;
	}
}

/**
 * @description Convert utc string date to local date format
 * @param {string} date
 * @param {*} row
 * @param {*} rowIndex
 * @returns YYYY-MMM-DD HH:mm:ss localDate format (24 hours format)
 */
export const utcToLocalDate = (date) => {
	try {
		const gmtDateTime = moment.utc(date, 'YYYY-MM-DD HH:mm:ss');
		const local = gmtDateTime.local().format('YYYY-MMM-DD HH:mm:ss');
		return local;
	} catch (error) {
		return date;
	}
};
