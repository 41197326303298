import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './couponTable.scss';
import filterFactory from 'react-bootstrap-table2-filter';
import Delete from './../../assets/images/deleteIcon.png';
import ToolkitProvider from '../../utils/reactBootstrapTable2Toolkit';
/* let options = {} */
class BinDetailsTable extends Component {
	constructor(props) {
		super(props);

		const columnsBin = [
			{
				dataField: 'binStartRange',
				text: 'Bin Start Range ',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'binEndRange',
				text: 'Bin End Range',
				headerAlign: 'center',
				searchable: false
			}
		];
		const columnsBinVersion = [
			{
				dataField: 'binStartRange',
				text: 'Bin Start Range ',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'binEndRange',
				text: 'Bin End Range',
				headerAlign: 'center',
				searchable: false
			},
			{
				dataField: 'deletedBy',
				text: 'Deleted',
				headerAlign: 'center',
				searchable: false,
				formatter: this.deletedInfoFormatter
			}
			// {
			//   dataField: "deletedOn",
			//   text: "Deleted On",
			//   headerAlign: 'center',
			//   searchable: false
			// },
		];
		this.state = {
			// For displaying data
			data: props.data,
			columns: props.type == 'VERSION' ? columnsBinVersion : columnsBin,
			isFollow: true,
			placeholder: props.placeholder,
			sizePerPage: 8,
			selectedProducts: []
		};

		this.options = {
			onPageChange: this.props.onPageChange,
			sizePerPage: this.state.sizePerPage,
			prePageText: 'Previous',
			nextPageText: 'Next',
			paginationSize: 1,
			alwaysShowAllBtns: true, // Always show next and previous button
			withFirstAndLast: false,
			hidePageListOnlyOnePage: true
		};

		//this.linkAction = this.linkAction.bind(this);
	}
	deletedInfoFormatter(cell, row) {
		return cell ? (
			<div>
				<div>
					<span className="font-weight-bold">By: </span>
					<span> {cell}</span>
				</div>
				<div>
					<span className="font-weight-bold">On: </span>
					<span> {row.deletedOn}</span>
				</div>
			</div>
		) : (
			<div className="text-center">-</div>
		);
	}
	selectRow = {
		mode: 'checkbox',
		onSelect: (row, isSelect, rowIndex) => {
			//debugger
			let newSelectedProducts = [...this.props.selectedGeneric];
			if (!isSelect) {
				newSelectedProducts.splice(rowIndex, 1);
			} else {
				newSelectedProducts.push(row);
			}

			this.props.onSelectedGenericChange(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			//debugger
			let newSelectedProducts = [...this.props.selectedGeneric];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val;
				});
			}
			this.props.onSelectedGenericChange(newSelectedProducts);
		}
	};
	selectRowExclusion = {
		mode: 'checkbox',
		onSelect: (row, isSelect, rowIndex) => {
			//debugger
			let newSelectedProducts = [...this.props.selectedBinExclusion];
			if (!isSelect) {
				newSelectedProducts.splice(rowIndex, 1);
			} else {
				newSelectedProducts.push(row);
			}

			this.props.onSelectedBinExclusionChange(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			//debugger
			let newSelectedProducts = [...this.props.selectedGeneric];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val;
				});
			}
			this.props.onSelectedBinExclusionChange(newSelectedProducts);
		}
	};

	render() {
		return (
			<ToolkitProvider
				keyField="id"
				data={this.state.data}
				columns={this.state.columns}
				search
			>
				{(props) => (
					<div
						style={{ padding: '0px 15px 15px 15px', width: '50%' }}
						className="couponTable binDetails"
					>
						{/*  <h1 className="h2">Products</h1> */}
						{/* <SearchBar {...props.searchProps}  placeholder={this.state.placeholder}/> */}
						<span className="font-weight-bold">Inclusion</span>
						{this.props.type == 'VERSION' ? undefined : (
							<span
								className={
									this.props.selectedGeneric.length == 0
										? 'disabled float-right'
										: 'pointer float-right'
								}
								onClick={() => {
									this.props.deleteBinInclusion();
								}}
							>
								<img
									src={Delete}
									title="Bulk Delete"
									className=" highlight"
									alt=""
								/>
							</span>
						)}
						<div className="pt-2">
							<BootstrapTable
								{...props.baseProps}
								/*  keyField= {props.columns == "searchedCouponGeneric" ? "promotionId" : props.columns == "searchedCouponTargeted" ? "promotionId" : "couponId"} */
								/* keyField="promotionId" */
								keyField="id"
								noDataIndication={() => <div>No BINs setup</div>}
								data={this.props.data}
								columns={this.state.columns}
								filter={filterFactory()}
								selectRow={
									this.props.type == 'VERSION' ? undefined : this.selectRow
								}
								/*  remote={true} */
								//  pagination={paginationFactory(this.options)}
							/>
						</div>
						<div className="d-flex">
							<div
								style={{
									'justify-self': 'center',
									flex: 1,
									'text-align': 'center',
									'margin-top': '20px'
								}}
							>
								<button
									className={
										this.props.pageNumberInclusion == 1
											? 'btn btn-primary m-2 disabled'
											: 'btn btn-primary m-2'
									}
									onClick={() => {
										if (this.props.type == 'VERSION')
											this.props.onPageChange('VerInclusion', 'Previous');
										else this.props.onPageChange('Inclusion', 'Previous');
									}}
								>
									Previous
								</button>
								<div className="btn btn-primary m-2">
									{this.props.pageNumberInclusion}
								</div>
								<button
									className={
										this.props.maxPageInclusion ==
											this.props.pageNumberInclusion ||
										this.props.data.length == 0
											? 'disabled btn btn-primary m-2'
											: 'btn btn-primary m-2'
									}
									onClick={() => {
										if (this.props.type == 'VERSION')
											this.props.onPageChange('VerInclusion', 'Next');
										else this.props.onPageChange('Inclusion', 'Next');
									}}
								>
									Next
								</button>
							</div>
							{/* 
                             {this.props.type == "VIEW" ? 
                             <div style={{
                              "margin-top": "20px"
                          }} class="mr-4">
                             <button className="cancel-btn1 roboto-b-16 " onClick={() => {
                     
                              this.props.gotoCouponSearch();

                          }} >Cancel</button></div>: undefined} */}
						</div>

						<span className="font-weight-bold">Exclusion</span>
						{this.props.type == 'VERSION' ? undefined : (
							<span
								className={
									this.props.selectedBinExclusion.length == 0
										? 'disabled float-right'
										: 'pointer float-right'
								}
								onClick={() => {
									this.props.deleteBinExclusion();
								}}
							>
								<img
									src={Delete}
									title="Bulk Delete"
									className=" highlight"
									alt=""
								/>
							</span>
						)}
						<div className="pt-2">
							<BootstrapTable
								{...props.baseProps}
								/*  keyField= {props.columns == "searchedCouponGeneric" ? "promotionId" : props.columns == "searchedCouponTargeted" ? "promotionId" : "couponId"} */
								/* keyField="promotionId" */
								keyField="id"
								noDataIndication={() => <div>No BINs setup</div>}
								data={this.props.data1}
								columns={this.state.columns}
								filter={filterFactory()}
								selectRow={
									this.props.type == 'VERSION'
										? undefined
										: this.selectRowExclusion
								}
								/*  remote={true} */
								//  pagination={paginationFactory(this.options)}
							/>
						</div>
						<div className="d-flex">
							<div
								style={{
									'justify-self': 'center',
									flex: 1,
									'text-align': 'center',
									'margin-top': '20px'
								}}
							>
								<button
									className={
										this.props.pageNumberExclusion == 1
											? 'btn btn-primary m-2 disabled'
											: 'btn btn-primary m-2'
									}
									onClick={() => {
										if (this.props.type == 'VERSION')
											this.props.onPageChange('VerExclusion', 'Previous');
										else this.props.onPageChange('Exclusion', 'Previous');
									}}
								>
									Previous
								</button>
								<div className="btn btn-primary m-2">
									{this.props.pageNumberExclusion}
								</div>
								<button
									className={
										this.props.maxPageExclusion ==
											this.props.pageNumberExclusion ||
										this.props.data1.length == 0
											? 'disabled btn btn-primary m-2'
											: 'btn btn-primary m-2'
									}
									onClick={() => {
										if (this.props.type == 'VERSION')
											this.props.onPageChange('VerExclusion', 'Next');
										else this.props.onPageChange('Exclusion', 'Next');
									}}
								>
									Next
								</button>
							</div>

							{/* {this.props.type == "VIEW" ? 
                             <div style={{
                              "margin-top": "20px"
                          }} class="mr-4">
                             <button className="cancel-btn1 roboto-b-16 " onClick={() => {
                            
                              this.props.gotoCouponSearch();

                          }} >Cancel</button></div>: undefined} */}
						</div>
					</div>
				)}
			</ToolkitProvider>
		);
	}
}

export default BinDetailsTable;
