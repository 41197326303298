import * as actionTypes from './actions';

const initialState = {
	loading: false,
	fullName: '',
	// fullName:{fullName:"Harish D",operatorID:"Harish01"},
	disableItem: false,
	assignedResources: [],
	loggedInLevelID: '',
	loggedInLevelHierarchy: '',
	loggedInBrand: '',
	currentMousePlace: '',
	loading2: false,
	allCategoryHierarchy: []
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOADING_BUTTON:
			return {
				...state,
				loading: action.value
			};
		case actionTypes.FULL_NAME:
			return {
				...state,
				fullName: action.value
			};
		case actionTypes.DISABLE_ITEM:
			return {
				...state,
				disableItem: action.value /* use to disable left nav */
			};
		case actionTypes.ASSIGNED_RESOURCES:
			return {
				...state,
				assignedResources: action.value
			};
		case actionTypes.LOGGEDIN_LEVELID:
			return {
				...state,
				loggedInLevelID: action.value
			};
		case actionTypes.LOGGEDIN_LEVELHIERARCHY:
			return {
				...state,
				loggedInLevelHierarchy: action.value
			};
		case actionTypes.LOGGEDIN_BRAND:
			return {
				...state,
				loggedInBrand: action.value
			};
		case actionTypes.MOUSE_PLACE:
			return {
				...state,
				currentMousePlace: action.value
			};
		case actionTypes.LOADING_BUTTON2 /* todo: relevant name page level loader */:
			return {
				...state,
				loading2: action.value
			};
		case actionTypes.allCategoryHierarchy:
			return {
				...state,
				allCategoryHierarchy: action.value
			};
		default:
			return state;
	}
};

export default reducer;
