import React, { Component } from 'react';
import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import resetIcon from './../../assets/images/undo.png';
import Datetime from 'react-datetime';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import { withRouter } from 'react-router-dom';
import Input from '../../Components/Input/Input';

export const FormInput = ({ formElementsArray, onInputChange, onViewModeChange, clearBtnClick }) => {
	let elm = [];
	const { config } = formElementsArray;
	switch (config.elementType) {
		case 'text':
			elm = (
				<>
					<input
						name={config.elementConfig.name}
						id={config.elementConfig.id || config.elementConfig.name}
						type={config.elementConfig.type}
						className={`form_control ${
							!config.valid && config.touched
								? 'Invalid'
								: ''
						}`}
						value={config.value}
						placeholder={config.elementConfig.placeholder}
						onChange={(ev) =>
							onInputChange(
								ev,
								config.validation,
								formElementsArray.id
							)
						}
						{...config.attrValidation}
					/>
					<label
						className={`${
							config.validation.required ? 'require' : ''
						}`}
					>
						{config.elementConfig.labelname}
					</label>
					<p className="errorMessage">{config.validationMessage}</p>
				</>
			);
			break;
		case 'select':
			elm = (
				<>
					<select
						name={config.elementConfig.name}
						id={config.elementConfig.id || config.elementConfig.name}
						className={`form_control customSelectIcon ${
							!config.valid && config.touched
								? 'Invalid'
								: ''
						}`}
						value={config.value}
						onChange={(ev) =>
							onInputChange(
								ev,
								config.validation,
								formElementsArray.id
							)
						}
					>
						{config.elementConfig.options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.displayValue}
							</option>
						))}
					</select>
					<label
						className={`${
							config.validation.required ? 'require' : ''
						}`}
					>
						{config.elementConfig.labelname}
					</label>
					<p className="errorMessage">{config.validationMessage}</p>
				</>
			);

			break;
		case 'dateTime':
			elm = (
				<>
					<div>
						<Datetime
							className={`float-left ${
								!config.valid && config.touched
									? 'Invalid '
									: ''
							}`}
							locale="en-GB"
							onChange={(ev) =>
								onInputChange(
									ev,
									config.validation,
									formElementsArray.id
								)
							}
							//For min and max date
							//isValidDate={(current) => { return current.isBefore(moment()) }}
							dateFormat="DD-MM-YYYY"
							inputProps={{ 
								name: config.elementConfig.name,
								id: (config.elementConfig.id || config.elementConfig.name),
								placeholder: 'DD-MM-YYYY HH:mm' }}
							value={config.value}
							closeOnSelect={true}
							onViewModeChange={onViewModeChange}
							timeFormat="HH:mm"
						/>
						<button className="clrBtn " onClick={clearBtnClick}>
							X
						</button>
						<label
							className={`labelStyle dateLabel ${
								config.validation.required ? 'require' : ''
							}`}
						>
							{config.elementConfig.labelname}
						</label>
					</div>
					<p className="errorMessage">{config.validationMessage}</p>
				</>
			);
			break;
		default:
			elm = (
				<Input
					elementType={config.elementType}
					value={config.value}
					changed={(e) => {
						this.onInputChange(
							e,
							config.validation,
							formElementsArray.id
						);
					}}
					elementConfig={{
						...config.elementConfig
					}}
				/>
			);
	}
	return elm;
};

export class SearchOffer extends Component {
	initialState = {
		searchForm: {
			offerType: {
				elementType: 'select',
				elementConfig: {
					name:'offerType',

					type: 'select',
					placeholder: 'Enter Offer Type',
					labelname: 'Promotion Type',
					options: [
						{ value: '', displayValue: 'ALL' },
						{ value: 'MULTIBUYPRODUCT', displayValue: 'Multibuy Product' },
						{ value: 'MULTIBUYGROUP', displayValue: 'Multibuy Group' },
						{ value: 'MERCHANDIZE', displayValue: 'Selling Hierarchy' },
						{ value: 'MULTIBUYPACKAGE', displayValue: 'Package' },
						/* { value: 'PACKAGEPRODUC T', displayValue: 'Package Product' },
		  { value: 'PACKAGEMERCHANDIZE', displayValue: 'Package Selling Hierarchy' }, */
						// { value: 'SCALEPRODUCT', displayValue: 'Scale Product' },
						// { value: 'SCALEMERCHANDIZE', displayValue: 'Scale Selling Hierarchy' },
						{ value: 'MULTIBUYSCALE', displayValue: 'Scale' },
						{ value: 'TXN', displayValue: 'Transaction' },
						{ value: 'SCALETXN', displayValue: 'Transaction Scale' },
						{ value: 'MTMB', displayValue: 'Multi Target Multi Benefit' },
						// { value: 'EARN_BURN_PRODUCT', displayValue: 'Earn Burn Product' },
						// { value: 'EARN_BURN_MERCHANDIZE', displayValue: 'Earn Burn Selling Hierarchy' },
						{ value: 'EARN_BURN', displayValue: 'Earn Burn' },
						{ value: 'SIMPLE', displayValue: 'Simple' }
					]
				},
				value: '',
				validation: {
					required: false
				},

				validationMessage: '',
				valid: false,
				touched: false
			},
			benefitType: {
				elementType: 'select',
				elementConfig: {
					name:'benefitType',

					type: '',
					placeholder: 'Enter Benefit Type',
					labelname: 'Benefit Type',
					options: [
						{ value: '', displayValue: 'ALL' },
						{ value: 'INSTANT', displayValue: 'Instant Discount' },
						{ value: 'CASHBACK', displayValue: 'Cashback' },
						{ value: 'LOYALTY_POINTS', displayValue: 'Loyalty Points' },
						{ value: 'EARN_BURN', displayValue: 'Earn Burn' },
						{ value: 'EMI', displayValue: 'EMI' },
						{ value: 'FREEBIE', displayValue: 'Freebies' },
						{ value: 'FREE_TEXT', displayValue: 'Free text' }
					]
				},
				value: '',
				validation: {
					required: false
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			startDateTime: {
				elementType: 'dateTime',
				elementConfig: {
					name:'startDateTime',

					type: 'text',
					placeholder: 'Enter Start Date & Time',
					labelname: 'Start Date & Time'
				},
				value: '',
				validation: {
					required: false,
					isPhoneNumber: true
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			endDateTime: {
				elementType: 'dateTime',
				elementConfig: {
					name:'endDateTime',

					type: 'text',
					placeholder: 'Enter End Date & Time',
					labelname: 'End Date & Time'
				},
				value: '',
				validation: {
					required: false,
					isPhoneNumber: true
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			createdDate: {
				elementType: 'dateTime',
				elementConfig: {		
					name:'createdDate',

					type: 'text',
					placeholder: 'Enter Created Date',
					labelname: 'Created Date & Time'
				},
				value: '',
				validation: {
					required: false,
					isPhoneNumber: true
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			createdBy: {
				elementType: 'select',
				elementConfig: {
					name:'createdBy',

					type: '',
					placeholder: 'Enter Created By',
					labelname: 'Created By',
					options: [{ value: '', displayValue: 'ALL' }]
				},
				value: '',
				validation: {
					required: false
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			productID: {
				elementType: 'text',
				elementConfig: {
					name:'productID',
					type: 'text',
					placeholder: 'Enter Product ID',
					labelname: 'Product ID'
				},
				value: '',
				validation: {
					// required: true,
					// maxLength: 10
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			},
			status: {
				elementType: 'select',
				elementConfig: {
					name:'status',
					type: '',
					placeholder: 'Enter Status',
					labelname: 'Status',
					options: [
						{ value: '', displayValue: 'ALL' },
						{ value: 'Active', displayValue: 'Active' },
						{ value: 'Scheduled', displayValue: 'Scheduled' },
						{ value: 'Expired', displayValue: 'Expired' },
						{ value: 'Paused', displayValue: 'Paused' }
					]
				},
				value: '',
				validation: {
					required: false
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			paymentBased: {
				elementType: 'select',
				elementConfig: {
					type: 'select',
					name: 'paymentBased',
					labelname: 'Payment Based',
					options: [
						{ value: '', displayValue: 'ALL' },
						{ value: 'payment', displayValue: 'Payment' },
						{ value: 'non-Payment', displayValue: 'Non-Payment' }
					]
				},
				value: '',
				validation: {
					required: false
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			couponBased: {
				elementType: 'select',
				elementConfig: {
					type: 'select',
					name: 'couponBased',
					labelname: 'Coupon Based',
					placeholder: 'Select Coupon Based',
					options: [
						{ value: '', displayValue: 'ALL' },
						{ value: 'coupon', displayValue: 'Coupon' },
						{ value: 'non-coupon', displayValue: 'Non-Coupon' }
					]
				},
				value: '',
				validation: {
					required: false
				},
				validationMessage: '',
				valid: false,
				touched: false
			}
		},
		formIsValid: false
	};

	state = this.initialState;

	componentDidMount = () => {
		this.getCreatedByOptions();
	};

	handleFormReset = () => {
		this.setState(() => this.initialState);
	};

	inputChangedHandler = (value, isValidObj, inputIdentifier, dateTimeRangeChanged) => {
		const updatedLoginForm = { ...this.state.searchForm };
		const updatedFormElement = { ...updatedLoginForm[inputIdentifier] };

		updatedFormElement.value = value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;

		updatedLoginForm[inputIdentifier] = updatedFormElement;
		if (dateTimeRangeChanged) {
			const updatedFormStartDateTimeElement = { ...updatedLoginForm['startDateTime'] };
			const updatedFormEndDateTimeElement = { ...updatedLoginForm['endDateTime'] };
			if (!moment(value, 'DD-MM-YYYY HH:mm', true).isValid()) {
				updatedFormElement.valid = false;
				updatedFormElement.validationMessage = 'Invalid date format';
			} else {
				updatedFormElement.valid = true;
				updatedFormElement.validationMessage = '';
			}
			if (updatedFormStartDateTimeElement.value && updatedFormEndDateTimeElement.value) {
				if (
					moment(updatedFormStartDateTimeElement.value) >
					moment(updatedFormEndDateTimeElement.value)
				) {
					updatedFormEndDateTimeElement.valid = false;
					updatedFormEndDateTimeElement.validationMessage = 'Invalid date time range';
				} else {
					updatedFormEndDateTimeElement.valid = isValidObj.value;
					updatedFormEndDateTimeElement.validationMessage = isValidObj.message;
				}
			}

			updatedLoginForm['endDateTime'] = updatedFormEndDateTimeElement;
		}
		let formIsValid = true;
		for (let inputIdentifier in updatedLoginForm) {
			formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ searchForm: updatedLoginForm, formIsValid: formIsValid });
	};

	onInputChange = (event, definedRules, inputIdentifier) => {
		let value = event.target.value;
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value.trim() !== '';
				isValid.message = isValid.value ? '' : '';
			}

			if (rules.minLength) {
				isValid.value = value.length >= rules.minLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Minimum length is ' + rules.minLength;
			}

			if (rules.maxLength) {
				isValid.value = value.length <= rules.maxLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Maximum length is ' + rules.maxLength;
			}

			if (rules.isEmail && value) {
				let regEx = new RegExp(
					/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid email';
			}

			if (rules.isPhoneNumber && value) {
				let regEx = new RegExp(
					/^\+?(\d+|(\(\d+\))+)+((([ ]?(\d+|(\(\d+\))+)+)*)|((\-\d+)*)|((\.\d+)*))*$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid phone number';
			}

			if (rules.isAlphabets && value) {
				let regEx = new RegExp('^[a-zA-Z]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets';
			}

			if (rules.isAlphaNumeric && value) {
				let regEx = new RegExp('^[a-zA-Z0-9]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets and numbers';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, false);
	};
	onDateChange = (startDate, definedRules, inputIdentifier) => {
		let value = startDate;
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value !== '';
				isValid.message = isValid.value ? '' : 'Field required';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, true);
	};

	getCreatedByOptions = () => {
		this.props.showLoadingButton(true);
		var dataToSend = {
			getUsersForFunctionIdRequest: {
				functionID: this.props.loggedInBrand.businessUnitGroupFunctionID
			}
		};

		RestSvc.postData(urlTypes.offers_maintenance.getUsersForFunctionID, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				const inputIdentifier = 'createdBy';
				const updatedSearchForm = { ...this.state.searchForm };
				const updatedFormElement = { ...updatedSearchForm[inputIdentifier] };
				updatedSearchForm[inputIdentifier] = updatedFormElement;
				var tempArr = [{ value: '', displayValue: 'ALL' }];
				var Users = response.data.getUsersForFunctionIdResponse.userIds;
				Users.map(function (item) {
					tempArr.push({
						value: item,
						displayValue: item
					});
				});
				updatedFormElement.elementConfig.options = tempArr;
				this.setState({ searchForm: updatedSearchForm });
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.searchForm) {
			formElementsArray.push({
				id: key,
				config: this.state.searchForm[key]
			});
		}
		return (
			<>
				<div className="modal-body">
					<div className="row p-4">
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[0]}
								onInputChange={this.onInputChange}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[1]}
								onInputChange={this.onInputChange}
							/>
						</div>
					</div>
					<div className="row p-4 searchOfferDate">
						<div className="col-md-6 d-flex flex-column">
							<FormInput
								formElementsArray={formElementsArray[2]}
								onInputChange={this.onDateChange}
								onViewModeChange={() => {
									if (formElementsArray[2].config.value == '') {
										formElementsArray[2].config.value = new Date();
									}
								}}
								clearBtnClick={() => {
									this.setState((prevState) => {
										let previousState = { ...prevState };
										previousState.searchForm.startDateTime.value = '';
										previousState.searchForm.startDateTime.valid = true;
										previousState.searchForm.startDateTime.validationMessage =
											'';
										previousState.searchForm.endDateTime.valid = true;
										previousState.searchForm.endDateTime.validationMessage = '';
										return previousState;
									});
								}}
							/>
						</div>
						<div className="col-md-6 d-flex flex-column">
							<FormInput
								formElementsArray={formElementsArray[3]}
								onInputChange={this.onDateChange}
								onViewModeChange={() => {
									if (formElementsArray[3].config.value == '') {
										formElementsArray[3].config.value = new Date();
									}
								}}
								clearBtnClick={() => {
									this.setState((prevState) => {
										let previousState = { ...prevState };
										previousState.searchForm.endDateTime.value = '';
										previousState.searchForm.endDateTime.valid = true;
										previousState.searchForm.endDateTime.validationMessage = '';
										return previousState;
									});
								}}
							/>
						</div>
					</div>

					<div className="row p-4">
						<div className="col-md-6 d-flex flex-column">
							<FormInput
								formElementsArray={formElementsArray[4]}
								onInputChange={this.onDateChange}
								onViewModeChange={() => {
									if (formElementsArray[4].config.value == '') {
										formElementsArray[4].config.value = new Date();
									}
								}}
								clearBtnClick={() => {
									this.setState((prevState) => {
										let previousState = { ...prevState };
										previousState.searchForm.createdDate.value = '';
										previousState.searchForm.createdDate.valid = true;
										previousState.searchForm.createdDate.validationMessage = '';
										return previousState;
									});
								}}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[5]}
								onInputChange={this.onInputChange}
							/>
						</div>
					</div>
					<div className="row p-4">
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[6]}
								onInputChange={this.onInputChange}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[7]}
								onInputChange={this.onInputChange}
							/>
						</div>
					</div>
					<div className="row p-4">
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[8]}
								onInputChange={this.onInputChange}
							/>
						</div>
						<div className="col-md-6">
							<FormInput
								formElementsArray={formElementsArray[9]}
								onInputChange={this.onInputChange}
							/>
						</div>
					</div>
				</div>

				<div className="modal-footer border-0">
					<p
						onClick={() => {
							this.handleFormReset();
						}}
						className=" grey-button roboto-b-16"
					>
						<img src={resetIcon} alt="img" /> Reset
					</p>
					<button
						onClick={() => this.props.closeHandler(false)}
						className="cancel-btn roboto-b-16 ml-auto"
					>
						Cancel
					</button>
					<button
						className={
							!formElementsArray[3].config.valid &&
							formElementsArray[3].config.touched
								? 'disabled btnNxtPrev ml-2 roboto-b-16'
								: 'btnNxtPrev ml-2 roboto-b-16'
						}
						onClick={() => {
							this.props.searchHandler(this.state.searchForm);
						}}
					>
						Search
					</button>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchOffer));
