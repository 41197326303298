import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Home from './Components/HomePage';
import LevelMaintenance from './Components/LevelMaintenance';
import UserMaintenance from './Components/UserMaintenance';
import CouponManagement from './Components/CouponManagement';
import './MainPage.scss';
import ChangePasswordModal from './ChangePasswordModal';
import * as RestSvc from '../libraries/offer-services';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import * as urlTypes from '../libraries/offer-service-urls';

import OfferConfigurator from './OfferConfigurator/OfferConfigurator';
import { ConfirmationModal } from './ConfirmationModal';
import './ReactDateTime.css';
import AuditMaintenance from './Components/AuditMaintenance';
import BinRangeManagement from './Components/BinRangeManagement';
import NotificationScreen from './NotificationScreen/NotificationScreen';
import CustomerManagement from './CustomerManagement/CustomerManagement.jsx';
import Toaster from '../Components/Toaster/Toaster';
import { clearUserSession } from '../utils/helperFunction';
import ErrorBoundary from '../Components/ErrorBoundary/ErrorBoundary';
import LeftMenuNav from '../Components/LeftMenuNav/LeftMenuNav';
import TopHeader from '../Components/TopHeader/TopHeader';
import OfferConfiguratorCS from './OfferConfiguratorCS/OfferConfiguratorCS';
import appConstants from '../utils/appConstants';
import SegmentManagement from './SegmentManagement/SegmentManagement.jsx';

const { buBrand } = appConstants;

class MainPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			changePwdModalShow: false,
			dropdownOpen: false,
			confirmationModalShow: false,
			confirmMsg: ''
		};
		this.idleTimer = null;
	}

	onLogOut = () => {
		var dataToSend = {
			revokeRefreshToken: {
				userID: this.props.userFullName.operatorID
			}
		};
		this.props.showLoadingButton(true);
		RestSvc.postData(urlTypes.user_maintenance.logout, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				clearUserSession();
				this.props.history.push('/');
				/* to clear the network logs from network tap reloading the page */
				window.location.reload(false);
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
				this.props.history.push('/');
				window.location.reload(false);
			});
	};

	confirmationModalClose = () => {
		this.setState({ confirmationModalShow: false });
	};

	showLogOffConfirmation = () => {
		this.setState({
			confirmationModalShow: true,
			confirmMsg: 'Are you sure you wish to log off?'
		});
	};

	proceedToOperation = () => {
		this.onLogOut();
	};

	toggle = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen });
	};

	changePwdModalHandler = (status) => {
		this.setState({ changePwdModalShow: status });
	};

	navigateToLogin = () => {
		this.props.history.push('/');
	};

	render() {
		return (
			<div className="MainPageFull row">
				<div className="col page-content">
					<TopHeader
						disableItem={this.props.disableItem}
						assignedResources={this.props.assignedResources}
						userFullName={this.props.userFullName}
						dropdownOpen={this.state.dropdownOpen}
						toggle={this.toggle}
						changePwdModalOpen={() => {
							this.changePwdModalHandler(true);
						}}
						showLogOffConfirmation={this.showLogOffConfirmation}
						location={this.props.location}
					/>
					<div className="row page-section">
						<section className="main-section">
							<LeftMenuNav
								disableItem={this.props.disableItem}
								assignedResources={this.props.assignedResources}
								location={this.props.location}
								isCustomerService={
									this.props.loggedInBrand.businessUnitGroupFunctionID ===
									buBrand.CUSTOMER_SERVICE.BUID
								}
							/>
							<div id="level" className="main-content mt-10">
								<Switch>
									<Route path="/MainPage/home">
										<Home />
									</Route>
									<Route path="/MainPage/offerconfigurator">
										<ErrorBoundary source="offerconfigurator">
											<OfferConfigurator />
										</ErrorBoundary>
									</Route>
									<Route path="/MainPage/offerconfiguratorcs">
										<ErrorBoundary source="offerconfiguratorcs">
											<OfferConfiguratorCS />
										</ErrorBoundary>
									</Route>
									<Route path="/MainPage/levelmaintenance">
										<ErrorBoundary source="levelmaintenance">
											<LevelMaintenance />
										</ErrorBoundary>
									</Route>
									<Route path="/MainPage/usermaintenance">
										<ErrorBoundary source="usermaintenance">
											<UserMaintenance />
										</ErrorBoundary>
									</Route>
									<Route path="/MainPage/couponManagement">
										<ErrorBoundary source="couponManagement">
											<CouponManagement />
										</ErrorBoundary>
									</Route>
									<Route path="/MainPage/auditMaintenance">
										<ErrorBoundary source="auditMaintenance">
											<AuditMaintenance />
										</ErrorBoundary>
									</Route>
									<Route path="/MainPage/binRangeManagement">
										<ErrorBoundary source="binRangeManagement">
											<BinRangeManagement />
										</ErrorBoundary>
									</Route>
									<Route path="/MainPage/notificationManager">
										<ErrorBoundary source="notificationManager">
											<NotificationScreen />
										</ErrorBoundary>
									</Route>
									<Route path="/MainPage/CustomerManager">
										<CustomerManagement />
									</Route>
									<Route path="/MainPage/segmentManagement">
										<SegmentManagement />
									</Route>
								</Switch>
							</div>
						</section>
					</div>
				</div>
				{this.state.changePwdModalShow && (
					<ChangePasswordModal
						show={this.state.changePwdModalShow}
						onHide={() => {
							this.changePwdModalHandler(false);
						}}
						navigateLogin={this.navigateToLogin}
						operatorid={this.props.userFullName.operatorID}
						userName="username"
					/>
				)}
				{this.state.confirmationModalShow && (
					<ConfirmationModal
						show={this.state.confirmationModalShow}
						onHide={this.confirmationModalClose}
						onProceed={this.proceedToOperation}
						confirmmsg={this.state.confirmMsg}
					/>
				)}
			</div>
		);
	}
	onAction = (e) => {};

	onActive = (e) => {};

	onIdle = (e) => {
		Toaster({ message: 'You have been idle, Logging Out...' });
		setTimeout(() => {
			this.onLogOut();
		}, 5000);
	};
}

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		disableItem: state.disableItem,
		assignedResources: state.assignedResources,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainPage));
