import React, { Component } from 'react';
import Edit from './../../assets/images/Edit.png';

import moment from 'moment';
import Delete from './../../assets/images/deleteIcon.png';
import Tick from './../../assets/images/checked.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class PreviewScreen extends Component {
	createLevel = () => {
		this.props.createLevel();
	};

	render() {
		let {
			hierarchicalLevel,
			createLevelForm,
			startDate,
			endDate,
			updatedCards,
			permissionsArray,
			clickPreview,
			existingLevelID
		} = this.props.formElements;

		return (
			<div className="col-md-9 p-0 m-0 reviewBody">
				<div className="review">
					<div className="reviewHeader">
						<span className="levelHierNumber">{hierarchicalLevel}</span>
						<span className="levelName">
							<div className="contentOne">
								{createLevelForm.levelName.value}
								{clickPreview ? (
									<span
										className={
											!(
												this.props.assignedResources.indexOf('OC009') != -1
											) ||
											(this.props.loggedInLevelHierarchy ==
												hierarchicalLevel &&
												this.props.loggedInLevelHierarchy != 1)
												? 'disabled pl-3'
												: 'pl-3 pointer'
										}
										onClick={() => {
											this.props.deleteLevel();
										}}
									>
										<img className="highlight" src={Delete} alt="img" />
									</span>
								) : null}
							</div>
							<div className="contenTwo">
								{createLevelForm.levelDescription.value}
							</div>
							{this.props.createdOn ? (
								<div className="contentThree">
									Created on{' '}
									{moment(this.props.createdOn).format('DD-MM-YYYY HH:mm')}
								</div>
							) : null}
						</span>
					</div>
					<div className="container containerClass1">
						<div className="row basicDetailsRow">
							<div className="col-md-12">
								<span className="hierarchyText">Basic Details</span>
								<span
									className={
										this.props.isExistingLevel == true
											? !(
													this.props.assignedResources.indexOf('OC008') !=
													-1
											  ) ||
											  (this.props.loggedInLevelHierarchy ==
													hierarchicalLevel &&
													this.props.loggedInLevelHierarchy != 1)
												? 'disabled reviewImage'
												: 'reviewImage pointer'
											: 'reviewImage pointer'
									}
									onClick={() => {
										this.props.editBasic();
									}}
								>
									<img className="highlight" src={Edit} alt="img" />
								</span>
							</div>
						</div>
						<div className="row levelsRow">
							<div className="col-md-3 cols">
								Level Id
								<div className="row rows">
									{existingLevelID ? existingLevelID : '-'}
								</div>
							</div>
							<div className="col-md-3 cols">
								Level name
								<div className="row rows">{createLevelForm.levelName.value}</div>
							</div>
							<div className="col-md-3 cols">
								Description
								<div className="row rows">
									{createLevelForm.levelDescription.value}
								</div>
							</div>
							<div className="col-md-3 cols">
								Status
								<div className="row rows">
									{createLevelForm.status.value === 'InActive' ||
									createLevelForm.status.value === 'I'
										? 'Inactive'
										: 'Active'}
								</div>
							</div>
						</div>
						<div className="row dateRows">
							<div className="col-md-3 cols">
								Start Date
								<div className="row rows">
									{moment(startDate).format('DD-MM-YYYY HH:mm')}
								</div>
							</div>
							<div className="col-md-3 cols">
								End Date
								<div className="row rows">
									{endDate ? moment(endDate).format('DD-MM-YYYY HH:mm') : '-'}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className="block1">
									<span className="hierarchyText">Hierarchy</span>
									<span
										className={
											this.props.isExistingLevel == true
												? !(
														this.props.assignedResources.indexOf(
															'OC008'
														) != -1
												  ) ||
												  (this.props.loggedInLevelHierarchy ==
														hierarchicalLevel &&
														this.props.loggedInLevelHierarchy != 1)
													? 'disabled hierarchyEdit'
													: 'hierarchyEdit pointer'
												: 'hierarchyEdit pointer'
										}
										onClick={() => {
											this.props.editHierarchy();
										}}
									>
										<img className="highlight" src={Edit} alt="img" />
									</span>
								</div>

								{updatedCards.map((level, i) => (
									<div
										key={i}
										className={
											this.props.loggedInLevelHierarchy >
												level.hierarchicalLevel &&
											(level.levelName !== 'Administrator' ||
												this.props.loggedInLevelHierarchy != '1')
												? 'd-lg-none row rows'
												: 'row rows'
										}
									>
										<span
											className={`id ${
												createLevelForm.levelName.value === level.levelName
													? 'customBlue'
													: ''
											}`}
										>
											{i + 1}
										</span>
										<span className="data">{level.levelName}</span>
									</div>
								))}
							</div>
							<div className="col-md-6">
								<div className="block2">
									<span className="permissionText">Permissions</span>
									<span
										className={
											this.props.isExistingLevel == true
												? !(
														this.props.assignedResources.indexOf(
															'OC008'
														) != -1
												  ) ||
												  (this.props.loggedInLevelHierarchy ==
														hierarchicalLevel &&
														this.props.loggedInLevelHierarchy != 1)
													? 'disabled permissionEdit'
													: 'permissionEdit pointer'
												: 'permissionEdit pointer'
										}
										onClick={() => {
											this.props.editPermissions();
										}}
									>
										<img className="highlight" src={Edit} alt="img" />
									</span>
								</div>
								<ul className="row rows pl-3 list-unstyled">
									{permissionsArray.map((permission, index) => {
										return permission.isAssigned ? (
											<li key={index} className="w-100">
												<img src={Tick} alt="img" />{' '}
												<span className="pl-1">
													{' '}
													{permission.description}
												</span>
											</li>
										) : null;
									})}
								</ul>
							</div>
						</div>
					</div>
				</div>

				{clickPreview ? null : (
					<div className="m-0 row footer pt-0">
						<div className="col-md-3 offset-md-2"></div>
						<div className="col-md-4 offset-md-1">
							<button
								className="btnCancel roboto-b-16"
								onClick={() => {
									this.props.cancel();
								}}
							>
								Cancel
							</button>
							<button
								className="btnNxtPrev ml-2 roboto-b-16"
								onClick={() => this.createLevel()}
							>
								Submit
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		disableItem: state.disableItem,
		assignedResources: state.assignedResources,
		loggedInLevelHierarchy: state.loggedInLevelHierarchy
	};
};

export default connect(mapStateToProps, null)(withRouter(PreviewScreen));
