import React, { useEffect } from 'react';
import { useField } from 'formik';

import ConditionalRedering from './ConditionalRedering';
import { itemHelp } from '../item-help-text';
import CustomSelect from '../customfields/CustomSelect';
import $ from 'jquery';

function Txn(props) {
	const thresholdTypeCodeOptions = [
		{
			value: '',
			name: 'Select one...'
		},
		{
			value: 'BASKET_INTERVAL_QTY_THRESHOLD',
			name: 'Basket Interval Quantity Threshold'
		},
		{
			value: 'BASKET_LIMIT_QTY_THRESHOLD',
			name: 'Basket Limit Quantity Threshold'
		},
		{
			value: 'BASKET_RANGE_VALUE_THRESHOLD',
			name: 'Basket Range Amount Threshold'
		},
		{
			value: 'BASKET_VALUE_THRESHOLD',
			name: 'Basket Value Threshold'
		},
		{
			value: 'BASKET_INTERVAL_VALUE_THRESHOLD',
			name: 'Basket Interval Value Threshold'
		},
		{
			value: 'BASKET_QTY_THRESHOLD',
			name: 'Basket Quantity Threshold'
		}
	];

	const getThresholdDropdown = (thresholdTypeCodeOptions) => {
		return thresholdTypeCodeOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	//Local const

	const [field] = useField(props);
	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));

	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility
					.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.thresholdTypeCode !=
				field.value.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.thresholdTypeCode
			) {
				let tempName =
					'totalPurchaseMarketBasedPriceDerivationRuleEligibiity.thresholdTypeCode';

				$('[name="' + tempName + '"]')
					.closest('.container')
					.parent()
					.nextAll()
					.addClass('d-none');
			}
		}
	}, []);
	return (
		<div className="container-fluid">
			{/* <div class="accordion" id="accordionExample">
        <div class="card" style={{ border: "none" }}>
          <CollapseHeaderBar
            text={localConst.eligibilitySettings}
            headingId={"headingOne"}
            dataTarget={"#collapseOne"}
          />
          <CollapseBody id="collapseOne" headingId={"headingOne"}> */}
			<div className="d-flex justify-content-center">
				<CustomSelect
					label="Threshold Type Code"
					name="totalPurchaseMarketBasedPriceDerivationRuleEligibiity.thresholdTypeCode"
					options={getThresholdDropdown(thresholdTypeCodeOptions)}
					required={true}
					className="customDropdown inlinePadding bg-position"
					smallText={itemHelp.ThresholdTypeCodeTXN}
				/>
			</div>
			{field.value.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.thresholdTypeCode ? (
				<ConditionalRedering
					value={
						field.value.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
							.thresholdTypeCode
					}
					resetProductTable={props.resetProductTable}
				/>
			) : null}
			{/* </CollapseBody>
        </div>
      </div> */}
			{/* ProductSetting */}
			{/*  <div class="accordion" id="accordionExample">
            <div class="card" style={{ border: "none" }}>
              <CollapseHeaderBar
                text={localConst.ProductSetting}
                headingId={"headingThree"}
                dataTarget={"#collapseThree"}
              />
              <CollapseBody id="collapseThree" headingId={"headingThree"}>
                <Field name="multibuyProduct" component={ProductSetting} />
              </CollapseBody>
            </div>
          </div>
          <div class="accordion" id="accordionExample">
            <div class="card" style={{ border: "none" }}>
              <CollapseHeaderBar
                text={localConst.benefitSettings}
                headingId={"headingTwo"}
                dataTarget={"#collapseTwo"}
              />
              <CollapseBody id="collapseTwo" headingId={"headingTwo"}>
                <Field name="benefit" component={BenefitSettings} />
              </CollapseBody>
            </div>
          </div> */}
		</div>
	);
}

export default Txn;
