import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import appLabels from '../../utils/appLabels';
import { FormikGroup } from '../../Components/Input/FormikControl';
import resetIcon from '../../assets/images/undo.png';
import { stringReplacer } from '../../utils/helperFunction';
import { fieldValidator } from '../GenerateCoupon/GenerateCouponForm';
import Toaster from '../../Components/Toaster/Toaster';
import appConstants from '../../utils/appConstants';

const {
	common: commonLabels,
	page: { couponPattern: pageLabels }
} = appLabels;
const { formLabels } = pageLabels;
const PATTERN_LENGTH = 4;
const defaultPatterSeparator = [
	'-',
	'#',
	'@',
	'$',
	'%',
	'&',
	'*',
	'^',
	'!',
	'_',
	'No Separator'
].map((val) => {
	return {
		value: val === 'No Separator' ? '' : val,
		label: val
	};
});

const initFormValues = (initVal = {}) => {
	const initialValues = {
		patternSeparator: defaultPatterSeparator[0].value,
		pattern: '',
		patterns: []
	};
	return initialValues;
};

const isPatternValid = (formik) => {
	const { patternSeparator, pattern } = formik.values;
	let error = null;
	const patternWordLen = pattern.split(patternSeparator).join('').length;
	error = fieldValidator(['require'])(pattern);
	if (error === null && patternWordLen < PATTERN_LENGTH) {
		error = stringReplacer(pageLabels.minPatternLengthText, '@min', PATTERN_LENGTH);
	}
	return {
		error,
		isValid: error === null
	};
};

const formControls = ({ addPatternRefBtn, formik }) => {
	const isValidPatternValid = isPatternValid(formik);
	const inputs = [
		[
			{
				control: 'select',
				...formLabels.patternSeparator,
				options: defaultPatterSeparator,
				name: 'patternSeparator',
				controlClass: 'vertical-group',
				inputClass: 'customSelectIcon'
				// validate: fieldValidator([charValidation({ min: 0, max: 1 })])
			},
			{
				control: 'input',
				...formLabels.pattern,
				name: 'pattern',
				required: true,
				iconHelper: false,
				controlClass: 'vertical-group',
				inputClass: (!isValidPatternValid.isValid && 'is-invalid') || '',
				helperText: pageLabels.patterHelperTxt,
				extraElm: formik.touched.pattern && !isValidPatternValid.isValid && (
					<span className="error text-danger">{isValidPatternValid.error}</span>
				),
				children: (
					<span
						title={
							(!isValidPatternValid.isValid && pageLabels.patternGenErrorText) || ''
						}
					>
						<button
							ref={addPatternRefBtn}
							type="button"
							className={`addPlusBtn ml-2 ${
								isValidPatternValid.isValid ? '' : 'disabled'
							}`}
							disabled={!isValidPatternValid.isValid}
							name="addPatternBtn"
							title={pageLabels.addPatternBtnText}
							onClick={() => {
								const { patternSeparator, pattern, patterns } = formik.values;
								const regExp = new RegExp(
									`[^${patternSeparator}]`,
									'g'
								); /* not patternSeparator */
								const formattedPattern = pattern.replace(
									regExp,
									appConstants.generateCouponMask
								);
								const isExist = patterns.filter(
									({ formattedPattern: _formattedPattern }) =>
										_formattedPattern === formattedPattern
								);
								/* check pattern format validation */
								if (isExist.length) {
									Toaster({
										message: `${pattern} ${pageLabels.patternFormatExist}`,
										closeAll: true
									});
									return;
								}
								if (pattern !== '') {
									formik.setFieldValue('patterns', [
										...patterns,
										{
											separator: patternSeparator,
											formattedPattern,
											pattern
										}
									]);
									formik.setFieldValue('pattern', '');
								}
							}}
						/>
					</span>
				)
			}
		],
		[
			{
				control: 'fieldArray',
				name: 'patterns',
				label: 'Pattern',
				fieldKeys: ['separator', 'pattern'],
				readOnly: true,
				showAllElms: false,
				controlClass: 'vertical-group'
			}
		]
	];

	return <FormikGroup inputs={inputs} />;
};

const PatternForm = ({ submitHandler, formResetRefBtn, onCloseHandler }) => {
	const addPatternRefBtn = useRef();

	const onSubmit = (values, { setSubmitting }) => {
		setSubmitting(false);
		submitHandler?.(values);
	};

	return (
		<div className="pattern-form-container col-md-5 offset-md-3">
			<Formik initialValues={initFormValues()} onSubmit={onSubmit}>
				{(formik) => {
					return (
						<Form
							onChange={(event) => {
								if (event.target.name === 'pattern') {
									const { patternSeparator } = formik.values;
									let patternVal = event.target.value;
									// patternVal = patternVal.replace(' ', patternSeparator);
									patternVal = stringReplacer(patternVal, ' ', patternSeparator);
									formik.setFieldValue('pattern', patternVal);
								}
							}}
							onKeyUp={(keyEvent) => {
								if (
									keyEvent.target.name === 'pattern' &&
									(keyEvent.charCode || keyEvent.keyCode) === 13
								) {
									addPatternRefBtn.current.click();
								}
							}}
							onKeyDown={(keyEvent) => {
								if (
									keyEvent.target.name === 'pattern' &&
									(keyEvent.charCode || keyEvent.keyCode) === 13
								) {
									keyEvent.preventDefault();
								}
							}}
						>
							{formControls({ addPatternRefBtn, formik })}
							<div className="d-flex justify-content-end mt-3 form-btn-container">
								<button
									ref={formResetRefBtn}
									type="button"
									className="grey-button roboto-b-16 bg-transparent ml-2"
									onClick={formik.resetForm}
								>
									<img
										src={resetIcon}
										className="mr-1"
										alt={commonLabels.reset}
									/>
									{commonLabels.reset}
								</button>
								<button
									type="button"
									className="btnCancel roboto-b-16 ml-2"
									onClick={() => onCloseHandler()}
								>
									{commonLabels.cancel}
								</button>
								<button
									type="submit"
									disabled={
										!formik.isValid ||
										formik.isSubmitting ||
										formik.values.patterns?.length === 0
									}
									className="btnNxtPrev roboto-b-16 ml-2"
								>
									{commonLabels.submit}
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

PatternForm.propTypes = {
	onSubmit: PropTypes.func,
	onCloseHandler: PropTypes.func,
	formResetRefBtn: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	])
};

export default PatternForm;
