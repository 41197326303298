import React from 'react';
import { Modal } from 'react-bootstrap';
import Input from '../Components/Input/Input';
import * as RestSvc from '../libraries/offer-services';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import * as urlTypes from '../libraries/offer-service-urls';
import { withRouter } from 'react-router-dom';

import Toaster from '../Components/Toaster/Toaster';
import appLabels from '../utils/appLabels';

const {
	common: commonLabels,
	page: { changePassword: pageLabels }
} = appLabels;

class ChangePasswordModal extends React.Component {
	initialState = {
		changePwdForm: {
			username: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: pageLabels.username.placeholder,
					labelname: pageLabels.username.label
				},
				value: this.props.operatorid,
				validation: {
					required: true
				},
				attrValidation: {
					//   readonly:''
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			password: {
				elementType: 'input',
				elementConfig: {
					type: 'password',
					placeholder: pageLabels.oldPassword.placeholder,
					labelname: pageLabels.oldPassword.label
				},
				value: '',
				validation: {
					required: true
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			NewPassword: {
				elementType: 'input',
				elementConfig: {
					type: 'password',
					placeholder: pageLabels.newPassword.placeholder,
					labelname: pageLabels.newPassword.label
				},
				value: '',
				validation: {
					required: true
				},
				validationMessage: '',
				valid: false,
				touched: false
			},
			ConfirmPassword: {
				elementType: 'input',
				elementConfig: {
					type: 'password',
					placeholder: pageLabels.confirmPassword.placeholder,
					labelname: pageLabels.confirmPassword.label
				},
				value: '',
				validation: {
					required: true
				},
				validationMessage: '',
				valid: false,
				touched: false
			}
		},
		formIsValid: false
	};

	state = this.initialState;

	handleFormReset = () => {
		this.setState(() => this.initialState);
	};

	getToken = (data) => {
		const dataToSend = {
			offersLoginRequest: {
				userID: data.userID,
				password: data.password
			}
		};

		return RestSvc.postData(urlTypes.user_maintenance.performLogin, dataToSend)
			.then((response) => {
				const result = response.data.offersLoginResponse;
				let token = null;
				if (
					result.authenticationDetails.isCredentialValid ||
					result.authenticationDetails.errorCode === 'ERR_SC_03'
				) {
					token = result.authenticationDetails.token?.accessToken;
				}
				if (!token) {
					Toaster({ message: result.authenticationDetails.errorMessage });
				}
				return token;
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	onChangePwdSubmit = async (event) => {
		event.preventDefault();
		let headers = {};
		const {
			changePwdForm: { username, password, NewPassword, ConfirmPassword }
		} = this.state;

		const dataToSend = {
			changePasswordRequest: {
				userID: username.value,
				password: btoa(password.value),
				newPassword: btoa(NewPassword.value),
				confirmPassword: btoa(ConfirmPassword.value)
			}
		};

		this.props.showLoadingButton(true);

		if (!this.props.operatorid) {
			const token = await this.getToken(dataToSend.changePasswordRequest);

			if (!token) {
				this.props.showLoadingButton(false);
				return;
			}

			headers = {
				headers: {
					Authorization: token
				}
			};
		}

		RestSvc.postData(urlTypes.user_maintenance.changePwd, dataToSend, null, headers)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.changeCredentialDetails;
				if (result.isCredentialChanged) {
					Toaster({ message: pageLabels.passwordSussesMsg });
					this.setState({
						formIsValid: false
					});
					this.props.onHide();
					this.props.navigateLogin();
				} else {
					Toaster({ message: result.errorMessage });
					this.setState((prevState) => {
						let previousState = { ...prevState };
						previousState.changePwdForm.password.value = '';
						previousState.changePwdForm.NewPassword.value = '';
						previousState.changePwdForm.ConfirmPassword.value = '';
						previousState.changePwdForm.password.valid = false;
						previousState.changePwdForm.NewPassword.valid = false;
						previousState.changePwdForm.ConfirmPassword.valid = false;
						previousState.changePwdForm.password.touched = false;
						previousState.changePwdForm.NewPassword.touched = false;
						previousState.changePwdForm.ConfirmPassword.touched = false;
						previousState.formIsValid = false;
						return previousState;
					});
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	inputChangedHandler = (event, isValidObj, inputIdentifier) => {
		const updatedchangePwdForm = { ...this.state.changePwdForm };
		const updatedFormElement = { ...updatedchangePwdForm[inputIdentifier] };
		updatedFormElement.value = event.target.value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;
		updatedchangePwdForm[inputIdentifier] = updatedFormElement;

		let formIsValid = true;
		for (let inputIdentifier in updatedchangePwdForm) {
			if (inputIdentifier !== this.props.userName)
				formIsValid = updatedchangePwdForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ changePwdForm: updatedchangePwdForm, formIsValid: formIsValid });
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.changePwdForm) {
			formElementsArray.push({
				id: key,
				config: this.state.changePwdForm[key]
			});
		}
		return (
			<div>
				<Modal
					show={this.props.show}
					onHide={this.props.onHide}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Body>
						<div className="m-0 row">
							<div className="col-sm-12">
								<div className="d-flex">
									<h5 style={{ flex: '1' }}>{pageLabels.passwordHeading}</h5>
									<span className="xCloseButtonSpan">
										<button
											className="xCloseButton"
											onClick={() => {
												this.props.onHide();
											}}
										>
											X
										</button>
									</span>
								</div>
								<p className="passwordMessage">{pageLabels.passwordSubTxt}</p>
								<form
									onReset={this.handleFormReset}
									onSubmit={this.onChangePwdSubmit}
									className="col-sm-9 pl-0"
								>
									<div
										className={
											this.props.operatorid === '' ? '' : 'disabledEvent'
										}
										key={formElementsArray[0].id}
									>
										<Input
											key={formElementsArray[0].id}
											elementType={formElementsArray[0].config.elementType}
											elementConfig={
												formElementsArray[0].config.elementConfig
											}
											value={formElementsArray[0].config.value}
											invalid={!formElementsArray[0].config.valid}
											shouldValidate={formElementsArray[0].config.validation}
											touched={formElementsArray[0].config.touched}
											attrValidation={
												formElementsArray[0].config.attrValidation
											}
											changed={(ev, isValidObj) =>
												this.inputChangedHandler(
													ev,
													isValidObj,
													formElementsArray[0].id
												)
											}
										/>
										{/* <p className="errorMessage">{formElementsArray[0].config.validationMessage}</p> */}
									</div>
									<div key={formElementsArray[1].id}>
										<Input
											key={formElementsArray[1].id}
											elementType={formElementsArray[1].config.elementType}
											elementConfig={
												formElementsArray[1].config.elementConfig
											}
											value={formElementsArray[1].config.value}
											invalid={!formElementsArray[1].config.valid}
											shouldValidate={formElementsArray[1].config.validation}
											touched={formElementsArray[1].config.touched}
											togglePassword
											changed={(ev, isValidObj) =>
												this.inputChangedHandler(
													ev,
													isValidObj,
													formElementsArray[1].id
												)
											}
										/>
										{/* <p className="errorMessage">{formElementsArray[1].config.validationMessage}</p> */}
									</div>
									<div key={formElementsArray[2].id}>
										<Input
											key={formElementsArray[2].id}
											elementType={formElementsArray[2].config.elementType}
											elementConfig={
												formElementsArray[2].config.elementConfig
											}
											value={formElementsArray[2].config.value}
											invalid={!formElementsArray[2].config.valid}
											shouldValidate={formElementsArray[2].config.validation}
											touched={formElementsArray[2].config.touched}
											togglePassword
											changed={(ev, isValidObj) =>
												this.inputChangedHandler(
													ev,
													isValidObj,
													formElementsArray[2].id
												)
											}
										/>
										{/* <p className="errorMessage">{formElementsArray[2].config.validationMessage}</p> */}
									</div>
									<div key={formElementsArray[3].id}>
										<Input
											key={formElementsArray[3].id}
											elementType={formElementsArray[3].config.elementType}
											elementConfig={
												formElementsArray[3].config.elementConfig
											}
											value={formElementsArray[3].config.value}
											invalid={!formElementsArray[3].config.valid}
											shouldValidate={formElementsArray[3].config.validation}
											touched={formElementsArray[3].config.touched}
											togglePassword
											changed={(ev, isValidObj) =>
												this.inputChangedHandler(
													ev,
													isValidObj,
													formElementsArray[3].id
												)
											}
										/>
										{/* <p className="errorMessage">{formElementsArray[3].config.validationMessage}</p> */}
									</div>
									<div className="buttonclass text-left pt-3">
										<button
											className="btnCancel roboto-b-16"
											onClick={() => {
												this.props.onHide();
											}}
										>
											{commonLabels.cancel}
										</button>
										<button
											type="submit"
											disabled={!this.state.formIsValid}
											className="btnNxtPrev ml-2 roboto-b-16"
										>
											{commonLabels.submit}
										</button>
									</div>
								</form>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val })
	};
};

export default connect(null, mapDispatchToProps)(withRouter(ChangePasswordModal));
