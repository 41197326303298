import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { MultiSelect } from 'react-multi-select-component';

const CustomReactMultiSelectComponent = ({
	label,
	required,
	prepend,
	smallText,
	type,
	...props
}) => {
	const [field, meta] = useField(props);
	const { setFieldValue } = useFormikContext();

	const [errMsg] = useState('');
	const [totalAggregators, setTotalAggregators] = useState([]);
	const [selectedAggregators, setSelectedAggregators] = useState([]);

	useEffect(() => {
		//  let aggregators=[ { aggregatorName: "PayU", aggregatorId: "1" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "2" },
		//  { aggregatorName: "PayU", aggregatorId: "3" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "4" },
		//  { aggregatorName: "PayU", aggregatorId: "5" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "6" },
		//  { aggregatorName: "PayU", aggregatorId: "7" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "8" },
		//  { aggregatorName: "PayU", aggregatorId: "9" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "10" },
		//  { aggregatorName: "PayU", aggregatorId: "11" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "12" },
		//  { aggregatorName: "PayU", aggregatorId: "13" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "14" },
		//  { aggregatorName: "PayU", aggregatorId: "15" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "16" },
		//  { aggregatorName: "PayU", aggregatorId: "17" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "18" },
		//  { aggregatorName: "PayU", aggregatorId: "19" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "20" },
		//  { aggregatorName: "PayU", aggregatorId: "21" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "22" },
		//  { aggregatorName: "PayU", aggregatorId: "23" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "2" },
		//  { aggregatorName: "PayU", aggregatorId: "1" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "24" },
		//  { aggregatorName: "PayU", aggregatorId: "25" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "26" },
		//  { aggregatorName: "PayU", aggregatorId: "27" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "28" },
		//  { aggregatorName: "PayU", aggregatorId: "291" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "30" },
		//  { aggregatorName: "PayU", aggregatorId: "31" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "32" },
		//  { aggregatorName: "PayU", aggregatorId: "33" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "37" },
		//  { aggregatorName: "PayU", aggregatorId: "35" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "36" },
		//  { aggregatorName: "PayU", aggregatorId: "37" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "38" },
		//  { aggregatorName: "PayU", aggregatorId: "1" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "2" },
		//  { aggregatorName: "PayU", aggregatorId: "1" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "2" },
		//  { aggregatorName: "PayU", aggregatorId: "35" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "36" },
		//  { aggregatorName: "PayU", aggregatorId: "37" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "38" },
		//  { aggregatorName: "PayU", aggregatorId: "1" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "2" },
		//  { aggregatorName: "PayU", aggregatorId: "1" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "2" },
		//  { aggregatorName: "PayU", aggregatorId: "35" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "36" },
		//  { aggregatorName: "PayU", aggregatorId: "37" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "38" },
		//  { aggregatorName: "PayU", aggregatorId: "1" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "2" },
		//  { aggregatorName: "PayU", aggregatorId: "1" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "2" },
		//  { aggregatorName: "PayU", aggregatorId: "35" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "36" },
		//  { aggregatorName: "PayU", aggregatorId: "37" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "38" },
		//  { aggregatorName: "PayU", aggregatorId: "1" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "2" },
		//  { aggregatorName: "PayU", aggregatorId: "1" },
		//  { aggregatorName: "Bill Desk", aggregatorId: "2" }]
		let temp = [];
		props.aggregatorOps.map((item) => {
			temp.push({ label: item.configValue, value: item.configId });
		});
		setTotalAggregators(temp);
		let tempArr = [];
		if (field.value?.length > 0) {
			field.value.map((item) => {
				tempArr.push({ label: item.aggregatorName, value: item.aggregatorId.toString() });
			});
		}
		setSelectedAggregators(tempArr);
	}, []);

	return (
		<div className="container">
			<div className="rowContainer">
				<div className="customLabelContainer">
					<label htmlFor="forprogramID" className="col	col-form-label">
						{label}

						{required ? <span className="requiredRed">*</span> : null}
					</label>
				</div>
				<div className={'customInputContainer'}>
					<div className="row">
						<MultiSelect
							options={totalAggregators}
							value={selectedAggregators}
							onChange={(slctd) => {
								setSelectedAggregators(slctd);
								let temp = [];
								slctd.map((item) => {
									temp.push({
										aggregatorName: item.label,
										aggregatorId: item.value
									});
								});
								setFieldValue(field.name, temp);
							}}
							labelledBy={'Select...'}
							disableSearch={false}
							hasSelectAll={false}
							overrideStrings={{
								search: 'Search Aggregator'
							}}
							valueRenderer={(selected, _options) => {
								return selected.length ? (
									<div className="d-flex flex-wrap">
										{selected.slice(0, 2).map(({ label }, ind) => (
											<span className="labelHeading" key={ind}>
												{label}
											</span>
										))}
										<span className="mr-1">...</span>
										<span className="labelHeadingCount">{selected.length}</span>
									</div>
								) : (
									'Select'
								);
							}}
						/>

						{smallText && (
							<small id="emailHelp" className="form-text pl-2">
								<div className="tooltipimg">
									<button className="tooltip-button btn br-50" type="button">
										<span className="tooltip-icon">?</span>
									</button>
									<span className="tooltiptext"> {smallText}</span>
								</div>
							</small>
						)}
						{meta.touched && meta.error ? (
							<div className="invalid-feedback d-block">{meta.error}</div>
						) : null}
						<div className="invalid-feedback d-block">{errMsg}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomReactMultiSelectComponent;
