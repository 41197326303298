import React, { useEffect } from 'react';

import { useField, useFormikContext } from 'formik';
import CustomInputText from '../customfields/CustomInputText';
import CustomSelect from '../customfields/CustomSelect';

const groupCombinationCode = {
	groupTriggerCombinationCode: [
		'PACKAGE_INTERVAL_QTY_THRESHOLD',
		'PACKAGE_AMOUNT_THRESHOLD',
		'PACKAGE_RANGE_QTY_THRESHOLD',
		'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD',
		'HIERARCHY_PACKAGE_QTY_THRESHOLD',
		'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
		'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
	],
	groupRewardCombinationCode: [
		'PACKAGE_INTERVAL_QTY_THRESHOLD',
		'PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
		'PACKAGE_RANGE_QTY_THRESHOLD',
		'PACKAGE_RANGE_AMOUNT_THRESHOLD',
		'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD',
		'HIERARCHY_PACKAGE_QTY_THRESHOLD',
		'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
		'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
	]
};

const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	triggerCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Specifies the combination code to be used between the trigger items for a promotion',
	thresholdQuantity: 'Group Threshold Quantity',
	thresholdAmount: 'Group Threshold Amount',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Specifies the combination code to be used between the trigger items for a promotion',
	rewardQuantity: 'Group Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	quantityLimit: 'Quantity Limit',
	groupMaximumThresholdAmount: 'Group Maximum Threshold Amount',
	groupMaximumThresholdQuantity: 'Group Maximum Threshold Quantity'
};

const rewardCombinationDropdown = {
	promotionTypeOr: [
		{
			value: 'OR',
			name: 'OR'
		}
	],
	promotionTypeOrAndEqual: [
		{
			value: 'OR',
			name: 'OR'
		},
		{
			value: 'AND',
			name: 'AND'
		},
		{
			value: 'EQUAL',
			name: 'EQUAL'
		}
	],
	promotionTypeOrAnd: [
		{
			value: 'OR',
			name: 'OR'
		},
		{
			value: 'AND',
			name: 'AND'
		}
	],
	operator: [
		{
			value: '',
			name: 'Select one'
		},
		{
			value: '<',
			name: '<'
		},
		{
			value: '>',
			name: '>'
		},
		{
			value: '<=',
			name: '<='
		},
		{
			value: '>=',
			name: '>='
		},
		{
			value: '=',
			name: '='
		},
		{
			value: 'BETWEEN',
			name: 'BETWEEN'
		}
	]
};

const getGroupCombinationCode = ({ formikContext, field, thresholdTypeCode }) => {
	const { initialValues, values } = formikContext;
	const initVal = initialValues.packageMultibuyEligibility?.[field];
	const val = values.packageMultibuyEligibility?.[field];
	let finalVal = initVal ?? 'OR';

	if (groupCombinationCode?.[field].includes(thresholdTypeCode)) {
		/* set val OR/AND */
		finalVal = val ?? 'OR';
	} else {
		/* set value OR */
		finalVal = 'OR';
	}

	return finalVal;
};

const getThresholdDropdown = (thresholdTypeCodeOptions) => {
	return thresholdTypeCodeOptions.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const ConditionalRederingPKG = (props) => {
	const { value } = props;
	//PackageMultibuyEligibility[index].mtmbthresholdTypeCode
	const [field] = useField(props);
	const fieldCR = field.value.packageMultibuyEligibility;
	const formikContext = useFormikContext();

	useEffect(() => {
		// if (!fieldCR.groupTriggerCombinationCode) {
		// 	fieldCR.groupTriggerCombinationCode = 'OR';
		// }
		// if (!fieldCR.groupRewardCombinationCode) {
		// 	fieldCR.groupRewardCombinationCode = 'OR';
		// }
		fieldCR.groupTriggerCombinationCode = getGroupCombinationCode({
			formikContext,
			field: 'groupTriggerCombinationCode',
			thresholdTypeCode: value
		});
		fieldCR.groupRewardCombinationCode = getGroupCombinationCode({
			formikContext,
			field: 'groupRewardCombinationCode',
			thresholdTypeCode: value
		});

		if (!fieldCR.groupRewardQuantity) {
			fieldCR.groupRewardQuantity = 1;
		}

		if (
			value == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			value == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
			value == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			value == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			fieldCR.groupRewardQuantity = undefined;
			fieldCR.groupMaximumThresholdQuantity = undefined;
			fieldCR.groupMaximumThresholdAmount = undefined;
			fieldCR.quantityLimit = undefined;
			fieldCR.groupThresholdQuantity = undefined;
			fieldCR.groupThresholdAmount = undefined;
		}

		if (
			value == 'PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			value == 'PACKAGE_QTY_THRESHOLD' ||
			value == 'PACKAGE_RANGE_QTY_THRESHOLD' ||
			value == 'PACKAGE_LIMIT_QTY_THRESHOLD'
		) {
			if (!fieldCR.groupThresholdQuantity) {
				fieldCR.groupThresholdQuantity = 1;
			}
			fieldCR.groupThresholdAmount = undefined;
			if (value != 'PACKAGE_LIMIT_QTY_THRESHOLD') {
				fieldCR.quantityLimit = undefined;
			}
			if (value != 'PACKAGE_RANGE_QTY_THRESHOLD') {
				fieldCR.groupMaximumThresholdQuantity = undefined;
			}
			fieldCR.groupMaximumThresholdAmount = undefined;
		}
		if (
			value == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			value == 'PACKAGE_AMOUNT_THRESHOLD' ||
			value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD'
		) {
			if (!fieldCR.groupThresholdAmount) {
				fieldCR.groupThresholdAmount = 0.01;
			}
			fieldCR.groupThresholdQuantity = undefined;
			fieldCR.quantityLimit = undefined;
			if (value != 'PACKAGE_RANGE_AMOUNT_THRESHOLD') {
				fieldCR.groupMaximumThresholdAmount = undefined;
			}
			fieldCR.groupMaximumThresholdQuantity = undefined;
		}

		if (value == 'PACKAGE_LIMIT_QTY_THRESHOLD') {
			if (!fieldCR.quantityLimit) {
				fieldCR.quantityLimit = 1;
			}
		}
		if (value == 'PACKAGE_RANGE_QTY_THRESHOLD') {
			if (!fieldCR.groupMaximumThresholdQuantity) {
				fieldCR.groupMaximumThresholdQuantity = 999999;
			}
		}
		if (value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD') {
			if (!fieldCR.groupMaximumThresholdAmount) {
				fieldCR.groupMaximumThresholdAmount = 999999.99;
			}
		}
		if (
			value == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			value == 'PACKAGE_AMOUNT_THRESHOLD' ||
			value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD' ||
			value == 'PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			value == 'PACKAGE_QTY_THRESHOLD' ||
			value == 'PACKAGE_RANGE_QTY_THRESHOLD' ||
			value == 'PACKAGE_LIMIT_QTY_THRESHOLD' ||
			value == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			value == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
			value == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			value == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			if (!fieldCR.group) {
				fieldCR.group = [
					{
						multibuyProduct: [],
						trigger: true,
						getReward: true
					}
				];
			}
		}
	}, [value]);

	//newly added
	useEffect(() => {
		if (
			fieldCR.triggerOperator == '<' ||
			fieldCR.triggerOperator == '>' ||
			fieldCR.triggerOperator == '<=' ||
			fieldCR.triggerOperator == '>=' ||
			fieldCR.triggerOperator == '='
		) {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
		} else if (fieldCR.triggerOperator == 'BETWEEN') {
			fieldCR.triggerOperatorValue = undefined;
		} else {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
			fieldCR.triggerOperatorValue = undefined;
		}
	}, [fieldCR.triggerOperator]);

	useEffect(() => {
		if (
			fieldCR.rewardOperator == '<' ||
			fieldCR.rewardOperator == '>' ||
			fieldCR.rewardOperator == '<=' ||
			fieldCR.rewardOperator == '>=' ||
			fieldCR.rewardOperator == '='
		) {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
		} else if (fieldCR.rewardOperator == 'BETWEEN') {
			fieldCR.rewardOperatorValue = undefined;
		} else {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
			fieldCR.rewardOperatorValue = undefined;
		}
	}, [fieldCR.rewardOperator]);

	return (
		<div className="purchaseContainer">
			<div className="purchaseRow">
				<div className="font-weight-bold">Purchase Conditions</div>
				{/* <FormController
        control="select"
        lable={localConst.triggerCombinationCode}
        name={`packageMultibuyEligibility.${index}.groupTriggerCombinationCode`}
        required={true}
        options={
          value == "PACKAGE_INTERVAL_QTY_THRESHOLD" ||
          value == "PACKAGE_AMOUNT_THRESHOLD" ||
          value == "PACKAGE_RANGE_QTY_THRESHOLD" ||
          value == "HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD"
            ? rewardCombinationDropdown.promotionTypeOrAnd
            : rewardCombinationDropdown.promotionTypeOr
        }
        smallText={localConst.subTriggerCombinationCode}
        className="customDropdown inlinePadding"
      /> */}
				<CustomSelect
					name={`packageMultibuyEligibility.groupTriggerCombinationCode`}
					required={true}
					label={localConst.triggerCombinationCode}
					options={
						[
							'PACKAGE_INTERVAL_QTY_THRESHOLD',
							'PACKAGE_AMOUNT_THRESHOLD',
							'PACKAGE_RANGE_QTY_THRESHOLD',
							'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD',
							'HIERARCHY_PACKAGE_QTY_THRESHOLD',
							'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
							'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
						].includes(value)
							? getThresholdDropdown(rewardCombinationDropdown.promotionTypeOrAnd)
							: getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)
					}
					smallText={localConst.subTriggerCombinationCode}
				/>

				{value == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ? undefined : (
					<CustomInputText
						name={
							value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD' ||
							value == 'PACKAGE_AMOUNT_THRESHOLD' ||
							value == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD'
								? `packageMultibuyEligibility.groupThresholdAmount`
								: `packageMultibuyEligibility.groupThresholdQuantity`
						}
						required={true}
						label={
							value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD' ||
							value == 'PACKAGE_AMOUNT_THRESHOLD' ||
							value == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD'
								? localConst.thresholdAmount
								: localConst.thresholdQuantity
						}
						smallText=""
						disabled={false}
					/>
				)}
				{value == 'PACKAGE_RANGE_QTY_THRESHOLD' ||
				value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD' ? (
					value == 'PACKAGE_RANGE_QTY_THRESHOLD' ? (
						<CustomInputText
							name={`packageMultibuyEligibility.groupMaximumThresholdQuantity`}
							required={true}
							label={localConst.groupMaximumThresholdQuantity}
							smallText=""
							disabled={false}
						/>
					) : (
						<CustomInputText
							name={`packageMultibuyEligibility.groupMaximumThresholdAmount`}
							required={true}
							label={localConst.groupMaximumThresholdAmount}
							smallText=""
							disabled={false}
						/>
					)
				) : undefined}
				{value == 'PACKAGE_LIMIT_QTY_THRESHOLD' ? (
					<CustomInputText
						name={`packageMultibuyEligibility.quantityLimit`}
						required={true}
						label={localConst.quantityLimit}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				<CustomSelect
					name="packageMultibuyEligibility.triggerOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{fieldCR.triggerOperator == '<' ||
				fieldCR.triggerOperator == '>' ||
				fieldCR.triggerOperator == '<=' ||
				fieldCR.triggerOperator == '>=' ||
				fieldCR.triggerOperator == '=' ? (
					<CustomInputText
						name="packageMultibuyEligibility.triggerOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{fieldCR.triggerOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="packageMultibuyEligibility.triggerOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="packageMultibuyEligibility.triggerOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>

			<div className="purchaseRow">
				<div className="font-weight-bold">Reward Conditions</div>
				{/* <FormController
        control="select"
        lable={localConst.rewardCombinationCode}
        name={`packageMultibuyEligibility.groupRewardCombinationCode`}
        required={true}
        options={
          value == "PACKAGE_INTERVAL_QTY_THRESHOLD" ||
          value == "PACKAGE_INTERVAL_AMOUNT_THRESHOLD" ||
          value == "PACKAGE_RANGE_QTY_THRESHOLD" ||
          value == "PACKAGE_RANGE_AMOUNT_THRESHOLD" ||
          value == "HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD" ||
          value == "HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD"
            ? rewardCombinationDropdown.promotionTypeOrAnd
            : rewardCombinationDropdown.promotionTypeOr
        }
        smallText={localConst.subRewardCombinationCode}
        className="customDropdown inlinePadding"
      /> */}
				<CustomSelect
					name={`packageMultibuyEligibility.groupRewardCombinationCode`}
					required={true}
					label={localConst.rewardCombinationCode}
					options={
						[
							'PACKAGE_INTERVAL_QTY_THRESHOLD',
							'PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
							'PACKAGE_RANGE_QTY_THRESHOLD',
							'PACKAGE_RANGE_AMOUNT_THRESHOLD',
							'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD',
							'HIERARCHY_PACKAGE_QTY_THRESHOLD',
							'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
							'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
						].includes(value)
							? getThresholdDropdown(rewardCombinationDropdown.promotionTypeOrAnd)
							: getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)
					}
					smallText={localConst.subRewardCombinationCode}
				/>
				{value == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ? undefined : (
					<CustomInputText
						name={`packageMultibuyEligibility.groupRewardQuantity`}
						required={false}
						label={localConst.rewardQuantity}
						smallText=""
						disabled={false}
					/>
				)}
				<CustomSelect
					name="packageMultibuyEligibility.rewardOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{fieldCR.rewardOperator == '<' ||
				fieldCR.rewardOperator == '>' ||
				fieldCR.rewardOperator == '<=' ||
				fieldCR.rewardOperator == '>=' ||
				fieldCR.rewardOperator == '=' ? (
					<CustomInputText
						name="packageMultibuyEligibility.rewardOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{fieldCR.rewardOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="packageMultibuyEligibility.rewardOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="packageMultibuyEligibility.rewardOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>
		</div>
	);
};
export default ConditionalRederingPKG;
