import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import filterFactory from 'react-bootstrap-table2-filter';
import { isObjectEmpty } from '../../../utils/helperFunction';
// import { productData } from './productData';
import { InformationModal } from '../../../MainContent/InformationModal';
import { modifiedValueFormatter } from './Audit/auditHelperFunction';
import ToolkitProvider from '../../../utils/reactBootstrapTable2Toolkit';

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
	<div className="btn-group" role="group">
		{options.map((option) => {
			const isSelect = currSizePerPage === `${option.page}`;
			return (
				<button
					key={option.text}
					type="button"
					onClick={() => onSizePerPageChange(option.page)}
					className={`btn ${isSelect ? 'btn-primary' : 'btn-secondary'}`}
				>
					{option.text}
				</button>
			);
		})}
	</div>
);

// eslint-disable-next-line no-unused-vars
let options = {};
const decimalRegex = /^\d+(\.\d{1,2})?$/;
const integerRegex = /^[0-9]+$/;

const eligibilityType = {
	ScaleTransaction: 'ScaleTransaction'
};

const ProductTableBoostrap = (props) => {
	// state and props
	const [products, setProducts] = useState([]);
	const [columns, setColumns] = useState('');
	const [selectedProducts, setSelectedProducts] = useState([]);
	// const { SearchBar } = Search;
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg] = useState('');
	const [informationTitle] = useState('Information');
	const [field] = useField(props);
	// const [prevPriceModificationMethodCodeValue, setPrevPriceModificationMethodCodeValue] =
	// 	useState('');
	//new
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;

	const { benefitType, thresholdTypeCode, priceModificationMethodCode } =
		field.value?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity || {};
	const { applicableOn } = isAuditFlw
		? props.applicableOn
		: field.value?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
				.paymentEligibility || {};

	const thresholdTypeCodeDropdownValues = {
		type1: 'SCALE_BASKET_QTY_THRESHOLD',
		type2: 'SCALE_BASKET_LIMIT_QTY_THRESHOLD',
		type3: 'SCALE_BASKET_VALUE_THRESHOLD',
		type4: 'SCALE_BASKET_LIMIT_QTY_AND_AMOUNT_THRESHOLD',
		type5: 'SCALE_BASKET_QTY_AND_VALUE_THRESHOLD'
	};
	const benefitTypeDropdownValues = {
		type1: 'INSTANT',
		type2: 'CASHBACK',
		type3: 'LOYALTY_POINTS',
		type4: 'FREE_TEXT'
	};
	const priceModificationMethodCodeDropdownValues = {
		type1: 'PERCENTAGE_OFF',
		type2: 'APPORTIONED_PERCENTAGE_OFF',
		type3: 'AMOUNT_OFF',
		type4: 'APPORTIONED_AMOUNT_OFF',
		type5: 'MARKDOWN',
		type6: 'KIT_MARKDOWN'
	};
	const priceModificationMethodCodeDropdownValuesForLoyalty = {
		type1: 'LOYALTY_ADDITIONAL_POINTS',
		type2: 'LOYALTY_POINTS_MULTIPLIER',
		type3: 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS',
		type4: 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE',
		type5: 'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS',
		type6: 'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE'
	};

	function headerFormatter(column, colIndex) {
		let a = column.text;
		if (a != undefined) {
			a = a.split('$');
			return (
				<div>
					<div>
						<b> {a[0]} </b>
					</div>
					<div>
						<b> {a[1]} </b>
					</div>
				</div>
			);
		}
	}

	const columnsScaleTransaction = [
		{
			dataField: 'id',
			text: 'ID',
			hidden: true
			// sort: true,
		},
		thresholdTypeCode != thresholdTypeCodeDropdownValues.type3
			? {
					dataField: 'thresholdQuantity',
					text: 'Threshold Quantity $ Range (1-9999999)',
					headerFormatter: headerFormatter,
					headerAlign: 'center',
					type: 'number',
					// sort: true,
					validator: (newValue) => {
						if (newValue == '' || newValue == undefined) {
							return {
								valid: false,
								message: 'Value should be Entered'
							};
						}
						if (!integerRegex.test(newValue)) {
							return {
								valid: false,
								message: 'Decimal places not allowed.'
							};
						}
						if (isNaN(newValue)) {
							return {
								valid: false,
								message: 'Value should be Numeric'
							};
						}
						if (newValue > 9999999 || newValue < 1) {
							return {
								valid: false,
								message: 'Purchased quantity must be between 1 and 9999999'
							};
						}
						return true;
					},
					formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
			  }
			: {
					dataField: 'thresholdQuantity',
					text: 'Threshold Quantity$ ',
					headerFormatter: headerFormatter,
					headerAlign: 'center',
					hidden: true,
					type: 'number'
			  },

		thresholdTypeCode == thresholdTypeCodeDropdownValues.type4 ||
		thresholdTypeCode == thresholdTypeCodeDropdownValues.type5 ||
		thresholdTypeCode == thresholdTypeCodeDropdownValues.type3
			? {
					dataField: 'thresholdAmount',
					text: 'Threshold Amount $ Range (0.01-9999999.99)',
					headerFormatter: headerFormatter,
					headerAlign: 'center',
					type: 'number',
					//  sort: true,
					validator: (newValue) => {
						if (newValue == '' || newValue == undefined) {
							return {
								valid: false,
								message: 'Value should be Entered'
							};
						}
						if (isNaN(newValue)) {
							return {
								valid: false,
								message: 'Value should be Numeric'
							};
						} else if (!decimalRegex.test(newValue)) {
							return {
								valid: false,
								message: 'Only two decimal places allowed.'
							};
						}
						if (newValue > 9999999.99 || newValue < 0.01) {
							return {
								valid: false,
								message: 'Threshold Amount should be between 0.01 to 9999999.99'
							};
						}
						return true;
					},
					formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
			  }
			: {
					dataField: 'thresholdAmount',
					text: 'Threshold Amount',
					hidden: true,
					type: 'number'
			  },
		{
			dataField:
				benefitType == benefitTypeDropdownValues.type1
					? priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValues.type1 ||
					  priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type2
						? 'benefit.priceModificationPercent'
						: priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type3 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type4
						? 'benefit.priceModificationAmount'
						: 'benefit.newPriceAmount'
					: benefitType == benefitTypeDropdownValues.type2
					? priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValues.type1 ||
					  priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type2
						? 'benefit.priceModificationPercent'
						: priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type3 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type4
						? 'benefit.priceModificationAmount'
						: undefined
					: benefitType == benefitTypeDropdownValues.type3
					? priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type1 ||
					  priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type3 ||
					  priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type4
						? 'benefit.loyalty.loyaltyAdditionalPoint'
						: priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type2 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type5 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type6
						? 'benefit.loyalty.loyaltyMultiplier'
						: undefined
					: benefitType == benefitTypeDropdownValues.type4
					? 'benefit.freeTextMessage'
					: undefined,
			text:
				benefitType == benefitTypeDropdownValues.type1
					? priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type1
						? 'Percentage Off $ Range (0.01-100.00)'
						: priceModificationMethodCode ==
						  priceModificationMethodCodeDropdownValues.type2
						? 'Apportioned Percentage Off $ Range (0.01-100.00)'
						: priceModificationMethodCode ==
						  priceModificationMethodCodeDropdownValues.type3
						? 'Amount Off $ Range (0.01-9999999.99)'
						: priceModificationMethodCode ==
						  priceModificationMethodCodeDropdownValues.type4
						? 'Apportioned Amount Off $ Range (0.01-9999999.99)'
						: 'New Price Amount $ Range (0.01-9999999.99)'
					: benefitType == benefitTypeDropdownValues.type2
					? priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type1
						? 'Percentage Off $ Range (0.01-100.00)'
						: priceModificationMethodCode ==
						  priceModificationMethodCodeDropdownValues.type2
						? 'Apportioned Percentage Off $ Range (0.01-100.00)'
						: priceModificationMethodCode ==
						  priceModificationMethodCodeDropdownValues.type3
						? 'Amount Off $ Range (0.01-9999999.99)'
						: priceModificationMethodCode ==
						  priceModificationMethodCodeDropdownValues.type4
						? 'Apportioned Amount Off $ Range (0.01-9999999.99)'
						: undefined
					: benefitType == benefitTypeDropdownValues.type3
					? priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type1 ||
					  priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type3 ||
					  priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type4
						? 'Loyalty Additional Point $ Range (0.01-9999999.99)'
						: priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type2 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type5 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type6
						? 'Loyalty Multiplier $ Range (1-9999999)'
						: undefined
					: benefitType == benefitTypeDropdownValues.type4
					? 'Free Text Message $'
					: undefined,
			//sort: true,
			type: benefitType == benefitTypeDropdownValues.type4 ? 'string' : 'number',
			headerFormatter: headerFormatter,
			headerAlign: 'center',
			//add validation
			validator: (newValue) => {
				if (newValue == '' || newValue == undefined) {
					return {
						valid: false,
						message: 'Value should be Entered'
					};
				}

				if (!benefitTypeDropdownValues.type4) {
					if (isNaN(newValue)) {
						return {
							valid: false,
							message: 'Value should be Numeric'
						};
					}
				}

				/*     if (!benefitTypeDropdownValues.type4 ) {   
            if (
          priceModificationMethodCode !=
          priceModificationMethodCodeDropdownValuesForLoyalty.type2 ||
          priceModificationMethodCode !=
          priceModificationMethodCodeDropdownValuesForLoyalty.type5 ||
          priceModificationMethodCode !=
          priceModificationMethodCodeDropdownValuesForLoyalty.type6 ){
          if (!decimalRegex.test(newValue)) {
            return {
              valid: false,
              message: 'Only two decimal places allowed.'
            };
          }}
          } 

          if (!benefitTypeDropdownValues.type4 ||
            !benefitTypeDropdownValues.type1 ||  !benefitTypeDropdownValues.type2
          ) {     if (priceModificationMethodCode ==
            priceModificationMethodCodeDropdownValuesForLoyalty.type2 ||
            priceModificationMethodCode ==
            priceModificationMethodCodeDropdownValuesForLoyalty.type5 ||
            priceModificationMethodCode ==
            priceModificationMethodCodeDropdownValuesForLoyalty.type6 
            ){
            if (!integerRegex.test(newValue)) {
              return {
                valid: false,
                message: 'Decimal places not allowed.'
              };
            }}
            }  */

				if (benefitType == benefitTypeDropdownValues.type1) {
					if (
						(priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValues.type1 ||
							priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type2) &&
						(newValue > 100.0 || newValue < 0.01)
					) {
						return {
							valid: false,
							message: 'Price Modification Percent should be between 0.01 to 100'
						};
					} else if (
						(priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValues.type3 ||
							priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type4) &&
						(newValue > 9999999.99 || newValue < 0.01)
					) {
						return {
							valid: false,
							message:
								'Price Modification Amount should be between 0.01 to 9999999.99'
						};
					} else if (
						(priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValues.type5 ||
							priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type6) &&
						(newValue > 9999999.99 || newValue < 0.01)
					) {
						return {
							valid: false,
							message: 'New Price Amount should be between 0.01 to 9999999.99'
						};
					}
				}
				//cashback
				else if (benefitType == benefitTypeDropdownValues.type2) {
					if (
						(priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValues.type1 ||
							priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type2) &&
						(newValue > 100.0 || newValue < 0.01)
					) {
						return {
							valid: false,
							message: 'Price Modification Percent should be between 0.01 to 100'
						};
					} else if (
						(priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValues.type3 ||
							priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type4) &&
						(newValue > 9999999.99 || newValue < 0.01)
					) {
						return {
							valid: false,
							message:
								'Price Modification Amount should be between 0.01 to 9999999.99'
						};
					}
				}
				//cashback
				//cashback
				else if (benefitType == benefitTypeDropdownValues.type3) {
					if (
						(priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type1 ||
							priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type3 ||
							priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type4) &&
						(newValue > 9999999.99 || newValue < 0.01)
					) {
						return {
							valid: false,
							message: 'Loyalty Additional Point should be between 0.01 to 9999999.99'
						};
					} else if (
						(priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type2 ||
							priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type5 ||
							priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type6) &&
						(newValue > 9999999 || newValue < 1)
					) {
						return {
							valid: false,
							message: 'Loyalty Multiplier should be between 1 to 9999999'
						};
					}
				}
				//cashback
				return true;
			},
			formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
		},
		//paymentThresholdTypeCode == paymentThresholdCodeDropdownValues.type1

		(isAuditFlw
			? props.applicableOn.hasOwnProperty('applicableOn')
			: field.value?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.hasOwnProperty(
					'applicableOn'
			  )) && applicableOn != ''
			? {
					dataField: 'paymentThresholdAmount',
					text: 'Payment Threshold Amount $ Range (0.01-9999999.99)',
					headerFormatter: headerFormatter,
					headerAlign: 'center',
					type: 'number',
					// sort: true,
					validator: (newValue) => {
						if (isNaN(newValue)) {
							return {
								valid: false,
								message: 'Value should be Numeric'
							};
						}
						if (!decimalRegex.test(newValue)) {
							return {
								valid: false,
								message: 'Only two decimal places allowed.'
							};
						}
						if (newValue > 9999999.99 || newValue < 0.01) {
							return {
								valid: false,
								message:
									'Payment Threshold Amount should be between 0.01 to 9999999.99'
							};
						}
						return true;
					},
					formatter: (cell, row, rowIndex) => modifiedValueFormatter(cell, row, rowIndex)
			  }
			: {
					dataField: 'paymentThresholdAmount',
					text: 'Payment Threshold Amount',
					type: 'number',
					hidden: true
			  }
	];

	//useEffects
	useEffect(() => {
		const { eligibility } = props;
		if (eligibility === eligibilityType.ScaleTransaction) {
			//debugger
			setColumns(columnsScaleTransaction);
		}
		if (isAuditFlw) {
			setProducts(props.data);
		} else {
			if (props.data.length == 0) addProduct();
			else setProducts(props.data);
		}

		return () => {
			setProducts([]);
		};
	}, []);

	useEffect(() => {
		setColumns(columnsScaleTransaction);
		if (products.length !== 0) {
			let priceModificationMethodCodeValue =
				benefitType == benefitTypeDropdownValues.type1
					? priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValues.type1 ||
					  priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type2
						? { benefit: { priceModificationPercent: 0.01 } }
						: priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type3 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type4
						? { benefit: { priceModificationAmount: 0.01 } }
						: { benefit: { newPriceAmount: 0.01 } }
					: benefitType == benefitTypeDropdownValues.type2
					? priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValues.type1 ||
					  priceModificationMethodCode == priceModificationMethodCodeDropdownValues.type2
						? { benefit: { priceModificationPercent: 0.01 } }
						: priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type3 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type4
						? { benefit: { priceModificationAmount: 0.01 } }
						: undefined
					: benefitType == benefitTypeDropdownValues.type3
					? priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type1 ||
					  priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type3 ||
					  priceModificationMethodCode ==
							priceModificationMethodCodeDropdownValuesForLoyalty.type4
						? { benefit: { loyalty: { loyaltyAdditionalPoint: 0.01 } } }
						: priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type2 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type5 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type6
						? { benefit: { loyalty: { loyaltyMultiplier: 1 } } }
						: undefined
					: benefitType == benefitTypeDropdownValues.type4
					? { benefit: { freeTextMessage: '' } }
					: undefined;
			//newly added
			setProducts([
				//  ...products,
				{
					id: 0,
					thresholdQuantity:
						thresholdTypeCode == thresholdTypeCodeDropdownValues.type3 ? undefined : 1,
					thresholdAmount:
						thresholdTypeCode == thresholdTypeCodeDropdownValues.type4 ||
						thresholdTypeCode == thresholdTypeCodeDropdownValues.type5 ||
						thresholdTypeCode == thresholdTypeCodeDropdownValues.type3
							? 0.01
							: undefined,
					...priceModificationMethodCodeValue,
					paymentThresholdAmount: applicableOn ? 0.01 : undefined
				}
			]);
			/* let modifiedProductDataList = [];


      const errObj = {
        id: 0,
        thresholdQuantity: 1,
        thresholdAmount: 0.01,
        ...priceModificationMethodCodeValue,
        paymentThresholdAmount: 0.01,
      };
      modifiedProductDataList.push(errObj);
      
      setProducts(modifiedProductDataList);
      ;

      //  if (products.length == 0) addProduct();
      setPrevPriceModificationMethodCodeValue(priceModificationMethodCodeValue); */
		}
	}, [benefitType, priceModificationMethodCode, thresholdTypeCode, applicableOn]);

	useEffect(() => {
		/* if (props.data.length == 0) {
      if (products.length == 0) addProduct();
    } else if (props.data.length != 0) {
      if (props.data.length == 0) addProduct();
    } */
		props.onProductTableChange(products);

		let confgrdDataToViewTableRecords = JSON.parse(
			sessionStorage.getItem('configuredDataToViewTableRecords')
		).recordGroupForTable;

		let sizePerPageLst = confgrdDataToViewTableRecords.map((val) => {
			return { text: val, value: val };
		});
		sizePerPageLst.push({ text: 'All', value: products.length });

		options = {
			sizePerPageRenderer,
			sizePerPageList: sizePerPageLst
		};
	}, [products]);

	// event handlers
	const selectRow = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			let newSelectedProducts = [...selectedProducts];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row.id);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.id);
			}
			setSelectedProducts(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			let newSelectedProducts = [...selectedProducts];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.id;
				});
			}
			setSelectedProducts(newSelectedProducts);
		}
	};

	const addProduct = async () => {
		const { eligibility } = props;

		if (eligibility === eligibilityType.ScaleTransaction) {
			if (priceModificationMethodCode || benefitType == benefitTypeDropdownValues.type4) {
				//new
				let priceModificationMethodCodeValue =
					benefitType == benefitTypeDropdownValues.type1
						? priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type1 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type2
							? { benefit: { priceModificationPercent: 0.01 } }
							: priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValues.type3 ||
							  priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValues.type4
							? { benefit: { priceModificationAmount: 0.01 } }
							: { benefit: { newPriceAmount: 0.01 } }
						: benefitType == benefitTypeDropdownValues.type2
						? priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type1 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type2
							? { benefit: { priceModificationPercent: 0.01 } }
							: priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValues.type3 ||
							  priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValues.type4
							? { benefit: { priceModificationAmount: 0.01 } }
							: undefined
						: benefitType == benefitTypeDropdownValues.type3
						? priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type1 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type3 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type4
							? { benefit: { loyalty: { loyaltyAdditionalPoint: 0.01 } } }
							: priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValuesForLoyalty.type2 ||
							  priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValuesForLoyalty.type5 ||
							  priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValuesForLoyalty.type6
							? { benefit: { loyalty: { loyaltyMultiplier: 1 } } }
							: undefined
						: benefitType == benefitTypeDropdownValues.type4
						? { benefit: { freeTextMessage: '' } }
						: undefined;
				// setPrevPriceModificationMethodCodeValue(priceModificationMethodCodeValue);
				//newly added
				setProducts([
					...products,
					{
						id: products.length !== 0 ? products[products.length - 1].id + 1 : 0,
						thresholdQuantity:
							thresholdTypeCode == thresholdTypeCodeDropdownValues.type3
								? undefined
								: 1,
						thresholdAmount:
							thresholdTypeCode == thresholdTypeCodeDropdownValues.type4 ||
							thresholdTypeCode == thresholdTypeCodeDropdownValues.type5 ||
							thresholdTypeCode == thresholdTypeCodeDropdownValues.type3
								? 0.01
								: undefined,
						...priceModificationMethodCodeValue,
						paymentThresholdAmount: applicableOn ? 0.01 : undefined
					}
				]);

				/* if (thresholdTypeCode && applicableOn == undefined) {
           if (
            thresholdTypeCode == thresholdTypeCodeDropdownValues.type4 ||
            thresholdTypeCode == thresholdTypeCodeDropdownValues.type5 ||
            thresholdTypeCode == thresholdTypeCodeDropdownValues.type3
          ) {
            setProducts([
              ...products,
              {
                id:
                  products.length !== 0
                    ? products[products.length - 1].id + 1
                    : 0,
                thresholdQuantity: 1,
                thresholdAmount: 0.01,
                ...priceModificationMethodCodeValue,
              },
            ]);
          } else {
            setProducts([
              ...products,
              {
                id:
                  products.length !== 0
                    ? products[products.length - 1].id + 1
                    : 0,
                thresholdQuantity: 1,
                ...priceModificationMethodCodeValue,
              },
            ]);
          } 
        } else if (applicableOn && thresholdTypeCode == undefined) {
          if (
            //applicableOn == paymentThresholdCodeDropdownValues.type1
            // applicableOn != ""
            field.value?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.hasOwnProperty("applicableOn") && applicableOn != ""
          ) {
            setProducts([
              ...products,
              {
                id:
                  products.length !== 0
                    ? products[products.length - 1].id + 1
                    : 0,
                thresholdQuantity: 1,
                ...priceModificationMethodCodeValue,
                paymentThresholdAmount: 0.01,
              },
            ]);
          }
        } else if (applicableOn && thresholdTypeCode) {
          if (
            thresholdTypeCode == thresholdTypeCodeDropdownValues.type4 ||
            thresholdTypeCode == thresholdTypeCodeDropdownValues.type5 ||
            thresholdTypeCode == thresholdTypeCodeDropdownValues.type3
          ) {
            setProducts([
              ...products,
              {
                id:
                  products.length !== 0
                    ? products[products.length - 1].id + 1
                    : 0,
                thresholdQuantity: 1,
                thresholdAmount: 0.01,
                ...priceModificationMethodCodeValue,
                paymentThresholdAmount: 0.01,
              },
            ]);
          } else {
            setProducts([
              ...products,
              {
                id:
                  products.length !== 0
                    ? products[products.length - 1].id + 1
                    : 0,
                thresholdQuantity: 1,
                ...priceModificationMethodCodeValue,
                paymentThresholdAmount: 0.01,
              },
            ]);
          }
        } else {
          setProducts([
            ...products,
            {
              id:
                products.length !== 0
                  ? products[products.length - 1].id + 1
                  : 0,
              thresholdQuantity: 1,
              ...priceModificationMethodCodeValue,
            },
          ]);
        }*/
			}
		}
		//  setProductID("");
	};

	const deleteSelected = () => {
		let newProducts = [...products];

		if (selectedProducts.length > 0) {
			newProducts = newProducts.filter((val) => {
				return !selectedProducts.includes(val.id);
			});
			if (newProducts.length == 0) {
				setColumns(columnsScaleTransaction);
				let priceModificationMethodCodeValue =
					benefitType == benefitTypeDropdownValues.type1
						? priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type1 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type2
							? { benefit: { priceModificationPercent: 0.01 } }
							: priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValues.type3 ||
							  priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValues.type4
							? { benefit: { priceModificationAmount: 0.01 } }
							: { benefit: { newPriceAmount: 0.01 } }
						: benefitType == benefitTypeDropdownValues.type2
						? priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type1 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValues.type2
							? { benefit: { priceModificationPercent: 0.01 } }
							: priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValues.type3 ||
							  priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValues.type4
							? { benefit: { priceModificationAmount: 0.01 } }
							: undefined
						: benefitType == benefitTypeDropdownValues.type3
						? priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type1 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type3 ||
						  priceModificationMethodCode ==
								priceModificationMethodCodeDropdownValuesForLoyalty.type4
							? { benefit: { loyalty: { loyaltyAdditionalPoint: 0.01 } } }
							: priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValuesForLoyalty.type2 ||
							  priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValuesForLoyalty.type5 ||
							  priceModificationMethodCode ==
									priceModificationMethodCodeDropdownValuesForLoyalty.type6
							? { benefit: { loyalty: { loyaltyMultiplier: 1 } } }
							: undefined
						: benefitType == benefitTypeDropdownValues.type4
						? { benefit: { freeTextMessage: '' } }
						: undefined;
				//debugger;
				let modifiedProductDataList = [];

				const errObj = {
					id: 0,
					thresholdQuantity: 1,
					thresholdAmount: 0.01,
					...priceModificationMethodCodeValue,
					paymentThresholdAmount: 0.01
				};
				modifiedProductDataList.push(errObj);
				//debugger
				setProducts(modifiedProductDataList);
				setSelectedProducts([]);
			} else {
				setProducts(newProducts);
				setSelectedProducts([]);
			}
		}
	};

	const handleTableChange = (oldValue, newValue, row) => {
		let newProducts = [...products];
		//debugger;
		newProducts.map((val) => {
			if (val.id == row.id) {
				return row;
			}
			return val;
		});
		setProducts(newProducts);
	};

	//render
	return (
		<div>
			<div className="justify-content-flex-start align-items-center mb-3">
				<div className="container">
					<div className="rowContainer">
						<div className="customInputContainer" style={{ flex: '1' }}>
							<div className="row float-right position-relative">
								<button
									type="button"
									className="addPlusBtn mr-1 ml-2"
									onClick={addProduct}
								></button>

								<button
									type="button"
									className="addDeleteBtn ml-1"
									onClick={deleteSelected}
								></button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{products.length > 0 && !isObjectEmpty(columns) && (
				<ToolkitProvider keyField="id" data={products} columns={columns} search>
					{(props) => (
						<>
							{/* <SearchBar className="text-left" {...props.searchProps} /> */}
							<BootstrapTable
								// onTableChange={onTableChange}
								{...props.baseProps}
								keyField="id"
								data={products}
								columns={columns}
								cellEdit={cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: handleTableChange
								})}
								// pagination={paginationFactory(options)}
								filter={filterFactory()}
								selectRow={selectRow}
							/>
						</>
					)}
				</ToolkitProvider>
			)}
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

export default ProductTableBoostrap;
