import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import './topHeader.scss';

import NotificationLogo from '../../assets/images/notification.png';
import Profile from '../../assets/images/Profile.png';
import Up from '../../assets/images/up-arrow.png';
import Down from '../../assets/images/down-arrow.png';
import ChangePwd from '../../assets/images/ChangePwd.png';
import Info from '../../assets/images/Info.png';
import LogOff from '../../assets/images/LogOff.png';

const subPageHeading = {
	// offerconfigurator: 'Offer Management',
	offerconfiguratorcs: '/ Offer Management',
	// levelmaintenance: 'Level Management',
	// UserMaintenance: 'User Management',
	// couponManagement: 'Coupon Management',
	segmentManagement: '/ Segment Management'
	// auditMaintenance: 'Audit Management',
	// binRangeManagement: 'Bin Setup',
	// notificationManager: 'Notification Management',
	// CustomerManager:'Promotion Customer Mapping',
};

const TopHeader = ({
	disableItem,
	assignedResources,
	userFullName,
	dropdownOpen,
	toggle,
	changePwdModalOpen,
	showLogOffConfirmation,
	location
}) => {
	const pageHeading = subPageHeading[location.pathname.split('/')[2]];
	return (
		<div className="row top-header-main">
			<div className="col-9 d-flex align-items-baseline">
				<span className="offer-text-style">Offer Configurator</span>
				<span className="offer-page-heading">{pageHeading}</span>
			</div>
			<div className="col">
				<div className="headerAlign">
					{/* <input type="text" className="nav-search-input" placeholder="Search keywords" /> */}
					<img className="notificationImage" src={NotificationLogo} alt="img" />
					<div
						className={`d-flex align-items-center colorwhite
				${
					disableItem || !(assignedResources.indexOf('BO003') === -1)
						? 'disabled'
						: 'dropdown-transparent-bg'
				}`}
					>
						<span className="text-nowrap">{userFullName.fullName}</span>
						<div>
							<Dropdown isOpen={dropdownOpen} toggle={toggle}>
								<DropdownToggle>
									<img src={dropdownOpen ? Up : Down} alt="img" />
								</DropdownToggle>
								<DropdownMenu className="dropdownMenuClass">
									<DropdownItem header className="dropdownheaderClass">
										Account Settings
									</DropdownItem>
									<DropdownItem divider />
									<DropdownItem className="dropdownitemClass dropdownTexts">
										<span className="image">
											<img src={Profile} alt="img" />
										</span>
										<span className="">Profile</span>
									</DropdownItem>
									<DropdownItem
										className="dropdownitemClass dropdownTexts"
										onClick={changePwdModalOpen}
									>
										<span className="image">
											<img src={ChangePwd} alt="img" />
										</span>
										<span>Change Password</span>
									</DropdownItem>
									<DropdownItem className="dropdownitemClass dropdownTexts">
										<span className="image">
											<img src={Info} alt="img" />
										</span>
										<span className="">Update Other Information</span>
									</DropdownItem>
									<DropdownItem
										className="dropdownitemClass dropdownTexts"
										onClick={showLogOffConfirmation}
									>
										<span className="image">
											<img src={LogOff} alt="img" />
										</span>
										<span>Log Off</span>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

TopHeader.propTypes = {
	disableItem: PropTypes.bool,
	assignedResources: PropTypes.arrayOf(PropTypes.string),
	location: PropTypes.shape({}),
	dropdownOpen: PropTypes.bool,
	toggle: PropTypes.func,
	changePwdModalOpen: PropTypes.func,
	showLogOffConfirmation: PropTypes.func
};

export default TopHeader;
