import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import * as _ from 'lodash';
import {
	isObjectEmpty,
	validateMinOverMax,
	validateMaxOverMin,
	validateCSV,
	validateMaxOverMinCsv,
	validateMinOverMaxCsv,
	is_boolean
} from '../../../utils/helperFunction';
// import { productData } from './productData';
import { InformationModal } from '../../../MainContent/InformationModal';
import * as RestSvc from '../../../libraries/offer-services';
import * as urlTypes from '../../../libraries/offer-service-urls';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import { CSVReader } from 'react-papaparse';
import ToolkitProvider from '../../../utils/reactBootstrapTable2Toolkit';

var itmDescObj = {};

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
	<div className="btn-group" role="group">
		{options.map((option) => {
			const isSelect = currSizePerPage === `${option.page}`;
			return (
				<button
					key={option.text}
					type="button"
					onClick={() => onSizePerPageChange(option.page)}
					className={`btn ${isSelect ? 'btn-primary' : 'btn-secondary'}`}
				>
					{option.text}
				</button>
			);
		})}
	</div>
);

let options = {};

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
	let a = column.text;
	if (a != undefined) {
		a = a.split('$');
		return (
			<div>
				<div>
					{sortElement}
					{filterElement}
				</div>
				<div>
					<b> {a[0]} </b>
				</div>
				<div>
					<b> {a[1]} </b>
				</div>
			</div>
		);
	}
}

const columnsMultiBuyGroup = [
	{
		dataField: 'TableSlNo',
		text: 'Serial No. $ ',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false
	},
	{
		dataField: 'productID',
		text: 'Product ID',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		headerClasses: 'hdrProductId',
		filter: textFilter()
	},
	{
		dataField: 'itemDescription',
		text: 'Description',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false,
		headerClasses: 'hdrProductDesc',
		filter: textFilter()
	},
	{
		dataField: 'trigger',
		text: 'Trigger $ ',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		editor: {
			type: Type.CHECKBOX,
			value: 'true:false'
		}
	},
	{
		dataField: 'getReward',
		text: 'Reward $ ',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		editor: {
			type: Type.CHECKBOX,
			value: 'true:false'
		}
	},
	{
		dataField: 'minimumPrice',
		text: 'Minimum Price $ Range (0.01-999999.99)',
		sort: true,
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		validator: (newValue, row, column) => {
			return validateMinOverMax(newValue, row, column);
		}
	},
	{
		dataField: 'maximumPrice',
		text: 'Maximum Price $ Range (0.01-999999.99)',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		validator: (newValue, row, column) => {
			return validateMaxOverMin(newValue, row, column);
		}
	}
];

const columnsMTMB = [
	{
		dataField: 'TableSlNo',
		text: 'Serial No. $ ',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false
	},
	{
		dataField: 'productID',
		text: 'Product ID',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		headerClasses: 'hdrProductId',
		filter: textFilter()
	},
	{
		dataField: 'itemDescription',
		text: 'Description',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false,
		headerClasses: 'hdrProductDesc',
		filter: textFilter()
	},
	{
		dataField: 'minimumPrice',
		text: 'Minimum Price $ Range (0.01-999999.99)',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		validator: (newValue, row, column) => {
			return validateMinOverMax(newValue, row, column);
		}
	},
	{
		dataField: 'maximumPrice',
		text: 'Maximum Price $ Range (0.01-999999.99)',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		validator: (newValue, row, column) => {
			return validateMaxOverMin(newValue, row, column);
		}
	}
];
const columnsTXN = [
	{
		dataField: 'TableSlNo',
		text: 'Serial No. $ ',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false
	},
	{
		dataField: 'productID',
		text: 'Product ID',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		headerClasses: 'hdrProductId',
		filter: textFilter()
	},
	{
		dataField: 'itemDescription',
		text: 'Description',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		editable: false,
		headerClasses: 'hdrProductDesc',
		filter: textFilter()
	},
	{
		dataField: 'includeForThreshold',
		text: 'Include For Threshold $ ',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		editor: {
			type: Type.CHECKBOX,
			value: 'true:false'
		}
	},
	{
		dataField: 'minimumPrice',
		text: 'Minimum Price $ Range (0.01-999999.99)',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		validator: (newValue, row, column) => {
			return validateMinOverMax(newValue, row, column);
		}
	},
	{
		dataField: 'maximumPrice',
		text: 'Maximum Price $ Range (0.01-999999.99)',
		headerFormatter: headerFormatter,
		headerAlign: 'center',
		sort: true,
		validator: (newValue, row, column) => {
			return validateMaxOverMin(newValue, row, column);
		}
	}
];
const eligibilityType = {
	MTMB: 'MTMB',
	MultiBuyGroup: 'MultiBuyGroup',
	scaledPriceEligibility: 'scaledPriceEligibility',
	TXN: 'TXN',
	MultiByProduct: 'MultiByProduct'
};

const ProductTableBoostrap = (props) => {
	// state and props
	const [products, setProducts] = useState([]);
	const [productID, setProductID] = useState('');
	const [columns, setColumns] = useState('');
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState('');
	const [informationTitle] = useState('Information');

	//useEffects
	useEffect(() => {
		const { eligibility } = props;
		if (
			eligibility === eligibilityType.scaledPriceEligibility ||
			eligibility === eligibilityType.MultiByProduct
		) {
			setColumns(columnsMultiBuyGroup);
		} else if (eligibility === eligibilityType.MTMB) {
			setColumns(columnsMTMB);
		} else if (eligibility === eligibilityType.TXN) {
			setColumns(columnsTXN);
		}

		/* let prdcts = props.data.map((val, index, arrayobj) => {
            return { ...val, TableSlNo: index+1 };
        })
        setProducts(prdcts) */

		getItemDescription(props.data);
		//setProducts(prevMovies => ([...prevMovies, ...props.data]));
		//debugger

		/* options = {
            sizePerPageRenderer,
            sizePerPageList: [{
                text: '20', value: 20
            }, {
                text: '50', value: 50
            }, {
                text: 'All', value: products.length
            }]
        }; */
		return () => {
			setProducts([]);
		};
	}, []);

	useEffect(() => {
		let prdcts = JSON.parse(JSON.stringify(products));

		prdcts = prdcts.map((val) => {
			delete val['TableSlNo'];
			delete val['itemDescription'];
			return val;
		});
		props.onProductTableChange(prdcts);

		let confgrdDataToViewTableRecords = JSON.parse(
			sessionStorage.getItem('configuredDataToViewTableRecords')
		).recordGroupForTable;

		let sizePerPageLst = confgrdDataToViewTableRecords.map((val) => {
			return { text: val, value: val };
		});
		sizePerPageLst.push({ text: 'All', value: products.length });

		options = {
			sizePerPageRenderer,
			sizePerPageList: sizePerPageLst
		};
	}, [products]);

	// event handlers
	const selectRow = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			//debugger
			let newSelectedProducts = [...selectedProducts];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row.productID);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.productID);
			}
			setSelectedProducts(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			let newSelectedProducts = [...selectedProducts];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.productID;
				});
			}
			setSelectedProducts(newSelectedProducts);
		}
	};

	const getItemDescription = async (prodcts) => {
		if (prodcts.length) {
			let flatPrdcts = prodcts.map((val) => {
				return val.productID;
			});

			const businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const dataToSend = {
				getItemDescriptionRequest: {
					id: flatPrdcts,
					businessUnitFunctionID: businessUnitFunctionID,
					idType: 'product'
				}
			};

			props.showLoadingButton(true);

			const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
			RestSvc.postData(
				urlTypes.offers_maintenance.getItemDescriptionUrl,
				dataToSend,
				svPromUrl
			)
				.then((response) => {
					props.showLoadingButton(false);
					const { itemDescription } = response.data.getItemDescriptionResponse;

					itemDescription &&
						itemDescription.map((val) => {
							itmDescObj[val.itemID] = val.description;
						});

					let prdctsWithDesc = prodcts.map((val, index) => {
						return {
							...val,
							TableSlNo: index + 1,
							itemDescription: itmDescObj[val.productID]
						};
					});
					setProducts(prdctsWithDesc);
				})
				.catch((error) => {
					props.showLoadingButton(false);
					console.error(error);
				});
		} else {
			setProducts([]);
		}
	};

	const addProduct = async () => {
		let errorMsgs = [];
		if (productID) {
			const { eligibility } = props;
			const businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const serviceInput = {
				validateItemRequest: {
					id: [productID],
					businessUnitFunctionID: businessUnitFunctionID,
					idType: 'product'
				}
			};

			if (eligibility === eligibilityType.scaledPriceEligibility) {
				if (!products.find((x) => x.productID == productID)) {
					// if (eligibility === eligibilityType.scaledPriceEligibility) {
					//debugger
					const serviceResponse = await getValidProductId(serviceInput);
					const { invalidId, itemDescription } =
						serviceResponse.data.validateItemResponse;
					if (invalidId.length == 0) {
						itemDescription &&
							itemDescription.map((val) => {
								itmDescObj[val.itemID] = val.description;
							});

						setProducts([
							...products,
							{
								TableSlNo: products.length + 1,
								itemDescription: itmDescObj[productID],
								productID: productID,
								maximumPrice: props.defaultValueForAdd.maximumPrice,
								minimumPrice: props.defaultValueForAdd.minimumPrice,
								trigger: props.defaultValueForAdd.trigger,
								getReward: props.defaultValueForAdd.getReward
							}
						]);
					} else {
						// setInformationMsg('Product ' + invalidId.join(', ') + ' is invalid');
						// setInformationModalShow(true);
						errorMsgs.push(
							<>
								<b>Invalid Products</b>
								<p>{`${invalidId.join(',')}`}</p>
							</>
						);
					}
					// }
					// else if (eligibility === eligibilityType.MTMB) {
					//     setProducts([...products, {
					//         // id: state.products[state.products.length - 1].id + 1,
					//         productID: productID,
					//         maximumPrice: props.defaultValueForAdd.maximumPrice,
					//         minimumPrice: props.defaultValueForAdd.minimumPrice,
					//     }])
					// }
				} else {
					// alert('ProductID ' + productID + ' is already present')
					// setInformationMsg('Product ' + productID + ' is already present');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Duplicate Products</b>
							<p>{productID}</p>
						</>
					);
				}
			} else if (eligibility === eligibilityType.MultiByProduct) {
				if (!products.length > 0) {
					if (!products.find((x) => x.productID == productID)) {
						// if (eligibility === eligibilityType.scaledPriceEligibility) {
						//debugger
						const serviceResponse = await getValidProductId(serviceInput);
						const { invalidId, itemDescription } =
							serviceResponse.data.validateItemResponse;
						if (invalidId.length == 0) {
							itemDescription &&
								itemDescription.map((val) => {
									itmDescObj[val.itemID] = val.description;
								});

							setProducts([
								...products,
								{
									TableSlNo: products.length + 1,
									itemDescription: itmDescObj[productID],
									productID: productID,
									maximumPrice: props.defaultValueForAdd.maximumPrice,
									minimumPrice: props.defaultValueForAdd.minimumPrice,
									trigger: props.defaultValueForAdd.trigger,
									getReward: props.defaultValueForAdd.getReward
								}
							]);
						} else {
							// setInformationMsg('Product ' + invalidId.join(', ') + ' is invalid');
							// setInformationModalShow(true);
							errorMsgs.push(
								<>
									<b>Invalid Products</b>
									<p>{`${invalidId.join(',')}`}</p>
								</>
							);
						}
						// }
						// else if (eligibility === eligibilityType.MTMB) {
						//     setProducts([...products, {
						//         // id: state.products[state.products.length - 1].id + 1,
						//         productID: productID,
						//         maximumPrice: props.defaultValueForAdd.maximumPrice,
						//         minimumPrice: props.defaultValueForAdd.minimumPrice,
						//     }])
						// }
					} else {
						// alert('ProductID ' + productID + ' is already present')
						// setInformationMsg('Product ' + productID + ' is already present');
						// setInformationModalShow(true);
						errorMsgs.push(
							<>
								<b>Duplicate Products</b>
								<p>{productID}</p>
							</>
						);
					}
				} else {
					// alert('ProductID ' + productID + ' is already present')
					// setInformationMsg('Product ' + productID + ' is already present');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Canot add more than one Products</b>
						</>
					);
				}
			} else if (eligibility === eligibilityType.MTMB) {
				let errFlag = false;
				props.group.map((g) => {
					g.multibuyProduct.map((item) => {
						if (item.productID === productID) {
							errFlag = true;
						}
					});
				});
				if (errFlag) {
					// alert('ProductID ' + productID + ' is already present');
					// setInformationMsg('Product ' + productID + ' is already present');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Duplicate Products</b>
							<p>{productID}</p>
						</>
					);
				} else {
					const serviceResponse = await getValidProductId(serviceInput);
					const { invalidId, itemDescription } =
						serviceResponse.data.validateItemResponse;
					if (invalidId.length == 0) {
						itemDescription &&
							itemDescription.map((val) => {
								itmDescObj[val.itemID] = val.description;
							});

						setProducts([
							...products,
							{
								TableSlNo: products.length + 1,
								itemDescription: itmDescObj[productID],
								productID: productID,
								maximumPrice: props.defaultValueForAdd.maximumPrice,
								minimumPrice: props.defaultValueForAdd.minimumPrice
							}
						]);
					} else {
						// alert('ProductID ' + productID + ' is already present')
						// setInformationMsg('Product ' + productID + ' is invalid');
						// setInformationModalShow(true);
						errorMsgs.push(
							<>
								<b>Invalid Products</b>
								<p>{productID}</p>
							</>
						);
					}
				}
			} else if (eligibility === eligibilityType.TXN) {
				if (!products.find((x) => x.productID == productID)) {
					const serviceResponse = await getValidProductId(serviceInput);
					const { invalidId, itemDescription } =
						serviceResponse.data.validateItemResponse;
					if (invalidId.length == 0) {
						itemDescription &&
							itemDescription.map((val) => {
								itmDescObj[val.itemID] = val.description;
							});

						setProducts([
							...products,
							{
								TableSlNo: products.length + 1,
								itemDescription: itmDescObj[productID],
								productID: productID,
								maximumPrice: props.defaultValueForAdd.maximumPrice,
								minimumPrice: props.defaultValueForAdd.minimumPrice,
								includeForThreshold: props.defaultValueForAdd.includeForThreshold
							}
						]);
					} else {
						// setInformationMsg('Product ' + invalidId.join(', ') + ' is invalid');
						// setInformationModalShow(true);
						errorMsgs.push(
							<>
								<b>Invalid Products</b>
								<p>{`${invalidId.join(',')}`}</p>
							</>
						);
					}
				} else {
					// alert('ProductID ' + productID + ' is already present')
					// setInformationMsg('Product ' + productID + ' is already present');
					// setInformationModalShow(true);
					errorMsgs.push(
						<>
							<b>Duplicate Products</b>
							<p>{productID}</p>
						</>
					);
				}
			}

			setProductID('');
			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		}
	};

	const buttonRef = React.createRef();

	const handleOpenDialog = (e) => {
		// Note that the ref is set async, so it might be null at some point
		if (buttonRef.current) {
			buttonRef.current.open(e);
		}
	};

	const handleOnFileLoad = async (data) => {
		let errorMsgs = [];
		let csvUploadStatus = validateCSV(props.keys, data);

		if (csvUploadStatus == 'CORRECT') {
			let csvHeaders = data[0].data;
			let tmpData = [];

			for (let i = 1; i < data.length - 1; i++) {
				let tmpObj = {};

				for (let j = 0; j < csvHeaders.length; j++) {
					tmpObj[csvHeaders[j]] = data[i].data[j]
						? data[i].data[j].toString().trim()
						: data[i].data[j];
				}

				tmpData.push(tmpObj);
			}

			const { eligibility } = props;
			let parsedData = tmpData;
			let duplicateValues;
			let Failedcnt = 0;
			let alreadyAddedProducts = [];

			let productFlatArray = [...parsedData];

			productFlatArray = productFlatArray.map((val) => {
				return val.productID;
			});

			let businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const serviceInput = {
				validateItemRequest: {
					id: productFlatArray,
					businessUnitFunctionID: businessUnitFunctionID,
					idType: 'product'
				}
			};

			const serviceResponse = await getValidProductId(serviceInput);

			const { invalidId, duplicateId, validId, itemDescription } =
				serviceResponse.data.validateItemResponse;

			itemDescription &&
				itemDescription.map((val) => {
					itmDescObj[val.itemID] = val.description;
				});

			let tmpParsData = [];

			validId.map((val) => {
				for (let i = 0; i < parsedData.length; i++) {
					if (val == parsedData[i].productID) {
						tmpParsData.push(parsedData[i]);
					}
				}
			});

			parsedData = tmpParsData;

			if (eligibility === eligibilityType.MTMB) {
				props.group.map((g) => {
					g.multibuyProduct.map((item) => {
						alreadyAddedProducts.push(item);
					});
				});
			}
			if (eligibility === eligibilityType.MTMB) {
				duplicateValues = _.intersectionBy(alreadyAddedProducts, parsedData, 'productID');
				//  parsedData = parsedData.filter(val => !alreadyAddedProducts.includes((a)=>{a.productID==val.productID}));
				parsedData = _.reject(parsedData, function (e1) {
					return _.find(duplicateValues, function (e2) {
						return e1.productID == e2.productID;
					});
				});
			} else {
				duplicateValues = _.intersectionBy(products, parsedData, 'productID');
			}
			//debugger
			duplicateValues = duplicateValues.map((val) => {
				return val.productID;
			});
			//debugger
			if (duplicateValues.length > 0) {
				// alert('Duplicate product ID are removed ' + duplicateValues.join(', '))
				Failedcnt = Failedcnt + duplicateValues.length;
				errorMsgs.push(
					<>
						<b>Product id(s) already added:{duplicateValues.length}</b>
						<p>{`${duplicateValues.join(', ')}`}</p>
					</>
				);
			}
			/* let productFlatArray = [...parsedData]
            productFlatArray = productFlatArray.map(val => {
                return val.productID
            })
            let businessUnitFunctionID = JSON.parse(sessionStorage.getItem("taggedBrandToLoggedInUser")).businessUnitGroupFunctionID
            const serviceInput = {
                "validateItemRequest": {
                    "id": productFlatArray,
                    "businessUnitFunctionID": businessUnitFunctionID,
                    "idType": "product"
                }
            }
            //debugger
            // getValidProductId(serviceInput);
            const serviceResponse = await getValidProductId(serviceInput);
            //debugger
            const { invalidId } = serviceResponse.data.validateItemResponse
            //debugger */
			Failedcnt = productFlatArray.length - validId.length;
			if (duplicateId && duplicateId.length > 0) {
				// duplicateValues=duplicateValues.concat(duplicateId);
				// duplicateValues= duplicateValues.filter((it, i, ar) => ar.indexOf(it) === i);
				//Failedcnt=Failedcnt+duplicateId.length;
				errorMsgs.push(
					<>
						<b>Duplicate product id(s) :{duplicateId.length}</b>
						<p>{`${duplicateId.join(', ')}`}</p>
					</>
				);
			}
			if (invalidId.length > 0) {
				// errorMsgs = `${errorMsgs} Products  ${invalidId.join(', ')} are invalid. <br>`
				//Failedcnt=Failedcnt+invalidId.length;
				errorMsgs.push(
					<>
						<b>Invalid product id(s) : {invalidId.length}</b>
						<p>{`${invalidId.join(', ')}`}</p>
					</>
				);
			}
			//debugger
			// parsedData = parsedData.filter(val => {
			//     return !val.productID.includes(invalidId)
			// })
			parsedData = parsedData.filter((val) => !invalidId.includes(val.productID));

			//minimum maximum validation

			let removedmaxvalRow = parsedData.filter((val) => {
				let maxvaliatin = validateMaxOverMinCsv(val.maximumPrice, val);
				return !maxvaliatin;
			});
			removedmaxvalRow = removedmaxvalRow.map((val) => {
				return val.productID;
			});
			if (removedmaxvalRow.length > 0) {
				Failedcnt = Failedcnt + removedmaxvalRow.length;
				// errorMsgs = `${errorMsgs} Products ${removedRow.join(', ')} are removed, trigger and rewared columns were empty.`
				errorMsgs.push(
					<>
						<b>
							Entered maximum unit price value should be greater than minimum unit
							price and must be between (0.01 to 999999.99) and only accepting two
							decimal value :{removedmaxvalRow.length}{' '}
						</b>
						<p>{` ${removedmaxvalRow.join(', ')}`}</p>
					</>
				);
			}

			parsedData = parsedData.filter((val) => validateMaxOverMinCsv(val.maximumPrice, val));
			//minimum validation
			let removedminvalRow = parsedData.filter((val) => {
				let maxvaliatin = validateMinOverMaxCsv(val.minimumPrice, val);
				return !maxvaliatin;
			});
			removedminvalRow = removedminvalRow.map((val) => {
				return val.productID;
			});
			if (removedminvalRow.length > 0) {
				Failedcnt = Failedcnt + removedminvalRow.length;

				errorMsgs.push(
					<>
						<b>
							Entered minimum unit price value should be less than maximum unit price
							and must be between (0.01 to 9999999.99) and only accepting two decimal
							value :{removedminvalRow.length}{' '}
						</b>
						<p>{` ${removedminvalRow.join(', ')}`}</p>
					</>
				);
			}

			parsedData = parsedData.filter((val) => validateMinOverMaxCsv(val.minimumPrice, val));

			if (parsedData.length >= 0) {
				let mergedData = [...products, ...parsedData];
				mergedData = _.uniqBy(mergedData, function (e) {
					return e.productID;
				});
				if (eligibility === eligibilityType.scaledPriceEligibility) {
					let removedRow = mergedData.filter((val) => {
						return val.trigger == '' && val.getReward == '';
					});
					removedRow = removedRow.map((val) => {
						return val.productID;
					});
					//debugger
					// alert('product IDs ' + removedRow.join(', ') + ' are removed, trigger and rewared columns were empty');
					if (removedRow.length > 0) {
						Failedcnt = Failedcnt + removedRow.length;
						// errorMsgs = `${errorMsgs} Products ${removedRow.join(', ')} are removed, trigger and rewared columns were empty.`
						errorMsgs.push(
							<>
								<b>
									Trigger/Reward not defined for the product id(s) :{' '}
									{removedRow.length}
								</b>
								<p>{` ${removedRow.join(', ')}`}</p>
							</>
						);
					}
					mergedData = mergedData.filter((val) => {
						return val.trigger !== '' || val.getReward !== '';
					});

					mergedData = mergedData.map((val) => {
						if (val.trigger == '' || val.getReward == '') {
							return val.trigger == ''
								? { ...val, trigger: 'false' }
								: { ...val, getReward: 'false' };
						}
						return val;
					});

					//validation for MS office issue
					mergedData = mergedData.map((val) => {
						if (val.trigger && val.getReward) {
							return {
								...val,
								trigger: val.trigger.toString().toLowerCase().trim(),
								getReward: val.getReward.toString().toLowerCase().trim()
							};
						} else if (val.trigger) {
							return { ...val, trigger: val.trigger.toString().toLowerCase().trim() };
						} else if (val.getReward) {
							return {
								...val,
								getReward: val.getReward.toString().toLowerCase().trim()
							};
						} else if (val.includeForThreshold) {
							return {
								...val,
								includeForThreshold: val.includeForThreshold
									.toString()
									.toLowerCase()
									.trim()
							};
						}

						return { ...val };
					});
					//validation on true and false
					let returntriggerdata = mergedData.filter((val) => {
						let checktriggerval = is_boolean(val.trigger);
						return checktriggerval;
					});
					returntriggerdata = returntriggerdata.map((val) => {
						return val.productID;
					});
					if (returntriggerdata.length > 0) {
						Failedcnt = Failedcnt + returntriggerdata.length;

						errorMsgs.push(
							<>
								<b>
									Trigger must be eaither true or false for the product id(s) :{' '}
									{returntriggerdata.length}
								</b>
								<p>{` ${returntriggerdata.join(', ')}`}</p>
							</>
						);
					}

					mergedData = mergedData.filter((val) => {
						let checktriggerval1 = is_boolean(val.trigger);
						return !checktriggerval1;
					});

					//validation on reward true and false
					let returnrewarddata = mergedData.filter((val) => {
						let checkrewardval = is_boolean(val.getReward);
						return checkrewardval;
					});
					returnrewarddata = returnrewarddata.map((val) => {
						return val.productID;
					});
					if (returnrewarddata.length > 0) {
						Failedcnt = Failedcnt + returnrewarddata.length;

						errorMsgs.push(
							<>
								<b>
									Reward must be eaither true or false for the product id(s) :{' '}
									{returnrewarddata.length}
								</b>
								<p>{` ${returnrewarddata.join(', ')}`}</p>
							</>
						);
					}

					mergedData = mergedData.filter((val) => {
						let checkrewardval1 = is_boolean(val.getReward);
						return !checkrewardval1;
					});
				}

				errorMsgs.push(
					<>
						<b>Product id(s) uploaded successfully: </b>
						<p>{` ${tmpData.length - Failedcnt}`}</p>
						<b>Product id(s) failed to upload: </b>
						<p>{` ${Failedcnt}`}</p>
					</>
				);

				mergedData = mergedData.map((val, index) => {
					return {
						...val,
						TableSlNo: index + 1,
						itemDescription: itmDescObj[val.productID]
					};
				});

				setProducts(mergedData);
			}
			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		} else {
			errorMsgs.push(
				<>
					<b>The uploaded CSV format is incorrect. The colum headers must be: </b>
					<p>{csvUploadStatus}</p>
				</>
			);

			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		}
	};

	const handleOnError = () => {};

	const handleOnRemoveFile = () => {};

	const getValidProductId = (dataToSend) => {
		props.showLoadingButton(true);

		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		return RestSvc.postData('/rest/offers/configurator/validateItems', dataToSend, svPromUrl)
			.then((response) => {
				props.showLoadingButton(false);
				return response;
				//debugger
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const deleteSelected = () => {
		let newProducts = [...products];

		//debugger
		if (selectedProducts.length > 0) {
			newProducts = newProducts.filter((val) => {
				return !selectedProducts.includes(val.productID);
			});

			newProducts = newProducts.map((val, index) => {
				return { ...val, TableSlNo: index + 1 };
			});

			setProducts(newProducts);
			setSelectedProducts([]);
		}
	};

	const handleTableChange = (oldValue, newValue, row) => {
		let newProducts = [...products];
		newProducts.map((val) => {
			if (val.productID == row.productID) {
				return row;
			}
			return val;
		});
		setProducts(newProducts);

		//debugger
	};

	//render
	return (
		<div>
			{/* <div>
                <input type='text' value={productID} onChange={handleProductIDChange} />
                <button onClick={addProduct}>+</button>
                <CsvParse
                    keys={props.keys}
                    onDataUploaded={(data) => handleCSVData(data)}
                    onError={() => { }}
                    render={onChange => <input type="file" onChange={onChange} />}
                />
                <button onClick={deleteSelected}>X</button>
            </div> */}
			<div className="justify-content-flex-start align-items-center mb-3">
				<div className="container">
					<div className="rowContainer">
						<div className="customLabelContainer">
							<label htmlFor="forprogramID" className="col	col-form-label">
								Product ID
							</label>
						</div>
						<div className="customInputContainer">
							<div className="row">
								<input
									type="text"
									className="form-control"
									onChange={(e) => setProductID(e.target.value)}
									value={productID}
								/>
								<button
									type="button"
									className="addPlusBtn ml-2"
									onClick={addProduct}
								></button>
								{/* <CsvParse
                                    keys={props.keys}
                                    onDataUploaded={(data) => handleCSVData(data)}
                                    onError={() => { }}
                                    render={onChange => <>

                                        <input
                                            type="file"
                                            // id="idLoadProductFromFile"
                                            id={`idLoadProductFromFile${props.i?props.i:''}${props.j?props.j:''}`}
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                    onChange(e)
                                    e.target.value = ''
                                }}
                                        />
                                        <label className={`mb-0 uploadBtn ml-1 ${props.eligibility == 'MultiByProduct'? 'd-none' :''}`} 
                                            for={`idLoadProductFromFile${props.i?props.i:''}${props.j?props.j:''}`}> </label>
                                    </>}
                                /> */}
								<CSVReader
									ref={buttonRef}
									onFileLoad={handleOnFileLoad}
									onError={handleOnError}
									noClick
									noDrag
									onRemoveFile={handleOnRemoveFile}
								>
									{() => (
										<aside
											style={{
												display: 'flex',
												flexDirection: 'row'
											}}
										>
											<label
												className="mb-0 uploadBtn ml-1"
												onClick={handleOpenDialog}
											></label>
											{/* <button
                                                type='button'
                                                onClick={handleOpenDialog}
                                                style={{
                                                    borderRadius: 0,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                    width: '40%',
                                                    paddingLeft: 0,
                                                    paddingRight: 0
                                                }}
                                            >
                                                Browe file
                                            </button>
                                            <div
                                                style={{
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    borderColor: '#ccc',
                                                    height: 45,
                                                    lineHeight: 2.5,
                                                    marginTop: 5,
                                                    marginBottom: 5,
                                                    paddingLeft: 13,
                                                    paddingTop: 3,
                                                    width: '60%'
                                                }}
                                            >
                                                {file && file.name}
                                            </div>
                                            <button
                                                style={{
                                                    borderRadius: 0,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                    paddingLeft: 20,
                                                    paddingRight: 20
                                                }}
                                                onClick={handleRemoveFile}
                                            >
                                                Remove
                                            </button> */}
										</aside>
									)}
								</CSVReader>
								<button
									type="button"
									className="addDeleteBtn ml-1"
									onClick={deleteSelected}
								></button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{products.length > 0 && !isObjectEmpty(columns) && (
				<ToolkitProvider keyField="productID" data={products} columns={columns} search>
					{(props) => (
						<>
							{/* <SearchBar className="text-left" {...props.searchProps} /> */}
							<div className="pt-3 pb-4 pl-2 border-1-lightgray">
								<b>Filter by:</b>
							</div>
							<BootstrapTable
								// onTableChange={onTableChange}
								{...props.baseProps}
								keyField="productID"
								data={products}
								columns={columns}
								cellEdit={cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: handleTableChange
								})}
								pagination={paginationFactory(options)}
								filter={filterFactory()}
								selectRow={selectRow}
							/>
						</>
					)}
				</ToolkitProvider>
			)}
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTableBoostrap);
