import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../BasicDetails.scss';
import { useField, Field, useFormikContext } from 'formik';

const CustomInputRadio = ({
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	let formattedName = '';
	let newJson = '';
	let basicOrEligibilityJson = '';
	if (isAuditFlw) {
		let newCustName = JSON.parse(JSON.stringify(props.name));
		// eslint-disable-next-line no-unused-vars
		newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
		basicOrEligibilityJson = newCustName.includes('promotionPolicy')
			? 'newJson'
			: 'newJson.promotionPolicy.priceDerivationRuleEligibility';
		let arrayOfName = newCustName.split('.');

		for (let i = 0; i < arrayOfName.length; i++) {
			if (isNaN(arrayOfName[i])) {
				formattedName = formattedName + '?.' + arrayOfName[i];
			} else {
				formattedName = formattedName + '?.[' + arrayOfName[i] + ']';
			}
		}
	}

	return (
		<div className="container">
			<div
				className={
					isAuditFlw && eval(basicOrEligibilityJson + formattedName) === field.value
						? 'hideForAudit rowContainer'
						: 'rowContainer'
				}
			>
				<div className="customLabelContainer">
					<label htmlFor="forprogramID" className="col col-form-label">
						{label}

						{required ? <span className="requiredRed">*</span> : null}
					</label>
				</div>
				<div className="customInputContainer">
					<div className="row mg-5">
						<div>
							<div className={isAuditFlw ? 'hideForAudit' : ''}>
								<Field
									type="radio"
									{...field}
									{...props}
									value={JSON.stringify(option1)}
									checked={field.value == option1}
									className={
										props?.disabledVal1 ? 'disabledEventOpacity' : undefined
									}
									onChange={() => {
										setFieldValue(field.name, true);
									}}
								/>
								{label1}
								<Field
									type="radio"
									{...field}
									{...props}
									value={JSON.stringify(option2)}
									className={
										props?.disabledVal
											? 'radioMargin disabledEventOpacity'
											: 'radioMargin'
									}
									checked={field.value == option2}
									onChange={() => {
										setFieldValue(field.name, false);
									}}
								/>
								{label2}
							</div>
							{isAuditFlw ? (
								<div>
									<div className="d-flex gap70 replacedText">
										<input
											type="text"
											className="form-control lightGray"
											value={
												field.value?.toString() == 'true' ? label1 : label2
											}
										/>
										<input
											type="text"
											className="form-control lightBlue"
											value={
												eval(
													basicOrEligibilityJson + formattedName
												)?.toString() == 'true'
													? label1
													: label2
											}
										/>
									</div>
								</div>
							) : null}
						</div>
						{smallText && (
							<small id="emailHelp" className="form-text pl-2">
								<div className="tooltipimg">
									<button type="button" className="tooltip-button btn br-50">
										<span className="tooltip-icon">?</span>
									</button>
									<span className="tooltiptext mx-ht"> {smallText}</span>
								</div>
							</small>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomInputRadio;
