import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import BlackOutPeriod from './BlackOutPeriod';
import DaysOfTheWeekIncludedDay from './HappyHourComponents/DaysOfTheWeekIncludedDay';
import DaysOfTheWeekExcludedDay from './HappyHourComponents/DaysOfTheWeekExcludedDay';
import DaysOfTheMonthIncludedDay from './HappyHourComponents/DaysOfTheMonthIncludedDay';
import DaysOfTheMonthExcludedDate from './HappyHourComponents/DaysOfTheMonthExcludedDate';
// Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
//   return this.test('unique', message, function (list) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
	return this.test('unique', message, function (list) {
		if (!list) list = [];
		return list.length === new Set(list.map(mapper)).size;
	});
});

const HappyHours = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	//
	//useValues(name, props);
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();

	const fieldHappyHours =
		field.value.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility;
	const [daysInclude, setDaysInclude] = React.useState(false);
	const [daysExclude, setDaysExclude] = React.useState(false);
	const [dateInclude, setDateInclude] = React.useState(false);
	const [dateExclude, setDateExclude] = React.useState(false);
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;

	useEffect(() => {
		if (fieldHappyHours.eligibleDaysOfTheWeek?.includedDaysOfTheWeek?.includedDay?.length) {
			setDaysInclude(true);
		}
		if (fieldHappyHours.eligibleDaysOfTheWeek?.excludedDaysOfTheWeek?.excludedDay?.length) {
			setDaysExclude(true);
		}
		if (
			fieldHappyHours.eligibleDatesOfTheMonth?.includedDatesOfTheMonth?.includedDate?.length
		) {
			setDateInclude(true);
		}
		if (
			fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDate?.length
		) {
			setDateExclude(true);
		}

		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				fieldHappyHours?.eligibleDaysOfTheWeek?.includedDaysOfTheWeek?.includedDayAdded
					?.length
			) {
				setDaysInclude(true);
			}
			if (
				fieldHappyHours?.eligibleDaysOfTheWeek?.includedDaysOfTheWeek?.includedDayRemoved
					?.length
			) {
				setDaysInclude(true);
			}
			if (
				fieldHappyHours?.eligibleDaysOfTheWeek?.excludedDaysOfTheWeek?.excludedDayAdded
					?.length
			) {
				setDaysExclude(true);
			}
			if (
				fieldHappyHours?.eligibleDaysOfTheWeek?.excludedDaysOfTheWeek?.excludedDayRemoved
					?.length
			) {
				setDaysExclude(true);
			}
			if (
				fieldHappyHours?.eligibleDatesOfTheMonth?.includedDatesOfTheMonth?.includedDateAdded
					?.length
			) {
				setDateInclude(true);
			}
			if (
				fieldHappyHours?.eligibleDatesOfTheMonth?.includedDatesOfTheMonth
					?.includedDateRemoved?.length
			) {
				setDateInclude(true);
			}

			if (
				fieldHappyHours?.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDateAdded
					?.length
			) {
				setDateExclude(true);
			}

			if (
				fieldHappyHours?.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth
					?.excludedDateRemoved?.length
			) {
				setDateExclude(true);
			}
		}

		//
	}, []);

	useEffect(() => {
		if (
			daysInclude == true &&
			fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay.length == 0
		) {
			// fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay = [{
			//   "day": "01",
			//   "effectiveDuration": [{
			//     "effectiveStartTime": "00:00:00",
			//     "effectiveEndTime": "23:59:00"
			//   }]
			// }]
			// setFieldValue("", "");
			setFieldValue(
				'promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay',
				[
					{
						day: '01',
						effectiveDuration: [
							{
								effectiveStartTime: '00:00:00',
								effectiveEndTime: '23:59:00'
							}
						]
					}
				]
			);
		}
		// else if (daysInclude == false) {
		//   fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay = [];
		// }
		if (
			daysExclude == true &&
			fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay.length == 0
		) {
			// fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay = [{
			//   "day": "01",
			//   "effectiveDuration": [{
			//     "effectiveStartTime": "00:00:00",
			//     "effectiveEndTime": "23:59:00"
			//   }]
			// }]
			setFieldValue(
				'promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay',
				[
					{
						day: '01',
						effectiveDuration: [
							{
								effectiveStartTime: '00:00:00',
								effectiveEndTime: '23:59:00'
							}
						]
					}
				]
			);
		}
		// else if (fieldHappyHours.DaysExclude == false) {
		//   fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay = [];
		// }
		if (
			dateInclude == true &&
			fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate.length == 0
		) {
			// fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate = [{
			//   "date": "01",
			//   "effectiveDuration": [{
			//     "effectiveStartTime": "00:00:00",
			//     "effectiveEndTime": "23:59:00"
			//   }]
			// }]
			setFieldValue(
				'promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate',
				[
					{
						date: '01',
						effectiveDuration: [
							{
								effectiveStartTime: '00:00:00',
								effectiveEndTime: '23:59:00'
							}
						]
					}
				]
			);
		}
		// else if (fieldHappyHours.DateInclude == false) {
		//   fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate = [];
		// }
		if (
			dateExclude == true &&
			fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.length == 0
		) {
			// fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate = [{
			//   "date": "01",
			//   "effectiveDuration": [{
			//     "effectiveStartTime": "00:00:00",
			//     "effectiveEndTime": "23:59:00"
			//   }]
			// }]
			setFieldValue(
				'promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate',
				[
					{
						date: '01',
						effectiveDuration: [
							{
								effectiveStartTime: '00:00:00',
								effectiveEndTime: '23:59:00'
							}
						]
					}
				]
			);
		}
		// else if (fieldHappyHours.DateExclude == false) {
		//   fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate = [];
		// }
	}, [fieldHappyHours]);

	let includedDaysOfTheWeekArray = fieldHappyHours.eligibleDaysOfTheWeek?.includedDaysOfTheWeek
		?.includedDayAdded?.length
		? fieldHappyHours.eligibleDaysOfTheWeek?.includedDaysOfTheWeek?.includedDayAdded?.length
		: 0 +
		  fieldHappyHours.eligibleDaysOfTheWeek?.includedDaysOfTheWeek?.includedDayRemoved?.length
		? fieldHappyHours.eligibleDaysOfTheWeek?.includedDaysOfTheWeek?.includedDayRemoved?.length
		: 0 + fieldHappyHours.eligibleDaysOfTheWeek?.includedDaysOfTheWeek?.includedDay?.length
		? fieldHappyHours.eligibleDaysOfTheWeek?.includedDaysOfTheWeek?.includedDay?.length
		: 0;

	let excludedDaysOfTheWeekArray = fieldHappyHours.eligibleDaysOfTheWeek?.excludedDaysOfTheWeek
		?.excludedDayAdded?.length
		? fieldHappyHours.eligibleDaysOfTheWeek?.excludedDaysOfTheWeek?.excludedDayAdded?.length
		: 0 +
		  fieldHappyHours.eligibleDaysOfTheWeek?.excludedDaysOfTheWeek?.excludedDayRemoved?.length
		? fieldHappyHours.eligibleDaysOfTheWeek?.excludedDaysOfTheWeek?.excludedDayRemoved?.length
		: 0 + fieldHappyHours.eligibleDaysOfTheWeek?.excludedDaysOfTheWeek?.excludedDay?.length
		? fieldHappyHours.eligibleDaysOfTheWeek?.excludedDaysOfTheWeek?.excludedDay?.length
		: 0;

	let includedDatesOfTheMonthArray = fieldHappyHours.eligibleDatesOfTheMonth
		?.includedDatesOfTheMonth?.includedDateAdded?.length
		? fieldHappyHours.eligibleDatesOfTheMonth?.includedDatesOfTheMonth?.includedDateAdded
				?.length
		: 0 +
		  fieldHappyHours.eligibleDatesOfTheMonth?.includedDatesOfTheMonth?.includedDateRemoved
				?.length
		? fieldHappyHours.eligibleDatesOfTheMonth?.includedDatesOfTheMonth?.includedDateRemoved
				?.length
		: 0 + fieldHappyHours.eligibleDatesOfTheMonth?.includedDatesOfTheMonth?.includedDate?.length
		? fieldHappyHours.eligibleDatesOfTheMonth?.includedDatesOfTheMonth?.includedDate?.length
		: 0;

	let excludedDatesOfTheMonthArray = fieldHappyHours.eligibleDatesOfTheMonth
		?.excludedDatesOfTheMonth?.excludedDateAdded?.length
		? fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDateAdded
				?.length
		: 0 +
		  fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDateRemoved
				?.length
		? fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDateRemoved
				?.length
		: 0 + fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDate?.length
		? fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDate?.length
		: 0;
	return (
		<div className="h-Hours">
			<div
				className={
					JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow &&
					includedDaysOfTheWeekArray + excludedDaysOfTheWeekArray == 0
						? 'd-none'
						: 'purchaseContainer flexColumn'
				}
			>
				<label className="font-weight-bold pl-10">Days of the week</label>
				<div className="purchaseContainer">
					<div
						className={
							JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow &&
							includedDaysOfTheWeekArray == 0
								? 'd-none'
								: 'purchaseContainer'
						}
					>
						<div
							className={
								JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow
									? 'purchaseRow happy-hrs happy-hrs-Auidt align-self-start d-block lightBlue w-100 ml-2'
									: 'purchaseRow happy-hrs align-self-start d-block'
							}
						>
							{/* <Field type="checkbox" name="promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.DaysInclude" className={'form-check-input m-0 '} /> */}
							<input
								type="checkbox"
								checked={daysInclude}
								onChange={() => {
									setDaysInclude(!daysInclude);
									setFieldValue(
										'promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay',
										[]
									);
								}}
							/>
							<label htmlFor="" className="pl-3 font-weight-bold">
								Include<span className="checkmark"></span>
							</label>

							{daysInclude == true ? (
								<>
									<div className="text-danger">
										{props.errors &&
										typeof props?.errors?.eligibleDaysOfTheWeek
											?.includedDaysOfTheWeek?.includedDay == 'string'
											? props?.errors?.eligibleDaysOfTheWeek
													?.includedDaysOfTheWeek?.includedDay
											: null}
									</div>
									{/*   <FieldArray name="promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay">
              {({ insert, remove, push }) => (
                <>
                  {fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay.map((item, index) => (

                    <div className="d-flex flex-row happy-hours">
                      <div className="d-flex">
                        <CustomSelect name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay.${index}.day`} required={true} label={index==0? 'Day' : ''} options={getDaysDropdown()} />
                        {index == 0 ?<div className="happy-hours-plusBtn"> <button type="button" className="addPlusBtn bg-color h-75" onClick={() => {
                          if (fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay.length < 7) {
                            fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay.push(
                              {
                                "day": '0' + (fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay.length + 1),
                                "effectiveDuration": [{
                                  "effectiveStartTime": "00:00:00",
                                  "effectiveEndTime": "23:59:00"
                                }]
                              })
                          }
                          setFieldValue("","");

                        }}></button></div> :
                        <div className="happy-hours-deleteBtn"> <button type="button" className="addDeleteBtn bg-color" onClick={() => remove(index)}></button></div>}
                      </div>
                      <div>
                      <FieldArray name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay.${index}.effectiveDuration`}>
                        {({ insert, remove, push }) => (
                          <>
                            {fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay[index].effectiveDuration.map((item, i) => (
                              <div className="d-flex">
                                <div className="d-flex">
                                  <CustomHoursPicker name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay.${index}.effectiveDuration.${i}.effectiveStartTime`} required={true} label={index==0 && i==0? 'Start Time' : ''} 
                                  valueHour={fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay[index].effectiveDuration[i].effectiveStartTime}/>
                                </div>
                                <div className="d-flex align-items-center">
                                <CustomHoursPicker name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay.${index}.effectiveDuration.${i}.effectiveEndTime`} required={true} label={index==0 && i==0? 'End Time' : ''} 
                                valueHour={fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay[index].effectiveDuration[i].effectiveEndTime}/>
                                {i == 0 ? <div className="happy-hours-DateplusBtn">  <button type="button" className="addPlusBtn bg-color" onClick={() => {
                                    // if (fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay[index].effectiveDuration.length < 5) {
                                      fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay[index].effectiveDuration.push(
                                        {
                                          "effectiveStartTime": "00:00:00",
                                          "effectiveEndTime": "23:59:00"
                                        })
                                    // }
                                    setFieldValue("","");
                                   }}></button> </div> :
                                	  <div className="happy-hours-deleteBtn"> <button type="button" className="addDeleteBtn bg-color" onClick={() => remove(i)}></button></div>}

                              </div>
                              </div>
                            ))}</>)}</FieldArray>
                            </div>

                    </div>
                  ))}
                </>
              )}
            </FieldArray>*/}
									{JSON.parse(sessionStorage.getItem('typeOfOffersFlow'))
										.isAuditFlow ? (
										<div className="happyHourForAuditContainer">
											<div className="happyHourForAudit">
												{fieldHappyHours.eligibleDaysOfTheWeek
													?.includedDaysOfTheWeek?.includedDayAdded
													?.length > 0 ? (
													<div className="bg-white m-2 p-3 mt-2 addedSection">
														<label className="font-weight-bold">
															Added (
															{
																fieldHappyHours
																	.eligibleDaysOfTheWeek
																	?.includedDaysOfTheWeek
																	?.includedDayAdded?.length
															}
															)
														</label>
														<DaysOfTheWeekIncludedDay
															customArrayName="includedDayAdded"
															fieldHappyHours={
																field.value.promotionPolicy
																	.promotionApplicableEligibility
																	.happyHoursApplicableEligibility
															}
														/>
													</div>
												) : undefined}
												{fieldHappyHours.eligibleDaysOfTheWeek
													?.includedDaysOfTheWeek?.includedDayRemoved
													?.length > 0 ? (
													<div className="bg-white m-2 p-3 removedSection">
														<label className="font-weight-bold">
															Removed (
															{
																fieldHappyHours
																	.eligibleDaysOfTheWeek
																	?.includedDaysOfTheWeek
																	?.includedDayRemoved?.length
															}
															)
														</label>
														<DaysOfTheWeekIncludedDay
															customArrayName="includedDayRemoved"
															fieldHappyHours={
																field.value.promotionPolicy
																	.promotionApplicableEligibility
																	.happyHoursApplicableEligibility
															}
														/>
													</div>
												) : undefined}
											</div>
											{fieldHappyHours.eligibleDaysOfTheWeek
												?.includedDaysOfTheWeek?.includedDay?.length > 0 ? (
												<div className="bg-white m-2 p-3">
													<label className="font-weight-bold">
														Modified (
														{
															fieldHappyHours.eligibleDaysOfTheWeek
																?.includedDaysOfTheWeek?.includedDay
																?.length
														}
														)
													</label>
													<DaysOfTheWeekIncludedDay
														customArrayName="includedDay"
														fieldHappyHours={
															field.value.promotionPolicy
																.promotionApplicableEligibility
																.happyHoursApplicableEligibility
														}
													/>
												</div>
											) : undefined}
										</div>
									) : (
										<DaysOfTheWeekIncludedDay
											customArrayName="includedDay"
											fieldHappyHours={
												field.value.promotionPolicy
													.promotionApplicableEligibility
													.happyHoursApplicableEligibility
											}
										/>
									)}
								</>
							) : null}
						</div>
					</div>
					<div
						className={
							JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow &&
							excludedDaysOfTheWeekArray == 0
								? 'd-none'
								: 'purchaseContainer'
						}
					>
						<div
							className={
								JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow
									? 'purchaseRow happy-hrs happy-hrs-Auidt align-self-start d-block lightBlue w-100 ml-2'
									: 'purchaseRow happy-hrs align-self-start d-block'
							}
						>
							{/* <Field type="checkbox" name="promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.DaysExclude" className={'form-check-input m-0 '} /> */}
							<input
								type="checkbox"
								checked={daysExclude}
								onChange={() => {
									setDaysExclude(!daysExclude);
									setFieldValue(
										'promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay',
										[]
									);
								}}
							/>
							<label htmlFor="" className="pl-3 font-weight-bold">
								Exclude<span className="checkmark"></span>
							</label>
							{daysExclude == true ? (
								<>
									<div className="text-danger">
										{props.errors &&
										typeof props?.errors?.eligibleDaysOfTheWeek
											?.excludedDaysOfTheWeek?.excludedDay == 'string'
											? props?.errors?.eligibleDaysOfTheWeek
													?.excludedDaysOfTheWeek?.excludedDay
											: null}
									</div>
									{/* <FieldArray name="promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay">
              {({ insert, remove, push }) => (
                <>
                  {fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay.map((item, index) => (
                  
                    <div className="d-flex flex-row happy-hours">
                      <div className="d-flex">
                        <CustomSelect name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay.${index}.day`} required={true} label={index==0? 'Day' : ''} options={getDaysDropdown()} />
                         {index == 0 ?<div className="happy-hours-plusBtn"> <button type="button" className="addPlusBtn bg-color" onClick={() => {
                          if (fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay.length < 7) {
                            fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay.push(
                              {
                                "day": '0' + (fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay.length + 1),
                                "effectiveDuration": [{
                                  "effectiveStartTime": "00:00:00",
                                  "effectiveEndTime": "23:59:00"
                                }]
                              })
                          }
                          setFieldValue("","");
                        }}></button></div> :
                         <div className="happy-hours-deleteBtn"> <button type="button" className="addDeleteBtn bg-color" onClick={() => remove(index)}></button></div>}
                      </div>
                     <div>
                      <FieldArray name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay.${index}.effectiveDuration`}>
                        {({ insert, remove, push }) => (
                          <>
                            {fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay[index].effectiveDuration.map((item, i) => (
                              <div className="d-flex">
                                <div className="d-flex">
                                  <CustomHoursPicker name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay.${index}.effectiveDuration.${i}.effectiveStartTime`} required={true} label={index==0 && i==0? 'Start Time' : ''} 
                                  valueHour={fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay[index].effectiveDuration[i].effectiveStartTime}/>
                                 
                                </div>
                                <div className="d-flex align-items-center">
                                <CustomHoursPicker name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay.${index}.effectiveDuration.${i}.effectiveEndTime`} required={true} label={index==0 && i==0? 'End Time' : ''} 
                                valueHour={fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay[index].effectiveDuration[i].effectiveEndTime}/>
                                 {i == 0 ? <div className="happy-hours-DateplusBtn">  <button type="button" className="addPlusBtn bg-color h-75" onClick={() => {
                                    // if (fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay[index].effectiveDuration.length < 5) {
                                      fieldHappyHours.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay[index].effectiveDuration.push(
                                        {
                                          "effectiveStartTime": "00:00:00",
                                          "effectiveEndTime": "23:59:00"
                                        })
                                    // }
                                    setFieldValue("","");
                                   }}></button> </div> :
                                	  <div className="happy-hours-deleteBtn"> <button type="button" className="addDeleteBtn bg-color h-75" onClick={() => remove(i)}></button></div>}

                              </div>
                              </div>
                            ))}</>)}
                      </FieldArray>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </FieldArray> */}

									{JSON.parse(sessionStorage.getItem('typeOfOffersFlow'))
										.isAuditFlow ? (
										<div className="happyHourForAuditContainer">
											<div className="happyHourForAudit">
												{fieldHappyHours.eligibleDaysOfTheWeek
													?.excludedDaysOfTheWeek?.excludedDayAdded
													?.length > 0 ? (
													<div className="bg-white m-2 p-3 mt-2 addedSection">
														<label className="font-weight-bold">
															Added (
															{
																fieldHappyHours
																	.eligibleDaysOfTheWeek
																	?.excludedDaysOfTheWeek
																	?.excludedDayAdded?.length
															}
															)
														</label>
														<DaysOfTheWeekExcludedDay
															customArrayName="excludedDayAdded"
															fieldHappyHours={
																field.value.promotionPolicy
																	.promotionApplicableEligibility
																	.happyHoursApplicableEligibility
															}
														/>
													</div>
												) : undefined}
												{fieldHappyHours.eligibleDaysOfTheWeek
													?.excludedDaysOfTheWeek?.excludedDayRemoved
													?.length > 0 ? (
													<div className="bg-white m-2 p-3 removedSection">
														<label className="font-weight-bold">
															Removed (
															{
																fieldHappyHours
																	.eligibleDaysOfTheWeek
																	?.excludedDaysOfTheWeek
																	?.excludedDayRemoved?.length
															}
															)
														</label>
														<DaysOfTheWeekExcludedDay
															customArrayName="excludedDayRemoved"
															fieldHappyHours={
																field.value.promotionPolicy
																	.promotionApplicableEligibility
																	.happyHoursApplicableEligibility
															}
														/>
													</div>
												) : undefined}
											</div>
											{fieldHappyHours.eligibleDaysOfTheWeek
												?.excludedDaysOfTheWeek?.excludedDay?.length > 0 ? (
												<div className="bg-white m-2 p-3">
													<label className="font-weight-bold">
														Modified (
														{
															fieldHappyHours.eligibleDaysOfTheWeek
																?.excludedDaysOfTheWeek?.excludedDay
																?.length
														}
														)
													</label>
													<DaysOfTheWeekExcludedDay
														customArrayName="excludedDay"
														fieldHappyHours={
															field.value.promotionPolicy
																.promotionApplicableEligibility
																.happyHoursApplicableEligibility
														}
													/>
												</div>
											) : undefined}
										</div>
									) : (
										<DaysOfTheWeekExcludedDay
											customArrayName="excludedDay"
											fieldHappyHours={
												field.value.promotionPolicy
													.promotionApplicableEligibility
													.happyHoursApplicableEligibility
											}
										/>
									)}
								</>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<div
				className={
					JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow &&
					includedDatesOfTheMonthArray + excludedDatesOfTheMonthArray == 0
						? 'd-none'
						: 'purchaseContainer flexColumn'
				}
			>
				<label className="font-weight-bold pl-10">Date of the month</label>
				<div className="purchaseContainer">
					<div
						className={
							JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow &&
							includedDatesOfTheMonthArray == 0
								? 'd-none'
								: 'purchaseContainer'
						}
					>
						<div
							className={
								JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow
									? 'purchaseRow happy-hrs happy-hrs-Auidt align-self-start d-block lightBlue w-100 ml-2'
									: 'purchaseRow happy-hrs align-self-start d-block'
							}
						>
							{/* <Field type="checkbox" name="promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.DateInclude" className={'form-check-input m-0 '} /> */}
							<input
								type="checkbox"
								checked={dateInclude}
								onChange={() => {
									setDateInclude(!dateInclude);
									setFieldValue(
										'promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate',
										[]
									);
								}}
							/>
							<label htmlFor="" className="pl-3 font-weight-bold">
								Include<span className="checkmark"></span>
							</label>
							{dateInclude == true ? (
								<>
									<div className="text-danger">
										{props.errors &&
										typeof props?.errors?.eligibleDatesOfTheMonth
											?.includedDatesOfTheMonth?.includedDate == 'string'
											? props?.errors?.eligibleDatesOfTheMonth
													?.includedDatesOfTheMonth?.includedDate
											: null}
									</div>

									{/*  <FieldArray name="promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate">
              {({ insert, remove, push }) => (
                <>
                  {fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate.map((item, index) => (

                    <div className="d-flex flex-row happy-hours">
                      <div className="d-flex">
                        <CustomSelect name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate.${index}.date`} required={true} label={index==0? 'Date' : ''} options={getDatesDropdown()} />
                        {index == 0 ?<div className="happy-hours-plusBtn"><button type="button" className="addPlusBtn bg-color h-75" onClick={() => {
                          if (fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate.length < 7) {
                            fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate.push(
                              {
                                "date": '0' + (fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate.length + 1),
                                "effectiveDuration": [{
                                  "effectiveStartTime": "00:00:00",
                                  "effectiveEndTime": "23:59:00"
                                }]
                              })
                          }
                          setFieldValue("","");
                        }}></button></div> :
                          <div className="happy-hours-deleteBtn"><button type="button" className="addDeleteBtn bg-color h-75" onClick={() => remove(index)}></button></div>}
                      </div>
                      <div>
                      <FieldArray name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate.${index}.effectiveDuration`}>
                        {({ insert, remove, push }) => (
                          <>
                            {fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate[index].effectiveDuration.map((item, i) => (
                              <div className="d-flex">
                                <div className="d-flex">
                                  <CustomHoursPicker name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate.${index}.effectiveDuration.${i}.effectiveStartTime`} required={true} label={index==0 && i==0? 'Start Time' : ''} 
                                  valueHour={fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate[index].effectiveDuration[i].effectiveStartTime}/>
                                 
                                </div>
                                <div className="d-flex align-items-center">
                                <CustomHoursPicker name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate.${index}.effectiveDuration.${i}.effectiveEndTime`} required={true} label={index==0 && i==0? 'End Time' : ''} 
                                valueHour={fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate[index].effectiveDuration[i].effectiveEndTime}/>
                                {i == 0 ? <div className="happy-hours-DateplusBtn">  <button type="button" className="addPlusBtn bg-color h-75" onClick={() => {
                                    // if (fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate[index].effectiveDuration.length < 5) {
                                       fieldHappyHours.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate[index].effectiveDuration.push(
                                        {
                                          "effectiveStartTime": "00:00:00",
                                          "effectiveEndTime": "23:59:00"
                                        })
                                    // }
                                    setFieldValue("","");
                                }}></button> </div> :
                                	  <div className="happy-hours-deleteBtn"> <button type="button" className="addDeleteBtn bg-color h-75" onClick={() => remove(i)}></button> </div>}

                              </div>
                              </div>
                            ))}</>)}
                      </FieldArray>
                      </div>

                    </div>
                  ))}
                </>
              )}
            </FieldArray> */}

									{JSON.parse(sessionStorage.getItem('typeOfOffersFlow'))
										.isAuditFlow ? (
										<div className="happyHourForAuditContainer">
											<div className="happyHourForAudit">
												{fieldHappyHours.eligibleDatesOfTheMonth
													?.includedDatesOfTheMonth?.includedDateAdded
													?.length > 0 ? (
													<div className="bg-white m-2 p-3 mt-2 addedSection">
														<label className="font-weight-bold">
															Added (
															{
																fieldHappyHours
																	.eligibleDatesOfTheMonth
																	?.includedDatesOfTheMonth
																	?.includedDateAdded?.length
															}
															)
														</label>
														<DaysOfTheMonthIncludedDay
															customArrayName="includedDateAdded"
															fieldHappyHours={
																field.value.promotionPolicy
																	.promotionApplicableEligibility
																	.happyHoursApplicableEligibility
															}
														/>
													</div>
												) : undefined}
												{fieldHappyHours.eligibleDatesOfTheMonth
													?.includedDatesOfTheMonth?.includedDateRemoved
													?.length > 0 ? (
													<div className="bg-white m-2 p-3 removedSection">
														<label className="font-weight-bold">
															Removed (
															{
																fieldHappyHours
																	.eligibleDatesOfTheMonth
																	?.includedDatesOfTheMonth
																	?.includedDateRemoved?.length
															}
															)
														</label>
														<DaysOfTheMonthIncludedDay
															customArrayName="includedDateRemoved"
															fieldHappyHours={
																field.value.promotionPolicy
																	.promotionApplicableEligibility
																	.happyHoursApplicableEligibility
															}
														/>
													</div>
												) : undefined}
											</div>
											{fieldHappyHours.eligibleDatesOfTheMonth
												?.includedDatesOfTheMonth?.includedDate?.length >
											0 ? (
												<div className="bg-white m-2 p-3">
													<label className="font-weight-bold">
														Modified (
														{
															fieldHappyHours.eligibleDatesOfTheMonth
																?.includedDatesOfTheMonth
																?.includedDate?.length
														}
														)
													</label>
													<DaysOfTheMonthIncludedDay
														customArrayName="includedDate"
														fieldHappyHours={
															field.value.promotionPolicy
																.promotionApplicableEligibility
																.happyHoursApplicableEligibility
														}
													/>
												</div>
											) : undefined}
										</div>
									) : (
										<DaysOfTheMonthIncludedDay
											customArrayName="includedDate"
											fieldHappyHours={
												field.value.promotionPolicy
													.promotionApplicableEligibility
													.happyHoursApplicableEligibility
											}
										/>
									)}
								</>
							) : null}
						</div>
					</div>
					<div
						className={
							JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow &&
							excludedDatesOfTheMonthArray == 0
								? 'd-none'
								: 'purchaseContainer'
						}
					>
						<div
							className={
								JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow
									? 'purchaseRow happy-hrs happy-hrs-Auidt align-self-start d-block lightBlue w-100 ml-2'
									: 'purchaseRow happy-hrs align-self-start d-block'
							}
						>
							{/* <Field type="checkbox" name="promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.DateExclude" className={'form-check-input m-0 '} /> */}
							<input
								type="checkbox"
								checked={dateExclude}
								onChange={() => {
									setDateExclude(!dateExclude);
									setFieldValue(
										'promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate',
										[]
									);
								}}
							/>
							<label htmlFor="" className="pl-3 font-weight-bold">
								Exclude<span className="checkmark"></span>
							</label>

							{dateExclude == true ? (
								<>
									<div className="text-danger">
										{props.errors &&
										typeof props?.errors?.eligibleDatesOfTheMonth
											?.excludedDatesOfTheMonth?.excludedDate == 'string'
											? props?.errors?.eligibleDatesOfTheMonth
													?.excludedDatesOfTheMonth?.excludedDate
											: null}
									</div>
									{/* <FieldArray name="promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate">
              {({ insert, remove, push }) => (
                <>
                  {fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.map((item, index) => (

                    <div className="d-flex flex-row happy-hours">
                      <div className="d-flex">
                        <CustomSelect name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.${index}.date`} required={true} label={index==0? 'Date' : ''} options={getDatesDropdown()} />
                        {index == 0 ?<div className="happy-hours-plusBtn"> <button type="button" className="addPlusBtn bg-color h-75" onClick={() => {
                          if (fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.length < 7) {
                            fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.push(
                              {
                                "date": '0' + (fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.length + 1),
                                "effectiveDuration": [{
                                  "effectiveStartTime": "00:00:00",
                                  "effectiveEndTime": "23:59:00"
                                }]
                              })
                          }
                          setFieldValue("","");
                        }}></button></div> :
                        <div className="happy-hours-deleteBtn"><button type="button" className="addDeleteBtn bg-color h-75" onClick={() => remove(index)}></button></div>}
                      </div>
                     <div>
                      <FieldArray name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.${index}.effectiveDuration`}>
                        {({ insert, remove, push }) => (
                          <>
                            {fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate[index].effectiveDuration.map((item, i) => (
                              <div className="d-flex">
                                <div className="d-flex">
                                  <CustomHoursPicker name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.${index}.effectiveDuration.${i}.effectiveStartTime`} required={true} label={index==0 && i==0? 'Start Time' : ''} 
                                  valueHour={fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate[index].effectiveDuration[i].effectiveStartTime}/>
                                 
                                </div>
                                <div className="d-flex align-items-center">
                                <CustomHoursPicker name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.${index}.effectiveDuration.${i}.effectiveEndTime`} required={true} label={index==0 && i==0? 'End Time' : ''}
                                valueHour={fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate[index].effectiveDuration[i].effectiveEndTime} />
                                {i == 0 ? <div className="happy-hours-DateplusBtn">  <button type="button" className="addPlusBtn bg-color h-75" onClick={() => {
                                    // if (fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate[index].effectiveDuration.length < 5) {
                                      fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate[index].effectiveDuration.push(
                                        {
                                          "effectiveStartTime": "00:00:00",
                                          "effectiveEndTime": "23:59:00"
                                        })
                                    // }
                                    setFieldValue("","");
                                  }}></button></div> :
                                	  <div className="happy-hours-deleteBtn">  <button type="button" className="addDeleteBtn bg-color h-75" onClick={() => remove(i)}></button> </div>}

                              </div>
                              </div>
                            ))}</>)}
                      </FieldArray>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </FieldArray> */}

									{/*  {JSON.parse(sessionStorage.getItem("typeOfOffersFlow")).isAuditFlow?
            <div className="happyHourForAuditContainer">
         
            <div className="happyHourForAudit">
            {fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDateAdded?.length >0 ? 
            <div className="bg-white m-4 p-3 mt-2">
            <label className="font-weight-bold">Added ({fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDateAdded.length})</label>
            <DaysOfTheMonthExcludedDate customArrayName="excludedDateAdded" fieldHappyHours = {field.value.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility}/>
            </div>
            :undefined}
            {fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDateRemoved?.length >0 ? 
            <div className="bg-white m-4 p-3">
            <label className="font-weight-bold">Removed ({fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDateRemoved.length})</label>
            <DaysOfTheMonthExcludedDate customArrayName="excludedDateRemoved" fieldHappyHours = {field.value.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility}/>
            </div>
            :undefined}
            </div> 
            {fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDate?.length > 0 ?
             <div className="bg-white m-4 p-3">
            <label className="font-weight-bold">Modified ({fieldHappyHours.eligibleDatesOfTheMonth?.excludedDatesOfTheMonth?.excludedDate.length})</label>
            <DaysOfTheMonthExcludedDate customArrayName="excludedDate" fieldHappyHours = {field.value.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility}/>
            </div>
            : undefined}
            
            </div>
            :
 <DaysOfTheMonthExcludedDate customArrayName="excludedDate" fieldHappyHours = {field.value.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility}/> 
 }
 */}

									{JSON.parse(sessionStorage.getItem('typeOfOffersFlow'))
										.isAuditFlow ? (
										<div className="happyHourForAuditContainer">
											<div className="happyHourForAudit">
												{fieldHappyHours.eligibleDatesOfTheMonth
													?.excludedDatesOfTheMonth?.excludedDateAdded &&
												fieldHappyHours.eligibleDatesOfTheMonth
													?.excludedDatesOfTheMonth?.excludedDateAdded
													?.length > 0 ? (
													<div className="bg-white m-2 p-3 mt-2 addedSection">
														<label className="font-weight-bold">
															Added (
															{
																fieldHappyHours
																	.eligibleDatesOfTheMonth
																	?.excludedDatesOfTheMonth
																	?.excludedDateAdded?.length
															}
															)
														</label>
														<DaysOfTheMonthExcludedDate
															customArrayName="excludedDateAdded"
															fieldHappyHours={
																field.value.promotionPolicy
																	.promotionApplicableEligibility
																	.happyHoursApplicableEligibility
															}
														/>
													</div>
												) : undefined}
												{fieldHappyHours.eligibleDatesOfTheMonth
													?.excludedDatesOfTheMonth
													?.excludedDateRemoved &&
												fieldHappyHours.eligibleDatesOfTheMonth
													?.excludedDatesOfTheMonth?.excludedDateRemoved
													?.length > 0 ? (
													<div className="bg-white m-2 p-3 removedSection">
														<label className="font-weight-bold">
															Removed (
															{
																fieldHappyHours
																	.eligibleDatesOfTheMonth
																	?.excludedDatesOfTheMonth
																	?.excludedDateRemoved?.length
															}
															)
														</label>
														<DaysOfTheMonthExcludedDate
															customArrayName="excludedDateRemoved"
															fieldHappyHours={
																field.value.promotionPolicy
																	.promotionApplicableEligibility
																	.happyHoursApplicableEligibility
															}
														/>
													</div>
												) : undefined}
											</div>
											{fieldHappyHours.eligibleDatesOfTheMonth
												?.excludedDatesOfTheMonth?.excludedDate &&
											fieldHappyHours.eligibleDatesOfTheMonth
												?.excludedDatesOfTheMonth?.excludedDate?.length >
												0 ? (
												<div className="bg-white m-2 p-3">
													<label className="font-weight-bold">
														Modified (
														{
															fieldHappyHours.eligibleDatesOfTheMonth
																?.excludedDatesOfTheMonth
																?.excludedDate?.length
														}
														)
													</label>
													<DaysOfTheMonthExcludedDate
														customArrayName="excludedDate"
														fieldHappyHours={
															field.value.promotionPolicy
																.promotionApplicableEligibility
																.happyHoursApplicableEligibility
														}
													/>
												</div>
											) : undefined}
										</div>
									) : (
										<DaysOfTheMonthExcludedDate
											customArrayName="excludedDate"
											fieldHappyHours={
												field.value.promotionPolicy
													.promotionApplicableEligibility
													.happyHoursApplicableEligibility
											}
										/>
									)}
								</>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<label className="font-weight-bold pl-10">Blackout Period</label>
			{/* <div className="box-blackout-period"> */}
			{isAuditFlw ? (
				<>
					<div
						className={
							fieldHappyHours?.blackOutAdded?.length == 0 &&
							fieldHappyHours?.blackOutRemoved?.length == 0 &&
							JSON.parse(sessionStorage.getItem('NewPolicy')).promotionPolicy
								.promotionApplicableEligibility?.happyHoursApplicableEligibility
								?.blackOutPeriod.length == 0
								? 'd-none'
								: 'box-blackout-period lightBlue'
						}
					>
						<div
							className={
								fieldHappyHours && fieldHappyHours?.blackOutAdded?.length > 0
									? 'issuerOuterBox mb-3 addedSection auditHide'
									: 'd-none'
							}
						>
							<label className="font-weight-bold">
								Added({fieldHappyHours?.blackOutAdded?.length})
							</label>
							<BlackOutPeriod blackOutType="blackOutAdded" />
						</div>
						<div
							className={
								fieldHappyHours && fieldHappyHours?.blackOutRemoved?.length > 0
									? 'issuerOuterBox mb-3 removedSection auditHide'
									: 'd-none'
							}
						>
							<label className="font-weight-bold">
								Removed({fieldHappyHours?.blackOutRemoved?.length})
							</label>
							<BlackOutPeriod blackOutType="blackOutRemoved" />
						</div>
						<div
							className={
								JSON.parse(sessionStorage.getItem('NewPolicy')).promotionPolicy
									.promotionApplicableEligibility?.happyHoursApplicableEligibility
									?.blackOutPeriod.length > 0
									? 'issuerOuterBox mb-3'
									: 'd-none'
							}
						>
							<label className="font-weight-bold">
								Modified({fieldHappyHours?.blackOutPeriod?.length})
							</label>
							<BlackOutPeriod blackOutType="blackOutPeriod" />
						</div>
					</div>
				</>
			) : (
				<div className="box-blackout-period">
					<BlackOutPeriod blackOutType="blackOutPeriod" />
				</div>
			)}

			{/* </div> */}
		</div>
	);
};

export default HappyHours;
