import React, { useEffect } from 'react';

import { useField, useFormikContext } from 'formik';
import CustomInputText from '../customfields/CustomInputText';
import CustomSelect from '../customfields/CustomSelect';
import $ from 'jquery';

const groupCombinationCode = {
	groupTriggerCombinationCode: [
		'PACKAGE_INTERVAL_QTY_THRESHOLD',
		'PACKAGE_AMOUNT_THRESHOLD',
		'PACKAGE_RANGE_QTY_THRESHOLD',
		'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD'
	],
	groupRewardCombinationCode: [
		'PACKAGE_INTERVAL_QTY_THRESHOLD',
		'PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
		'PACKAGE_RANGE_QTY_THRESHOLD',
		'PACKAGE_RANGE_AMOUNT_THRESHOLD',
		'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD',
		'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD'
	]
};

const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	triggerCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Specifies the combination code to be used between the trigger items for a promotion',
	thresholdQuantity: 'Group Threshold Quantity',
	thresholdAmount: 'Group Threshold Amount',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Specifies the combination code to be used between the trigger items for a promotion',
	rewardQuantity: 'Group Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	quantityLimit: 'Quantity Limit',
	groupMaximumThresholdAmount: 'Group Maximum Threshold Amount',
	groupMaximumThresholdQuantity: 'Group Maximum Threshold Quantity'
};

const rewardCombinationDropdown = {
	promotionTypeOr: [
		{
			value: 'OR',
			name: 'OR'
		}
	],
	promotionTypeOrAndEqual: [
		{
			value: 'OR',
			name: 'OR'
		},
		{
			value: 'AND',
			name: 'AND'
		},
		{
			value: 'EQUAL',
			name: 'EQUAL'
		}
	],
	promotionTypeOrAnd: [
		{
			value: 'OR',
			name: 'OR'
		},
		{
			value: 'AND',
			name: 'AND'
		}
	],
	operator: [
		{
			value: '',
			name: 'Select one'
		},
		{
			value: '<',
			name: '<'
		},
		{
			value: '>',
			name: '>'
		},
		{
			value: '<=',
			name: '<='
		},
		{
			value: '>=',
			name: '>='
		},
		{
			value: '=',
			name: '='
		},
		{
			value: 'BETWEEN',
			name: 'BETWEEN'
		}
	]
};

const getGroupCombinationCode = ({ formikContext, field, thresholdTypeCode, index }) => {
	const { initialValues, values } = formikContext;
	const initVal = initialValues.mtmbEligibility?.packageMultibuyEligibility?.[index]?.[field];
	const val = values.mtmbEligibility.packageMultibuyEligibility[index][field];
	let finalVal = initVal ?? 'OR';

	if (groupCombinationCode?.[field].includes(thresholdTypeCode)) {
		/* set val OR/AND */
		finalVal = val ?? 'OR';
	} else {
		/* set value OR */
		finalVal = 'OR';
	}

	return finalVal;
};

const getThresholdDropdown = (thresholdTypeCodeOptions) => {
	return thresholdTypeCodeOptions.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const ConditionalRederingMTMB = (props) => {
	const { value, index, customName } = props;
	//packageMultibuyEligibility[index].mtmbthresholdTypeCode
	const [field] = useField(props);
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const fieldCR = field.value.mtmbEligibility;
	const fieldMTMBPackage =
		customName == 'packageMultibuyEligibilityAdded'
			? fieldCR.packageMultibuyEligibilityAdded
			: customName == 'packageMultibuyEligibilityRemoved'
			? fieldCR.packageMultibuyEligibilityRemoved
			: fieldCR.packageMultibuyEligibility;

	const formikContext = useFormikContext();

	useEffect(() => {
		// if (!fieldMTMBPackage[index].groupTriggerCombinationCode) { /* check with OR/AND  this is default value*/
		// 	fieldMTMBPackage[index].groupTriggerCombinationCode = 'OR';
		// }
		// if (!fieldMTMBPackage[index].groupRewardCombinationCode) { /* check with OR/AND  this is default value */
		// 	fieldMTMBPackage[index].groupRewardCombinationCode = 'OR';
		// }
		fieldMTMBPackage[index].groupTriggerCombinationCode = getGroupCombinationCode({
			formikContext,
			index,
			field: 'groupTriggerCombinationCode',
			thresholdTypeCode: value
		});
		fieldMTMBPackage[index].groupRewardCombinationCode = getGroupCombinationCode({
			formikContext,
			index,
			field: 'groupRewardCombinationCode',
			thresholdTypeCode: value
		});
		if (!fieldMTMBPackage[index].groupRewardQuantity) {
			fieldMTMBPackage[index].groupRewardQuantity = 1;
		}

		if (
			value == 'PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			value == 'PACKAGE_QTY_THRESHOLD' ||
			value == 'PACKAGE_RANGE_QTY_THRESHOLD'
		) {
			if (!fieldMTMBPackage[index].groupThresholdQuantity) {
				fieldMTMBPackage[index].groupThresholdQuantity = 1;
			}
			fieldMTMBPackage[index].groupThresholdAmount = undefined;
			fieldMTMBPackage[index].quantityLimit = undefined;
			/* fieldMTMBPackage[
        index
      ].groupMaximumThresholdQuantity = undefined; */
			fieldMTMBPackage[index].groupMaximumThresholdAmount = undefined;
		}

		if (value == 'PACKAGE_LIMIT_QTY_THRESHOLD') {
			if (!fieldMTMBPackage[index].groupThresholdQuantity) {
				fieldMTMBPackage[index].groupThresholdQuantity = 1;
			}
			fieldMTMBPackage[index].groupThresholdAmount = undefined;
			//fieldMTMBPackage[index].quantityLimit = undefined;
			fieldMTMBPackage[index].groupMaximumThresholdQuantity = undefined;
			fieldMTMBPackage[index].groupMaximumThresholdAmount = undefined;
		}
		if (
			value == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			value == 'PACKAGE_AMOUNT_THRESHOLD' ||
			value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD'
		) {
			if (!fieldMTMBPackage[index].groupThresholdAmount) {
				fieldMTMBPackage[index].groupThresholdAmount = 0.01;
			}
			fieldMTMBPackage[index].groupThresholdQuantity = undefined;
			fieldMTMBPackage[index].quantityLimit = undefined;
			fieldMTMBPackage[index].groupMaximumThresholdQuantity = undefined;
			if (value != 'PACKAGE_RANGE_AMOUNT_THRESHOLD') {
				fieldMTMBPackage[index].groupMaximumThresholdAmount = undefined;
			}
		}

		if (value == 'PACKAGE_LIMIT_QTY_THRESHOLD') {
			if (!fieldMTMBPackage[index].quantityLimit) {
				fieldMTMBPackage[index].quantityLimit = 1;
			}
		}
		if (value == 'PACKAGE_RANGE_QTY_THRESHOLD') {
			if (!fieldMTMBPackage[index].groupMaximumThresholdQuantity) {
				fieldMTMBPackage[index].groupMaximumThresholdQuantity = 999999;
			}
		}
		if (value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD') {
			if (!fieldMTMBPackage[index].groupMaximumThresholdAmount) {
				fieldMTMBPackage[index].groupMaximumThresholdAmount = 0.01;
			}
		}
		if (
			[
				'PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
				'PACKAGE_AMOUNT_THRESHOLD',
				'PACKAGE_RANGE_AMOUNT_THRESHOLD',
				'PACKAGE_INTERVAL_QTY_THRESHOLD',
				'PACKAGE_QTY_THRESHOLD',
				'PACKAGE_RANGE_QTY_THRESHOLD',
				'PACKAGE_LIMIT_QTY_THRESHOLD',
				'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD',
				'HIERARCHY_PACKAGE_QTY_THRESHOLD',
				'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
				'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
			].includes(value)
		) {
			if (!fieldMTMBPackage[index].group) {
				fieldMTMBPackage[index].group = [
					{
						multibuyProduct: [],
						trigger: true,
						getReward: true
					}
				];
			}
		}
	}, [value]);

	useEffect(() => {
		if (
			fieldMTMBPackage[index].triggerOperator == '<' ||
			fieldMTMBPackage[index].triggerOperator == '>' ||
			fieldMTMBPackage[index].triggerOperator == '<=' ||
			fieldMTMBPackage[index].triggerOperator == '>=' ||
			fieldMTMBPackage[index].triggerOperator == '='
		) {
			fieldMTMBPackage[index].triggerOperatorValueTo = undefined;
			fieldMTMBPackage[index].triggerOperatorValueFrom = undefined;
		} else if (fieldMTMBPackage[index].triggerOperator == 'BETWEEN') {
			fieldMTMBPackage[index].triggerOperatorValue = undefined;
		} else {
			fieldMTMBPackage[index].triggerOperatorValueTo = undefined;
			fieldMTMBPackage[index].triggerOperatorValueFrom = undefined;
			fieldMTMBPackage[index].triggerOperatorValue = undefined;
		}
	}, [fieldMTMBPackage[index].triggerOperator]);

	useEffect(() => {
		if (
			fieldMTMBPackage[index].rewardOperator == '<' ||
			fieldMTMBPackage[index].rewardOperator == '>' ||
			fieldMTMBPackage[index].rewardOperator == '<=' ||
			fieldMTMBPackage[index].rewardOperator == '>=' ||
			fieldMTMBPackage[index].rewardOperator == '='
		) {
			fieldMTMBPackage[index].rewardOperatorValueTo = undefined;
			fieldMTMBPackage[index].rewardOperatorValueFrom = undefined;
		} else if (fieldMTMBPackage[index].rewardOperator == 'BETWEEN') {
			fieldMTMBPackage[index].rewardOperatorValue = undefined;
		} else {
			fieldMTMBPackage[index].rewardOperatorValueTo = undefined;
			fieldMTMBPackage[index].rewardOperatorValueFrom = undefined;
			fieldMTMBPackage[index].rewardOperatorValue = undefined;
		}
	}, [fieldMTMBPackage[index].rewardOperator]);
	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
	useEffect(() => {
		if (
			JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow &&
			customName == 'packageMultibuyEligibility'
		) {
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
					.packageMultibuyEligibility[index].thresholdTypeCode !=
				fieldMTMBPackage[index].thresholdTypeCode
			) {
				let tempName = `mtmbEligibility.packageMultibuyEligibility.${index}.thresholdTypeCode`;
				$('[name="' + tempName + '"]')
					.closest('.container')
					.parent()
					.nextAll()
					.addClass('d-none');
			}
		}
	}, []);
	//html
	return (
		<div
			className={
				isAuditFlw &&
				!(customName.indexOf('Added') !== -1 || customName.indexOf('Removed') !== -1)
					? 'purchaseContainer d-block'
					: customName.indexOf('Added') !== -1 || customName.indexOf('Removed') !== -1
					? 'purchaseContainer d-flex flexRow'
					: 'purchaseContainer'
			}
		>
			<div className="purchaseRow">
				<div className="font-weight-bold">Purchase Conditions</div>
				{/* <FormController
          control="select"
          lable={localConst.triggerCombinationCode}
          name={`mtmbEligibility.${customName}.${index}.groupTriggerCombinationCode`}
          required={true}
          options={
            value == "PACKAGE_INTERVAL_QTY_THRESHOLD" ||
            value == "PACKAGE_AMOUNT_THRESHOLD" ||
            value == "PACKAGE_RANGE_QTY_THRESHOLD" ||
            value == "HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD"
              ? rewardCombinationDropdown.promotionTypeOrAnd
              : rewardCombinationDropdown.promotionTypeOr
          }
          smallText={localConst.subTriggerCombinationCode}
          className="customDropdown inlinePadding"
        /> */}
				<CustomSelect
					name={`mtmbEligibility.${customName}.${index}.groupTriggerCombinationCode`}
					required={true}
					label={localConst.triggerCombinationCode}
					options={
						[
							'PACKAGE_INTERVAL_QTY_THRESHOLD',
							'PACKAGE_AMOUNT_THRESHOLD',
							'PACKAGE_RANGE_QTY_THRESHOLD',
							'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD'
						].includes(value)
							? getThresholdDropdown(rewardCombinationDropdown.promotionTypeOrAnd)
							: getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)
					}
					smallText={localConst.subTriggerCombinationCode}
				/>

				{value == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ? undefined : (
					<CustomInputText
						name={
							value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD' ||
							value == 'PACKAGE_AMOUNT_THRESHOLD' ||
							value == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD'
								? `mtmbEligibility.${customName}.${index}.groupThresholdAmount`
								: `mtmbEligibility.${customName}.${index}.groupThresholdQuantity`
						}
						required={true}
						label={
							value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD' ||
							value == 'PACKAGE_AMOUNT_THRESHOLD' ||
							value == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD'
								? localConst.thresholdAmount
								: localConst.thresholdQuantity
						}
						smallText=""
						disabled={false}
					/>
				)}
				{value == 'PACKAGE_RANGE_QTY_THRESHOLD' ||
				value == 'PACKAGE_RANGE_AMOUNT_THRESHOLD' ? (
					value == 'PACKAGE_RANGE_QTY_THRESHOLD' ? (
						<CustomInputText
							name={`mtmbEligibility.${customName}.${index}.groupMaximumThresholdQuantity`}
							required={true}
							label={localConst.groupMaximumThresholdQuantity}
							smallText=""
							disabled={false}
						/>
					) : (
						<CustomInputText
							name={`mtmbEligibility.${customName}.${index}.groupMaximumThresholdAmount`}
							required={true}
							label={localConst.groupMaximumThresholdAmount}
							smallText=""
							disabled={false}
						/>
					)
				) : undefined}
				{value == 'PACKAGE_LIMIT_QTY_THRESHOLD' ? (
					<CustomInputText
						name={`mtmbEligibility.${customName}.${index}.quantityLimit`}
						required={true}
						label={localConst.quantityLimit}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				<CustomSelect
					name={`mtmbEligibility.${customName}.${index}.triggerOperator`}
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{fieldMTMBPackage[index].triggerOperator == '<' ||
				fieldMTMBPackage[index].triggerOperator == '>' ||
				fieldMTMBPackage[index].triggerOperator == '<=' ||
				fieldMTMBPackage[index].triggerOperator == '>=' ||
				fieldMTMBPackage[index].triggerOperator == '=' ? (
					<CustomInputText
						name={`mtmbEligibility.${customName}.${index}.triggerOperatorValue`}
						// required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{fieldMTMBPackage[index].triggerOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${index}.triggerOperatorValueFrom`}
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${index}.triggerOperatorValueTo`}
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>

			<div className="purchaseRow">
				<div className="font-weight-bold">Reward Conditions</div>
				{/* <FormController
          control="select"
          lable={localConst.rewardCombinationCode}
          name={`mtmbEligibility.${customName}.${index}.groupRewardCombinationCode`}
          required={true}
          options={
            value == "PACKAGE_INTERVAL_QTY_THRESHOLD" ||
            value == "PACKAGE_INTERVAL_AMOUNT_THRESHOLD" ||
            value == "PACKAGE_RANGE_QTY_THRESHOLD" ||
            value == "PACKAGE_RANGE_AMOUNT_THRESHOLD" ||
            value == "HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD" ||
            value == "HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD"
              ? rewardCombinationDropdown.promotionTypeOrAnd
              : rewardCombinationDropdown.promotionTypeOr
          }
          smallText={localConst.subRewardCombinationCode}
          className="customDropdown inlinePadding"
        /> */}
				<CustomSelect
					name={`mtmbEligibility.${customName}.${index}.groupRewardCombinationCode`}
					required={true}
					label={localConst.rewardCombinationCode}
					options={
						[
							'PACKAGE_INTERVAL_QTY_THRESHOLD',
							'PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
							'PACKAGE_RANGE_QTY_THRESHOLD',
							'PACKAGE_RANGE_AMOUNT_THRESHOLD',
							'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD',
							'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD'
						].includes(value)
							? getThresholdDropdown(rewardCombinationDropdown.promotionTypeOrAnd)
							: getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)
					}
					smallText={localConst.subRewardCombinationCode}
				/>
				{value == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
				value == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ? undefined : (
					<CustomInputText
						name={`mtmbEligibility.${customName}.${index}.groupRewardQuantity`}
						required={true}
						label={localConst.rewardQuantity}
						smallText=""
						disabled={false}
					/>
				)}
				<CustomSelect
					name={`mtmbEligibility.${customName}.${index}.rewardOperator`}
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{fieldMTMBPackage[index].rewardOperator == '<' ||
				fieldMTMBPackage[index].rewardOperator == '>' ||
				fieldMTMBPackage[index].rewardOperator == '<=' ||
				fieldMTMBPackage[index].rewardOperator == '>=' ||
				fieldMTMBPackage[index].rewardOperator == '=' ? (
					<CustomInputText
						name={`mtmbEligibility.${customName}.${index}.rewardOperatorValue`}
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{fieldMTMBPackage[index].rewardOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${index}.rewardOperatorValueFrom`}
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name={`mtmbEligibility.${customName}.${index}.rewardOperatorValueTo`}
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>
		</div>
	);
};
export default ConditionalRederingMTMB;
