import React from 'react';
import PropTypes from 'prop-types';

import toaster from 'toasted-notes';
import './toaster.scss';

const toasterType = {
	info: 'bg-success',
	warn: 'bg-warning',
	error: 'toasterRed'
};

const Toaster = ({
	className = '',
	message = '',
	position = 'top',
	duration = 10000,
	type = 'error',
	closeAll
}) => {
	if (!message) {
		return null;
	}
	closeAll && toaster.closeAll();
	const toasterTypeClass = toasterType[type] || toasterType.error;
	return toaster.notify(
		({ onClose }) => (
			<div className={`Toaster-message-wrapper ${className}`}>
				<div>
					<div id="1" className={`Toaster__alert ${toasterTypeClass}`}>
						<div className="Toaster__alert_text">{message}</div>
						<button
							className="Toaster__alert_close"
							type="button"
							aria-label="Close"
							onClick={onClose}
						>
							<span aria-hidden="true">×</span>
						</button>
					</div>
				</div>
			</div>
		),
		{
			position,
			duration
		}
	);
};

Toaster.defaultProps = {
	className: '',
	message: '',
	position: 'top',
	duration: 10000,
	type: 'error',
	closeAll: false
};

Toaster.propTypes = {
	message: PropTypes.string,
	className: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
	duration: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.bool
	]) /* This notification will not automatically close  */,
	position: PropTypes.oneOf([
		'top-left',
		'top',
		'top-right',
		'bottom-left',
		'bottom',
		'bottom-right'
	]),
	type: PropTypes.oneOf(['info', 'warn', 'error']),
	closeAll: PropTypes.bool
};

export default Toaster;
