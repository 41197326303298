import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Formik } from 'formik';

import './OfferConfigCS.scss';
import '../Components/couponTableBtnContainer.scss';

import searchIcon from '../../assets/images/grey-search.png';

import * as RestSvc from '../../libraries/offer-services';
import * as actionTypes from '../../store/actions';
import * as urlTypes from '../../libraries/offer-service-urls';
import appLabels from '../../utils/appLabels';
import ReactTable, { textFilter, headerFormatter } from '../../Components/ReactTable/ReactTable';
import Toaster from '../../Components/Toaster/Toaster';
import { FormikGroup } from '../../Components/Input/FormikControl';
import OfferDetailsModal from './OfferDetailsModal/OfferDetailsModal';
import OfferFilterModal from './OfferFilterModal/OfferFilterModal';

const { common: commonLabels, table: tableLabels, offerConfiguratorCS: pageLabels } = appLabels;

const OfferCount = ({ offers = [] }) => {
	const offerLength = offers.length;
	if (!offerLength) {
		return null;
	}
	return (
		<p className="mb-2 roboto-r-14 grey">
			{`${offerLength} Promotion${offerLength > 1 ? 's' : ''} Found`}
		</p>
	);
};

const OffersTable = (props) => {
	return (
		<div className="table-container">
			<ReactTable {...props} />
		</div>
	);
};

const UploadOffersBtn = ({ uploadOfferHandler }) => {
	const uploadFileInput = useRef();
	return (
		<div className="coupon-table-btn-container m-0">
			<button
				type="button"
				className="couponBtn d-flex align-items-center"
				onClick={() => {
					uploadFileInput.current.click();
				}}
				disabled={false}
			>
				<span className="btn font-weight-bold" style={{ fontSize: '13px' }}>
					Upload Promotion
				</span>
				<span className="couponUpload icon"></span>
				<input
					ref={uploadFileInput}
					type="file"
					id="idLoadPromotionFromFile"
					style={{ display: 'none' }}
					onChange={(event) => {
						uploadOfferHandler(event, () => {
							uploadFileInput.current.value = '';
						});
					}}
				/>
			</button>
		</div>
	);
};

const SearchFormIdForm = ({ submitHandler, formControls, showLoadingButton, programIdMap }) => {
	const getSearchOfferId = (promotionId, cb = () => {}) => {
		let brand = promotionId
			?.substring(0, promotionId.indexOf(promotionId.match(/\d/)))
			?.toUpperCase().replace(/_/g, '');
		if(brand === 'ONEMG') {
			brand = '1MG';
		}
		const payLoad = {
			searchPromotionRequestLite: {
				programId: programIdMap[brand] || null,
				promotionId: promotionId.toUpperCase()
			}
		};

		submitHandler([]);
		showLoadingButton(true);
		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		RestSvc.postData(urlTypes.offers_maintenance_cs.searchPromotionLite, payLoad, svPromUrl)
			.then((response) => {
				showLoadingButton(false);
				const result = response.data.searchPromotionResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
				} else {
					submitHandler(result);
				}
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	return (
		<div className="search-offer-id-form-container">
			<Formik
				initialValues={{
					searchOfferId: ''
				}}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(false);
					values.searchOfferId.length && getSearchOfferId(values.searchOfferId);
					resetForm();
				}}
			>
				{(formik) => <Form>{formControls()}</Form>}
			</Formik>
		</div>
	);
};

const OfferConfiguratorCS = (props) => {
	const [searchedOffers, setSearchedOffers] = useState([]);
	const [sizePerPageList, setSizePerPageList] = useState([{ text: '50', value: 50 }]);
	const [programIdMap, setProgramIdMap] = useState([]);

	useEffect(() => {
		getItemsPerPageForSearchedOffers();
		fetchBrandsForCSView();
	}, []);

	const getItemsPerPageForSearchedOffers = () => {
		props.showLoadingButton(true);

		RestSvc.getData(urlTypes.offers_maintenance.getConfigurationUrl)
			.then((response) => {
				props.showLoadingButton(false);
				const result = response.data.getConfigurationResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });

					setSizePerPageList([{ text: '50', value: 50 }]);
				} else {
					const pageList = result.configurationList
						.reduce((pre, cur, i) => {
							const newReducer = [...pre];
							if (
								cur.category === 'CONFIGURATOR_CONFIGURATION' &&
								!newReducer.includes(cur.value)
							) {
								newReducer.push(cur.value);
							}
							return newReducer;
						}, [])
						.sort((a, b) => {
							return a - b;
						});
					setSizePerPageList(
						pageList.map((val) => {
							return { text: val, value: parseInt(val) };
						})
					);
				}
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const fetchBrandsForCSView = () => {
		props.showLoadingButton(true);

		RestSvc.getData(urlTypes.offers_maintenance_cs.fetchBrandsForCSView)
			.then((response) => {
				props.showLoadingButton(false);
				const result = response.data.brandsProgramIdMapCsViewRes;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}

				const prefixProgIdMap = result?.prefixProgIdMap;
				const brandProgMap = Object.fromEntries(
					Object.entries(prefixProgIdMap).map(([k, v]) => [k.replace(/_/g, ''), v]) /* replacing underscore with '' for brand so that fetch easy in searchPromotionLite program id */
				);
				setProgramIdMap(brandProgMap);
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const uploadOfferHandler = (event, callBack) => {
		const fileList = event.target.files;
		const {
			showLoadingButton,
			userFullName: { operatorID },
			loggedInBrand: { businessUnitGroupFunctionID, programId }
		} = props;

		const user = `${operatorID}:${programId}`;
		const bsngp_fnc_id = businessUnitGroupFunctionID;

		const formData = new FormData();
		formData.append('file', fileList[0]);
		formData.append('user', user);
		formData.append('bsngp_fnc_id', bsngp_fnc_id);

		showLoadingButton(true);

		RestSvc.postData(urlTypes.offers_maintenance_cs.uploadPromotion, formData)
			.then((response) => {
				showLoadingButton(false);
				const result = response.data.uploadCouponResponse;

				if (result.businessError) {
					Toaster({ message: result.businessError?.description });
					return;
				}

				const { status, businessExceptions } = result;
				let infoMsg = [];
				const statusMsg = [];
				const errors = [];

				status && statusMsg.push(<div key="status-1">{status}</div>);

				businessExceptions?.forEach(({ code, description }, i) => {
					errors.push(<div key={i}>{description}</div>);
				});
				infoMsg = [...statusMsg, ...errors];
				if (infoMsg.length) {
					Toaster({ message: infoMsg, type: `${errors.length ? 'error' : 'info'}` });
				}
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
		callBack();
	};

	const getTableData = () => {
		let noDataInfo = commonLabels.nothingToShow;

		const data = [...searchedOffers];

		const dateFormatter = ({ value, row, index }) => {
			return moment(value).format('DD-MM-YYYY HH:mm');
		};

		const actionFormatter = ({ value, row, index }) => {
			return (
				<div className="action-container">
					<OfferDetailsModal offer={row} showLoadingButton={props.showLoadingButton} />
				</div>
			);
		};
		const columns = [
			{
				dataField: 'promotionId',
				text: tableLabels.promotionId,
				headerAlign: 'center',
				headerFormatter,
				filter: textFilter(),
				formatter: (value, row, index) => value?.split(':')[1] || value
			},
			{
				dataField: 'promotionTitle',
				text: tableLabels.title,
				headerAlign: 'center',
				headerFormatter,
				filter: textFilter()
			},
			{
				dataField: 'businessUnit',
				text: tableLabels.businessUnit,
				headerAlign: 'center'
			},
			{
				dataField: 'limitedUse',
				text: tableLabels.promotionType,
				headerAlign: 'center',
				formatter: (value, row, index) => value
			},
			{
				dataField: 'benefitType',
				text: tableLabels.benefitType,
				headerAlign: 'center',
				formatter: (value, row, index) => (Array.isArray(value) ? value.join(', ') : value)
			},
			{
				dataField: 'effectiveStartDate',
				text: tableLabels.startDateTime,
				headerAlign: 'center',
				formatter: (value, row, index) =>
					dateFormatter({
						value,
						row,
						index
					})
			},
			{
				dataField: 'effectiveEndDate',
				text: tableLabels.endDateTime,
				headerAlign: 'center',
				formatter: (value, row, index) =>
					dateFormatter({
						value,
						row,
						index
					})
			},
			{
				dataField: 'couponType',
				text: tableLabels.couponType,
				headerAlign: 'center',
				formatter: (value, row, index) => value
			},
			{
				isDummyField: true,
				dataField: 'actionColumn',
				text: tableLabels.action,
				headerAlign: 'center',
				align: 'center',
				headerStyle: {
					width: '55px'
				},
				formatter: (value, row, index) =>
					actionFormatter({
						value,
						row,
						index
					})
			}
		];

		const expandRow = {
			renderer: (row) => (
				<>
					<div className="d-flex mb-2">
						<div className="pr-2">
							<span className="cGray mr-1">{tableLabels.description}:</span>
							<span className="">
								{row.promotionDescription || '-'}
							</span>
						</div>
					</div>
				</>
			)
		};

		return {
			columns: [...columns],
			data,
			keyField: 'promotionId',
			noDataInfo,
			paginationEnable: true,
			sizePerPageList: sizePerPageList,
			customClasses: 'offers-table',
			expandRow,
			searchBarEnable: true
		};
	};

	const formControls = () => {
		const inputs = [
			{
				...pageLabels.offerFilter.formLabels.searchOfferId,
				control: 'input',
				name: 'searchOfferId',
				inputClass: 'search-offer-id',
				// controlClass: 'vertical-group',
				// required: true,
				// validate: fieldValidator(['require']),
				children: <img className="search-icon" alt="" src={searchIcon}></img>
			}
		];

		return <FormikGroup inputs={inputs} />;
	};

	return (
		<div className="offer-management-cs-screen">
			{/* <h1 className="roboto-b-24 m-0">Offer Management</h1>
		<hr /> */}

			<div className="action-section d-flex flex-wrap" style={{ gap: '8px' }}>
				<UploadOffersBtn uploadOfferHandler={uploadOfferHandler} />
				<SearchFormIdForm
					submitHandler={(offer) => {
						setSearchedOffers(offer.promoPolicyLiteList);
					}}
					formControls={formControls}
					showLoadingButton={props.showLoadingButton}
					programIdMap={programIdMap}
				/>
				<>
					<p className="d-flex align-items-center m-0 roboto-b-16"> OR </p>
					<OfferFilterModal
						programId={props.loggedInBrand.programId}
						showLoadingButton={props.showLoadingButton}
						searchResultHandler={(offers) => {
							setSearchedOffers(offers.promoPolicyLiteList);
						}}
					/>
					<a
						className="file-download-link d-flex align-items-center channelLink roboto-b-16"
						href="../CS_Upload_Promotion.xlsm"
						title={pageLabels.downloadTemplateTitle}
						download
					>
						<span className="download template-icon" />
					</a>
				</>
			</div>
			<hr />
			<div className="offer-content" style={{ height: '78.5vh', overflowY: 'auto' }}>
				{searchedOffers?.length ? (
					<>
						<OfferCount offers={searchedOffers} />
						<OffersTable {...getTableData()} />
					</>
				) : null}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OfferConfiguratorCS));
