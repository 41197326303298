import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import Searchable from 'react-searchable-dropdown';

const CustomReactSearchableDropdownComponent = ({
	label,
	required,
	prepend,
	smallText,
	type,
	flag,
	pgType,
	...props
}) => {
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();
	const [options] = useState(props.options);
	const [selected, setSelected] = useState(field.value);

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	let formattedName = '';
	let newJson = '';
	let basicOrEligibilityJson = '';
	if (isAuditFlw) {
		let newCustName = JSON.parse(JSON.stringify(props.name));
		// eslint-disable-next-line no-unused-vars
		newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
		basicOrEligibilityJson = newCustName.includes('promotionPolicy')
			? 'newJson'
			: 'newJson.promotionPolicy.priceDerivationRuleEligibility';
		let arrayOfName = newCustName.split('.');

		for (let i = 0; i < arrayOfName.length; i++) {
			if (isNaN(arrayOfName[i])) {
				formattedName = formattedName + '?.' + arrayOfName[i];
			} else {
				formattedName = formattedName + '?.[' + arrayOfName[i] + ']';
			}
		}
	}

	useEffect(() => {
		// let paymentMode= [
		//   {
		//     "configValue": "CARD",
		//     "configName": "CARD"
		//   },
		//   {
		//     "configValue": "NETBANKING",
		//     "configName": "NETBANKING"
		//   },
		//   {
		//     "configValue": "WALLET",
		//     "configName": "WALLET"
		//   }
		// ]
		let temp = [];
		props.options.map((item) => {
			temp.push({ label: item.configName, value: item.configValue });
		});
		// setOptions(temp)
		if (field.value) setSelected(field.value);
	}, []);

	return (
		//  <div className="container">
		//    <div className="rowContainer">
		//      <div className="customLabelContainer">
		//        <label htmlFor="forprogramID" className="col	col-form-label">
		//          {label}

		//          {required ? <span className="requiredRed">*</span> : null}
		//        </label>
		//      </div>
		//      <div className={"customInputContainer"}>
		//        <div className="row">
		isAuditFlw ? (
			<>
				<label
					className={
						eval(basicOrEligibilityJson + formattedName) === field.value &&
						flag != 'show'
							? 'd-none'
							: 'mt-1'
					}
				>
					{label}
				</label>

				<div
					className={
						eval(basicOrEligibilityJson + formattedName) === field.value &&
						flag != 'show'
							? 'd-none'
							: 'gap70 replacedText'
					}
				>
					<input type="text" className="form-control lightGray" value={field.value} />
					<input
						type="text"
						className={
							eval(basicOrEligibilityJson + formattedName) === field.value ||
							pgType != 'paymentGroup'
								? 'd-none'
								: 'form-control lightBlue mt-2'
						}
						value={eval(basicOrEligibilityJson + formattedName)}
					/>
				</div>
			</>
		) : (
			<>
				<label className="mt-1">{label}</label>
				<Searchable
					value={selected}
					placeholder="Select" // by default "Search"
					notFoundText="No result found" // by default "No result found"
					options={options}
					onSelect={(value) => {
						setFieldValue(field.name, value);
						setSelected(value);
					}}
					listMaxHeight={200} //by default 140
					disabled={props.disabled}
				/>
			</>
		)

		//                    {smallText &&
		//                      <small
		//                        id="emailHelp"
		//                        className="form-text pl-2">
		//                        <div class="tooltipimg">
		//                            <button className="tooltip-button btn br-50" type="button">
		//                                <span className="tooltip-icon">?</span></button>
		//                            <span class="tooltiptext"> {smallText}</span>
		//                        </div>
		//                    </small>}
		//                    {
		//                        meta.touched &&
		//                            meta.error ? (
		//                                <div className="invalid-feedback d-block">{meta.error}</div>
		//                            ) : null}
		//                    <div className="invalid-feedback d-block">{errMsg}</div>
		//        </div>

		//      </div>
		//    </div>
		//  </div>
	);
};

export default CustomReactSearchableDropdownComponent;
