import React, { useEffect, useState } from 'react';
import SimplePromotionSellingTable from '../MerchTableSimplePromo.js';

const AuditSimpleProductTable = ({ ...props }) => {
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const getAdded = (products, products1) => {
		var added = [];
		for (var i = 0; i < products.length; i++) {
			var id = products[i].merchandizeGroupId[0];
			var flag = false;
			for (var j = 0; j < products1.length; j++) {
				var id2 = products1[j].merchandizeGroupId[0];
				if (id === id2) {
					flag = true;
				}
			}
			if (!flag) {
				added.push(products[i]);
			}
		}
		return added;
	};

	const getReplaced = (newProds, oldProds, added, removed) => {
		let newProdsModified = [],
			oldProdsModifed = [],
			replaced = [];
		newProdsModified = getAdded(newProds, added);
		oldProdsModifed = getAdded(oldProds, removed);

		newProdsModified.map((item) => {
			oldProdsModifed.map((item1) => {
				if (item.merchandizeGroupId[0] == item1.merchandizeGroupId[0]) {
					if (JSON.stringify(item) != JSON.stringify(item1)) {
						if (item.minimumPrice != item1.minimumPrice) {
							//  item.minimumPrice = 'Old value:' + item1.minimumPrice + ', New value:' + item.minimumPrice
							item.minimumPrice = JSON.stringify({
								oldValue: item1.minimumPrice,
								newValue: item.minimumPrice
							});
						}
						if (item.maximumPrice != item1.maximumPrice) {
							//item.maximumPrice = 'Old value: ' + item1.maximumPrice + ', New value: ' + item.maximumPrice
							item.maximumPrice = JSON.stringify({
								oldValue: item1.maximumPrice,
								newValue: item.maximumPrice
							});
						}
						if (item.trigger != item1.trigger) {
							//  item.trigger = 'Old value:' + item1.trigger + ', New value:' + item.trigger
							item.trigger = JSON.stringify({
								oldValue: item1.trigger,
								newValue: item.trigger
							});
						}
						if (item.getReward != item1.getReward) {
							//  item.getReward = 'Old value:' + item1.getReward + ', New value:' + item.getReward
							item.getReward = JSON.stringify({
								oldValue: item1.getReward,
								newValue: item.getReward
							});
						}
						if (
							props.priceModMethodCode == 'AMOUNT_OFF' ||
							props.priceModMethodCode == 'APPORTIONED_AMOUNT_OFF'
						) {
							if (item.priceModificationAmount != item1.priceModificationAmount) {
								// item.priceModificationAmount = 'Old value: ' + item1.priceModificationAmount + ', New value: ' + item.priceModificationAmount
								item.priceModificationAmount = JSON.stringify({
									oldValue: item1.priceModificationAmount,
									newValue: item.priceModificationAmount
								});
							}
						}
						if (
							props.priceModMethodCode == 'PERCENTAGE_OFF' ||
							props.priceModMethodCode == 'APPORTIONED_PERCENTAGE_OFF'
						) {
							if (item.priceModificationPercent != item1.priceModificationPercent) {
								//  item.priceModificationPercent = 'Old value: ' + item1.priceModificationPercent + ', New value: ' + item.priceModificationPercent
								item.priceModificationPercent = JSON.stringify({
									oldValue: item1.priceModificationPercent,
									newValue: item.priceModificationPercent
								});
							}
						}
						if (props.priceModMethodCode == 'MARKDOWN') {
							if (item.newPriceAmount != item1.newPriceAmount) {
								//    item.newPriceAmount = 'Old value: ' + item1.newPriceAmount + ', New value: ' + item.newPriceAmount
								item.newPriceAmount = JSON.stringify({
									oldValue: item1.newPriceAmount,
									newValue: item.newPriceAmount
								});
							}
						}
						replaced.push(item);
					}
				}
			});
		});
		return replaced;
	};
	const [productsAdded, setProductsAdded] = useState([]);
	const [productsRemoved, setProductsRemoved] = useState([]);
	const [productsReplaced, setProductsReplaced] = useState([]);

	useEffect(() => {
		if (isAuditFlw) {
			let OldProds = props.oldJson;
			// let NewProds = props.newJson.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.merchandizeHierarchyGroup ? props.newJson.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.merchandizeHierarchyGroup : [];
			let NewProds = props.newJson ? props.newJson : [];
			let tempRemoved = getAdded(OldProds, NewProds);
			setProductsRemoved(tempRemoved);
			let tempAdded = getAdded(NewProds, OldProds);

			setProductsAdded(tempAdded);
			let tempReplaced = getReplaced(NewProds, OldProds, tempAdded, tempRemoved);
			setProductsReplaced(tempReplaced);
		}
		return () => {};
	}, []);

	return (
		<div
			className={
				productsAdded.length == 0 &&
				productsRemoved.length == 0 &&
				productsReplaced?.length == 0
					? 'd-none'
					: 'box-blackout-period lightBlue container pl-2'
			}
		>
			{productsAdded.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">Added ({productsAdded.length})</label>
					</div>
					<div className="w-auto dataAdded">
						<SimplePromotionSellingTable
							data={productsAdded}
							keys={[
								'merchandizeGroupId',
								'minimumPrice',
								'maximumPrice',
								'benefitValue'
							]}
							defaultValueForAdd={{
								maximumPrice: 9999999.99,
								minimumPrice: 0.01,
								trigger: true,
								getReward: true
							}}
							onMerchTableChange={() => {}}
							// ScaleMerchTableData={ScaleMerchTableData}
							//data={props.data1}
							eligibility="simplePromotionEligibility"
							pricemodificationmedthodcode={props.priceModMethodCode}
						/>
					</div>
				</div>
			) : undefined}
			{productsRemoved.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">
							Removed ({productsRemoved.length})
						</label>
					</div>
					<div className="w-auto dataRemoved">
						<SimplePromotionSellingTable
							data={productsRemoved}
							keys={[
								'merchandizeGroupId',
								'minimumPrice',
								'maximumPrice',
								'benefitValue'
							]}
							defaultValueForAdd={{
								maximumPrice: 9999999.99,
								minimumPrice: 0.01,
								trigger: true,
								getReward: true
							}}
							onMerchTableChange={() => {}}
							// ScaleMerchTableData={ScaleMerchTableData}
							//data={props.data1}
							eligibility="simplePromotionEligibility"
							pricemodificationmedthodcode={props.priceModMethodCode}
						/>
					</div>
				</div>
			) : undefined}
			{productsReplaced.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">
							Modified ({productsReplaced.length})
						</label>
					</div>
					<div className="w-auto">
						<SimplePromotionSellingTable
							data={productsReplaced}
							keys={[
								'merchandizeGroupId',
								'minimumPrice',
								'maximumPrice',
								'benefitValue'
							]}
							defaultValueForAdd={{
								maximumPrice: 9999999.99,
								minimumPrice: 0.01,
								trigger: true,
								getReward: true
							}}
							onMerchTableChange={() => {}}
							// ScaleMerchTableData={ScaleMerchTableData}
							//data={props.data1}
							eligibility="simplePromotionEligibility"
							pricemodificationmedthodcode={props.priceModMethodCode}
						/>
					</div>
				</div>
			) : undefined}
		</div>
	);
};

export default AuditSimpleProductTable;
