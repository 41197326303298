import React, { useEffect } from 'react';
import {
	useField,
	// eslint-disable-next-line no-unused-vars
	getFieldProps,
	useFormikContext
} from 'formik';
import CustomInputText from './customfields/CustomInputText';
import CustomInputRadio from './customfields/CustomInputRadio';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import CustomInputRadioString from './customfields/CustomInputRadioString';

const localConst = {
	promotionSharable: 'Is Promotion Sharable',
	subPromotion: 'Determines if the promotion can be shared with other persons',
	promotionShareType: 'Promotion Share Type',
	subPromotionShareType: 'Determines the type of share allowed for the promotion',
	totalShare: 'Total Share',
	subTotalShare: 'Determines the count of shares allowed'
};

const Shares = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();

	const fieldShares = field.value.promotionPolicy.sharability;
	const customValidateTotalShare = (value, offrFlow) => {
		let error;

		if (value && offrFlow.type == 'modify-offer-flow') {
			if (
				parseInt(value) !== parseInt(props.initialValBakup.sharability.totalShares) &&
				parseInt(value) < parseInt(props.initialValBakup.sharability.totalShares)
			) {
				error =
					'Total Share value should be greater than the current value: ' +
					props.initialValBakup.sharability.totalShares;
				// setFieldValue("promotionPolicysharability.totalShares",props.initialValBakup.sharability.totalShares);
				return error;
			}
		}
		return error;
	};

	const { limitedUse } = props.fieldBasicDetails;
	const { multimapPromotion } =
		props.fieldBasicDetails?.promotionApplicableEligibility?.promotionActivationEligibility ||
		{};
	useEffect(() => {
		let limitedUseVal = limitedUse ? 'LIMITED' : 'UNLIMITED';

		if (
			fieldShares.isPromotionSharable ==
			true /* && fieldShares.promotionShareType == undefined */
		) {
			//fieldShares.promotionShareType = 'UNLIMITED'

			setFieldValue('promotionPolicy.sharability.promotionShareType', limitedUseVal);
		}
		if (multimapPromotion == true) {
			setFieldValue('promotionPolicy.limitedUse', true);
			setFieldValue('promotionPolicy.sharability.isPromotionSharable', false);
			setFieldValue('promotionPolicy.sharability.totalShares', undefined);
		}
	}, [fieldShares, limitedUse, multimapPromotion]);

	useEffect(() => {
		if (multimapPromotion == false) {
			if (!props.fieldBasicDetails.limitedUse)
				setFieldValue('promotionPolicy.limitedUse', false);
			if (props.fieldBasicDetails.limitedUse)
				setFieldValue('promotionPolicy.limitedUse', true);
		}
	}, [multimapPromotion]);
	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = offerFlow.isAuditFlow;
	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));

	return (
		<div>
			<CustomInputRadio
				name="promotionPolicy.sharability.isPromotionSharable"
				label={localConst.promotionSharable}
				label1="Yes"
				label2="No"
				option1={true}
				option2={false}
				disabledVal1={multimapPromotion}
				smallText={localConst.subPromotion}
			/>

			{fieldShares.isPromotionSharable == true ? (
				isAuditFlw &&
				fieldShares.isPromotionSharable !=
					newJson.promotionPolicy.sharability.isPromotionSharable ? null : (
					<>
						<CustomInputRadioString
							name="promotionPolicy.sharability.promotionShareType"
							label={localConst.promotionShareType}
							promotionShareTypeVal={fieldShares.promotionShareType}
							label1="LIMITED"
							label2="UNLIMITED"
							option1="LIMITED"
							option2="UNLIMITED"
							smallText={localConst.subPromotionShareType}
						/>
						{fieldShares.promotionShareType == 'LIMITED' ? (
							<>
								{isAuditFlw &&
								fieldShares.promotionShareType !=
									newJson.promotionPolicy.sharability
										.promotionShareType ? null : (
									<CustomInputText
										name="promotionPolicy.sharability.totalShares"
										required={true}
										label={localConst.totalShare}
										smallText={localConst.subTotalShare}
										disabled={false}
										validate={(val) => customValidateTotalShare(val, offerFlow)}
									/>
								)}
							</>
						) : (
							(fieldShares.totalShares = undefined)
						)}
					</>
				)
			) : (
				((fieldShares.promotionShareType = undefined),
				(fieldShares.totalShares = undefined))
			)}
		</div>
	);
};

export default Shares;
