import React from 'react';
import PropTypes from 'prop-types';
import { errorMsg } from '../../utils/appLabels';
import './errorBoundary.scss';
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	/* this will catch in the root <ErrorBounday> */
	// static getDerivedStateFromError(error, errorInfo) {
	// 	/*  Update state so the next render will show the fallback UI. */
	// 	return {
	// 		error,
	// 		errorInfo
	// 	};
	// }

	/* this will catch in the nearest <ErrorBounday> */
	componentDidCatch(error, errorInfo) {
		/* Display fallback UI */
		this.setState({
			errorSource: this.props.source,
			error,
			errorInfo
		});
		/* You can also log the error to an error reporting service */
	}

	render() {
		const { source, detailOpen, children } = this.props;
		const { errorInfo, errorSource, error } = this.state;

		if (errorInfo && errorSource === source) {
			/* You can render any custom fallback UI */
			/* if env is know try to show the error on local env */
			return (
				<div className="error-boundary">
					<h2>
						{errorMsg.commonError} at {source}
					</h2>
					<details open={detailOpen}>
						{error?.message.toString()}
						<br />
						{errorInfo?.componentStack}
						<br />
						{error?.stack.toString()}
					</details>
				</div>
			);
		}
		return children;
	}
}

ErrorBoundary.defaultProps = {
	source: 'error' /* use to show error only at source origin */,
	detailOpen: false
};

ErrorBoundary.propTypes = {
	source: PropTypes.string,
	detailOpen: PropTypes.bool
};
export default ErrorBoundary;
