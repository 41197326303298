import React, { Component } from 'react';
import moment from 'moment';
import Edit from './../../assets/images/Edit.png';
import Delete from './../../assets/images/deleteIcon.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class UserDetails extends Component {
	render() {
		return (
			<div className="col-md-9">
				<div className="media edit-block">
					<div className="media-left pr-3 v-line">
						<p className="blue-circle-bg m-0 mt-1">
							{this.props.userLevelHierarcy ? this.props.userLevelHierarcy : '-'}
						</p>
					</div>
					<div className="media-body ml-4">
						<h6 className="m-0">
							<span className="roboto-b-20 mb-0">
								{this.props.inputToUserDetails.userId.value}
							</span>
							<span className="roboto-r-20 ml-3">
								{this.props.inputToUserDetails.userFirstName.value +
									' ' +
									this.props.inputToUserDetails.userMiddleName.value +
									' ' +
									this.props.inputToUserDetails.userLastName.value}
								{this.props.clickPreview ? (
									<span
										className={
											this.props.isExistingUser == true
												? !(
														this.props.assignedResources.indexOf(
															'OC006'
														) != -1
												  ) ||
												  (this.props.loggedInLevelHierarchy !== 1 &&
														this.props.loggedInLevelHierarchy >=
															this.props.userLevelHierarcy)
													? 'disabled pl-3'
													: 'pl-3 pointer'
												: 'pl-3 pointer'
										}
										onClick={() => {
											this.props.deleteUser();
										}}
									>
										<img className="highlight" src={Delete} alt="img" />
									</span>
								) : null}
							</span>
						</h6>
					</div>
					{this.props.createdOn ? (
						<div className="media-right date-text-bottom">
							<p className="created-date roboto-m-11">
								Created on {moment(this.props.createdOn).format('DD/MM/YYYY')}
							</p>
						</div>
					) : null}
				</div>
				<div className="user-details">
					<div className="row m-0">
						<div className="col-md-10">
							<h6 className="roboto-b-16">Basic Details</h6>
						</div>
						<div className="col-md-2 text-right">
							<span
								className={
									this.props.isExistingUser == true
										? !(this.props.assignedResources.indexOf('OC005') != -1) ||
										  (this.props.loggedInLevelHierarchy !== 1 &&
												this.props.loggedInLevelHierarchy >=
													this.props.userLevelHierarcy)
											? 'disabled'
											: 'pointer'
										: 'pointer'
								}
								onClick={() =>
									this.props.handleSelection('basic', 'selectedOption')
								}
							>
								<img
									className="highlight"
									src={Edit}
									style={{ width: '20px' }}
									alt=""
								/>
							</span>
						</div>
					</div>
					<hr className="m-0 mx-3" />
					<div className="row my-4 ml-1">
						<div className="col-md-3">
							<p className="roboto-m-11 m-0 grey">User ID</p>
							<p className="roboto-m-12 m-0">
								{this.props.inputToUserDetails.userId.value}
							</p>
						</div>
						<div className="col-md-3">
							<p className="roboto-m-11 m-0 grey">First Name</p>
							<p className="roboto-m-12 m-0">
								{this.props.inputToUserDetails.userFirstName.value}
							</p>
						</div>
						<div className="col-md-3">
							<p className="roboto-m-11 m-0 grey">Middle Name</p>
							<p className="roboto-m-12 m-0">
								{this.props.inputToUserDetails.userMiddleName.value || '-'}
							</p>
						</div>
						<div className="col-md-3">
							<p className="roboto-m-11 m-0 grey">Last Name</p>
							<p className="roboto-m-12 m-0">
								{this.props.inputToUserDetails.userLastName.value}
							</p>
						</div>
					</div>
					<div className="row my-4 ml-1">
						<div className="col-md-3">
							<p className="roboto-m-11 m-0 grey">Status</p>
							<p className="roboto-m-12 m-0">
								{this.props.inputToUserDetails.userStatus.value === 'A' ||
								this.props.inputToUserDetails.userStatus.value === 'Active'
									? 'Active'
									: 'Inactive'}
							</p>
						</div>
						<div className="col-md-3">
							<p className="roboto-m-11 m-0 grey">Start Date & Time</p>
							<p className="roboto-m-12 m-0">
								{moment(
									this.props.inputToUserDetails.userStartDateTime.value
								).format('DD-MM-YYYY HH:mm:ss')}
							</p>
						</div>
						<div className="col-md-3">
							<p className="roboto-m-11 m-0 grey">End Date & Time</p>
							<p className="roboto-m-12 m-0">
								{this.props.inputToUserDetails.userEndDateTime.value
									? moment(
											this.props.inputToUserDetails.userEndDateTime.value
									  ).format('DD-MM-YYYY HH:mm:ss')
									: '-'}
							</p>
						</div>
						<div className="col-md-3">
							<p className="roboto-m-11 m-0 grey">Level Name</p>
							<p className="roboto-m-12 m-0">
								{this.props.inputToUserDetails.userLevelId.value
									? this.props.inputToUserDetails.userLevelId.value.split('/')[0]
									: '-'}
							</p>
						</div>
					</div>
					<div className="row my-4 ml-1">
						<div className="col-md-3">
							<p className="roboto-m-11 m-0 grey">Email</p>
							<p className="roboto-m-12 m-0">
								{this.props.inputToUserDetails.userEmailId.value || '-'}
							</p>
						</div>
						<div className="col-md-3">
							<p className="roboto-m-11 m-0 grey">Phone Number</p>
							<p className="roboto-m-12 m-0">
								{this.props.inputToUserDetails.userPhoneNumber.value || '-'}
							</p>
						</div>
					</div>
					<div className="row m-0">
						<div className="col-md-3 b-line">
							<h6 className="roboto-b-16">Brand Details</h6>
						</div>
						<div className="col-md-3 b-line text-right">
							<span
								className={
									this.props.isExistingUser == true
										? !(this.props.assignedResources.indexOf('OC005') != -1) ||
										  (this.props.loggedInLevelHierarchy !== 1 &&
												this.props.loggedInLevelHierarchy >=
													this.props.userLevelHierarcy)
											? 'disabled'
											: 'pointer'
										: 'pointer'
								}
								onClick={() =>
									this.props.handleSelection('brand', 'selectedOption')
								}
							>
								{' '}
								<img
									className="highlight"
									src={Edit}
									style={{ width: '20px' }}
									alt=""
								/>
							</span>
						</div>
					</div>
					<div className="m-2">
						{this.props.inputToUserDetails.userBrand.value.split('/')[1]}
					</div>
				</div>
				<div className="m-0 row footer">
					<div className="col-md-3 offset-md-2"></div>
					{this.props.clickPreview ? null : (
						<div className="col-md-4 offset-md-1">
							<button
								onClick={this.props.cancelCall}
								className="btnNxtPrev roboto-b-16"
							>
								Cancel
							</button>
							<button
								className="btnNxtPrev ml-2 roboto-b-16"
								onClick={this.props.onCreateUserSubmit}
							>
								Submit
							</button>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		disableItem: state.disableItem,
		assignedResources: state.assignedResources,
		loggedInLevelID: state.loggedInLevelID,
		loggedInLevelHierarchy: state.loggedInLevelHierarchy
	};
};

export default connect(mapStateToProps, null)(withRouter(UserDetails));
