import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import 'bootstrap/dist/css/bootstrap.css';
import '../BasicDetails.scss';
import CustomSelect from '../customfields/CustomSelect';
import EffectiveDurationExcludedDaysOfTheMonth from './EffectiveDurationExcludedDaysOfTheMonth';

const dropdownValues = {
	days: [
		{
			value: '01',
			name: 'Sunday'
		},
		{
			value: '02',
			name: 'Monday'
		},
		{
			value: '03',
			name: 'Tuesday'
		},
		{
			value: '04',
			name: 'Wednesday'
		},
		{
			value: '05',
			name: 'Thursday'
		},
		{
			value: '06',
			name: 'Friday'
		},
		{
			value: '07',
			name: 'Saturday'
		}
	],
	dates: [
		{
			value: '01',
			name: '01'
		},
		{
			value: '02',
			name: '02'
		},
		{
			value: '03',
			name: '03'
		},
		{
			value: '04',
			name: '04'
		},
		{
			value: '05',
			name: '05'
		},
		{
			value: '06',
			name: '06'
		},
		{
			value: '07',
			name: '07'
		},
		{
			value: '08',
			name: '08'
		},
		{
			value: '09',
			name: '09'
		},
		{
			value: '10',
			name: '10'
		},
		{
			value: '11',
			name: '11'
		},
		{
			value: '12',
			name: '12'
		},
		{
			value: '13',
			name: '13'
		},
		{
			value: '14',
			name: '14'
		},
		{
			value: '15',
			name: '15'
		},
		{
			value: '16',
			name: '16'
		},
		{
			value: '17',
			name: '17'
		},
		{
			value: '18',
			name: '18'
		},
		{
			value: '19',
			name: '19'
		},
		{
			value: '20',
			name: '20'
		},
		{
			value: '21',
			name: '21'
		},
		{
			value: '22',
			name: '22'
		},
		{
			value: '23',
			name: '23'
		},
		{
			value: '24',
			name: '24'
		},
		{
			value: '25',
			name: '25'
		},
		{
			value: '26',
			name: '26'
		},
		{
			value: '27',
			name: '27'
		},
		{
			value: '28',
			name: '28'
		},
		{
			value: '29',
			name: '29'
		},
		{
			value: '30',
			name: '30'
		},
		{
			value: '31',
			name: '31'
		}
	]
};

const DaysOfTheMonthIncludedDay = ({ ...props }) => {
	let { customArrayName } = props;
	const fieldHappyHours = props.fieldHappyHours;
	const { setFieldValue } = useFormikContext();

	const getDatesDropdown = () => {
		return dropdownValues.dates.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	return (
		<div className="Container">
			<FieldArray
				name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.${customArrayName}`}
			>
				{({ remove }) => (
					<>
						{fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth[
							customArrayName
						] &&
							fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth[
								customArrayName
							].map((item, index) => (
								<div key={index} className="d-flex flex-row happy-hours">
									<div
										className={
											JSON.parse(sessionStorage.getItem('typeOfOffersFlow'))
												.isAuditFlow
												? 'd-flex fixHHwidth'
												: 'd-flex'
										}
									>
										<CustomSelect
											flag="show"
											name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.${customArrayName}.${index}.date`}
											required={true}
											label={
												index == 0
													? 'Date'
													: JSON.parse(
															sessionStorage.getItem(
																'typeOfOffersFlow'
															)
													  ).isAuditFlow
													? 'Date'
													: ''
											}
											options={getDatesDropdown()}
										/>
										{index == 0 ? (
											<div className="happy-hours-plusBtn">
												{' '}
												<button
													type="button"
													className="addPlusBtn bg-color h-75"
													onClick={() => {
														if (
															fieldHappyHours.eligibleDatesOfTheMonth
																.excludedDatesOfTheMonth[
																customArrayName
															].length < 7
														) {
															fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth[
																customArrayName
															].push({
																date:
																	'0' +
																	(fieldHappyHours
																		.eligibleDatesOfTheMonth
																		.excludedDatesOfTheMonth[
																		customArrayName
																	].length +
																		1),
																effectiveDuration: [
																	{
																		effectiveStartTime:
																			'00:00:00',
																		effectiveEndTime: '23:59:00'
																	}
																]
															});
														}
														setFieldValue('', '');
													}}
												></button>
											</div>
										) : (
											<div className="happy-hours-deleteBtn">
												<button
													type="button"
													className="addDeleteBtn bg-color h-75"
													onClick={() => remove(index)}
												></button>
											</div>
										)}
									</div>
									<div>
										{/*  <FieldArray name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.${index}.effectiveDuration`}>
                        {({ insert, remove, push }) => (
                          <>
                            {fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate[index].effectiveDuration.map((item, i) => (
                              <div className="d-flex">
                                <div className="d-flex">
                                  <CustomHoursPicker name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.${index}.effectiveDuration.${i}.effectiveStartTime`} required={true} label={index==0 && i==0? 'Start Time' : ''} 
                                  valueHour={fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate[index].effectiveDuration[i].effectiveStartTime}/>
                                 
                                </div>
                                <div className="d-flex align-items-center">
                                <CustomHoursPicker name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate.${index}.effectiveDuration.${i}.effectiveEndTime`} required={true} label={index==0 && i==0? 'End Time' : ''}
                                valueHour={fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate[index].effectiveDuration[i].effectiveEndTime} />
                                {i == 0 ? <div className="happy-hours-DateplusBtn">  <button type="button" className="addPlusBtn bg-color h-75" onClick={() => {
                                    // if (fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate[index].effectiveDuration.length < 5) {
                                      fieldHappyHours.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate[index].effectiveDuration.push(
                                        {
                                          "effectiveStartTime": "00:00:00",
                                          "effectiveEndTime": "23:59:00"
                                        })
                                    // }
                                    setFieldValue("","");
                                  }}></button></div> :
                                	  <div className="happy-hours-deleteBtn">  <button type="button" className="addDeleteBtn bg-color h-75" onClick={() => remove(i)}></button> </div>}

                              </div>
                              </div>
                            ))}</>)}
                      </FieldArray> */}
										{JSON.parse(sessionStorage.getItem('typeOfOffersFlow'))
											.isAuditFlow &&
										props.customArrayName == 'excludedDate' ? (
											<>
												<div className="happyHourForAudit">
													{fieldHappyHours?.eligibleDatesOfTheMonth
														?.excludedDatesOfTheMonth[
														customArrayName
													] &&
													fieldHappyHours?.eligibleDatesOfTheMonth
														?.excludedDatesOfTheMonth[customArrayName][
														index
													]?.effectiveDurationAdded?.length > 0 ? (
														<div className="m-2 p-2 addedSection">
															<label className="font-weight-bold">
																Added (
																{
																	fieldHappyHours
																		?.eligibleDatesOfTheMonth
																		?.excludedDatesOfTheMonth[
																		customArrayName
																	][index]?.effectiveDurationAdded
																		?.length
																}
																)
															</label>
															<EffectiveDurationExcludedDaysOfTheMonth
																{...props}
																index={index}
																customName="effectiveDurationAdded"
															/>
														</div>
													) : undefined}
													{fieldHappyHours?.eligibleDatesOfTheMonth
														?.excludedDatesOfTheMonth[
														customArrayName
													] &&
													fieldHappyHours?.eligibleDatesOfTheMonth
														?.excludedDatesOfTheMonth[customArrayName][
														index
													]?.effectiveDurationRemoved?.length > 0 ? (
														<div className="lightBlueBorder m-2 p-2 removedSection">
															<label className="font-weight-bold">
																Removed (
																{
																	fieldHappyHours
																		?.eligibleDatesOfTheMonth
																		?.excludedDatesOfTheMonth[
																		customArrayName
																	][index]
																		?.effectiveDurationRemoved
																		?.length
																}
																)
															</label>
															<EffectiveDurationExcludedDaysOfTheMonth
																{...props}
																index={index}
																customName="effectiveDurationRemoved"
															/>
														</div>
													) : undefined}
												</div>
												{fieldHappyHours?.eligibleDatesOfTheMonth
													?.excludedDatesOfTheMonth[customArrayName] &&
												fieldHappyHours?.eligibleDatesOfTheMonth
													?.excludedDatesOfTheMonth[customArrayName][
													index
												]?.effectiveDuration?.length > 0 ? (
													<div className="lightBlueBorder m-2 p-2">
														<label className="font-weight-bold">
															Modified (
															{
																fieldHappyHours
																	?.eligibleDatesOfTheMonth
																	?.excludedDatesOfTheMonth[
																	customArrayName
																][index]?.effectiveDuration?.length
															}
															)
														</label>
														<EffectiveDurationExcludedDaysOfTheMonth
															{...props}
															index={index}
															customName="effectiveDuration"
														/>
													</div>
												) : undefined}
											</>
										) : JSON.parse(sessionStorage.getItem('typeOfOffersFlow'))
												.isAuditFlow &&
										  props.customArrayName != 'excludedDate' ? (
											<EffectiveDurationExcludedDaysOfTheMonth
												{...props}
												index={index}
												customName="effectiveDuration"
											/>
										) : (
											<EffectiveDurationExcludedDaysOfTheMonth
												{...props}
												index={index}
												customName="effectiveDuration"
											/>
										)}
									</div>
								</div>
							))}
					</>
				)}
			</FieldArray>
		</div>
	);
};

export default DaysOfTheMonthIncludedDay;
