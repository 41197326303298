import React, { useEffect } from 'react';
import { Form, Field, ErrorMessage, FieldArray, useField, useFormikContext } from 'formik';
import CustomInputText from './customfields/CustomInputText';
import CustomSelect from './customfields/CustomSelect';
import CollapseHeaderBar from './CollapseHeaderBar';
import CollapseBody from './CollapseBody';
import BenefitSettingsMTMB from './BenefitSettingsMTMB';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import PaymentConditionMTMB from './PaymentConditionMTMB';
import CouponConditionMTMB from './CouponConditionMTMB';
import { itemHelp } from './item-help-text';
// import FormController from "./MutiByGroup/FormController";
import ConditionalRederingMTMB from './MutiByGroup/ConditionalRederingMTMB';
import { Element } from 'react-scroll';
import MerchTableBootstrapSCALE from './MerchTableBootstrapSCALE';

const dropdownValues = {
	promotionType: [
		{
			value: 'Interval Quantity Threshold',
			name: 'Interval Quantity Threshold'
		},
		{
			value: 'Quantity Threshold',
			name: 'Quantity Threshold'
		},
		{
			value: 'Interval Amount Threshold',
			name: 'Interval Amount Threshold'
		}
	],
	applyBenefit: [
		{
			value: 'SOURCE',
			name: 'Source'
		},
		{
			value: 'TARGET',
			name: 'Target'
		}
	]
};

const thresholdTypeCodeOptions = [
	{
		value: '',
		name: 'Select one...'
	},
	{
		value: 'PACKAGE_INTERVAL_QTY_THRESHOLD',
		name: 'Package Interval Quantity Threshold'
	},
	{
		value: 'PACKAGE_QTY_THRESHOLD',
		name: 'Package Quantity Threshold'
	},
	{
		value: 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
		name: 'Package Interval Amount Threshold'
	},
	{
		value: 'PACKAGE_AMOUNT_THRESHOLD',
		name: 'Package Amount Threshold'
	},
	{
		value: 'PACKAGE_RANGE_QTY_THRESHOLD',
		name: 'Package Range Quantity Threshold'
	},
	{
		value: 'PACKAGE_RANGE_AMOUNT_THRESHOLD',
		name: 'Package Range Amount Threshold'
	},
	{
		value: 'PACKAGE_LIMIT_QTY_THRESHOLD',
		name: 'Package Limit Quantity Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD',
		name: 'Hierarchy Package Interval Quantity Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_QTY_THRESHOLD',
		name: 'Hierarchy Package  Quantity Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD',
		name: 'Hierarchy Package Interval Amount Threshold'
	},
	{
		value: 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD',
		name: 'Hierarchy Package Amount Threshold'
	}
];
const promotionTypeOrAnd = [
	{
		value: 'OR',
		name: 'OR'
	},
	{
		value: 'AND',
		name: 'AND'
	}
];

const localConst = {
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	triggerCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Determines the combination code to be used between the trigger items for a promotion',
	thresholdQuantity: 'Threshold Quantity',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	rewardQuantity: 'Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product Settings',
	PaymentConditions: 'Payment Conditions',
	CouponConditions: 'Coupon Conditions',
	ExclusionsSetting: 'Exclusion Settings'
};
const elType = {
	TRIGGER_AND_REWARD: 'Trigger and Reward',
	TRIGGER: 'Trigger',
	REWARD: 'Reward'
};
let errMsg = '';
let ScaleMerchTableData;
const keysMerch = ['merchandizeGroupId', 'minimumPrice', 'maximumPrice', 'trigger', 'getReward'];
const defaultValueForAddMG = {
	maximumPrice: '999999.99',
	minimumPrice: '0.01'
	// trigger: 'Y',
	// getReward: 'Y',
};

const getThresholdDropdown = (thresholdTypeCodeOptions) => {
	return thresholdTypeCodeOptions.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const EligibilityForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [field] = useField(props);

	if (Object.keys(props.dataMTMBtable).length != 0) {
		ScaleMerchTableData = props.dataMTMBtable;
	} else {
		if (!ScaleMerchTableData) {
			ScaleMerchTableData = {
				packageMultibuyEligibility: [
					{
						group: [
							{
								merchandizeHierarchyGroup: []
							}
						]
					}
				]
			};
		}
	}
	// data=ScaleMerchTableData;

	const fieldMTMB = field.value.mtmbEligibility;
	const { setFieldValue, values } = useFormikContext();

	const getApplyBenefitDropdown = () => {
		return dropdownValues.applyBenefit.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const getOccurrence = (array, value) => {
		var count = 0;
		array.forEach((v) => v === value && count++);
		return count;
	};
	useEffect(() => {
		fieldMTMB.packageMultibuyEligibility.map((item, index) => {
			if (item.thresholdTypeCode == '') {
				item.group = [
					{
						merchandizeHierarchyGroup: [],
						trigger: true,
						getReward: true
					}
				];
			}
			let elVal = [];
			item.group.map((g) => {
				if (g.trigger) {
					elVal.push('Trigger');
				}
				if (g.getReward) {
					elVal.push('Reward');
				}
			});
			const oldEligibilityType =
				values.mtmbEligibility.packageMultibuyEligibility[index].eligibilityType;
			let newEligibilityType = '';
			if (getOccurrence(elVal, 'Trigger') == 0 && getOccurrence(elVal, 'Reward') != 0) {
				// 	setFieldValue(
				// 		`mtmbEligibility.packageMultibuyEligibility.${index}.eligibilityType`,
				// 		'REWARD'
				// 	);
				newEligibilityType = 'REWARD';
			} else if (
				getOccurrence(elVal, 'Reward') == 0 &&
				getOccurrence(elVal, 'Trigger') != 0
			) {
				// 	setFieldValue(
				// 		`mtmbEligibility.packageMultibuyEligibility.${index}.eligibilityType`,
				// 		'TRIGGER'
				// 	);
				newEligibilityType = 'TRIGGER';
			} else if (
				(getOccurrence(elVal, 'Reward') != 0 && getOccurrence(elVal, 'Trigger') != 0) ||
				elVal.length == 0
			) {
				// 	setFieldValue(
				// 		`mtmbEligibility.packageMultibuyEligibility.${index}.eligibilityType`,
				// 		'TRIGGER_AND_REWARD'
				// 	);
				newEligibilityType = 'TRIGGER_AND_REWARD';
			}
			/* hotfix/mtmb-offer-browser-hang */
			if (oldEligibilityType !== newEligibilityType) {
				setFieldValue(
					`mtmbEligibility.packageMultibuyEligibility.${index}.eligibilityType`,
					newEligibilityType
				);
			}
		});
	}, [fieldMTMB.packageMultibuyEligibility]);
	useEffect(() => {}, []);

	const onMerchTableChange = (val, i, j) => {
		//debugger
		ScaleMerchTableData.packageMultibuyEligibility[i].group[j].merchandizeHierarchyGroup = val;

		props.onmerchandizeTableChangedata(ScaleMerchTableData);
	};

	const triggerValueChange = (index, i) => {
		if (
			fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_QTY_THRESHOLD'
		) {
			fieldMTMB.packageMultibuyEligibility[index].group[i].memberGroupThresholdQuantity = '';
		} else if (
			fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			fieldMTMB.packageMultibuyEligibility[index].group[i].memberGroupThresholdAmount = '';
		}
	};

	const getRewardValueChange = (index, i) => {
		if (
			fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
			fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
			fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD'
		) {
			fieldMTMB.packageMultibuyEligibility[index].group[i].memberGroupRewardQuantity = '';
		}
	};

	const customValidateMemberGroupThresholdQuantity = (value, index, i) => {
		let error;
		if (
			(fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
					'HIERARCHY_PACKAGE_QTY_THRESHOLD') &&
			fieldMTMB.packageMultibuyEligibility[index].group[i].trigger
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}$/)) {
					error = 'Group Threshold Quantity value should be between 1 to 999999';
				}
			} else {
				error = 'Please define a Group Threshold Quantity value';
			}
		}
		return error;
	};
	//amount
	const customValidateMemberGroupThresholdAmount = (value, index, i) => {
		let error;
		if (
			(fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
				fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
					'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD') &&
			fieldMTMB.packageMultibuyEligibility[index].group[i].trigger
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/)) {
					error = 'Group Threshold Amount value should be between 0.01 to 999999.99';
				}
			} else {
				error = 'Please define a Group Threshold Amount value';
			}
		}
		return error;
	};

	//reward
	const customValidateMemberGroupRewardQuantity = (value, index, i) => {
		let error;
		if (
			(fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
				'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
				fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
					'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
				fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
					'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
				fieldMTMB.packageMultibuyEligibility[index].thresholdTypeCode ==
					'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD') &&
			fieldMTMB.packageMultibuyEligibility[index].group[i].getReward
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}$/)) {
					error = 'Group Reward Quantity value should be between 1 to 999999';
				}
			} else {
				error = 'Please define a Group Reward Quantity value';
			}
		}
		return error;
	};

	return (
		<div>
			<Form>
				<FieldArray name="mtmbEligibility.packageMultibuyEligibility">
					{({ remove }) => (
						<>
							{fieldMTMB.packageMultibuyEligibility.map((item, index) => (
								<>
									{index == 0 ? (
										<div className="d-flex justify-content-end m-2">
											<button
												type="button"
												className="addPlusBtn"
												onClick={() => {
													ScaleMerchTableData.packageMultibuyEligibility.push(
														{
															group: [
																{
																	merchandizeHierarchyGroup: []
																}
															]
														}
													);
													fieldMTMB.packageMultibuyEligibility.push({
														benefit: {
															benefitType: ''
														},
														group: [
															{
																merchandizeHierarchyGroup: [],
																trigger: true,
																getReward: true
															}
														],
														multiTargetCondition: 'AND',
														thresholdTypeCode: '',
														//
														groupTriggerCombinationCode: '',
														groupRewardCombinationCode: '',
														//
														groupRewardQuantity: '',
														groupThresholdQuantity: '',
														//
														maximumThresholdQuantity: '',
														maximumThresholdAmount: '',
														quantityLimit: ''
													});
													setFieldValue('', '');
												}}
											></button>
										</div>
									) : (
										<div className="d-flex justify-content-end m-2">
											<button
												type="button"
												className="addDeleteBtn"
												onClick={() => {
													remove(index);
													setFieldValue('', '');
												}}
											></button>
										</div>
									)}
									<div className="accordion" id="accordionExample">
										{/* <div class="card cardStyle mtmb-style m-2 p-3"> */}
										<Element
											name="scrollToProductEligibility"
											class="card cardStyle mtmb-style m-2 p-3"
										>
											<h5 className="mt-ml">
												{' '}
												MTMB Selling Hierarchy Eligibility
											</h5>
											<hr />
											<div className="pl-4">
												<CustomSelect
													name={`mtmbEligibility.packageMultibuyEligibility.${index}.multiTargetCondition`}
													required={true}
													label="MultiTarget Condition"
													options={getThresholdDropdown(
														promotionTypeOrAnd
													)}
													// smallText={itemHelp.ThresholdTypeCodeMBG}
												/>
											</div>
											<div className="p-3">
												<div className="container-fluid">
													<div className="d-flex justify-content-center text-center">
														<CustomSelect
															name={`mtmbEligibility.packageMultibuyEligibility.${index}.thresholdTypeCode`}
															required={true}
															label="Threshold Type Code"
															options={getThresholdDropdown(
																thresholdTypeCodeOptions
															)}
															smallText={
																itemHelp.ThresholdTypeCodeMBG
															}
														/>
													</div>
													<ErrorMessage
														name={`mtmbEligibility.packageMultibuyEligibility.${index}.thresholdTypeCode`}
														component="div"
														className="field-error text-center hideMsg"
													/>
													{fieldMTMB.packageMultibuyEligibility[index]
														.thresholdTypeCode ? (
														<>
															<ConditionalRederingMTMB
																value={
																	fieldMTMB
																		.packageMultibuyEligibility[
																		index
																	].thresholdTypeCode
																}
																index={index}
															/>
															<FieldArray
																name={`mtmbEligibility.packageMultibuyEligibility.${index}.group`}
															>
																{({ remove }) => (
																	<>
																		{fieldMTMB.packageMultibuyEligibility[
																			index
																		].group.map((item, i) => (
																			<>
																				{i == 0 ? (
																					<div className="d-flex justify-content-end m-2">
																						<button
																							type="button"
																							className="addPlusBtn bg-color"
																							onClick={() => {
																								//debugger
																								ScaleMerchTableData.packageMultibuyEligibility[
																									index
																								].group.push(
																									{
																										merchandizeHierarchyGroup:
																											[]
																									}
																								);

																								fieldMTMB.packageMultibuyEligibility[
																									index
																								].group.push(
																									{
																										merchandizeHierarchyGroup:
																											[],
																										trigger: true,
																										getReward: true
																									}
																								);
																								setFieldValue(
																									'',
																									''
																								);
																							}}
																						></button>
																					</div>
																				) : (
																					<div className="d-flex justify-content-end m-2">
																						<button
																							type="button"
																							className="addDeleteBtn bg-color"
																							onClick={() => {
																								remove(
																									i
																								);
																								setFieldValue(
																									'',
																									''
																								);
																							}}
																						></button>
																					</div>
																				)}
																				<div
																					className="accordion"
																					id="accordionExample"
																				>
																					<div
																						className="card cardStyle ml-2"
																						style={{
																							border: 'none'
																						}}
																					>
																						<h6
																							className="pl-1"
																							style={{
																								'font-size':
																									'inherit'
																							}}
																						>
																							Group
																						</h6>

																						<div
																							className="container-fluid"
																							style={{
																								border: '1px solid #E6EBF1',
																								'border-radius':
																									'5px'
																							}}
																						>
																							<div className="text-center mt-3">
																								<span className="col-6">
																									<label>
																										Trigger
																									</label>
																									<span className="requiredRed pr-2">
																										*
																									</span>
																									<Field
																										type="checkbox"
																										name={`mtmbEligibility.packageMultibuyEligibility.${index}.group.${i}.trigger`}
																										className="p-3"
																										onClick={() =>
																											triggerValueChange(
																												index,
																												i
																											)
																										}
																									/>
																								</span>
																								<span className="col-6">
																									<label>
																										Get
																										Reward
																									</label>
																									<span className="requiredRed pr-2">
																										*
																									</span>
																									<Field
																										type="checkbox"
																										name={`mtmbEligibility.packageMultibuyEligibility.${index}.group.${i}.getReward`}
																										className="p-3"
																										onClick={() =>
																											getRewardValueChange(
																												index,
																												i
																											)
																										}
																									/>
																								</span>
																							</div>
																							{/* <MTMBProductTable data={data} onProductTableChange={(val) => onProductTableChange(val, index, i)} /> */}
																							{fieldMTMB
																								.packageMultibuyEligibility[
																								index
																							]
																								.thresholdTypeCode ==
																								'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
																							fieldMTMB
																								.packageMultibuyEligibility[
																								index
																							]
																								.thresholdTypeCode ==
																								'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
																							fieldMTMB
																								.packageMultibuyEligibility[
																								index
																							]
																								.thresholdTypeCode ==
																								'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
																							fieldMTMB
																								.packageMultibuyEligibility[
																								index
																							]
																								.thresholdTypeCode ==
																								'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ? (
																								<div className="purchaseContainer">
																									<div className="purchaseRow align-self-start d-block group-threshold-reward">
																										{fieldMTMB
																											.packageMultibuyEligibility[
																											index
																										]
																											.thresholdTypeCode ==
																											'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD' ||
																										fieldMTMB
																											.packageMultibuyEligibility[
																											index
																										]
																											.thresholdTypeCode ==
																											'HIERARCHY_PACKAGE_QTY_THRESHOLD' ? (
																											<CustomInputText
																												name={`mtmbEligibility.packageMultibuyEligibility.${index}.group.${i}.memberGroupThresholdQuantity`}
																												required={
																													false
																												}
																												label={
																													'Group Threshold Quantity'
																												}
																												validate={(
																													value
																												) =>
																													customValidateMemberGroupThresholdQuantity(
																														value,
																														index,
																														i
																													)
																												}
																												smallText=""
																												disabled={
																													!fieldMTMB
																														.packageMultibuyEligibility[
																														index
																													]
																														.group[
																														i
																													]
																														.trigger
																												}
																											/>
																										) : (
																											<CustomInputText
																												name={`mtmbEligibility.packageMultibuyEligibility.${index}.group.${i}.memberGroupThresholdAmount`}
																												required={
																													false
																												}
																												label={
																													'Group Threshold Amount'
																												}
																												validate={(
																													value
																												) =>
																													customValidateMemberGroupThresholdAmount(
																														value,
																														index,
																														i
																													)
																												}
																												smallText=""
																												disabled={
																													!fieldMTMB
																														.packageMultibuyEligibility[
																														index
																													]
																														.group[
																														i
																													]
																														.trigger
																												}
																											/>
																										)}
																									</div>
																									<div className="purchaseRow align-self-start d-block group-threshold-reward">
																										<CustomInputText
																											name={`mtmbEligibility.packageMultibuyEligibility.${index}.group.${i}.memberGroupRewardQuantity`}
																											required={
																												false
																											}
																											label={
																												'Group Reward Quantity'
																											}
																											validate={(
																												value
																											) =>
																												customValidateMemberGroupRewardQuantity(
																													value,
																													index,
																													i
																												)
																											}
																											smallText=""
																											disabled={
																												!fieldMTMB
																													.packageMultibuyEligibility[
																													index
																												]
																													.group[
																													i
																												]
																													.getReward
																											}
																										/>
																									</div>
																								</div>
																							) : null}
																							{/* <Field name={`packageMultibuyEligibility.${index}.group.${i}.multibuyProduct`} component={ProductSetting} /> */}
																							<div>
																								{/* <div className="row justify-content-center">
                                                    <label className="p-2">
                                                      Product ID
                                                  </label>
                                                    <Field
                                                      className="form-control col-md-3"
                                                      name={`mtmbEligibility.packageMultibuyEligibility.${index}.group.${i}.productID`}
                                                      type="text"
                                                    />
                                                    <button
                                                      type="button"
                                                      className="btn btn-outline-secondary ml-3 h-25"

                                                      onClick={() => {
                                                        let errFlag = false;
                                                        errMsg = "";
                                                        fieldMTMB.packageMultibuyEligibility[
                                                          index
                                                        ].group.map((g) => {
                                                          g.multibuyProduct.map(
                                                            (item) => {
                                                              if (
                                                                item.productID ==
                                                                fieldMTMB
                                                                  .packageMultibuyEligibility[
                                                                  index
                                                                ].group[i].productID
                                                              ) {
                                                                errFlag = true;
                                                              }
                                                            }
                                                          );
                                                        })
                                                        if (errFlag) {
                                                          errMsg =
                                                            "Product ID:" +
                                                            fieldMTMB
                                                              .packageMultibuyEligibility[
                                                              index
                                                            ].group[i].productID +
                                                            " already added";
                                                        } else {
                                                          fieldMTMB.packageMultibuyEligibility[
                                                            index
                                                          ].group[
                                                            i
                                                          ].multibuyProduct.push({
                                                            productID:
                                                              fieldMTMB
                                                                .packageMultibuyEligibility[
                                                                index
                                                              ].group[i]
                                                                .productID,
                                                            maximumPrice: 999999.99,
                                                            minimumPrice: 0.01,
                                                          });
                                                          fieldMTMB.packageMultibuyEligibility[
                                                            index
                                                          ].group[i].productID =
                                                            "";
                                                          setFieldValue("", "")
                                                        }
                                                      }}
                                                    >
                                                      +
                                                  </button>
                                                  </div> */}
																								<div className="text-center text-danger">
																									{errMsg ==
																									'Product ID:' +
																										fieldMTMB
																											.packageMultibuyEligibility[
																											index
																										]
																											.group[
																											i
																										]
																											.productID +
																										' already added'
																										? errMsg
																										: null}
																								</div>

																								<div className="margin10">
																									{/* <MTMBProductTable data={data} onProductTableChange={(val) => onProductTableChange(val, index, i) } group={MTMBProductTableData.packageMultibuyEligibility[index].group} /> */}
																									{/* <ProductTableBoostrap  data={MTMBProductTableData.packageMultibuyEligibility[index].group[i].multibuyProduct} keys={keys}
                                                      defaultValueForAdd={defaultValueForAddMTMB}
                                                      onProductTableChange={(val) => onProductTableChange(val, index, i) }
                                                      eligibility={'MTMB'} 
                                                      group={MTMBProductTableData.packageMultibuyEligibility[index].group}/> */}
																									{/* <SellingHierarchyTable data={data} onmerchandizeTableChange={onmerchandizeTableChange} /> */}
																									<MerchTableBootstrapSCALE
																										data={
																											ScaleMerchTableData
																												.packageMultibuyEligibility[
																												index
																											]
																												.group[
																												i
																											]
																												.merchandizeHierarchyGroup
																										}
																										keys={
																											keysMerch
																										}
																										defaultValueForAdd={
																											defaultValueForAddMG
																										}
																										onMerchTableChange={(
																											val
																										) =>
																											onMerchTableChange(
																												val,
																												index,
																												i
																											)
																										}
																										eligibility={
																											'packageMultibuyEligibility'
																										}
																										group={
																											ScaleMerchTableData
																												.packageMultibuyEligibility[
																												index
																											]
																												.group
																										}
																										isMerchTableRepetative={
																											true
																										}
																										currentGroupIndex={
																											i
																										}
																										currentPkgIndex={
																											index
																										}
																										ScaleMerchTableData={
																											ScaleMerchTableData
																										}
																										brandEligibility={
																											JSON.parse(
																												props.fullValue
																											)
																												.promotionPolicy
																												.promotionApplicableEligibility
																												.brandEligibility
																										}
																									/>

																									{/* <CustomFieldArrayInputMTMB
                                                      productID={
                                                        fieldMTMB
                                                          .packageMultibuyEligibility[
                                                          index
                                                        ].group[i].productID
                                                      }
                                                      value={
                                                        fieldMTMB
                                                          .packageMultibuyEligibility[
                                                          index
                                                        ].group[i].multibuyProduct
                                                      }
                                                      index={index}
                                                      i={i}
                                                    /> */}
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</>
																		))}
																	</>
																)}
															</FieldArray>
														</>
													) : null}
												</div>

												<div className="accordion" id="accordionExample">
													<div
														className="card pt-2 cardStyle benefit-style"
														style={{ border: 'none' }}
													>
														<h5 className="mt-ml">Benefit Settings</h5>
														<hr />
														<BenefitSettingsMTMB index={index} />
														<CustomSelect
															name="mtmbEligibility.packageMultibuyEligibility.applyBenefitOn"
															required={true}
															label="Apply Benefit On"
															options={getApplyBenefitDropdown()}
														/>
														<br />
														{fieldMTMB.packageMultibuyEligibility[index]
															.eligibilityType != '' ? (
															// <p className="text-center">Eligibility Type:  {elType[fieldMTMB.packageMultibuyEligibility[index].eligibilityType]}</p>
															<div className="container">
																<div className="rowContainer">
																	<div className="customLabelContainer">
																		<label
																			htmlFor="forprogramID"
																			className="col	col-form-label"
																		>
																			Eligibility Type
																		</label>
																	</div>
																	<div className="customInputContainer">
																		<div className="row">
																			<input
																				value={
																					elType[
																						fieldMTMB
																							.packageMultibuyEligibility[
																							index
																						]
																							.eligibilityType
																					]
																				}
																				disabled={true}
																				className="form-control "
																			/>
																		</div>
																	</div>
																</div>
															</div>
														) : null}
													</div>
												</div>
											</div>
											{/* </div> */}
										</Element>
									</div>
								</>
							))}
						</>
					)}
				</FieldArray>

				{/* <div class="accordion" id="accordionExample">
          <div class="card" style={{ border: "none" }}>
            <CollapseHeaderBar
              text={localConst.ProductSetting}
              headingId={"headingThree"}
              dataTarget={"#collapseThree"}
            />
            <CollapseBody id="collapseThree" headingId={"headingThree"}>
            </CollapseBody>
          </div>
        </div> */}
				{/* <div class="accordion" id="accordionExample">
          <div class="card" style={{ border: "none" }}>
            <CollapseHeaderBar
              text={localConst.ExclusionsSetting}
              headingId={"headingES"}
              dataTarget={"#collapseES"}
            />
            <CollapseBody id="collapseES" headingId={"headingES"}>
              <Field name="exclusions" component={ExclusionSetting} />
            </CollapseBody>
          </div>
        </div> */}
				<div className="accordion" id="accordionExample">
					{/* <div class="card" style={{ border: "none" }}> */}
					<Element
						name="scrollToPaymentConditions"
						class="card"
						style={{ border: 'none' }}
					>
						<CollapseHeaderBar
							text={localConst.PaymentConditions}
							headingId={'headingFour'}
							dataTarget={'#collapseFour'}
						/>
						<CollapseBody id="collapseFour" headingId={'headingFour'}>
							<PaymentConditionMTMB fullVal={props.fullValue} />
						</CollapseBody>
						{/* </div> */}
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					{/* <div class="card" style={{ border: "none" }}> */}
					<Element
						name="scrollToCouponConditions"
						class="card"
						style={{ border: 'none' }}
					>
						<CollapseHeaderBar
							text={localConst.CouponConditions}
							headingId={'headingFive'}
							dataTarget={'#collapseFive'}
						/>
						<CollapseBody id="collapseFive" headingId={'headingFive'}>
							<CouponConditionMTMB fullVal={props.fullValue} />
						</CollapseBody>
						{/* </div> */}
					</Element>
				</div>
				{/* <div class="accordion" id="accordionExample">
          <div class="card" style={{ border: "none" }}>
            <CollapseHeaderBar
              text={localConst.benefitSettings}
              headingId={"headingTwo"}
              dataTarget={"#collapseTwo"}
            />
            <CollapseBody id="collapseTwo" headingId={"headingTwo"}>
              <Field name="benefit" component={BenefitSettings} />
            </CollapseBody>
          </div>
        </div> */}
			</Form>
		</div>
	);
};

export default EligibilityForm;
