import React, { useEffect, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import 'bootstrap/dist/css/bootstrap.css';
import '../BasicDetails.scss';
import CustomSelect from '../customfields/CustomSelect';
import MerchTableBootstrapSCALE from '../MerchTableBootstrapSCALE';
import ProductTableBoostrap from '../ProductTableBootstrap';
import AuditProductTablePackage from '../Audit/AuditProductTablePackage';
import AuditSHTablePackage from '../Audit/AuditSHTablePackage';
import CustomInputText from '../customfields/CustomInputText';
import CustomCheckbox1 from '../customfields/CustomCheckbox1';

let PKGProductTableData;
const keys = ['productID', 'minimumPrice', 'maximumPrice'];
const keysMerch = ['merchandizeGroupId', 'minimumPrice', 'maximumPrice'];
const packageType = [
	{
		value: 'PRODUCT',
		name: 'Product'
	},
	{
		value: 'SH',
		name: 'Selling Hierarchy'
	}
];
const defaultValueForAddPP = {
	maximumPrice: '9999999.99',
	minimumPrice: '0.01'
	// trigger: 'Y',
	// getReward: 'Y',
};

const defaultValueForAddPM = {
	maximumPrice: '9999999.99',
	minimumPrice: '0.01'
	// trigger: 'Y',
	// getReward: 'Y',
};

let errMsg = '';

const getThresholdDropdown = (thresholdTypeCodeOptions) => {
	return thresholdTypeCodeOptions.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const EarnBurnGroupArray = ({ ...props }) => {
	let { customArrayName } = props;
	const fieldPKG = props.fieldPKG;
	const [showTable, setshowTable] = useState(true);
	const { setFieldValue } = useFormikContext();
	// const [tempProductID, setTempProductID] = useState('');

	// const [showExpireOn, setShowExpireOn] = useState(false);

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;

	if (props.dataPKGtable && Object.keys(props.dataPKGtable).length != 0) {
		PKGProductTableData = props.dataPKGtable;
	} else {
		if (!PKGProductTableData) {
			PKGProductTableData = {
				groupDetail: [
					{
						multibuyProduct: [],
						merchandizeHierarchyGroup: []
					}
				]
			};
		}
	}

	useEffect(() => {
		let isEarnArray = [];
		fieldPKG[customArrayName].map((grp) => {
			if (grp.isEarn) {
				isEarnArray.push(grp);
			}
		});
		if (isEarnArray.length > 0) {
			// setShowExpireOn(true);
		} else {
			// setShowExpireOn(false);
			if (fieldPKG.expireOn) {
				fieldPKG.expireOn.expireOnType = undefined;
				fieldPKG.expireOn.date = undefined;
				fieldPKG.expireOn.days = undefined;
				fieldPKG.expireOn.months = undefined;
				fieldPKG.expireOn.hours = undefined;
			}
		}
	}, [fieldPKG[customArrayName]]);

	useEffect(() => {
		if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_AMOUNT_THRESHOLD'
		) {
			fieldPKG[customArrayName][0].earnQuantity = undefined;
			fieldPKG[customArrayName][0].burnQuantity = undefined;
			if (
				!fieldPKG[customArrayName][0].earnQuantity &&
				fieldPKG[customArrayName][0].isEarn == true
			) {
				if (!fieldPKG[customArrayName][0].earnAmount)
					fieldPKG[customArrayName][0].earnAmount = '0.01';
			}
			if (
				!fieldPKG[customArrayName][0].earnQuantity &&
				fieldPKG[customArrayName][0].isBurn == true
			) {
				if (!fieldPKG[customArrayName][0].burnAmount)
					fieldPKG[customArrayName][0].burnAmount = '0.01';
			}
		}
		if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_QTY_THRESHOLD'
		) {
			fieldPKG[customArrayName][0].earnAmount = undefined;
			fieldPKG[customArrayName][0].burnAmount = undefined;
			if (
				!fieldPKG[customArrayName][0].earnAmount &&
				fieldPKG[customArrayName][0].isEarn == true
			) {
				if (!fieldPKG[customArrayName][0].earnQuantity)
					fieldPKG[customArrayName][0].earnQuantity = '1';
			}
			if (
				!fieldPKG[customArrayName][0].earnAmount &&
				fieldPKG[customArrayName][0].isBurn == true
			) {
				if (!fieldPKG[customArrayName][0].burnQuantity)
					fieldPKG[customArrayName][0].burnQuantity = '1';
			}
		}
	}, [fieldPKG.thresholdTypeCode]);
	const isEarnValueChange = (i) => {
		if (i == 0) {
			if (
				fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'EARN_BURN_AMOUNT_THRESHOLD'
			) {
				fieldPKG[customArrayName][0].earnQuantity = undefined;
				if (!fieldPKG[customArrayName][0].isEarn == true) {
					if (!fieldPKG[customArrayName][0].earnAmount)
						fieldPKG[customArrayName][0].earnAmount = '0.01';
				}
			}
			if (
				fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'EARN_BURN_QTY_THRESHOLD'
			) {
				fieldPKG[customArrayName][0].earnAmount = undefined;
				if (!fieldPKG[customArrayName][0].isEarn == true) {
					if (!fieldPKG[customArrayName][0].earnQuantity)
						fieldPKG[customArrayName][0].earnQuantity = '1';
				}
			}
		}
		if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_QTY_THRESHOLD'
		) {
			if (!fieldPKG[customArrayName][i]?.isEarn == true) {
				if (fieldPKG[customArrayName][i].earnQuantity == '') {
					fieldPKG[customArrayName][i].earnQuantity = '1';
				}
				fieldPKG[customArrayName][i].earnAmount = '';
			} else {
				fieldPKG[customArrayName][i].earnQuantity = '';
				fieldPKG[customArrayName][i].earnAmount = '';
			}
		} else if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_AMOUNT_THRESHOLD'
		) {
			if (!fieldPKG[customArrayName][i]?.isEarn == true) {
				if (fieldPKG[customArrayName][i].earnAmount == '') {
					fieldPKG[customArrayName][i].earnAmount = '0.01';
				}
				fieldPKG[customArrayName][i].earnQuantity = '';
			} else {
				fieldPKG[customArrayName][i].earnAmount = '';
				fieldPKG[customArrayName][i].earnQuantity = '';
			}
		}
	};
	const isBurnValueChange = (i) => {
		if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_QTY_THRESHOLD'
		) {
			if (!fieldPKG[customArrayName][i].isBurn == true) {
				if (fieldPKG[customArrayName][i].burnQuantity == '') {
					fieldPKG[customArrayName][i].burnQuantity = '1';
				}
				fieldPKG[customArrayName][i].burnAmount = '';
			} else {
				fieldPKG[customArrayName][i].burnQuantity = '';
				fieldPKG[customArrayName][i].burnAmount = '';
			}
		} else if (
			fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
			fieldPKG.thresholdTypeCode == 'EARN_BURN_AMOUNT_THRESHOLD'
		) {
			if (!fieldPKG[customArrayName][i].isBurn == true) {
				if (fieldPKG[customArrayName][i].burnAmount == '') {
					fieldPKG[customArrayName][i].burnAmount = '0.01';
				}
				fieldPKG[customArrayName][i].burnQuantity = '';
			} else {
				fieldPKG[customArrayName][i].burnAmount = '';
				fieldPKG[customArrayName][i].burnQuantity = '';
			}
		}
	};

	const onProductTableChange = (val, j) => {
		// MTMBProductTableData.earnBurnEligibility[i][customArrayName][j] = val
		if (val.length != 0) {
			PKGProductTableData[customArrayName][j].multibuyProduct = val;
		} else {
			PKGProductTableData[customArrayName][j].multibuyProduct = [];
		}

		// if(val.length!=0){
		//   props.setMtmbTable(PKGProductTableData);
		// }
		props.onPKGProductTableChange(PKGProductTableData);
	};

	const onMerchTableChange = (val, j) => {
		//

		// MTMBProductTableData.earnBurnEligibility[i].group[j] = val
		// if(val.length!=0){
		//PKGMerchTableData[customArrayName][j].merchandizeHierarchyGroup = val
		//  }
		if (val.length != 0) {
			PKGProductTableData[customArrayName][j].merchandizeHierarchyGroup = val;
		} else {
			PKGProductTableData[customArrayName][j].merchandizeHierarchyGroup = [];
		}
		//

		props.onPKGProductTableChange(PKGProductTableData);
	};

	/*  const onMerchTableChange = (val, j) => {
    
    if (val.length != 0) {
      PKGProductTableData[customArrayName][j].merchandizeHierarchyGroup = val;
    } else {
      PKGProductTableData[customArrayName][j].merchandizeHierarchyGroup = []
    }
    
    
    props.onPKGProductTableChange(PKGProductTableData);
    //props.onMerchTableDataChange(PKGProductTableData)
  } */

	const customValidateQuantity = (value, i) => {
		let error;
		if (
			(fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'EARN_BURN_QTY_THRESHOLD') &&
			fieldPKG[customArrayName][i].isEarn
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}$/)) {
					error = 'Quantity value should be between 1 to 999999';
				}
			} else {
				//  error = "Please define a Quantity value"
			}
		}
		return error;
	};
	//amount
	const customValidateAmount = (value, i) => {
		let error;
		if (
			(fieldPKG.thresholdTypeCode == 'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
				fieldPKG.thresholdTypeCode == 'EARN_BURN_AMOUNT_THRESHOLD') &&
			fieldPKG[customArrayName][i].isEarn
		) {
			if (value) {
				if (!value.toString().match(/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/)) {
					error = 'Amount value should be between 0.01 to 999999.99';
				}
			} else {
				// error = "Please define a Amount value"
			}
		}
		return error;
	};

	const packageTypeChange = (e, i) => {
		if (fieldPKG[customArrayName][i].packageType == 'PRODUCT')
			fieldPKG[customArrayName][i].packageType = 'SH';
		else fieldPKG[customArrayName][i].packageType = 'PRODUCT';
		fieldPKG[customArrayName][i].multibuyProduct = [];
		fieldPKG[customArrayName][i].merchandizeHierarchyGroup = [];

		PKGProductTableData[customArrayName][i] = fieldPKG[customArrayName][i];
		/*  {
           "multibuyProduct": [],
           "merchandizeHierarchyGroup": [],    
         }
        */
		setFieldValue('', '');
	};

	return (
		<div
			className={
				JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow
					? 'Container viewOfferContainer'
					: 'Container'
			}
		>
			<FieldArray name={`earnBurnEligibility.${customArrayName}`}>
				{({ remove }) => (
					<>
						<div className="accordion mtmb-acrdn mr-2 p-3" id="accordionGroupExample">
							{fieldPKG[customArrayName].map((item, i) => (
								<>
									<div className="d-flex">
										<div
											className={
												customArrayName.indexOf('Added') !== -1
													? 'card mtmb-card m-2 borderForAdded'
													: customArrayName.indexOf('Removed') !== -1
													? 'card mtmb-card m-2 borderForRemoved'
													: 'card mtmb-card m-2'
											}
											style={{ border: 'none', width: 'calc(100% - 50px)' }}
										>
											<div
												className={
													i + 1 == fieldPKG[customArrayName].length
														? 'card-head mtmb-crd-head group'
														: 'card-head mtmb-crd-head group collapsed'
												}
												data-toggle="collapse"
												data-target={`#mtmbPackageMultibuycollapseGroup${i}`}
											>
												<h2 className="mb-0">
													<div className="item-before pointer-events-auto">
														{`Group ${i + 1}`}
													</div>
												</h2>
											</div>

											<div
												id={`mtmbPackageMultibuycollapseGroup${i}`}
												className={
													i + 1 == fieldPKG[customArrayName].length
														? 'collapse show mtmb-collapse'
														: 'collapse mtmb-collapse'
												}
												data-parent={`#accordionGroupExample`}
											>
												<CustomSelect
													name={`earnBurnEligibility.${customArrayName}.${i}.packageType`}
													style={{ 'margin-bottom': '20px' }}
													required={true}
													label="Package Type"
													options={getThresholdDropdown(packageType)}
													onChange={(e) => packageTypeChange(e, i)}
													// smallText={itemHelp.ThresholdTypeCodeMBG}
												/>

												<div className="card-body">
													<div className="row mt-3 mtmbTriggerReward">
														{/*   <span className="col-2 text-right">
                                                            <label>Is Earn</label>
                                                            <span className="requiredRed pr-2">
                                                              *
                                                          </span>
                                    <Field
                                      type="checkbox"
                                      name={`earnBurnEligibility.${customArrayName}.${i}.isEarn`}
                                      className="p-3"
                                      onClick={() => isEarnValueChange(i)}
                                      disabled={fieldPKG[customArrayName][i].isBurn}
                                    />
                                  </span> */}
														<span>
															<CustomCheckbox1
																required={true}
																name={`earnBurnEligibility.${customArrayName}.${i}.isEarn`}
																className="p-3"
																label="Is Earn"
																onClick={() => isEarnValueChange(i)}
																disabled={
																	fieldPKG[customArrayName][i]
																		.isBurn
																}
															/>
														</span>
														{/* <span className="col-6">
                                    <label>Is Burn</label>
                                    <span className="requiredRed pr-2">*</span>
                                    <Field
                                      type="checkbox"
                                      name={`earnBurnEligibility.${customArrayName}.${i}.isBurn`}
                                      className="p-3"
                                      onClick={() => isBurnValueChange(i)}
                                      disabled={fieldPKG[customArrayName][i].isEarn}
                                    />
                                  </span> */}
														<span>
															<CustomCheckbox1
																required={true}
																name={`earnBurnEligibility.${customArrayName}.${i}.isBurn`}
																className="p-3"
																label="Is Burn"
																onClick={() => isBurnValueChange(i)}
																disabled={
																	fieldPKG[customArrayName][i]
																		.isEarn
																}
															/>
														</span>
													</div>
													{/* <MTMBProductTable data={data} onProductTableChange={(val) => onProductTableChange(val, index, i)} /> */}
													{
														<div
															className={
																isAuditFlw &&
																!(
																	customArrayName.indexOf(
																		'Added'
																	) !== -1 ||
																	customArrayName.indexOf(
																		'Removed'
																	) !== -1
																)
																	? 'purchaseContainer d-block'
																	: customArrayName.indexOf(
																			'Added'
																	  ) !== -1 ||
																	  customArrayName.indexOf(
																			'Removed'
																	  ) !== -1
																	? 'purchaseContainer d-flex flexRow'
																	: 'purchaseContainer'
															}
															name="PurchaseandRewardBlock"
														>
															<div className="purchaseRow align-self-start d-block">
																{fieldPKG.thresholdTypeCode ==
																	'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
																fieldPKG.thresholdTypeCode ==
																	'EARN_BURN_QTY_THRESHOLD' ? (
																	<CustomInputText
																		name={`earnBurnEligibility.${customArrayName}.${i}.earnQuantity`}
																		required={false}
																		validate={(value) =>
																			customValidateQuantity(
																				value,
																				i
																			)
																		}
																		label={'Earn Quantity'}
																		flag={
																			customArrayName.indexOf(
																				'Added'
																			) !== -1 ||
																			customArrayName.indexOf(
																				'Removed'
																			) !== -1
																				? 'show'
																				: ''
																		}
																		smallText=""
																		disabled={
																			!fieldPKG[
																				customArrayName
																			][i].isEarn
																		}
																	/>
																) : (
																	<CustomInputText
																		name={`earnBurnEligibility.${customArrayName}.${i}.earnAmount`}
																		validate={(value) =>
																			customValidateAmount(
																				value,
																				i
																			)
																		}
																		required={false}
																		label={'Earn Amount'}
																		flag={
																			customArrayName.indexOf(
																				'Added'
																			) !== -1 ||
																			customArrayName.indexOf(
																				'Removed'
																			) !== -1
																				? 'show'
																				: ''
																		}
																		smallText=""
																		disabled={
																			!fieldPKG[
																				customArrayName
																			][i].isEarn
																		}
																	/>
																)}
															</div>
															<div className="purchaseRow align-self-start d-block">
																{fieldPKG.thresholdTypeCode ==
																	'EARN_BURN_INTERVAL_QTY_THRESHOLD' ||
																fieldPKG.thresholdTypeCode ==
																	'EARN_BURN_QTY_THRESHOLD' ? (
																	<CustomInputText
																		name={`earnBurnEligibility.${customArrayName}.${i}.burnQuantity`}
																		required={false}
																		validate={(value) =>
																			customValidateQuantity(
																				value,
																				i
																			)
																		}
																		label={'Burn Quantity'}
																		smallText=""
																		disabled={
																			!fieldPKG[
																				customArrayName
																			][i].isBurn
																		}
																	/>
																) : (
																	<CustomInputText
																		name={`earnBurnEligibility.${customArrayName}.${i}.burnAmount`}
																		validate={(value) =>
																			customValidateAmount(
																				value,
																				i
																			)
																		}
																		required={false}
																		label={'Burn Amount'}
																		smallText=""
																		disabled={
																			!fieldPKG[
																				customArrayName
																			][i].isBurn
																		}
																	/>
																)}
															</div>
														</div>
													}
													{/* <Field name={`earnBurnEligibility.${index}.${customArrayName}.${i}.multibuyProduct`} component={ProductSetting} /> */}
													<div>
														<div className="text-center text-danger">
															{errMsg ==
															'Product ID:' +
																fieldPKG[customArrayName][i]
																	?.productID +
																' already added'
																? errMsg
																: null}
														</div>

														<div className="margin10">
															{customArrayName.indexOf('Added') !==
																-1 ||
															customArrayName.indexOf('Removed') !==
																-1 ? (
																<>
																	{fieldPKG[customArrayName] &&
																	fieldPKG[customArrayName][i]
																		.packageType ==
																		'PRODUCT' ? (
																		<div
																			className={
																				customArrayName.indexOf(
																					'Added'
																				) !== -1
																					? 'dataAdded'
																					: 'dataRemoved'
																			}
																		>
																			<ProductTableBoostrap
																				keys={keys}
																				data={
																					fieldPKG[
																						customArrayName
																					][i]
																						?.multibuyProduct
																				}
																				defaultValueForAdd={
																					defaultValueForAddPP
																				}
																				onProductTableChange={() => {}}
																				eligibility={
																					'earnBurnEligibility'
																				}
																				i={i}
																				groupDetail={
																					fieldPKG[
																						customArrayName
																					]
																				}
																				brandEligibility={
																					JSON.parse(
																						props.fullValue
																					)
																						.promotionPolicy
																						.promotionApplicableEligibility
																						.brandEligibility
																				}
																			/>
																		</div>
																	) : (
																		<div
																			className={
																				customArrayName.indexOf(
																					'Added'
																				) !== -1
																					? 'dataAdded'
																					: 'dataRemoved'
																			}
																		>
																			<MerchTableBootstrapSCALE
																				keys={keysMerch} //data={data}
																				data={
																					fieldPKG[
																						customArrayName
																					][i]
																						?.merchandizeHierarchyGroup
																				}
																				defaultValueForAdd={
																					defaultValueForAddPM
																				}
																				onMerchTableChange={() => {}}
																				eligibility={
																					'earnBurnEligibility'
																				}
																				groupDetail={
																					fieldPKG[
																						customArrayName
																					]
																				}
																				PKGMerchTableData={
																					fieldPKG
																				}
																				isMerchTableRepetative={
																					true
																				}
																				currentGroupIndex={
																					i
																				}
																			/>
																		</div>
																	)}
																</>
															) : JSON.parse(
																	sessionStorage.getItem(
																		'typeOfOffersFlow'
																	)
															  ).isAuditFlow &&
															  customArrayName == 'groupDetail' ? (
																fieldPKG[customArrayName] &&
																fieldPKG[customArrayName][i]
																	.packageType == 'PRODUCT' ? (
																	<AuditProductTablePackage
																		customArrayName="groupDetail"
																		PKGProductTableData={
																			PKGProductTableData
																		}
																		oldJson={
																			props.dataPKGtable
																				.groupDetail[i]
																				?.multibuyProduct
																		}
																		i={i}
																		newJson={
																			JSON.parse(
																				sessionStorage.getItem(
																					'NewPolicy'
																				)
																			).promotionPolicy
																				.priceDerivationRuleEligibility
																				.earnBurnEligibility
																				.groupDetail[i]
																				?.multibuyProduct
																		}
																	/>
																) : (
																	<AuditSHTablePackage
																		customArrayName="groupDetail"
																		PKGProductTableData={
																			PKGProductTableData
																		}
																		oldJson={
																			props.dataPKGtable
																				.groupDetail[i]
																				?.merchandizeHierarchyGroup
																		}
																		i={i}
																		newJson={
																			JSON.parse(
																				sessionStorage.getItem(
																					'NewPolicy'
																				)
																			).promotionPolicy
																				.priceDerivationRuleEligibility
																				.earnBurnEligibility
																				.groupDetail[i]
																				?.merchandizeHierarchyGroup
																		}
																	/>
																)
															) : (
																<>
																	{fieldPKG[customArrayName][i]
																		.packageType ==
																	'PRODUCT' ? (
																		showTable ? (
																			<ProductTableBoostrap
																				keys={keys}
																				data={
																					PKGProductTableData[
																						customArrayName
																					][i]
																						?.multibuyProduct
																				}
																				defaultValueForAdd={
																					defaultValueForAddPP
																				}
																				onProductTableChange={(
																					val
																				) =>
																					onProductTableChange(
																						val,
																						i
																					)
																				}
																				eligibility={
																					'earnBurnEligibility'
																				}
																				i={i}
																				groupDetail={
																					PKGProductTableData[
																						customArrayName
																					]
																				}
																				brandEligibility={
																					JSON.parse(
																						props.fullValue
																					)
																						.promotionPolicy
																						.promotionApplicableEligibility
																						.brandEligibility
																				}
																			/>
																		) : null
																	) : showTable ? (
																		<MerchTableBootstrapSCALE
																			keys={keysMerch} //data={data}
																			data={
																				PKGProductTableData[
																					customArrayName
																				][i]
																					?.merchandizeHierarchyGroup
																			}
																			defaultValueForAdd={
																				defaultValueForAddPM
																			}
																			onMerchTableChange={(
																				val
																			) =>
																				onMerchTableChange(
																					val,
																					i
																				)
																			}
																			eligibility={
																				'earnBurnEligibility'
																			}
																			groupDetail={
																				PKGProductTableData[
																					customArrayName
																				]
																			}
																			PKGMerchTableData={
																				PKGProductTableData
																			}
																			isMerchTableRepetative={
																				true
																			}
																			currentGroupIndex={i}
																		/>
																	) : null}
																</>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
										{fieldPKG[customArrayName].length > 1 ? (
											<button
												type="button"
												className="addDeleteBtn mt-3"
												style={{ 'background-color': 'unset' }}
												onClick={() => {
													remove(i);
													PKGProductTableData[customArrayName].splice(
														i,
														1
													);
													props.onPKGProductTableChange(
														PKGProductTableData
													);
													//PKGMerchTableData[customArrayName].splice(i,1);
													props.onPKGProductTableChange(
														PKGProductTableData
													);
													setshowTable(false);
													setTimeout(() => {
														setshowTable(true);
													}, 50);
													setFieldValue('', '');
												}}
											></button>
										) : null}
									</div>
									{i + 1 == fieldPKG[customArrayName]?.length ? (
										<div className="d-flex justify-content-end mt-2">
											<button
												type="button"
												className="createGroupBtn"
												onClick={() => {
													PKGProductTableData[customArrayName].push({
														merchandizeHierarchyGroup: [],
														multibuyProduct: []
													});

													fieldPKG[customArrayName].push({
														multibuyProduct: [],
														merchandizeHierarchyGroup: [],
														isEarn: false,
														isBurn: true,
														packageType: 'PRODUCT',
														earnAmount:
															fieldPKG.thresholdTypeCode ==
																'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
															fieldPKG.thresholdTypeCode ==
																'EARN_BURN_AMOUNT_THRESHOLD'
																? ''
																: undefined,
														earnQuantity:
															fieldPKG.thresholdTypeCode ==
																'EARN_BURN_QTY_THRESHOLD' ||
															fieldPKG.thresholdTypeCode ==
																'EARN_BURN_INTERVAL_QTY_THRESHOLD'
																? ''
																: undefined,
														burnAmount:
															fieldPKG.thresholdTypeCode ==
																'EARN_BURN_INTERVAL_AMOUNT_THRESHOLD' ||
															fieldPKG.thresholdTypeCode ==
																'EARN_BURN_AMOUNT_THRESHOLD'
																? '0.01'
																: undefined,
														burnQuantity:
															fieldPKG.thresholdTypeCode ==
																'EARN_BURN_QTY_THRESHOLD' ||
															fieldPKG.thresholdTypeCode ==
																'EARN_BURN_INTERVAL_QTY_THRESHOLD'
																? '1'
																: undefined
													});

													// setTempProductID('');
													setFieldValue('', '');
												}}
											>
												<span className="pr-2">+</span>Add Group
											</button>
										</div>
									) : null}
								</>
							))}
						</div>
					</>
				)}
			</FieldArray>
		</div>
	);
};

export default EarnBurnGroupArray;
