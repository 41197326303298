import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {
	textFilter,
	Comparator,
	FILTER_TYPES,
	customFilter,
	numberFilter
} from 'react-bootstrap-table2-filter';
import './AuditTable.css';
// import ToolkitProvider, { Search } from '../../utils/reactBootstrapTable2Toolkit';
import DateFilter from './DateFilter';
class AuditVersionsTable extends Component {
	constructor() {
		super();
		this.state = {
			// For displaying data
			columns: [
				{
					dataField: 'promotionVersion',
					text: '',
					sort: true,
					headerAlign: 'center',
					//headerFormatter: this.headerFormatter,
					filter: numberFilter({
						placeholder: 'Search Version', // placeholder for number input
						style: { display: 'inline-grid' }, // custom the style on number filter
						comparatorClassName: 'd-none', // custom the class on comparator select
						defaultValue: { number: '', comparator: Comparator.EQ }, // default value
						id: 'id' // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
					}),
					formatter: this.versionFormatter
				},
				{
					dataField: 'action',
					text: '',
					sort: true,
					headerAlign: 'center',
					formatter: this.actionFormatter
				},
				{
					dataField: 'modifiedBy',
					text: '',
					headerAlign: 'center',
					filter: textFilter({
						delay: 500, // default is 500ms
						style: {
							//   backgroundColor: 'yellow'
						},
						className: 'test-classname',
						placeholder: 'Search Modified By',
						onClick: (e) => {}
					}),
					formatter: this.modifiedByFormatter
				},
				{
					dataField: 'modifiedOn',
					text: '',
					headerAlign: 'center',
					formatter: this.dateFormatter,

					headerStyle: { width: '400px' },
					headerClass: 'auditDateFiler',
					// filter: dateFilter({
					//     defaultValue: {comparator: Comparator.GT}
					//     })
					// filter: dateFilter({
					//     delay: 400,
					//     placeholder: 'Search Date',
					//     withoutEmptyComparatorOption: true,
					//     comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
					//     style: { display: 'inline-grid' },
					//     className: 'custom-datefilter-class',
					//     comparatorStyle: { backgroundColor: 'antiquewhite' },
					//     comparatorClassName: 'custom-comparator-class',
					//     dateStyle: { backgroundColor: 'cadetblue', margin: '0px' },
					//     dateClassName: 'custom-date-class'
					//   }),
					filter: customFilter({
						type: FILTER_TYPES.DATE
					}),
					filterRenderer: (onFilter, column) => (
						<DateFilter onFilter={onFilter} column={column} />
					)
				},
				{
					dataField: 'Action',
					text: '',
					formatter: this.linkAction,
					sort: true,
					headerAlign: 'center'
				}
			],
			vesrionsForSelectedPromo: [
				// {
				//     "action": "CREATED",
				//     "modifiedDate": "2021-02-11 15:17:35",
				//     "modifiedBy": "101",
				//     "promotionVersion": 1
				// },
				// {
				//     "action": "MODIFIED",
				//     "modifiedDate": "2021-02-11 15:17:35",
				//     "modifiedBy": "102",
				//     "promotionVersion": 2
				// },
				// {
				//     "action": "CREATED",
				//     "modifiedDate": "2021-02-11 15:17:35",
				//     "modifiedBy": "101",
				//     "promotionVersion": 3
				// },
				// {
				//     "action": "MODIFIED",
				//     "modifiedDate": "2021-02-11 15:17:35",
				//     "modifiedBy": "102",
				//     "promotionVersion": 4
				// }
			]
		};
	}

	headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
		let a = column.text;
		if (a) {
			return (
				<div>
					<div>
						{sortElement}
						{filterElement}
					</div>
					<div>
						<b> {a} </b>
					</div>
				</div>
			);
		}
	};

	linkAction = (cell, row, rowIndex, formatExtraData) => {
		return (
			<>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<button
						type="button"
						className=""
						onClick={() => {
							this.props.viewOffer(row);
						}}
					>
						View
					</button>
				</div>
			</>
		);
	};
	modifiedByFormatter(cell, row) {
		return (
			<div>
				<div className="cols">Modified By</div>
				<div> {cell}</div>
			</div>
		);
	}
	dateFormatter(cell, row) {
		return (
			<div>
				<div className="cols">Date</div>
				<div> {cell}</div>
			</div>
		);
	}
	actionFormatter(cell, row) {
		const translateAction = {
			'PROMOTION DURATION MODIFIED': 'MODIFIED',
			'PROMOTION CHANNELS MODIFIED': 'MODIFIED',
			'COUPONS DISPLAY FLAG MODIFIED': 'MODIFIED',
			PAUSED: 'Promotion has been Paused',
			RESUMED: 'Promotion is Resumed'
		};
		return (
			<div>
				<div className="cols">Action</div>
				<div> {translateAction[cell] ? translateAction[cell] : cell}</div>
			</div>
		);
	}
	versionFormatter(cell, row) {
		return (
			<div className="d-flex pt-2">
				<span className="cols pr-1">
					<h6>Version </h6>
				</span>
				<span className="">
					{' '}
					<h6> {cell}</h6>
				</span>
			</div>
		);
	}

	render() {
		const selectRow = {
			mode: 'checkbox',
			hideSelectAll: true,
			selectionRenderer: ({ mode, ...rest }) => (
				<>
					<input
						className={
							this.props.selectedVersions.length == 2 && !rest.checked
								? 'disabled1'
								: ''
						}
						type={mode}
						{...rest}
					/>
				</>
			),
			nonSelectable: [],
			onSelect: (row, isSelect, rowIndex, e) => {
				if (isSelect) {
					if (
						this.props.selectedVersions.length < 2 &&
						row.action != 'RESUMED' &&
						row.action != 'PAUSED' &&
						!row.action.includes('COUPON')
					) {
						let temp = this.props.selectedVersions;
						temp.push(row);
						this.props.setSelectedVersions(temp);
						return true;
					} else {
						return false;
					}
				} else {
					let temp = this.props.selectedVersions;
					temp = temp.filter(function (value, index, arr) {
						return value != row;
					});
					this.props.setSelectedVersions(temp);
				}
				// setSelectedVersions(row);
			},
			onSelectAll: (isSelect, rows, e) => {}
		};

		return (
			<>
				<div className="p-2">
					<div className="row pt-3 pb-3">
						<div className="d-flex ml-3">
							<span>
								<div className="roboto-m-16">Revision History</div>
							</span>
							<span>
								{' '}
								<small id="emailHelp" className="form-text pl-2">
									<div className="tooltipimg">
										<button className="tooltip-button btn br-50" type="button">
											<span className="tooltip-icon">?</span>
										</button>
										<span className="tooltiptext">
											<div className="item-help">
												<ol className="text-dark">
													<li className="list-row">
														<div className="item-help-description-text">
															Select Two Versions To Compare
														</div>
													</li>
													{/* <li class="list-row"><div class="item-help-description-text">{this.props.auditMessage || '-'}</div></li> */}
												</ol>{' '}
											</div>
										</span>
									</div>
								</small>
							</span>
						</div>
					</div>
					<div className="version_table">
						<BootstrapTable
							keyField="promotionVersion"
							data={this.props.data}
							// data={this.state.vesrionsForSelectedPromo}
							columns={this.state.columns}
							pagination={paginationFactory()}
							filter={filterFactory()}
							selectRow={selectRow}
						/>
						<div style={{ float: 'right' }}>
							<button
								className="cancel-btn1 roboto-b-16 "
								onClick={() => {
									this.props.gotoAuditSearch();
								}}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default AuditVersionsTable;
