/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from 'react';
import { itemHelp } from './item-help-text';

const CollapseBar = ({ text, dataTarget, headingId }) => {
	return (
		<div className="card-head" id={headingId}>
			<h2
				className="mb-0"
				//data-toggle="collapse"
				data-target={dataTarget}
				aria-expanded="true"
				aria-controls={headingId}
			>
				<input type="checkbox" id={dataTarget + 'chckbx'} className="hide-me" />
				{text == 'Happy Hours' ? (
					<div className="happyHours-Tooltip">
						<label className="tab-label" htmlFor={dataTarget + 'chckbx'}>
							{text}
						</label>
						<small id="emailHelp" className="form-text pl-2">
							<div className="tooltipimg">
								<button className="tooltip-button btn br-50" type="button">
									<span className="tooltip-icon">?</span>
								</button>
								<span className="tooltiptext"> {itemHelp.HappyHours} </span>
							</div>
						</small>
					</div>
				) : (
					<label className="tab-label" htmlFor={dataTarget + 'chckbx'}>
						{text}
					</label>
				)}
			</h2>
		</div>
	);
};

export default CollapseBar;
