import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { FieldArray, ErrorMessage, useField, useFormikContext } from 'formik';
import CustomDateTime from './customfields/CustomDateTime';
import CustomSelect from './customfields/CustomSelect';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import * as Yup from 'yup';
import CustomReactSearchableDropdownComponent from './customfields/CustomReactSearchableDropdownComponent';
import CustomInputText from './customfields/CustomInputText';
import { InformationModal } from '../../../MainContent/InformationModal';
import CustomInputText2 from './customfields/CustomInputText2';
import EmiScale from './EmiScale';
import CashbackInfo from './CashbackInfo';

const localConst = {
	applicableOn: 'Applicable on',
	subApplicableOn:
		'Determines if the promotion is applicable on the payment made through the tendered value or on the transaction condition',
	paymentThresholdCode: 'Payment Threshold Code',
	subPaymentThresholdCode: 'Determines the type of threshold for the payment',
	paymentThresholdAmount: 'Payment Threshold Amount',
	subPaymentThresholdAmount:
		'Determines the minimum threshold amount for the Payment Mode specified',
	paymentMode: 'Payment Mode',
	subPaymentMode: 'Defines the Payment eligibility Mode for which the promotion is applicable',
	emiType: 'EMI Type',
	subEmiType: 'Defines the types of EMI',
	provider: 'Provider',
	subProvider: 'Defines the Payment eligibility Provider for which the promotion is applicable',
	paymentType: 'Payment Type',
	subpaymentType: 'Defines the Payment eligibility Type for which the promotion is applicable',
	paymentSubType: 'Payment Sub Type',
	subPaymentSubType:
		'Defines the Payment eligibility SubType for which the promotion is applicable',
	coolOffPeriod: 'Cool Off Period'
};
// Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
//   return this.test('unique', message, function (list) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
	return this.test('unique', message, function (list) {
		if (!list) list = [];
		return list.length === new Set(list.map(mapper)).size;
	});
});

const Issuers = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	//
	//useValues(name, props);
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const index = props.index;
	const filednamepmtelg = props.filednamepmtelg;
	const fieldPaymentGroup =
		props.pgType == 'paymentGroupAdded'
			? filednamepmtelg?.payment?.paymentGroupAdded
			: props.pgType == 'paymentGroupRemoved'
			? filednamepmtelg?.payment?.paymentGroupRemoved
			: filednamepmtelg?.payment?.paymentGroup;
	const fieldIssuer =
		props.isType == 'issuersAdded'
			? fieldPaymentGroup[index]?.issuersAdded
			: props.isType == 'issuersRemoved'
			? fieldPaymentGroup[index]?.issuersRemoved
			: fieldPaymentGroup[index]?.issuers;
	const valueEligbility = props.valueEligbility;
	const fieldbenefit = props.fieldbenefit;
	const show = props.show;
	const setShow = props.setShow;
	const paymentModeOps = props.paymentModeOps;
	const paymentProviderOps = props.paymentProviderOps;
	const paymentTypeOps = props.paymentTypeOps;
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg] = useState('');
	const [informationTitle] = useState('');

	const dropdownValues = {
		applicableOn: [
			{
				value: '',
				name: 'Select'
			},
			{
				value: 'TENDER_VALUE',
				name: 'Tender Value'
			},
			{
				value: 'TRANSACTION_VALUE',
				name: 'Transaction Value'
			}
		],
		paymentThreshold: [
			/*  {
         value: "",
         name: "Select",
       }, */
			{
				value: 'PAYMENT_AMOUNT_THRESHOLD',
				name: 'Payment Amount Threshold'
			}
		],
		emi: [
			{
				value: '',
				name: 'Select'
			},
			{
				value: 'NO_COST_EMI',
				name: 'No Cost EMI'
			},
			{
				value: 'ONE_EMI_FREE',
				name: 'One EMI Free'
			}
		],
		coolOff: [
			{
				value: '',
				name: 'Select'
			},
			// {
			//   value: "Date",
			//   name: "Date",
			// },
			{
				value: 'Months',
				name: 'Months'
			},
			{
				value: 'Days',
				name: 'Days'
			},
			{
				value: 'Years',
				name: 'Years'
			}
		],
		collectionValidity: [
			{ value: '', name: 'Select one' },
			{
				value: 'Date',
				name: 'Date'
			},
			{
				value: 'Months',
				name: 'Months'
			},
			{
				value: 'Days',
				name: 'Days'
			},
			{
				value: 'Hours',
				name: 'Hours'
			}
		]
	};
	const getCoolOffDropdown = () => {
		return dropdownValues.coolOff.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const customValidateIssuerPaymentMode = (value, index, i) => {
		let error;
		// if(filednamepmtelg.isBinRangePromotion){
		//   if(!value){
		//     error="Please define an Issuer Payment Mode"
		//   }
		// }
		// else{
		if (
			// fieldPaymentGroup[index].aggregators.length==0&&
			fieldIssuer[i].issuerPaymentSubType == '' &&
			fieldIssuer[i].issuerPaymentType == '' &&
			fieldIssuer[i].issuerProvider == ''
		) {
			if (!value) {
				error = 'Please define an Issuer';
			}
		}
		// }
		return error;
	};
	const customValidateIssuerPaymentType = (value, index, i) => {
		let error;
		// if(filednamepmtelg.isBinRangePromotion){
		//   if(!value){
		//     error="Please define an Issuer Payment Type"
		//   }
		// }
		// else{
		if (
			// fieldPaymentGroup[index].aggregators.length==0&&
			fieldIssuer[i].issuerPaymentSubType == '' &&
			fieldIssuer[i].issuerPaymentMode == '' &&
			fieldIssuer[i].issuerProvider == ''
		) {
			if (!value) {
				error = 'Please define an Issuer';
			}
		}
		// }

		return error;
	};
	const customValidateIssuerPaymentSubType = (value, index, i) => {
		let error;
		// if(filednamepmtelg.isBinRangePromotion){
		//   if(!value){
		//     error="Please define an Issuer Payment Sub Type"
		//   }
		// }else{
		if (
			// fieldPaymentGroup[index].aggregators.length==0&&
			fieldIssuer[i].issuerPaymentMode == '' &&
			fieldIssuer[i].issuerPaymentType == '' &&
			fieldIssuer[i].issuerProvider == ''
		) {
			if (!value) {
				error = 'Please define an Issuer';
			}
		}
		// }

		return error;
	};
	const customValidateIssuerPaymentProvider = (value, index, i) => {
		let error;
		// if(filednamepmtelg.isBinRangePromotion){
		//   if(!value){
		//     error="Please define an Issuer Provider"
		//   }
		// }
		// else{
		if (
			// fieldPaymentGroup[index].aggregators.length==0&&
			fieldIssuer[i].issuerPaymentSubType == '' &&
			fieldIssuer[i].issuerPaymentType == '' &&
			fieldIssuer[i].issuerPaymentMode == ''
		) {
			if (!value) {
				error = 'Please define an Issuer';
			}
		}
		// }
		return error;
	};

	const informationModalClose = () => {
		setInformationModalShow(false);
	};
	useEffect(() => {}, []);

	return (
		<>
			<FieldArray
				name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}`}
			>
				{({ remove }) => (
					<div className={isAuditFlw ? 'w-auto' : 'issuerOuterBox w-auto'}>
						{fieldPaymentGroup &&
							fieldIssuer &&
							fieldIssuer.map((item, i) => (
								<div key={i} className="aggregatorBox1">
									{fieldIssuer?.length > 1 ? (
										<div className="d-flex justify-content-end">
											{' '}
											<button
												type="button"
												className="addDeleteBtn"
												onClick={() => {
													remove(i);
													setTimeout(() => {
														setShow(false);
													}, 500);
													setTimeout(() => {
														setShow(true);
													}, 500);
												}}
											></button>
										</div>
									) : null}

									<label className="font-weight-bold">Issuer</label>
									<div className="row">
										<div className="col-sm-3 mb-4">
											{/* <label className="mb-0 ml-2">Issuer Payment Mode</label>
                              <Field
                                className="form-control m-2"
                                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentMode`}
                                type="text"
                                validate={customValidateIssuerPaymentMode}
                              />
                              <ErrorMessage
                                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentMode`}
                                component="div"
                                className="field-error"
                              /> */}
											{/* <label className="mt-1">Issuer Payment Mode</label> */}
											{show ? (
												<CustomReactSearchableDropdownComponent
													name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentMode`}
													label="Issuer Payment Mode"
													smallText=""
													options={paymentModeOps}
													// validate={customValidateIssuerPaymentMode}
													validate={(val) =>
														customValidateIssuerPaymentMode(
															val,
															index,
															i
														)
													}
													disabled={filednamepmtelg?.isBinRangePromotion}
													flag={'show'}
													pgType={props.pgType}
												/>
											) : null}

											<ErrorMessage
												name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentMode`}
												component="div"
												className="field-error"
											/>
										</div>
										<div className="col-sm-3">
											{/* <label className="mt-1">Issuer Payment Provider</label> */}
											{/* <Field
                                className="form-control m-2"
                                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerProvider`}
                                type="text"
                                validate={customValidateIssuerPaymentProvider}
        
                              /> */}
											{show ? (
												<CustomReactSearchableDropdownComponent
													name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerProvider`}
													smallText=""
													options={paymentProviderOps}
													validate={(val) =>
														customValidateIssuerPaymentProvider(
															val,
															index,
															i
														)
													}
													label={'Issuer Payment Provider'}
													flag="show"
													pgType={props.pgType}
												/>
											) : null}
											<ErrorMessage
												name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerProvider`}
												component="div"
												className="field-error"
											/>
										</div>
										<div className="col-sm-3">
											{/* <label className="mt-1">Issuer Payment Type</label> */}
											{/* <Field
                                className="form-control m-2"
                                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentType`}
                                type="text"
                                validate={customValidateIssuerPaymentType}
                              /> */}
											{show ? (
												<CustomReactSearchableDropdownComponent
													name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentType`}
													smallText=""
													options={paymentTypeOps}
													validate={(val) =>
														customValidateIssuerPaymentType(
															val,
															index,
															i
														)
													}
													label={'Issuer Payment Type'}
													flag="show"
													pgType={props.pgType}
												/>
											) : null}
											<ErrorMessage
												name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentType`}
												component="div"
												className="field-error"
											/>
										</div>
										<div className="col-sm-3">
											{/* <label className="mt-1">Issuer Payment Sub Type</label>
                                    <Field
                                      className="form-control"
                                      name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentSubType`}
                                      type="text"
                                      validate={(val) => customValidateIssuerPaymentSubType(val, index, i)}
                                      onChange={(e) => {
                                        
                                        setFieldValue(`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentSubType`, e.target.value.toUpperCase())
                                      }}
                                    /> */}
											<CustomInputText2
												name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentSubType`}
												type="text"
												validate={(val) =>
													customValidateIssuerPaymentSubType(
														val,
														index,
														i
													)
												}
												label={'Issuer Payment Sub Type'}
												onChange={(e) => {
													setFieldValue(
														`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentSubType`,
														e.target.value.toUpperCase()
													);
												}}
												flag="show"
											/>
											{/* {show? 
                              <CustomReactSearchableDropdownComponent
                                name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentSubType`}
                                smallText=""
                              options={paymentSubTypeOps}
                              validate={customValidateIssuerPaymentSubType}
                              />
                              :null} */}
											{/* <ErrorMessage
                                      name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.issuerPaymentSubType`}
                                      component="div"
                                      className="field-error"
                                    /> */}
										</div>

										{fieldbenefit?.benefitType == 'CASHBACK' ||
										field.value.promotionalOfferReward?.itemPriceDerivationRule
											?.benefit?.benefitType == 'CASHBACK' ? (
											<>
												{isAuditFlw ? (
													<>
														{fieldIssuer[i].cashbackInformationAdded ==
															undefined &&
														fieldIssuer[i].cashbackInformationRemoved ==
															undefined ? (
															<CashbackInfo
																filednamepmtelg={
																	props.filednamepmtelg
																}
																valueEligbility={
																	props.valueEligbility
																}
																fieldbenefit={props.fieldbenefit}
																show={props.show}
																setShow={props.setShow}
																paymentProviderOps={
																	props.paymentProviderOps
																}
																paymentModeOps={
																	props.paymentModeOps
																}
																paymentTypeOps={
																	props.paymentTypeOps
																}
																aggregatorOps={props.aggregatorOps}
																fullVal={props.fullVal}
																index={index}
																pgType={props.pgType}
																isType={props.isType}
																i={i}
																cashbackInfoType="cashbackInformation"
															/>
														) : null}
													</>
												) : (
													<CashbackInfo
														filednamepmtelg={props.filednamepmtelg}
														valueEligbility={props.valueEligbility}
														fieldbenefit={props.fieldbenefit}
														show={props.show}
														setShow={props.setShow}
														paymentProviderOps={
															props.paymentProviderOps
														}
														paymentModeOps={props.paymentModeOps}
														paymentTypeOps={props.paymentTypeOps}
														aggregatorOps={props.aggregatorOps}
														fullVal={props.fullVal}
														index={index}
														pgType={props.pgType}
														isType={props.isType}
														i={i}
														cashbackInfoType="cashbackInformation"
													/>
												)}
											</>
										) : null}
										{isAuditFlw && fieldIssuer[i].cashbackInformationAdded ? (
											<>
												<CashbackInfo
													filednamepmtelg={props.filednamepmtelg}
													valueEligbility={props.valueEligbility}
													fieldbenefit={props.fieldbenefit}
													show={props.show}
													setShow={props.setShow}
													paymentProviderOps={props.paymentProviderOps}
													paymentModeOps={props.paymentModeOps}
													paymentTypeOps={props.paymentTypeOps}
													aggregatorOps={props.aggregatorOps}
													fullVal={props.fullVal}
													index={index}
													pgType={props.pgType}
													isType={props.isType}
													i={i}
													cashbackInfoType="cashbackInformationAdded"
												/>
											</>
										) : null}
										{isAuditFlw && fieldIssuer[i].cashbackInformationRemoved ? (
											<>
												<div className="font-weight-bold pl-2">
													{fieldIssuer[i].cashbackInformationRemoved}
												</div>
											</>
										) : null}
									</div>

									{filednamepmtelg.payment && filednamepmtelg.payment.emiType ? (
										<>
											<hr />
											<div className="d-flex justify-content-end mr-7rem">
												<button
													className="addPlusBtn"
													type="button"
													onClick={() => {
														fieldIssuer[i].emi &&
															fieldIssuer[i].emi.emiScale.push({
																tenurePeriodInMonths: '',
																interestRate: '',
																cappedAmount: JSON.parse(
																	props.fullVal
																).promotionPolicy.rewardLimit
																	? JSON.parse(props.fullVal)
																			.promotionPolicy
																			.rewardLimit
																	: ''
															});
														setFieldValue('', '');
													}}
												></button>
											</div>

											{isAuditFlw ? (
												<>
													<div className="box-blackout-period-new lightBlue">
														<div
															className={
																fieldIssuer[i].emi?.emiScaleAdded
																	?.length > 0
																	? 'issuerOuterBox mb-3 auditHide addedSection'
																	: 'd-none'
															}
														>
															<label className="font-weight-bold">
																Added (
																{
																	fieldIssuer[i].emi
																		?.emiScaleAdded?.length
																}
																)
															</label>
															<EmiScale
																filednamepmtelg={
																	props.filednamepmtelg
																}
																valueEligbility={
																	props.valueEligbility
																}
																fieldbenefit={props.fieldbenefit}
																show={props.show}
																setShow={props.setShow}
																paymentProviderOps={
																	props.paymentProviderOps
																}
																paymentModeOps={
																	props.paymentModeOps
																}
																paymentTypeOps={
																	props.paymentTypeOps
																}
																aggregatorOps={props.aggregatorOps}
																fullVal={props.fullVal}
																index={index}
																pgType={props.pgType}
																isType={props.isType}
																i={i}
																emiScaleType="emiScaleAdded"
															/>
														</div>
														<div
															className={
																fieldIssuer[i].emi?.emiScaleRemoved
																	?.length > 0
																	? 'issuerOuterBox mb-3 auditHide removedSection'
																	: 'd-none'
															}
														>
															<label className="font-weight-bold">
																Removed (
																{
																	fieldIssuer[i].emi
																		?.emiScaleRemoved?.length
																}
																)
															</label>
															<EmiScale
																filednamepmtelg={
																	props.filednamepmtelg
																}
																valueEligbility={
																	props.valueEligbility
																}
																fieldbenefit={props.fieldbenefit}
																show={props.show}
																setShow={props.setShow}
																paymentProviderOps={
																	props.paymentProviderOps
																}
																paymentModeOps={
																	props.paymentModeOps
																}
																paymentTypeOps={
																	props.paymentTypeOps
																}
																aggregatorOps={props.aggregatorOps}
																fullVal={props.fullVal}
																index={index}
																pgType={props.pgType}
																isType={props.isType}
																i={i}
																emiScaleType="emiScaleRemoved"
															/>
														</div>
														<div
															className={
																fieldIssuer[i].emi?.emiScale
																	?.length > 0
																	? 'issuerOuterBox mb-3'
																	: 'd-none'
															}
														>
															<label
																className={
																	props.isType == 'issuers'
																		? 'font-weight-bold'
																		: 'd-none'
																}
															>
																Modified (
																{
																	fieldIssuer[i].emi?.emiScale
																		?.length
																}
																)
															</label>
															<EmiScale
																filednamepmtelg={
																	props.filednamepmtelg
																}
																valueEligbility={
																	props.valueEligbility
																}
																fieldbenefit={props.fieldbenefit}
																show={props.show}
																setShow={props.setShow}
																paymentProviderOps={
																	props.paymentProviderOps
																}
																paymentModeOps={
																	props.paymentModeOps
																}
																paymentTypeOps={
																	props.paymentTypeOps
																}
																aggregatorOps={props.aggregatorOps}
																fullVal={props.fullVal}
																index={index}
																pgType={props.pgType}
																isType={props.isType}
																i={i}
																emiScaleType="emiScale"
															/>
														</div>
													</div>
												</>
											) : (
												<EmiScale
													filednamepmtelg={props.filednamepmtelg}
													valueEligbility={props.valueEligbility}
													fieldbenefit={props.fieldbenefit}
													show={props.show}
													setShow={props.setShow}
													paymentProviderOps={props.paymentProviderOps}
													paymentModeOps={props.paymentModeOps}
													paymentTypeOps={props.paymentTypeOps}
													aggregatorOps={props.aggregatorOps}
													fullVal={props.fullVal}
													index={index}
													pgType={props.pgType}
													isType={props.isType}
													i={i}
													emiScaleType="emiScale"
												/>
											)}
										</>
									) : filednamepmtelg.payment ? (
										fieldIssuer[i].emi ? (
											(fieldIssuer[i].emi.emiScale = undefined)
										) : null
									) : null}

									{filednamepmtelg.payment && filednamepmtelg.payment.emiType ? (
										<div>
											<CustomSelect
												name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.coolOffPeriod.coolOffPeriodType`}
												label={localConst.coolOffPeriod}
												options={getCoolOffDropdown()}
											/>
											{filednamepmtelg.payment &&
											fieldIssuer[i].emi &&
											fieldIssuer[i].emi.coolOffPeriod &&
											fieldIssuer[i].emi.coolOffPeriod.coolOffPeriodType ==
												'Date' ? (
												<>
													<CustomDateTime
														name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.coolOffPeriod.date`}
														label="Date(yyyy-mm-dd)"
													/>
												</>
											) : filednamepmtelg.payment ? (
												fieldIssuer[i].emi ? (
													fieldIssuer[i].emi.coolOffPeriod ? (
														(fieldIssuer[i].emi.coolOffPeriod.date =
															undefined)
													) : null
												) : null
											) : null}

											{filednamepmtelg.payment &&
											fieldIssuer[i].emi &&
											fieldIssuer[i].emi.coolOffPeriod &&
											fieldIssuer[i].emi.coolOffPeriod.coolOffPeriodType ==
												'Months' ? (
												<>
													<CustomInputText
														name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.coolOffPeriod.months`}
														required={true}
														label="Months"
													/>
												</>
											) : filednamepmtelg.payment ? (
												fieldIssuer[i].emi ? (
													fieldIssuer[i].emi.coolOffPeriod ? (
														(fieldIssuer[i].emi.coolOffPeriod.months =
															undefined)
													) : null
												) : null
											) : null}

											{filednamepmtelg.payment &&
											fieldIssuer[i].emi &&
											fieldIssuer[i].emi.coolOffPeriod &&
											fieldIssuer[i].emi.coolOffPeriod.coolOffPeriodType ==
												'Days' ? (
												<>
													<CustomInputText
														name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.coolOffPeriod.days`}
														required={true}
														label="Days"
													/>
												</>
											) : filednamepmtelg.payment ? (
												fieldIssuer[i].emi ? (
													fieldIssuer[i].emi.coolOffPeriod ? (
														(fieldIssuer[i].emi.coolOffPeriod.days =
															undefined)
													) : null
												) : null
											) : null}
											{filednamepmtelg.payment &&
											fieldIssuer[i].emi &&
											fieldIssuer[i].emi.coolOffPeriod &&
											fieldIssuer[i].emi.coolOffPeriod.coolOffPeriodType ==
												'Years' ? (
												<>
													<CustomInputText
														name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.coolOffPeriod.years`}
														required={true}
														label="Years"
													/>
												</>
											) : filednamepmtelg.payment ? (
												fieldIssuer[i].emi ? (
													fieldIssuer[i].emi.coolOffPeriod ? (
														(fieldIssuer[i].emi.coolOffPeriod.years =
															undefined)
													) : null
												) : null
											) : null}

											{filednamepmtelg.payment &&
											fieldIssuer[i].emi &&
											fieldIssuer[i].emi.coolOffPeriod &&
											fieldIssuer[i].emi.coolOffPeriod.coolOffPeriodType ==
												'Hours' ? (
												<>
													<CustomHoursPicker
														name={`${valueEligbility}.paymentEligibility.payment.${props.pgType}.${index}.${props.isType}.${i}.emi.coolOffPeriod.hours`}
														required={true}
														label="Hours(HH:mm)"
														valueHour={
															fieldIssuer[i].emi.coolOffPeriod.hours
														}
													/>
												</>
											) : filednamepmtelg.payment ? (
												fieldIssuer[i].emi ? (
													fieldIssuer[i].emi.coolOffPeriod ? (
														(fieldIssuer[i].emi.coolOffPeriod.hours =
															undefined)
													) : null
												) : null
											) : null}
										</div>
									) : filednamepmtelg.payment ? (
										fieldIssuer[i].emi ? (
											(fieldIssuer[i].emi.emiScale = undefined)
										) : null
									) : null}
								</div>
							))}
					</div>
				)}
			</FieldArray>
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={informationModalClose}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</>
	);
};

export default Issuers;
