import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../BasicDetails.scss';
import moment from 'moment';
import Datetime from 'react-datetime';
import { useField, useFormikContext } from 'formik';

const CustomTime = ({ label, required, prepend, smallText, type, ...props }) => {
	const [field, meta] = useField(props);
	const { setFieldValue, setFieldTouched } = useFormikContext();
	//debugger
	const [errMsg, setErrMsg] = useState('');
	const formatDate = (value) => {
		return moment(value).format('HH:mm');
	};
	let inputProps = {
		//() => alert('You went to the input but it was disabled')
		onClick: (e) => {
			e.preventDefault();
			var s = e.target.parentElement;
			s.scrollIntoView();
		},
		placeholder: 'HH:mm',
		open: true
	};

	let inputProps1 = {
		placeholder: 'HH:mm',
		open: true
	};

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	let formattedName = '';
	// eslint-disable-next-line no-unused-vars
	let newJson = '';
	let basicOrEligibilityJson = '';
	if (isAuditFlw) {
		let newCustName = JSON.parse(JSON.stringify(props.name));
		// eslint-disable-next-line no-unused-vars
		//newJson = JSON.parse(sessionStorage.getItem("NewPolicy"));
		basicOrEligibilityJson = newCustName.includes('promotionPolicy')
			? 'newJson'
			: 'newJson.promotionPolicy.priceDerivationRuleEligibility';
		let arrayOfName = newCustName.split('.');

		for (let i = 0; i < arrayOfName.length; i++) {
			if (isNaN(arrayOfName[i])) {
				formattedName = formattedName + '?.' + arrayOfName[i];
			} else {
				formattedName = formattedName + '?.[' + arrayOfName[i] + ']';
			}
		}
	}

	return (
		<div className="container">
			<div
				className={
					isAuditFlw &&
					eval(basicOrEligibilityJson + formattedName) === field.value &&
					props?.flag != 'show'
						? 'hideForAudit rowContainer'
						: 'rowContainer'
				}
			>
				<div
					className={
						props.name.includes('cashbackInformation')
							? 'd-none'
							: 'customLabelContainer'
					}
				>
					<label htmlFor="forprogramID" className="col	col-form-label">
						{label}

						{required && label ? <span className="requiredRed">*</span> : null}
					</label>
				</div>
				<div
					className={
						props.name.includes('happyHoursApplicableEligibility')
							? 'customInputContainer'
							: props.name.includes('cashbackInformation')
							? 'customInputContainerPA couponDate'
							: 'customInputContainer couponDate'
					}
				>
					<div className="row">
						{/* <input
                            type="time"
                            {...field}
                            {...props}
                            className="form-control col-md-9"
                            className={`form-control ${
                                meta.touched && meta.error ? "is-invalid" : ""
                                }`}
                        /> */}
						<div className={isAuditFlw ? 'hideForAudit' : ''}>
							<Datetime
								name={props.name}
								id={props.name}
								placeholder="Enter date"
								value={
									props.valueHour
										? moment(props.valueHour, 'HH:mm:ss').format('HH:mm')
										: undefined
								}
								dateFormat={false}
								timeFormat="HH:mm"
								onChange={(dateFromValue) => {
									if (!moment(dateFromValue, 'HH:mm', true).isValid()) {
										setErrMsg('Please define a valid Hour value');
									} else {
										setErrMsg('');
										setFieldValue(
											props.name,
											formatDate(dateFromValue) + ':00'
										);
									}
									setTimeout(() => setFieldTouched(props.name, true));
								}}
								inputProps={
									props?.isBottomDateTimePicker ? inputProps : inputProps1
								}

								// onBlur={(dateFromValue) => { setFieldValue(props.name, formatDate(dateFromValue)) }}
								// isInvalid={!!this.props.errors.dateFrom}
								// readOnly={this.props.status !== ProjectStatus.InProgress}
							/>
						</div>
						{isAuditFlw ? (
							<div>
								<div className="d-flex gap70 replacedText">
									<input
										type="text"
										className="form-control lightGray"
										value={moment(field.value, 'HH:mm:ss').format('HH:mm')}
									/>
									<input
										type="text"
										className={
											eval(basicOrEligibilityJson + formattedName) ===
											field.value
												? 'hideForAudit form-control'
												: 'form-control lightBlue'
										}
										value={moment(
											eval(basicOrEligibilityJson + formattedName),
											'HH:mm:ss'
										).format('HH:mm')}
									/>
								</div>
							</div>
						) : null}
						{smallText && (
							<small id="emailHelp" className="form-text pl-2">
								<div className="tooltipimg">
									<button className="tooltip-button btn br-50" type="button">
										<span className="tooltip-icon">?</span>
									</button>
									<span className="tooltiptext"> {smallText}</span>
								</div>
							</small>
						)}
						{meta.touched && meta.error ? (
							<div className="invalid-feedback d-block">{meta.error}</div>
						) : (
							<div className="invalid-feedback d-block">{errMsg}</div>
						)}
						{/* <div className="invalid-feedback d-block">{errMsg}</div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomTime;
