/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from 'react';
import { useField, FieldArray, useFormikContext } from 'formik';

import Shares from './Shares';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import EligibilitySubForm from './EligibilitySubForm';
import CustomInputText from './customfields/CustomInputText';
import CustomInputRadio from './customfields/CustomInputRadio';
import CustomDateTime from './customfields/CustomDateTime';
import CustomSelect from './customfields/CustomSelect';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "jquery/dist/jquery.min.js";
import 'bootstrap/dist/js/bootstrap.min.js';
import CustomInputRadioString from './customfields/CustomInputRadioString';
import * as RestSvc from '../../../libraries/offer-services';
import * as urlTypes from '../../../libraries/offer-service-urls';
import { itemHelp } from './item-help-text';
import { Element } from 'react-scroll';
import { InformationModal } from '../../../MainContent/InformationModal';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import toaster from 'toasted-notes';
import moment from 'moment';
import $ from 'jquery';
import { stringReplacer, validateCSV } from '../../../utils/helperFunction';
import { CSVReader } from 'react-papaparse';
import AuditChannel from './Audit/AuditChannel';
import appLabels from '../../../utils/appLabels';

const dropdownValues = {
	promotionType: [
		{
			value: '',
			name: 'Select one'
		},
		{
			value: 'MULTIBUYPRODUCT',
			name: 'Multibuy Product'
		},
		{
			value: 'MULTIBUYGROUP',
			name: 'Multibuy Group'
		},
		{
			value: 'MERCHANDIZE',
			name: 'Selling Hierarchy'
		},
		{
			value: 'MULTIBUYPACKAGE',
			name: 'Package'
		},
		{
			value: 'MULTIBUYSCALE',
			name: 'Scale'
		},
		// {
		//   value: "SCALEMERCHANDIZE",
		//   name: "Scale Selling Hierarchy",
		// },
		{
			value: 'TXN',
			name: 'Transaction'
		},
		{
			value: 'SCALETXN',
			name: 'Transaction Scale'
		},
		{
			value: 'MTMB',
			name: 'Multi Target Multi Benefit'
		},
		// {
		//   value: "MTMB_MERCHANDIZE",
		//   name: "Multi Target Multi Benefit Selling Hierarchy",
		// },
		{
			value: 'EARN_BURN',
			name: 'Earn Burn'
		},
		// {
		//   value: "EARN_BURN_MERCHANDIZE",
		//   name: "Earn Burn Selling Hierarchy",
		// },
		{
			value: 'SIMPLE',
			name: 'Simple'
		}
	],
	distributedSavings: [
		{
			value: 'ON_REWARDS_ONLY',
			name: 'On Rewards Only'
		},
		{
			value: 'ON_REWARDS_AND_TRIGGERS',
			name: 'On Rewards And Triggers'
		},
		{
			value: 'ON_ALL_ELIGIBLE_ITEMS',
			name: 'On All Eligible Items'
		}
	],
	distributedSavingsMBG: [
		{
			value: 'ON_REWARDS_AND_TRIGGERS',
			name: 'On Rewards And Triggers'
		},
		{
			value: 'ON_REWARDS_ONLY',
			name: 'On Rewards Only'
		},

		{
			value: 'ON_ALL_ELIGIBLE_ITEMS',
			name: 'On All Eligible Items'
		}
	]
};

//Local const
const localConst = {
	offerMaintenance: 'Offer Maintenance',
	basicDetails: 'Basic Details',
	promotionType: 'Promotion Type',
	description: 'Description',
	combinationPromotion: 'Combination With Other Promotions',
	shares: 'Shares',
	redemptionSettings: 'Redemption Settings',
	others: 'Others',
	applicationEligibility: 'Applicable Eligibility',
	channelEligibility: 'Channel Eligibility',
	privilegeCustomer: 'Privilege Customer Eligibility',
	anonymousCostomer: 'Anonymous Customer Eligibility',
	employeeEligibility: 'Employee Eligibility',
	loyaltyCustomer: 'Loyalty Customer Eligibility',
	customerSegments: 'Customer Segments Eligibility',
	loyaltyCard: 'LoyaltyCard Customer Eligibility',
	happyHours: 'Happy Hours',
	collectionRequired: 'Collection Required',
	operatorDisplayText: 'Offer Title',
	customerDisplayTextText: 'Offer Description',
	receiptPrintText: 'Receipt Print Text',
	subOperator:
		'Promotional message to be displayed to the operator on the POS screen(Ex: Buy 3 for the price of 2)',
	subCustomer:
		'Promotional message to be displayed to the customer on the customer display screen(Ex: Buy 3 for the price of 2)',
	subReciept: 'Promotional message to be printed on the receipt (Ex: Buy 3 for the price of 2)',
	allowOverlap: 'Allow Overlap',
	priority: 'Priority',
	displayPriority: 'Display Priority', //Commenting this code to remove sku and mtmb feature from release one. OFFERS-2203
	discountAppliesTo: 'Discount Applies On',
	subAllowoverlap:
		'Determines if the promotion can overlap with other promotions When set as False/Non Overlap: Each quantity of the item is eligible to get the best benefit from any one of the eligible promotions When set as True/ Overlap : Each quantity of the item is eligible to get benefit from all the eligible promotions, till the price of the item becomes Zero',
	subPriority: 'Determines the priority in which the promoiton should be applied.',
	subdiscountAppliesTo:
		'Determines whether the highest or lowest priced items should be considered for the eligible trigger and reward items for the promotion.',
	promotionRedemptionLimit: 'Redemption Settings',
	redemptionLimitAcrossTransaction: 'Redemption Limit Across Transaction',
	redemptionUserLimit: 'Redemption Limit Per User',
	maximumTrigger: 'Maximum Triggers Per Transaction',
	rewardLimit: 'Reward Limit Amount',
	limitedUse: 'Limited Use',
	subRedemptionTransactionLimit:
		'Determines the count of redemption limit of a promotion across transactions',
	subRedemptionUserLimit: 'Determines the count of redemption limit of a promotion per user',
	subMaximumTrigger: 'Determines the number of times promotion can be triggered in a transaction',
	subRewardLimit:
		'Determines maximum reward the promotion can apply for a promotion in a given transaction.',
	subLimitedUse:
		'Determines if the promotion is for a specific set of customers or for all the customers Unlimited :Promotion is applicable for all the customers Limited :Promotion is applicable only for a specific set of customers',
	allowPromotionOnMarkdown: 'Allow Promotion On Markdown',
	includeNonDiscount: 'Include Non Discountable Item',
	allowCouponToBeAppliedOnPromotionItems: 'Allow Coupon To Be Applied',
	redirectionUrl: 'Redirection Url',
	termsAndCondition: 'Terms and Conditions',
	imageUrl: 'Offer Image Url',
	offerGenre: 'Offer Genre',
	supplementaryDetails: 'Supplementary Details',
	customField1: 'Custom Field1',
	customField2: 'Custom Field2',
	giftVoucherTenderedPromotion: 'Gift Voucher Tendered Promotion',

	suballowPromotionOnMarkdown:
		'Determines if the promotion can be applied on items on which item manual discount is already applied.',
	subIncludeNonDiscount:
		'Determines if the non discountable items will participate in the promotion',
	suballowCouponToBeAppliedOnPromotionItems:
		'Determines if coupons are allowed to be applied on items for which the promotional benefit is already applied',
	subRedirectionUrl: 'Input for any redirection Urls for a promotion',
	subTermsAndCondition: 'Input for any Terms and conditions Urls for a promotion',
	subImageUrl: 'Input for any Image Urls for a promotion',
	subOfferGenre: 'Genre for the promotion',
	subSupplementaryDetails: 'Additional details related to the promotion',
	customField1Details: 'Custom Field1 Additional details related to the promotion',
	customField2Details: 'Custom Field2 Additional details related to the promotion',
	subgiftVoucherTenderedPromotion: 'Determines if the promotion is gift card tendered promotion'
};

const channelKey = ['channelId'];

const BasicDetailsFormik = ({ name, errors, touched, getFieldProps, isTSSSEligible, ...props }) => {
	const [show, setShow] = useState(false);
	const [field] = useField(props);
	const [errorMsg, setErrorMsg] = useState('');
	const [promoIdType, setPromoIdType] = useState('');
	const { setFieldValue } = useFormikContext();
	const { handleBlur } = useFormikContext();
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState('');
	const [informationTitle, setInformationTitle] = useState('Information');
	const [storeIdNameObj, setStrIdNameObj] = useState({});
	const [initialValBakup, setInitialValBakup] = useState({});
	const [disableRewardLimit, setDisableRewardLimit] = useState(false);
	const getAdded = (products, products1) => {
		var added = [];
		for (var i = 0; i < products.length; i++) {
			var id = products[i];
			var flag = false;
			for (var j = 0; j < products1.length; j++) {
				var id2 = products1[j];
				if (id === id2) {
					flag = true;
					// products1[j].contained = true;
				}
			}
			if (!flag) {
				added.push(products[i]);
			}
		}
		return added;
	};
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	let newJson = '';
	let termsAdded = [];
	let termsRemoved = [];
	let offerImgUrlAdded = [];
	let offerImgUrlRemoved = [];
	let OldTc = [],
		NewTc = [],
		OldOiu = [],
		NewOiu = [];
	if (isAuditFlw) {
		newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));

		OldTc = field.value.promotionPolicy.termsAndConditions
			? field.value.promotionPolicy.termsAndConditions
			: [];
		OldTc = OldTc.filter(function (el) {
			return el != '';
		});
		NewTc = newJson.promotionPolicy.termsAndConditions
			? newJson.promotionPolicy.termsAndConditions
			: [];
		NewTc = NewTc.filter(function (el) {
			return el != '';
		});
		termsRemoved = getAdded(OldTc, NewTc);
		termsAdded = getAdded(NewTc, OldTc);
		termsRemoved = termsRemoved.filter(function (el) {
			return el != '';
		});

		OldOiu = field.value.promotionPolicy.offerImageUrl
			? field.value.promotionPolicy.offerImageUrl
			: [];
		OldOiu = OldOiu.filter(function (el) {
			return el != '';
		});
		NewOiu = newJson.promotionPolicy.offerImageUrl ? newJson.promotionPolicy.offerImageUrl : [];
		NewOiu = NewOiu.filter(function (el) {
			return el != '';
		});
		offerImgUrlRemoved = getAdded(OldOiu, NewOiu);
		offerImgUrlAdded = getAdded(NewOiu, OldOiu);
		offerImgUrlRemoved = offerImgUrlRemoved.filter(function (el) {
			return el != '';
		});
	}
	const validateForManualConf = (value) => {
		let error;

		if (promoIdType != 'Automatic' && !value) {
			error = 'Promotion ID length must be 1 to 50';
		} else if (promoIdType != 'Automatic' && value) {
			if (value.length > 50) {
				error = 'Promotion ID length must be 1 to 50';
			}
		}

		return error;
	};
	const fieldBasicDetails = field.value.promotionPolicy;

	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			props.showLoadingButton2(true);
			// let listofNames=["scrollToChannelEligibility","scrollToProduct/SellingHierarchySettings","scrollToExclusionSettings"]
			setTimeout(() => {
				var tempName1 = 'scrollTo';
				$('[name^="' + tempName1 + '"]').each(function () {
					// if(!listofNames.includes($(this).attr("name"))){
					var visibleDivs = 0;
					$(this)
						.children()
						.find('.card-body')
						.find('.container')
						.each(function () {
							visibleDivs += $(this).children(':visible').length;
						});
					if (visibleDivs == 0) $(this).addClass('hide-nochange-section-for-audit');
					// }
				});
				//hiding purchase and reward
				$('[name="PurchaseandRewardBlock"]')
					.find('.purchaseRow')
					.each(function () {
						var visibleDivs1 = 0;
						$(this)
							.find('.container')
							.each(function () {
								visibleDivs1 += $(this).children(':visible').length;
							});
						if (visibleDivs1 == 0) $(this).addClass('hide-nochange-section-for-audit');
					});
				//hiding section
				$('.section-container').each(function () {
					var visibleDivs1 = 0;
					$(this)
						.children()
						.find('.container')
						.each(function () {
							visibleDivs1 += $(this).children(':visible').length;
						});
					if (visibleDivs1 == 0) {
						$(this).addClass('hide-nochange-section-for-audit');
					}
				});
				props.showLoadingButton2(false);
			}, 5000);
		}
	}, []);

	useEffect(() => {
		//businessUnitGroupFunctionID
		let url = `${urlTypes.offers_maintenance.getPromoIdConfig}${
			JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
				.businessUnitGroupFunctionID
		}`;
		RestSvc.getData(url).then((response) => {
			var result = response.data.configurationResponse;
			if (result.promoIdType != undefined) {
				setPromoIdType(result.promoIdType);
			}
		});
		setInitialValBakup(fieldBasicDetails);
	}, []);

	useEffect(() => {
		if (!fieldBasicDetails.discountAppliesTo) {
			fieldBasicDetails.discountAppliesTo = 'AMST';
		}

		/* if (fieldBasicDetails.promotionType == "MULTIBUYPRODUCT") {

   if(!fieldBasicDetails.distributedSavings){
        fieldBasicDetails.distributedSavings = "ON_REWARDS_ONLY";
        //setFieldValue("fieldBasicDetails.distributedSavings", "ON_REWARDS_ONLY");
      }
    } else if (fieldBasicDetails.promotionType &&
      (fieldBasicDetails.promotionType == "MULTIBUYGROUP" ||
      fieldBasicDetails.promotionType == "MERCHANDIZE" ||
      fieldBasicDetails.promotionType == "MULTIBUYPACKAGE" ||
      fieldBasicDetails.promotionType == "SCALEPRODUCT" ||
      fieldBasicDetails.promotionType == "SCALEMERCHANDIZE" ||
      fieldBasicDetails.promotionType == "TXN"||
      fieldBasicDetails.promotionType == "SCALETXN" ||
      fieldBasicDetails.promotionType == "SIMPLE")) {

     // if(!fieldBasicDetails.distributedSavings){
        fieldBasicDetails.distributedSavings = "ON_REWARDS_AND_TRIGGERS";
        //setFieldValue("fieldBasicDetails.distributedSavings", "ON_REWARDS_AND_TRIGGERS");
     // }
    }  */
	}, [fieldBasicDetails.promotionType]);

	useEffect(() => {
		if (fieldBasicDetails.promotionApplicableEligibility.channelEligibility.channel.length) {
			let chanlIdsOnly = [];

			fieldBasicDetails.promotionApplicableEligibility.channelEligibility.channel.map(
				(obj) => {
					obj.applicableIds.id.map((id) => {
						chanlIdsOnly.push(id);
					});
				}
			);

			let dataToSend = {
				getBusinessUnitIdNamesRequest: {
					businessUnitIds: chanlIdsOnly
				}
			};

			props.showLoadingButton(true);

			RestSvc.postData('/employee/getBusinessUnitIdNames', dataToSend)
				.then((response) => {
					props.showLoadingButton(false);
					var result = response.data.getBusinessUnitIdNamesResponse;

					if (result.businessError) {
						toaster.notify(
							({ onClose }) => (
								<div className="Toaster__message-wrapper">
									<div>
										<div
											id="1"
											className="toasterRed Toaster__alert Toaster__alert_error"
										>
											<div className="Toaster__alert_text">
												{result.businessError.errorMessage}
											</div>
											<button
												className="Toaster__alert_close"
												type="button"
												aria-label="Close"
												onClick={onClose}
											>
												<span aria-hidden="true">×</span>
											</button>
										</div>
									</div>
								</div>
							),
							{
								duration: 10000
							}
						);
					} else {
						setStrIdNameObj(result.businessUnitIdNameMap);
					}
				})
				.catch((error) => {
					props.showLoadingButton(false);
					console.error(error);
				});
		}
	}, [fieldBasicDetails.promotionApplicableEligibility.channelEligibility.channel]);

	/*  const distributedSelection = () => {
    if (fieldBasicDetails.promotionType == "MULTIBUYPRODUCT") {
       fieldBasicDetails.distributedSavings = "ON_REWARDS_ONLY";
   } else if (fieldBasicDetails.promotionType &&
     fieldBasicDetails.promotionType != "MTMB" &&
     fieldBasicDetails.promotionType != "EARN_BURN_PRODUCT" &&
     fieldBasicDetails.promotionType != "EARN_BURN_MERCHANDIZE") {
       fieldBasicDetails.distributedSavings = "ON_REWARDS_AND_TRIGGERS";
   } 
 } */
	const handleSelectionNew = (flag) => {
		setShow(flag);
		if (flag) {
			var selectedStores = [];
			fieldBasicDetails.promotionApplicableEligibility.channelEligibility.channel.map(
				(item) => {
					//selectedStores.concat(item.applicableIds.id);
					item.applicableIds.id.map((id) => {
						selectedStores.push(id);
					});
				}
			);
			document.getElementById('modalDivFrm').contentWindow['savedChannelData'] =
				selectedStores;
			document.getElementById('modalDivFrm').contentWindow.openPopup();
		}
	};

	const updatedSelectedStores = () => {
		setChannelsSelected(document.getElementById('modalDivFrm').contentWindow['resultObject']);
		handleSelectionNew(false);
	};

	const setChannelsSelected = (jsonstr) => {
		var testResponse = jsonstr;
		var structuredChannels = [];
		var channelCount = 0;
		testResponse.map((item) => {
			if (item.isChannel) {
				channelCount++;
				structuredChannels.push({
					name: item.businessUnitGroupName,
					applicableIds: { id: [] },
					selectedIncludedIds: { id: [] }
				});
			} else {
				structuredChannels[channelCount - 1].applicableIds.id.push(
					item.businessUnitGroupID
				);
				structuredChannels[channelCount - 1].selectedIncludedIds.id.push(
					item.businessUnitGroupID
				);
			}
		});
		// fieldBasicDetails.promotionApplicableEligibility.channelEligibility.channel = structuredChannels;
		setFieldValue(
			'promotionPolicy.promotionApplicableEligibility.channelEligibility.channel',
			structuredChannels
		);
	};

	const getPromotionTypeDropdown = () => {
		return dropdownValues.promotionType.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const getDistributedSavingsDropdown = () => {
		//if(fieldBasicDetails.promotionType == "MULTIBUYPRODUCT"){
		return dropdownValues.distributedSavings.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	}; /* else if (fieldBasicDetails.promotionType &&
    fieldBasicDetails.promotionType != "MTMB" &&
    fieldBasicDetails.promotionType != "EARN_BURN_PRODUCT" &&
    fieldBasicDetails.promotionType != "EARN_BURN_MERCHANDIZE"){
    return dropdownValues.distributedSavingsMBG.map((val, index) => {
      return (
        <option key={index + ""} value={val.value}>
          {val.name}
        </option>
      );
    });

  }
  };
 */

	const checkPromoId = () => {
		var programID = fieldBasicDetails.programID;
		var promotionId = fieldBasicDetails.promotionID;

		setErrorMsg('');
		var dataToSend = {
			searchPromotionRequest: {
				programId: programID,
				promotionId: promotionId
			}
		};

		props.showLoadingButton(true);

		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;

		RestSvc.postData(urlTypes.offers_maintenance.searchPromotion, dataToSend, svPromUrl)
			.then((response) => {
				props.showLoadingButton(false);
				var result = response.data.searchPromotionResponse;
				if (result.promotionPolicyDetails != undefined) {
					setErrorMsg(
						stringReplacer(
							appLabels.offerConfigurator.promoAlreadyAvailed,
							'@promo',
							result.promotionPolicyDetails[0].promotionId
						)
					);
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	const buttonRef = React.createRef();

	const handleOpenDialog = (e) => {
		// Note that the ref is set async, so it might be null at some point
		if (buttonRef.current) {
			buttonRef.current.open(e);
		}
	};

	const handleOnFileLoad = async (data) => {
		let errorMsgs = [];
		let csvUploadStatus = validateCSV(channelKey, data);

		if (csvUploadStatus == 'CORRECT') {
			let csvHeaders = data[0].data;
			let tmpData = [];

			for (let i = 1; i < data.length - 1; i++) {
				let tmpObj = {};

				for (let j = 0; j < csvHeaders.length; j++) {
					tmpObj[csvHeaders[j]] = data[i].data[j]
						? data[i].data[j].toString().trim()
						: data[i].data[j];
				}

				tmpData.push(tmpObj);
			}

			let channelId = [...tmpData];
			channelId = channelId.map((values) => {
				return values.channelId;
			});

			let busnesUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;

			let existingChanlIdsOnly = [];

			if (
				fieldBasicDetails.promotionApplicableEligibility.channelEligibility.channel.length
			) {
				fieldBasicDetails.promotionApplicableEligibility.channelEligibility.channel.map(
					(obj) => {
						obj.applicableIds.id.map((id) => {
							existingChanlIdsOnly.push(id);
						});
					}
				);
			}

			const dataToSend = {
				uploadChannelRequest: {
					channelIds: channelId,
					channelIdsAlreadyPresent: existingChanlIdsOnly,
					businessUnitFunctionId: busnesUnitFunctionID
				}
			};
			if (channelId.length == 0) {
				toaster.notify(
					({ onClose }) => (
						<div className="Toaster__message-wrapper">
							<div>
								<div
									id="1"
									className="toasterRed Toaster__alert Toaster__alert_error"
								>
									<div className="Toaster__alert_text">
										Please provide proper CSV data
									</div>
									<button
										className="Toaster__alert_close"
										type="button"
										aria-label="Close"
										onClick={onClose}
									>
										<span aria-hidden="true">×</span>
									</button>
								</div>
							</div>
						</div>
					),
					{
						duration: 10000
					}
				);
			} else {
				props.showLoadingButton(true);

				const svPromUrl = JSON.parse(
					sessionStorage.getItem('applctnUrls')
				).savePromotionsUrl;

				RestSvc.postData('/rest/offers/configurator/uploadChannels', dataToSend, svPromUrl)
					.then((response) => {
						props.showLoadingButton(false);
						var result = response.data.uploadChannelResponse;

						if (result.channelList && result.channelList.length) {
							setChannelsSelected(result.channelList);
						}

						if (result.invalidChannels.length || result.duplicateChannels.length) {
							let errorListTemplate = (
								<div>
									{result.invalidChannels &&
										result.invalidChannels.length > 0 && (
											<div>
												<b>
													Invalid Channels:{result.invalidChannels.length}
												</b>
												<div>{result.invalidChannels.join(', ')}</div>
											</div>
										)}
									{result.duplicateChannels &&
										result.duplicateChannels.length > 0 && (
											<div>
												<b>
													Duplicate Channels:
													{result.duplicateChannels.length}
												</b>
												<div>{result.duplicateChannels.join(', ')}</div>
											</div>
										)}
									{result.channelIdsAlreadyAdded &&
										result.channelIdsAlreadyAdded.length > 0 && (
											<div>
												<b>
													Channel id(s) already added:
													{result.channelIdsAlreadyAdded.length}
												</b>
												<div>
													{result.channelIdsAlreadyAdded.join(', ')}
												</div>
											</div>
										)}
									{
										<div>
											<b>
												Channel id(s) Successfully Updated:
												{result.channelIdsSuccessfullyUpdated}
											</b>
										</div>
									}
								</div>
							);
							setInformationMsg(errorListTemplate);
							setInformationModalShow(true);
						}
					})
					.catch((error) => {
						props.showLoadingButton(false);
						console.error(error);
					});
			}
		} else {
			errorMsgs.push(
				<>
					<b>The uploaded CSV format is incorrect. The colum headers must be: </b>
					<p>{csvUploadStatus}</p>
				</>
			);

			if (errorMsgs != '') {
				setInformationMsg(errorMsgs);
				setInformationModalShow(true);
			}
		}
	};

	const handleOnError = () => {};

	const handleOnRemoveFile = () => {};

	const customValidateEffctvStartDate = (value, offrFlow) => {
		let error;
		if (!value) {
			error = 'Please define a start date value';
		} else {
			if (
				offrFlow.type == 'modify-offer-flow' &&
				(offrFlow.slctdPromotionRow.status == 'Active' ||
					offrFlow.slctdPromotionRow.status == 'Expired' ||
					offrFlow.slctdPromotionRow.status == 'Paused')
				// eslint-disable-next-line no-empty
			) {
				// eslint-disable-next-line no-empty
			} else if (offrFlow.type == 'view-offer-flow') {
			} else {
				if (moment(value).format('YYYY/MM/DD') < moment().format('YYYY/MM/DD')) {
					error = 'Start date should not be less than the current date';
				}
			}
		}
		return error;
	};

	const customValidateRedemptionTransaction = (value, offrFlow) => {
		let error;

		if (value && offrFlow.type == 'modify-offer-flow') {
			if (
				parseInt(value) !== parseInt(initialValBakup.promotionRedemptionLimit) &&
				parseInt(value) < parseInt(initialValBakup.promotionRedemptionLimit)
			) {
				error =
					'Redemption Limit Across Transaction should be greater than the current value: ' +
					initialValBakup.promotionRedemptionLimit;
			}
		}
		return error;
	};
	const customValidateRedemptionUser = (value, offrFlow) => {
		let error;

		if (value && offrFlow.type == 'modify-offer-flow') {
			if (
				parseInt(value) !== parseInt(initialValBakup.redeemptionLimitPerUser) &&
				parseInt(value) < parseInt(initialValBakup.redeemptionLimitPerUser)
			) {
				error =
					'Redemption Limit Per User value should be greater than the current value: ' +
					initialValBakup.redeemptionLimitPerUser;
				return error;
			}
		}
		return error;
	};
	const customValidateMaxTrigger = (value, offrFlow) => {
		let error;

		if (value && offrFlow.type == 'modify-offer-flow') {
			if (
				parseInt(value) !== parseInt(initialValBakup.maximumTrigger) &&
				parseInt(value) < parseInt(initialValBakup.maximumTrigger)
			) {
				error =
					'Maximum Triggers Per Transaction value should be greater than the current value: ' +
					initialValBakup.maximumTrigger;
				return error;
			}
		}
		return error;
	};
	const customValidateRewardLimit = (value, offrFlow) => {
		let error;

		if (value && offrFlow.type == 'modify-offer-flow') {
			if (
				parseFloat(value) !== parseFloat(initialValBakup.rewardLimit) &&
				parseFloat(value) < parseFloat(initialValBakup.rewardLimit)
			) {
				error =
					'Reward Limit Amount value should be greater than the current value: ' +
					initialValBakup.rewardLimit;
				return error;
			}
		}
		return error;
	};

	const customValidateRedemptionLimitPerCard = (value, offrFlow) => {
		let error;

		if (value && offrFlow.type == 'modify-offer-flow') {
			if (
				parseInt(value) !== parseInt(initialValBakup.redeemptionLimitPerCard) &&
				parseInt(value) < parseInt(initialValBakup.redeemptionLimitPerCard)
			) {
				error =
					'Redemption Limit Per Card value should be greater than the current value: ' +
					initialValBakup.redeemptionLimitPerCard;
				return error;
			}
		}
		return error;
	};

	const customValidateMaxBenefitPerCardTxn = (value, offrFlow) => {
		let error;

		if (value && offrFlow.type == 'modify-offer-flow') {
			if (
				parseInt(value) !== parseInt(initialValBakup.maxBenefitPerCardAcrossTransaction) &&
				parseInt(value) < parseInt(initialValBakup.maxBenefitPerCardAcrossTransaction)
			) {
				error =
					'Maximum Benefit Per Card Across Transaction value should be greater than the current value: ' +
					initialValBakup.maxBenefitPerCardAcrossTransaction;
				return error;
			}
		}
		return error;
	};

	const customValidateMaxBenefitPerCustTxn = (value, offrFlow) => {
		let error;

		if (value && offrFlow.type == 'modify-offer-flow') {
			if (
				parseInt(value) !==
					parseInt(initialValBakup.maxBenefitPerCustomerAcrossTransaction) &&
				parseInt(value) < parseInt(initialValBakup.maxBenefitPerCustomerAcrossTransaction)
			) {
				error =
					'Maximum Benefit Per Customer Across Transaction value should be greater than the current value: ' +
					initialValBakup.maxBenefitPerCustomerAcrossTransaction;
				return error;
			}
		}
		return error;
	};
	const customValidateMaxRedeemAmountAcrossTransaction = (value, offrFlow) => {
		let error;

		if (value && offrFlow.type == 'modify-offer-flow') {
			if (
				parseInt(value) !== parseInt(initialValBakup.maxRedeemAmountAcrossTransaction) &&
				parseInt(value) < parseInt(initialValBakup.maxRedeemAmountAcrossTransaction)
			) {
				error =
					'Maximum Benefit Per Customer Across Transaction value should be greater than the current value: ' +
					initialValBakup.maxRedeemAmountAcrossTransaction;
				return error;
			}
		}
		return error;
	};
	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

	const onMouseEnterHandler = (evt, mouseIsON) => {
		if (mouseIsON != props.curntMousePlace) {
			props.setMousePlace(mouseIsON);
		}
	};

	//useValues(name, props);
	return (
		<div>
			<div className="section-container">
				<div className="item-title">{localConst.basicDetails}</div>
				<div
					className={
						offerFlow.type == 'view-offer-flow'
							? 'container disabledEvent'
							: 'container'
					}
				>
					<div onMouseEnter={(e) => onMouseEnterHandler(e, '')}>
						<CustomInputText
							name="promotionPolicy.programID"
							required={true}
							label="Program ID"
							smallText="Program ID for the promotion"
							disabled={true}
						/>
						<CustomInputText
							name="promotionPolicy.brandName"
							required={true}
							label="Brand Name"
							smallText="Brand Name the promotion is tagged to"
							disabled={true}
						/>
						<CustomInputText
							name="promotionPolicy.promotionID"
							required={promoIdType == 'Automatic' ? false : true}
							disabled={
								promoIdType == 'Automatic'
									? true
									: offerFlow.type == 'modify-offer-flow'
									? true
									: false
							}
							label="Promotion ID"
							className="one-row-input-group"
							prepend={fieldBasicDetails.programID}
							smallText="Unique identifier for the promotion"
							onBlur={(e) => {
								handleBlur(e);
								// checkPromotionId(); // Instead of getPromotionJSON, using search promotion for case insensitive issue
								checkPromoId();
							}}
							validate={validateForManualConf}
						/>
						<div
							className="mt-1 pl-19"
							style={{ color: '#dc3545', paddingLeft: 'calc(39% + 15px)' }}
						>
							{errorMsg}
						</div>
					</div>
					<div className="accordion" id="accordionExample">
						{/* Basic Promo details */}
						<div className="card" style={{ border: 'none' }}>
							{/* <div class="card-head" id="headingOne"> */}
							{/* <h2
                  class="mb-0"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  <div className="item-before">{localConst.basicDetails}</div>
                </h2> */}
							{/* <CollapseHeaderBar
                text={localConst.basicDetails}
                dataTarget={"#collapseOne"}
              /> */}
							{/* </div> */}
							{/* <CollapseBody id="collapseOne"> */}
							{/* </CollapseBody> */}
							{/* <CustomInputText
                  name="promotionPolicy.programID"
                  required={true}
                  label="Program ID"
                  smallText="Program ID for the promotion"
                  disabled={true}
                />
                <CustomInputText
                  name="promotionPolicy.brandName"
                  required={true}
                  label="Brand Name"
                  smallText="Brand Name the promotion is tagged to"
                  disabled={true}
                />
                <CustomInputText
                  name="promotionPolicy.promotionID"
                  required={true}
                  label="Promotion ID"
                  prepend={fieldBasicDetails.programID}
                  smallText="Unique ID for the promotion"
                  onBlur={checkPromotionId}
                />
                <div style={{color: 'red'}}>{errorMsg}</div>
              </CollapseBody> */}
						</div>
						{/* </div> */}
						{/* Promotion Type and Date details */}
						<Element
							onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToPromotionType')}
							name="scrollToPromotionType"
							class="card"
							style={{ border: 'none' }}
						>
							<div className="card-head" id="headingTwo">
								<h2
									className="mb-0"
									// data-toggle="collapse"
									data-target="#collapseTwo"
									aria-expanded="true"
									aria-controls="collapseTwo"
								>
									<div className="item-before">{localConst.promotionType}</div>
								</h2>
							</div>

							<div
								id="collapseTwo"
								className="collapse"
								aria-labelledby="headingTwo"
								data-parent="#accordionExample"
							>
								<div className="card-body dropDown-icon-style">
									<CustomSelect
										name="promotionPolicy.promotionType"
										required={true}
										label={localConst.promotionType}
										options={getPromotionTypeDropdown()}
										smallText={itemHelp.promotionType}
										//onChange= {distributedSelection}
										disabled={offerFlow.type == 'modify-offer-flow'}
										onChange={(e) => {
											props.resetAllTables();
											setFieldValue(
												'promotionPolicy.promotionType',
												e.target.value
											);
											fieldBasicDetails.discountAppliesTo = 'AMST';
											let PromoTypeName = e.target.value;

											if (PromoTypeName == 'MULTIBUYPRODUCT') {
												//  fieldBasicDetails.distributedSavings = "ON_REWARDS_ONLY";
												setFieldValue(
													'promotionPolicy.distributedSavings',
													'ON_REWARDS_ONLY'
												);
											} else if (
												//fieldBasicDetails.promotionType &&
												[
													'MULTIBUYGROUP',
													'MERCHANDIZE',
													'MULTIBUYPACKAGE',
													'SCALEPRODUCT',
													'SCALEMERCHANDIZE',
													'TXN',
													'SCALETXN',
													'SIMPLE',
													'MULTIBUYSCALE'
												].includes(PromoTypeName)
											) {
												// fieldBasicDetails.distributedSavings = "ON_REWARDS_AND_TRIGGERS";
												setFieldValue(
													'promotionPolicy.distributedSavings',
													'ON_REWARDS_AND_TRIGGERS'
												);
											}
											if (
												offerFlow.type == 'replicate-offer-flow' &&
												PromoTypeName != 'TXN'
											) {
												setFieldValue(
													'promotionPolicy.promotionalOfferReward',
													undefined
												);
											}
										}}
									/>
									<CustomDateTime
										className={
											offerFlow.type == 'modify-offer-flow' &&
											(offerFlow.slctdPromotionRow.status == 'Active' ||
												offerFlow.slctdPromotionRow.status == 'Expired' ||
												offerFlow.slctdPromotionRow.status == 'Paused')
												? 'disabledEvent'
												: ''
										}
										name="promotionPolicy.effectiveStartDate"
										required={true}
										label="Start Date"
										smallText="The date and time from which the promotion will be active."
										validate={(val) =>
											customValidateEffctvStartDate(val, offerFlow)
										}
										disabled={
											offerFlow.type == 'modify-offer-flow' &&
											(offerFlow.slctdPromotionRow.status == 'Active' ||
												offerFlow.slctdPromotionRow.status == 'Expired' ||
												offerFlow.slctdPromotionRow.status == 'Paused')
										}
										valueDate={fieldBasicDetails.effectiveStartDate}
									/>
									<CustomDateTime
										name="promotionPolicy.effectiveEndDate"
										required={true}
										label="End Date"
										smallText=" The date and time the promotion will be expired."
										valueDate={fieldBasicDetails.effectiveEndDate}
									/>
								</div>
							</div>
						</Element>
						{/* Promotion Description */}

						<Element
							onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToDescription')}
							name="scrollToDescription"
							class="card"
							style={{ border: 'none' }}
						>
							<div className="card-head" id="headingThree">
								<h2
									className="mb-0"
									// data-toggle="collapse"
									data-target="#collapseThree"
									aria-expanded="true"
									aria-controls="collapseThree"
								>
									<div className="item-before">{localConst.description}</div>
								</h2>
							</div>

							<div
								id="collapseThree"
								className="collapse"
								aria-labelledby="headingThree"
								data-parent="#accordionExample"
							>
								<div
									className={
										offerFlow.type == 'view-offer-flow'
											? 'card-body pointer-auto'
											: 'card-body'
									}
								>
									<CustomInputText
										// {offerFlow == "view-offer-flow"?"card-body":"card-body"}
										name="promotionPolicy.operatorDisplayText"
										type="textarea"
										required={true}
										readonly={
											offerFlow.type == 'view-offer-flow' ? true : false
										}
										label={localConst.operatorDisplayText}
										smallText={localConst.subOperator}
									/>
									<CustomInputText
										name="promotionPolicy.customerDisplayText"
										type="textarea"
										required={true}
										readonly={
											offerFlow.type == 'view-offer-flow' ? true : false
										}
										label={localConst.customerDisplayTextText}
										smallText={localConst.subCustomer}
									/>
									<CustomInputText
										name="promotionPolicy.receiptPrintText"
										type="textarea"
										readonly={
											offerFlow.type == 'view-offer-flow' ? true : false
										}
										required={false}
										label={localConst.receiptPrintText}
										smallText={localConst.subReciept}
									/>
								</div>
							</div>
						</Element>
						{/* Cobination Promotion */}

						<Element
							onMouseEnter={(e) =>
								onMouseEnterHandler(e, 'scrollToCombinationWithOtherPromotions')
							}
							name="scrollToCombinationWithOtherPromotions"
							class="card"
							style={{ border: 'none' }}
						>
							<div className="card-head" id="headingFour">
								<h2
									className="mb-0"
									// data-toggle="collapse"
									data-target="#collapseFour"
									aria-expanded="true"
									aria-controls="collapseFour"
								>
									<div className="item-before">
										{localConst.combinationPromotion}
									</div>
								</h2>
							</div>

							<div
								id="collapseFour"
								className="collapse"
								aria-labelledby="headingFour"
								data-parent="#accordionExample"
							>
								<div className="card-body">
									<CustomInputRadio
										name="promotionPolicy.allowOverlap"
										label={localConst.allowOverlap}
										required={true}
										label1="Yes"
										label2="No"
										option1={true}
										option2={false}
										smallText={localConst.subAllowoverlap}
									/>
									<CustomInputText
										name="promotionPolicy.priority"
										required={true}
										label={localConst.priority}
										smallText={localConst.subPriority}
									/>
									<CustomInputText
										name="promotionPolicy.displayPriority"
										autoComplete="off"
										required={false}
										label={localConst.displayPriority}
										smallText="Display priority for the promotion"
									/>
									{fieldBasicDetails.promotionType == 'MTMB' ||
									fieldBasicDetails.promotionType == 'EARN_BURN_PRODUCT' ||
									fieldBasicDetails.promotionType == 'EARN_BURN_MERCHANDIZE' ||
									fieldBasicDetails.promotionType == 'EARN_BURN' ? (
										<CustomInputRadioString
											name="promotionPolicy.discountAppliesTo"
											label={localConst.discountAppliesTo}
											required={true}
											label1="Lowest Price Item"
											label2="Highest Price Item"
											option1="ALST"
											option2="AMST"
											smallText={localConst.subdiscountAppliesTo}
										/>
									) : null}
								</div>
							</div>
						</Element>
						{/* Discount Application */}
						{fieldBasicDetails.promotionType &&
						fieldBasicDetails.promotionType != 'MTMB' &&
						fieldBasicDetails.promotionType != 'EARN_BURN_PRODUCT' &&
						fieldBasicDetails.promotionType != 'EARN_BURN_MERCHANDIZE' &&
						fieldBasicDetails.promotionType != 'EARN_BURN' ? (
							<Element
								name="scrollToDiscountApplication"
								class="card"
								style={{ border: 'none' }}
							>
								<div className="card-head" id="discountApplication">
									<h2
										className="mb-0"
										// data-toggle="collapse"
										data-target="#redemption"
										aria-expanded="true"
										aria-controls="redemption"
									>
										<div className="item-before">{'Discount Application'}</div>
									</h2>
								</div>

								<div
									id="redemption"
									className="collapse"
									aria-labelledby="discountApplication"
									data-parent="#accordionExample"
								>
									<div className="card-body">
										<CustomSelect
											name="promotionPolicy.distributedSavings"
											required={true}
											label="Distributed Savings"
											options={getDistributedSavingsDropdown()}
											smallText="Determines how the promotional benefit value will be distributed among the trigger and reward items for the promotion."
										/>
										<CustomInputRadioString
											name="promotionPolicy.discountAppliesTo"
											label={localConst.discountAppliesTo}
											required={true}
											label1="Lowest Price Item"
											label2="Highest Price Item"
											option1="ALST"
											option2="AMST"
											smallText={localConst.subdiscountAppliesTo}
										/>
									</div>
								</div>
							</Element>
						) : (
							(fieldBasicDetails.distributedSavings = undefined)
						)}

						{/* <Element
            onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToDiscountApplication')}
            name="scrollToDiscountApplication"
            class="card"
            style={{ border: "none" }}
          >
            <div class="card-head" id="discountApplication">
              <h2
                class="mb-0"
                // data-toggle="collapse"
                data-target="#redemption"
                aria-expanded="true"
                aria-controls="redemption"
              >
                <div className="item-before">{"Discount Application"}</div>
              </h2>
            </div>

            <div
              id="redemption"
              class="collapse"
              aria-labelledby="discountApplication"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                {fieldBasicDetails.promotionType &&
                  fieldBasicDetails.promotionType != "MTMB" &&
                  fieldBasicDetails.promotionType != "EARN_BURN_PRODUCT" &&
                  fieldBasicDetails.promotionType != "EARN_BURN_MERCHANDIZE" &&
                  fieldBasicDetails.promotionType != "EARN_BURN" ? (
                    <CustomSelect
                      name="promotionPolicy.distributedSavings"
                      required={true}
                      label="Distributed Savings"
                      options={getDistributedSavingsDropdown()}
                      smallText="Determines how the promotional benefit value will be distributed among the trigger and reward items for the promotion."
                    />
                  ) : (
                    fieldBasicDetails.distributedSavings = undefined
                  )}
                <CustomInputRadioString
                  name="promotionPolicy.discountAppliesTo"
                  label={localConst.discountAppliesTo}
                  required={true}
                  label1="Lowest Price Item"
                  label2="Highest Price Item"
                  option1="ALST"
                  option2="AMST"
                  smallText={localConst.subdiscountAppliesTo}
                />
              </div>
            </div>
          </Element> */}

						{/* Redemption Transaction Limit */}
						<Element
							onMouseEnter={(e) =>
								onMouseEnterHandler(e, 'scrollToRedemptionSettings')
							}
							name="scrollToRedemptionSettings"
							class="card"
							style={{ border: 'none' }}
						>
							<div className="card-head" id="redemptionTransactionLimit">
								<h2
									className="mb-0"
									// data-toggle="collapse"
									data-target="#redemption"
									aria-expanded="true"
									aria-controls="redemption"
								>
									<div className="item-before">
										{localConst.promotionRedemptionLimit}
									</div>
								</h2>
							</div>

							<div
								id="redemption"
								className="collapse"
								aria-labelledby="redemptionTransactionLimit"
								data-parent="#accordionExample"
							>
								<div className="card-body">
									<CustomInputText
										name="promotionPolicy.promotionRedemptionLimit"
										label={localConst.redemptionLimitAcrossTransaction}
										smallText={localConst.subRedemptionTransactionLimit}
										validate={(val) =>
											customValidateRedemptionTransaction(val, offerFlow)
										}
									/>
									<CustomInputText
										name="promotionPolicy.redeemptionLimitPerUser"
										label={localConst.redemptionUserLimit}
										smallText={localConst.subRedemptionUserLimit}
										validate={(val) =>
											customValidateRedemptionUser(val, offerFlow)
										}
									/>
									<CustomInputText
										name="promotionPolicy.maximumTrigger"
										label={localConst.maximumTrigger}
										smallText={localConst.subMaximumTrigger}
										validate={(val) => customValidateMaxTrigger(val, offerFlow)}
									/>
									<CustomInputText
										name="promotionPolicy.rewardLimit"
										label={localConst.rewardLimit}
										smallText={localConst.subRewardLimit}
										validate={(val) =>
											customValidateRewardLimit(val, offerFlow)
										}
										onClick={() => {
											if (
												Object.keys(
													initialValBakup.priceDerivationRuleEligibility
												)[0] &&
												eval(
													'initialValBakup.priceDerivationRuleEligibility.' +
														Object.keys(
															initialValBakup.priceDerivationRuleEligibility
														)[0].toString() +
														'.paymentEligibility?.payment'
												)
											) {
												if (
													eval(
														'initialValBakup.priceDerivationRuleEligibility.' +
															Object.keys(
																initialValBakup.priceDerivationRuleEligibility
															)[0].toString() +
															'.paymentEligibility.payment.emiType'
													) ||
													eval(
														'initialValBakup.priceDerivationRuleEligibility.' +
															Object.keys(
																initialValBakup.priceDerivationRuleEligibility
															)[0].toString() +
															'.paymentEligibility.payment.paymentInformation.emiType'
													)
												) {
													let disableField = false;
													if (
														eval(
															'initialValBakup.priceDerivationRuleEligibility.' +
																Object.keys(
																	initialValBakup.priceDerivationRuleEligibility
																)[0].toString() +
																'.paymentEligibility.payment.paymentInformation'
														) &&
														eval(
															'initialValBakup.priceDerivationRuleEligibility.' +
																Object.keys(
																	initialValBakup.priceDerivationRuleEligibility
																)[0].toString() +
																'.paymentEligibility.payment.emi.emiScale'
														)
													) {
														let emiTable = eval(
															'initialValBakup.priceDerivationRuleEligibility.' +
																Object.keys(
																	initialValBakup.priceDerivationRuleEligibility
																)[0].toString() +
																'.paymentEligibility.payment.emi.emiScale'
														);
														for (let i = 0; i < emiTable.length; i++) {
															if (emiTable[i].cappedAmount) {
																disableField = true;
																break;
															}
														}
													}
													if (
														eval(
															'initialValBakup.priceDerivationRuleEligibility.' +
																Object.keys(
																	initialValBakup.priceDerivationRuleEligibility
																)[0].toString() +
																'.paymentEligibility.payment.paymentGroup'
														)
													) {
														// let emiTableArrays=eval("initialValBakup.priceDerivationRuleEligibility."+((Object.keys(initialValBakup.priceDerivationRuleEligibility)[0]).toString())+".paymentEligibility.payment.paymentGroup").map(val=>{return val.emi.emiScale})
														let emiTableArrays = eval(
															'initialValBakup.priceDerivationRuleEligibility.' +
																Object.keys(
																	initialValBakup.priceDerivationRuleEligibility
																)[0].toString() +
																'.paymentEligibility.payment.paymentGroup'
														).map((val) => {
															return val.issuers.map((v) => {
																return v.emi.emiScale;
															});
														});

														for (
															let j = 0;
															j < emiTableArrays.length;
															j++
														) {
															for (
																let i = 0;
																i < emiTableArrays[j].length;
																i++
															) {
																for (
																	let k = 0;
																	k < emiTableArrays[j][i].length;
																	k++
																)
																	if (
																		emiTableArrays[j][i][k]
																			.cappedAmount
																	) {
																		disableField = true;
																		break;
																	}
															}
														}
													}
													if (
														disableField &&
														!fieldBasicDetails.rewardLimit
													) {
														setDisableRewardLimit(true);
														setInformationTitle('Warning');
														setInformationMsg(
															'Amount Capping is configured in the Payment Conditions, please clear all the Amount Capping to enter the Reward Limit Amount'
														);
														setInformationModalShow(true);
													}
												}
											}
										}}
										disabled={disableRewardLimit}
									/>
									<CustomInputText
										name="promotionPolicy.redeemptionLimitPerCard"
										label={'Redemption Limit Per Card'}
										smallText={''}
										validate={(val) =>
											customValidateRedemptionLimitPerCard(val, offerFlow)
										}
									/>
									<CustomInputText
										name="promotionPolicy.maxBenefitPerCardAcrossTransaction"
										label={'Maximum Benefit Per Card Across Transaction'}
										smallText={''}
										validate={(val) =>
											customValidateMaxBenefitPerCardTxn(val, offerFlow)
										}
									/>
									<CustomInputText
										name="promotionPolicy.maxBenefitPerCustomerAcrossTransaction"
										label={'Maximum Benefit Per Customer Across Transaction'}
										smallText={''}
										validate={(val) =>
											customValidateMaxBenefitPerCustTxn(val, offerFlow)
										}
									/>
									<CustomInputText
										name="promotionPolicy.maxRedeemAmountAcrossTransaction"
										label={'Maximum Reward Amount Across Transaction '}
										smallText={
											'Determines maximum reward for a promotion can be applied across transaction.'
										}
										validate={(val) =>
											customValidateMaxRedeemAmountAcrossTransaction(
												val,
												offerFlow
											)
										}
									/>
									<CustomInputRadio
										name="promotionPolicy.limitedUse"
										label={localConst.limitedUse}
										disabledVal={
											fieldBasicDetails?.promotionActivationEligibility
												?.multimapPromotion
										}
										required={true}
										label1="Limited"
										label2="Unlimited"
										option1={true}
										option2={false}
										smallText={localConst.subLimitedUse}
									/>
								</div>
							</div>
						</Element>
						{/* Shares */}
						<Element
							onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToShares')}
							name="scrollToShares"
							class="card"
							style={{ border: 'none' }}
						>
							<div className="card-head" id="headingFive">
								<h2
									className="mb-0"
									//  data-toggle="collapse"
									data-target="#collapseFive"
									aria-expanded="true"
									aria-controls="collapseFive"
								>
									<div className="item-before">{localConst.shares}</div>
								</h2>
							</div>
							<div
								id="collapseFive"
								className="collapse"
								aria-labelledby="headingFive"
								data-parent="#accordionExample"
							>
								<div className="card-body">
									{/* <Field name="sharability" component={Shares} /> */}
									<Shares
										initialValBakup={initialValBakup}
										fieldBasicDetails={fieldBasicDetails}
									/>
								</div>
							</div>
						</Element>
						{/* Other details */}
						<Element
							onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToOthers')}
							name="scrollToOthers"
							class="card"
							style={{ border: 'none' }}
						>
							<div className="card-head" id="headingFive">
								<h2
									className="mb-0"
									//  data-toggle="collapse"
									data-target="#collapseSeven"
									aria-expanded="true"
									aria-controls="headingSeven"
								>
									<div className="item-before">{localConst.others}</div>
								</h2>
							</div>
							<div
								id="collapseSeven"
								className="collapse"
								aria-labelledby="headingSeven"
								data-parent="#accordionExample"
							>
								<div className="card-body">
									<CustomInputRadio
										name="promotionPolicy.allowPromotionOnMarkdown"
										label={localConst.allowPromotionOnMarkdown}
										required={true}
										label1="Yes"
										label2="No"
										option1={true}
										option2={false}
										smallText={localConst.suballowPromotionOnMarkdown}
									/>
									<CustomInputRadio
										name="promotionPolicy.includeNonDiscountableItems"
										label={localConst.includeNonDiscount}
										required={true}
										label1="Yes"
										label2="No"
										option1={true}
										option2={false}
										smallText={localConst.subIncludeNonDiscount}
									/>
									<CustomInputRadio
										name="promotionPolicy.allowCouponToBeAppliedOnPromotionItems"
										label={localConst.allowCouponToBeAppliedOnPromotionItems}
										required={true}
										label1="Yes"
										label2="No"
										option1={true}
										option2={false}
										smallText={
											localConst.suballowCouponToBeAppliedOnPromotionItems
										}
									/>
									<CustomInputText
										name="promotionPolicy.redirectionUrl"
										label={localConst.redirectionUrl}
										smallText={localConst.subRedirectionUrl}
									/>
									{/* <CustomInputText name="promotionPolicy.termsAndCondition" label={localConst.termsAndCondition} smallText={localConst.subTermsAndCondition} />
                  <CustomInputText name="promotionPolicy.imageUrl" label={localConst.imageUrl} smallText={localConst.subImageUrl} /> */}
									<div className="pl-pr container">
										{isAuditFlw ? (
											<>
												<div
													className={
														OldTc.length == 0 && NewTc.length == 0
															? 'd-none'
															: 'font-weight-bold pl-2'
													}
												>
													{localConst.termsAndCondition}
												</div>
												<div
													className={
														OldTc.length == 0 && NewTc.length == 0
															? 'd-none'
															: 'box-blackout-period lightBlue'
													}
												>
													<div className="d-flex mr-2">
														<div className="issuerOuterBox mb-3 col-sm-6">
															<div className="pl-2 ">
																{/* <label className="font-weight-bold">v{typeofOfferFlw.prevVer}</label> */}
															</div>
															<div className="col-sm-12">
																{OldTc.map((val, ind) => {
																	return (
																		<div
																			key={ind}
																			className="row"
																		>
																			<div
																				className={
																					termsRemoved.indexOf(
																						val
																					) != -1
																						? 'channelNodeAudit text-danger mb-2'
																						: 'channelNodeAudit mb-2 text-dark bg-white'
																				}
																			>
																				{val}
																			</div>
																			<div
																				className={
																					termsRemoved.indexOf(
																						val
																					) != -1
																						? 'text-danger'
																						: 'd-none'
																				}
																			>
																				{' '}
																				(Removed)
																			</div>
																		</div>
																	);
																})}
															</div>
														</div>

														<div className="issuerOuterBox mb-3 col-sm-6">
															<div className="pl-2 ">
																{/* <label className="font-weight-bold">v{typeofOfferFlw.currVer}</label> */}
															</div>
															<div className="col-sm-12">
																{NewTc.map((val, ind) => {
																	return (
																		<div
																			key={ind}
																			className="row"
																		>
																			<div
																				className={
																					termsAdded.indexOf(
																						val
																					) != -1
																						? 'channelNodeAudit mb-2'
																						: 'channelNodeAudit mb-2 text-dark bg-white'
																				}
																			>
																				{val}
																			</div>
																			<div
																				className={
																					termsAdded.indexOf(
																						val
																					) != -1
																						? 'text-success'
																						: 'd-none'
																				}
																			>
																				{' '}
																				(Added)
																			</div>
																		</div>
																	);
																})}
															</div>
														</div>
													</div>
												</div>
											</>
										) : (
											<FieldArray name="promotionPolicy.termsAndConditions">
												{({ remove }) => (
													<>
														{fieldBasicDetails.termsAndConditions.map(
															(item, index) => (
																<div
																	key={index}
																	className="d-flex justify-content-center align-items-center"
																>
																	<CustomInputText
																		name={`promotionPolicy.termsAndConditions.${index}`}
																		label={
																			localConst.termsAndCondition
																		}
																		smallText={
																			localConst.subTermsAndCondition
																		}
																	/>
																	{index == 0 ? (
																		<>
																			{' '}
																			<button
																				type="button"
																				className="pl-prPlusBtn addPlusBtn"
																				onClick={() => {
																					fieldBasicDetails.termsAndConditions.push(
																						''
																					);
																					setFieldValue(
																						'',
																						''
																					);
																				}}
																			></button>
																			<button
																				type="button"
																				className="pl-prDelBtn addDeleteBtn"
																				onClick={() => {
																					/* fieldBasicDetails.termsAndConditions.splice(
                                          index,
                                          1
                                        );
                                        setFieldValue(`promotionPolicy.termsAndConditions.${index}`,""); */
																					if (
																						fieldBasicDetails
																							.termsAndConditions
																							.length >
																						1
																					) {
																						remove(
																							index
																						);
																						setFieldValue(
																							'',
																							''
																						);
																					} else {
																						setFieldValue(
																							`promotionPolicy.termsAndConditions.${0}`,
																							''
																						);
																					}
																				}}
																			></button>{' '}
																		</>
																	) : (
																		<button
																			type="button"
																			className="pl-prDeleteBtn addDeleteBtn"
																			onClick={() => {
																				/* fieldBasicDetails.termsAndConditions.splice(
                                      index,
                                      1
                                    );
                                    setFieldValue(`promotionPolicy.termsAndConditions.${index}`,""); */

																				remove(index);
																				setFieldValue(
																					'',
																					''
																				);
																			}}
																		></button>
																	)}
																</div>
															)
														)}
													</>
												)}
											</FieldArray>
										)}
									</div>
									<div className="pl-pr container">
										{isAuditFlw ? (
											<>
												<div
													className={
														OldOiu.length == 0 && NewOiu.length == 0
															? 'd-none'
															: 'font-weight-bold pl-2'
													}
												>
													{localConst.imageUrl}
												</div>
												<div
													className={
														OldOiu.length == 0 && NewOiu.length == 0
															? 'd-none'
															: 'box-blackout-period lightBlue'
													}
												>
													<div className="d-flex mr-2">
														<div className="issuerOuterBox mb-3 col-sm-6">
															<div className="pl-2 ">
																{/* <label className="font-weight-bold">v{typeofOfferFlw.prevVer}</label> */}
															</div>
															<div className="col-sm-12">
																{OldOiu.map((val, ind) => {
																	return (
																		<div
																			key={ind}
																			className="row"
																		>
																			<div
																				className={
																					offerImgUrlRemoved.indexOf(
																						val
																					) != -1
																						? 'channelNodeAudit text-danger mb-2'
																						: 'channelNodeAudit mb-2 text-dark bg-white'
																				}
																			>
																				{val}
																			</div>
																			<div
																				className={
																					offerImgUrlRemoved.indexOf(
																						val
																					) != -1
																						? 'text-danger'
																						: 'd-none'
																				}
																			>
																				{' '}
																				(Removed)
																			</div>
																		</div>
																	);
																})}
															</div>
														</div>

														<div className="issuerOuterBox mb-3 col-sm-6">
															<div className="pl-2 ">
																{/* <label className="font-weight-bold">v{typeofOfferFlw.currVer}</label> */}
															</div>
															<div className="col-sm-12">
																{NewOiu.map((val, ind) => {
																	return (
																		<div
																			key={ind}
																			className="row"
																		>
																			<div
																				className={
																					offerImgUrlAdded.indexOf(
																						val
																					) != -1
																						? 'channelNodeAudit mb-2'
																						: 'channelNodeAudit mb-2 text-dark bg-white'
																				}
																			>
																				{val}
																			</div>
																			<div
																				className={
																					offerImgUrlAdded.indexOf(
																						val
																					) != -1
																						? 'text-success'
																						: 'd-none'
																				}
																			>
																				{' '}
																				(Added)
																			</div>
																		</div>
																	);
																})}
															</div>
														</div>
													</div>
												</div>
											</>
										) : (
											<FieldArray name="promotionPolicy.offerImageUrl">
												{({ remove }) => (
													<>
														{fieldBasicDetails.offerImageUrl.map(
															(item, index) => (
																<div
																	key={index}
																	className="d-flex justify-content-center align-items-center"
																>
																	<CustomInputText
																		name={`promotionPolicy.offerImageUrl.${index}`}
																		label={localConst.imageUrl}
																		smallText={
																			localConst.subImageUrl
																		}
																	/>
																	{index == 0 ? (
																		<>
																			<button
																				type="button"
																				className="pl-prPlusBtn addPlusBtn"
																				onClick={() => {
																					fieldBasicDetails.offerImageUrl.push(
																						''
																					);
																					setFieldValue(
																						'',
																						''
																					);
																				}}
																			></button>
																			<button
																				type="button"
																				className="pl-prDelBtn addDeleteBtn"
																				onClick={() => {
																					//  remove(index);
																					//  setFieldValue("", "")
																					/* fieldBasicDetails.offerImageUrl.splice(
                                          index,
                                          1
                                        )
                                       setFieldValue(`promotionPolicy.offerImageUrl.${index}`, ""); */
																					if (
																						fieldBasicDetails
																							.offerImageUrl
																							.length >
																						1
																					) {
																						remove(
																							index
																						);
																						setFieldValue(
																							'',
																							''
																						);
																					} else {
																						setFieldValue(
																							`promotionPolicy.offerImageUrl.${0}`,
																							''
																						);
																					}
																				}}
																			></button>
																		</>
																	) : (
																		<button
																			type="button"
																			className="pl-prDeleteBtn addDeleteBtn"
																			onClick={() => {
																				remove(index);
																				setFieldValue(
																					'',
																					''
																				);
																				/* fieldBasicDetails.offerImageUrl.splice(
                                      index,
                                      1
                                    )
                                   setFieldValue(`promotionPolicy.offerImageUrl.${index}`, ""); */
																			}}
																		></button>
																	)}
																</div>
															)
														)}
													</>
												)}
											</FieldArray>
										)}
									</div>
									<CustomInputText
										name="promotionPolicy.offerGenre"
										type="textarea"
										label={localConst.offerGenre}
										smallText={localConst.subOfferGenre}
									/>
									<CustomInputText
										type={'textarea'}
										name="promotionPolicy.supplementaryDetails"
										label={localConst.supplementaryDetails}
										required={false}
										smallText={localConst.subSupplementaryDetails}
									/>
									<CustomInputText
										type={'textarea'}
										name="promotionPolicy.customField1"
										label={localConst.customField1}
										required={false}
										smallText={localConst.customField1Details}
									/>
									<CustomInputText
										type={'textarea'}
										name="promotionPolicy.customField2"
										label={localConst.customField2}
										required={false}
										smallText={localConst.customField2Details}
									/>
									<CustomInputRadio
										name="promotionPolicy.giftVoucherTenderedPromotion"
										label={localConst.giftVoucherTenderedPromotion}
										required={true}
										label1="Yes"
										label2="No"
										option1={true}
										option2={false}
										smallText={
											localConst.suballowCouponToBeAppliedOnPromotionItems
										}
									/>
								</div>
							</div>
						</Element>
					</div>
				</div>
			</div>
			<div className="section-container">
				<div className="item-title">{localConst.applicationEligibility}</div>
				{/* Other details */}
				<div className="container">
					<Element
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToChannelEligibility')}
						name="scrollToChannelEligibility"
						class="card"
						style={{ border: 'none' }}
					>
						<div className="card-head" id="headingFive">
							<h2
								className="mb-0"
								//  data-toggle="collapse"
								data-target="#collapse8"
								aria-expanded="true"
								aria-controls="heading8"
							>
								<div className="item-before" style={{ border: 'none' }}>
									{localConst.channelEligibility}
								</div>
							</h2>
						</div>
						<div
							id="collapse8"
							className="collapse"
							aria-labelledby="heading8"
							data-parent="#accordionExample"
						>
							<div className="card-body">
								{/* <div className="container"> */}
								{isAuditFlw ? (
									<>
										<AuditChannel
											oldJson={
												fieldBasicDetails.promotionApplicableEligibility
													.channelEligibility.channel
											}
											newJson={JSON.parse(
												sessionStorage.getItem('NewPolicy')
											)}
										/>
									</>
								) : (
									<>
										<div className="d-flex">
											<span
												className="channelLink mr-2"
												onClick={() => {
													handleSelectionNew(true);
												}}
											>
												Configure Channel
											</span>
											{/* {meta.error && meta.error.promotionPolicy && meta.error.promotionPolicy.promotionApplicableEligibility 
                    && meta.error.promotionPolicy.promotionApplicableEligibility.channelEligibility
                    && meta.error.promotionPolicy.promotionApplicableEligibility.channelEligibility.channel?
                    <div class="item-error" style={{color: 'red'}}>{meta.error.promotionPolicy.promotionApplicableEligibility.channelEligibility.channel}</div>
                    :
                    null
                  }                 */}
											{/* <CsvParse
                  keys={channelKey}
                  onDataUploaded={(data) => handleCSVData(data)}
                  onError={() => {}}
                  render={(onChange) => (
                    <>
                      <input
                        type="file"
                        id="idLoadChannelFromFile"
                        style={{ display: "none" }}
                        onChange={onChange}
                      />
                      <label for="idLoadChannelFromFile" className={offerFlow.type == "view-offer-flow"?"uploadBtn disabled":"uploadBtn"}>
                        {" "}
                        
                      </label>
                      <small
                          id="emailHelp"
                          className="form-text pl-2 channel-Tooltip">
                          <div class="tooltipimg"><button className="tooltip-button btn br-50" type="button"><span className="tooltip-icon">?</span></button>
                              <span class="tooltiptext">Determines the set of channels and stores the promotion is applicable</span>
                          </div>
                      </small>
                    </>
                  )}
                /> */}
											<CSVReader
												ref={buttonRef}
												onFileLoad={handleOnFileLoad}
												onError={handleOnError}
												noClick
												noDrag
												onRemoveFile={handleOnRemoveFile}
											>
												{() => (
													<aside
														style={{
															display: 'flex',
															flexDirection: 'row'
														}}
													>
														<label
															className={
																offerFlow.type == 'view-offer-flow'
																	? 'uploadBtn disabled'
																	: 'uploadBtn'
															}
															onClick={handleOpenDialog}
														></label>
														<small
															id="emailHelp"
															className="form-text pl-2 channel-Tooltip"
														>
															<div className="tooltipimg">
																<button
																	className="tooltip-button btn br-50"
																	type="button"
																>
																	<span className="tooltip-icon">
																		?
																	</span>
																</button>
																<span className="tooltiptext">
																	Determines the set of channels
																	and stores the promotion is
																	applicable
																</span>
															</div>
														</small>
														{/* <button
                                type='button'
                                onClick={handleOpenDialog}
                                style={{
                                    borderRadius: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    width: '40%',
                                    paddingLeft: 0,
                                    paddingRight: 0
                                }}
                            >
                                Browe file
                            </button>
                            <div
                                style={{
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#ccc',
                                    height: 45,
                                    lineHeight: 2.5,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    paddingLeft: 13,
                                    paddingTop: 3,
                                    width: '60%'
                                }}
                            >
                                {file && file.name}
                            </div>
                            <button
                                style={{
                                    borderRadius: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    paddingLeft: 20,
                                    paddingRight: 20
                                }}
                                onClick={handleRemoveFile}
                            >
                                Remove
                            </button> */}
													</aside>
												)}
											</CSVReader>
										</div>
										{fieldBasicDetails.promotionApplicableEligibility.channelEligibility.channel.map(
											(item, ind) => {
												return (
													<div
														key={ind}
														id="item-enclosing-49-instance-1_1_1_1"
														className="sequence pt-2"
													>
														<div
															id="repeating-enclosing-49-instance-1_1_1_1"
															className="repeating-enclosing channelFlex"
														>
															<div
																id="sequence-49-instance-1"
																className="sequence-content"
															>
																<div
																	id="item-enclosing-50-instance-1_1_1_1_1"
																	className="item-enclosing"
																>
																	<div
																		id="repeating-enclosing-50-instance-1_1_1_1_1"
																		className="repeating-enclosing"
																	>
																		<label
																			className="item-label channelLabel"
																			htmlFor="item-input-50-instance-1_1_1_1_1"
																		>
																			{item.name}
																		</label>
																		<div className="item-input">
																			<div
																				id="item-path-50-instance-1_1_1_1_1"
																				className="item-path"
																				/* eslint-disable react/no-unknown-property */
																				enabled="true"
																			></div>
																		</div>
																		<div className="clr"></div>
																	</div>
																</div>
																<div className="d-inline-flex flex-wrap">
																	{item.applicableIds.id.map(
																		(storeID, i) => {
																			if (i < 10) {
																				return (
																					<div key={i}>
																						<div
																							id="repeating-enclosing-52-instance-1_1_1_1_1_1"
																							className="repeating-enclosing channelNode"
																						>
																							<label
																								className="channelIdLabel"
																								htmlFor="item-input-52-instance-1_1_1_1_1_1"
																							>
																								{
																									storeIdNameObj[
																										storeID
																									]
																								}
																							</label>
																							<div className="item-input">
																								<div
																									id="item-path-52-instance-1_1_1_1_1_1"
																									className="item-path"
																									/* eslint-disable react/no-unknown-property */
																									enabled="true"
																								></div>
																							</div>
																						</div>
																					</div>
																				);
																			}
																		}
																	)}
																	{item.applicableIds.id.length >
																	10 ? (
																		<div
																			className="viewAll"
																			onClick={() => {
																				handleSelectionNew(
																					true
																				);
																			}}
																		>
																			View All
																		</div>
																	) : null}
																</div>
															</div>
														</div>
													</div>
												);
											}
										)}
									</>
								)}
								{/* </div> */}

								<div className={show ? 'modal in-right d-block' : 'modal'}>
									<div
										className="modal-dialog m-0 modal-bottom-right"
										role="document"
									>
										<div className="modal-content h-100">
											<div className="modal-header modal-title-custom p-2 b-radius-none">
												<p
													className="modal-title ml-2 roboto-r-18"
													id="exampleModalLabel"
												>
													Assign Brand
												</p>
											</div>

											<iframe
												title="iFrame"
												id="modalDivFrm"
												src="../../channel-tree/index.html"
												width="100%"
												height="85%"
												frameBorder="0"
											></iframe>
											<div className="modalDiv-footer">
												<div
													className="buttonClose"
													id="buttonCloseStorePopup"
													onClick={() => {
														handleSelectionNew(false);
													}}
												>
													Cancel
												</div>
												<div
													className={
														offerFlow.type == 'view-offer-flow'
															? 'buttonAdd disabled'
															: 'buttonAdd'
													}
													id="buttonAddStorePopup"
													onClick={() => {
														updatedSelectedStores();
													}}
												>
													Add
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Element>
				</div>

				<div
					className={`container ${
						offerFlow.type == 'view-offer-flow' ? ' disabledEvent' : ''
					}`}
				>
					{/* <div className="item-before">{localConst.channelEligibility}</div> */}
					{/* <Field
            name="promotionApplicableEligibility"
            component={EligibilitySubForm}
          /> */}
					<EligibilitySubForm
						isTSSSEligible={isTSSSEligible}
						resetAllTables={props.resetAllTables}
					/>
				</div>
			</div>
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val }),
		showLoadingButton2: (val) => dispatch({ type: actionTypes.LOADING_BUTTON2, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetailsFormik);
