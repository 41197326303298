import React from 'react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';

import { FormikGroup } from '../../Components/Input/FormikControl';
import resetIcon from '../../assets/images/undo.png';
import appLabels from '../../utils/appLabels';
import { stringReplacer } from '../../utils/helperFunction';
import appConstants from '../../utils/appConstants';

const {
	couponSubType: { MULTIUSE, SINGLEUSE },
	multiuseCouponRedemptionLimit,
	singleUseCouponRedemptionLimit
} = appConstants;
const {
	common: commonLabels,
	page: { generateCoupon: pageLabels }
} = appLabels;

export const validDateFormat = [
	'DD-MM-YYYY HH:mm',
	'DD-MM-YYYY HH:mm:ss',
	'YYYY-MM-DD HH:mm',
	'YYYY-MM-DD HH:mm:ss'
];

const DEFAULT_PATTERN = {
	ind: 0,
	value: '########,' /* pattern,separator  {string}*/,
	label: '(No Pattern) ########'
};
const perBatchLimitNumOfCoupons = 1000000;
const { formLabels } = pageLabels;

const characterSetOptions = [
	{ label: 'Uppercase', value: 'uppercase' },
	{ label: 'Lowercase', value: 'lowercase' },
	{ label: 'Number', value: 'number' }
];

const makeCode = ({ couponLength = 1, characterSetList = {} }) => {
	const { uppercase = true, lowercase, number } = characterSetList;
	let result = '';
	let characters = '';

	if (uppercase) {
		characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	}
	if (lowercase) {
		characters += 'abcdefghijklmnopqrstuvwxyz';
	}
	if (number) {
		characters += '0123456789';
	}
	if (!characters) {
		characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	}

	const charactersLength = characters.length;
	for (let i = 0; i < couponLength; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const numberValidation = ({ min = 1, max = perBatchLimitNumOfCoupons }) => {
	return (value) => {
		let error = null;
		if (value == null || value.length === 0) {
			return null;
		}
		if (!Number.isInteger(value)) {
			error = pageLabels.numberInvalidError;
		} else if (value < min || value > max) {
			error = stringReplacer(pageLabels.numberBetweenError, { '@min': min, '@max': max });
		}
		return error;
	};
};

export const fieldValidator = (validators = []) => {
	return (value) => {
		let error = null;
		validators.forEach((validator, ind) => {
			if (error !== null) {
				/* return with the first error */
				return null;
			}

			const validatorTypeOf = typeof validator;

			if (validatorTypeOf === 'string') {
				switch (validator) {
					case 'require':
						if (
							!value ||
							(Array.isArray(value) && !value.length) ||
							(typeof value === 'string' && !value.trim())
						) {
							error = 'Required';
						}
						break;
					default:
					// error = null;
				}
			} else if (validatorTypeOf === 'function') {
				const funError = validator(value);
				error = funError;
			}
		});
		return error;
	};
};

export const dateValidator = ({ min = null, max = null }) => {
	return (value) => {
		const dateFormat = validDateFormat[0];

		let error = null;
		if (!value) {
			return null;
		}

		const isValidFomat = moment(value, dateFormat, true).isValid();
		if (!isValidFomat) {
			error = stringReplacer(pageLabels.dateFormatError, ' @format', dateFormat);
			return error;
		}

		let _dateTime = moment(value, dateFormat);
		let isBetween = true;

		if (min && max) {
			let _min = moment(min, dateFormat);
			let _max = moment(max, dateFormat);
			isBetween = moment(_dateTime).isBetween(_min, _max);
			if (!isBetween) {
				/* check the same date */
				isBetween =
					moment(_dateTime).isSame(moment(_max, dateFormat)) ||
					moment(_dateTime).isSame(moment(_min, dateFormat));
			}
		} else if (min != null && min !== '' && max === null) {
			isBetween = moment(_dateTime).isSameOrAfter(moment(min, dateFormat));
		}
		if (min === null && max != null && max !== '') {
			isBetween = moment(_dateTime).isSameOrBefore(moment(max, dateFormat));
		}

		if (!isBetween) {
			if (max === null) {
				error = `Date should be greater than ${min}`;
			} else if (min == null) {
				error = `Date should be less than ${max}`;
			} else {
				error = stringReplacer(pageLabels.dateBetweenError, {
					'@min': dbDateFormat(min),
					'@max': dbDateFormat(max)
				});
			}
			return error;
		}

		return error;
	};
};

/* convert the date from  DD-MM-YYYY HH:mm used to format date from data base*/
export const dbDateFormat = (date) => {
	const isValidFomat = moment(date, validDateFormat[3], true).isValid();
	if (isValidFomat) {
		return moment(date).format(validDateFormat[0]);
	} else {
		return date;
	}
};

const patternMasking = ({ couponLength, couponPattern }) => {
	const [pattern, patternSeperator] = couponPattern.split(',');

	const patternWordList = pattern.split(patternSeperator);

	const patternWordLen = patternWordList.join('').length; // word length between code separator ###

	const numOfPattern = parseInt(couponLength / patternWordLen); // number of word  between code separator  eg ###,###,###
	const extNumPattern = couponLength % patternWordLen; // number word length of last code separator -##
	let patternList = [];
	try {
		patternList = new Array(numOfPattern).fill(pattern);
	} catch (e) {
		patternList = [];
	}

	let extPatternList = [];
	let remExtPat = extNumPattern;

	if (extNumPattern) {
		for (let i = 0; i < patternWordList.length; i++) {
			const patLen = patternWordList[i].length;
			if (patLen < remExtPat) {
				extPatternList.push(appConstants.generateCouponMask.repeat(patLen));
				remExtPat = remExtPat - patLen;
			} else {
				extPatternList.push(appConstants.generateCouponMask.repeat(remExtPat));
				break;
			}
		}
	}

	return [...patternList, ...extPatternList];
};

const previewCode = (formik) => {
	const {
		couponLength,
		pattern: _pattern,
		couponPrefix,
		couponSuffix,
		characterSetList
	} = formik.values;
	let readyForPreview = formik.isValid;
	const clusterList = [];
	let code = '';

	const [pattern, patternSeperator] = _pattern.split(',');

	const patternWordList = pattern.split(patternSeperator);

	const patternWordLen = patternWordList.join('').length; // word length between code separator ###

	if (readyForPreview && couponLength && pattern) {
		readyForPreview = couponLength >= patternWordLen;
	}

	let isNoPattern = patternSeperator === '' && pattern === '';

	if (readyForPreview) {
		if (!isNoPattern) {
			const _characterSet = {
				uppercase: characterSetList.includes('uppercase'),
				lowercase: characterSetList.includes('lowercase'),
				number: characterSetList.includes('number')
			};

			const finalCodeList = patternMasking({ couponLength, couponPattern: _pattern })
				.join(patternSeperator)
				.split(patternSeperator);

			finalCodeList.forEach((codeWord) => {
				clusterList.push(
					makeCode({ couponLength: codeWord.length, characterSetList: _characterSet })
				);
			});

			code = clusterList.join(patternSeperator);
		}
		code = `${couponPrefix.trim()}${code}${couponSuffix.trim()}`;
	}
	return code;
};

const PreviewCodeInput = ({ formik }) => {
	return (
		<div className="formik-Control-wrapper">
			<div className="formik-Control vertical-group">
				<div className=" formik-input-group">
					<label className="formik-input-label col-form-label">
						{formLabels.previewCode.label}
					</label>
					<div className="formik-input">
						<div className="input-wrapper">
							<input
								type="text"
								className="form-control input-field"
								id="previewCode"
								name="previewCode"
								placeholder={formLabels.previewCode.placeholder}
								readOnly
								value={previewCode(formik)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const possibleCouponCount = (values) => {
	const { couponLength, characterSetList } = values;
	const _uppercase = characterSetList.includes('uppercase');
	const _lowercase = characterSetList.includes('lowercase');
	const _number = characterSetList.includes('number');

	let base = 0;
	if (_uppercase) base = base + 26;
	if (_lowercase) base = base + 26;
	if (_number) base = base + 10;

	return base ** couponLength;
};

const calcMaxCouponsGenerated = ({ formik, batchId, leftOverCombination }) => {
	const { couponLength, characterSetList } = formik.values;
	const nextBatchID = `${parseInt(batchId) + 1}`;
	/* couponLength <= 4 not using batchID to prevent duplicate code */
	const _couponLength = couponLength > 4 ? couponLength - nextBatchID.length : couponLength;
	const possibleCount = possibleCouponCount({
		couponLength: _couponLength,
		characterSetList
	});

	let maxCouponsGenerated = possibleCount;
	if (maxCouponsGenerated > leftOverCombination) {
		maxCouponsGenerated = leftOverCombination;
	}
	if (maxCouponsGenerated > perBatchLimitNumOfCoupons) {
		maxCouponsGenerated = perBatchLimitNumOfCoupons;
	}

	return {
		possibleCouponCount: possibleCount,
		maxCouponsGenerated
	};
};

const CouponsProbabilityCount = ({ formik, batchId, leftOverCombination }) => {
	// if (!formik.isValid) {
	// 	return null;
	// }
	// const { couponLength, characterSetList } = formik.values;
	// const nextBatchID = `${parseInt(batchId) + 1}`;
	// const _couponLength = couponLength > 6 ? couponLength - nextBatchID.length : couponLength;
	// let maxCouponsGenerated = possibleCouponCount({
	// 	couponLength: _couponLength,
	// 	characterSetList
	// });
	// if (maxCouponsGenerated > leftOverCombination) {
	// 	maxCouponsGenerated = leftOverCombination;
	// }
	// if (maxCouponsGenerated > perBatchLimitNumOfCoupons) {
	// 	maxCouponsGenerated = perBatchLimitNumOfCoupons;
	// }

	const data = calcMaxCouponsGenerated({ formik, batchId, leftOverCombination });
	return (
		<>
			<span className="text-center pr-5 text-success">
				{stringReplacer(pageLabels.maxPossibleHelper, '@num', data.possibleCouponCount)}
			</span>
			<br />
			<span className="text-center pr-5 text-success">
				{stringReplacer(pageLabels.maxNumCouponsHelper, '@num', data.maxCouponsGenerated)}
			</span>
		</>
	);
};

// formValidation not require as pattern options is alreadyfilter and sync with couponLength
/* const formValidation = (value) => {
	const { couponLength, pattern: _pattern } = value;
	let errors = {};
	const [pattern, patternSeperator] = _pattern.split(',');

	if (couponLength && pattern) {
		const patternWordList = pattern.split(patternSeperator);

		const patternWordLen = patternWordList.join('').length; // word length between code separator ###
		// patternSeperator.length > 0 to check no pattern so that no error at no-pattern selection
		if ( patternSeperator.length > 0 && couponLength < patternWordLen) {
			errors.couponLength = stringReplacer(
				pageLabels.patterLengthError,
				'@num',
				patternWordLen
			);
		}
	}
	return errors;
}; */

export const GeneratedCouponInfo = ({ generatedCouponCount, leftOverCombination }) => {
	return (
		<span
			className={`p-2 ${leftOverCombination === 0 ? 'bg-danger text-white' : 'text-success'}`}
		>
			{pageLabels.noOfCouponsGen} <strong>{generatedCouponCount}</strong>
			{'. '}
			{pageLabels.leftOverText} <strong>{leftOverCombination}</strong>
		</span>
	);
};

/* filter the options as per the couponCodeLength */
const getPatternOptions = (patternOptions, couponCodeLength, defaultPattern) => {
	let _patterOptions = patternOptions.filter((obj) => {
		const [pattern, patternSeperator] = obj.value.split(',');
		const patternWordLen = pattern.split(patternSeperator).join('').length;
		return parseInt(couponCodeLength) === patternWordLen;
	});
	// _patterOptions.push(defaultPattern);

	if (_patterOptions.length === 0) {
		_patterOptions = [defaultPattern];
	}
	return _patterOptions;
};

const formControls = (formik, props) => {
	const {
		patternOptions,
		defaultPattern,
		initValues: { startDate, expiryDate, isMultiuseCoupon, isReplicate = false },
		configuredCount,
		generatedCouponCount,
		batchId
	} = props;

	const couponRedemptionLimitMax = isMultiuseCoupon
		? multiuseCouponRedemptionLimit
		: singleUseCouponRedemptionLimit;
	const leftOverCombination = configuredCount - generatedCouponCount;

	let _patterOptions = getPatternOptions(
		patternOptions,
		formik.values.couponLength,
		defaultPattern
	);

	const inputs = [
		[
			{
				control: 'input',
				...formLabels.promoID,
				name: 'promotionId',
				readOnly: true,
				controlClass: 'vertical-group'
			},
			{
				control: 'input',
				...formLabels.promoExpiryDate,
				name: 'promotionEndDate',
				readOnly: true,
				controlClass: 'vertical-group'
			}
		],
		[
			{
				control: 'input',
				...formLabels.couponType,
				name: 'couponType',
				readOnly: true,
				controlClass: 'vertical-group'
			},
			{
				control: 'input',
				...formLabels.couponSubType,
				name: 'couponSubType',
				readOnly: true,
				controlClass: 'vertical-group'
			}
		],
		[
			{
				control: 'number',
				min: 0,
				max: couponRedemptionLimitMax,
				...formLabels.couponRedemptionLimit,
				name: 'couponRedemptionLimit',
				readOnly: isReplicate || !isMultiuseCoupon,
				controlClass: 'vertical-group',
				validate: fieldValidator([
					numberValidation({ min: 1, max: couponRedemptionLimitMax })
				])
			},
			{
				control: 'datetime',
				...formLabels.couponExpiryDate,
				name: 'couponExpiryDate',
				required: true,
				controlClass: 'vertical-group',
				readOnly: isReplicate,
				validate: fieldValidator([
					'require',
					dateValidator({
						min: dbDateFormat(startDate),
						max: dbDateFormat(expiryDate)
					})
				])
			}
		],
		[
			{
				control: 'number',
				min: 0,
				max: perBatchLimitNumOfCoupons,
				...formLabels.numberOfCoupons,
				name: 'numberOfCoupons',
				required: true,
				controlClass: 'vertical-group',
				helperText: (
					<CouponsProbabilityCount
						formik={formik}
						batchId={batchId}
						leftOverCombination={leftOverCombination}
					/>
				),
				iconHelper: false,
				validate: fieldValidator([
					'require',
					numberValidation({
						min: 1,
						max:
							leftOverCombination === 0
								? 1
								: calcMaxCouponsGenerated({ formik, batchId, leftOverCombination })
										.maxCouponsGenerated
					})
				])
			},
			{
				control: 'number',
				min: 0,
				max: 30,
				...formLabels.couponLength,
				name: 'couponLength',
				required: true,
				controlClass: 'vertical-group',
				readOnly: isReplicate,
				iconHelper: false,
				validate: fieldValidator(['require', numberValidation({ min: 4, max: 30 })])
			}
		],
		[
			{
				control: 'select',
				...formLabels.pattern,
				options: _patterOptions,
				name: 'pattern',
				required: true,
				controlClass: 'vertical-group',
				inputClass: 'customSelectIcon',
				readOnly: isReplicate,
				validate: fieldValidator(['require'])
			},
			{
				control: 'checkbox',
				...formLabels.characterSetList,
				name: 'characterSetList',
				options: characterSetOptions,
				required: true,
				controlClass: 'vertical-group group-inline',
				readOnly: isReplicate,
				iconHelper: false,
				validate: fieldValidator(['require'])
			}
		],
		[
			{
				control: 'input',
				...formLabels.couponPrefix,
				name: 'couponPrefix',
				readOnly: isReplicate,
				controlClass: 'vertical-group'
			},
			{
				control: 'input',
				...formLabels.sufix,
				name: 'couponSuffix',
				readOnly: isReplicate,
				controlClass: 'vertical-group'
			}
		]
	];

	return <FormikGroup inputs={inputs} />;
};

const GenerateCouponForm = (props) => {
	const {
		initValues,
		patternOptions,
		configuredCount,
		generatedCouponCount,
		cancel,
		defaultPattern,
		defaultCouponLength,
		defaultCharacterSet
	} = props;
	const leftOverCombination = configuredCount - generatedCouponCount;

	const initFormValues = (initVal = {}, patternOptions = []) => {
		const {
			promotionId,
			expiryDate,
			couponType,
			isMultiuseCoupon,
			displayCoupons,
			couponRedemptionLimit,
			numberOfCoupons = 1,
			couponLength = defaultCouponLength,
			couponPatternSeparator,
			characterSetList = defaultCharacterSet,
			couponPrefix = '',
			couponSuffix = '',
			isReplicate = false
		} = initVal;

		const _couponSubType = isMultiuseCoupon ? MULTIUSE : SINGLEUSE;

		let _couponRedemptionLimit = couponRedemptionLimit || multiuseCouponRedemptionLimit;
		if (!isReplicate && _couponSubType === MULTIUSE && displayCoupons && configuredCount) {
			_couponRedemptionLimit = parseInt(configuredCount);
		}

		if (!isReplicate && _couponSubType === SINGLEUSE) {
			_couponRedemptionLimit = 1;
		}

		const replicatePattern = isReplicate && couponPatternSeparator;
		const expiryDateFormatted = dbDateFormat(expiryDate);
		const _pattern =
			replicatePattern ||
			getPatternOptions(patternOptions, couponLength, defaultPattern)[0].value ||
			defaultPattern.value;
		const initialValues = {
			promotionId,
			promotionEndDate: expiryDateFormatted,
			couponType,
			couponSubType: _couponSubType,
			numberOfCoupons,
			couponLength,
			pattern: _pattern,
			couponPrefix,
			couponSuffix,
			characterSetList,
			couponExpiryDate: expiryDateFormatted,
			couponRedemptionLimit: _couponRedemptionLimit
		};
		return initialValues;
	};

	const onSubmit = (values, { setSubmitting }) => {
		setSubmitting(false);
		const { startDate, displayCoupons } = props.initValues;
		const {
			promotionEndDate,
			couponExpiryDate,
			pattern,
			couponLength,
			couponPrefix,
			couponSuffix
		} = values;

		const [, separator] = pattern.split(',');
		const regExp = new RegExp(`[^${separator}]`, 'g'); /* not separator */
		let couponPattern = patternMasking({ couponLength, couponPattern: pattern }).join(
			separator
		);
		couponPattern = couponPattern.replace(regExp, appConstants.generateCouponMask);
		const formData = {
			...values,
			promotionEndDate: moment(promotionEndDate, validDateFormat[0]).format(
				validDateFormat[3]
			),
			couponExpiryDate: moment(couponExpiryDate, validDateFormat[0]).format(
				validDateFormat[3]
			),
			couponPattern,
			promotionStartDate: startDate,
			couponPatternSeparator: pattern,
			displayCoupons: displayCoupons.toString(),
			couponPrefix: couponPrefix.trim(),
			couponSuffix: couponSuffix.trim()
		};
		/* removing extra pattern from formdata */
		['pattern'].forEach((key) => {
			delete formData[key];
		});

		props.submitHandler?.({
			formData
		});
	};

	return (
		<Formik
			initialValues={initFormValues(initValues, patternOptions)}
			onSubmit={onSubmit}
			// validate={formValidation}
		>
			{(formik) => {
				return (
					<Form
						onChange={(event) => {
							// Not require pattern options is alreadyfilter and sync with couponLength
							// if (
							// 	event.target.name === 'pattern' &&
							// 	event.target.options?.[event.target.options?.selectedIndex]?.text
							// ) {
							// 	/**
							// 	 * set the couponLength touch so that it show the error
							// 	 * when couponLength is untouched and have error
							// 	 */
							// 	formik.setFieldTouched('couponLength', true);
							// }
							if (event.target.name === 'couponLength') {
								formik.setFieldValue(
									'pattern',
									getPatternOptions(
										patternOptions,
										event.target.value,
										defaultPattern
									)[0].value
								);
							}
						}}
					>
						{formControls(formik, props)}
						<PreviewCodeInput formik={formik} />
						<div className="d-flex justify-content-end align-items-center form-btn-container">
							<GeneratedCouponInfo
								generatedCouponCount={generatedCouponCount}
								leftOverCombination={leftOverCombination}
							/>
							<button
								type="button"
								className="grey-button roboto-b-16 bg-transparent ml-2"
								onClick={formik.resetForm}
							>
								<img src={resetIcon} className="mr-1" alt={commonLabels.reset} />
								{commonLabels.reset}
							</button>
							<button
								type="button"
								className="btnCancel roboto-b-16 ml-2"
								onClick={() => cancel()}
							>
								{commonLabels.cancel}
							</button>
							<button
								type="submit"
								disabled={
									!formik.isValid ||
									formik.isSubmitting ||
									leftOverCombination === 0
								}
								className="btnNxtPrev roboto-b-16 ml-2"
							>
								{initValues.isReplicate
									? commonLabels.replicate
									: commonLabels.submit}
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

GenerateCouponForm.defaultProps = {
	initValues: {
		// promotionId: '',
		// startDate: '',
		// expiryDate: '',
		// couponType: '',
		// couponSubType: '',
	},
	defaultCouponLength: 8,
	patternOptions: [DEFAULT_PATTERN],
	defaultPattern: DEFAULT_PATTERN,
	defaultCharacterSet: ['uppercase']
};

GenerateCouponForm.propTypes = {
	initValues: PropTypes.shape({
		promotionId: PropTypes.string.isRequired,
		startDate: PropTypes.string.isRequired,
		expiryDate: PropTypes.string.isRequired,
		couponType: PropTypes.string.isRequired
	}).isRequired,
	patternOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					separator: PropTypes.string.isRequired,
					pattern: PropTypes.string.isRequired
				})
			])
		})
	),
	defaultPattern: PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				separator: PropTypes.string.isRequired,
				pattern: PropTypes.string.isRequired
			})
		])
	}),
	submitHandler: PropTypes.func
};
export default GenerateCouponForm;
