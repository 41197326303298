/* Reference: https://getbootstrap.com/docs/4.0/components/dropdowns/ */
import React from 'react';
import PropTypes from 'prop-types';

const ButtonDropdown = (props) => {
	const { headerClassName, icon, headerLabel, dropdownMenu } = props;
	return (
		<>
			<div className="dropdownd">
				<button
					className={`btn dropdown-toggle ${headerClassName}`}
					type="button"
					id="dropdownMenu"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					{icon}
					{headerLabel}
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenu">
					{dropdownMenu}
				</div>
			</div>
		</>
	);
};
ButtonDropdown.propTypes = {
	headerClassName: PropTypes.string,
	headerLabel: PropTypes.string,
	dropdownMenu: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.shape({})),
		PropTypes.func,
		// Or the instance of a DOM native element (see the note about SSR)
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	])
};

export default ButtonDropdown;
