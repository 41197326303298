import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import BinRangeSearch from './BinRangeSearch';
import * as urlTypes from '../../libraries/offer-service-urls';
import * as RestSvc from '../../libraries/offer-services';
import toaster from 'toasted-notes';
import moment from 'moment';
import { isObjectEmpty } from '../../utils/helperFunction';
import $ from 'jquery';
import './couponTable.scss';
import couponUploadNew from '../../assets/images/couponUploadNew.png';
import { ConfirmationModal } from '../../MainContent/ConfirmationModal';
import { InformationModal } from '../../MainContent/InformationModal';
import { parseInt } from 'lodash';
import BinTable from './BinTable';
import BinDetailsTable from './BinDetailsTable';
import Search_icon from '../../assets/images/searchGrey.png';
import BinVersionsTable from './BinVersionsTable';
import GroupInformationModal from '../../MainContent/GroupInformationModal';
import DeletedBinsTable from './DeletedBinsTable';
import './couponTableBtnContainer.scss';

// eslint-disable-next-line no-unused-vars
let genericCouponTableData = [];
// eslint-disable-next-line no-unused-vars
let genericUnLimitedCouponTableData = [];
// eslint-disable-next-line no-unused-vars
let targetedCouponTableData = [];
// eslint-disable-next-line no-unused-vars
let personalisedCouponTableData = [];

// eslint-disable-next-line no-unused-vars
let viewCouponTableData = [];

export class BinRangeManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			confirmationModalShow: false,
			confirmMsg: '',
			informationModalShow: false,
			informationMsg: '',
			searchOffersForm: {
				offerId: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'Search Offer'
					},
					value: '',
					validation: {
						required: false
					},
					attrValidation: {},
					validationMessage: '',
					valid: false,
					touched: false
				}
			},
			searchOffersFormIsValid: false,
			showPromotionSearch: false,
			searchedOffers: [],
			filteredOffer: [],
			filterPageCount: 0,
			filteredArray: [],
			CopyOfSearchedOffer: [],
			searchPopup: false,
			searchForm: '',
			selectedOffer: '',
			itemsCountPerPage: 10,
			activePage: 1,
			searchedOffersToDisplay: [],
			informationTitle: '',
			selectAll: false,
			startDate: '',
			endDate: '',
			dropdownOpen: false,
			selectedOffers: [],
			modifyStartError: '',
			modifyEndError: '',
			showSearchedCouponTable: false,
			viewBinDetailsTable: false,
			binPromotionData: [],
			binTableCurrentPage: 1,
			viewCouponDataCurrentPage: 1,
			genericUnLimitedCouponData: [],
			targetedCouponData: [],
			personalisedCouponData: [],
			viewBinDetailsDataInclusion: [],
			clickedPromotion: [],
			selectedGeneric: [],
			selectedTargeted: [],
			selectedPersonalised: [],
			showVersionHistory: false,
			selectedBinExclusion: [],
			errorMsg: '',
			groupInformationModalShow: false,
			viewBinDetailsDataExclusion: [],
			programId: '',
			brandName: '',
			promotionType: '',
			totalRecords: '',
			viewBinDetailsExclusionCurrentPage: 1,
			viewBinDetailsInclusionCurrentPage: 1,
			binVersionsData: [],
			inclusionVersionBins: [],
			exclusionVersionBins: [],
			currVersion: '',
			viewBinDetailsInclusionVersionCurrentPage: 1,
			viewBinDetailsExclusionVersionCurrentPage: 1,
			modifiedBy: '',
			modifiedOn: '',
			deletedBinRanges: [],
			showDeletedBinRanges: false,
			deletedBinsCurrentPage: 1,
			viewBinDetailsExclusionMaxPage: 0,
			viewBinDetailsInclusionMaxPage: 0,
			binTableMaxPage: 0,
			deletedBinsMaxPage: 0,
			viewBinDetailsExclusionVersionMaxPage: 0,
			viewBinDetailsInclusionVersionMaxPage: 0
		};
	}

	toggleSearchPopup = (flag) => {
		this.setState({
			searchPopup: flag
		});
	};

	handleSearch = (searchform) => {
		this.setState(
			{
				searchForm: searchform,
				showSearchedCouponTable: true
			},
			() => {
				this.binSearch('Search', 0);
			}
		);
	};

	confirmationModalClose = () => {
		this.setState({ confirmationModalShow: false });
	};

	informationModalClose = () => {
		this.setState({ informationModalShow: false, groupInformationModalShow: false });
	};

	deleteBinInclusion = () => {
		let binList = [];
		this.state.selectedGeneric.map((item) => {
			binList.push({ binStartRange: item.binStartRange, binEndRange: item.binEndRange });
		});
		const dataToSend = {
			deleteBinRangesRequest: {
				promotionId: this.state.clickedPromotion.promotionId,
				exclusion: false,
				user: this.props.userFullName.operatorID,
				binRange: binList
			}
		};
		this.props.showLoadingButton(true);
		// const svPromUrl = JSON.parse(sessionStorage.getItem("applctnUrls")).savePromotionsUrl;
		const binUrl = sessionStorage.getItem('binServiceUrl');

		RestSvc.postData(urlTypes.bin_setup.deleteBin, dataToSend, binUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.deleteBinRangeResponse;
				let errorMessage = [];
				if (result.status) {
					errorMessage.push(
						<>
							<b>{result.status}</b>
						</>
					);
				}
				if (result.businessExceptions) {
					result.businessExceptions.map((item) => {
						if (!isObjectEmpty(item)) {
							errorMessage.push(
								<>
									<div>
										<b>{item.description}</b>
									</div>
								</>
							);
						}
					});
				}
				this.setState({
					informationModalShow: true,
					informationMsg: errorMessage,
					informationTitle: 'Information'
				});
				this.viewBinDetails(
					this.state.clickedPromotion,
					'other',
					this.state.viewBinDetailsInclusionCurrentPage,
					false
				);
				this.setState({ selectedGeneric: [] });
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				// this.toggleSearchPopup(false);
				console.error(error);
			});
	};
	deleteBinExclusion = () => {
		let binList = [];
		this.state.selectedBinExclusion.map((item) => {
			binList.push({ binStartRange: item.binStartRange, binEndRange: item.binEndRange });
		});
		const dataToSend = {
			deleteBinRangesRequest: {
				promotionId: this.state.clickedPromotion.promotionId,
				exclusion: true,
				user: this.props.userFullName.operatorID,
				binRange: binList
			}
		};
		this.props.showLoadingButton(true);
		// const svPromUrl = JSON.parse(sessionStorage.getItem("applctnUrls")).savePromotionsUrl;
		const binUrl = sessionStorage.getItem('binServiceUrl');

		RestSvc.postData(urlTypes.bin_setup.deleteBin, dataToSend, binUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.deleteBinRangeResponse;
				let errorMessage = [];
				if (result.status) {
					errorMessage.push(
						<>
							<b>{result.status}</b>
						</>
					);
				}
				if (result.businessExceptions) {
					result.businessExceptions.map((item) => {
						if (!isObjectEmpty(item)) {
							errorMessage.push(
								<>
									<div>
										<b>{item.description}</b>
									</div>
								</>
							);
						}
					});
				}
				if (result.businessError) {
					errorMessage.push(
						<>
							<div>
								<b>{result.businessError.description}</b>
							</div>
						</>
					);
				}
				this.setState({
					informationModalShow: true,
					informationMsg: errorMessage,
					informationTitle: 'Information'
				});
				this.viewBinDetails(
					this.state.clickedPromotion,
					'other',
					this.state.viewBinDetailsExclusionCurrentPage,
					true
				);
				this.setState({ selectedBinExclusion: [] });
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				// this.toggleSearchPopup(false);
				console.error(error);
			});
	};

	binSearch = (type, pageNumber, flag) => {
		this.props.showLoadingButton(true);
		let dataToSend;
		if (type == 'Search') {
			if (this.state.searchForm.promotionID.value) {
				dataToSend = {
					SearchBinRangeByPromoIdRequest: {
						promotionId: this.state.searchForm.promotionID.value
							? `${
									JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
										.programId
							  }:${this.state.searchForm.promotionID.value}`
							: undefined,
						pageNumber: 0
					}
				};
			} else {
				dataToSend = {
					searchBinRangeRequest: {
						binStartRange: this.state.searchForm.binStartRange.value
							? this.state.searchForm.binStartRange.value
							: undefined,
						binEndRange: this?.state?.searchForm?.binEndRange?.value
							? this?.state?.searchForm?.binEndRange?.value
							: undefined,
						binNumber: this?.state?.searchForm?.binNumber?.value
							? this?.state?.searchForm?.binNumber?.value
							: undefined,
						modifiedBy: this?.state?.searchForm?.createdBy?.value
							? this?.state?.searchForm?.createdBy?.value
							: undefined,
						pageNumber: 0
					}
				};
			}
		} else {
			if (this.state.searchForm.promotionID.value) {
				dataToSend = {
					SearchBinRangeByPromoIdRequest: {
						promotionId: this.state.searchForm.promotionID.value
							? `${
									JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
										.programId
							  }:${this.state.searchForm.promotionID.value}`
							: undefined,
						pageNumber:
							flag == 'Next'
								? pageNumber
								: flag == 'Previous'
								? pageNumber - 2
								: pageNumber - 1
					}
				};
			} else {
				dataToSend = {
					searchBinRangeRequest: {
						binStartRange: this.state.searchForm.binStartRange.value
							? this.state.searchForm.binStartRange.value
							: undefined,
						binEndRange: this?.state?.searchForm?.binEndRange?.value
							? this?.state?.searchForm?.binEndRange?.value
							: undefined,
						binNumber: this?.state?.searchForm?.binNumber?.value
							? this?.state?.searchForm?.binNumber?.value
							: undefined,
						modifiedBy: this?.state?.searchForm?.createdBy?.value
							? this?.state?.searchForm?.createdBy?.value
							: undefined,
						// "pageNumber": pageNumber ? pageNumber - 1 : undefined
						pageNumber:
							flag == 'Next'
								? pageNumber
								: flag == 'Previous'
								? pageNumber - 2
								: pageNumber - 1
					}
				};
			}
		}
		const binUrl = sessionStorage.getItem('binServiceUrl');
		if (type == 'Search') {
			genericCouponTableData = [];
			genericUnLimitedCouponTableData = [];
			targetedCouponTableData = [];
			personalisedCouponTableData = [];
		}
		//debugger
		let url;
		if (this.state.searchForm.promotionID.value) {
			url = urlTypes.bin_setup.searchWithOfferId;
		} else if (this.state.searchForm.createdBy.value) {
			url = urlTypes.bin_setup.searchByLastModifiedBy;
		} else {
			url = urlTypes.bin_setup.searchWithBinRange;
		}
		RestSvc.postData(url, dataToSend, binUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.searchBinRangeResponse;

				if (result.businessError) {
					if (type != 'Search') {
						if (type == 'BINTABLE') {
							// this.setState((previousState) => ({
							//     binTableCurrentPage: previousState.binTableCurrentPage - 1
							// }))
						}
					}
					toaster.notify(
						({ onClose }) => (
							<div className="Toaster__message-wrapper">
								<div>
									<div
										id="1"
										className="toasterRed Toaster__alert Toaster__alert_error"
									>
										<div className="Toaster__alert_text">
											{result.businessError.description}
										</div>
										<button
											className="Toaster__alert_close"
											type="button"
											aria-label="Close"
											onClick={onClose}
										>
											<span aria-hidden="true">×</span>
										</button>
									</div>
								</div>
							</div>
						),
						{
							duration: 10000
						}
					);
				}
				// if (result.binRangeDataList) {
				//     genericCouponTableData = result.binRangeDataList;
				// }
				if (type == 'Search' && result.binRangeDataList) {
					this.setState({
						binPromotionData: result.binRangeDataList,
						binTableCurrentPage: 1
					});
					if (result.totalRecords && result.maxRecordsPerPage) {
						this.setState({
							binTableMaxPage: Math.ceil(
								parseInt(result.totalRecords) / parseInt(result.maxRecordsPerPage)
							)
						});
					}
					//HardCode
					// this.setState({binTableMaxPage:Math.ceil(parseInt(3)/parseInt(10))})
				} else if (type == 'BINTABLE' && result.binRangeDataList) {
					this.setState({
						binPromotionData: result.binRangeDataList
					});
					if (flag == 'Next')
						this.setState((previousState) => ({
							binTableCurrentPage: previousState.binTableCurrentPage + 1
						}));
					else if (flag == 'Previous')
						this.setState((previousState) => ({
							binTableCurrentPage: previousState.binTableCurrentPage - 1
						}));
				}

				this.toggleSearchPopup(false);
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				this.toggleSearchPopup(false);
				console.error(error);
				// if (type != "Search") {
				//     if (type == "BINTABLE") {
				//         this.setState((previousState) => ({
				//             binTableCurrentPage: previousState.binTableCurrentPage - 1
				//         }))
				//     }
				// }
			});
	};

	viewBinDetails = ({ promotionId }, type, pageNumber, exclusion, flag) => {
		this.props.showLoadingButton(true);
		let dataToSend;
		if (type == 'Search') {
			dataToSend = {
				searchBinRangeDetailsRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					promotionId: promotionId,
					// "exclusion": exclusion,
					// "binStartRange": this.state.searchForm.binStartRange.value ? this.state.searchForm.binStartRange.value : undefined,
					// "binEndRange": this?.state?.searchForm?.binEndRange?.value ? this?.state?.searchForm?.binEndRange?.value : undefined,
					// "binNumber": this?.state?.searchForm?.binNumber?.value ? this?.state?.searchForm?.binNumber?.value : undefined,
					pageNumber: 0
				}
			};
		} else {
			dataToSend = {
				searchBinRangeDetailsRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					promotionId: promotionId,
					exclusion: exclusion,
					// "binStartRange": this.state.searchForm.binStartRange.value ? this.state.searchForm.binStartRange.value : undefined,
					// "binEndRange": this?.state?.searchForm?.binEndRange?.value ? this?.state?.searchForm?.binEndRange?.value : undefined,
					// "binNumber": this?.state?.searchForm?.binNumber?.value ? this?.state?.searchForm?.binNumber?.value : undefined,
					pageNumber:
						flag == 'Next'
							? pageNumber
							: flag == 'Previous'
							? pageNumber - 2
							: pageNumber - 1
				}
			};
		}
		// const svPromUrl = JSON.parse(sessionStorage.getItem("applctnUrls")).savePromotionsUrl;
		const binUrl = sessionStorage.getItem('binServiceUrl');

		// if (type == "Search") {
		//     this.setState({
		//         viewBinDetailsDataInclusion: [],
		//          viewBinDetailsDataExclusion:[]
		//     })
		// }
		viewCouponTableData = [];
		RestSvc.postData(urlTypes.bin_setup.searchBinDetails, dataToSend, binUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.searchBinRangeResponse;
				if (result.businessExceptions.length > 0) {
					// eslint-disable-next-line no-empty
					if (flag == undefined && type != 'Search') {
					} else {
						result.businessExceptions.map((item) => {
							toaster.notify(
								({ onClose }) => (
									<div className="Toaster__message-wrapper">
										<div>
											<div
												id="1"
												className="toasterRed Toaster__alert Toaster__alert_error"
											>
												<div className="Toaster__alert_text">
													{item.description}
												</div>
												<button
													className="Toaster__alert_close"
													type="button"
													aria-label="Close"
													onClick={onClose}
												>
													<span aria-hidden="true">×</span>
												</button>
											</div>
										</div>
									</div>
								),
								{
									duration: 10000
								}
							);
						});
					}

					if (flag == undefined) {
						if (exclusion) {
							this.setState({ viewBinDetailsDataExclusion: [] });
						} else {
							this.setState({ viewBinDetailsDataInclusion: [] });
						}
					}
				} else {
					if (type == 'Search') {
						this.setState({
							viewBinDetailsExclusionCurrentPage: 1,
							viewBinDetailsInclusionCurrentPage: 1
						});
						if (result.exclusionRangeDetails) {
							this.setState(
								{
									viewBinDetailsDataExclusion: []
								},
								() => {
									let temp = [...result.exclusionRangeDetails];
									temp.forEach((row) => {
										row.id = row.binStartRange + 'a' + row.binEndRange;
									});
									this.setState({
										viewBinDetailsDataExclusion: temp
									});
								}
							);
						}
						if (result.inclusionRangeDetails) {
							this.setState(
								{
									viewBinDetailsDataInclusion: []
								},
								() => {
									let temp = [...result.inclusionRangeDetails];
									temp.forEach((row) => {
										row.id = row.binStartRange + 'a' + row.binEndRange;
									});
									this.setState({
										viewBinDetailsDataInclusion: temp
									});
								}
							);
						}
					} else {
						if (exclusion) {
							if (result.exclusionRangeDetails) {
								this.setState(
									{
										viewBinDetailsDataExclusion: []
									},
									() => {
										let temp = [...result.exclusionRangeDetails];
										temp.forEach((row) => {
											row.id = row.binStartRange + 'a' + row.binEndRange;
										});
										this.setState({
											viewBinDetailsDataExclusion: temp
										});
									}
								);
							}
							if (flag == 'Next') {
								this.setState((previousState) => ({
									viewBinDetailsExclusionCurrentPage:
										previousState.viewBinDetailsExclusionCurrentPage + 1
								}));
							} else if (flag == 'Previous') {
								this.setState((previousState) => ({
									viewBinDetailsExclusionCurrentPage:
										previousState.viewBinDetailsExclusionCurrentPage - 1
								}));
							}
						} else {
							if (result.inclusionRangeDetails) {
								this.setState(
									{
										viewBinDetailsDataInclusion: []
									},
									() => {
										let temp = [...result.inclusionRangeDetails];
										temp.forEach((row) => {
											row.id = row.binStartRange + 'a' + row.binEndRange;
										});
										this.setState({
											viewBinDetailsDataInclusion: temp
										});
									}
								);
							}
							if (flag == 'Next') {
								this.setState((previousState) => ({
									viewBinDetailsInclusionCurrentPage:
										previousState.viewBinDetailsInclusionCurrentPage + 1
								}));
							} else if (flag == 'Previous') {
								this.setState((previousState) => ({
									viewBinDetailsInclusionCurrentPage:
										previousState.viewBinDetailsInclusionCurrentPage - 1
								}));
							}
						}
					}

					if (result.promotionType && result.startDate)
						this.setState({
							promotionType: result.promotionType,
							startDate: result.startDate
						});
					if (result.modifiedBy && result.modifiedOn)
						this.setState({
							modifiedBy: result.modifiedBy,
							modifiedOn: result.modifiedOn
						});

					if (type == 'Search' || type == 'other') {
						if (result.totalBinRecordsForExclusion) {
							this.setState({
								viewBinDetailsExclusionMaxPage: Math.ceil(
									parseInt(result.totalBinRecordsForExclusion) /
										parseInt(result.maxRecordsPerPage)
								)
							});
						}
						if (result.totalBinRecordsForInclusion) {
							this.setState({
								viewBinDetailsInclusionMaxPage: Math.ceil(
									parseInt(result.totalBinRecordsForInclusion) /
										parseInt(result.maxRecordsPerPage)
								)
							});
						}
						//Hardcode
						// this.setState({viewBinDetailsExclusionMaxPage:Math.ceil(9/10)})
						// this.setState({viewBinDetailsInclusionMaxPage:Math.ceil(8/10)})
					}
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	onFollowChanged = (row) => {
		this.setState(
			{
				viewBinDetailsTable: true,
				showSearchedCouponTable: false,
				viewCouponDataCurrentPage: 1,
				clickedPromotion: row
			},
			() => {
				this.viewBinDetails(row, 'Search', 0, false);
				// this.viewBinDetails(row, "Search", 0,true);
			}
		);
	};

	gotoBinSearch = () => {
		if (this.state.showVersionBins) {
			this.setState({ showVersionBins: false, viewBinDetailsTable: true });
		} else {
			this.setState({
				viewBinDetailsDataInclusion: [],
				viewBinDetailsTable: false,
				showSearchedCouponTable: true,
				showVersionBins: false,
				viewBinDetailsDataExclusion: [],
				showVersionHistory: false,
				inclusionVersionBins: [],
				exclusionVersionBins: [],
				showDeletedBinRanges: false,
				deletedBinRanges: []
			});
		}
	};

	onInputChange = (event, definedRules, inputIdentifier) => {
		let value = event.target.value;
		let rules = definedRules;
		let isValid = { value: true, message: '' };
		if (rules) {
			if (rules.required) {
				isValid.value = value.trim() !== '';
				isValid.message = isValid.value ? '' : 'Field required';
			}
			if (rules.minLength) {
				isValid.value = value.length >= rules.minLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Minimum length is ' + rules.minLength;
			}
			if (rules.maxLength) {
				isValid.value = value.length <= rules.maxLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Maximum length is ' + rules.maxLength;
			}
			if (rules.isEmail && value) {
				let regEx = new RegExp(
					/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid email';
			}
			if (rules.isPhoneNumber && value) {
				let regEx = new RegExp(
					/^\+?(\d+|(\(\d+\))+)+((([ ]?(\d+|(\(\d+\))+)+)*)|((\-\d+)*)|((\.\d+)*))*$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid phone number';
			}
			if (rules.isAlphabets && value) {
				let regEx = new RegExp('^[a-zA-Z]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets';
			}
			if (rules.isAlphaNumeric && value) {
				let regEx = new RegExp('^[a-zA-Z0-9]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets and numbers';
			}
		}
		this.inputChangedHandler(value, isValid, inputIdentifier, false);
	};

	inputChangedHandler = (value, isValidObj, inputIdentifier, dateTimeRangeChanged) => {
		const updatedSearchOffersForm = { ...this.state.searchOffersForm };
		const updatedFormElement = { ...updatedSearchOffersForm[inputIdentifier] };
		updatedFormElement.value = value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;
		updatedSearchOffersForm[inputIdentifier] = updatedFormElement;
		if (dateTimeRangeChanged) {
			const updatedFormStartDateTimeElement = {
				...updatedSearchOffersForm['userStartDateTime']
			};
			const updatedFormEndDateTimeElement = { ...updatedSearchOffersForm['userEndDateTime'] };
			if (updatedFormStartDateTimeElement.value && updatedFormEndDateTimeElement.value) {
				if (
					moment(updatedFormStartDateTimeElement.value) >
					moment(updatedFormEndDateTimeElement.value)
				) {
					updatedFormEndDateTimeElement.valid = false;
					updatedFormEndDateTimeElement.validationMessage = 'Invalid date time range';
				} else {
					updatedFormEndDateTimeElement.valid = isValidObj.value;
					updatedFormEndDateTimeElement.validationMessage = isValidObj.message;
				}
			}

			updatedSearchOffersForm['userEndDateTime'] = updatedFormEndDateTimeElement;
		}
		let formIsValid = true;
		for (let inputIdentifier in updatedSearchOffersForm) {
			formIsValid = updatedSearchOffersForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({
			searchOffersForm: updatedSearchOffersForm,
			searchOffersFormIsValid: formIsValid
		});
	};

	onSearchOfferSubmit = (event) => {
		event.preventDefault();
		var dataToSend = {
			searchPromotionRequest: {
				programId: this.props.loggedInBrand.programId,
				promotionId: this.state.searchOffersForm.offerId.value
			}
		};
		this.setState({ searchedOffers: [] });
		this.props.showLoadingButton(true);
		// const svPromUrl = JSON.parse(sessionStorage.getItem("applctnUrls")).savePromotionsUrl
		const binUrl = sessionStorage.getItem('binServiceUrl');

		RestSvc.postData(urlTypes.offers_maintenance.searchPromotion, dataToSend, binUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.searchPromotionResponse;
				if (result.businessError) {
					toaster.notify(
						({ onClose }) => (
							<div className="Toaster__message-wrapper">
								<div>
									<div
										id="1"
										className="toasterRed Toaster__alert Toaster__alert_error"
									>
										<div className="Toaster__alert_text">
											{result.businessError.errorMessage}
										</div>
										<button
											className="Toaster__alert_close"
											type="button"
											aria-label="Close"
											onClick={onClose}
										>
											<span aria-hidden="true">×</span>
										</button>
									</div>
								</div>
							</div>
						),
						{
							duration: 10000
						}
					);
				} else {
					this.setState({ selectedOffers: [] });
					this.setState(
						(previousState) => {
							previousState.searchOffersForm.offerId.value = '';
							return previousState;
						},
						() => {}
					);
					this.setState(
						{
							searchedOffers: result.promotionPolicyDetails,
							showPromotionSearch: true
						} /* ,
                        () => { this.handlePageChange(1);; this.resetCheckbox() } */
					);
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	linkAction = (cell, row) => {
		return (
			<>
				<div style={{ display: 'flex', width: '20px', height: '20px' }}>
					<span
						className={row.binRangeCount == 0 ? 'viewCoupon' : 'viewCoupon'}
						onClick={() => {
							this.onFollowChanged(row);
						}}
						style={{
							color: 'blue',
							textDecoration: 'underline'
						}}
						/* eslint-disable react/no-unknown-property */ 
						variant="link"
					></span>
				</div>
			</>
		);
	};

	getItemsPerPageForSearchedOffers = () => {
		this.props.showLoadingButton(true);

		// const svPromUrl = JSON.parse(sessionStorage.getItem("applctnUrls")).savePromotionsUrl;
		const binUrl = sessionStorage.getItem('binServiceUrl');

		RestSvc.getData(urlTypes.offers_maintenance.getConfigurationUrl, binUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.getConfigurationResponse;
				if (result.businessError) {
					toaster.notify(
						({ onClose }) => (
							<div className="Toaster__message-wrapper">
								<div>
									<div
										id="1"
										className="toasterRed Toaster__alert Toaster__alert_error"
									>
										<div className="Toaster__alert_text">
											{result.businessError.errorMessage}
										</div>
										<button
											className="Toaster__alert_close"
											type="button"
											aria-label="Close"
											onClick={onClose}
										>
											<span aria-hidden="true">×</span>
										</button>
									</div>
								</div>
							</div>
						),
						{
							duration: 10000
						}
					);

					this.setState({ itemsCountPerPage: 10 });
				} else {
					let p = result.configurationList.findIndex(
						(i) => i.configurationType == 'SEARCH_RESULTS_PER_PAGE_COUPON'
					);
					if (p != -1) {
						this.setState({ itemsCountPerPage: result.configurationList[p].value });
					}

					let tableViewValueList = [];
					result.configurationList.map((obj) => {
						if (obj.configurationType.includes('TABLE_VIEW_VALUE_COUPON_')) {
							tableViewValueList.push(parseInt(obj.value));
						}
					});

					tableViewValueList.sort(function (a, b) {
						return a - b;
					});

					sessionStorage.setItem(
						'configuredDataToViewTableRecords',
						JSON.stringify({
							resultPerPage: this.state.itemsCountPerPage,
							recordGroupForTable: tableViewValueList.length
								? tableViewValueList
								: [50]
						})
					);
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	onSelectedGenericChange = (newList) => {
		this.setState({
			selectedGeneric: newList
		});
	};
	onSelectedBinExclusionChange = (newList) => {
		this.setState({
			selectedBinExclusion: newList
		});
	};
	onSelectedPersonalisedChange = (newList) => {
		this.setState({
			selectedPersonalised: newList
		});
	};

	onPageChange = (type, flag) => {
		//debugger
		if (type == 'BINTABLE' && flag == 'Previous') {
			if (this.state.binTableCurrentPage > 1) {
				// this.setState((previousState) => ({
				//     binTableCurrentPage: previousState.binTableCurrentPage - 1
				// }), () => {
				if (this.state.searchForm)
					this.binSearch(type, this.state.binTableCurrentPage, flag);
				else this.viewAll(type, this.state.binTableCurrentPage, flag);
			}
		}
		if (type == 'BINTABLE' && flag == 'Next') {
			if (this.state.binTableCurrentPage >= 1) {
				// this.setState((previousState) => ({
				//     binTableCurrentPage: previousState.binTableCurrentPage + 1
				// }), () => {
				if (this.state.searchForm)
					this.binSearch(type, this.state.binTableCurrentPage, flag);
				else this.viewAll(type, this.state.binTableCurrentPage, flag);
			}
		}
		//view
		if (type == 'Exclusion' && flag == 'Previous') {
			if (this.state.viewBinDetailsExclusionCurrentPage > 1) {
				// this.setState((previousState) => ({
				//     viewBinDetailsExclusionCurrentPage: previousState.viewBinDetailsExclusionCurrentPage - 1
				// }), () => {
				this.viewBinDetails(
					this.state.clickedPromotion,
					type,
					this.state.viewBinDetailsExclusionCurrentPage,
					true,
					flag
				);
				// })
			}
		}
		if (type == 'Exclusion' && flag == 'Next') {
			if (this.state.viewBinDetailsExclusionCurrentPage >= 1) {
				// this.setState((previousState) => ({
				//     viewBinDetailsExclusionCurrentPage: previousState.viewBinDetailsExclusionCurrentPage + 1
				// }), () => {
				this.viewBinDetails(
					this.state.clickedPromotion,
					type,
					this.state.viewBinDetailsExclusionCurrentPage,
					true,
					flag
				);
				// })
			}
		}
		if (type == 'Inclusion' && flag == 'Previous') {
			if (this.state.viewBinDetailsInclusionCurrentPage > 1) {
				// this.setState((previousState) => ({
				//     viewBinDetailsInclusionCurrentPage: previousState.viewBinDetailsInclusionCurrentPage - 1
				// }), () => {
				this.viewBinDetails(
					this.state.clickedPromotion,
					type,
					this.state.viewBinDetailsInclusionCurrentPage,
					false,
					flag
				);
				// })
			}
		}
		if (type == 'Inclusion' && flag == 'Next') {
			if (this.state.viewBinDetailsInclusionCurrentPage >= 1) {
				// this.setState((previousState) => ({
				//     viewBinDetailsInclusionCurrentPage: previousState.viewBinDetailsInclusionCurrentPage + 1
				// }), () => {
				this.viewBinDetails(
					this.state.clickedPromotion,
					type,
					this.state.viewBinDetailsInclusionCurrentPage,
					false,
					flag
				);
				// })
			}
		}

		if (type == 'VerInclusion' && flag == 'Previous') {
			if (this.state.viewBinDetailsInclusionVersionCurrentPage > 1) {
				// this.setState((previousState) => ({
				//     viewBinDetailsInclusionVersionCurrentPage: previousState.viewBinDetailsInclusionVersionCurrentPage - 1
				// }), () => {
				this.viewBins(
					this.state.currVersion,
					type,
					this.state.viewBinDetailsInclusionVersionCurrentPage,
					flag
				);
				// })
			}
		}
		if (type == 'VerInclusion' && flag == 'Next') {
			if (this.state.viewBinDetailsInclusionVersionCurrentPage >= 1) {
				// this.setState((previousState) => ({
				//     viewBinDetailsInclusionVersionCurrentPage: previousState.viewBinDetailsInclusionVersionCurrentPage + 1
				// }), () => {
				this.viewBins(
					this.state.currVersion,
					type,
					this.state.viewBinDetailsInclusionVersionCurrentPage,
					flag
				);
				// })
			}
		}
		if (type == 'VerExclusion' && flag == 'Previous') {
			if (this.state.viewBinDetailsExclusionVersionCurrentPage > 1) {
				// this.setState((previousState) => ({
				//     viewBinDetailsExclusionVersionCurrentPage: previousState.viewBinDetailsExclusionVersionCurrentPage - 1
				// }), () => {
				this.viewBins(
					this.state.currVersion,
					type,
					this.state.viewBinDetailsExclusionVersionCurrentPage,
					flag
				);
				// })
			}
		}
		if (type == 'VerExclusion' && flag == 'Next') {
			if (this.state.viewBinDetailsExclusionVersionCurrentPage >= 1) {
				// this.setState((previousState) => ({
				//     viewBinDetailsExclusionVersionCurrentPage: previousState.viewBinDetailsExclusionVersionCurrentPage + 1
				// }), () => {
				this.viewBins(
					this.state.currVersion,
					type,
					this.state.viewBinDetailsExclusionVersionCurrentPage,
					flag
				);
				// })
			}
		}
		if (type == 'DeletedBins') {
			if (this.state.deletedBinsCurrentPage >= 1) {
				this.getDeletedBins(type, this.state.deletedBinsCurrentPage, flag);
			}
		}
	};
	viewBins = (versionId, type, pageNumber, flag) => {
		this.setState({ currVersion: versionId });
		//Hardcode
		// var result = {
		//     "binRangeDataList": [
		//         {
		//             "inclusionRangeDetails": [
		//                 {
		//                     "binStartRange": "123456",
		//                     "binEndRange": "300000",
		//                     "deleteStatus": "false"
		//                 },
		//                 {
		//                     "binStartRange": "56749",
		//                     "binEndRange": "900000",
		//                     "deleteStatus": "false"
		//                 },
		//                 {
		//                     "binStartRange": "56789",
		//                     "binEndRange": "",
		//                     "deleteStatus": "false"
		//                 },
		//                 {
		//                     "binStartRange": "123456",
		//                     "binEndRange": "300000",
		//                     "deleteStatus" : "true",
		//                     "deletedBy":"user34",
		//                     "deletedOn":"20/22/2020 19:16:49"
		//                 },
		//             ]
		//         },
		//         {
		//             "exclusionRangeDetails": [
		//                 {
		//                     "binStartRange": "323232",
		//                     "binEndRange": "300000",
		//                     "deleteStatus": "false"
		//                 },
		//                 {
		//                     "binStartRange": "60000",
		//                     "binEndRange": "",
		//                     "deleteStatus": "false"
		//                 }
		//             ]
		//         }
		//     ],
		//     "pageNumber": 0
		// }
		// let errorMessage = [];
		// if (result.businessExceptions) {
		//     result.businessExceptions.map((item) => {
		//         if (!isObjectEmpty(item)) {
		//             errorMessage.push(
		//                 <>
		//                     <div><b>{item.description}</b></div>
		//                 </>
		//             )
		//         }
		//     })
		//     this.setState({ informationModalShow: true, informationMsg: errorMessage, informationTitle: 'Information' });
		//     this.setState({ showVersionBins: false });
		// }
		// else{
		//     this.setState({inclusionVersionBins:result.binRangeDataList[0].inclusionRangeDetails,
		//     exclusionVersionBins:result.binRangeDataList[1].exclusionRangeDetails});
		//     this.setState({ viewBinDetailsTable: false, showVersionBins: true })
		// }
		//End

		let dataToSend;
		if (type == 'Search') {
			dataToSend = {
				promotionBinRangeHistoryRequest: {
					promotionId: this.state.clickedPromotion.promotionId,
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					versionId: versionId,
					pageNumber: 0
				}
			};
		} else {
			dataToSend = {
				promotionBinRangeHistoryRequest: {
					promotionId: this.state.clickedPromotion.promotionId,
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					versionId: versionId,
					exclusion: type == 'VerInclusion' ? false : true,
					pageNumber:
						flag == 'Next'
							? pageNumber
							: flag == 'Previous'
							? pageNumber - 2
							: pageNumber - 1
				}
			};
		}
		this.props.showLoadingButton(true);
		const binUrl = sessionStorage.getItem('binServiceUrl');

		RestSvc.postData(urlTypes.bin_setup.promotionBinRangeHistory, dataToSend, binUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.promotionBinRangeHistoryResponse;
				let errorMessage = [];
				if (result.businessExceptions) {
					result.businessExceptions.map((item) => {
						if (!isObjectEmpty(item)) {
							errorMessage.push(
								<>
									<div>
										<b>{item.description}</b>
									</div>
								</>
							);
						}
					});
					this.setState({
						informationModalShow: true,
						informationMsg: errorMessage,
						informationTitle: 'Information'
					});
					this.setState({ showVersionBins: false });
				} else if (result.businessError) {
					toaster.notify(
						({ onClose }) => (
							<div className="Toaster__message-wrapper">
								<div>
									<div
										id="1"
										className="toasterRed Toaster__alert Toaster__alert_error"
									>
										<div className="Toaster__alert_text">
											{result.businessError.description}
										</div>
										<button
											className="Toaster__alert_close"
											type="button"
											aria-label="Close"
											onClick={onClose}
										>
											<span aria-hidden="true">×</span>
										</button>
									</div>
								</div>
							</div>
						),
						{
							duration: 10000
						}
					);
				} else {
					if (type == 'Search') {
						if (result.binRangeDataList.inclusionRangeDetails) {
							this.setState({
								inclusionVersionBins: result.binRangeDataList.inclusionRangeDetails
							});
						}
						if (result.binRangeDataList.exclusionRangeDetails) {
							this.setState({
								exclusionVersionBins: result.binRangeDataList.exclusionRangeDetails
							});
						}
					} else {
						if (result.binRangeDataList.inclusionRangeDetails) {
							this.setState({
								inclusionVersionBins: result.binRangeDataList.inclusionRangeDetails
							});
							if (flag == 'Next') {
								this.setState((previousState) => ({
									viewBinDetailsInclusionVersionCurrentPage:
										previousState.viewBinDetailsInclusionVersionCurrentPage + 1
								}));
							} else if (flag == 'Previous') {
								this.setState((previousState) => ({
									viewBinDetailsInclusionVersionCurrentPage:
										previousState.viewBinDetailsInclusionVersionCurrentPage - 1
								}));
							}
						} else if (result.binRangeDataList.exclusionRangeDetails) {
							this.setState({
								exclusionVersionBins: result.binRangeDataList.exclusionRangeDetails
							});
							if (flag == 'Next') {
								this.setState((previousState) => ({
									viewBinDetailsExclusionVersionCurrentPage:
										previousState.viewBinDetailsExclusionVersionCurrentPage + 1
								}));
							} else if (flag == 'Previous') {
								this.setState((previousState) => ({
									viewBinDetailsExclusionVersionCurrentPage:
										previousState.viewBinDetailsExclusionVersionCurrentPage - 1
								}));
							}
						}
					}
					if (type == 'Search') {
						if (result.totalBinRecordsForExclusion) {
							this.setState({
								viewBinDetailsExclusionVersionMaxPage: Math.ceil(
									parseInt(result.totalBinRecordsForExclusion) /
										parseInt(result.maxRecordsPerPage)
								)
							});
						}
						if (result.totalBinRecordsForInclusion) {
							this.setState({
								viewBinDetailsInclusionVersionMaxPage: Math.ceil(
									parseInt(result.totalBinRecordsForInclusion) /
										parseInt(result.maxRecordsPerPage)
								)
							});
						}
					}
					this.setState({ viewBinDetailsTable: false, showVersionBins: true });
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				// this.toggleSearchPopup(false);
				console.error(error);
			});
	};
	componentDidMount() {
		this.getItemsPerPageForSearchedOffers();
	}
	getBinVersions = (promotionID) => {
		//Hardcode
		// var result=[
		//     {
		//         "versionId": "1",
		//         "modifiedBy": "user123",
		//         "modifiedOn": "12/22/2020 19:16:49"
		//     },
		//     {
		//         "versionId": "2",
		//         "modifiedBy": "testuser",
		//         "modifiedOn": "14/22/2020 19:16:49"
		//     },
		//     {
		//         "versionId": "3",
		//         "modifiedBy": "admin",
		//         "modifiedOn": "20/22/2020 19:16:49"
		//     },
		// ]

		//     let errorMessage = [];
		//     if (result.businessExceptions) {
		//         result.businessExceptions.map((item) => {
		//             if (!isObjectEmpty(item)) {
		//                 errorMessage.push(
		//                     <>
		//                         <div><b>{item.description}</b></div>
		//                     </>
		//                 )
		//             }
		//         })
		//         this.setState({ informationModalShow: true, informationMsg: errorMessage, informationTitle: 'Information' });
		//         this.setState({ showVersionHistory: false });
		//     }
		//     else{
		//         this.setState({binVersionsData:result})
		//         this.setState({ showVersionHistory: true });
		//     }
		//End

		const dataToSend = {
			binRangeHistoryRequest: {
				promotionId: promotionID,
				programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
					.programId,
				// "pageNumber":0 //For service side pagination
				pageNumber: null
			}
		};
		this.props.showLoadingButton(true);
		// const svPromUrl = JSON.parse(sessionStorage.getItem("applctnUrls")).savePromotionsUrl;
		const binUrl = sessionStorage.getItem('binServiceUrl');

		RestSvc.postData(urlTypes.bin_setup.binRangeHistory, dataToSend, binUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.binRangeHistoryResponse;
				let errorMessage = [];
				if (result.businessExceptions) {
					result.businessExceptions.map((item) => {
						if (!isObjectEmpty(item)) {
							errorMessage.push(
								<>
									<div>
										<b>{item.description}</b>
									</div>
								</>
							);
						}
					});
					this.setState({
						informationModalShow: true,
						informationMsg: errorMessage,
						informationTitle: 'Information'
					});
					this.setState({ showVersionHistory: false });
				} else if (result.businessError) {
					toaster.notify(
						({ onClose }) => (
							<div className="Toaster__message-wrapper">
								<div>
									<div
										id="1"
										className="toasterRed Toaster__alert Toaster__alert_error"
									>
										<div className="Toaster__alert_text">
											{result.businessError.description}
										</div>
										<button
											className="Toaster__alert_close"
											type="button"
											aria-label="Close"
											onClick={onClose}
										>
											<span aria-hidden="true">×</span>
										</button>
									</div>
								</div>
							</div>
						),
						{
							duration: 10000
						}
					);
				} else {
					if (result.binRangeHistoryDettail) {
						this.setState({ binVersionsData: result.binRangeHistoryDettail });
						this.setState({ showVersionHistory: true });
						setTimeout(function () {
							document
								.getElementById('scrollToBinHstryTable')
								.scrollIntoView({ behavior: 'smooth' });
						}, 500);
					}
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				// this.toggleSearchPopup(false);
				console.error(error);
			});
	};
	handleViewAll = () => {
		this.setState({
			showSearchedCouponTable: true,
			searchForm: ''
		});
		this.viewAll('Search', 0);
	};
	viewAll = (type, pageNumber, flag) => {
		this.props.showLoadingButton(true);
		let dataToSend;
		if (type == 'Search') {
			dataToSend = {
				searchBinRangeRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					pageNumber: 0
				}
			};
		} else {
			dataToSend = {
				searchBinRangeRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					pageNumber:
						flag == 'Next'
							? pageNumber
							: flag == 'Previous'
							? pageNumber - 2
							: pageNumber - 1
				}
			};
		}
		const binUrl = sessionStorage.getItem('binServiceUrl');
		if (type == 'Search') {
			genericCouponTableData = [];
			genericUnLimitedCouponTableData = [];
			targetedCouponTableData = [];
			personalisedCouponTableData = [];
		}
		//debugger
		let url;
		url = urlTypes.bin_setup.searchAll;
		RestSvc.postData(url, dataToSend, binUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.searchBinRangeResponse;

				if (result.businessError) {
					if (type != 'Search') {
						if (type == 'BINTABLE') {
							// this.setState((previousState) => ({
							//     binTableCurrentPage: previousState.binTableCurrentPage - 1
							// }))
						}
					}
					toaster.notify(
						({ onClose }) => (
							<div className="Toaster__message-wrapper">
								<div>
									<div
										id="1"
										className="toasterRed Toaster__alert Toaster__alert_error"
									>
										<div className="Toaster__alert_text">
											{result.businessError.description}
										</div>
										<button
											className="Toaster__alert_close"
											type="button"
											aria-label="Close"
											onClick={onClose}
										>
											<span aria-hidden="true">×</span>
										</button>
									</div>
								</div>
							</div>
						),
						{
							duration: 10000
						}
					);
				}
				// if (result.binRangeDataList) {
				//     genericCouponTableData = result.binRangeDataList;
				// }
				if (type == 'Search' && result.binRangeDataList) {
					this.setState({
						binPromotionData: result.binRangeDataList,
						binTableCurrentPage: 1
					});
					if (result.totalRecords && result.maxRecordsPerPage) {
						this.setState({
							binTableMaxPage: Math.ceil(
								parseInt(result.totalRecords) / parseInt(result.maxRecordsPerPage)
							)
						});
					}
				} else if (type == 'BINTABLE' && result.binRangeDataList) {
					this.setState({
						binPromotionData: result.binRangeDataList
					});
					if (flag == 'Next')
						this.setState((previousState) => ({
							binTableCurrentPage: previousState.binTableCurrentPage + 1
						}));
					else if (flag == 'Previous')
						this.setState((previousState) => ({
							binTableCurrentPage: previousState.binTableCurrentPage - 1
						}));
				}

				this.toggleSearchPopup(false);
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				this.toggleSearchPopup(false);
				console.error(error);
			});
	};
	getDeletedBins = (type, pageNumber, flag) => {
		// Hardcode
		// this.setState({deletedBinRanges:[
		//             {
		//                 "deletedOn": "2021-05-13 18:13:43",
		//                 "binStartRange": "34",
		//                 "binEndRange": "",
		//                 "exclusionFlag": false,
		//                 "deletedBy": "guptaUPLOAD333"
		//             },
		//             {
		//                 "deletedOn": "2021-05-13 18:13:43",
		//                 "binStartRange": "355",
		//                 "binEndRange": "788",
		//                 "exclusionFlag": false,
		//                 "deletedBy": "guptaUPLOAD333"
		//             }
		//         ],
		//    showDeletedBinRanges:true})

		this.props.showLoadingButton(true);
		let dataToSend;
		if (type == 'Search') {
			dataToSend = {
				searchDeletedBinRangesRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					promotionId: this.state.clickedPromotion.promotionId,
					pageNumber: 0
				}
			};
		} else {
			dataToSend = {
				searchDeletedBinRangesRequest: {
					programId: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
						.programId,
					promotionId: this.state.clickedPromotion.promotionId,
					pageNumber:
						flag == 'Next'
							? pageNumber
							: flag == 'Previous'
							? pageNumber - 2
							: pageNumber - 1
				}
			};
		}

		const binUrl = sessionStorage.getItem('binServiceUrl');
		let url;
		url = urlTypes.bin_setup.searchDeletedBinRanges;
		RestSvc.postData(url, dataToSend, binUrl)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.searchDeletedBinRangesResponse;
				if (result.businessError) {
					toaster.notify(
						({ onClose }) => (
							<div className="Toaster__message-wrapper">
								<div>
									<div
										id="1"
										className="toasterRed Toaster__alert Toaster__alert_error"
									>
										<div className="Toaster__alert_text">
											{result.businessError.description}
										</div>
										<button
											className="Toaster__alert_close"
											type="button"
											aria-label="Close"
											onClick={onClose}
										>
											<span aria-hidden="true">×</span>
										</button>
									</div>
								</div>
							</div>
						),
						{
							duration: 10000
						}
					);
				}
				if (result.businessExceptions.length > 0) {
					result.businessExceptions.map((item) => {
						toaster.notify(
							({ onClose }) => (
								<div className="Toaster__message-wrapper">
									<div>
										<div
											id="1"
											className="toasterRed Toaster__alert Toaster__alert_error"
										>
											<div className="Toaster__alert_text">
												{item.description}
											</div>
											<button
												className="Toaster__alert_close"
												type="button"
												aria-label="Close"
												onClick={onClose}
											>
												<span aria-hidden="true">×</span>
											</button>
										</div>
									</div>
								</div>
							),
							{
								duration: 10000
							}
						);
					});
				}
				if (result.deletedBinRanges) {
					this.setState({
						deletedBinRanges: result.deletedBinRanges,
						showDeletedBinRanges: true
					});
					if (flag == 'Next')
						this.setState((previousState) => ({
							deletedBinsCurrentPage: previousState.deletedBinsCurrentPage + 1
						}));
					else if (flag == 'Previous')
						this.setState((previousState) => ({
							deletedBinsCurrentPage: previousState.deletedBinsCurrentPage - 1
						}));
					setTimeout(function () {
						document
							.getElementById('scrollToDeleteTable')
							.scrollIntoView({ behavior: 'smooth' });
					}, 500);
				}
				if (result.totalRecords && result.maxRecordsPerPage) {
					this.setState({
						deletedBinsMaxPage: Math.ceil(
							parseInt(result.totalRecords) / parseInt(result.maxRecordsPerPage)
						)
					});
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	render() {
		return (
			<>
				<h1 className="roboto-b-24 m-0">Bin Setup</h1>
				<hr></hr>
				<div
					style={
						this.state.viewBinDetailsTable || this.state.showVersionBins
							? { height: '64.3vh', overflowY: 'auto', overflowX: 'hidden' }
							: { height: '81.6vh', overflowY: 'auto', overflowX: 'hidden' }
					}
				>
					<div className="row" style={{ width: '99%' }}>
						<form className="col" method="POST" onSubmit={this.onSearchOfferSubmit}>
							<div className="roboto-b-16">
								<div className="coupon-table-btn-container">
									<button
										type="button"
										className="btnNxtPrev roboto-b-16"
										onClick={() => {
											document.getElementById('idLoadBinsFromFile').click();
										}}
									>
										Upload BINs
										<span className="ml-2">
											<img alt="img" src={couponUploadNew} />
										</span>
										<input
											type="file"
											id="idLoadBinsFromFile"
											style={{ display: 'none' }}
											onChange={(event) => {
												this.setState((previousState) => {
													previousState.searchOffersForm.offerId.value =
														'';
													previousState.searchedOffers = [];
													previousState.showSearchedCouponTable = false;
													previousState.binPromotionData = [];
													previousState.genericUnLimitedCouponData = [];
													previousState.personalisedCouponData = [];
													previousState.targetedCouponData = [];
													previousState.viewBinDetailsDataInclusion = [];
													previousState.viewBinDetailsDataExclusion = [];
													previousState.binTableCurrentPage = 1;
													previousState.viewBinDetailsDataInclusion = [];
													previousState.viewBinDetailsTable = false;
													previousState.showVersionBins = false;
													previousState.viewBinDetailsDataExclusion = [];
													previousState.showVersionHistory = false;
													previousState.inclusionVersionBins = [];
													previousState.exclusionVersionBins = [];
													previousState.showDeletedBinRanges = false;
													previousState.deletedBinRanges = [];
												});
												const fileList = event.target.files;

												// const url = '/rest/offers/configurator/uploadCoupons';
												const formData = new FormData();
												formData.append('file', fileList[0]);
												formData.append(
													'user',
													this.props.userFullName.operatorID
												);
												this.props.showLoadingButton(true);
												const binUrl =
													sessionStorage.getItem('binServiceUrl');
												RestSvc.postData(
													urlTypes.bin_setup.uploadBin,
													formData,
													binUrl
												)
													.then((response) => {
														this.props.showLoadingButton(false);
														var result = response.data.binRangeResponse;
														// let errorMessage = [];
														// if (result.status) {
														//     errorMessage.push(
														//         <>
														//             <b>{result.status}</b>
														//         </>
														//     )
														// }
														// if (result.modifyPromotionResult) {
														//     result.modifyPromotionResult.map((item) => {
														//         if (!isObjectEmpty(item)) {
														//             errorMessage.push(
														//                 <>
														//                     <div><b>{item.message}</b></div>
														//                 </>
														//             )
														//         }
														//     })
														// }

														// if (result.businessExceptions) {
														//     result.businessExceptions.map((item) => {
														//         if (!isObjectEmpty(item)) {
														//             errorMessage.push(
														//                 <>
														//                     <div><b>{item.description}</b></div>
														//                 </>
														//             )
														//         }
														//     })
														// }
														// this.setState({ informationModalShow: true, informationMsg: errorMessage, informationTitle: 'Information' });
														let errorMessage = '';

														if (result.modifyPromotionResult) {
															result.modifyPromotionResult.map(
																(item) => {
																	if (!isObjectEmpty(item)) {
																		errorMessage = item.message;
																	}
																}
															);
														}

														if (result.businessExceptions) {
															result.businessExceptions.map(
																(item) => {
																	if (!isObjectEmpty(item)) {
																		errorMessage =
																			errorMessage.concat(
																				'|',
																				item.description
																			);
																	}
																}
															);
														}
														this.setState({
															groupInformationModalShow: true,
															informationMsg: result.status,
															informationTitle: 'Information',
															errorMsg: errorMessage
														});
													})
													.catch((error) => {
														this.props.showLoadingButton(false);
														console.error(error);
													});
												$('#idLoadBinsFromFile').val('');

												// Hardcode
												// var result ={
												//     "status": "2 Record(s) have been successfully uploaded",
												//      "businessExceptions": [
												//      {
												//         "code": "ERR_MSG_BIN_ROW_ISSUE",
												//         "description": "No data is present in the BIN start range column in the following Row(s): [1, 2] Count :2"
												//     },
												//     {
												//         "code": "ERR_MSG_BIN_EXCLUSION_INVALID",
												//         "description": "User enters any other value outside of 'Y' or ('N' /blank) in the following Row(s): [3, 4, 5] Count :3"
												//     },
												//     {
												//         "code": "ERR_MSG_CHECK_RANGEVALUEISSUE",
												//         "description": "Start range value greater than the end range value in the following Row(s): [6, 7, 8] Count :3"
												//     },
												//     {
												//         "code": "ERR_MSG_BIN_ROW_ISSUE",
												//         "description": "No data is present in the BIN start range column in the following Row(s): [1, 2] Count :2"
												//     },
												//     {
												//         "code": "ERR_MSG_BIN_EXCLUSION_INVALID",
												//         "description": "User enters any other value outside of 'Y' or ('N' /blank) in the following Row(s): [3, 4, 5] Count :3"
												//     },
												//     {
												//         "code": "ERR_MSG_CHECK_RANGEVALUEISSUE",
												//         "description": "Start range value greater than the end range value in the following Row(s): [6, 7, 8] Count :3"
												//     },
												//     {
												//         "code": "ERR_MSG_CHECK_RANGEVALUEISSUE",
												//         "description": "Start range value greater than the end range value in the following Row(s): [6, 7, 8] Count :3"
												//     }

												//     ]
												// };
												// let errorMessage = [];

												// if (result.modifyPromotionResult) {
												//     result.modifyPromotionResult.map((item) => {
												//         if (!isObjectEmpty(item)) {
												//             errorMessage.push(item.message)
												//         }
												//     })
												// }

												// if (result.businessExceptions) {
												//     result.businessExceptions.map((item) => {
												//         if (!isObjectEmpty(item)) {
												//             errorMessage.push(item.description)
												//         }
												//     })
												// }
												// this.setState({ groupInformationModalShow: true, informationMsg: result.status, informationTitle: 'Information',errorMsg:errorMessage });
											}}
										/>
									</button>

									<button
										type="button"
										className="couponBtn d-flex align-items-center"
										style={{
											fontSize: '13px',
											outline: 'none',
											border: '0px'
										}}
										onClick={() => {
											document.getElementById('idAppendBinsFromFile').click();
										}}
									>
										<span className="btn font-weight-bold font13px">
											Add More BINs
										</span>
										<span className="couponUpload icon ml-2"></span>
										<input
											type="file"
											id="idAppendBinsFromFile"
											style={{ display: 'none' }}
											onChange={(event) => {
												this.setState((previousState) => {
													previousState.searchOffersForm.offerId.value =
														'';
													previousState.searchedOffers = [];
													previousState.showSearchedCouponTable = false;
													previousState.binPromotionData = [];
													previousState.genericUnLimitedCouponData = [];
													previousState.personalisedCouponData = [];
													previousState.targetedCouponData = [];
													previousState.viewBinDetailsDataInclusion = [];
													previousState.viewBinDetailsDataExclusion = [];
													previousState.binTableCurrentPage = 1;
													previousState.viewBinDetailsDataInclusion = [];
													previousState.viewBinDetailsTable = false;
													previousState.showVersionBins = false;
													previousState.viewBinDetailsDataExclusion = [];
													previousState.showVersionHistory = false;
													previousState.inclusionVersionBins = [];
													previousState.exclusionVersionBins = [];
													previousState.showDeletedBinRanges = false;
													previousState.deletedBinRanges = [];
												});
												const fileList = event.target.files;

												// const url = '/rest/offers/configurator/uploadCoupons';
												const formData = new FormData();
												formData.append('file', fileList[0]);
												formData.append(
													'user',
													this.props.userFullName.operatorID
												);
												this.props.showLoadingButton(true);
												const binUrl =
													sessionStorage.getItem('binServiceUrl');
												RestSvc.postData(
													urlTypes.bin_setup.appendBin,
													formData,
													binUrl
												)
													.then((response) => {
														this.props.showLoadingButton(false);
														var result = response.data.binRangeResponse;
														// let errorMessage = [];
														// if (result.status) {
														//     errorMessage.push(
														//         <>
														//             <b>{result.status}</b>
														//         </>
														//     )
														// }
														// if (result.modifyPromotionResult) {
														//     result.modifyPromotionResult.map((item) => {
														//         if (!isObjectEmpty(item)) {
														//             errorMessage.push(
														//                 <>
														//                     <div><b>{item.message}</b></div>
														//                 </>
														//             )
														//         }
														//     })
														// }

														// if (result.businessExceptions) {
														//     result.businessExceptions.map((item) => {
														//         if (!isObjectEmpty(item)) {
														//             errorMessage.push(
														//                 <>
														//                     <div><b>{item.description}</b></div>
														//                 </>
														//             )
														//         }
														//     })
														// } /* else {
														//     errorMessage.push(
														//         <>
														//             <b>{"Coupons uploaded successfully."}</b>
														//         </>
														//     )
														// } */
														// this.setState({ informationModalShow: true, informationMsg: errorMessage, informationTitle: 'Information' });

														let errorMessage = '';

														if (result.modifyPromotionResult) {
															result.modifyPromotionResult.map(
																(item) => {
																	if (!isObjectEmpty(item)) {
																		errorMessage = item.message;
																	}
																}
															);
														}

														if (result.businessExceptions) {
															result.businessExceptions.map(
																(item) => {
																	if (!isObjectEmpty(item)) {
																		errorMessage =
																			errorMessage.concat(
																				'|',
																				item.description
																			);
																	}
																}
															);
														}
														this.setState({
															groupInformationModalShow: true,
															informationMsg: result.status,
															informationTitle: 'Information',
															errorMsg: errorMessage
														});
													})
													.catch((error) => {
														this.props.showLoadingButton(false);
														console.error(error);
													});
												$('#idAppendBinsFromFile').val('');
											}}
										/>
									</button>
									<button
										type="button"
										className="couponBtn d-flex align-items-center"
										style={{
											fontSize: '13px',
											outline: 'none',
											border: '0px'
										}}
										onClick={() => {
											this.toggleSearchPopup(true);
											this.setState((previousState) => {
												previousState.searchOffersForm.offerId.value = '';
												previousState.searchedOffers = [];
												previousState.showSearchedCouponTable = false;
												previousState.binPromotionData = [];
												previousState.genericUnLimitedCouponData = [];
												previousState.personalisedCouponData = [];
												previousState.targetedCouponData = [];
												previousState.viewBinDetailsDataInclusion = [];
												previousState.viewBinDetailsDataExclusion = [];
												previousState.binTableCurrentPage = 1;
												previousState.viewBinDetailsDataInclusion = [];
												previousState.viewBinDetailsTable = false;
												previousState.showVersionBins = false;
												previousState.viewBinDetailsDataExclusion = [];
												previousState.showVersionHistory = false;
												previousState.inclusionVersionBins = [];
												previousState.exclusionVersionBins = [];
												previousState.showDeletedBinRanges = false;
												previousState.deletedBinRanges = [];
											});
										}}
									>
										<span className="btn font-weight-bold font13px">
											{' '}
											Modify/View BINs
										</span>
										<span className="ml-2">
											<img alt="img" src={Search_icon} />
										</span>
									</button>
									<div className="d-flex align-items-center channelLink">
										<a
											href="/#"
											title="Click to view the recently created/modified BIN promotions"
											onClick={(e) => {
												e.preventDefault();
												this.handleViewAll();
											}}
										>
											View All BINs
										</a>
									</div>
									<div className="d-flex align-items-center channelLink">
										<a
											href="../upload-append-bins.csv"
											title="Click to download the applicable templates for BIN Setup"
											download
										>
											Download BIN Templates
										</a>
									</div>
									{/* <div className="pl-3">
                                            <button type="button" className="couponBtn d-flex align-items-center" onClick={() => {
                                                document.getElementById("idLoadCouponsFromFile").click();
                                            }}
                                            >
                                                <span class="font-weight-bold font13px"> Delete BINs</span>
                                                <span className="couponDelete  icon  ml-2"></span>
                                                <input
                                                    type="file"
                                                    id="idLoadCouponsFromFile"
                                                    style={{ display: "none" }}
                                                    onChange={(event) => {
                                                        const fileList = event.target.files;
                                                        
                                                        const url = '/rest/offers/configurator/uploadCoupons';
                                                        const formData = new FormData();
                                                        formData.append('file', fileList[0])
                                                        this.props.showLoadingButton(true);
                                                        RestSvc.postData(url, formData)
                                                            .then((response) => {
                                                                this.props.showLoadingButton(false);
                                                                var result = response.data.uploadCouponResponse;
                                                                let errorMessage = [];
                                                                if (result.status) {
                                                                    errorMessage.push(
                                                                        <>
                                                                            <b>{result.status}</b>
                                                                        </>
                                                                    )
                                                                }                
                                                                if (result.modifyPromotionResult) {
                                                                    result.modifyPromotionResult.map((item) => {
                                                                        if (!isObjectEmpty(item)) {
                                                                            errorMessage.push(
                                                                                <>
                                                                                    <div><b>{item.message}</b></div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                } 
                                                               
                                                                if (result.businessExceptions) {
                                                                    result.businessExceptions.map((item) => {
                                                                        if (!isObjectEmpty(item)) {
                                                                            errorMessage.push(
                                                                                <>
                                                                                    <div><b>{item.description}</b></div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                } 
                                                                this.setState({ informationModalShow: true, informationMsg: errorMessage, informationTitle: 'Information' });
                                                            })
                                                            .catch((error) => {
                                                                this.props.showLoadingButton(false);
                                                                console.error(error);
                                                            });
                                                        $("#idLoadCouponsFromFile").val('');
                                                    }}
                                                /></button>
                                    </div> */}
								</div>
							</div>
						</form>

						<div
							className={this.state.searchPopup ? 'modal in-right d-block' : 'modal'}
						>
							<div
								className="modal-dialog m-0 searchOffer-modal-bottom-right"
								role="document"
							>
								<div className="modal-content h-100">
									<div className="modal-header modal-title-custom p-2 b-radius-none d-flex">
										<p
											className="modal-title ml-2 roboto-r-18"
											style={{ flex: '1' }}
											id="exampleModalLabel"
										>
											Search BINs
										</p>
										<span className="xSCloseButtonSpan">
											<button
												className="xSCloseButton"
												onClick={() => {
													this.toggleSearchPopup();
												}}
											>
												X
											</button>
										</span>
									</div>

									<BinRangeSearch
										handleSelection={this.toggleSearchPopup}
										handleSearch={this.handleSearch}
										handleViewAll={this.handleViewAll}
									/>
								</div>
							</div>
						</div>
					</div>
					{/* <hr></hr> */}
					{this.state.showSearchedCouponTable ? (
						<div className={'row d-block ml-1'}>
							{' '}
							<hr></hr>
							<div className="row" style={{ width: '99%' }}>
								<div className="couponTableContainer ">
									<>
										{this.state?.binPromotionData.length == 0 ? null : (
											<>
												<div className="mt-3 d-flex">
													{this.state.searchForm?.promotionID?.value ? (
														<div className="d-flex couponCloseSpan">
															<span className="mr-2">{`Promotion ID: ${this.state.searchForm?.promotionID.value}`}</span>
														</div>
													) : undefined}
													{this.state.searchForm?.binStartRange?.value ? (
														<div className="d-flex couponCloseSpan">
															<span className="mr-2">{`Bin Start Range: ${this.state.searchForm?.binStartRange.value}`}</span>
														</div>
													) : undefined}
													{this.state.searchForm?.binEndRange?.value ? (
														<div className="d-flex couponCloseSpan">
															<span className="mr-2">{`Bin End Range: ${this.state.searchForm?.binEndRange.value}`}</span>
														</div>
													) : undefined}
													{this.state.searchForm?.binNumber?.value ? (
														<div className="d-flex couponCloseSpan">
															<span className="mr-2">{`Bin Number: ${this.state.searchForm?.binNumber.value}`}</span>
														</div>
													) : undefined}
													{this.state.searchForm?.createdBy?.value ? (
														<div className="d-flex couponCloseSpan">
															<span className="mr-2">{`Created/Modified By: ${this.state.searchForm?.createdBy.value}`}</span>
														</div>
													) : undefined}
												</div>
											</>
										)}

										{this.state.binPromotionData &&
										this.state.binPromotionData.length > 0 ? (
											<>
												<div className="d-flex pt-3 pb-1">
													<div className="font-weight-bold pl-3">
														Search Results
													</div>
													<div className="cGray pl-3">
														{this.state.binPromotionData.length}{' '}
														Promotions Found.
													</div>
												</div>

												<BinTable
													data={this.state.binPromotionData}
													columns="searchedCouponGeneric"
													placeholder="Search Promotion Id"
													linkAction={this.linkAction}
													/* searchForm={this.state.searchForm}  */
													pageNumber={this.state.binTableCurrentPage}
													onPageChange={this.onPageChange}
													selectedGeneric={this.state.selectedGeneric}
													onSelectedGenericChange={
														this.onSelectedGenericChange
													}
													type="BINTABLE"
													downloadCoupon={this.downloadCoupon}
													maxPage={this.state.binTableMaxPage}
												/>
											</>
										) : undefined}
									</>
								</div>
							</div>{' '}
						</div>
					) : null}

					{this.state?.viewBinDetailsTable ? (
						<div
							className={
								// this.state?.viewBinDetailsTable ?
								'row justify-content-center d-block'
								//  : "d-none"
							}
							style={{ width: '99%' }}
						>
							<div className="couponTableContainer ">
								{
									// this.state?.viewBinDetailsDataInclusion && this.state?.viewBinDetailsDataInclusion.length > 0 ||
									// this.state?.viewBinDetailsDataExclusion && this.state?.viewBinDetailsDataExclusion.length > 0

									<>
										<hr></hr>
										<div className="d-flex">
											<div
												className="d-flex"
												style={{ padding: '0px 15px 15px' }}
											>
												{/* <span className="cGray mr-1">Promotion ID: </span> */}
												<span className="font-weight-bold">
													{this.state.clickedPromotion.promotionId} &nbsp;
													(
													<span
														className="channelLink roboto-r-14 font-weight-normal"
														onClick={() => {
															// this.setState({ showVersionHistory: true });//For Hardcode
															this.getBinVersions(
																this.state.clickedPromotion
																	.promotionId
															);
															// setTimeout(function(){
															//     document.getElementById("scrollToBinHstryTable").scrollIntoView({behavior: "smooth"});
															// }, 1000);
														}}
													>
														{this.state.totalRecords} Revisions in BIN
														Setup
													</span>
													)
												</span>
											</div>
										</div>
										<div className="d-flex cGray">
											{/* <div className="col-md-3">
                                            Program Id
                                        </div>
                                        <div className="col-md-3 ">
                                            Brand Name
                                        </div> */}
											<div className="col-md-3">Promotion Type</div>
											<div className="col-md-3">Start Date</div>
											<div className="col-md-3">Last Modified By</div>
											<div className="col-md-3">Last Modified On</div>
										</div>
										<div className="d-flex">
											{/* <div className="col-md-3">
                                            {this.state.programId}
                                        </div>
                                        <div className="col-md-3">
                                            {this.state.brandName}
                                        </div> */}
											<div className="col-md-3">
												{this.state.promotionType}
											</div>
											<div className="col-md-3">{this.state.startDate}</div>
											<div className="col-md-3">
												{this.state.modifiedBy || '-'}
											</div>
											<div className="col-md-3">
												{this.state.modifiedOn || '-'}
											</div>
										</div>
										<br></br>
										<div className="d-flex">
											<span
												className="font-weight-bold mr-1"
												style={{ padding: '0px 0px 0px 15px' }}
											></span>
										</div>

										<BinDetailsTable
											// data={binData}
											data={this.state.viewBinDetailsDataInclusion}
											data1={this.state.viewBinDetailsDataExclusion}
											// columns="viewCoupon"
											placeholder="Search Coupon Id"
											// onFollowChanged={this.onFollowChanged}
											pageNumberInclusion={
												this.state.viewBinDetailsInclusionCurrentPage
											}
											pageNumberExclusion={
												this.state.viewBinDetailsExclusionCurrentPage
											}
											linkAction={this.linkAction}
											type="VIEW"
											onPageChange={this.onPageChange}
											deleteBinInclusion={this.deleteBinInclusion}
											gotoBinSearch={this.gotoBinSearch}
											searchForm={this.state.searchForm}
											selectedGeneric={this.state.selectedGeneric}
											onSelectedGenericChange={this.onSelectedGenericChange}
											selectedBinExclusion={this.state.selectedBinExclusion}
											onSelectedBinExclusionChange={
												this.onSelectedBinExclusionChange
											}
											deleteBinExclusion={this.deleteBinExclusion}
											maxPageExclusion={
												this.state.viewBinDetailsExclusionMaxPage
											}
											maxPageInclusion={
												this.state.viewBinDetailsInclusionMaxPage
											}
										/>
										<div className="ml-3">
											<span className="font-weight-bold pr-1">
												Deleted BINs:
											</span>
											<span
												className="channelLink"
												onClick={() => {
													this.getDeletedBins('Search', 0);
												}}
											>
												Click to view the deleted BINs
											</span>
											{this.state.showDeletedBinRanges ? (
												<div id="scrollToDeleteTable">
													<DeletedBinsTable
														data={this.state.deletedBinRanges}
														pageNumber={
															this.state.deletedBinsCurrentPage
														}
														onPageChange={this.onPageChange}
														maxPage={this.state.deletedBinsMaxPage}
													/>
												</div>
											) : null}
										</div>
									</>
								}
							</div>
							{this.state.showVersionHistory ? (
								<div
									id="scrollToBinHstryTable"
									ref={(el) => {
										this.messagesEnd = el;
									}}
								>
									<BinVersionsTable
										// setSelectedVersions={this.setSelectedVersions}
										// selectedVersions={this.state.selectedVersions}
										binVersions={this.state.binVersionsData}
										viewBins={this.viewBins}
									/>
								</div>
							) : null}
						</div>
					) : null}
					{this.state.showVersionBins ? (
						<>
							<div>
								<hr></hr>
								<span className="font-weight-bold">
									{this.state.clickedPromotion.promotionId}
									<span>- BIN Version {this.state.currVersion}</span>
								</span>
								<hr></hr>
							</div>
							<BinDetailsTable
								data={this.state.inclusionVersionBins}
								data1={this.state.exclusionVersionBins}
								// columns="viewCoupon"
								placeholder="Search Coupon Id"
								// onFollowChanged={this.onFollowChanged}
								pageNumberInclusion={
									this.state.viewBinDetailsInclusionVersionCurrentPage
								}
								pageNumberExclusion={
									this.state.viewBinDetailsExclusionVersionCurrentPage
								}
								linkAction={this.linkAction}
								type="VERSION"
								onPageChange={this.onPageChange}
								downloadCoupon={this.downloadCoupon}
								gotoBinSearch={this.gotoBinSearch}
								searchForm={this.state.searchForm}
								selectedGeneric={this.state.selectedGeneric}
								onSelectedGenericChange={this.onSelectedGenericChange}
								selectedBinExclusion={this.state.selectedBinExclusion}
								onSelectedBinExclusionChange={this.onSelectedBinExclusionChange}
								deleteBinExclusion={this.deleteBinExclusion}
								maxPageExclusion={this.state.viewBinDetailsExclusionVersionMaxPage}
								maxPageInclusion={this.state.viewBinDetailsInclusionVersionMaxPage}
							/>
						</>
					) : null}
				</div>
				<div className="binSetup">
					{
						this.state.viewBinDetailsTable || this.state.showVersionBins ? (
							<div className="footer">
								<button
									type="button"
									className={'cancel mr-3'}
									onClick={this.gotoBinSearch}
								>
									Back
								</button>
							</div>
						) : null
						// <div className="footer footerBg">

						//     </div>
					}
				</div>

				{this.state.confirmationModalShow ? (
					<ConfirmationModal
						show={this.state.confirmationModalShow}
						onHide={this.confirmationModalClose}
						onProceed={this.proceedToOperation}
						confirmmsg={this.state.confirmMsg}
					/>
				) : null}

				{this.state.informationModalShow ? (
					<InformationModal
						show={this.state.informationModalShow}
						onHide={this.informationModalClose}
						infoMsg={this.state.informationMsg}
						infoTitle={this.state.informationTitle}
					/>
				) : null}
				{this.state.groupInformationModalShow ? (
					<GroupInformationModal
						show={this.state.groupInformationModalShow}
						onHide={this.informationModalClose}
						infoMsg={this.state.informationMsg}
						infoTitle={this.state.informationTitle}
						errorMsg={this.state.errorMsg}
						splitErrorMsg
						addFilter
					/>
				) : null}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		diffJsonReplace: state.diffJsonReplace,
		diffJsonAdd: state.diffJsonAdd,
		diffJsonRemove: state.diffJsonRemove
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BinRangeManagement);
