import React from 'react';
import { useField } from 'formik';
import ConditionalRedering from './ConditionalRedering';
import { itemHelp } from '../item-help-text';
import CustomSelect from '../customfields/CustomSelect';

function SimplePromotion(props) {
	const thresholdTypeCodeOptions = [
		{
			value: '',
			name: 'Select one...'
		},
		{
			value: 'SIMPLE_INTERVAL_QTY_THRESHOLD',
			name: 'Simple Interval Quantity Threshold'
		}
	];

	//Local const

	/*  const multiByGroupSchema = Yup.object({
    thresholdTypeCode: Yup.string().required("Required"),
  }); */
	const [field] = useField(props);

	const getThresholdDropdown = (dropdownOptions) => {
		return dropdownOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};
	return (
		<div className="container-fluid">
			{/* <div class="accordion" id="accordionExample">
        <div class="card" style={{ border: "none" }}>
          <CollapseHeaderBar
            text={localConst.eligibilitySettings}
            headingId={"headingOne"}
            dataTarget={"#collapseOne"}
          />
          <CollapseBody id="collapseOne" headingId={"headingOne"}> */}
			<div className="d-flex justify-content-center">
				<CustomSelect
					name="simplePromotionEligibility.thresholdTypeCode"
					required={true}
					label="Threshold Type Code"
					options={getThresholdDropdown(thresholdTypeCodeOptions)}
					className="customDropdown inlinePadding bg-position"
					smallText={itemHelp.ThresholdTypeCodeMBP}
				/>
			</div>
			{field.value.simplePromotionEligibility.thresholdTypeCode ? (
				<ConditionalRedering
					value={field.value.simplePromotionEligibility.thresholdTypeCode}
				/>
			) : null}
		</div>
	);
}

export default SimplePromotion;
