import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './couponTable.scss';
import ToolkitProvider from '../../utils/reactBootstrapTable2Toolkit';
import filterFactory from 'react-bootstrap-table2-filter';

/* let options = {} */
class DeletedBinsTable extends Component {
	constructor(props) {
		super(props);

		function formatYesNo(value, row, index) {
			return value ? 'Y' : 'N';
		}

		const columnsBin = [
			{
				dataField: 'binStartRange',
				text: 'Bin Start Range ',
				headerAlign: 'center',
				sort: true
			},
			{
				dataField: 'binEndRange',
				text: 'Bin End Range',
				headerAlign: 'center',
				searchable: false
			},
			{
				dataField: 'exclusionFlag',
				text: 'Exclusion',
				headerAlign: 'center',
				// searchable: false
				formatter: formatYesNo
			},
			{
				dataField: 'deletedOn',
				text: 'Deleted On',
				headerAlign: 'center'
				// sort: true
			},
			{
				dataField: 'deletedBy',
				text: 'Deleted By',
				headerAlign: 'center'
				// searchable: false
			}
		];

		this.state = {
			// For displaying data
			data: props.data,
			columns: columnsBin,
			isFollow: true,
			placeholder: props.placeholder,
			sizePerPage: 8,
			selectedProducts: []
		};

		this.options = {
			onPageChange: this.props.onPageChange,
			sizePerPage: this.state.sizePerPage,
			prePageText: 'Previous',
			nextPageText: 'Next',
			paginationSize: 1,
			alwaysShowAllBtns: true, // Always show next and previous button
			withFirstAndLast: false,
			hidePageListOnlyOnePage: true
		};
	}
	render() {
		return (
			<ToolkitProvider
				keyField="id"
				data={this.state.data}
				columns={this.state.columns}
				search
			>
				{(props) => (
					<div style={{ width: '70%' }} className="couponTable binDetails">
						<div className="pt-2">
							<BootstrapTable
								{...props.baseProps}
								// keyField="id"
								noDataIndication={() => <div>No BINs setup</div>}
								data={this.props.data}
								columns={this.state.columns}
								filter={filterFactory()}
								selectRow={
									this.props.type == 'VERSION' ? undefined : this.selectRow
								}
								//  pagination={paginationFactory(this.options)}
							/>
						</div>

						<div className="d-flex">
							<div
								style={{
									'justify-self': 'center',
									flex: 1,
									'text-align': 'center',
									'margin-top': '20px'
								}}
							>
								<button
									className={
										this.props.pageNumber == 1
											? 'btn btn-primary m-2 disabled'
											: 'btn btn-primary m-2'
									}
									onClick={() => {
										this.props.onPageChange('DeletedBins', 'Previous');
									}}
								>
									Previous
								</button>
								<div className="btn btn-primary m-2">{this.props.pageNumber}</div>
								<button
									className={
										this.props.maxPage == this.props.pageNumber
											? 'disabled btn btn-primary m-2'
											: 'btn btn-primary m-2'
									}
									onClick={() => {
										this.props.onPageChange('DeletedBins', 'Next');
									}}
								>
									Next
								</button>
							</div>
						</div>
					</div>
				)}
			</ToolkitProvider>
		);
	}
}

export default DeletedBinsTable;
