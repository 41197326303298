import appConstants from './appConstants';

const appConfig = {
	hostNames: {
		prod: 'oc.tatadigital.com',
		pt: 'configurator.pt-2-offer.tatadigital.com',
		preProd: 'configurator.pp-offer.tatadigital.com',
		sit: 'ocnewui-sit.tatadigital.com',
		dev: 'ocnewui-dev.tatadigital.com',
		local: 'localhost'
	},
	flashSaleTemplates: {
		lower: '../Multibuygroup promotion upload Template.xlsm',
		prod: '../Multibuygroup promotion upload Template_Prod.xlsm'
	},
	refreshTokenTimer: 1000 * 60 * 17, // 17 minutes as token is valid for 20 minutes
	caseSensitiveSearch: [
		appConstants.buBrand.TSSS.BUID
	] /* add businessUnitGroupFunctionID for case-sensitive search  */,
	feature: {
		enableGenerateCoupon: false /* feature flag to enable generate coupon */
	},
	createOfferBrandEligibility: [
		appConstants.buBrand.TSSS.BUID,
		appConstants.buBrand.GEOFENCED.BUID
	]
};

export default appConfig;
