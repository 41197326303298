import React, { useEffect, useState } from 'react';
import ProductTableBoostrap from '../ProductTableBootstrap';

const AuditProductTablePackage = ({ ...props }) => {
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const { i, PKGProductTableData, customArrayName } = props;

	const keys = ['productID', 'minimumPrice', 'maximumPrice'];
	const defaultValueForAddPP = {
		maximumPrice: '999999.99',
		minimumPrice: '0.01'
		// trigger: 'Y',
		// getReward: 'Y',
	};

	// let productsAdded=[];
	const getAdded = (products, products1, identifier) => {
		//debugger
		var added = [];
		for (var i = 0; i < products.length; i++) {
			var id = products[i][identifier];
			var flag = false;
			for (var j = 0; j < products1.length; j++) {
				var id2 = products1[j][identifier];
				if (id === id2) {
					flag = true;
					// products1[j].contained = true;
				}
			}
			if (!flag) {
				added.push(products[i]);
			}
		}
		return added;
	};

	const getReplaced = (newProds, oldProds, added, removed, identifier) => {
		//debugger
		let newProdsModified = [],
			oldProdsModifed = [],
			replaced = [];
		newProdsModified = getAdded(newProds, added, 'productID'); //Remove added from new json
		oldProdsModifed = getAdded(oldProds, removed, 'productID'); //Remove deleted from old json

		newProdsModified.map((item) => {
			oldProdsModifed.map((item1) => {
				if (item[identifier] == item1[identifier]) {
					if (JSON.stringify(item) != JSON.stringify(item1)) {
						if (item.minimumPrice != item1.minimumPrice) {
							// item.minimumPrice = 'Old value:' + item1.minimumPrice + ', New value:' + item.minimumPrice
							item.minimumPrice = JSON.stringify({
								oldValue: item1.minimumPrice,
								newValue: item.minimumPrice
							});
						}
						if (item.maximumPrice != item1.maximumPrice) {
							//  item.maximumPrice = 'Old value: ' + item1.maximumPrice + ', New value: ' + item.maximumPrice
							item.maximumPrice = JSON.stringify({
								oldValue: item1.maximumPrice,
								newValue: item.maximumPrice
							});
						}
						replaced.push(item);
					}
				}
			});
		});
		return replaced;
	};
	const [productsAdded, setProductsAdded] = useState([]);
	const [productsRemoved, setProductsRemoved] = useState([]);
	const [productsReplaced, setProductsReplaced] = useState([]);

	useEffect(() => {
		if (isAuditFlw) {
			let OldProds = props.oldJson ? props.oldJson : [];
			// let NewProds = props.newJson.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.group[i]?.multibuyProduct ? props.newJson.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.group[i]?.multibuyProduct : [];
			let NewProds = props.newJson ? props.newJson : [];

			let tempRemoved = getAdded(OldProds, NewProds, 'productID');
			setProductsRemoved(tempRemoved);
			let tempAdded = getAdded(NewProds, OldProds, 'productID');
			setProductsAdded(tempAdded);
			let tempReplaced = getReplaced(NewProds, OldProds, tempAdded, tempRemoved, 'productID');
			setProductsReplaced(tempReplaced);
		}
		return () => {};
	}, []);

	return (
		<div
			className={
				productsAdded?.length == 0 &&
				productsRemoved?.length == 0 &&
				productsReplaced?.length == 0
					? 'd-none'
					: 'box-blackout-period lightBlue container pl-2'
			}
		>
			{productsAdded.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">Added ({productsAdded.length})</label>
					</div>
					<div className="w-auto dataAdded">
						<ProductTableBoostrap
							keys={keys}
							data={productsAdded}
							defaultValueForAdd={defaultValueForAddPP}
							onProductTableChange={() => {}}
							eligibility={'packageMultibuyEligibility'}
							i={i}
							group={PKGProductTableData[customArrayName]}
						/>
					</div>
				</div>
			) : undefined}
			{productsRemoved.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">
							Removed ({productsRemoved.length})
						</label>
					</div>
					<div className="w-auto dataRemoved">
						<ProductTableBoostrap
							keys={keys}
							data={productsRemoved}
							defaultValueForAdd={defaultValueForAddPP}
							onProductTableChange={() => {}}
							eligibility={'packageMultibuyEligibility'}
							i={i}
							group={PKGProductTableData[customArrayName]}
						/>
					</div>
				</div>
			) : undefined}
			{productsReplaced.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">
							Modified ({productsReplaced.length})
						</label>
					</div>
					<div className="w-auto">
						<ProductTableBoostrap
							keys={keys}
							data={productsReplaced}
							defaultValueForAdd={defaultValueForAddPP}
							onProductTableChange={() => {}}
							eligibility={'packageMultibuyEligibility'}
							i={i}
							group={PKGProductTableData[customArrayName]}
						/>
					</div>
				</div>
			) : undefined}
		</div>
	);
};

export default AuditProductTablePackage;
