import React from 'react';
import { createRoot } from 'react-dom/client';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

import 'bootstrap/dist/js/bootstrap.min.js';
import App from './App';
import './styles.scss';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reducer from './store/reducer';
import thunk from 'redux-thunk';

const store = createStore(
	reducer,
	composeWithDevToolsDevelopmentOnly(
		applyMiddleware(thunk)
		// other store enhancers if any
	)
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);
