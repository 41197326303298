import React, { useEffect, useState } from 'react';
import ProductTableBootstrapNew from '../ProductTableBootstrapNew';
import TxnTable from '../ProductTableBootstrap';

const AuditProductTable = ({ ...props }) => {
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	// let productsAdded=[];
	const getAdded = (products, products1) => {
		var added = [];
		for (var i = 0; i < products.length; i++) {
			var id = products[i].productID;
			var flag = false;
			for (var j = 0; j < products1.length; j++) {
				var id2 = products1[j].productID;
				if (id === id2) {
					flag = true;
					// products1[j].contained = true;
				}
			}
			if (!flag) {
				added.push(products[i]);
			}
		}
		return added;
	};

	const getReplaced = (newProds, oldProds, added, removed) => {
		let newProdsModified = [],
			oldProdsModifed = [],
			replaced = [];
		newProdsModified = getAdded(newProds, added); //Remove added from new json
		oldProdsModifed = getAdded(oldProds, removed); //Remove deleted from old json

		newProdsModified.map((item) => {
			oldProdsModifed.map((item1) => {
				if (item.productID == item1.productID) {
					if (JSON.stringify(item) != JSON.stringify(item1)) {
						if (item.minimumPrice != item1.minimumPrice) {
							//item.minimumPrice = JSON.stringify({ 'oldValue': item1.minimumPrice, 'newValue': item.minimumPrice })
							item.minimumPrice = JSON.stringify({
								oldValue: item1.minimumPrice,
								newValue: item.minimumPrice
							});
						}
						if (item.maximumPrice != item1.maximumPrice) {
							// item.maximumPrice = 'Old value: ' + item1.maximumPrice + ', New value: ' + item.maximumPrice
							item.maximumPrice = JSON.stringify({
								oldValue: item1.maximumPrice,
								newValue: item.maximumPrice
							});
						}
						if (props.txnTable) {
							if (item.includeForThreshold != item1.includeForThreshold) {
								//  item.includeForThreshold = 'Old value:' + item1.includeForThreshold + ', New value:' + item.includeForThreshold
								item.includeForThreshold = JSON.stringify({
									oldValue: JSON.stringify(item1.includeForThreshold),
									newValue: JSON.stringify(item.includeForThreshold)
								});
							}
						} else {
							if (item.trigger != item1.trigger) {
								// item.trigger = 'Old value:' + item1.trigger + ', New value:' + item.trigger
								item.trigger = JSON.stringify({
									oldValue: JSON.stringify(item1.trigger),
									newValue: JSON.stringify(item.trigger)
								});
							}
							if (item.getReward != item1.getReward) {
								//item.getReward = 'Old value:' + item1.getReward + ', New value:' + item.getReward
								item.getReward = JSON.stringify({
									oldValue: JSON.stringify(item1.getReward),
									newValue: JSON.stringify(item.getReward)
								});
							}
						}

						replaced.push(item);
					}
				}
			});
		});

		return replaced;
	};
	const [productsAdded, setProductsAdded] = useState([]);
	const [productsRemoved, setProductsRemoved] = useState([]);
	const [productsReplaced, setProductsReplaced] = useState([]);

	useEffect(() => {
		if (isAuditFlw) {
			let OldProds = props.oldJson;
			// let NewProds = props.newJson.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.multibuyProduct ? props.newJson.promotionPolicy.priceDerivationRuleEligibility.groupMultibuyEligbility.multibuyProduct : [];
			let NewProds = props.newJson ? props.newJson : [];

			let tempRemoved = getAdded(OldProds, NewProds);
			setProductsRemoved(tempRemoved);
			let tempAdded = getAdded(NewProds, OldProds);
			setProductsAdded(tempAdded);
			let tempReplaced = getReplaced(NewProds, OldProds, tempAdded, tempRemoved);
			setProductsReplaced(tempReplaced);
		}
		return () => {};
	}, []);

	return (
		<div
			className={
				productsAdded.length == 0 &&
				productsRemoved.length == 0 &&
				productsReplaced.length == 0
					? 'd-none'
					: 'box-blackout-period lightBlue container pl-2'
			}
		>
			{productsAdded.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">Added ({productsAdded.length})</label>
					</div>
					<div className="w-auto dataAdded">
						{props.txnTable ? (
							<TxnTable
								data={productsAdded}
								onProductTableChange={() => {}}
								eligibility={'TXN'}
								defaultValueForAdd={{
									maximumPrice: '9999999.99',
									minimumPrice: '0.01',
									includeForThreshold: true
								}}
								auditType={'Add'}
								keys={[
									'productID',
									'includeForThreshold',
									'minimumPrice',
									'maximumPrice'
								]}
							/>
						) : props.MTMBtable ? (
							<TxnTable
								data={productsAdded}
								onProductTableChange={() => {}}
								eligibility={'MTMB'}
								defaultValueForAdd={{
									maximumPrice: '9999999.99',
									minimumPrice: '0.01',
									includeForThreshold: true
								}}
								auditType={'Add'}
								keys={[
									'productID',
									'includeForThreshold',
									'minimumPrice',
									'maximumPrice'
								]}
							/>
						) : (
							<ProductTableBootstrapNew
								dataProduct={productsAdded}
								dataMer={[]}
								keys={['productID', 'minimumPrice', 'maximumPrice']}
								keysSH={[
									'merchandizeGroupId',
									'trigger',
									'getReward',
									'minimumPrice',
									'maximumPrice'
								]}
								defaultValueForAdd={{
									maximumPrice: '9999999.99',
									minimumPrice: '0.01',
									trigger: true,
									getReward: true
								}}
								onProductTableChange={() => {}}
								eligibility={'MultiBuyGroup'}
								auditType={'Add'}
							/>
						)}
					</div>
				</div>
			) : undefined}
			{productsRemoved.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">
							Removed ({productsRemoved.length})
						</label>
					</div>
					<div className="w-auto dataRemoved">
						{props.txnTable ? (
							<TxnTable
								data={productsRemoved}
								onProductTableChange={() => {}}
								eligibility={'TXN'}
								defaultValueForAdd={{
									maximumPrice: '9999999.99',
									minimumPrice: '0.01',
									includeForThreshold: true
								}}
								auditType={'Add'}
								keys={[
									'productID',
									'includeForThreshold',
									'minimumPrice',
									'maximumPrice'
								]}
							/>
						) : props.MTMBtable ? (
							<TxnTable
								data={productsRemoved}
								onProductTableChange={() => {}}
								eligibility={'MTMB'}
								defaultValueForAdd={{
									maximumPrice: '9999999.99',
									minimumPrice: '0.01',
									includeForThreshold: true
								}}
								auditType={'Add'}
								keys={[
									'productID',
									'includeForThreshold',
									'minimumPrice',
									'maximumPrice'
								]}
							/>
						) : (
							<ProductTableBootstrapNew
								dataProduct={productsRemoved}
								dataMer={[]}
								keys={[
									'productID',
									'trigger',
									'getReward',
									'minimumPrice',
									'maximumPrice'
								]}
								keysSH={[
									'merchandizeGroupId',
									'trigger',
									'getReward',
									'minimumPrice',
									'maximumPrice'
								]}
								defaultValueForAdd={{
									maximumPrice: '9999999.99',
									minimumPrice: '0.01',
									trigger: true,
									getReward: true
								}}
								onProductTableChange={() => {}}
								eligibility={'MultiBuyGroup'}
								auditType={'Remove'}
							/>
						)}
					</div>
				</div>
			) : undefined}
			{productsReplaced.length > 0 ? (
				<div className="issuerOuterBox mb-3">
					<div>
						<label className="font-weight-bold">
							Modified ({productsReplaced.length})
						</label>
					</div>
					<div className="w-auto">
						{props.txnTable ? (
							<TxnTable
								data={productsReplaced}
								onProductTableChange={() => {}}
								eligibility={'TXN'}
								defaultValueForAdd={{
									maximumPrice: '9999999.99',
									minimumPrice: '0.01',
									includeForThreshold: true
								}}
								keys={[
									'productID',
									'includeForThreshold',
									'minimumPrice',
									'maximumPrice'
								]}
							/>
						) : props.MTMBtable ? (
							<TxnTable
								data={productsReplaced}
								onProductTableChange={() => {}}
								eligibility={'MTMB'}
								defaultValueForAdd={{
									maximumPrice: '9999999.99',
									minimumPrice: '0.01',
									includeForThreshold: true
								}}
								keys={[
									'productID',
									'includeForThreshold',
									'minimumPrice',
									'maximumPrice'
								]}
							/>
						) : (
							<ProductTableBootstrapNew
								dataProduct={productsReplaced}
								dataMer={[]}
								keys={[
									'productID',
									'trigger',
									'getReward',
									'minimumPrice',
									'maximumPrice'
								]}
								keysSH={[
									'merchandizeGroupId',
									'trigger',
									'getReward',
									'minimumPrice',
									'maximumPrice'
								]}
								defaultValueForAdd={{
									maximumPrice: '9999999.99',
									minimumPrice: '0.01',
									trigger: true,
									getReward: true
								}}
								onProductTableChange={() => {}}
								eligibility={'MultiBuyGroup'}
							/>
						)}
					</div>
				</div>
			) : undefined}
		</div>
	);
};

export default AuditProductTable;
