import React from 'react';
import { Modal } from 'react-bootstrap';

export class ConfirmationModal extends React.Component {
	render() {
		return (
			<div>
				<Modal
					show={this.props.show}
					onHide={this.props.onHide}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					{/* <Modal.Header closeButton > */}
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.props.confirmmsg.startsWith(
							'Are you sure you want to delete offer?'
						) ||
						this.props.confirmmsg.startsWith(
							'Are you sure you want to delete offers?'
						) ? (
							this.props.confirmmsg.split('|').map((value) => (
								<>
									<p>{value}</p>
								</>
							))
						) : (
							<p>{this.props.confirmmsg}</p>
						)}
					</Modal.Body>
					<Modal.Footer>
						<button className="btnCancel ml-2 roboto-b-16" onClick={this.props.onHide}>
							No
						</button>
						<button
							className="btnNxtPrev ml-2 roboto-b-16"
							onClick={this.props.onProceed}
						>
							Yes
						</button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default ConfirmationModal;
