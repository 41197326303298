import React, { useEffect } from 'react';
import { Form, useField, useFormikContext } from 'formik';
import CustomInputText from './customfields/CustomInputText';
import CustomInputRadio from './customfields/CustomInputRadio';
import CustomDateTime from './customfields/CustomDateTime';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import CustomSelect from './customfields/CustomSelect';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { itemHelp } from './item-help-text';
import ScaleProductTableBootstrapSPSSH from './ScaleProductTableBootstrapSPSSH';
import moment from 'moment';
import AuditScaleTable from './Audit/AuditScaleTable';
import $ from 'jquery';

/* const benefitSchema = Yup.object().shape({
    benefitType: Yup.string().required("Required: A Benefit Type must be selected"),
    priceModificationMethodCode: Yup.string().required("Required"),
    priceModificationPercent: Yup.string().required("Required")
    .matches(/^(100|([0-9][0-9]?(\.[0-9]{0,2})?))$/, "Price Modification Percent should be between 0.01 to 100"),
    priceModificationAmount:Yup.string().required("Required")
    .matches(/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/, "Price Modification Amount should be between 0.01 to 999999.99"),
    newPriceAmount: Yup.string().required("Required")
    .matches(/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/, "Price Modification Amount should be between 0.01 to 999999.99"),
    cashback: Yup.object().shape({
        creditBy:Yup.object().shape({
            date: Yup.date().min(new Date(), "Date should not be less than the Current Date"),
            days: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
            months: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
        }),
        expireOn:Yup.object().shape({
            date: Yup.date().min(new Date(), "Date should not be less than the Current Date"),
            days: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
            months: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
        }),
    }),
    Loyalty:  Yup.object().shape({
        loyaltyMultiplier : Yup.string().required("Required")
        .matches(/^(?=.*[1-9])\d{0,6}$/, "Loyalty Multiplier Point shoukd be between 1 to 999999"),
        loyaltyAdditionalPoint: Yup.string().required("Required")
        .matches(/^(?=.*[1-9])\d{0,6}$/, "Loyalty Additional Point shoukd be between 1 to 999999"),
        expireOn:Yup.object().shape({
            date: Yup.date().min(new Date(), "Date should not be less than the Current Date"),
            days: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
            months: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
        }),
    }),
    
    
});
 */

//newly added
const keys = ['productID', 'trigger', 'getReward', 'minimumPrice', 'maximumPrice'];
const defaultValueForAddMG = {
	maximumPrice: '9999999.99',
	minimumPrice: '0.01',
	trigger: true,
	getReward: true
};

const dropdownValues = {
	benefitTypes: [
		{ value: '', name: 'Select one' },
		{ value: 'INSTANT', name: 'Instant Discount' },
		{ value: 'CASHBACK', name: 'Cashback' },
		{ value: 'LOYALTY_POINTS', name: 'Loyalty Points' },
		{ value: 'FREEBIE', name: 'Freebies' },
		{ value: 'FREE_TEXT', name: 'Free text' },
		{ value: 'EMI', name: 'EMI' }
	],
	benefitTypesForScaleTransaction: [
		{ value: '', name: 'Select one' },
		{ value: 'INSTANT', name: 'Instant Discount' },
		{ value: 'CASHBACK', name: 'Cashback' },
		{ value: 'LOYALTY_POINTS', name: 'Loyalty Points' },
		{ value: 'FREE_TEXT', name: 'Free text' }
	],
	collectionValidity: [
		{ value: '', name: 'Select one' },
		{
			value: 'Date',
			name: 'Date'
		},
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Hours',
			name: 'Hours'
		}
	]
};

const priceMethodCodeDropdown = {
	INSTANT: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' },
		{ value: 'APPORTIONED_PERCENTAGE_OFF', name: 'Apportioned Percentage Off' },
		{ value: 'AMOUNT_OFF', name: 'Amount Off' },
		{ value: 'APPORTIONED_AMOUNT_OFF', name: 'Apportioned Amount Off' },
		{ value: 'MARKDOWN', name: 'Markdown' },
		{ value: 'KIT_MARKDOWN', name: 'Kit Markdown' }
	],
	CASHBACK: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' },
		{ value: 'APPORTIONED_PERCENTAGE_OFF', name: 'Apportioned Percentage Off' },
		{ value: 'AMOUNT_OFF', name: 'Amount Off' },
		{ value: 'APPORTIONED_AMOUNT_OFF', name: 'Apportioned Amount Off' }
	],
	LOYALTY_POINTS: [
		{ value: '', name: 'Select one' },
		{ value: 'LOYALTY_ADDITIONAL_POINTS', name: 'Loyalty Additional Points' },
		{ value: 'LOYALTY_POINTS_MULTIPLIER', name: 'Loyalty Multiplier Points' },
		{
			value: 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS',
			name: 'Apportioned Loyalty Additional Points on Points'
		},
		{
			value: 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE',
			name: 'Apportioned Loyalty Additional Points on Unit Price'
		},
		{
			value: 'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS',
			name: 'Apportioned Loyalty Multiplier on Points'
		},
		{
			value: 'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE',
			name: 'Apportioned Loyalty Multiplier on Unit Price'
		}
	],
	FREEBIE: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' },
		{ value: 'APPORTIONED_PERCENTAGE_OFF', name: 'Apportioned Percentage Off' }
	],
	EMI: [
		{ value: '', name: 'Select one' },
		{ value: 'EMI_PERCENTAGE_OFF', name: 'EMI Percentage Off' },
		{
			value: 'EMI_APPORTIONED_PERCENTAGE_OFF',
			name: 'EMI Apportioned Percentage off'
		}
	]
};

const localConst = {
	benefitType: 'Benefit Type',
	priceMethodCode: 'Price Modification Method Code',
	priceModificationPercent: 'Price Modification Percent',
	priceModificationAmount: 'Price Modification Amount',
	newPriceAmount: 'New Price Amount',
	subPriceModificationPercent: 'Determines the benefit percent for the promotion',
	provider: 'Provider',
	subProvider: 'Defines the Payment eligibility Provider for which the promotion is applicable',
	paymentType: 'Payment Type',
	subpaymentType: 'Defines the Payment eligibility Type for which the promotion is applicable',
	paymentSubType: 'Payment Sub Type',
	paymentMode: 'Payment Mode',
	subCashBackCreditByMonths:
		'Specifies the period the cashback will be credited to the customer in months post the transaction',
	subCashBackCreditByDays:
		'Specifies the period the cashback will be credited to the customer in days post the transaction',
	subCashBackCreditByHours:
		'Specifies the period the cashback will be credited to the customer in hours post the transaction',
	subCashBackCreditByDate:
		'Specifies the period the cashback will be credited to the customer post the transaction',
	subCashBackExpireOnMonths:
		'Specifies the period the cashback will be Expired to the customer in months post the transaction',
	subCashBackExpireOnDays:
		'Specifies the period the cashback will be Expired to the customer in days post the transaction',
	subCashBackExpireOnHours:
		'Specifies the period the cashback will be Expired to the customer in hours post the transaction',
	subCashBackExpireOnDate:
		'Specifies the period the cashback will be Expired to the customer post the transaction'
};

const BenefitSettingsSCALEPROD = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();
	// const fieldbenefit= field.value.groupMultibuyEligbility.benefit;
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const valueEligbility = props.customName;
	const fieldbenefit =
		valueEligbility == 'groupMultibuyEligbility'
			? field.value.groupMultibuyEligbility
			: valueEligbility == 'packageMultibuyEligibility'
			? field.value.packageMultibuyEligibility
			: valueEligbility == 'multibuyEligbility'
			? field.value.multibuyEligbility
			: valueEligbility == 'merchandizeHierarchyEligibility'
			? field.value.merchandizeHierarchyEligibility
			: valueEligbility == 'promotionalOfferReward.itemPriceDerivationRule'
			? field.value.promotionalOfferReward.itemPriceDerivationRule
			: valueEligbility == 'scaledPriceEligibility'
			? field.value.scaledPriceEligibility
			: undefined;
	const fieldPayment = field.value.scaledPriceEligibility.paymentEligibility;
	const customValidateCashBackCreditByDate = (value) => {
		let error;

		if (value) {
			if (
				moment(JSON.parse(props.fullValue).promotionPolicy.effectiveStartDate) >
				moment(value)
			) {
				error = 'Entered date should not be less than promotion start date';
			} else if (
				moment(JSON.parse(props.fullValue).promotionPolicy.effectiveEndDate) <
					moment(value) &&
				field.value.scaledPriceEligibility.benefitType == 'CASHBACK'
			) {
				error = 'Entered date should not be more than promotion end date';
			}
		} else {
			error = 'Please define the date value';
		}
		return error;
	};

	const customValidateCashBackExpireOnDate = (value) => {
		let error;

		if (value) {
			if (
				moment(field.value.scaledPriceEligibility.cashback?.creditBy?.date) > moment(value)
			) {
				error = 'Entered date should not be less than credited by date';
			} else if (
				moment(JSON.parse(props.fullValue).promotionPolicy.effectiveStartDate) >
				moment(value)
			) {
				error = 'Entered date should not be less than promotion start date';
			} else if (
				moment(JSON.parse(props.fullValue).promotionPolicy.effectiveEndDate) < moment(value)
			) {
				error = 'Entered date should not be more than promotion end date';
			}
		} else {
			error = 'Please define the date value';
		}
		return error;
	};

	const customValidateCashBackCreditByMonths = (value) => {
		let error;
		if (value) {
			/* if(!value.match(/^(?=.*[1-9])\d{0,3}$/)){
        error="Please enter a value greater than 1 to 999."
      } */
		} else {
			error = 'Please define the months value';
		}
		return error;
	};

	const customValidateCashBackCreditByDays = (value) => {
		let error;
		if (value) {
			/* if(!value.match(/^(?=.*[1-9])\d{0,3}$/)){
        error="Please enter a value greater than 1 to 999."
      } */
		} else {
			error = 'Please define the days value';
		}
		return error;
	};

	const customValidateCashBackCreditByHours = (value) => {
		let error;
		if (!value) {
			error = 'Please define the hours value';
		}
		return error;
	};

	const { benefitType, thresholdTypeCode, priceModificationMethodCode } =
		field.value?.scaledPriceEligibility || {};
	const { applicableOn } = field.value?.scaledPriceEligibility.paymentEligibility || {};

	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]
					?.benefitType != fieldbenefit.benefitType
			) {
				let tempName = valueEligbility + '.benefitType';
				$('[name="' + tempName + '"]')
					.closest('.container')
					.nextAll()
					.addClass('hide-nochange-section-for-audit');
			} else if (
				newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]
					?.priceModificationMethodCode != fieldbenefit.priceModificationMethodCode
			) {
				let tempName = valueEligbility + '.priceModificationMethodCode';
				$('[name="' + tempName + '"]')
					.closest('.container')
					.nextAll()
					.addClass('hide-nochange-section-for-audit');
			} else if (
				fieldbenefit.benefitType == 'CASHBACK' &&
				newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.cashback
					?.creditBy?.cashbackCreditData !=
					fieldbenefit?.cashback?.creditBy?.cashbackCreditData
			) {
				let tempName = valueEligbility + '.benefit.cashback.creditBy.cashbackCreditData';
				$('[name="' + tempName + '"]')
					.closest('.container')
					.next()
					.addClass('hide-nochange-section-for-audit');
			} else if (
				fieldbenefit.benefitType == 'CASHBACK' &&
				newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.cashback
					?.expireOn?.cashbackExpireData !=
					fieldbenefit?.cashback?.expireOn.cashbackExpireData
			) {
				let tempName = valueEligbility + '.cashback.expireOn.cashbackExpireData';
				$('[name="' + tempName + '"]')
					.closest('.container')
					.next()
					.addClass('hide-nochange-section-for-audit');
			} else if (
				fieldbenefit.benefitType == 'LOYALTY_POINTS' &&
				newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.loyalty
					?.expireOn?.loyaltyExpireData !=
					fieldbenefit?.loyalty?.expireOn?.loyaltyExpireData
			) {
				let tempName = valueEligbility + '.loyalty.expireOn.loyaltyExpireData';
				$('[name="' + tempName + '"]')
					.closest('.container')
					.next()
					.addClass('hide-nochange-section-for-audit');
			}
		}
	}, []);

	useEffect(() => {
		onProductTableChange([]);
	}, [benefitType, priceModificationMethodCode, thresholdTypeCode, applicableOn]);

	useEffect(() => {
		// if(!fieldbenefit.priceModificationMethodCode){
		//setFieldValue(`${valueEligbility}.priceModificationMethodCode`, "");
		// onProductTableChange([]);

		//  }
		if (fieldbenefit.benefitType == 'INSTANT') {
			if (
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_APPORTIONED_PERCENTAGE_OFF'
			) {
				setFieldValue(`${valueEligbility}.priceModificationMethodCode`, '');
			}
			setFieldValue(`${valueEligbility}.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.cashback`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextMessage`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextType`, undefined);
		}
		if (fieldbenefit.benefitType == 'CASHBACK') {
			if (
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_APPORTIONED_PERCENTAGE_OFF'
			) {
				setFieldValue(`${valueEligbility}.priceModificationMethodCode`, '');
			}
			setFieldValue(`${valueEligbility}.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextMessage`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextType`, undefined);
		}
		if (fieldbenefit.benefitType == 'LOYALTY_POINTS') {
			if (
				fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'AMOUNT_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'MARKDOWN' ||
				fieldbenefit.priceModificationMethodCode == 'KIT_MARKDOWN' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_APPORTIONED_PERCENTAGE_OFF'
			) {
				setFieldValue(`${valueEligbility}.priceModificationMethodCode`, '');
			}
			setFieldValue(`${valueEligbility}.cashback`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextMessage`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextType`, undefined);
		}

		if (fieldbenefit.benefitType == 'FREE_TEXT') {
			setFieldValue(`${valueEligbility}.cashback`, undefined);
			setFieldValue(`${valueEligbility}.loyalty`, undefined);
			//setFieldValue(`${valueEligbility}.priceModificationMethodCode`, "")
			setFieldValue(`${valueEligbility}.freeTextMessage`, '');
		}
	}, [fieldbenefit.benefitType]);

	useEffect(() => {
		if (fieldbenefit.benefitType == 'INSTANT') {
			if (fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF') {
				//setFieldValue("priceModificationPercent", "0.01");
			} else if (fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF') {
				// setFieldValue("priceModificationPercent", "0.01");
			} else if (fieldbenefit.priceModificationMethodCode == 'AMOUNT_OFF') {
				// setFieldValue("priceModificationAmount", "0.01");
			} else if (fieldbenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF') {
				//setFieldValue("priceModificationAmount", "0.01");
			} else if (fieldbenefit.priceModificationMethodCode == 'MARKDOWN') {
				// setFieldValue("newPriceAmount", "0.01");
			} else if (fieldbenefit.priceModificationMethodCode == 'KIT_MARKDOWN') {
				//setFieldValue("newPriceAmount", "0.01");
			}
			setFieldValue(`${valueEligbility}.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.cashback`, undefined);
		} else if (fieldbenefit.benefitType == 'CASHBACK') {
			if (fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF') {
				// setFieldValue("priceModificationPercent", "0.01");
			} else if (fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF') {
				// setFieldValue("priceModificationPercent", "0.01");
			} else if (fieldbenefit.priceModificationMethodCode == 'AMOUNT_OFF') {
				// setFieldValue("priceModificationAmount", "0.01");
			} else if (fieldbenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF') {
				//setFieldValue("priceModificationAmount", "0.01");
			}
			setFieldValue(`${valueEligbility}.loyalty`, undefined);
		} else if (fieldbenefit.benefitType == 'LOYALTY_POINTS') {
			// if (fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS') {
			if (
				fieldbenefit.loyalty == undefined ||
				fieldbenefit.loyalty?.baseEarnPointEligibility
			) {
				setFieldValue(`${valueEligbility}.loyalty.baseEarnPointEligibility`, true);
			}
			if (
				fieldbenefit.loyalty == undefined ||
				fieldbenefit.loyalty?.considerBaseRateForItem
			) {
				setFieldValue(`${valueEligbility}.loyalty.considerBaseRateForItem`, true);
			}
			// }
			if (
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE'
			) {
				//setFieldValue("loyalty.loyaltyAdditionalPoint", "0.01");
				//  setFieldValue("loyalty.loyaltyMultiplier", undefined);
			} else if (
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE'
			) {
				// setFieldValue("loyalty.loyaltyMultiplier", "1");
				// setFieldValue("loyalty.loyaltyAdditionalPoint", undefined);
			}
			setFieldValue(`${valueEligbility}.cashback`, undefined);
		} else if (fieldbenefit.benefitType == 'FREEBIE') {
			if (fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF') {
				// setFieldValue("priceModificationPercent", "100");
			} else if (fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF') {
				// setFieldValue("priceModificationPercent", "100");
			}
			setFieldValue(`${valueEligbility}.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.cashback`, undefined);
		} else if (fieldbenefit.benefitType == 'FREE_TEXT') {
			setFieldValue(`${valueEligbility}.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.cashback`, undefined);
		}
	}, [fieldbenefit.priceModificationMethodCode]);

	const getBenefitTypeDropdown = () => {
		{
			return valueEligbility == 'scaledPriceEligibility'
				? dropdownValues.benefitTypesForScaleTransaction.map((val, index) => {
						return (
							<option key={index + ''} value={val.value}>
								{val.name}
							</option>
						);
				  })
				: dropdownValues.benefitTypes.map((val, index) => {
						return (
							<option key={index + ''} value={val.value}>
								{val.name}
							</option>
						);
				  });
		}
	};

	const getCollectionValidityDropdown = () => {
		return dropdownValues.collectionValidity.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const getPriceMethodCodeDropdown = () => {
		if (fieldbenefit.benefitType == 'INSTANT') {
			return priceMethodCodeDropdown.INSTANT.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldbenefit.benefitType == 'CASHBACK') {
			return priceMethodCodeDropdown.CASHBACK.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldbenefit.benefitType == 'LOYALTY_POINTS') {
			return priceMethodCodeDropdown.LOYALTY_POINTS.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldbenefit.benefitType == 'FREEBIE') {
			return priceMethodCodeDropdown.FREEBIE.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldbenefit.benefitType == 'EMI') {
			return priceMethodCodeDropdown.EMI.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		}
	};

	const getContentBelowToBenefitType = () => {
		if (fieldbenefit.benefitType == 'INSTANT') {
			return (
				<>
					<CustomSelect
						name={`${valueEligbility}.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw ? (
								<>
									(For Instant Discount)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.InstantPMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldbenefit.benefitType == 'CASHBACK') {
			return (
				<>
					<CustomSelect
						name={`${valueEligbility}.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw ? (
								<>
									(For Cashback)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.CashBackPMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldbenefit.benefitType == 'LOYALTY_POINTS') {
			return (
				<>
					<CustomSelect
						name={`${valueEligbility}.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw ? (
								<>
									(For Loyalty Points)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.LoyaltyPMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldbenefit.benefitType == 'FREEBIE') {
			return (
				<>
					<CustomSelect
						name={`${valueEligbility}.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw ? (
								<>
									(For Freebies)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.FreeBiePMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldbenefit.benefitType == 'FREE_TEXT') {
			return (
				<>
					<CustomInputText
						name={`${valueEligbility}.freeTextType`}
						required={false}
						label={'Free Text Type'}
						smallText=""
					/>
					{valueEligbility != 'scaledPriceEligibility' ? (
						<CustomInputText
							name={`${valueEligbility}.FreeTextMessage`}
							required={false}
							label={'Free Text Message'}
							smallText=""
						/>
					) : undefined}
				</>
			);
		}
	};

	const getContentBelowToPMMC = () => {
		if (
			fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF' ||
			fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF'
		) {
			if (
				valueEligbility != 'scaledPriceEligibility' &&
				fieldbenefit.benefitType == 'INSTANT'
			) {
				return (
					<CustomInputText
						name={`${valueEligbility}.priceModificationPercent`}
						required={true}
						label={localConst.priceModificationPercent}
						smallText="Determines the benefit percent for the promotion"
						disabled={false}
					/>
				);
			} else if (fieldbenefit.benefitType == 'CASHBACK') {
				return (
					<>
						{valueEligbility != 'scaledPriceEligibility' ? (
							<CustomInputText
								name={`${valueEligbility}.priceModificationPercent`}
								required={true}
								label={localConst.priceModificationPercent}
								smallText="Determines the benefit percent for the promotion"
								disabled={false}
							/>
						) : undefined}
						{fieldPayment?.payment?.paymentGroup ? null : (
							<>
								<CustomInputText
									name={`${valueEligbility}.cashback.paymentInformation.paymentMode`}
									required={false}
									label={localConst.paymentMode}
									smallText="Defines the Payment eligibility Mode for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.cashback.paymentInformation.provider`}
									required={false}
									label={localConst.provider}
									smallText="Defines the Payment eligibility Provider for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.cashback.paymentInformation.paymentType`}
									required={false}
									label={localConst.paymentType}
									smallText="Defines the Payment eligibility Type for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.cashback.paymentInformation.paymentSubType`}
									required={false}
									label={localConst.paymentSubType}
									smallText="Defines the Payment eligibility SubType for which the promotion is applicable"
								/>

								<CustomSelect
									name={`${valueEligbility}.cashback.creditBy.cashbackCreditData`}
									required={false}
									label="Credit By"
									options={getCollectionValidityDropdown()}
								/>

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Date' ? (
									<>
										<CustomDateTime
											name={`${valueEligbility}.cashback.creditBy.date`}
											required={true}
											label="Date(yyyy-mm-dd)"
											smallText={localConst.subCashBackCreditByDate}
											validate={customValidateCashBackCreditByDate}
											valueDate={fieldbenefit.cashback.creditBy.date}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.date = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Months' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.cashback.creditBy.months`}
											required={true}
											label="Months"
											smallText={localConst.subCashBackCreditByMonths}
											validate={customValidateCashBackCreditByMonths}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.months = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Days' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.cashback.creditBy.days`}
											required={true}
											label="Days"
											smallText={localConst.subCashBackCreditByDays}
											validate={customValidateCashBackCreditByDays}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.days = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Hours' ? (
									<>
										<CustomHoursPicker
											name={`${valueEligbility}.cashback.creditBy.hours`}
											required={true}
											label="Hours(HH:mm)"
											smallText={localConst.subCashBackCreditByHours}
											validate={customValidateCashBackCreditByHours}
											valueHour={fieldbenefit.cashback.creditBy.hours}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.hours = undefined)
									) : null
								) : null}

								<CustomSelect
									name={`${valueEligbility}.cashback.expireOn.cashbackExpireData`}
									required={false}
									label="Expire On"
									options={getCollectionValidityDropdown()}
								/>

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Date' ? (
									<>
										<CustomDateTime
											name={`${valueEligbility}.cashback.expireOn.date`}
											required={true}
											label="Date(yyyy-mm-dd)"
											smallText={localConst.subCashBackExpireOnDate}
											validate={customValidateCashBackExpireOnDate}
											valueDate={fieldbenefit.cashback.expireOn.date}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.date = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Months' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.cashback.expireOn.months`}
											required={true}
											label="Months"
											smallText={localConst.subCashBackExpireOnMonths}
											validate={customValidateCashBackCreditByMonths}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.months = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Days' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.cashback.expireOn.days`}
											required={true}
											label="Days"
											smallText={localConst.subCashBackExpireOnDays}
											validate={customValidateCashBackCreditByDays}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.days = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Hours' ? (
									<>
										<CustomHoursPicker
											name={`${valueEligbility}.cashback.expireOn.hours`}
											required={true}
											label="Hours(HH:mm)"
											smallText={localConst.subCashBackExpireOnHours}
											validate={customValidateCashBackCreditByHours}
											valueHour={fieldbenefit.cashback.expireOn.hours}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.hours = undefined)
									) : null
								) : null}
							</>
						)}
					</>
				);
			} else if (fieldbenefit.benefitType == 'FREEBIE') {
				return (
					<CustomInputText
						name={`${valueEligbility}.priceModificationPercent`}
						required={true}
						label={localConst.priceModificationPercent}
						smallText="Determines the benefit percent for the promotion"
						disabled={false}
					/>
				);
			}
		} else {
			fieldbenefit.priceModificationPercent = undefined;
		}

		if (
			fieldbenefit.priceModificationMethodCode == 'AMOUNT_OFF' ||
			fieldbenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF'
		) {
			if (
				valueEligbility != 'scaledPriceEligibility' &&
				fieldbenefit.benefitType == 'INSTANT'
			) {
				return (
					<CustomInputText
						name={`${valueEligbility}.priceModificationAmount`}
						required={true}
						label={localConst.priceModificationAmount}
						smallText="Define the benefit amount for the promotion"
						disabled={false}
					/>
				);
			} else if (fieldbenefit.benefitType == 'CASHBACK') {
				return (
					<>
						{valueEligbility != 'scaledPriceEligibility' ? (
							<CustomInputText
								name={`${valueEligbility}.PriceModificationAmount`}
								required={true}
								label={localConst.PriceModificationAmount}
								smallText="Define the benefit amount for the promotion"
								disabled={false}
							/>
						) : undefined}
						{fieldPayment?.payment?.paymentGroup ? null : (
							<>
								<CustomInputText
									name={`${valueEligbility}.cashback.paymentInformation.paymentMode`}
									required={false}
									label={localConst.paymentMode}
									smallText="Defines the Payment eligibility Mode for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.cashback.paymentInformation.provider`}
									required={false}
									label={localConst.provider}
									smallText="Defines the Payment eligibility Provider for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.cashback.paymentInformation.paymentType`}
									required={false}
									label={localConst.paymentType}
									smallText="Defines the Payment eligibility Type for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.cashback.paymentInformation.paymentSubType`}
									required={false}
									label={localConst.paymentSubType}
									smallText="Defines the Payment eligibility SubType for which the promotion is applicable"
								/>

								<CustomSelect
									name={`${valueEligbility}.cashback.creditBy.cashbackCreditData`}
									required={false}
									label="Credit By"
									options={getCollectionValidityDropdown()}
								/>

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Date' ? (
									<>
										<CustomDateTime
											name={`${valueEligbility}.cashback.creditBy.date`}
											required={true}
											label="Date(yyyy-mm-dd)"
											validate={customValidateCashBackCreditByDate}
											valueDate={fieldbenefit.cashback.creditBy.date}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.date = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Months' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.cashback.creditBy.months`}
											required={true}
											label="Months"
											validate={customValidateCashBackCreditByMonths}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.months = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Days' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.cashback.creditBy.days`}
											required={true}
											label="Days"
											validate={customValidateCashBackCreditByDays}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.days = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Hours' ? (
									<>
										<CustomHoursPicker
											name={`${valueEligbility}.cashback.creditBy.hours`}
											required={true}
											label="Hours(HH:mm)"
											validate={customValidateCashBackCreditByHours}
											valueHour={fieldbenefit.cashback.creditBy.hours}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.hours = undefined)
									) : null
								) : null}

								<CustomSelect
									name={`${valueEligbility}.cashback.expireOn.cashbackExpireData`}
									required={false}
									label="Expire On"
									options={getCollectionValidityDropdown()}
								/>

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Date' ? (
									<>
										<CustomDateTime
											name={`${valueEligbility}.cashback.expireOn.date`}
											required={true}
											label="Date(yyyy-mm-dd)"
											validate={customValidateCashBackExpireOnDate}
											valueDate={fieldbenefit.cashback.expireOn.date}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.date = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Months' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.cashback.expireOn.months`}
											required={true}
											label="Months"
											validate={customValidateCashBackCreditByMonths}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.months = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Days' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.cashback.expireOn.days`}
											required={true}
											label="Days"
											validate={customValidateCashBackCreditByDays}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.days = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Hours' ? (
									<>
										<CustomHoursPicker
											name={`${valueEligbility}.cashback.expireOn.hours`}
											required={true}
											label="Hours(HH:mm)"
											validate={customValidateCashBackCreditByHours}
											valueHour={fieldbenefit.cashback.expireOn.hours}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.hours = undefined)
									) : null
								) : null}
							</>
						)}
					</>
				);
			}
		} else {
			fieldbenefit.priceModificationAmount = undefined;
		}

		if (
			valueEligbility != 'scaledPriceEligibility' &&
			(fieldbenefit.priceModificationMethodCode == 'MARKDOWN' ||
				fieldbenefit.priceModificationMethodCode == 'KIT_MARKDOWN')
		) {
			return (
				<CustomInputText
					name={`${valueEligbility}.newPriceAmount`}
					required={true}
					label={localConst.newPriceAmount}
					smallText="Determines the new price for the product/sku in the promotion"
					disabled={false}
				/>
			);
		} else {
			fieldbenefit.newPriceAmount = undefined;
		}

		if (
			fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
			fieldbenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
			fieldbenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE'
		) {
			return (
				<>
					{valueEligbility != 'scaledPriceEligibility' ? (
						<CustomInputText
							name={`${valueEligbility}.loyalty.loyaltyAdditionalPoint`}
							required={true}
							label={'Loyalty Additional Point'}
							smallText="Determines the additional point as benefit for the promotion"
						/>
					) : undefined}
					<CustomInputRadio
						name={`${valueEligbility}.loyalty.baseEarnPointEligibility`}
						label={'Apply Base Earn'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={
							'Determines whether the Base earn Point should be applied for the promotion'
						}
					/>
					<CustomInputRadio
						name={`${valueEligbility}.loyalty.considerBaseRateForItem`}
						label={'Consider Base Rate For Item'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={'Determines whether to Consider Base Rate For Item'}
					/>

					<CustomSelect
						name={`${valueEligbility}.loyalty.expireOn.loyaltyExpireData`}
						required={false}
						label="Expire On"
						options={getCollectionValidityDropdown()}
					/>

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Date' ? (
						<>
							<CustomDateTime
								name={`${valueEligbility}.loyalty.expireOn.date`}
								required={true}
								label="Date(yyyy-mm-dd)"
								validate={customValidateCashBackCreditByDate}
								valueDate={fieldbenefit.loyalty.expireOn.date}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.date = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Months' ? (
						<>
							<CustomInputText
								name={`${valueEligbility}.loyalty.expireOn.months`}
								required={true}
								label="Months"
								validate={customValidateCashBackCreditByMonths}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.months = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Days' ? (
						<>
							<CustomInputText
								name={`${valueEligbility}.loyalty.expireOn.days`}
								required={true}
								label="Days"
								validate={customValidateCashBackCreditByDays}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.days = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Hours' ? (
						<>
							<CustomHoursPicker
								name={`${valueEligbility}.loyalty.expireOn.hours`}
								required={true}
								label="Hours(HH:mm)"
								validate={customValidateCashBackCreditByHours}
								valueHour={fieldbenefit.loyalty.expireOn.hours}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.hours = undefined)
						) : null
					) : null}
				</>
			);
		} else {
			fieldbenefit.newPriceAmount = undefined;
		}

		if (
			fieldbenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
			fieldbenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
			fieldbenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE'
		) {
			return (
				<>
					{valueEligbility != 'scaledPriceEligibility' ? (
						<CustomInputText
							name={`${valueEligbility}.loyalty.loyaltyMultiplier`}
							required={true}
							label={'Loyalty Multiplier Point'}
							smallText="Determines the multiplier point as benefit for the promotion"
						/>
					) : undefined}
					<CustomInputRadio
						name={`${valueEligbility}.loyalty.baseEarnPointEligibility`}
						label={'Apply Base Earn'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={
							'Determines whether the Base earn Point should be applied for the promotion'
						}
					/>
					<CustomInputRadio
						name={`${valueEligbility}.loyalty.considerBaseRateForItem`}
						label={'Consider Base Rate For Item'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={'Determines whether to Consider Base Rate For Item'}
					/>

					<CustomSelect
						name={`${valueEligbility}.loyalty.expireOn.loyaltyExpireData`}
						required={false}
						label="Expire On"
						options={getCollectionValidityDropdown()}
					/>

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Date' ? (
						<>
							<CustomDateTime
								name={`${valueEligbility}.loyalty.expireOn.date`}
								required={true}
								label="Date(yyyy-mm-dd)"
								validate={customValidateCashBackCreditByDate}
								valueDate={fieldbenefit.loyalty.expireOn.date}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.date = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Months' ? (
						<>
							<CustomInputText
								name={`${valueEligbility}.loyalty.expireOn.months`}
								required={true}
								label="Months"
								validate={customValidateCashBackCreditByMonths}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.months = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Days' ? (
						<>
							<CustomInputText
								name={`${valueEligbility}.loyalty.expireOn.days`}
								required={true}
								label="Days"
								validate={customValidateCashBackCreditByDays}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.days = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Hours' ? (
						<>
							<CustomHoursPicker
								name={`${valueEligbility}.loyalty.expireOn.hours`}
								required={true}
								label="Hours(HH:mm)"
								validate={customValidateCashBackCreditByHours}
								valueHour={fieldbenefit.loyalty.expireOn.hours}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.hours = undefined)
						) : null
					) : null}
				</>
			);
		} else {
			fieldbenefit.newPriceAmount = undefined;
		}
	};

	const onProductTableChange = (val) => {
		props.onProductTableChanged(val);
	};

	return (
		<div>
			<Form>
				<CustomSelect
					name={`${valueEligbility}.benefitType`}
					required={true}
					label={localConst.benefitType}
					options={getBenefitTypeDropdown()}
					smallText={itemHelp.BenifitTypeMBG}
				/>

				{getContentBelowToBenefitType()}
				{valueEligbility == 'scaledPriceEligibility' ? (
					isAuditFlw ? (
						field.value?.scaledPriceEligibility?.priceModificationMethodCode ==
						JSON.parse(sessionStorage.getItem('NewPolicy')).promotionPolicy
							.priceDerivationRuleEligibility.scaledPriceEligibility
							?.priceModificationMethodCode ? (
							<div className="container">
								<AuditScaleTable
									oldJson={props.data1}
									newJson={
										JSON.parse(sessionStorage.getItem('NewPolicy'))
											.promotionPolicy.priceDerivationRuleEligibility
											.scaledPriceEligibility.scale
									}
									data1={props.data1}
									onProductTableChange={onProductTableChange}
								/>
							</div>
						) : undefined
					) : field.value?.scaledPriceEligibility?.priceModificationMethodCode ? (
						<ScaleProductTableBootstrapSPSSH
							data={props.data1}
							keys={keys}
							defaultValueForAdd={defaultValueForAddMG}
							onProductTableChange={onProductTableChange}
							eligibility={'ScaleTransaction'}
						/>
					) : field.value.scaledPriceEligibility?.benefitType == 'FREE_TEXT' ? (
						<ScaleProductTableBootstrapSPSSH
							data={props.data1}
							keys={keys}
							defaultValueForAdd={defaultValueForAddMG}
							onProductTableChange={onProductTableChange}
							eligibility={'ScaleTransaction'}
						/>
					) : undefined
				) : undefined}
			</Form>
		</div>
	);
};

/* const BenefitSettingsFrom = withSubForm(
    BenefitSettings,
    benefitSchema
); */

export default BenefitSettingsSCALEPROD;
