import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
import CustomInputText2 from './customfields/CustomInputText2';

// Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
//   return this.test('unique', message, function (list) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
	return this.test('unique', message, function (list) {
		if (!list) list = [];
		return list.length === new Set(list.map(mapper)).size;
	});
});

const EmiTable = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	const { setFieldValue } = useFormikContext();
	const filednamepmtelg = props.filednamepmtelg;
	const fieldEmiScale =
		props.emiScaleType == 'emiScaleAdded'
			? filednamepmtelg.payment?.emi?.emiScaleAdded
			: props.emiScaleType == 'emiScaleRemoved'
			? filednamepmtelg.payment?.emi?.emiScaleRemoved
			: filednamepmtelg.payment?.emi?.emiScale;
	const valueEligbility = props.valueEligbility;

	const customValidateTenure = (value) => {
		let error;

		if (value) {
			let tenureArray = fieldEmiScale.map((val) => val.tenurePeriodInMonths);

			var count = tenureArray.reduce(function (n, val) {
				return n + (val === value);
			}, 0);
			if (count > 1) error = 'Tenure Period In Months cannot be same';
		}
		return error;
	};
	const customValidateInterestRate = (value) => {
		let error;
		if (value) {
			let interestArray = fieldEmiScale.map((val) => val.interestRate);

			var count = interestArray.reduce(function (n, val) {
				return n + (val === value);
			}, 0);
			if (count > 1) error = 'Interest Rate cannot be same';
		}
		return error;
	};
	useEffect(() => {}, []);

	return (
		<>
			<table className="mt-10 bg-white">
				<tr>
					<th>Tenure Period In Months</th>
					<th>Interest Rate</th>
					<th>Amount Capping</th>
					<th>
						<button
							className="addPlusBtn"
							type="button"
							onClick={() => {
								filednamepmtelg.payment.emi &&
									fieldEmiScale.push({
										tenurePeriodInMonths: '',
										interestRate: '',
										cappedAmount: JSON.parse(props.fullVal).promotionPolicy
											.rewardLimit
											? JSON.parse(props.fullVal).promotionPolicy.rewardLimit
											: ''
									});
								setFieldValue('', '');
							}}
						></button>
					</th>
				</tr>

				<FieldArray
					name={`${valueEligbility}.paymentEligibility.payment.emi.${props.emiScaleType}`}
				>
					{({ remove }) => (
						<>
							{filednamepmtelg.payment.emi &&
								fieldEmiScale &&
								fieldEmiScale.map((item, index) => (
									<tr key={index} className="payment-Conditions">
										<td>
											<div className="col-md-12 pb-3">
												{/* <label htmlFor={`multibuyProduct.${index}.productID`}>Name</label> */}
												{/* <CustomInputText name={`payment.emi.${props.emiScaleType}.${index}.tenurePeriodInMonths`} required={false} label=""
                                                            /> */}
												{/* <Field
                          className="form-control m-2"
                          name={`${valueEligbility}.paymentEligibility.payment.emi.${props.emiScaleType}.${index}.tenurePeriodInMonths`}
                          type="text"
                          validate={customValidateTenure}
                        /> */}
												<CustomInputText2
													name={`${valueEligbility}.paymentEligibility.payment.emi.${props.emiScaleType}.${index}.tenurePeriodInMonths`}
													type="text"
													validate={customValidateTenure}
												/>
												{/* <ErrorMessage
                          name={`${valueEligbility}.paymentEligibility.payment.emi.${props.emiScaleType}.${index}.tenurePeriodInMonths`}
                          component="div"
                          className="field-error"
                        /> */}
											</div>
										</td>

										<td>
											{/* <CustomInputText name={`payment.emi.${props.emiScaleType}.${index}.interestRate`} required={false} label=""
                                                        /> */}
											<div className="col-md-12 pb-3">
												{/* <Field
                          className="form-control m-2"
                          name={`${valueEligbility}.paymentEligibility.payment.emi.${props.emiScaleType}.${index}.interestRate`}
                          type="text"
                          validate={customValidateInterestRate}
                        /> */}
												<CustomInputText2
													name={`${valueEligbility}.paymentEligibility.payment.emi.${props.emiScaleType}.${index}.interestRate`}
													type="text"
													validate={customValidateInterestRate}
												/>
												{/* <ErrorMessage
                          name={`${valueEligbility}.paymentEligibility.payment.emi.${props.emiScaleType}.${index}.interestRate`}
                          component="div"
                          className="field-error"
                        /> */}
											</div>
										</td>
										<td>
											<div className="col-md-12 pb-3">
												{/* <Field
                          className="form-control m-2"
                          name={`${valueEligbility}.paymentEligibility.payment.emi.${props.emiScaleType}.${index}.cappedAmount`}
                          type="text"
                          disabled={JSON.parse(props.fullVal).promotionPolicy.rewardLimit}
                        /> */}
												<CustomInputText2
													name={`${valueEligbility}.paymentEligibility.payment.emi.${props.emiScaleType}.${index}.cappedAmount`}
													type="text"
													disabled={
														JSON.parse(props.fullVal).promotionPolicy
															.rewardLimit
													}
												/>
												{/* <ErrorMessage
                          name={`${valueEligbility}.paymentEligibility.payment.emi.${props.emiScaleType}.${index}.cappedAmount`}
                          component="div"
                          className="field-error"
                        /> */}
											</div>
										</td>
										<td>
											<div className="col">
												<button
													type="button"
													// className="secondary"
													className="addDeleteBtn"
													onClick={() => remove(index)}
												></button>
											</div>
										</td>
									</tr>
								))}
						</>
					)}
				</FieldArray>
			</table>
		</>
	);
};

export default EmiTable;
