const appConstants = {
	COUPON: {
		ALL: 'ALL',
		GENERIC: 'GENERIC',
		TARGETED: 'TARGETED',
		PERSONALIZED: 'PERSONALIZED',
		VIEW: 'VIEW',
		GENERIC_LIMITED: 'GENERIC_LIMITED',
		GENERIC_UNLIMITED: 'GENERIC_UNLIMITED',
		GENERIC__UNLIMITED: 'GENERIC UNLIMITED',
		GENERIC_UNLIMITED_COUPON_DATA: 'GENERIC_UNLIMITED_COUPON_DATA'
	},
	couponSubType: {
		MULTIUSE: 'MULTIUSE',
		SINGLEUSE: 'SINGLEUSE'
	},
	SEARCH: 'SEARCH',
	PREVIOUS: 'PREVIOUS',
	NEXT: 'NEXT',
	translatePromoType: {
		MULTIBUYPRODUCT: 'Multibuy Product',
		MULTIBUYGROUP: 'Multibuy Group',
		MERCHANDIZE: 'Selling Hierarchy',
		PACKAGEPRODUCT: 'Package Product',
		MULTIBUYPACKAGE: 'Package',
		PACKAGEMERCHANDIZE: 'Package Selling Hierarchy',
		SCALEPRODUCT: 'Scale Product',
		SCALEMERCHANDIZE: 'Scale Selling Hierarchy',
		TXN: 'Transaction',
		SCALETXN: 'Transaction Scale',
		MTMB: 'Multi Target Multi Benefit',
		EARN_BURN_PRODUCT: 'Earn Burn Product',
		EARN_BURN_MERCHANDIZE: 'Earn Burn Selling Hierarchy',
		MTMB_PRODUCT: 'Multi Target Multi Benefit Product',
		MTMB_MERCHANDIZE: 'Multi Target Multi Benefit Selling Hierarchy',
		SIMPLE: 'Simple',
		EARN_BURN: 'Earn Burn',
		MULTIBUYSCALE: 'Scale'
	},
	translateBenefitType: {
		INSTANT: 'Instant Discount',
		CASHBACK: 'Cashback',
		LOYALTY_POINTS: 'Loyalty Points',
		EARN_BURN: 'Earn Burn',
		EMI: 'EMI',
		FREEBIE: 'Freebies',
		FREE_TEXT: 'Free text'
	},
	CS_benefitType: {
		NeuCoins: 'Neu Coins',
		Discount: 'Discount',
		Cashback: 'Cashback',
		Coupon: 'Coupon'
	},
	genre: {
		'In-Market': 'In Market',
		'Category-led': 'Category led',
		'Platform level': 'Platform level'
	},
	eventType: {
		GENERATE_COUPON: 'GENERATE_COUPON',
		CUSTOM_COUPON: 'CUSTOM_COUPON',
		UPLOAD_PROMOTION: 'UPLOAD PROMOTION',
		UPDATE_PROMOTION: 'UPDATE PROMOTION',
		DOWNLOAD_OFFERS: 'DOWNLOAD_OFFERS',
		DOWNLOAD_COUPONS: 'DOWNLOAD_COUPONS',
		PROMO_CUSTOMER_UPDATE: 'PROMO_CUSTOMER_UPDATE',
		AUTOMATIC_COUPON_EXPIRY: 'AUTOMATIC_COUPON_EXPIRY',
		CUSTOMERHASH_COUPON_MAP: 'CUSTOMERHASH_COUPON_MAP',
		COUPON_UPLOAD: 'COUPON_UPLOAD',
		UPLOAD_PROMOTIONS_LITE: 'UPLOAD_PROMOTIONS_LITE'
	},
	defaultCouponLimit: 7500000,
	multiuseCouponRedemptionLimit: 999999,
	singleuseCouponRedemptionLimit: 5,
	generateCouponMask: '+',
	buBrand: {
		CROMA: {
			BUID: 101,
			name: 'CROMA'
		},
		TSSS: {
			BUID: 125,
			name: 'TSSS'
		},
		CUSTOMER_SERVICE: {
			BUID: 127,
			name: 'CUSTOMER SERVICE'
		},
		NEUPASS: {
			BUID: 128,
			name: 'Neupass'
		},
		GEOFENCED: {
			BUID: 129,
			name: 'Geofenced Coupons'
		}
	}
};

export default appConstants;
