import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import './AuditTable.css';
class BinVersionsTable extends Component {
	constructor() {
		super();
		this.state = {
			// For displaying data
			columns: [
				{
					dataField: 'versionId',
					text: '',
					sort: true,
					headerAlign: 'center',
					headerFormatter: this.headerFormatter,
					formatter: this.versionFormatter
				},
				// {
				//     dataField: "action",
				//     text: "",
				//     sort: true,
				//     headerAlign: 'center',
				//     formatter:this.actionFormatter
				// },
				{
					dataField: 'modifiedBy',
					text: '',
					headerAlign: 'center',
					// filter: textFilter({
					//     delay: 500, // default is 500ms
					//     style: {
					//     //   backgroundColor: 'yellow'
					//     },
					//     className: 'test-classname',
					//     placeholder: 'Search Modified By',
					//     onClick: e =>
					//   }),
					formatter: this.modifiedByFormatter
				},
				{
					dataField: 'modifiedOn',
					text: '',
					headerAlign: 'center',
					formatter: this.dateFormatter
					// filter: dateFilter({
					//     defaultValue: {comparator: Comparator.GT}
					//     })
					// filter: dateFilter({
					//     delay: 400,
					//     placeholder: 'Search Date',
					//     withoutEmptyComparatorOption: true,
					//     comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
					//     style: { display: 'inline-grid' },
					//     className: 'custom-datefilter-class',
					//     comparatorStyle: { backgroundColor: 'antiquewhite' },
					//     comparatorClassName: 'custom-comparator-class',
					//     dateStyle: { backgroundColor: 'cadetblue', margin: '0px' },
					//     dateClassName: 'custom-date-class'
					//   }),
					// filter:customFilter({
					//     type: FILTER_TYPES.DATE
					//   }),
					//   filterRenderer: (onFilter, column) =>
					//   <DateFilter onFilter={ onFilter } column={ column } />
				},
				{
					dataField: 'Action',
					text: '',
					formatter: this.linkAction,
					sort: true,
					headerAlign: 'center'
				}
			]
			// vesrionsForSelectedPromo: [
			//     {
			//         "action": "CREATED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "101",
			//         "promotionVersion": 1
			//     },
			//     {
			//         "action": "MODIFIED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "102",
			//         "promotionVersion": 2
			//     },
			//     {
			//         "action": "CREATED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "101",
			//         "promotionVersion": 3
			//     },
			//     {
			//         "action": "MODIFIED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "102",
			//         "promotionVersion": 4
			//     },
			//     {
			//         "action": "MODIFIED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "102",
			//         "promotionVersion": 5
			//     },
			//     {
			//         "action": "MODIFIED",
			//         "modifiedDate": "2021-02-11 15:17:35",
			//         "modifiedBy": "102",
			//         "promotionVersion": 6
			//     }
			// ]
		};
	}

	headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
		let a = column.text;
		if (a) {
			return (
				<div>
					<div>
						{sortElement}
						{filterElement}
					</div>
					<div>
						<b> {a} </b>
					</div>
				</div>
			);
		}
	};

	linkAction = (cell, row) => {
		return (
			<>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<button
						type="button"
						className=""
						onClick={() => {
							this.props.viewBins(row.versionId, 'Search');
						}}
					>
						View
					</button>
				</div>
			</>
		);
	};
	modifiedByFormatter(cell, row) {
		return (
			<div>
				<div className="cols">Modified By</div>
				<div> {cell}</div>
			</div>
		);
	}
	dateFormatter(cell, row) {
		return (
			<div>
				<div className="cols">Date</div>
				<div> {cell}</div>
			</div>
		);
	}
	actionFormatter(cell) {
		return (
			<div>
				<div className="cols">Action</div>
				<div> {cell}</div>
			</div>
		);
	}
	versionFormatter(cell, row) {
		return (
			<div className="d-flex pt-2">
				<span className="cols pr-1">
					<h6>Version </h6>
				</span>
				<span className="">
					{' '}
					<h6> {cell}</h6>
				</span>
			</div>
		);
	}

	render() {
		const options = {
			// pageStartIndex: 0,
			sizePerPage: 5,
			hideSizePerPage: true,
			hidePageListOnlyOnePage: true
		};

		return (
			<>
				<div className="p-4">
					<div className="row pt-3 pb-1">
						<div className="d-flex ml-3">
							<span>
								<div className="roboto-m-16 font-weight-bold">
									BIN Revision History
								</div>
							</span>
						</div>
					</div>
					<div className="version_table w-75">
						<BootstrapTable
							keyField="promotionVersion"
							data={this.props.binVersions}
							columns={this.state.columns}
							pagination={paginationFactory(options)}
							filter={filterFactory()}
							headerClasses="d-none"
						/>
					</div>
				</div>
			</>
		);
	}
}

export default BinVersionsTable;
