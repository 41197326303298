import _ from 'lodash';
import { postData } from '../libraries/offer-services';
import * as urlTypes from '../libraries/offer-service-urls';
import appConfig from './appConfig';
import Toaster from '../Components/Toaster/Toaster';
import appLabels from './appLabels';

export function isObjectEmpty(obj) {
	for (let prop in obj) {
		if (obj.hasOwnProperty(prop)) {
			return false;
		}
	}

	return JSON.stringify(obj) === JSON.stringify({});
}

export function trimDate(coupon, parentKey, childKey) {
	if (Array.isArray(coupon)) {
		return coupon.map((val) => {
			const expireOn = _.omit(val[parentKey], [childKey]);
			return { ...val, expireOn };
		});
	} else {
		const expireOn = _.omit(coupon[parentKey], [childKey]);
		let couponTemp = { ...coupon };
		couponTemp[parentKey] = expireOn;
		return couponTemp;
	}
}

export function appendDate(data, parentKey, childKey) {
	if (Array.isArray(data)) {
		const tempCoupon = data.map((val) => {
			if (val[parentKey].date != undefined) {
				return { ...val, [parentKey]: { ...val[parentKey], [childKey]: 'Date' } };
			} else if (val[parentKey].hours != undefined) {
				return { ...val, [parentKey]: { ...val[parentKey], [childKey]: 'Hours' } };
			} else if (val[parentKey].months != undefined) {
				return { ...val, [parentKey]: { ...val[parentKey], [childKey]: 'Months' } };
			} else if (val[parentKey].years != undefined) {
				return { ...val, [parentKey]: { ...val[parentKey], [childKey]: 'Years' } };
			} else if (val[parentKey].days != undefined) {
				return { ...val, [parentKey]: { ...val[parentKey], [childKey]: 'Days' } };
			} else {
				return val;
			}
		});
		return tempCoupon;
	} else {
		let tempCoupon;
		if (data[parentKey]?.date != undefined) {
			tempCoupon = { ...data, [parentKey]: { ...data[parentKey], [childKey]: 'Date' } };
		} else if (data[parentKey]?.hours != undefined) {
			tempCoupon = { ...data, [parentKey]: { ...data[parentKey], [childKey]: 'Hours' } };
		} else if (data[parentKey]?.months != undefined) {
			tempCoupon = { ...data, [parentKey]: { ...data[parentKey], [childKey]: 'Months' } };
		} else if (data[parentKey]?.years != undefined) {
			tempCoupon = { ...data, [parentKey]: { ...data[parentKey], [childKey]: 'Years' } };
		} else if (data[parentKey]?.days != undefined) {
			return { ...data, [parentKey]: { ...data[parentKey], [childKey]: 'Days' } };
		} else {
			tempCoupon = data;
		}
		return tempCoupon;
	}
}

export function getOfferFlowType() {
	let text = '';

	switch (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).type) {
		case 'modify-offer-flow':
			text = 'Modify Offer';
			break;
		case 'replicate-offer-flow':
			text = 'Replicate Offer';
			break;
		case 'view-offer-flow':
			text = 'View Offer';
			break;
		default:
			text = 'Create Offer';
	}

	return text;
}

const decimalRegex = /^\d+(\.\d{1,2})?$/;

export function validateMinOverMax(newValue, row, column) {
	if (isNaN(newValue)) {
		return {
			valid: false,
			message: 'Price should be numeric.'
		};
	} else if (!decimalRegex.test(parseFloat(newValue))) {
		return {
			valid: false,
			message: 'Only two decimal places allowed.'
		};
	} else if (parseFloat(newValue) > parseFloat(row.maximumPrice)) {
		return {
			valid: false,
			message: 'Entered minimum unit price value should less than maximum unit price '
		};
	} else if (parseFloat(newValue) < 0.01 || parseFloat(newValue) > 9999999.99) {
		return {
			valid: false,
			message: 'Minimum Unit Price must be between 0.01 to 9999999.99'
		};
	}
	return true;
}

export function validateMaxOverMin(newValue, row, column) {
	if (isNaN(newValue)) {
		return {
			valid: false,
			message: 'Price should be numeric'
		};
	} else if (!decimalRegex.test(parseFloat(newValue))) {
		return {
			valid: false,
			message: 'Only two decimal places allowed.'
		};
	} else if (parseFloat(newValue) < parseFloat(row.minimumPrice)) {
		return {
			valid: false,
			message: 'Entered maximum unit price value should be greater than minimum unit price '
		};
	} else if (parseFloat(newValue) < 0.01 || parseFloat(newValue) > 9999999.99) {
		return {
			valid: false,
			message: 'Maximum Unit Price must be between 0.01 to 9999999.99'
		};
	}

	return true;
}

export function validateCSV(keys, data) {
	let err = 'CORRECT';
	let csvData = data;
	//var keyNames = Object.keys(csvData[0]);
	const keyNames = JSON.parse(JSON.stringify(csvData[0].data));
	const keysConfgrd = JSON.parse(JSON.stringify(keys));

	if (
		keyNames.length != keysConfgrd.length ||
		JSON.stringify(keyNames.sort()) != JSON.stringify(keysConfgrd.sort())
	) {
		//err = `The uploaded CSV format is incorrect. The colum headers must be: ${keys.join(', ')}`;
		err = keys.join(', ');
	}

	return err;
}

export function validateMaxOverMinCsv(newValue, row) {
	if (newValue && isNaN(newValue)) {
		return false;
	} else if (newValue && !decimalRegex.test(parseFloat(newValue))) {
		return false;
	} else if (parseFloat(newValue) < parseFloat(row.minimumPrice)) {
		return false;
	} else if (parseFloat(newValue) < 0.01 || parseFloat(newValue) > 9999999.99) {
		return false;
	}

	return true;
}

export function validateMinOverMaxCsv(newValue, row) {
	if (newValue && isNaN(newValue)) {
		return false;
	} else if (newValue && !decimalRegex.test(parseFloat(newValue))) {
		return false;
	} else if (parseFloat(newValue) > parseFloat(row.maximumPrice)) {
		return false;
	} else if (parseFloat(newValue) < 0.01 || parseFloat(newValue) > 9999999.99) {
		return false;
	}

	return true;
}

export function is_boolean(newValue) {
	if (newValue == 'true') {
		return false;
	} else if (newValue == 'false') {
		return false;
	}

	/*if( newValue === true || newValue === false || toString.call(newValue) === '[object Boolean]'){
	  return false;
	}*/

	return true;
}
export const rowClasses = (row, rowIndex) => {
	let classes = null;

	if (row?.trigger?.toString() == 'false' && row?.getReward?.toString() == 'false') {
		classes = 'errorRow';
	}

	return classes;
};

/**
 * @description: use to clear the user session token after logout
 */
export const clearUserSession = () => {
	clearInterval(window.tokenTimer);
	sessionStorage.clear(); /* remove all the session storage */
	// 	sessionStorage.removeItem('tokens');
	// 	sessionStorage.removeItem('loggedInUserId');
	// 	sessionStorage.removeItem('taggedBrandToLoggedInUser');
	// 	sessionStorage.removeItem('refreshToken');
	// 	sessionStorage.removeItem('brandEligibilityBrand');
};

/**
 * @description: invokeRefreshTokenTimer will initiate after login success
 * and clear when userSession clear
 */
window.tokenTimer = null;
export const invokeRefreshTokenTimer = () => {
	window.tokenTimer = setInterval(() => {
		if (sessionStorage.getItem('tokens') === null) {
			return;
		}
		var dataToSend = {
			accessTokenRequest: {
				operatorId: sessionStorage.getItem('loggedInUserId'),
				grantType: 'refresh_token'
			}
		};

		postData(urlTypes.user_maintenance.refreshToken, dataToSend)
			.then((response) => {
				sessionStorage.setItem(
					'tokens',
					JSON.stringify(response.data.OutboundJaxrsResponse?.entity?.token?.accessToken)
				);
			})
			.catch((error) => {
				console.error(error);
				Toaster({ message: appLabels.errorMsg.refreshTokenError });
			});
	}, appConfig.refreshTokenTimer);
};

/* convert base64 to Blob to download file */
export const base64toBlob = (base64Data, contentType) => {
	contentType = contentType || '';
	var sliceSize = 1024;
	var byteCharacters = atob(base64Data);
	var bytesLength = byteCharacters.length;
	var slicesCount = Math.ceil(bytesLength / sliceSize);
	var byteArrays = new Array(slicesCount);

	for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		var begin = sliceIndex * sliceSize;
		var end = Math.min(begin + sliceSize, bytesLength);

		var bytes = new Array(end - begin);
		for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes);
	}
	return new Blob(byteArrays, { type: contentType });
};

/* download file api */
export const downloadFile = (result, fileName) => {
	let link = document.createElement('a');
	document.body.appendChild(link);
	link.style = 'display: none';
	const blob = base64toBlob(result, 'application/vnd.ms-excel');
	const url = window.URL.createObjectURL(blob);
	link.href = url;
	// File for the download
	link.download = fileName;
	link.click();
	window.URL.revokeObjectURL(url);
	link.remove();
};

/**
 * @description use this function to replace the part of string
 * @param {String} txt
 * @param {*} identifier
 * @param  {...Object} args
 * @returns {String}
 * @example
 * stringReplacer('hello @name @surname I am happy to see you', {'@name': 'Kavi','@surname': 'Saxena' });
 * return 'hello Kavi Saxena I am happy to see you
 * stringReplacer('hello @name. I am happy to see you @name', '@name', 'Kavi');
 * return 'hello Kavi. I am happy to see you Kavi
 * stringReplacer('hello @name. I am happy to see you @name', '@name', 'Kavi', 'Saxena');
 * return 'hello Kavi. I am happy to see you Saxena
 */
export const stringReplacer = (txt, identifier, ...args) => {
	let finalTxt = `${txt}`;
	if (typeof identifier === 'object') {
		let regExp = '';
		for (const key in identifier) {
			regExp = new RegExp(`${key}`, 'g');
			finalTxt = finalTxt.replace(regExp, identifier[key]);
		}
	} else {
		const regExp = (identifier && new RegExp(`${identifier}`, 'g')) || null;
		if (args.length === 1) {
			finalTxt = finalTxt.replace(regExp, args[0]);
		} else if (args.length > 1) {
			args.forEach((arg, i) => {
				finalTxt = finalTxt.replace(`${identifier}`, arg);
			});
		}
	}
	return finalTxt;
};

/**
 * @description converts a word to uppercase if the business unit does not
 * require case-sensitive search as per appConfig.caseSensitiveSearch
 * @param {object}
 * @param {number} businessUnit businessUnitGroupFunctionID
 * @param {string} word businessUnitGroupFunctionID
 * @returns string converted word
 */

export const convertCaseSensitiveSearch = ({ businessUnit, word = '' }) => {
	const isCaseSensitive = appConfig.caseSensitiveSearch.includes(businessUnit);
	return isCaseSensitive ? word : word.toUpperCase();
};
