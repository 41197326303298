import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './input.scss';
import _eyeIcon from '../../assets/images/eyeiconOff.png';

const Input = (props) => {
	const [elmConfig, setElmConfig] = useState({
		...props.elementConfig
	});
	// const value = props.elementType === 'checkbox' ? props.checked : props.value;
	const [inputVal, setInputVal] = useState('');

	const changeHandler = (event) => {
		const { target, type } = event;
		const value = type === 'checkbox' ? target.checked : target.value;
		let rules = props.shouldValidate;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value.trim() !== '';
				isValid.message = isValid.value ? '' : 'Field required';
			}

			if (rules.minLength) {
				isValid.value = value.length >= rules.minLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Minimum length is ' + rules.minLength;
			}

			if (rules.maxLength) {
				isValid.value = value.length <= rules.maxLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Maximum length is ' + rules.maxLength;
			}
		}
		setInputVal(value);
		props.changed(event, isValid);
	};

	const inputElement = () => {
		let inputElement = null;
		const inputClasses = ['InputElement', props.elementClass];

		if (props.invalid && props.shouldValidate && props.touched) {
			inputClasses.push('Invalid');
		}

		switch (props.elementType) {
			case 'input':
				inputElement = (
					<input
						className={inputClasses.join(' ')}
						{...elmConfig}
						{...props.attrValidation}
						value={props.value}
						onChange={changeHandler}
					/>
				);
				break;
			case 'textarea':
				inputClasses.push('pt-3');
				inputElement = (
					<textarea
						className={inputClasses.join(' ')}
						{...elmConfig}
						{...props.attrValidation}
						value={props.value}
						onChange={changeHandler}
					/>
				);
				break;
			case 'select':
				inputElement = (
					<select
						className={inputClasses.join(' ')}
						value={props.value}
						onChange={changeHandler}
					>
						{elmConfig.options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.displayValue}
							</option>
						))}
					</select>
				);
				break;
			case 'checkbox':
				/* todo: checkbox Warning: changing a controlled input to be uncontrolled */
				inputElement = (
					<input
						className={inputClasses.join(' ')}
						{...elmConfig}
						{...props.attrValidation}
						value={props.value}
						checked={props.checked}
						onChange={changeHandler}
					/>
				);
				break;
			default:
				inputElement = (
					<input
						className={inputClasses.join(' ')}
						{...elmConfig}
						value={props.value}
						onChange={changeHandler}
					/>
				);
		}

		return (
			<>
				<label className="label-through" htmlFor={elmConfig?.id}>
					{elmConfig.labelname}
					{props.shouldValidate?.required ? (
						<span className="text-danger"> * </span>
					) : null}
				</label>
				{inputElement}
			</>
		);
	};

	const eyeIcon = () => {
		if (!props.togglePassword) {
			return null;
		}
		const isInputEmpty = !inputVal;
		return (
			<span
				className={`eyeIconLogin ${!isInputEmpty ? 'enable' : ''}`}
				onMouseDown={() => {
					if (!isInputEmpty) {
						const config = { ...elmConfig, type: 'text' };
						setElmConfig(config);
					}
				}}
				onMouseUp={() => {
					if (!isInputEmpty) {
						const config = { ...elmConfig, type: 'password' };
						setElmConfig(config);
					}
				}}
				onMouseOut={() => {
					if (!isInputEmpty) {
						const config = { ...elmConfig, type: 'password' };
						setElmConfig(config);
					}
				}}
			>
				<img className="imageEyeClass1" src={_eyeIcon} alt="img" />
			</span>
		);
	};

	const wrapperClasses = () => {
		const { customClasses, elementType } = props;

		const inputType = elementType === 'input' ? 'text' : elementType;
		const _classes = `input-${inputType} ${customClasses}`;
		return _classes;
	};

	return (
		<div className={`Input ${wrapperClasses()}`}>
			{inputElement()}
			{eyeIcon()}
		</div>
	);
};

Input.defaultProps = {
	customClasses: ''
};

Input.propTypes = {
	elementType: PropTypes.string,
	shouldValidate: PropTypes.shape({
		required: PropTypes.bool,
		minLength: PropTypes.number,
		maxLength: PropTypes.number
	}),
	invalid: PropTypes.bool,
	touched: PropTypes.bool,
	changed: PropTypes.func,
	elementConfig: PropTypes.shape({
		type: PropTypes.string,
		placeholder: PropTypes.string,
		labelname: PropTypes.string,
		options: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string,
				displayValue: PropTypes.string
			})
		)
	}),
	attrValidation: PropTypes.shape({}),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	togglePassword: PropTypes.bool,
	customClasses: PropTypes.string,

	/* not used in Input component */
	valid: PropTypes.bool,
	validationMessage: PropTypes.string
};
export default Input;
