import React, { useState } from 'react';
// import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './couponTable.scss';
// import ToolkitProvider, { Search } from '../../utils/reactBootstrapTable2Toolkit';
import CONSTANT from '../../utils/appConstants';
import ModalPopup from '../../Components/ModalPopup/ModalPopup';
import { postData } from '../../libraries/offer-services';
import ReactTable, {
	// filterFactory,
	headerFormatter,
	textFilter,
	selectFilter
} from '../../Components/ReactTable/ReactTable';
import { coupon_maintenance as urlTypes } from '../../libraries/offer-service-urls';
import appLabels from '../../utils/appLabels';

// const { SearchBar } = Search;

const {
	COUPON: { GENERIC, TARGETED, PERSONALIZED, VIEW },
	PREVIOUS,
	NEXT
} = CONSTANT;

const {
	page: { couponManagement: pageLabel },
	table: tableLabel,
	common: commonLabel
} = appLabels;

const getTableData = (data) => {
	const columns = [
		{
			dataField: 'srNo',
			text: tableLabel.sNo,
			headerAlign: 'center',
			headerStyle: {
				width: '70px'
			},
			align: 'center',
			sort: true
		},
		{
			dataField: 'customerHash',
			text: tableLabel.customerHash,
			headerAlign: 'center',
			sort: false,
			filter: textFilter(),
			headerFormatter,
			formatter: (value, row, index) => {
				return value ?? '-';
			}
		},
		{
			dataField: 'isRedeem',
			text: tableLabel.transactionType,
			headerAlign: 'center',
			sort: true,
			headerFormatter,
			filter: selectFilter({
				placeholder: pageLabel.transactionFilterTxt,
				className: 'customSelectIcon',
				options: {
					true: commonLabel.redeem,
					false: commonLabel.unredeem
				}
			}),
			formatter: (value, row, index) =>
				value === false ? commonLabel.unredeem : commonLabel.redeem
		},
		{
			dataField: 'couponRedeemDate',
			text: tableLabel.transactionDate,
			headerAlign: 'center',
			sort: true
		},
		{
			dataField: 'couponRedeemCount',
			text: tableLabel.redeemedCount,
			headerAlign: 'center',
			align: 'center',
			sort: true
		}
	];

	return {
		columns,
		data,
		keyField: 'srNo',
		noDataInfo: pageLabel.redemptionEmptyMsg,
		paginationEnable: true,
		sizePerPageList: [
			{ text: 10, value: 10 },
			{ text: 20, value: 20 },
			{ text: 50, value: 50 }
		]
	};
};

const RedeemLauncher = ({
	text,
	showLoadingButton,
	promotionDetails = null,
	couponDetails = null
}) => {
	const [redeemData, setRedeemData] = useState([]);
	const [showModal, setShowModal] = useState(false);

	const { promotionId, endDate: promotionEndDate } = promotionDetails || {};

	const { couponType, couponId, couponEndDate } = couponDetails || {};

	const getRedeemData = () => {
		showLoadingButton && showLoadingButton(true);
		postData(urlTypes.getCouponRedeemDetails, {
			getCouponRedeemDetailsRequest: {
				promotionId,
				couponId
			}
		})
			.then((res) => {
				const { GetCouponRedeemDetailsResponse: { couponDataList = [] } = {} } =
					res?.data || {};

				couponDataList?.length && setRedeemData(couponDataList);
				setShowModal(true);
			})
			.catch((error) => {
				console.error(error);
				setRedeemData([]);
			})
			.finally(() => {
				showLoadingButton && showLoadingButton(false);
			});
	};

	return (
		<>
			<button
				type="button"
				className="btn btn-link redeem-btn-link"
				onClick={() => {
					getRedeemData();
				}}
			>
				{text}
			</button>
			<ModalPopup
				title={pageLabel.modalPopupTitle}
				closeHandler={() => {
					setRedeemData([]);
					setShowModal(false);
				}}
				showModal={showModal}
				customClasses="coupon-table-modal"
				size="lg"
			>
				<>
					<div className="coupon-details">
						<div className="d-flex mb-2 ">
							<div className="pr-2">
								<span className="cGray mr-1">{commonLabel.promotionId}: </span>
								<span className="font-weight-bold">{promotionId}</span>
							</div>
							<div className="">
								<span className="cGray mr-1">{commonLabel.promotionEndDate}: </span>
								<span className="font-weight-bold">{promotionEndDate}</span>
							</div>
						</div>
						<div className="d-flex mb-2">
							<div className="pr-2">
								<span className="cGray mr-1">{commonLabel.couponId}: </span>
								<span className="font-weight-bold">{couponId}</span>
							</div>
							<div className="pr-2">
								<span className="cGray mr-1">{commonLabel.couponType}: </span>
								<span className="font-weight-bold">{couponType}</span>
							</div>
							<div className="">
								<span className="cGray mr-1">{commonLabel.couponExpiryDate}: </span>
								<span className="font-weight-bold">{couponEndDate}</span>
							</div>
						</div>
						<div className="d-flex justify-content-end mb-2">
							<div className="pr-2">
								<span className="cGray mr-1">{tableLabel.redeemedCount}: </span>
								<span className="font-weight-bold">{text}</span>
							</div>
						</div>
					</div>
					{redeemData && <ReactTable {...getTableData(redeemData)} />}
				</>
			</ModalPopup>
		</>
	);
};

const formatYesNo = (value, row, index) => {
	return value ? 'Y' : 'N';
};

const redeemedFormatter = ({ value, row, index, showLoadingButton, clickedPromotion }) => {
	const text = value ?? 0;
	return (
		<RedeemLauncher
			text={text}
			showLoadingButton={showLoadingButton}
			promotionDetails={clickedPromotion}
			couponDetails={row}
		/>
	);
};

const getColumns = ({
	couponType = null,
	couponActionFormatter,
	showLoadingButton,
	couponActionType,
	clickedPromotion,
	isTSSSBu
}) => {
	const {
		COUPON: { GENERIC, TARGETED, PERSONALIZED, VIEW }
	} = CONSTANT;

	const columnsForSearchedCoupon = [
		{
			dataField: 'promotionId',
			text: tableLabel.promotionId,
			headerAlign: 'center',
			sort: true
		},
		{
			dataField: 'endDate',
			text: tableLabel.promotionEndDateAndTime,
			headerAlign: 'center',
			searchable: false
		},
		{
			dataField: 'promotionStatus',
			text: tableLabel.status,
			headerAlign: 'center',
			searchable: false
		},
		{
			dataField: 'displayCoupons',
			text: tableLabel.allowCouponToBeDisplayed,
			headerAlign: 'center',
			searchable: false,
			formatter: formatYesNo
		},
		{
			dataField: 'couponCount',
			text: tableLabel.couponCount,
			headerAlign: 'center',
			align: 'right',
			searchable: false
		},
		{
			dataField: '',
			text: tableLabel.actions,
			headerAlign: 'center',
			formatter: (cell, row) => couponActionFormatter(cell, row, couponType),
			headerStyle: { width: '70px' },
			searchable: false
		}
	];

	if([TARGETED, PERSONALIZED].includes(couponType)) {
		/* Only for Targeted and Personalized not for GENERIC */
		const additionalCol = [{
			dataField: 'customerMappedCount',
			text: tableLabel.customerMappedCount,
			headerAlign: 'center',
			align: 'right',
			searchable: false
		}]
		columnsForSearchedCoupon.splice(columnsForSearchedCoupon.length -1, 0, ...additionalCol);
	}

	const columnsForViewCoupon = [
		{
			dataField: 'couponId',
			text: tableLabel.couponId,
			headerAlign: 'center',
			sort: true
		},
		{
			dataField: 'couponStatus',
			text: tableLabel.couponStatus,
			headerAlign: 'center',
			searchable: false,
			formatter: (value, row, index) => {
				let valueDescription = commonLabel.active;
				if(value === 'E') {
					valueDescription = commonLabel.expired;
				} 
				else if (value === 'I') {
					valueDescription = commonLabel.inactive;
				}
				return valueDescription;
			}
		},
		{
			dataField: 'couponEndDate',
			text: tableLabel.couponExpiryDateAndTime,
			headerAlign: 'center',
			searchable: false
		},
		{
			dataField: 'couponLimit',
			text: tableLabel.couponLimit,
			headerAlign: 'center',
			align: 'right',
			searchable: false
		},

		{
			dataField: 'couponRedemptionLimit',
			text: tableLabel.couponRedeemedCount,
			headerAlign: 'center',
			align: 'right',
			formatter: (value, row, index) =>
				redeemedFormatter({ value, row, index, showLoadingButton, clickedPromotion }),
			searchable: false
		}
	];


	if(isTSSSBu){
		const tsssColumns = [{
			dataField: 'couponNotMappedToCustomerCount',
			text: tableLabel.couponNotMappedToCustomerCount,
			headerAlign: 'center',
			align: 'right',
			searchable: false,
			formatter: (value, row, index) =>  (value ?? '-')
		},
		{
			dataField: 'totalCouponRedeemCount',
			text: tableLabel.totalCouponRedeemCount,
			headerAlign: 'center',
			align: 'right',
			searchable: false,
			formatter: (value, row, index) =>  (value ?? '-')
		}];
		columnsForSearchedCoupon.splice(columnsForSearchedCoupon.length -1, 0, ...tsssColumns);
	};

	if (couponType === VIEW) {
		/* Only for Targeted and Personalized not for GENERIC */
		if (couponActionType !== GENERIC) {
			const additionalCol = {
				dataField: 'customerHash',
				text: tableLabel.customerHash,
				headerAlign: 'center',
				sort: false,
				formatter: (value, row, index) => {
					return value ?? '-';
				}
			};
			columnsForViewCoupon.splice(1, 0, additionalCol);
		}
	}

	switch (couponType) {
		case GENERIC:
		case TARGETED:
		case PERSONALIZED:
			return columnsForSearchedCoupon;
		case VIEW:
			return columnsForViewCoupon;
		default:
			return columnsForViewCoupon;
	}
};

const TableBottomBtn = ({
	pageNumber,
	couponType,
	onPageChange,
	gotoCouponSearch,
	downloadCoupon
}) => (
	<div className="d-flex">
		<div
			style={{
				justifySelf: 'center',
				flex: 1,
				textAlign: 'center',
				marginTop: '20px'
			}}
		>
			<button
				className={`btn btn-primary m-2 ${pageNumber === 1 ? 'disabled' : ''}`}
				onClick={() => onPageChange(couponType, PREVIOUS)}
			>
				{commonLabel.previous}
			</button>
			<div className="btn btn-primary m-2">{pageNumber}</div>
			<button className="btn btn-primary m-2" onClick={() => onPageChange(couponType, NEXT)}>
				{commonLabel.next}
			</button>
		</div>
		{couponType === VIEW && (
			<div
				style={{
					marginTop: '20px'
				}}
				className="mr-4"
			>
				<button
					className="cancel-btn1 roboto-b-16 "
					onClick={() => {
						gotoCouponSearch();
					}}
				>
					{commonLabel.cancel}
				</button>
			</div>
		)}

		<div
			style={{
				marginTop: '20px'
			}}
		>
			<button
				type="button"
				className="cancel-btn1 roboto-b-16"
				onClick={() => downloadCoupon(couponType)}
			>
				{commonLabel.download}
			</button>
		</div>
	</div>
);

const CouponTable = (props) => {
	const {
		data,
		keyField = 'promotionId',
		placeholder,
		pageNumber,
		onPageChange,
		couponType,
		gotoCouponSearch,
		downloadCoupon,
		selectedGeneric,
		onSelectedGenericChange,
		selectedTargeted,
		onSelectedTargetedChange,
		selectedPersonalized,
		onSelectedPersonalizedChange,
		couponActionFormatter,
		showLoadingButton,
		couponActionType,
		clickedPromotion,
		isTSSSBu
	} = props;

	const columns = getColumns({
		couponType,
		couponActionFormatter,
		showLoadingButton,
		couponActionType,
		clickedPromotion,
		isTSSSBu
	});

	const selectRow = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			let newSelectedProducts = [...selectedGeneric];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row?.promotionId);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.promotionId);
			}
			onSelectedGenericChange(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			let newSelectedProducts = [...selectedGeneric];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.promotionId;
				});
			}
			onSelectedGenericChange(newSelectedProducts);
		}
	};

	const selectRowTargeted = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			let newSelectedProducts = [...selectedTargeted];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row?.promotionId);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.promotionId);
			}
			onSelectedTargetedChange(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			let newSelectedProducts = [...selectedTargeted];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.promotionId;
				});
			}
			onSelectedTargetedChange(newSelectedProducts);
		}
	};

	const selectRowPersonalized = {
		mode: 'checkbox',
		onSelect: (row, isSelect) => {
			let newSelectedProducts = [...selectedPersonalized];
			if (!isSelect) {
				const removeIndex = newSelectedProducts.indexOf(row?.promotionId);
				newSelectedProducts.splice(removeIndex, 1);
			} else {
				newSelectedProducts.push(row.promotionId);
			}
			onSelectedPersonalizedChange(newSelectedProducts);
		},
		onSelectAll: (isSelect, rows) => {
			let newSelectedProducts = [...selectedPersonalized];

			if (!isSelect) {
				newSelectedProducts = [];
			} else {
				newSelectedProducts = rows.map((val) => {
					return val.promotionId;
				});
			}
			onSelectedPersonalizedChange(newSelectedProducts);
		}
	};

	return (
		<>
			<ReactTable
				customClasses="couponTable table-padding"
				columns={columns}
				data={data}
				keyField={keyField}
				searchBarEnable={couponType !== VIEW}
				searchOptions={{ placeholder: placeholder }}
				selectRowEnable={couponType !== VIEW}
				selectedRow={
					couponType === GENERIC
						? selectRow
						: couponType === TARGETED
						? selectRowTargeted
						: couponType === PERSONALIZED
						? selectRowPersonalized
						: null
				}
				tableCondensed={false}
			>
				<TableBottomBtn
					pageNumber={pageNumber}
					couponType={couponType}
					onPageChange={onPageChange}
					gotoCouponSearch={gotoCouponSearch}
					downloadCoupon={downloadCoupon}
					noDataInfo={tableLabel.noDataInfo}
				/>
			</ReactTable>
			{/* todo: remove below comment code after testing */}
			{/* <ToolkitProvider keyField={keyField} data={data} columns={columns} search>
				{(props) => (
					<div style={{ padding: '0px 15px 15px 15px' }} className="couponTable">
						{couponType !== VIEW && (<SearchBar {...props.searchProps} placeholder={placeholder} />)}
						<BootstrapTable
							{...props.baseProps}
							keyField={keyField}
							noDataIndication={() => <div>No Data available</div>}
							filter={filterFactory()}
							selectRow={
								couponType === GENERIC
									? selectRow
									: couponType === TARGETED
										? selectRowTargeted
										: couponType === PERSONALIZED
											? selectRowPersonalized
											: undefined
							}
						/>
						<TableBottomBtn
							pageNumber={pageNumber}
							couponType={couponType}
							onPageChange={onPageChange}
							gotoCouponSearch={gotoCouponSearch}
							downloadCoupon={downloadCoupon}
						/>
					</div>
				)}
			</ToolkitProvider> */}
		</>
	);
};

CouponTable.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})),
	keyField: PropTypes.string,
	placeholder: PropTypes.string,
	couponType: PropTypes.string,
	pageNumber: PropTypes.number,
	onPageChange: PropTypes.func,
	gotoCouponSearch: PropTypes.func,
	downloadCoupon: PropTypes.func,
	onSelectedGenericChange: PropTypes.func,
	onSelectedTargetedChange: PropTypes.func,
	selectedGeneric: PropTypes.arrayOf(PropTypes.string),
	selectedTargeted: PropTypes.arrayOf(PropTypes.string),
	selectedPersonalized: PropTypes.arrayOf(PropTypes.string),
	onSelectedPersonalizedChange: PropTypes.func,
	couponActionFormatter: PropTypes.func,
	showLoadingButton: PropTypes.func,
	couponActionType: PropTypes.string,
	isTSSSBu: PropTypes.bool
};

export default CouponTable;
