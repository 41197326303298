import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Button } from 'react-bootstrap';
import './AuditTable.css';
// const products = [
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 1", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 5", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 5", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 5", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 5", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 4", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },
//     {
//         "Offer Tittle": "Item 2", "Offer version": 2,
//         "Offer Type": "simple", "Last Updated By": 109
//     },

// ];

// const products=[{
//     "promotionId": "12345678911:MG",
//     "status": "Active",
//     "description": "Buy 2 Get 1 Free",
//     "title": "Summer Sale",
//     "modifiedDate": "2021-02-11 15:17:35",
//     "modifiedBy": "101",
//     "promotionVersion": 2
// },
// {
//     "promotionId": "12345678911:TestMG",
//     "status": "Active",
//     "description": "Get 20% off",
//     "title": "Christmas Offer",
//     "modifiedDate": "2021-02-11 15:17:35",
//     "modifiedBy": "101",
//     "promotionVersion": 2
// }];
class AuditTable extends Component {
	constructor() {
		super();
		function getPromotionName(value, row, index) {
			if (value == 'MERCHANDIZE') {
				return 'Selling Hierarchy';
			} else if (value == 'MULTIBUYPRODUCT') {
				return 'Multibuy Product';
			} else if (value == 'MULTIBUYGROUP') {
				return 'Multibuy Group';
			} else if (value == 'MULTIBUYPACKAGE') {
				return 'Package';
			} else if (value == 'MULTIBUYSCALE') {
				return 'Scale';
			} else if (value == 'TXN') {
				return 'Transaction';
			} else if (value == 'SCALETXN') {
				return 'Transaction Scale';
			} else if (value == 'MTMB') {
				return 'Multi Target Multi Benefit';
			} else if (value == 'EARN_BURN') {
				return 'Earn Burn';
			} else if (value == 'SIMPLE') {
				return 'Simple';
			} else {
				return value;
			}
		}
		this.state = {
			// For displaying data
			columns: [
				{
					dataField: 'promotionId',
					text: 'Promotion ID',
					sort: true,
					headerAlign: 'center'
				},
				{
					dataField: 'promotionTitle',
					text: 'Promotion Title',
					sort: true,
					headerAlign: 'center'
				},
				{
					dataField: 'promotionType',
					text: 'Promotion Type',
					headerAlign: 'center',
					formatter: getPromotionName
				},
				{
					dataField: 'modifiedBy',
					text: 'Modified By',
					headerAlign: 'center'
				},
				{
					dataField: 'modifiedDateAndTime',
					text: 'Modified Date & Time',
					headerAlign: 'center'
				},
				{
					dataField: 'versionNumber',
					text: 'Current Promotion Version',
					headerAlign: 'center'
				},
				{
					dataField: 'Action',
					text: 'Action',
					classes: (cell, row, rowIndex, colIndex) => {
						// if (row.versionNumber  == 1) return 'disabled';
						return '';
					},
					formatter: this.linkAction,
					sort: true,
					headerAlign: 'center'
				}
			],
			isFollow: true
		};

		this.onFollowChanged.bind(this);
	}

	onFollowChanged(row) {
		// alert(JSON.stringify(row))
		this.props.getPromoChangeHistory(row);
		this.props.toggleShowSelectedPromo();
	}
	onFollowChanged1() {
		alert('clicked1');
	}

	linkAction = (cell, row, rowIndex, formatExtraData) => {
		return (
			<>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<button
						className={'viewBtn btn'}
						onClick={() => {
							this.onFollowChanged(row);
						}}
					></button>
					{/*  <Button
                        onClick={() => {
                            this.onFollowChanged1(row);
                        }}
                    >
                        Follow1
      </Button> */}
				</div>
			</>
		);
	};
	linkExport = (cell, row, rowIndex, formatExtraData) => {
		return (
			<>
				<div style={{ display: 'flex' }}>
					<Button
						onClick={() => {
							this.onFollowChanged(row);
						}}
					>
						Export
					</Button>
					{/*  <Button
                        onClick={() => {
                            this.onFollowChanged1(row);
                        }}
                    >
                        Follow1
      </Button> */}
				</div>
			</>
		);
	};

	render() {
		return (
			<>
				<div className="p-2 AuditSearchTable">
					<div className="d-flex pt-3 pb-1">
						<div className="font-weight-bold pl-3">Search Results</div>
						<div className="cGray pl-3">
							{this.props.promotionDetails.length} Promotions Found.
						</div>
					</div>
					<div className="">
						<BootstrapTable
							keyField="promotionId"
							noDataIndication={() => <div>No Promotion Found</div>}
							data={this.props.promotionDetails}
							columns={this.state.columns}
							/*  filter={filterFactory()} */
							// pagination={paginationFactory()}
						/>
					</div>
					<div
						className={
							this.props.pageNumber == 1 &&
							this.props.promotionDetails != undefined &&
							this.props?.promotionDetails.length == 1
								? 'd-none'
								: 'd-flex'
						}
					>
						<div
							style={{
								justifySelf: 'center',
								flex: 1,
								textAlign: 'center',
								marginTop: '20px'
							}}
						>
							<button
								className={
									this.props.pageNumber == 1
										? 'btn btn-primary m-2 disabled'
										: 'btn btn-primary m-2'
								}
								onClick={() => this.props.onPageChange(this.props.type, 'Previous')}
							>
								Previous
							</button>
							<div className="btn btn-primary m-2">{this.props.pageNumber}</div>
							<button
								className={
									this.props.maxPage == this.props.pageNumber
										? 'disabled btn btn-primary m-2'
										: 'btn btn-primary m-2'
								}
								onClick={() => this.props.onPageChange(this.props.type, 'Next')}
							>
								Next
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default AuditTable;
