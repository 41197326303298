import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import viewIcon from '../../assets/images/view.png';

import appLabels from '../../utils/appLabels';
import ReactTable, { headerFormatter, selectFilter } from '../../Components/ReactTable/ReactTable';
import appConstants from '../../utils/appConstants';

const {
	common: commonLabels,
	table: tableLabels,
	page: { generateCoupon: pageLabels }
} = appLabels;

const OfferTable = ({
	customClasses = '',
	offers,
	generateCouponActionHandler,
	viewCouponsActionHandler
}) => {
	if (offers.length === 0) {
		return null;
	}
	const getTableData = () => {
		const data = [...offers];

		const dateFormater = ({ value, row, index }) => {
			return moment(value).format('DD-MM-YYYY HH:mm');
		};

		const actionFormatter = ({ value, row, index }) => {
			return (
				<div className="action-container">
					<button
						className="addPlusBtn btn btn-link p-0 btn-icon"
						title={pageLabels.actionBtnGenerateText}
						onClick={() => {
							generateCouponActionHandler(row);
						}}
					></button>
					<button
						className="btn btn-link p-0 btn-icon"
						title={pageLabels.actionBtnViewCouponText}
						onClick={() => viewCouponsActionHandler?.(row)}
					>
						<img
							alt={pageLabels.actionBtnViewCouponText}
							src={viewIcon}
							className="highlight"
						/>
					</button>
				</div>
			);
		};

		const columns = [
			{
				dataField: 'promotionId',
				text: tableLabels.promotionId,
				headerAlign: 'center',
				formatter: (value, row, index) => value.split(':')[1]
			},
			{
				dataField: 'status',
				text: commonLabels.status,
				headerAlign: 'center',
				searchable: false,
				headerStyle: {
					width: '65px'
				},
				style: {
					width: '65px'
				}
			},
			{
				dataField: 'promotionType',
				text: tableLabels.promotionType,
				searchable: false,
				headerAlign: 'center',
				formatter: (value, row, index) => appConstants.translatePromoType[value]
			},
			{
				dataField: 'title',
				text: tableLabels.title,
				searchable: false,
				headerAlign: 'center',
				formatter: (value, row, index) => value || '-'
			},
			{
				dataField: 'benefitType',
				text: tableLabels.benefitType,
				searchable: false,
				headerAlign: 'center',
				formatter: (value, row, index) => appConstants.translateBenefitType[value]
			},
			{
				dataField: 'startDate',
				text: tableLabels.startDateTime,
				searchable: false,
				headerAlign: 'center',
				headerStyle: {
					width: '130px'
				},
				formatter: (value, row, index) =>
					dateFormater({
						value,
						row,
						index
					})
			},
			{
				dataField: 'endDate',
				text: tableLabels.endDateTime,
				searchable: false,
				headerAlign: 'center',
				headerStyle: {
					width: '130px'
				},
				formatter: (value, row, index) =>
					dateFormater({
						value,
						row,
						index
					})
			},
			{
				dataField: 'couponType',
				text: tableLabels.couponType,
				searchable: false,
				headerAlign: 'center',
				headerStyle: {
					width: '130px'
				},
				formatter: (value, row, index) => value || '-'
			},
			{
				dataField: 'displayCoupons',
				text: tableLabels.displayCoupons,
				headerAlign: 'center',
				align: 'center',
				headerStyle: {
					width: '190px'
				},
				formatter: (value, row, index) => (value === true ? 'Y' : 'N'),
				headerFormatter,
				headerClasses: 'display-coupons-col',
				filter: selectFilter({
					placeholder: tableLabels.displayCoupons,
					className: 'customSelectIcon',
					options: {
						true: `${tableLabels.displayCoupons} Y`,
						false: `${tableLabels.displayCoupons} N`
					}
				})
			},
			{
				isDummyField: true,
				dataField: 'actionColumn',
				text: tableLabels.action,
				searchable: false,
				headerAlign: 'center',
				align: 'center',
				headerStyle: {
					width: '55px'
				},
				formatter: (value, row, index) =>
					actionFormatter({
						value,
						row,
						index
					})
			}
		];

		return {
			columns: [...columns],
			data,
			keyField: 'promotionId',
			noDataInfo: commonLabels.nothingToShow,
			paginationEnable: true,
			customClasses: `offers-table ${customClasses}`,
			searchBarEnable: true,
			searchOptions: {
				placeholder: pageLabels.searchOptionsPlaceHolder
			}
		};
	};
	return <ReactTable {...getTableData()} />;
};

OfferTable.propTypes = {
	customClasses: PropTypes.string,
	offers: PropTypes.arrayOf(PropTypes.shape({})),
	generateCouponActionHandler: PropTypes.func,
	viewCouponsActionHandler: PropTypes.func
};

export default OfferTable;
