import axios from 'axios';
import Toaster from '../Components/Toaster/Toaster';
import appLabels from '../utils/appLabels';
import { clearUserSession } from '../utils/helperFunction';
import * as urlTypes from './offer-service-urls';

const { errorMsg } = appLabels;

const instance = axios.create({
	baseURL: ''
});

instance.interceptors.request.use(
	(request) => {
		const head = request.headers || {};
		let _headers = {
			Accept: 'application/json',
			'Content-type': 'application/json',
			'Accept-Language': 'en-US',
			'Base-Locale': 'en-US',
			Authorization: sessionStorage.tokens || null,
			'Access-Control-Allow-Origin': '*',
			...head
		};

		if (
			request.url.includes('/rest/offers/uploadCoupons') ||
			request.url.includes('/rest/offers/configurator/bulkModifyPromotionDuration') ||
			request.url.includes('/rest/offers/uploadBinRange') ||
			request.url.includes('/rest/offers/appendBinRange') ||
			request.url.includes('/rest/configurator/customer/uploadCustomer')
		) {
			_headers = {
				..._headers,
				'Content-type': 'multipart/form-data'
			};
		} else if (request.url.includes('/auth/v1/performEnterpriseLogin')) {
			_headers = {
				..._headers,
				Accept: '*/*'
			};

			delete sessionStorage.authData;
		} else if (request.url.includes(urlTypes.user_maintenance.refreshToken)) {
			_headers = {
				..._headers,
				Accept: '*/*',
				refreshToken: `Bearer ${sessionStorage.refreshToken}`
			};
		}

		request.headers = { ..._headers };

		return request;
	},
	(error) => {
		console.error(error);
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const { response: { status, data } = {} } = error;
		let msg = errorMsg.wentWrong;
		if (status === 401) {
			msg = `${data?.statusCode || status} : ${
				data?.errorDescription || errorMsg.unauthorized
			}`;
			clearUserSession();
		}
		Toaster({ message: msg });
		return Promise.reject(error);
	}
);

export default instance;
