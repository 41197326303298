import React from 'react';

export const CheckBox = (props) => {
	return (
		<li className="p-2">
			<input
				id={props.resourceID}
				key={props.resourceID}
				onChange={props.handleCheckFieldElement}
				type="checkbox"
				checked={props.isAssigned}
				value={props.description}
			/>
			<label htmlFor={props.resourceID} className="pl-3">
				{props.description}
				<span className="checkmark"></span>
			</label>
		</li>
	);
};

export default CheckBox;
