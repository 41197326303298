import React, { useEffect } from 'react';

import { useField } from 'formik';

import CustomInputText from '../customfields/CustomInputText';
import CustomSelect from '../customfields/CustomSelect';

function ConditionalRedering(props) {
	const [field] = useField(props);

	const localConst = {
		eligibilitySettings: 'Eligibility Settings',
		thresholdCode: 'Threshold Type Code',
		triggerCombinationCode: 'Trigger Combination Code',
		subTriggerCombinationCode:
			'Determines the combination code to be used between the trigger items for a promotion',
		thresholdQuantity: 'Threshold Quantity',
		thresholdAmount: 'Threshold Amount',
		rewardCombinationCode: 'Reward Combination Code',
		subRewardCombinationCode:
			'Determines the combination code to be used between the reward item for a promotion',
		rewardQuantity: 'Reward Quantity',
		benefitSettings: 'Benefit Settings',
		ProductSetting: 'Product Settings',
		quantityLimit: 'Quantity Limit',
		groupMaximumThresholdAmount: 'Maximum Threshold Amount',
		groupMaximumThresholdQuantity: 'Maximum Threshold Quantity'
	};

	const rewardCombinationDropdown = {
		promotionTypeOr: [
			{
				value: 'OR',
				name: 'OR'
			}
		],
		promotionTypeOrAndEqual: [
			{
				value: 'OR',
				name: 'OR'
			},
			{
				value: 'AND',
				name: 'AND'
			},
			{
				value: 'EQUAL',
				name: 'EQUAL'
			}
		],
		promotionTypeOrAnd: [
			{
				value: 'OR',
				name: 'OR'
			},
			{
				value: 'AND',
				name: 'AND'
			}
		],
		operator: [
			{
				value: '',
				name: 'Select one'
			},
			{
				value: '<',
				name: '<'
			},
			{
				value: '>',
				name: '>'
			},
			{
				value: '<=',
				name: '<='
			},
			{
				value: '>=',
				name: '>='
			},
			{
				value: '=',
				name: '='
			},
			{
				value: 'BETWEEN',
				name: 'BETWEEN'
			}
		]
	};

	const filedvaluemerch = field.value.merchandizeHierarchyEligibility;

	const getThresholdDropdown = (dropdownOptions) => {
		return dropdownOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};
	useEffect(() => {
		if (!filedvaluemerch.groupTriggerCombinationCode)
			filedvaluemerch.groupTriggerCombinationCode = 'OR';
		if (!filedvaluemerch.groupRewardCombinationCode)
			filedvaluemerch.groupRewardCombinationCode = 'OR';
		if (!filedvaluemerch.groupRewardQuantity) {
			filedvaluemerch.groupRewardQuantity = 1;
		}
		if (
			filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_INTERVAL_QTY_THRESHOLD' ||
			filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_QTY_THRESHOLD' ||
			filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_LIMIT_QTY_THRESHOLD'
		) {
			if (!filedvaluemerch.groupThresholdQuantity) {
				filedvaluemerch.groupThresholdQuantity = 1;
			}
			if (filedvaluemerch.thresholdTypeCode != 'MERCHANDIZE_LIMIT_QTY_THRESHOLD') {
				filedvaluemerch.quantityLimit = undefined;
			}
			filedvaluemerch.groupMaximumThresholdQuantity = undefined;
			filedvaluemerch.groupMaximumThresholdAmount = undefined;
		}
		if (filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_QTY_THRESHOLD') {
			if (!filedvaluemerch.groupThresholdQuantity) {
				filedvaluemerch.groupThresholdQuantity = 1;
			}
			filedvaluemerch.quantityLimit = undefined;
			// filedvaluemerch.groupMaximumThresholdQuantity = undefined;
			filedvaluemerch.groupMaximumThresholdAmount = undefined;
		}
		if (
			filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_INTERVAL_AMOUNT_THRESHOLD' ||
			filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_AMOUNT_THRESHOLD'
		) {
			if (!filedvaluemerch.groupThresholdAmount) {
				filedvaluemerch.groupThresholdAmount = 0.01;
			}
			filedvaluemerch.quantityLimit = undefined;
			filedvaluemerch.groupMaximumThresholdQuantity = undefined;
			filedvaluemerch.groupMaximumThresholdAmount = undefined;
		}
		if (filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_AMOUNT_THRESHOLD') {
			if (!filedvaluemerch.groupThresholdAmount) {
				filedvaluemerch.groupThresholdAmount = 0.01;
			}
			filedvaluemerch.quantityLimit = undefined;
			filedvaluemerch.groupMaximumThresholdQuantity = undefined;
			//filedvaluemerch.groupMaximumThresholdAmount = undefined;
		}
		if (filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_LIMIT_QTY_THRESHOLD') {
			if (!filedvaluemerch.quantityLimit) {
				filedvaluemerch.quantityLimit = 1;
			}
		}
		if (filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_QTY_THRESHOLD') {
			if (!filedvaluemerch.groupMaximumThresholdQuantity) {
				filedvaluemerch.groupMaximumThresholdQuantity = 999999;
			}
		}
		if (filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_AMOUNT_THRESHOLD') {
			if (!filedvaluemerch.groupMaximumThresholdAmount) {
				filedvaluemerch.groupMaximumThresholdAmount = 999999.99;
			}
		}
	}, [filedvaluemerch.thresholdTypeCode]);

	const fieldCR = filedvaluemerch;
	//newly added
	useEffect(() => {
		if (
			fieldCR.triggerOperator == '<' ||
			fieldCR.triggerOperator == '>' ||
			fieldCR.triggerOperator == '<=' ||
			fieldCR.triggerOperator == '>=' ||
			fieldCR.triggerOperator == '='
		) {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
		} else if (fieldCR.triggerOperator == 'BETWEEN') {
			fieldCR.triggerOperatorValue = undefined;
		} else {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
			fieldCR.triggerOperatorValue = undefined;
		}
	}, [fieldCR.triggerOperator]);

	useEffect(() => {
		if (
			fieldCR.rewardOperator == '<' ||
			fieldCR.rewardOperator == '>' ||
			fieldCR.rewardOperator == '<=' ||
			fieldCR.rewardOperator == '>=' ||
			fieldCR.rewardOperator == '='
		) {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
		} else if (fieldCR.rewardOperator == 'BETWEEN') {
			fieldCR.rewardOperatorValue = undefined;
		} else {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
			fieldCR.rewardOperatorValue = undefined;
		}
	}, [fieldCR.rewardOperator]);

	//html
	return (
		<div className="purchaseContainer">
			<div className="purchaseRow">
				Purchase Conditions
				<CustomSelect
					name="merchandizeHierarchyEligibility.groupTriggerCombinationCode"
					required={true}
					label={localConst.triggerCombinationCode}
					options={
						filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_INTERVAL_QTY_THRESHOLD' ||
						filedvaluemerch.thresholdTypeCode ==
							'MERCHANDIZE_INTERVAL_AMOUNT_THRESHOLD' ||
						filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_QTY_THRESHOLD'
							? getThresholdDropdown(rewardCombinationDropdown.promotionTypeOrAnd)
							: getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)
					}
					smallText={localConst.subTriggerCombinationCode}
					className="customDropdown inlinePadding bg-position"
				/>
				{filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_INTERVAL_QTY_THRESHOLD' ||
				filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_QTY_THRESHOLD' ||
				filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_QTY_THRESHOLD' ||
				filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_LIMIT_QTY_THRESHOLD' ? (
					<CustomInputText
						name="merchandizeHierarchyEligibility.groupThresholdQuantity"
						required={true}
						label={localConst.thresholdQuantity}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				{filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_INTERVAL_AMOUNT_THRESHOLD' ||
				filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_AMOUNT_THRESHOLD' ||
				filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_AMOUNT_THRESHOLD' ? (
					<CustomInputText
						name="merchandizeHierarchyEligibility.groupThresholdAmount"
						required={true}
						label={localConst.thresholdAmount}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				{filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_QTY_THRESHOLD' ||
				filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_AMOUNT_THRESHOLD' ? (
					filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_QTY_THRESHOLD' ? (
						<CustomInputText
							name="merchandizeHierarchyEligibility.groupMaximumThresholdQuantity"
							required={true}
							label={localConst.groupMaximumThresholdQuantity}
							smallText=""
							disabled={false}
						/>
					) : (
						<CustomInputText
							name="merchandizeHierarchyEligibility.groupMaximumThresholdAmount"
							required={true}
							label={localConst.groupMaximumThresholdAmount}
							smallText=""
							disabled={false}
						/>
					)
				) : undefined}
				{filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_LIMIT_QTY_THRESHOLD' ? (
					<CustomInputText
						name="merchandizeHierarchyEligibility.quantityLimit"
						required={true}
						label={localConst.quantityLimit}
						smallText=""
						disabled={false}
					/>
				) : undefined}
				<CustomSelect
					name="merchandizeHierarchyEligibility.triggerOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{fieldCR.triggerOperator == '<' ||
				fieldCR.triggerOperator == '>' ||
				fieldCR.triggerOperator == '<=' ||
				fieldCR.triggerOperator == '>=' ||
				fieldCR.triggerOperator == '=' ? (
					<CustomInputText
						name="merchandizeHierarchyEligibility.triggerOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{fieldCR.triggerOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="merchandizeHierarchyEligibility.triggerOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="merchandizeHierarchyEligibility.triggerOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>

			<div className="purchaseRow">
				Reward Conditions
				<CustomSelect
					label={localConst.rewardCombinationCode}
					name="merchandizeHierarchyEligibility.groupRewardCombinationCode"
					required={true}
					options={
						filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_INTERVAL_QTY_THRESHOLD' ||
						filedvaluemerch.thresholdTypeCode ==
							'MERCHANDIZE_INTERVAL_AMOUNT_THRESHOLD' ||
						filedvaluemerch.thresholdTypeCode == 'MERCHANDIZE_RANGE_QTY_THRESHOLD'
							? getThresholdDropdown(rewardCombinationDropdown.promotionTypeOrAnd)
							: getThresholdDropdown(rewardCombinationDropdown.promotionTypeOr)
					}
					smallText={localConst.subRewardCombinationCode}
					className="customDropdown inlinePadding bg-position"
				/>
				<CustomInputText
					name="merchandizeHierarchyEligibility.groupRewardQuantity"
					required={true}
					label={localConst.rewardQuantity}
					smallText=""
					disabled={false}
				/>
				<CustomSelect
					name="merchandizeHierarchyEligibility.rewardOperator"
					label="Price Range Indicator"
					className="customDropdown inlinePadding bg-position"
					options={getThresholdDropdown(rewardCombinationDropdown.operator)}
					// smallText={localConst.subTriggerCombinationCode}
					/*  className="customDropdown inlinePadding" */
				/>
				{fieldCR.rewardOperator == '<' ||
				fieldCR.rewardOperator == '>' ||
				fieldCR.rewardOperator == '<=' ||
				fieldCR.rewardOperator == '>=' ||
				fieldCR.rewardOperator == '=' ? (
					<CustomInputText
						name="merchandizeHierarchyEligibility.rewardOperatorValue"
						required={true}
						label="Price Range Value"
						smallText=""
						disabled={false}
					/>
				) : null}
				{fieldCR.rewardOperator == 'BETWEEN' ? (
					<>
						<CustomInputText
							name="merchandizeHierarchyEligibility.rewardOperatorValueFrom"
							required={true}
							label="Price Range Value From"
							smallText=""
							disabled={false}
						/>
						<CustomInputText
							name="merchandizeHierarchyEligibility.rewardOperatorValueTo"
							required={true}
							label="Price Range Value To"
							smallText=""
							disabled={false}
						/>{' '}
					</>
				) : null}
			</div>
		</div>
	);
}
export default ConditionalRedering;
