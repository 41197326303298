import React, { useEffect, useState } from 'react';

import toaster from 'toasted-notes';
import * as RestSvc from '../../../../libraries/offer-services';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actionTypes from '../../../../store/actions';
import * as urlTypes from '../../../../libraries/offer-service-urls';

const AuditSegments = ({ ...props }) => {
	const getAdded = (products, products1) => {
		var added = [];
		for (var i = 0; i < products.length; i++) {
			var id = products[i];
			var flag = false;
			for (var j = 0; j < products1.length; j++) {
				var id2 = products1[j];
				if (id === id2) {
					flag = true;
				}
			}
			if (!flag) {
				added.push(products[i]);
			}
		}
		return added;
	};
	const getSegmentNames = () => {
		let url = `${urlTypes.offers_maintenance.getSegments}${
			JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
				.businessUnitGroupFunctionID
		}`;
		RestSvc.getData(url).then((response) => {
			var result = response.data.getSegmentDetailsResponse.segmentMap;
			if (response.data.getSegmentDetailsResponse.businessError) {
				toaster.notify(
					({ onClose }) => (
						<div className="Toaster__message-wrapper">
							<div>
								<div
									id="1"
									className="toasterRed Toaster__alert Toaster__alert_error"
								>
									<div className="Toaster__alert_text">
										{
											response.data.getSegmentDetailsResponse.businessError
												.errorMessage
										}
									</div>
									<button
										className="Toaster__alert_close"
										type="button"
										aria-label="Close"
										onClick={onClose}
									>
										<span aria-hidden="true">×</span>
									</button>
								</div>
							</div>
						</div>
					),
					{
						duration: 10000
					}
				);
			} else {
				if (result.CustomerSegment != undefined) {
					const newObj = {};
					result.CustomerSegment.forEach((item) => {
						newObj[item.value] = item.name;
					});
					setCustSegMap(newObj);
				}
				if (result.LoyaltySegment != undefined) {
					const newObj = {};
					result.LoyaltySegment.forEach((item) => {
						newObj[item.value] = item.name;
					});
					setLoyalSegMap(newObj);
				}
				if (result.EmployeeSegment != undefined) {
					const newObj = {};
					result.EmployeeSegment.forEach((item) => {
						newObj[item.value] = item.name;
					});
					setEmpSegMap(newObj);
				}
			}
		});
	};
	const [includeAdded, setIncludeAdded] = useState([]);
	const [excludeAdded, setExcludeAdded] = useState([]);
	const [includeRemoved, setIncludeRemoved] = useState([]);
	const [excludeRemoved, setExcludeRemoved] = useState([]);
	const [empSegMap, setEmpSegMap] = useState({});
	const [custSegMap, setCustSegMap] = useState({});
	const [loyalSegMap, setLoyalSegMap] = useState({});

	useEffect(() => {
		getSegmentNames();
		let newIncl = props.newIncludedSegments ? props.newIncludedSegments : [];
		let oldIncl = props.oldIncludedSegments ? props.oldIncludedSegments : [];
		let newEx = props.newExcludedSegments ? props.newExcludedSegments : [];
		let oldEx = props.oldExcludedSegments ? props.oldExcludedSegments : [];
		let tempRemoved = getAdded(oldIncl, newIncl);
		let tempAdded = getAdded(newIncl, oldIncl);
		let tempExRemoved = getAdded(oldEx, newEx);
		let tempExAdded = getAdded(newEx, oldEx);
		setIncludeAdded(tempAdded);
		setIncludeRemoved(tempRemoved);
		setExcludeAdded(tempExAdded);
		setExcludeRemoved(tempExRemoved);
		return () => {};
	}, []);

	return (
		<>
			<div className="row mt-2">
				<div
					className={
						includeAdded.length == 0 && includeRemoved.length == 0
							? 'd-none'
							: 'col-sm-6 container pl-2'
					}
				>
					<label className="font-weight-bold m-0 pl-2">Included</label>
					<div className="box-blackout-period lightBlue">
						{includeAdded.length > 0 ? (
							<>
								<div className="issuerOuterBox mb-3">
									<div className="pl-2">
										<label className="font-weight-bold">
											Added ({includeAdded.length}){' '}
										</label>
									</div>
									<div className="row w-auto">
										{includeAdded.map((item, ind) => {
											return (
												<span key={ind} className="channelNodeAudit mb-2">
													{empSegMap[item] ||
														loyalSegMap[item] ||
														custSegMap[item]}
												</span>
											);
										})}
									</div>
								</div>
							</>
						) : undefined}
						{includeRemoved.length > 0 ? (
							<>
								<div className="issuerOuterBox mb-3">
									<div className="pl-2">
										<label className="font-weight-bold">
											Removed ({includeRemoved.length})
										</label>
									</div>
									<div className="row w-auto">
										{includeRemoved.map((item, ind) => {
											return (
												<span
													key={ind}
													className="channelNodeAudit mb-2 text-danger"
												>
													{empSegMap[item] ||
														loyalSegMap[item] ||
														custSegMap[item]}
												</span>
											);
										})}
									</div>
								</div>
							</>
						) : undefined}
					</div>
				</div>
				<div
					className={
						excludeAdded.length == 0 && excludeRemoved.length == 0
							? 'd-none'
							: 'col-sm-6 container pl-2'
					}
				>
					<label className="font-weight-bold m-0 pl-2">Excluded</label>
					<div className="box-blackout-period lightBlue">
						{excludeAdded.length > 0 ? (
							<>
								<div className="issuerOuterBox mb-3">
									<div className="pl-2">
										<label className="font-weight-bold">
											Added ({excludeAdded.length}){' '}
										</label>
									</div>
									<div className="row w-auto">
										{excludeAdded.map((item, ind) => {
											return (
												<span key={ind} className="channelNodeAudit mb-2">
													{empSegMap[item] ||
														loyalSegMap[item] ||
														custSegMap[item]}
												</span>
											);
										})}
									</div>
								</div>
							</>
						) : undefined}
						{excludeRemoved.length > 0 ? (
							<>
								<div className="issuerOuterBox mb-3">
									<div className="pl-2">
										<label className="font-weight-bold">
											Removed ({excludeRemoved.length})
										</label>
									</div>
									<div className="row w-auto">
										{excludeRemoved.map((item, ind) => {
											return (
												<span
													key={ind}
													className="channelNodeAudit mb-2 text-danger"
												>
													{empSegMap[item] ||
														loyalSegMap[item] ||
														custSegMap[item]}
												</span>
											);
										})}
									</div>
								</div>
							</>
						) : undefined}
					</div>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuditSegments));
