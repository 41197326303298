import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import 'bootstrap/dist/css/bootstrap.css';
import '../BasicDetails.scss';
import CustomHoursPicker from '../customfields/CustomHoursPicker';

const EffectiveDuration = ({ ...props }) => {
	let { customArrayName } = props;
	const fieldHappyHours = props.fieldHappyHours;
	const { setFieldValue } = useFormikContext();
	let customName = props.customName;

	return (
		<div className="Container">
			<FieldArray
				name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.${customArrayName}.${props.index}.${customName}`}
			>
				{({ insert, remove, push }) => (
					<>
						{fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek[
							customArrayName
						][props.index][customName].map((item, i) => (
							<div key={i} className="d-flex">
								<div className="d-flex">
									<CustomHoursPicker
										flag="show"
										name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.${customArrayName}.${props.index}.${customName}.${i}.effectiveStartTime`}
										required={true}
										label={
											props.index == 0 && i == 0
												? 'Start Time'
												: JSON.parse(
														sessionStorage.getItem('typeOfOffersFlow')
												  ).isAuditFlow
												? 'Start Time'
												: ''
										}
										valueHour={
											fieldHappyHours?.eligibleDaysOfTheWeek
												?.includedDaysOfTheWeek[customArrayName][
												props.index
											].effectiveDuration[i]?.effectiveStartTime
										}
									/>
								</div>
								<div className="d-flex align-items-center">
									<CustomHoursPicker
										flag="show"
										name={`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.${customArrayName}.${props.index}.${customName}.${i}.effectiveEndTime`}
										required={true}
										label={
											props.index == 0 && i == 0
												? 'End Time'
												: JSON.parse(
														sessionStorage.getItem('typeOfOffersFlow')
												  ).isAuditFlow
												? 'End Time'
												: ''
										}
										valueHour={
											fieldHappyHours?.eligibleDaysOfTheWeek
												?.includedDaysOfTheWeek[customArrayName][
												props.index
											][customName][i]?.effectiveEndTime
										}
									/>
									{i == 0 ? (
										<div className="happy-hours-DateplusBtn">
											{' '}
											<button
												type="button"
												className="addPlusBtn bg-color"
												onClick={() => {
													// if (fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay[props.index].effectiveDuration.length < 5) {
													fieldHappyHours.eligibleDaysOfTheWeek.includedDaysOfTheWeek[
														customArrayName
													][props.index][customName].push({
														effectiveStartTime: '00:00:00',
														effectiveEndTime: '23:59:00'
													});
													// }
													setFieldValue('', '');
												}}
											></button>{' '}
										</div>
									) : (
										<div className="happy-hours-deleteBtn">
											{' '}
											<button
												type="button"
												className="addDeleteBtn bg-color"
												onClick={() => remove(i)}
											></button>
										</div>
									)}
								</div>
							</div>
						))}
					</>
				)}
			</FieldArray>
		</div>
	);
};

export default EffectiveDuration;
