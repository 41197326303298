import React, { useEffect, useState } from 'react';
import { useField, Form, useFormikContext } from 'formik';
import CustomSelect from './customfields/CustomSelect';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { InformationModal } from '../../../MainContent/InformationModal';
import ExclusionTableBootstrap from './ExclusionTableBootstrap';
import AuditExclusionProductTable from './Audit/AuditExclusionProductTable';
import AuditExclusionSHTable from './Audit/AuditExclusionSHTable';

const localConst = {
	SelectID: 'Select ID',
	EnterID: 'Enter ID'
};

const dropdownValues = {
	selectId: [
		{ value: '', name: 'Select one' },
		{ value: 'ITEM_ID', name: 'Product Id' },
		{ value: 'MERCHANDIZE', name: 'Selling Hierarchy Group Id' }
	]
};

const getESSelectIDDropdown = () => {
	return dropdownValues.selectId.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};
const keys = ['productID'];
let productTableDataFlat = [];
const ExclusionSetting = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [SelectID, setSelectID] = useState('');
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg] = useState('');
	const [informationTitle] = useState('Information');

	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();
	// const fieldexclusions= field.value.groupMultibuyEligbility.exclusions;
	const valueEligbility = props.customName;

	const fieldexclusions =
		valueEligbility == 'groupMultibuyEligbility'
			? field.value.groupMultibuyEligbility.exclusions
			: valueEligbility == 'packageMultibuyEligibility'
			? field.value.packageMultibuyEligibility.exclusions
			: valueEligbility == 'multibuyEligbility'
			? field.value.multibuyEligbility.exclusions
			: valueEligbility == 'merchandizeHierarchyEligibility'
			? field.value.merchandizeHierarchyEligibility.exclusions
			: valueEligbility == 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity'
			? field.value.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions
			: valueEligbility == 'scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity'
			? field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions
			: valueEligbility == 'scaledPriceEligibility'
			? field.value.scaledPriceEligibility.exclusions
			: valueEligbility == 'earnBurnEligibility'
			? field.value.earnBurnEligibility.exclusions
			: undefined;
	var obj = [];
	if (fieldexclusions.excludedItemIDList) {
		fieldexclusions.excludedItemIDList.excludedItemID.forEach(function (value) {
			obj.push({ productID: value });
		});
	}

	// obj=fieldexclusions.excludedItemIDList.excludedItemID.map(val=> {
	//     return productID.val
	//     });
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const [productTableData, setProductTableData] = useState(obj);

	const [sHTableData, setSHTableData] = useState(
		fieldexclusions?.merchandizeHierarchyGroup || []
	);
	useEffect(() => {
		productTableDataFlat = productTableData.map((val) => {
			return val.productID;
		});
		if (fieldexclusions.excludedItemIDList) {
			fieldexclusions.excludedItemIDList.excludedItemID = productTableDataFlat;
		} else {
			fieldexclusions.excludedItemIDList = { excludedItemID: [] };
		}
		fieldexclusions.merchandizeHierarchyGroup = sHTableData;

		setFieldValue('', '');
	}, [sHTableData, productTableData]);
	useEffect(() => {
		return () => {
			setProductTableData([]);
			setSHTableData([]);
		};
	}, []);

	return (
		<div className="main">
			<Form>
				<div className="justify-content-center">
					{/*
					 * <select value={SelectID} onChange={(e) =>
					 * setSelectID(e.target.value)}>
					 * {getESSelectIDDropdown()} </select>
					 */}

					<CustomSelect
						name={`${valueEligbility}.exclusions.exclsnSttngSelectIdDropDown`}
						required={false}
						value={SelectID}
						onChange={(e) => setSelectID(e.target.value)}
						label={localConst.SelectID}
						options={getESSelectIDDropdown()}
						smallText=""
					/>

					<div className="">
						{isAuditFlw ? (
							<>
								<AuditExclusionProductTable
									valueEligbility={valueEligbility}
									oldJson={productTableData}
									newJson={JSON.parse(sessionStorage.getItem('NewPolicy'))}
								/>
								<AuditExclusionSHTable
									valueEligbility={valueEligbility}
									oldJson={sHTableData}
									newJson={JSON.parse(sessionStorage.getItem('NewPolicy'))}
								/>
							</>
						) : (
							<ExclusionTableBootstrap
								dataProduct={productTableData}
								SelectIDProp={SelectID}
								dataMer={sHTableData}
								keys={keys}
								defaultValueForAdd={{
									maximumPrice: '999999.99',
									minimumPrice: '0.01',
									trigger: true,
									getReward: true
								}}
								onProductTableChange={(val, val2) => {
									setProductTableData(val);
									setSHTableData(val2);
								}}
								eligibility={'MultiBuyGroup'}
								type={SelectID}
								brandEligibility={
									JSON.parse(props.fullValue).promotionPolicy
										.promotionApplicableEligibility.brandEligibility
								}
							/>
						)}
					</div>
				</div>
			</Form>
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

/*
 * const ExclusionSettingFrom = withSubForm( ExclusionSetting,
 * ExclusionSettingSchema );
 */

export default ExclusionSetting;
