import React from 'react';
import PropTypes from 'prop-types';

import './loader.scss';

const Spinner = () => (
	<div className="spinner-loader row">
		<div className="col">
			<div className="spinner">Loading...</div>
		</div>
	</div>
);

const Pulser = () => (
	<div className="pulse-loader row">
		<div className="pulse col" />
	</div>
);

const Loader = ({ type }) => <>{type === 'spin' ? <Spinner /> : <Pulser />}</>;

Loader.defaultProps = {
	type: 'pulse'
};

Loader.propTypes = {
	type: PropTypes.oneOf(['spin', 'pulse'])
};

export default Loader;
