import React, { useEffect } from 'react';
import { Form, useField, useFormikContext } from 'formik';
import CustomInputText from './customfields/CustomInputText';
import CustomInputRadio from './customfields/CustomInputRadio';
import CustomDateTime from './customfields/CustomDateTime';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import CustomSelect from './customfields/CustomSelect';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import { itemHelp } from './item-help-text';
import MultiBuyProductTableST from './MultiBuyProductTableST';
import SimplePromotionProductTable from './ProductTableSimplePromo';
import SimplePromotionSellingTable from './MerchTableSimplePromo.js';
import moment from 'moment';
import $ from 'jquery';
import AuditSimpleProductTable from './Audit/AuditSimpleProductTable';
import AuditSimpleSHTable from './Audit/AuditSimpleSHTable';

/* const benefitSchema = Yup.object().shape({
    benefitType: Yup.string().required("Required: A Benefit Type must be selected"),
    priceModificationMethodCode: Yup.string().required("Required"),
    priceModificationPercent: Yup.string().required("Required")
    .matches(/^(100|([0-9][0-9]?(\.[0-9]{0,2})?))$/, "Price Modification Percent should be between 0.01 to 100"),
    priceModificationAmount:Yup.string().required("Required")
    .matches(/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/, "Price Modification Amount should be between 0.01 to 999999.99"),
    newPriceAmount: Yup.string().required("Required")
    .matches(/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/, "Price Modification Amount should be between 0.01 to 999999.99"),
    cashback: Yup.object().shape({
        creditBy:Yup.object().shape({
            date: Yup.date().min(new Date(), "Date should not be less than the Current Date"),
            days: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
            months: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
        }),
        expireOn:Yup.object().shape({
            date: Yup.date().min(new Date(), "Date should not be less than the Current Date"),
            days: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
            months: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
        }),
    }),
    Loyalty:  Yup.object().shape({
        loyaltyMultiplier : Yup.string().required("Required")
        .matches(/^(?=.*[1-9])\d{0,6}$/, "Loyalty Multiplier Point should be between 1 to 999999"),
        LoyaltyAdditionalPoint: Yup.string().required("Required")
        .matches(/^(?=.*[1-9])\d{0,6}$/, "Loyalty Additional Point should be between 1 to 999999"),
        expireOn:Yup.object().shape({
            date: Yup.date().min(new Date(), "Date should not be less than the Current Date"),
            days: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
            months: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
        }),
    }),
    
    
});
 */

const keysSimplepromo = ['productID', 'minimumPrice', 'maximumPrice', 'benefitValue'];
const keysSimpleselling = ['merchandizeGroupId', 'minimumPrice', 'maximumPrice', 'benefitValue'];

const defaultValueForSimplePromotion = {
	maximumPrice: 9999999.99,
	minimumPrice: 0.01,
	trigger: true,
	getReward: true
};

const dropdownValues = {
	benefitTypes: [
		{ value: '', name: 'Select one' },
		{ value: 'INSTANT', name: 'Instant Discount' },
		{ value: 'CASHBACK', name: 'Cashback' },
		{ value: 'LOYALTY_POINTS', name: 'Loyalty Points' },
		{ value: 'FREEBIE', name: 'Freebies' },
		{ value: 'FREE_TEXT', name: 'Free text' },
		{ value: 'EMI', name: 'EMI' }
	],
	benefitTypesForScaleTransaction: [
		{ value: '', name: 'Select one' },
		{ value: 'INSTANT', name: 'Instant Discount' },
		{ value: 'CASHBACK', name: 'Cashback' },
		{ value: 'LOYALTY_POINTS', name: 'Loyalty Points' },
		{ value: 'FREE_TEXT', name: 'Free text' }
	],
	benefitTypesForSimplePromotion: [
		{ value: '', name: 'Select one' },
		{ value: 'INSTANT', name: 'Instant Discount' }
	],

	benefitTypesForEarnBurnPromotion: [
		{ value: '', name: 'Select one' },
		{ value: 'EARN_BURN', name: 'Earn Burn' }
	],

	collectionValidity: [
		{ value: '', name: 'Select one' },
		{
			value: 'Date',
			name: 'Date'
		},
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Hours',
			name: 'Hours'
		}
	]
};

const priceMethodCodeDropdown = {
	INSTANT_SIMPLEPROMO: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' },
		{ value: 'APPORTIONED_PERCENTAGE_OFF', name: 'Apportioned Percentage Off' },
		{ value: 'AMOUNT_OFF', name: 'Amount Off' },
		{ value: 'APPORTIONED_AMOUNT_OFF', name: 'Apportioned Amount Off' },
		{ value: 'MARKDOWN', name: 'Markdown' }
	],
	INSTANT_MULTIBUYGROUP: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' },
		{ value: 'APPORTIONED_PERCENTAGE_OFF', name: 'Apportioned Percentage Off' },
		{ value: 'AMOUNT_OFF', name: 'Amount Off' },
		{ value: 'APPORTIONED_AMOUNT_OFF', name: 'Apportioned Amount Off' },
		{ value: 'MARKDOWN', name: 'Markdown' },
		{ value: 'KIT_MARKDOWN', name: 'Kit Markdown' },
		{ value: 'QUANTITY_PRICING', name: 'Quantity Pricing' }
	],
	INSTANT: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' },
		{ value: 'APPORTIONED_PERCENTAGE_OFF', name: 'Apportioned Percentage Off' },
		{ value: 'AMOUNT_OFF', name: 'Amount Off' },
		{ value: 'APPORTIONED_AMOUNT_OFF', name: 'Apportioned Amount Off' },
		{ value: 'MARKDOWN', name: 'Markdown' },
		{ value: 'KIT_MARKDOWN', name: 'Kit Markdown' }
	],
	CASHBACK: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' },
		{ value: 'APPORTIONED_PERCENTAGE_OFF', name: 'Apportioned Percentage Off' },
		{ value: 'AMOUNT_OFF', name: 'Amount Off' },
		{ value: 'APPORTIONED_AMOUNT_OFF', name: 'Apportioned Amount Off' }
	],
	LOYALTY_POINTS: [
		{ value: '', name: 'Select one' },
		{ value: 'LOYALTY_ADDITIONAL_POINTS', name: 'Loyalty Additional Points' },
		{ value: 'LOYALTY_POINTS_MULTIPLIER', name: 'Loyalty Multiplier Points' },
		{
			value: 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS',
			name: 'Apportioned Loyalty Additional Points on Points'
		},
		{
			value: 'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE',
			name: 'Apportioned Loyalty Additional Points on Unit Price'
		},
		{
			value: 'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS',
			name: 'Apportioned Loyalty Multiplier on Points'
		},
		{
			value: 'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE',
			name: 'Apportioned Loyalty Multiplier on Unit Price'
		}
	],
	FREEBIE: [
		{ value: '', name: 'Select one' },
		{ value: 'PERCENTAGE_OFF', name: 'Percentage Off' }
		// { value: "APPORTIONED_PERCENTAGE_OFF", name: "Apportioned Percentage Off" },
	],
	EMI: [
		{ value: '', name: 'Select one' },
		{ value: 'EMI_PERCENTAGE_OFF', name: 'EMI Percentage Off' },
		{
			value: 'EMI_APPORTIONED_PERCENTAGE_OFF',
			name: 'EMI Apportioned Percentage off'
		}
	],
	EMI1: [
		{ value: '', name: 'Select one' },
		{ value: 'ONE_EMI_FREE_PERCENTAGE_OFF', name: 'Percentage Off' }
	]
};

const localConst = {
	benefitType: 'Benefit Type',
	priceMethodCode: 'Price Modification Method Code',
	priceModificationPercent: 'Price Modification Percent',
	priceModificationAmount: 'Price Modification Amount',
	newPriceAmount: 'New Price Amount',
	priceModificationQuantity: 'Price Modification Quantity',
	subPriceModificationPercent: 'Determines the benefit percent for the promotion',
	provider: 'Provider',
	subProvider: 'Defines the Payment eligibility Provider for which the promotion is applicable',
	paymentType: 'Payment Type',
	subpaymentType: 'Defines the Payment eligibility Type for which the promotion is applicable',
	paymentSubType: 'Payment Sub Type',
	paymentMode: 'Payment Mode',
	subCashBackCreditByMonths:
		'Specifies the period the cashback will be credited to the customer in months post the transaction',
	subCashBackCreditByDays:
		'Specifies the period the cashback will be credited to the customer in days post the transaction',
	subCashBackCreditByHours:
		'Specifies the period the cashback will be credited to the customer in hours post the transaction',
	subCashBackCreditByDate:
		'Specifies the period the cashback will be credited to the customer post the transaction',
	subCashBackExpireOnMonths:
		'Specifies the period the cashback will be Expired to the customer in months post the transaction',
	subCashBackExpireOnDays:
		'Specifies the period the cashback will be Expired to the customer in days post the transaction',
	subCashBackExpireOnHours:
		'Specifies the period the cashback will be Expired to the customer in hours post the transaction',
	subCashBackExpireOnDate:
		'Specifies the period the cashback will be Expired to the customer post the transaction'
};
let ScaleMerchTableData = [];
const BenefitSettings = ({ name, errors, touched, getFieldProps, ...props }) => {
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();
	// const fieldbenefit= field.value.groupMultibuyEligbility.benefit;
	const valueEligbility = props.customName;
	const fieldbenefit =
		valueEligbility == 'groupMultibuyEligbility'
			? field.value.groupMultibuyEligbility.benefit
			: valueEligbility == 'packageMultibuyEligibility'
			? field.value.packageMultibuyEligibility.benefit
			: valueEligbility == 'multibuyEligbility'
			? field.value.multibuyEligbility.benefit
			: valueEligbility == 'merchandizeHierarchyEligibility'
			? field.value.merchandizeHierarchyEligibility.benefit
			: valueEligbility == 'promotionalOfferReward.itemPriceDerivationRule'
			? field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
			: valueEligbility == 'earnBurnEligibility'
			? field.value.earnBurnEligibility.benefit
			: valueEligbility == 'scaleTransaction'
			? field.value.scaleTransaction.benefit
			: valueEligbility == 'simplePromotionEligibility'
			? field.value.simplePromotionEligibility.benefit
			: undefined;
	const fieldPayment =
		valueEligbility == 'groupMultibuyEligbility'
			? field.value.groupMultibuyEligbility.paymentEligibility
			: valueEligbility == 'packageMultibuyEligibility'
			? field.value.packageMultibuyEligibility.paymentEligibility
			: valueEligbility == 'multibuyEligbility'
			? field.value.multibuyEligbility.paymentEligibility
			: valueEligbility == 'merchandizeHierarchyEligibility'
			? field.value.merchandizeHierarchyEligibility.paymentEligibility
			: valueEligbility == 'earnBurnEligibility'
			? field.value.earnBurnEligibility.paymentEligibility
			: valueEligbility == 'scaleTransaction'
			? field.value.scaleTransaction.paymentEligibility
			: valueEligbility == 'simplePromotionEligibility'
			? field.value.simplePromotionEligibility.paymentEligibility
			: valueEligbility == 'promotionalOfferReward.itemPriceDerivationRule'
			? field.value.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
			: undefined;

	const customValidateloyaltyAdditionalPoint = (value) => {
		let error;

		if (value) {
			//if(!value.match((/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/))){
			if (!/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/.test(value)) {
				error = 'Loyalty Additional Point should be between 0.01 to 999999.99';
			}
		} else {
			error = 'Please define Loyalty Additional Point value';
		}
		return error;
	};

	const customValidateCashBackCreditByDate = (value) => {
		let error;
		//debugger
		if (value) {
			if (
				moment(JSON.parse(props.fullValue).promotionPolicy.effectiveStartDate) >
				moment(value)
			) {
				error = 'Entered date should not be less than promotion start date';
			} else if (
				moment(JSON.parse(props.fullValue).promotionPolicy.effectiveEndDate) <
					moment(value) &&
				fieldbenefit.benefitType == 'CASHBACK'
			) {
				error = 'Entered date should not be more than promotion end date';
			}
		} else {
			error = 'Please define the date value';
		}
		return error;
	};

	const customValidateCashBackExpireOnDate = (value) => {
		let error;
		//debugger
		if (value) {
			if (moment(fieldbenefit.cashback?.creditBy?.date) > moment(value)) {
				error = 'Entered date should not be less than credited by date';
			} else if (
				moment(JSON.parse(props.fullValue).promotionPolicy.effectiveStartDate) >
				moment(value)
			) {
				error = 'Entered date should not be less than promotion start date';
			} else if (
				moment(JSON.parse(props.fullValue).promotionPolicy.effectiveEndDate) < moment(value)
			) {
				error = 'Entered date should not be more than promotion end date';
			}
		} else {
			error = 'Please define the date value';
		}
		return error;
	};

	const customValidateCashBackCreditByMonths = (value) => {
		let error;
		if (value) {
			/*  if(!value.match(/^(?=.*[1-9])\d{0,3}$/)){
         error="Please enter a value greater than 1 to 999."
       } */
		} else {
			error = 'Please define the months value';
		}
		return error;
	};

	const customValidateCashBackCreditByDays = (value) => {
		let error;
		if (value) {
			/* if(!value.match(/^(?=.*[1-9])\d{0,3}$/)){
        error="Please enter a value greater than 1 to 999."
      } */
		} else {
			error = 'Please define the days value';
		}
		return error;
	};

	const customValidateCashBackCreditByHours = (value) => {
		let error;
		if (!value) {
			error = 'Please define the hours value';
		}
		return error;
	};

	const onsimpleTableChanged = (val) => {
		props.onProductTableChanged(val);
	};

	/*  useEffect(() => {
     getContentBelowToBenefitType()
   },   [valueEligbility.benifitType]);
    */
	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			//
			//
			//
			if (valueEligbility == 'promotionalOfferReward.itemPriceDerivationRule') {
				let valueEligbility1 = valueEligbility.split('.');
				if (
					newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility1[0]][
						valueEligbility1[1]
					]?.benefit?.benefitType != fieldbenefit.benefitType
				) {
					let tempName = valueEligbility + '.benefit.benefitType';
					//
					$('[name="' + tempName + '"]')
						.closest('.container')
						.nextAll()
						.addClass('d-none');
				} else if (
					newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility1[0]][
						valueEligbility1[1]
					]?.benefit?.priceModificationMethodCode !=
					fieldbenefit.priceModificationMethodCode
				) {
					let tempName = valueEligbility + '.benefit.priceModificationMethodCode';

					$('[name="' + tempName + '"]')
						.closest('.container')
						.nextAll()
						.addClass('d-none');
				} else if (
					fieldbenefit.benefitType == 'CASHBACK' &&
					newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility1[0]][
						valueEligbility1[1]
					]?.benefit?.cashback?.creditBy?.cashbackCreditData !=
						fieldbenefit?.cashback?.creditBy?.cashbackCreditData
				) {
					let tempName =
						valueEligbility + '.benefit.cashback.creditBy.cashbackCreditData';

					$('[name="' + tempName + '"]')
						.closest('.container')
						.next()
						.addClass('d-none');
				}
				//.benefit.cashback.expireOn.cashbackExpireData
				else if (
					fieldbenefit.benefitType == 'CASHBACK' &&
					newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility1[0]][
						valueEligbility1[1]
					]?.benefit?.cashback?.expireOn?.cashbackExpireData !=
						fieldbenefit?.cashback?.expireOn.cashbackExpireData
				) {
					let tempName =
						valueEligbility + '.benefit.cashback.expireOn.cashbackExpireData';

					$('[name="' + tempName + '"]')
						.closest('.container')
						.next()
						.addClass('d-none');
				}
				//groupMultibuyEligbility.benefit.loyalty.expireOn.loyaltyExpireData
				else if (
					fieldbenefit.benefitType == 'LOYALTY_POINTS' &&
					newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility1[0]][
						valueEligbility1[1]
					]?.benefit?.loyalty?.expireOn?.loyaltyExpireData !=
						fieldbenefit?.loyalty?.expireOn?.loyaltyExpireData
				) {
					let tempName = valueEligbility + '.benefit.loyalty.expireOn.loyaltyExpireData';

					$('[name="' + tempName + '"]')
						.closest('.container')
						.next()
						.addClass('d-none');
				}
			} else {
				if (
					newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.benefit
						?.benefitType != fieldbenefit.benefitType
				) {
					let tempName = valueEligbility + '.benefit.benefitType';
					//
					$('[name="' + tempName + '"]')
						.closest('.container')
						.nextAll()
						.addClass('d-none');
				} else if (
					newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.benefit
						?.priceModificationMethodCode != fieldbenefit.priceModificationMethodCode
				) {
					let tempName = valueEligbility + '.benefit.priceModificationMethodCode';

					$('[name="' + tempName + '"]')
						.closest('.container')
						.nextAll()
						.addClass('d-none');
				} else if (
					fieldbenefit.benefitType == 'CASHBACK' &&
					newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.benefit
						?.cashback?.creditBy?.cashbackCreditData !=
						fieldbenefit?.cashback?.creditBy?.cashbackCreditData
				) {
					let tempName =
						valueEligbility + '.benefit.cashback.creditBy.cashbackCreditData';

					$('[name="' + tempName + '"]')
						.closest('.container')
						.next()
						.addClass('d-none');
				}
				//.benefit.cashback.expireOn.cashbackExpireData
				else if (
					fieldbenefit.benefitType == 'CASHBACK' &&
					newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.benefit
						?.cashback?.expireOn?.cashbackExpireData !=
						fieldbenefit?.cashback?.expireOn.cashbackExpireData
				) {
					let tempName =
						valueEligbility + '.benefit.cashback.expireOn.cashbackExpireData';

					$('[name="' + tempName + '"]')
						.closest('.container')
						.next()
						.addClass('d-none');
				}
				//groupMultibuyEligbility.benefit.loyalty.expireOn.loyaltyExpireData
				else if (
					fieldbenefit.benefitType == 'LOYALTY_POINTS' &&
					newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.benefit
						?.loyalty?.expireOn?.loyaltyExpireData !=
						fieldbenefit?.loyalty?.expireOn?.loyaltyExpireData
				) {
					let tempName = valueEligbility + '.benefit.loyalty.expireOn.loyaltyExpireData';

					$('[name="' + tempName + '"]')
						.closest('.container')
						.next()
						.addClass('d-none');
				}
			}
		}
		//$('[name="groupMultibuyEligbility.benefit.benefitType"]').closest(".container").nextAll().css("display","none")
		//$('[name="groupMultibuyEligbility.benefit.priceModificationMethodCode"]').closest(".container").nextAll().css("display","none")
		//$('[name="groupMultibuyEligbility.benefit.cashback.creditBy.cashbackCreditData"]').closest(".container").nextAll().css("display","none")
	}, []);

	useEffect(() => {
		if (
			valueEligbility.paymentEligibility?.payment?.paymentInformation?.emiType ==
			'NO_COST_EMI'
		) {
			setFieldValue(`${valueEligbility}.benefit`, 'EMI');
			//setFieldValue(`${valueEligbility}.benefit`, "EMI");
		}
	}, [valueEligbility.paymentEligibility?.payment?.paymentInformation?.emiType]);

	useEffect(() => {
		if (valueEligbility == field.value?.promotionalOfferReward?.itemPriceDerivationRule) {
			if (
				field.value?.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
					?.paymentEligibility?.payment?.paymentInformation?.emiType == 'NO_COST_EMI'
			) {
				setFieldValue(valueEligbility.benefit, 'EMI');
			}
			if (
				field.value?.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
					?.paymentEligibility?.payment?.paymentInformation?.emiType == 'ONE_EMI_FREE'
			) {
				setFieldValue(valueEligbility.benefit, 'EMI');
			}
		}
	}, [
		field.value?.totalPurchaseMarketBasedPriceDerivationRuleEligibiity?.paymentEligibility
			?.payment?.paymentInformation?.emiType
	]);

	useEffect(() => {
		if (!fieldbenefit.priceModificationMethodCode) {
			setFieldValue(`${valueEligbility}.benefit.priceModificationMethodCode`, '');
		}
		if (fieldbenefit.benefitType == 'INSTANT') {
			if (
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_APPORTIONED_PERCENTAGE_OFF'
			) {
				setFieldValue(`${valueEligbility}.benefit.priceModificationMethodCode`, '');
			}
			setFieldValue(`${valueEligbility}.benefit.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.benefit.cashback`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextMessage`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextType`, undefined);
		}
		if (fieldbenefit.benefitType == 'CASHBACK') {
			if (
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_APPORTIONED_PERCENTAGE_OFF'
			) {
				setFieldValue(`${valueEligbility}.benefit.priceModificationMethodCode`, '');
			}
			setFieldValue(`${valueEligbility}.benefit.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextMessage`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextType`, undefined);
		}
		if (fieldbenefit.benefitType == 'LOYALTY_POINTS') {
			if (
				fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'AMOUNT_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'MARKDOWN' ||
				fieldbenefit.priceModificationMethodCode == 'KIT_MARKDOWN' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_APPORTIONED_PERCENTAGE_OFF'
			) {
				setFieldValue(`${valueEligbility}.benefit.priceModificationMethodCode`, '');
			}
			setFieldValue(`${valueEligbility}.benefit.cashback`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextMessage`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextType`, undefined);
		}

		if (fieldbenefit.benefitType == 'FREE_TEXT') {
			setFieldValue(`${valueEligbility}.benefit.cashback`, undefined);
			setFieldValue(`${valueEligbility}.benefit.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.benefit.priceModificationMethodCode`, '');
			setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined);
			setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, undefined);
			setFieldValue(`${valueEligbility}.benefit.newPriceAmount`, undefined);
			setFieldValue(`${valueEligbility}.benefit.priceModificationQuantity`, undefined);
			if (!fieldbenefit?.freeTextMessage) {
				setFieldValue(`${valueEligbility}.benefit.freeTextMessage`, '');
			}
		}
		if (fieldbenefit.benefitType == 'EMI') {
			if (
				fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'AMOUNT_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'MARKDOWN' ||
				fieldbenefit.priceModificationMethodCode == 'KIT_MARKDOWN' ||
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == '' ||
				!fieldbenefit.priceModificationMethodCode
			) {
				if (fieldPayment?.payment?.paymentInformation?.emiType == 'ONE_EMI_FREE') {
					if (!fieldbenefit.priceModificationMethodCode)
						setFieldValue(
							`${valueEligbility}.benefit.priceModificationMethodCode`,
							'ONE_EMI_FREE_PERCENTAGE_OFF'
						);
				} else {
					if (!fieldbenefit.priceModificationMethodCode)
						// setFieldValue(`${valueEligbility}.benefit.priceModificationMethodCode`, "EMI_PERCENTAGE_OFF");
						setFieldValue(`${valueEligbility}.benefit.priceModificationMethodCode`, '');
				}
			}
			setFieldValue(`${valueEligbility}.benefit.cashback`, undefined);
			setFieldValue(`${valueEligbility}.benefit.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextMessage`, undefined);
			setFieldValue(`${valueEligbility}.benefit.freeTextType`, undefined);
			setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined);
			setFieldValue(`${valueEligbility}.benefit.newPriceAmount`, undefined);
			setFieldValue(`${valueEligbility}.benefit.priceModificationQuantity`, undefined);
			setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, undefined);
		}
		if (fieldbenefit.benefitType == 'FREEBIE') {
			if (
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
				fieldbenefit.priceModificationMethodCode == 'EMI_APPORTIONED_PERCENTAGE_OFF'
			) {
				// setFieldValue(`${valueEligbility}.benefit.priceModificationMethodCode`, "");
			}

			setFieldValue(`${valueEligbility}.benefit.cashback`, undefined);
			setFieldValue(`${valueEligbility}.benefit.loyalty`, undefined);
			if (!fieldbenefit.priceModificationMethodCode) {
				setFieldValue(`${valueEligbility}.benefit.priceModificationMethodCode`, '');
				// setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined)
				// setFieldValue(`${valueEligbility}.benefit.freeTextMessage`, "")
				setFieldValue(`${valueEligbility}.benefit.freeTextMessage`, undefined);
				setFieldValue(`${valueEligbility}.benefit.freeTextType`, undefined);
			}
			setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined);
			setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, undefined);
			setFieldValue(`${valueEligbility}.benefit.newPriceAmount`, undefined);
			setFieldValue(`${valueEligbility}.benefit.priceModificationQuantity`, undefined);
			setFieldValue(
				`${valueEligbility}.benefit.priceModificationMethodCode`,
				'PERCENTAGE_OFF'
			);
			setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, '100');
		}
	}, [fieldbenefit.benefitType]);

	useEffect(() => {
		if (fieldbenefit.benefitType == 'INSTANT' || fieldbenefit.benefitType == 'EARN_BURN') {
			if (fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF') {
				if (!fieldbenefit.priceModificationPercent) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, '0.01');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, undefined);
				setFieldValue(`${valueEligbility}.benefit.newPriceAmount`, undefined);
				setFieldValue(`${valueEligbility}.benefit.priceModificationQuantity`, undefined);
			} else if (fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF') {
				if (!fieldbenefit.priceModificationPercent) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, '0.01');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, undefined);
				setFieldValue(`${valueEligbility}.benefit.newPriceAmount`, undefined);
				setFieldValue(`${valueEligbility}.benefit.priceModificationQuantity`, undefined);
			} else if (fieldbenefit.priceModificationMethodCode == 'AMOUNT_OFF') {
				if (!fieldbenefit.priceModificationAmount) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, '0.01');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined);
				setFieldValue(`${valueEligbility}.benefit.newPriceAmount`, undefined);
				setFieldValue(`${valueEligbility}.benefit.priceModificationQuantity`, undefined);
			} else if (fieldbenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF') {
				if (!fieldbenefit.priceModificationAmount) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, '0.01');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined);
				setFieldValue(`${valueEligbility}.benefit.newPriceAmount`, undefined);
				setFieldValue(`${valueEligbility}.benefit.priceModificationQuantity`, undefined);
			} else if (fieldbenefit.priceModificationMethodCode == 'MARKDOWN') {
				if (!fieldbenefit.newPriceAmount) {
					setFieldValue(`${valueEligbility}.benefit.newPriceAmount`, '0.01');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, undefined);
				setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined);
				setFieldValue(`${valueEligbility}.benefit.priceModificationQuantity`, undefined);
			} else if (fieldbenefit.priceModificationMethodCode == 'KIT_MARKDOWN') {
				if (!fieldbenefit.newPriceAmount) {
					setFieldValue(`${valueEligbility}.benefit.newPriceAmount`, '0.01');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, undefined);
				setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined);
				setFieldValue(`${valueEligbility}.benefit.priceModificationQuantity`, undefined);
			} else if (fieldbenefit.priceModificationMethodCode == 'QUANTITY_PRICING') {
				if (!fieldbenefit.priceModificationQuantity) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationQuantity`, '1');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, undefined);
				setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined);
				setFieldValue(`${valueEligbility}.benefit.newPriceAmount`, undefined);
			}
			setFieldValue(`${valueEligbility}.benefit.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.benefit.cashback`, undefined);
		} else if (fieldbenefit.benefitType == 'CASHBACK') {
			if (fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF') {
				if (!fieldbenefit.priceModificationPercent) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, '0.01');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, undefined);
			} else if (fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF') {
				if (!fieldbenefit.priceModificationPercent) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, '0.01');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, undefined);
			} else if (fieldbenefit.priceModificationMethodCode == 'AMOUNT_OFF') {
				if (!fieldbenefit.priceModificationAmount) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, '0.01');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined);
			} else if (fieldbenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF') {
				if (!fieldbenefit.priceModificationAmount) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationAmount`, '0.01');
				}
				setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, undefined);
			}
			setFieldValue(`${valueEligbility}.benefit.loyalty`, undefined);
		} else if (fieldbenefit.benefitType == 'LOYALTY_POINTS') {
			// if (fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS') {
			if (
				fieldbenefit.loyalty == undefined ||
				fieldbenefit.loyalty?.baseEarnPointEligibility
			) {
				setFieldValue(`${valueEligbility}.benefit.loyalty.baseEarnPointEligibility`, true);
			}
			if (
				fieldbenefit.loyalty == undefined ||
				fieldbenefit.loyalty?.considerBaseRateForItem
			) {
				setFieldValue(`${valueEligbility}.benefit.loyalty.considerBaseRateForItem`, true);
			}
			// }
			if (
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE'
			) {
				if (!fieldbenefit.loyalty?.loyaltyAdditionalPoint) {
					setFieldValue(
						`${valueEligbility}.benefit.loyalty.loyaltyAdditionalPoint`,
						'0.01'
					);
				}
				setFieldValue(`${valueEligbility}.benefit.loyalty.loyaltyMultiplier`, undefined);
			} else if (
				fieldbenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
				fieldbenefit.priceModificationMethodCode ==
					'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE'
			) {
				if (!fieldbenefit.loyalty?.loyaltyMultiplier) {
					setFieldValue(`${valueEligbility}.benefit.loyalty.loyaltyMultiplier`, '1');
				}
				setFieldValue(
					`${valueEligbility}.benefit.loyalty.loyaltyAdditionalPoint`,
					undefined
				);
			}
			setFieldValue(`${valueEligbility}.benefit.cashback`, undefined);
		} else if (fieldbenefit.benefitType == 'FREEBIE') {
			if (fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF') {
				if (!fieldbenefit.priceModificationPercent) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, '100');
				}
			} else if (fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF') {
				if (!fieldbenefit.priceModificationPercent) {
					setFieldValue(`${valueEligbility}.benefit.priceModificationPercent`, '100');
				}
			}
			setFieldValue(`${valueEligbility}.benefit.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.benefit.cashback`, undefined);
		} else if (fieldbenefit.benefitType == 'FREE_TEXT' || fieldbenefit.benefitType == 'EMI') {
			setFieldValue(`${valueEligbility}.benefit.loyalty`, undefined);
			setFieldValue(`${valueEligbility}.benefit.cashback`, undefined);
		}
	}, [fieldbenefit.priceModificationMethodCode]);

	const getBenefitTypeDropdown = () => {
		{
			return valueEligbility == 'scaleTransaction'
				? dropdownValues.benefitTypesForScaleTransaction.map((val, index) => {
						return (
							<option key={index + ''} value={val.value}>
								{val.name}
							</option>
						);
				  })
				: valueEligbility == 'simplePromotionEligibility'
				? dropdownValues.benefitTypesForSimplePromotion.map((val, index) => {
						return (
							<option key={index + ''} value={val.value}>
								{val.name}
							</option>
						);
				  })
				: valueEligbility == 'earnBurnEligibility'
				? dropdownValues.benefitTypesForEarnBurnPromotion.map((val, index) => {
						return (
							<option key={index + ''} value={val.value}>
								{val.name}
							</option>
						);
				  })
				: dropdownValues.benefitTypes.map((val, index) => {
						return (
							<option key={index + ''} value={val.value}>
								{val.name}
							</option>
						);
				  });
		}
	};

	const getCollectionValidityDropdown = () => {
		return dropdownValues.collectionValidity.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const getPriceMethodCodeDropdown = () => {
		if (fieldbenefit.benefitType == 'INSTANT') {
			return priceMethodCodeDropdown.INSTANT.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldbenefit.benefitType == 'CASHBACK') {
			return priceMethodCodeDropdown.CASHBACK.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldbenefit.benefitType == 'LOYALTY_POINTS') {
			return priceMethodCodeDropdown.LOYALTY_POINTS.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldbenefit.benefitType == 'FREEBIE') {
			return priceMethodCodeDropdown.FREEBIE.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else if (fieldbenefit.benefitType == 'EMI') {
			if (
				fieldPayment?.payment?.paymentInformation?.emiType == 'ONE_EMI_FREE' ||
				fieldPayment?.payment?.emiType == 'ONE_EMI_FREE' ||
				field.value?.totalPurchaseMarketBasedPriceDerivationRuleEligibiity
					?.paymentEligibility?.payment?.paymentInformation?.emiType == 'ONE_EMI_FREE'
			) {
				return priceMethodCodeDropdown.EMI1.map((val, index) => {
					return (
						<option key={index + ''} value={val.value}>
							{val.name}
						</option>
					);
				});
			} else {
				return priceMethodCodeDropdown.EMI.map((val, index) => {
					return (
						<option key={index + ''} value={val.value}>
							{val.name}
						</option>
					);
				});
			}
		}
	};

	const getPriceMethodCodeDropdownEB = () => {
		if (fieldbenefit.benefitType == 'EARN_BURN') {
			return priceMethodCodeDropdown.INSTANT.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		}
	};

	const getPriceMethodCodeDropdownSP = () => {
		return priceMethodCodeDropdown.INSTANT_SIMPLEPROMO.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const getPriceMethodCodeDropdownMBG = () => {
		return priceMethodCodeDropdown.INSTANT_MULTIBUYGROUP.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const getContentBelowToBenefitType = () => {
		if (fieldbenefit.benefitType == 'INSTANT' || fieldbenefit.benefitType == 'EARN_BURN') {
			return (
				<>
					{valueEligbility == 'simplePromotionEligibility' ? (
						<CustomSelect
							name={`${valueEligbility}.benefit.priceModificationMethodCode`}
							required={true}
							label={
								isAuditFlw ? (
									<>
										{fieldbenefit.benefitType == 'INSTANT'
											? '(For Instant Discount)'
											: '(For Earn Burn)'}
										<br />
										{localConst.priceMethodCode}{' '}
									</>
								) : (
									localConst.priceMethodCode
								)
							}
							options={getPriceMethodCodeDropdownSP()}
							smallText={itemHelp.InstantPMMC_MBG}
						/>
					) : valueEligbility == 'groupMultibuyEligbility' ||
					  valueEligbility == 'multibuyEligbility' ||
					  valueEligbility == 'merchandizeHierarchyEligibility' ? (
						<CustomSelect
							name={`${valueEligbility}.benefit.priceModificationMethodCode`}
							required={true}
							label={
								isAuditFlw ? (
									<>
										{fieldbenefit.benefitType == 'INSTANT'
											? '(For Instant Discount)'
											: '(For Earn Burn)'}
										<br />
										{localConst.priceMethodCode}{' '}
									</>
								) : (
									localConst.priceMethodCode
								)
							}
							options={getPriceMethodCodeDropdownMBG()}
							smallText={itemHelp.InstantPMMC_MBG1}
						/>
					) : valueEligbility == 'earnBurnEligibility' ? (
						<CustomSelect
							name={`${valueEligbility}.benefit.priceModificationMethodCode`}
							required={true}
							label={
								isAuditFlw ? (
									<>
										{fieldbenefit.benefitType == 'INSTANT'
											? '(For Instant Discount)'
											: '(For Earn Burn)'}
										<br />
										{localConst.priceMethodCode}{' '}
									</>
								) : (
									localConst.priceMethodCode
								)
							}
							options={getPriceMethodCodeDropdownEB()}
							smallText={itemHelp.InstantPMMC_MBG}
						/>
					) : (
						<CustomSelect
							name={`${valueEligbility}.benefit.priceModificationMethodCode`}
							required={true}
							label={
								isAuditFlw ? (
									<>
										{fieldbenefit.benefitType == 'INSTANT'
											? '(For Instant Discount)'
											: '(For Earn Burn)'}
										<br />
										{localConst.priceMethodCode}{' '}
									</>
								) : (
									localConst.priceMethodCode
								)
							}
							options={getPriceMethodCodeDropdown()}
							smallText={itemHelp.InstantPMMC_MBG}
						/>
					)}
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldbenefit.benefitType == 'CASHBACK') {
			return (
				<>
					<CustomSelect
						name={`${valueEligbility}.benefit.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw ? (
								<>
									(For Cashback)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.CashBackPMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldbenefit.benefitType == 'LOYALTY_POINTS') {
			return (
				<>
					<CustomSelect
						name={`${valueEligbility}.benefit.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw ? (
								<>
									(For Loyalty Points)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.loyaltyPMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldbenefit.benefitType == 'FREEBIE') {
			return (
				<>
					<CustomSelect
						name={`${valueEligbility}.benefit.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw ? (
								<>
									(For Freebies)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.FreeBiePMMC_MBG}
					/>
					{getContentBelowToPMMC()}
				</>
			);
		}

		if (fieldbenefit.benefitType == 'FREE_TEXT') {
			return (
				<>
					<CustomInputText
						name={`${valueEligbility}.benefit.freeTextType`}
						required={false}
						label={'Free Text Type'}
						smallText=""
					/>
					{valueEligbility != 'scaleTransaction' ? (
						<CustomInputText
							name={`${valueEligbility}.benefit.freeTextMessage`}
							required={false}
							label={'Free Text Message'}
							smallText="Specify the free gift message to be displayed (i.e Special Gift Items worth Rs 100)"
						/>
					) : undefined}
				</>
			);
		}

		if (fieldbenefit.benefitType == 'EMI') {
			return (
				<>
					<CustomSelect
						name={`${valueEligbility}.benefit.priceModificationMethodCode`}
						required={true}
						label={
							isAuditFlw ? (
								<>
									(For EMI)
									<br />
									{localConst.priceMethodCode}{' '}
								</>
							) : (
								localConst.priceMethodCode
							)
						}
						options={getPriceMethodCodeDropdown()}
						smallText={itemHelp.EMI_PMMC_MBG}
					/>
				</>
			);
		}
	};

	const getContentBelowToPMMC = () => {
		if (
			fieldbenefit.priceModificationMethodCode == 'PERCENTAGE_OFF' ||
			fieldbenefit.priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF'
		) {
			if (
				valueEligbility != 'scaleTransaction' &&
				(fieldbenefit.benefitType == 'INSTANT' || fieldbenefit.benefitType == 'EARN_BURN')
			) {
				return (
					<>
						{valueEligbility != 'simplePromotionEligibility' ? (
							<CustomInputText
								name={`${valueEligbility}.benefit.priceModificationPercent`}
								required={true}
								label={
									isAuditFlw ? (
										<>
											(For{' '}
											{fieldbenefit.benefitType == 'INSTANT'
												? 'Instant Discount'
												: 'Earn Burn'}{' '}
											&gt;{' '}
											{fieldbenefit.priceModificationMethodCode ==
											'PERCENTAGE_OFF'
												? 'Percentage Off'
												: 'Apportioned Percentage Off'}
											)<br />
											{localConst.priceModificationPercent}{' '}
										</>
									) : (
										localConst.priceModificationPercent
									)
								}
								smallText="Determines the benefit percent for the promotion"
								disabled={false}
							/>
						) : undefined}
					</>
				);
			} else if (fieldbenefit.benefitType == 'CASHBACK') {
				return (
					<>
						{valueEligbility != 'scaleTransaction' ? (
							<CustomInputText
								name={`${valueEligbility}.benefit.priceModificationPercent`}
								required={true}
								label={
									isAuditFlw ? (
										<>
											(For Cashback &gt;{' '}
											{fieldbenefit.priceModificationMethodCode ==
											'PERCENTAGE_OFF'
												? 'Percentage Off'
												: 'Apportioned Percentage Off'}
											)<br />
											{localConst.priceModificationPercent}{' '}
										</>
									) : (
										localConst.priceModificationPercent
									)
								}
								smallText="Determines the benefit percent for the promotion"
								disabled={false}
							/>
						) : undefined}
						{fieldPayment?.payment?.paymentGroup ? null : (
							<>
								<CustomInputText
									name={`${valueEligbility}.benefit.cashback.paymentInformation.paymentMode`}
									required={false}
									label={localConst.paymentMode}
									smallText="Defines the Payment eligibility Mode for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.benefit.cashback.paymentInformation.provider`}
									required={false}
									label={localConst.provider}
									smallText="Defines the Payment eligibility Provider for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.benefit.cashback.paymentInformation.paymentType`}
									required={false}
									label={localConst.paymentType}
									smallText="Defines the Payment eligibility Type for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.benefit.cashback.paymentInformation.paymentSubType`}
									required={false}
									label={localConst.paymentSubType}
									smallText="Defines the Payment eligibility SubType for which the promotion is applicable"
								/>

								<CustomSelect
									name={`${valueEligbility}.benefit.cashback.creditBy.cashbackCreditData`}
									required={false}
									isBottomScroll={true}
									label="Credit By"
									options={getCollectionValidityDropdown()}
								/>

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Date' ? (
									<>
										<CustomDateTime
											name={`${valueEligbility}.benefit.cashback.creditBy.date`}
											required={true}
											label="Date(yyyy-mm-dd)"
											validate={customValidateCashBackCreditByDate}
											smallText={localConst.subCashBackCreditByDate}
											valueDate={fieldbenefit.cashback.creditBy.date}
											isBottomDateTimePicker={true}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.date = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Months' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.benefit.cashback.creditBy.months`}
											required={true}
											label="Months"
											validate={customValidateCashBackCreditByMonths}
											smallText={localConst.subCashBackCreditByMonths}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.months = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Days' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.benefit.cashback.creditBy.days`}
											required={true}
											label="Days"
											validate={customValidateCashBackCreditByDays}
											smallText={localConst.subCashBackCreditByDays}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.days = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Hours' ? (
									<>
										<CustomHoursPicker
											name={`${valueEligbility}.benefit.cashback.creditBy.hours`}
											required={true}
											label="Hours(HH:mm)"
											validate={customValidateCashBackCreditByHours}
											smallText={localConst.subCashBackCreditByHours}
											valueHour={fieldbenefit.cashback.creditBy.hours}
											isBottomDateTimePicker={true}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.hours = undefined)
									) : null
								) : null}

								<CustomSelect
									name={`${valueEligbility}.benefit.cashback.expireOn.cashbackExpireData`}
									required={false}
									isBottomScroll={true}
									label="Expire On"
									options={getCollectionValidityDropdown()}
								/>

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Date' ? (
									<>
										<CustomDateTime
											name={`${valueEligbility}.benefit.cashback.expireOn.date`}
											required={true}
											label="Date(yyyy-mm-dd)"
											validate={customValidateCashBackExpireOnDate}
											smallText={localConst.subCashBackExpireOnDate}
											valueDate={fieldbenefit.cashback.expireOn.date}
											isBottomDateTimePicker={true}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.date = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Months' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.benefit.cashback.expireOn.months`}
											required={true}
											label="Months"
											validate={customValidateCashBackCreditByMonths}
											smallText={localConst.subCashBackExpireOnMonths}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.months = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Days' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.benefit.cashback.expireOn.days`}
											required={true}
											label="Days"
											validate={customValidateCashBackCreditByDays}
											smallText={localConst.subCashBackExpireOnDays}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.days = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Hours' ? (
									<>
										<CustomHoursPicker
											name={`${valueEligbility}.benefit.cashback.expireOn.hours`}
											required={true}
											label="Hours(HH:mm)"
											validate={customValidateCashBackCreditByHours}
											smallText={localConst.subCashBackExpireOnHours}
											valueHour={fieldbenefit.cashback.expireOn.hours}
											isBottomDateTimePicker={true}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.hours = undefined)
									) : null
								) : null}
							</>
						)}
					</>
				);
			} else if (fieldbenefit.benefitType == 'FREEBIE') {
				return (
					<CustomInputText
						name={`${valueEligbility}.benefit.priceModificationPercent`}
						readOnly={true}
						label={
							isAuditFlw ? (
								<>
									(For Freebies &gt; Percentage Off)
									<br />
									{localConst.priceModificationPercent}{' '}
								</>
							) : (
								localConst.priceModificationPercent
							)
						}
						smallText="Determines the benefit percent for the promotion"
						disabled={false}
					/>
				);
			}
		} else {
			fieldbenefit.priceModificationPercent = undefined;
		}

		if (
			fieldbenefit.priceModificationMethodCode == 'AMOUNT_OFF' ||
			fieldbenefit.priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF'
		) {
			if (
				valueEligbility != 'scaleTransaction' &&
				(fieldbenefit.benefitType == 'INSTANT' || fieldbenefit.benefitType == 'EARN_BURN')
			) {
				return (
					<>
						{valueEligbility != 'simplePromotionEligibility' ? (
							<CustomInputText
								name={`${valueEligbility}.benefit.priceModificationAmount`}
								required={true}
								label={
									isAuditFlw ? (
										<>
											(For{' '}
											{fieldbenefit.benefitType == 'INSTANT'
												? 'Instant Discount'
												: 'Earn Burn'}{' '}
											&gt;{' '}
											{fieldbenefit.priceModificationMethodCode ==
											'AMOUNT_OFF'
												? 'Amount Off'
												: 'Apportioned Amount Off'}
											)<br />
											{localConst.priceModificationAmount}{' '}
										</>
									) : (
										localConst.priceModificationAmount
									)
								}
								smallText="Define the benefit amount for the promotion"
								disabled={false}
							/>
						) : undefined}
					</>
				);
			} else if (fieldbenefit.benefitType == 'CASHBACK') {
				return (
					<>
						{valueEligbility != 'scaleTransaction' ? (
							<CustomInputText
								name={`${valueEligbility}.benefit.priceModificationAmount`}
								required={true}
								label={
									isAuditFlw ? (
										<>
											(For Cashback &gt;{' '}
											{fieldbenefit.priceModificationMethodCode ==
											'AMOUNT_OFF'
												? 'Amount Off'
												: 'Apportioned Amount Off'}
											)<br />
											{localConst.priceModificationAmount}{' '}
										</>
									) : (
										localConst.priceModificationAmount
									)
								}
								smallText="Define the benefit amount for the promotion"
								disabled={false}
							/>
						) : undefined}
						{fieldPayment?.payment?.paymentGroup ? null : (
							<>
								<CustomInputText
									name={`${valueEligbility}.benefit.cashback.paymentInformation.paymentMode`}
									required={false}
									label={localConst.paymentMode}
									smallText="Defines the Payment eligibility Mode for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.benefit.cashback.paymentInformation.provider`}
									required={true}
									label={localConst.provider}
									smallText="Defines the Payment eligibility Provider for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.benefit.cashback.paymentInformation.paymentType`}
									required={false}
									label={localConst.paymentType}
									smallText="Defines the Payment eligibility Type for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.benefit.cashback.paymentInformation.paymentSubType`}
									required={false}
									label={localConst.paymentSubType}
									smallText="Defines the Payment eligibility SubType for which the promotion is applicable"
								/>

								<CustomSelect
									name={`${valueEligbility}.benefit.cashback.creditBy.cashbackCreditData`}
									required={false}
									isBottomScroll={true}
									label="Credit By"
									options={getCollectionValidityDropdown()}
								/>

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Date' ? (
									<>
										<CustomDateTime
											name={`${valueEligbility}.benefit.cashback.creditBy.date`}
											required={true}
											label="Date(yyyy-mm-dd)"
											valueDate={fieldbenefit.cashback.creditBy.date}
											isBottomDateTimePicker={true}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.date = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Months' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.benefit.cashback.creditBy.months`}
											required={true}
											label="Months"
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.months = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Days' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.benefit.cashback.creditBy.days`}
											required={true}
											label="Days"
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.days = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.creditBy &&
								fieldbenefit.cashback.creditBy.cashbackCreditData == 'Hours' ? (
									<>
										<CustomHoursPicker
											name={`${valueEligbility}.benefit.cashback.creditBy.hours`}
											required={true}
											label="Hours(HH:mm)"
											valueHour={fieldbenefit.cashback.creditBy.hours}
											isBottomDateTimePicker={true}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.creditBy ? (
										(fieldbenefit.cashback.creditBy.hours = undefined)
									) : null
								) : null}

								<CustomSelect
									name={`${valueEligbility}.benefit.cashback.expireOn.cashbackExpireData`}
									required={false}
									isBottomScroll={true}
									label="Expire On"
									options={getCollectionValidityDropdown()}
								/>

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Date' ? (
									<>
										<CustomDateTime
											name={`${valueEligbility}.benefit.cashback.expireOn.date`}
											required={true}
											label="Date(yyyy-mm-dd)"
											validate={customValidateCashBackExpireOnDate}
											smallText={localConst.subCashBackExpireOnDate}
											valueDate={fieldbenefit.cashback.expireOn.date}
											isBottomDateTimePicker={true}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.date = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Days' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.benefit.cashback.expireOn.days`}
											required={true}
											label="Days"
											validate={customValidateCashBackCreditByDays}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.days = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Months' ? (
									<>
										<CustomInputText
											name={`${valueEligbility}.benefit.cashback.expireOn.months`}
											required={true} // while giving true, text field is not rendering
											label="Months"
											validate={customValidateCashBackCreditByMonths}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.months = undefined)
									) : null
								) : null}

								{fieldbenefit.cashback &&
								fieldbenefit.cashback.expireOn &&
								fieldbenefit.cashback.expireOn.cashbackExpireData == 'Hours' ? (
									<>
										<CustomHoursPicker
											name={`${valueEligbility}.benefit.cashback.expireOn.hours`}
											required={true}
											label="Hours(HH:mm)"
											validate={customValidateCashBackCreditByHours}
											valueHour={fieldbenefit.cashback.expireOn.hours}
											isBottomDateTimePicker={true}
										/>
									</>
								) : fieldbenefit.cashback ? (
									fieldbenefit.cashback.expireOn ? (
										(fieldbenefit.cashback.expireOn.hours = undefined)
									) : null
								) : null}
							</>
						)}
					</>
				);
			}
		} else {
			fieldbenefit.priceModificationAmount = undefined;
		}

		if (
			valueEligbility != 'scaleTransaction' &&
			(fieldbenefit.priceModificationMethodCode == 'MARKDOWN' ||
				fieldbenefit.priceModificationMethodCode == 'KIT_MARKDOWN')
		) {
			if (
				valueEligbility != 'scaleTransaction' &&
				(fieldbenefit.benefitType == 'INSTANT' || fieldbenefit.benefitType == 'EARN_BURN')
			) {
				return (
					<>
						{valueEligbility != 'simplePromotionEligibility' ? (
							<CustomInputText
								name={`${valueEligbility}.benefit.newPriceAmount`}
								required={true}
								label={
									isAuditFlw ? (
										<>
											(For{' '}
											{fieldbenefit.benefitType == 'INSTANT'
												? 'Instant Discount'
												: 'Earn Burn'}{' '}
											&gt;{' '}
											{fieldbenefit.priceModificationMethodCode == 'MARKDOWN'
												? 'Markdown'
												: 'Kit Markdown'}
											)<br />
											{localConst.newPriceAmount}{' '}
										</>
									) : (
										localConst.newPriceAmount
									)
								}
								smallText="Determines the new price for the product/sku in the promotion"
								disabled={false}
							/>
						) : undefined}
					</>
				);
			}
		} else {
			fieldbenefit.newPriceAmount = undefined;
		}

		if (
			(valueEligbility == 'groupMultibuyEligbility' ||
				valueEligbility == 'multibuyEligbility' ||
				valueEligbility == 'merchandizeHierarchyEligibility') &&
			fieldbenefit.priceModificationMethodCode == 'QUANTITY_PRICING'
		) {
			if (fieldbenefit.benefitType == 'INSTANT') {
				return (
					<>
						{valueEligbility != 'simplePromotionEligibility' ? (
							<CustomInputText
								name={`${valueEligbility}.benefit.priceModificationQuantity`}
								required={true}
								label={
									isAuditFlw ? (
										<>
											(For Instant Discount &gt; Quantity Pricing )<br />
											{localConst.priceModificationQuantity}{' '}
										</>
									) : (
										localConst.priceModificationQuantity
									)
								}
								smallText="Determines the benefit quantity for the promotion"
								disabled={false}
							/>
						) : undefined}
					</>
				);
			}
		} else {
			fieldbenefit.priceModificationQuantity = undefined;
		}

		if (
			fieldbenefit.priceModificationMethodCode == 'LOYALTY_ADDITIONAL_POINTS' ||
			fieldbenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_POINTS' ||
			fieldbenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_ADDITIONAL_POINTS_ON_UNIT_PRICE'
		) {
			return (
				<>
					{valueEligbility != 'scaleTransaction' ? (
						<CustomInputText
							name={`${valueEligbility}.benefit.loyalty.loyaltyAdditionalPoint`}
							required={true}
							label={'Loyalty Additional Point'}
							smallText="Determines the additional point as benefit for the promotion"
							validate={customValidateloyaltyAdditionalPoint}
						/>
					) : undefined}
					<CustomInputRadio
						name={`${valueEligbility}.benefit.loyalty.baseEarnPointEligibility`}
						label={'Apply Base Earn'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={
							'Determines whether the Base earn Point should be applied for the promotion'
						}
					/>
					<CustomInputRadio
						name={`${valueEligbility}.benefit.loyalty.considerBaseRateForItem`}
						label={'Consider Base Rate For Item'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={'Determines whether to Consider Base Rate For Item'}
					/>

					<CustomSelect
						name={`${valueEligbility}.benefit.loyalty.expireOn.loyaltyExpireData`}
						required={false}
						isBottomScroll={true}
						label="Expire On"
						options={getCollectionValidityDropdown()}
					/>

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Date' ? (
						<>
							<CustomDateTime
								name={`${valueEligbility}.benefit.loyalty.expireOn.date`}
								required={true}
								validate={customValidateCashBackCreditByDate}
								label="Date(yyyy-mm-dd)"
								valueDate={fieldbenefit.loyalty.expireOn.date}
								isBottomDateTimePicker={true}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.date = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Months' ? (
						<>
							<CustomInputText
								name={`${valueEligbility}.benefit.loyalty.expireOn.months`}
								required={true}
								validate={customValidateCashBackCreditByMonths}
								label="Months"
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.months = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Days' ? (
						<>
							<CustomInputText
								name={`${valueEligbility}.benefit.loyalty.expireOn.days`}
								required={true}
								validate={customValidateCashBackCreditByDays}
								label="Days"
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.days = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Hours' ? (
						<>
							<CustomHoursPicker
								name={`${valueEligbility}.benefit.loyalty.expireOn.hours`}
								required={true}
								validate={customValidateCashBackCreditByHours}
								label="Hours(HH:mm)"
								valueHour={fieldbenefit.loyalty.expireOn.hours}
								isBottomDateTimePicker={true}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.hours = undefined)
						) : null
					) : null}
				</>
			);
		} else {
			fieldbenefit.newPriceAmount = undefined;
		}

		if (
			fieldbenefit.priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
			fieldbenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
			fieldbenefit.priceModificationMethodCode ==
				'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE'
		) {
			return (
				<>
					{valueEligbility != 'scaleTransaction' ? (
						<CustomInputText
							name={`${valueEligbility}.benefit.loyalty.loyaltyMultiplier`}
							required={true}
							label={'Loyalty Multiplier Point'}
							smallText="Determines the multiplier point as benefit for the promotion"
						/>
					) : undefined}
					<CustomInputRadio
						name={`${valueEligbility}.benefit.loyalty.baseEarnPointEligibility`}
						label={'Apply Base Earn'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={
							'Determines whether the Base earn Point should be applied for the promotion'
						}
					/>
					<CustomInputRadio
						name={`${valueEligbility}.benefit.loyalty.considerBaseRateForItem`}
						label={'Consider Base Rate For Item'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={'Determines whether to Consider Base Rate For Item'}
					/>

					<CustomSelect
						name={`${valueEligbility}.benefit.loyalty.expireOn.loyaltyExpireData`}
						required={false}
						isBottomScroll={true}
						label="Expire On"
						options={getCollectionValidityDropdown()}
					/>

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Date' ? (
						<>
							<CustomDateTime
								name={`${valueEligbility}.benefit.loyalty.expireOn.date`}
								required={true}
								label="Date(yyyy-mm-dd)"
								validate={customValidateCashBackCreditByDate}
								valueDate={fieldbenefit.loyalty.expireOn.date}
								isBottomDateTimePicker={true}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.date = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Months' ? (
						<>
							<CustomInputText
								name={`${valueEligbility}.benefit.loyalty.expireOn.months`}
								required={true}
								validate={customValidateCashBackCreditByMonths}
								label="Months"
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.months = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Days' ? (
						<>
							<CustomInputText
								name={`${valueEligbility}.benefit.loyalty.expireOn.days`}
								required={true}
								label="Days"
								validate={customValidateCashBackCreditByDays}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.days = undefined)
						) : null
					) : null}

					{fieldbenefit.loyalty &&
					fieldbenefit.loyalty.expireOn &&
					fieldbenefit.loyalty.expireOn.loyaltyExpireData == 'Hours' ? (
						<>
							<CustomHoursPicker
								name={`${valueEligbility}.benefit.loyalty.expireOn.hours`}
								required={true}
								label="Hours(HH:mm)"
								validate={customValidateCashBackCreditByHours}
								valueHour={fieldbenefit.loyalty.expireOn.hours}
								isBottomDateTimePicker={true}
							/>
						</>
					) : fieldbenefit.loyalty ? (
						fieldbenefit.loyalty.expireOn ? (
							(fieldbenefit.loyalty.expireOn.hours = undefined)
						) : null
					) : null}
				</>
			);
		} else {
			fieldbenefit.newPriceAmount = undefined;
		}
	};
	const onProductTableChange = (val) => {
		//debugger;
		props.onProductTableChanged(val);
	};
	const onMerchTableChange = (val) => {
		//debugger
		ScaleMerchTableData = val;

		props.onMerchTableDataChange(ScaleMerchTableData);
	};
	return (
		<div>
			<Form>
				<CustomSelect
					name={`${valueEligbility}.benefit.benefitType`}
					required={true}
					label={localConst.benefitType}
					options={getBenefitTypeDropdown()}
					smallText={
						valueEligbility == 'earnBurnEligibility'
							? itemHelp.BenifitTypeEarnBurn
							: itemHelp.BenifitTypeMBG
					}
					disabled={
						fieldPayment?.payment?.paymentGroup &&
						fieldbenefit?.benefitType == 'CASHBACK'
					}
				/>

				{getContentBelowToBenefitType()}
				{valueEligbility == 'scaleTransaction' ? (
					field.value.scaleTransaction.benefit.priceModificationMethodCode ? (
						<MultiBuyProductTableST
							data={props.data}
							onProductTableChange={onProductTableChange}
						/>
					) : undefined
				) : undefined}
				{valueEligbility == 'simplePromotionEligibility' ? (
					field.value.simplePromotionEligibility.benefit.priceModificationMethodCode ? (
						<>
							{isAuditFlw ? (
								<div className="container">
									<AuditSimpleProductTable
										oldJson={props.prodData}
										newJson={
											JSON.parse(sessionStorage.getItem('NewPolicy'))
												.promotionPolicy.priceDerivationRuleEligibility
												.simplePromotionEligibility.multibuyProduct
										}
										priceModMethodCode={
											field.value.simplePromotionEligibility.benefit
												.priceModificationMethodCode
										}
									/>
									<AuditSimpleSHTable
										oldJson={props.data}
										newJson={
											JSON.parse(sessionStorage.getItem('NewPolicy'))
												.promotionPolicy.priceDerivationRuleEligibility
												.simplePromotionEligibility
												.merchandizeHierarchyGroup
										}
										priceModMethodCode={
											field.value.simplePromotionEligibility.benefit
												.priceModificationMethodCode
										}
									/>
								</div>
							) : (
								<>
									<SimplePromotionProductTable
										data1={props.prodData}
										keys={keysSimplepromo}
										defaultValueForAdd={defaultValueForSimplePromotion}
										onProductTableChange={onsimpleTableChanged}
										eligibility="simplePromotionEligibility"
										pricemodificationmedthodcode={
											field.value.simplePromotionEligibility.benefit
												.priceModificationMethodCode
										}
									/>
									<SimplePromotionSellingTable
										data={props.data}
										keys={keysSimpleselling}
										defaultValueForAdd={defaultValueForSimplePromotion}
										onMerchTableChange={(val) => onMerchTableChange(val)}
										ScaleMerchTableData={ScaleMerchTableData}
										//data={props.data1}
										eligibility="simplePromotionEligibility"
										pricemodificationmedthodcode={
											field.value.simplePromotionEligibility.benefit
												.priceModificationMethodCode
										}
									/>
								</>
							)}
						</>
					) : undefined
				) : undefined}
			</Form>
		</div>
	);
};

/* const BenefitSettingsFrom = withSubForm(
    BenefitSettings,
    benefitSchema
); */

export default BenefitSettings;
