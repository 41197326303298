import React, { useEffect, useState } from 'react';
import { useField, Form, useFormikContext } from 'formik';

import CustomInputText from './customfields/CustomInputText';
import CustomSelect from './customfields/CustomSelect';
import CollapseHeaderBar from './CollapseHeaderBar';
import CollapseBody from './CollapseBody';
import BenefitSettingsSCALEPROD from './BenefitSettingsSCALEPROD';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import PaymentCondition from './PaymentCondition';
import CouponCondition from './CouponCondition';
import ExclusionSetting from './ExclusionSetting';
import ProductTableBootstrapNew from './ProductTableBootstrapNew';
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import AuditProductTable from './Audit/AuditProductTable';
import AuditSHTable from './Audit/AuditSHTable';
import $ from 'jquery';

const localConst = {
	SelectID: 'Select ID',
	eligibilitySettings: 'Eligibility Settings',
	thresholdCode: 'Threshold Type Code',
	triggerCombinationCode: 'Trigger Combination Code',
	subTriggerCombinationCode:
		'Determines the combination code to be used between the trigger items for a promotion',
	thresholdQuantity: 'Threshold Quantity',
	rewardCombinationCode: 'Reward Combination Code',
	subRewardCombinationCode:
		'Determines the combination code to be used between the reward item for a promotion',
	rewardQuantity: 'Reward Quantity',
	benefitSettings: 'Benefit Settings',
	ProductSetting: 'Product / Selling Hierarchy Settings',
	PaymentConditions: 'Payment Conditions',
	CouponConditions: 'Coupon Conditions',
	ExclusionsSetting: 'Exclusion Settings',
	PurchaseandRewardConditions: 'Purchase and Reward Conditions',
	quantityLimit: 'Quantity Limit',
	SellingHierarchySettings: 'Selling Hierarchy Settings'
};
const dropdownValues = {
	promotionType: [
		{
			value: 'Interval Quantity Threshold',
			name: 'Interval Quantity Threshold'
		},
		{
			value: 'Quantity Threshold',
			name: 'Quantity Threshold'
		},
		{
			value: 'Interval Amount Threshold',
			name: 'Interval Amount Threshold'
		}
	],
	selectId: [
		{ value: '', name: 'Select one' },
		{ value: 'ITEM_ID', name: 'Product Id' },
		{ value: 'MERCHANDIZE', name: 'Selling Hierarchy Group Id' }
	]
};

// eslint-disable-next-line no-unused-vars
let ScaleMerchTableData = [];
// eslint-disable-next-line no-unused-vars
let productIDTableData = [];

const keys = ['productID', 'trigger', 'getReward', 'minimumPrice', 'maximumPrice'];

const keysMerch = ['merchandizeGroupId', 'trigger', 'getReward', 'minimumPrice', 'maximumPrice'];

/* const keys = [
  'productID', 'minimumPrice', 'maximumPrice'
] */

const EligibilityForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [SelectID, setSelectID] = useState('');
	const [productTableData, setProductTableData] = useState(props?.data ? props?.data : []);

	const [sHTableData, setSHTableData] = useState(props?.data2 ? props?.data2 : []);

	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();

	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility.scaledPriceEligibility
					.thresholdTypeCode != field.value.scaledPriceEligibility.thresholdTypeCode
			) {
				let tempName = 'scaledPriceEligibility.thresholdTypeCode';
				//
				$('[name="' + tempName + '"]')
					.closest('.container')
					.parent()
					.parent()
					.nextAll()
					.addClass('hide-nochange-section-for-audit');

				let tempName2 = 'scrollToBenefitSettings';
				$('[name="' + tempName2 + '"]').addClass('hide-nochange-section-for-audit');
			}
		}
	}, []);

	useEffect(() => {
		field.value.scaledPriceEligibility.multibuyProduct = productTableData;
		field.value.scaledPriceEligibility.merchandizeHierarchyGroup = sHTableData;

		setFieldValue('', '');
	}, [sHTableData, productTableData]);
	useEffect(() => {
		return () => {
			setProductTableData([]);
			setSHTableData([]);
		};
	}, []);

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;

	//useValues(name, props);

	// const [field, meta, helpers] = useField(props);

	//

	if (Object.keys(props.data).length != 0) {
		productIDTableData = props.data;
	} else {
		productIDTableData = [];
	}

	if (Object.keys(props.data2).length != 0) {
		ScaleMerchTableData = props.data2;
	} else {
		ScaleMerchTableData = [];
	}

	const thresholdTypeCodeOptions = [
		{
			value: '',
			name: 'Select one...'
		},
		{
			value: 'SCALE_INTERVAL_QTY_THRESHOLD',
			name: 'Scale Interval Quantity Threshold'
		},
		{
			value: 'SCALE_QTY_THRESHOLD',
			name: 'Scale Quantity Threshold'
		},
		{
			value: 'SCALE_LIMIT_QTY_THRESHOLD',
			name: 'Scale Limit Quantity Threshold'
		},
		{
			value: 'SCALE_AMOUNT_THRESHOLD',
			name: 'Scale Amount Threshold'
		},
		{
			value: 'SCALE_QTY_AND_AMOUNT_THRESHOLD',
			name: 'Scale Quantity and Amount Threshold'
		},
		{
			value: 'SCALE_LIMIT_QTY_AND_AMOUNT_THRESHOLD',
			name: 'Scale Limit Quantity and Amount Threshold'
		}
	];

	const getThresholdDropdown = (thresholdTypeCodeOptions) => {
		return thresholdTypeCodeOptions.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const rewardCombinationDropdown = {
		operator: [
			{
				value: '',
				name: 'Select one'
			},
			{
				value: '<',
				name: '<'
			},
			{
				value: '>',
				name: '>'
			},
			{
				value: '<=',
				name: '<='
			},
			{
				value: '>=',
				name: '>='
			},
			{
				value: '=',
				name: '='
			},
			{
				value: 'BETWEEN',
				name: 'BETWEEN'
			}
		]
	};

	useEffect(() => {
		if (field.value.scaledPriceEligibility.thresholdTypeCode) {
			if (
				field.value.scaledPriceEligibility.thresholdTypeCode ==
					'SCALE_LIMIT_QTY_THRESHOLD' ||
				field.value.scaledPriceEligibility.thresholdTypeCode ==
					'SCALE_LIMIT_QTY_AND_AMOUNT_THRESHOLD'
			) {
				if (!field.value.scaledPriceEligibility.quantityLimit)
					field.value.scaledPriceEligibility.quantityLimit = 1;
			} else {
				field.value.scaledPriceEligibility.quantityLimit = undefined;
			}
		}
	}, [field.value.scaledPriceEligibility.thresholdTypeCode]);

	const fieldCR = field.value.scaledPriceEligibility;
	//newly added
	useEffect(() => {
		if (
			fieldCR.triggerOperator == '<' ||
			fieldCR.triggerOperator == '>' ||
			fieldCR.triggerOperator == '<=' ||
			fieldCR.triggerOperator == '>=' ||
			fieldCR.triggerOperator == '='
		) {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
		} else if (fieldCR.triggerOperator == 'BETWEEN') {
			fieldCR.triggerOperatorValue = undefined;
		} else {
			fieldCR.triggerOperatorValueTo = undefined;
			fieldCR.triggerOperatorValueFrom = undefined;
			fieldCR.triggerOperatorValue = undefined;
		}
	}, [fieldCR.triggerOperator]);

	useEffect(() => {
		if (
			fieldCR.rewardOperator == '<' ||
			fieldCR.rewardOperator == '>' ||
			fieldCR.rewardOperator == '<=' ||
			fieldCR.rewardOperator == '>=' ||
			fieldCR.rewardOperator == '='
		) {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
		} else if (fieldCR.rewardOperator == 'BETWEEN') {
			fieldCR.rewardOperatorValue = undefined;
		} else {
			fieldCR.rewardOperatorValueTo = undefined;
			fieldCR.rewardOperatorValueFrom = undefined;
			fieldCR.rewardOperatorValue = undefined;
		}
	}, [fieldCR.rewardOperator]);

	const onMouseEnterHandler = (evt, mouseIsON) => {
		if (mouseIsON != props.curntMousePlace) {
			props.setMousePlace(mouseIsON);
		}
	};

	const getESSelectIDDropdown = () => {
		return dropdownValues.selectId.map((val, index) => {
			return (
				<option key={index + ''} value={val.value}>
					{val.name}
				</option>
			);
		});
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

	return (
		<div>
			<Form className={offerFlow.type == 'view-offer-flow' ? 'disabledEvent' : ''}>
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToPaymentConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToPaymentConditions')}
					>
						<CollapseHeaderBar
							text={localConst.PaymentConditions}
							headingId={'headingFour'}
							dataTarget={'#collapseFour'}
						/>
						<CollapseBody id="collapseFour" headingId={'headingFour'}>
							<PaymentCondition
								customName="scaledPriceEligibility"
								fullVal={props.fullValue}
								loggedInBrand={props.loggedInBrand}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToPurchaseandRewardConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) =>
							onMouseEnterHandler(e, 'scrollToPurchaseandRewardConditions')
						}
					>
						<CollapseHeaderBar
							text={localConst.PurchaseandRewardConditions}
							headingId={'headingES'}
							dataTarget={'#collapseES'}
						/>
						<CollapseBody id="collapseES" headingId={'headingES'}>
							<div className="container-fluid">
								<div className="d-flex justify-content-center">
									<CustomSelect
										name="scaledPriceEligibility.thresholdTypeCode"
										required={true}
										label="Threshold Type Code"
										options={getThresholdDropdown(thresholdTypeCodeOptions)}
										className="customDropdown inlinePadding bg-position"
									/>
								</div>
							</div>
							{field.value?.scaledPriceEligibility?.thresholdTypeCode ? (
								field.value?.scaledPriceEligibility?.thresholdTypeCode ==
									'SCALE_LIMIT_QTY_THRESHOLD' ||
								field.value?.scaledPriceEligibility?.thresholdTypeCode ==
									'SCALE_LIMIT_QTY_AND_AMOUNT_THRESHOLD' ? (
									<CustomInputText
										name="scaledPriceEligibility.quantityLimit"
										required={false}
										label={localConst.quantityLimit}
									/>
								) : undefined
							) : undefined}

							<div className="d-flex">
								<div className="purchaseRow">
									<CustomSelect
										name="scaledPriceEligibility.triggerOperator"
										label="Price Range Indicator"
										className="customDropdown inlinePadding bg-position"
										options={getThresholdDropdown(
											rewardCombinationDropdown.operator
										)}
										// smallText={localConst.subTriggerCombinationCode}
										/*  className="customDropdown inlinePadding" */
									/>
									{fieldCR.triggerOperator == '<' ||
									fieldCR.triggerOperator == '>' ||
									fieldCR.triggerOperator == '<=' ||
									fieldCR.triggerOperator == '>=' ||
									fieldCR.triggerOperator == '=' ? (
										<CustomInputText
											name="scaledPriceEligibility.triggerOperatorValue"
											required={true}
											label="Price Range Value"
											smallText=""
											disabled={false}
										/>
									) : null}
									{fieldCR.triggerOperator == 'BETWEEN' ? (
										<>
											<CustomInputText
												name="scaledPriceEligibility.triggerOperatorValueFrom"
												required={true}
												label="Price Range Value From"
												smallText=""
												disabled={false}
											/>
											<CustomInputText
												name="scaledPriceEligibility.triggerOperatorValueTo"
												required={true}
												label="Price Range Value To"
												smallText=""
												disabled={false}
											/>{' '}
										</>
									) : null}
								</div>
								<div className="purchaseRow">
									<CustomSelect
										name="scaledPriceEligibility.rewardOperator"
										label="Price Range Indicator"
										className="customDropdown inlinePadding bg-position"
										options={getThresholdDropdown(
											rewardCombinationDropdown.operator
										)}
										// smallText={localConst.subTriggerCombinationCode}
										/*  className="customDropdown inlinePadding" */
									/>
									{fieldCR.rewardOperator == '<' ||
									fieldCR.rewardOperator == '>' ||
									fieldCR.rewardOperator == '<=' ||
									fieldCR.rewardOperator == '>=' ||
									fieldCR.rewardOperator == '=' ? (
										<CustomInputText
											name="scaledPriceEligibility.rewardOperatorValue"
											required={true}
											label="Price Range Value"
											smallText=""
											disabled={false}
										/>
									) : null}
									{fieldCR.rewardOperator == 'BETWEEN' ? (
										<>
											<CustomInputText
												name="scaledPriceEligibility.rewardOperatorValueFrom"
												required={true}
												label="Price Range Value From"
												smallText=""
												disabled={false}
											/>
											<CustomInputText
												name="scaledPriceEligibility.rewardOperatorValueTo"
												required={true}
												label="Price Range Value To"
												smallText=""
												disabled={false}
											/>{' '}
										</>
									) : null}
								</div>
							</div>
						</CollapseBody>
					</Element>
				</div>

				{field.value?.scaledPriceEligibility.thresholdTypeCode ? (
					<div className="accordion" id="accordionExample">
						<Element
							name="scrollToBenefitSettings"
							class="card"
							style={{ border: 'none' }}
							onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToBenefitSettings')}
						>
							<CollapseHeaderBar
								text={localConst.benefitSettings}
								headingId={'headingTwo'}
								dataTarget={'#collapseTwo'}
							/>
							<CollapseBody id="collapseTwo" headingId={'headingTwo'}>
								<BenefitSettingsSCALEPROD
									{...props}
									customName="scaledPriceEligibility"
								/>
							</CollapseBody>
						</Element>
					</div>
				) : undefined}

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToProduct/SellingHierarchySettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) =>
							onMouseEnterHandler(e, 'scrollToProduct/SellingHierarchySettings')
						}
					>
						<CollapseHeaderBar
							text={localConst.ProductSetting}
							headingId={'headingThree'}
							dataTarget={'#collapseThree'}
						/>
						<CollapseBody id="collapseThree" headingId={'headingThree'}>
							<div>
								{/* <div className="text-center text-danger">
                  {errMsg ==
                    "Product ID:" +
                    fieldSCALE.productID +
                    " already added"
                    ? errMsg
                    : null}
                </div>
                <ProductTableBootstrap data={productIDTableData} keys={keys}
                  defaultValueForAdd={defaultValueForAddMG}
                  onProductTableChange={(val) => onProductTableChange(val)}
                  eligibility={'MultiBuyGroup'}
                  // multibuyProduct={ScaleProductTableData.multibuyProduct}
                  ScaleProductTableData={ScaleProductTableData}
                /> */}
								{/* newly added */}
								<CustomSelect
									name={`scaledPriceEligibility.selectIdDropDown`}
									required={false}
									value={SelectID}
									onChange={(e) => setSelectID(e.target.value)}
									label={localConst.SelectID}
									options={getESSelectIDDropdown()}
									smallText=""
								/>

								{isAuditFlw ? (
									<div className="container">
										<AuditSHTable
											oldJson={sHTableData}
											newJson={
												JSON.parse(sessionStorage.getItem('NewPolicy'))
													.promotionPolicy.priceDerivationRuleEligibility
													.scaledPriceEligibility
													.merchandizeHierarchyGroup
											}
										/>
										<AuditProductTable
											oldJson={productTableData}
											newJson={
												JSON.parse(sessionStorage.getItem('NewPolicy'))
													.promotionPolicy.priceDerivationRuleEligibility
													.scaledPriceEligibility.multibuyProduct
											}
										/>
									</div>
								) : (
									<ProductTableBootstrapNew
										dataProduct={productTableData}
										SelectIDProp={SelectID}
										dataMer={sHTableData}
										keys={keys}
										keysSH={keysMerch}
										defaultValueForAdd={{
											maximumPrice: '9999999.99',
											minimumPrice: '0.01',
											trigger: true,
											getReward: true
										}}
										onProductTableChange={(val, val2) => {
											setProductTableData(val);
											setSHTableData(val2);
										}}
										eligibility={'MultiBuyGroup'}
										type={SelectID}
										brandEligibility={
											JSON.parse(props.fullValue).promotionPolicy
												.promotionApplicableEligibility.brandEligibility
										}
									/>
								)}
							</div>
						</CollapseBody>
					</Element>
				</div>

				{/*         <div class="accordion" id="accordionExample">
          <Element name="scrollToSellingHierarchySettings" class="card" style={{ border: "none" }}
          onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToSellingHierarchySettings')}
          >
            <CollapseHeaderBar
              text={localConst.SellingHierarchySettings}
              headingId={"headingThree"}
              dataTarget={"#collapseThree"}
            />
            <CollapseBody id="collapseThree" headingId={"headingThree"}>
              <div>
                <div className="text-center text-danger">
                  {errMsg ==
                    "Selling Hierarchy Group Id:" +
                    fieldSCALE.merchandizeGroupId +
                    " already added"
                    ? errMsg
                    : null}
                </div>
                <MerchTableBootstrapSCALE data={props.data2} keys={keysMerch}
                  defaultValueForAdd={defaultValueForAddMG}
                  onMerchTableChange={(val) => onMerchTableChange(val)}
                  eligibility={'scaledPriceEligibility'}
                  ScaleMerchTableData={ScaleMerchTableData}
                />
              </div>
            </CollapseBody>
          </Element>
        </div>
 */}
				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToExclusionsSettings"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToExclusionsSettings')}
					>
						<CollapseHeaderBar
							text={localConst.ExclusionsSetting}
							headingId={'headingES'}
							dataTarget={'#collapseES'}
						/>
						<CollapseBody id="collapseES" headingId={'headingES'}>
							<ExclusionSetting
								customName="scaledPriceEligibility"
								fullValue={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>

				<div className="accordion" id="accordionExample">
					<Element
						name="scrollToCouponConditions"
						class="card"
						style={{ border: 'none' }}
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToCouponConditions')}
					>
						<CollapseHeaderBar
							text={localConst.CouponConditions}
							headingId={'headingFive'}
							dataTarget={'#collapseFive'}
						/>
						<CollapseBody id="collapseFive" headingId={'headingFive'}>
							<CouponCondition
								customName="scaledPriceEligibility"
								fullVal={props.fullValue}
							/>
						</CollapseBody>
					</Element>
				</div>
			</Form>
		</div>
	);
};

//const EligibilitySubForm = withSubForm(EligibilityForm, eligibilitySchema);
//export default EligibilitySubForm;
//export default EligibilityForm;

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
