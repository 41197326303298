import React, { useEffect, useState } from 'react';
import { FieldArray, Field, ErrorMessage, useField, useFormikContext } from 'formik';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';

import CustomInputText from './customfields/CustomInputText';
import CustomSelect from './customfields/CustomSelect';
import CustomDateTime from './customfields/CustomDateTime';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import './BasicDetails.scss';
import moment from 'moment';
import CustomInputRadio from './customfields/CustomInputRadio';
import { InformationModal } from '../../../MainContent/InformationModal';
import $ from 'jquery';
import * as RestSvc from '../../../libraries/offer-services';
import * as urlTypes from '../../../libraries/offer-service-urls';
// Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
//   return this.test("unique", message, function (list) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });

/* const couponConditionFormSchema = Yup.object().shape({
  couponThresholdQuantity: Yup.string()
    .matches(/^[0-9]+$/, "Coupon Threshold Quantity value should be between 1 to 999999")
    .test(
      "val_test",
      "Coupon Threshold Quantity value should be between 1 to 999999",
      function (value) {
        return (1 <= parseInt(value) && parseInt(value) <= 999999)
      }
    ),

  coupon: Yup.array()
    .of(
      Yup.object().shape({
        couponId: Yup.string().min(2, "Required: Coupon ID cannot be empty and should be between 2 to 50 characters")
          .max(50, "Required: Coupon ID cannot be empty and should be between 2 to 50 characters")
          .required("Required: Coupon ID cannot be empty and should be between 2 to 50 characters"),
        couponLimit: Yup.string()
          .matches(/^[0-9]+$/, "Invalid")
          .test(
            "val_test",
            "Invalid",
            function (value) {
              return (1 <= parseInt(value) && parseInt(value) <= 999999999)
            }
          ),
        expireOn: Yup.object().shape({
          date: Yup.date().min(new Date(), "Date should not be less than the Current Date"),
          days: Yup.string()
            .matches(/^[0-9]+$/, "Invalid"),
        })
      })
    ),

}); */

const localConst = {
	couponType: 'Coupon Type',
	couponThresholdQty: 'Coupon Threshold Quantity'
};
const dropdownValues = {
	couponType: [
		{
			value: '',
			name: 'Select One'
		},
		{
			value: 'GENERIC',
			name: 'Generic'
		},
		{
			value: 'PERSONALIZED',
			name: 'Personalized'
		},
		{
			value: 'TARGETED',
			name: 'Targeted'
		}
	],
	coolOff: [
		{
			value: '',
			name: 'Select One'
		},
		{
			value: 'Date',
			name: 'Date'
		},
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Hours',
			name: 'Hours'
		}
	],
	onlyDate: [
		{
			value: '',
			name: 'Select One'
		},
		{
			value: 'Date',
			name: 'Date'
		}
	],
	couponTypeTargeted: [
		{
			value: '',
			name: 'Select One'
		},
		{
			value: 'TARGETED',
			name: 'Targeted'
		}
	]
};
/* todo remove this as this is not require */
const renderTable = false;
const CouponConditionForm = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	const [field] = useField(props);
	// const filedcalecoupons= field.value.groupMultibuyEligbility.coupons;
	const valueEligbility = props.customName;
	const { setFieldValue, setFieldTouched } = useFormikContext();
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg] = useState('');
	const [informationTitle] = useState('Information');
	const [selectAll, setSelectAll] = useState(false);

	const getCoolOffDropdown = () => {
		if (JSON.parse(props.fullVal).promotionPolicy.limitedUse) {
			return dropdownValues.coolOff.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else {
			return dropdownValues.onlyDate.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		}
	};
	const getCouponTypeDropdown = () => {
		if (
			JSON.parse(props.fullVal).promotionPolicy.promotionApplicableEligibility
				.promotionActivationEligibility.multimapPromotion
		) {
			return dropdownValues.couponTypeTargeted.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		} else {
			return dropdownValues.couponType.map((val, index) => {
				return (
					<option key={index + ''} value={val.value}>
						{val.name}
					</option>
				);
			});
		}
	};
	const filedcalecoupons =
		valueEligbility == 'groupMultibuyEligbility'
			? field.value.groupMultibuyEligbility.coupons
			: valueEligbility == 'packageMultibuyEligibility'
			? field.value.packageMultibuyEligibility.coupons
			: valueEligbility == 'multibuyEligbility'
			? field.value.multibuyEligbility.coupons
			: valueEligbility == 'merchandizeHierarchyEligibility'
			? field.value.merchandizeHierarchyEligibility.coupons
			: valueEligbility == 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity'
			? field.value.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons
			: valueEligbility == 'scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity'
			? field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons
			: valueEligbility == 'scaledPriceEligibility'
			? field.value.scaledPriceEligibility.coupons
			: undefined;
	const customValidateCouponExpiryDate = (value) => {
		let error;

		if (value) {
			if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate) < moment(value)
			) {
				error =
					'Entered validity date and time cannot be greater than the promotion end date and time';
			}
			if (
				moment(JSON.parse(props.fullVal).promotionPolicy.effectiveStartDate) > moment(value)
			) {
				error =
					'Entered validity date and time cannot be lesser than the promotion start date and time';
			}
		}

		return error;
	};
	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
	useEffect(() => {
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility]?.coupons
					?.couponType != filedcalecoupons?.couponType
			) {
				let tempName = valueEligbility + '.coupons.couponType';

				$('[name="' + tempName + '"]')
					.closest('.container')
					.nextAll()
					.addClass('d-none');
			}
		}
	}, []);
	useEffect(() => {
		if (
			JSON.parse(props.fullVal).promotionPolicy.promotionApplicableEligibility
				.promotionActivationEligibility.multimapPromotion &&
			(filedcalecoupons.couponType == 'GENERIC' ||
				filedcalecoupons.couponType == 'PERSONALIZED')
		) {
			setFieldValue(`${valueEligbility}.coupons.couponType`, '');
		}
		if (
			(filedcalecoupons.couponType == 'GENERIC' ||
				filedcalecoupons.couponType == 'PERSONALIZED' ||
				filedcalecoupons.couponType == 'TARGETED') &&
			filedcalecoupons.couponThresholdQuantity == undefined
		) {
			// filedcalecoupons.couponThresholdQuantity = "1";
			setFieldValue(`${valueEligbility}.coupons.couponThresholdQuantity`, '1');
		}
		if (
			(filedcalecoupons.couponType == 'GENERIC' ||
				filedcalecoupons.couponType == 'PERSONALIZED' ||
				filedcalecoupons.couponType == 'TARGETED') &&
			filedcalecoupons.displayCoupons == undefined
		) {
			// filedcalecoupons.couponThresholdQuantity = "1";
			setFieldValue(`${valueEligbility}.coupons.displayCoupons`, true);
		}

		if (
			filedcalecoupons.couponType == 'GENERIC' &&
			filedcalecoupons.isMultiUseCoupon == undefined
		) {
			// filedcalecoupons.couponThresholdQuantity = "1";
			setFieldValue(`${valueEligbility}.coupons.isMultiUseCoupon`, true);
		}

		if (
			filedcalecoupons.couponType == 'PERSONALIZED' ||
			filedcalecoupons.couponType == 'TARGETED'
		) {
			filedcalecoupons.isMultiUseCoupon = undefined;
		}

		if (
			(filedcalecoupons.couponType == 'GENERIC' ||
				filedcalecoupons.couponType == 'TARGETED') &&
			filedcalecoupons.coupon == undefined
		) {
			// filedcalecoupons.coupon = [
			//   {
			//     couponId: "",
			//     expireOn: {
			//       couponExpiry: "Date",
			//       date: JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate,
			//     },
			//     couponLimit: "999999999",
			//   },
			// ];
			filedcalecoupons.coupon = [];
		}
		if (!JSON.parse(props.fullVal).promotionPolicy.limitedUse) {
			if (filedcalecoupons.coupon) {
				filedcalecoupons.coupon.map((item) => {
					if (item.expireOn.couponExpiry != 'Date')
						item.expireOn = {
							couponExpiry: 'Date',
							date: JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate
						};
				});
			}
		}
	}, [filedcalecoupons]);
	useEffect(() => {
		if (filedcalecoupons.coupon && filedcalecoupons.coupon.length > 0) {
			let selectedRows = filedcalecoupons.coupon.filter((val) => {
				return val.selectedCoupon;
			});
			if (selectedRows.length == filedcalecoupons.coupon.length) {
				setSelectAll(true);
			} else {
				setSelectAll(false);
			}
		}
	}, [filedcalecoupons.coupon]);

	//Method used to find the duplication of coupon ID. now it is handled in validation schema unique()

	const settingExpiyDate = (index) => {
		if (!filedcalecoupons.coupon[index].expireOn.date) {
			filedcalecoupons.coupon[index].expireOn.date = JSON.parse(
				props.fullVal
			).promotionPolicy.effectiveEndDate;
		}
	};

	const handleCheckBox = (e, i) => {
		setFieldValue(
			`${valueEligbility}.coupons.coupon.${i}.selectedCoupon`,
			!filedcalecoupons.coupon[i].selectedCoupon
		);
	};

	const handleCheckBoxAll = () => {
		let tempCouponTable = [...filedcalecoupons.coupon];
		tempCouponTable = tempCouponTable.map((val) => {
			return { ...val, selectedCoupon: !selectAll };
		});
		setSelectAll(!selectAll);
		setFieldValue(`${valueEligbility}.coupons.coupon`, tempCouponTable);
	};

	const [errorMsg, setErrorMsg] = useState('');
	useEffect(() => {
		// setAllowDisplayFlagChange("");

		if (filedcalecoupons.displayCoupons) {
			//alert("Hi..");
			let fnIdJson = JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'));
			let fnId = fnIdJson.businessUnitGroupFunctionID;
			var programID = JSON.parse(props.fullVal).promotionPolicy.programID;
			var promotionId = JSON.parse(props.fullVal).promotionPolicy.promotionID;
			var promotionIdToSend = `${programID}:${promotionId}`;
			var dataToSend = {
				getPromotionCouponCountRequest: {
					promotionId: promotionIdToSend,
					brandId: fnId
				}
			};
			//props.showLoadingButton(true);
			RestSvc.postData(urlTypes.coupon_maintenance.getFlag, dataToSend).then((response) => {
				// props.showLoadingButton(false);
				if (response && response.data) {
					//setResult(response.data.getPromotionCouponCountResponse.displayFlg);
					let result = response.data.getPromotionCouponCountResponse.displayFlg;
					let configuredCount =
						response.data.getPromotionCouponCountResponse.configuredCount;
					if (result === 'True') {
						setErrorMsg(
							'Coupon count is more than configured value ' +
								configuredCount +
								", hence 'Allow Coupon To Be Displayed' cannot be modified to Yes"
						);
						let fieldName = `${valueEligbility}.coupons.displayCoupons`;

						setFieldValue(fieldName, false);
					} else {
						setErrorMsg('');
					}
				}
			});
		}
	}, [filedcalecoupons.displayCoupons]);
	return (
		<div className="main">
			<CustomSelect
				name={`${valueEligbility}.coupons.couponType`}
				label={localConst.couponType}
				options={getCouponTypeDropdown()}
				smallText="Determines if the coupon is Generic or personalized coupon"
				onChange={(e) => {
					if (e.target.value === 'GENERIC' || e.target.value === 'TARGETED') {
						setFieldValue(`${valueEligbility}.coupons.coupon`, [
							// {
							//   couponId: "",
							//   expireOn: {
							//     couponExpiry: "Date",
							//     date: JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate,
							//   },
							//   couponLimit: "999999999",
							// },
						]);
					} else {
						setFieldValue(`${valueEligbility}.coupons.coupon`, undefined);
					}

					setFieldValue(`${valueEligbility}.coupons.couponThresholdQuantity`, '1');
					setFieldValue(`${valueEligbility}.coupons.couponType`, e.target.value);
					setFieldTouched(`${valueEligbility}.coupons.coupon`, false);
				}}
			/>

			{filedcalecoupons.couponType &&
			(filedcalecoupons.couponType == 'GENERIC' ||
				filedcalecoupons.couponType == 'PERSONALIZED' ||
				filedcalecoupons.couponType == 'TARGETED') ? (
				<>
					<CustomInputText
						name={`${valueEligbility}.coupons.couponThresholdQuantity`}
						required={true}
						label={localConst.couponThresholdQty}
						smallText="Specifies number of coupons to be scanned to trigger promotion , by default it is 1 coupon per promotion"
					/>
					<CustomInputRadio
						name={`${valueEligbility}.coupons.displayCoupons`}
						label={'Allow Coupon To Be Displayed'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={'Determines whether the coupon details to be displayed'}
					/>
					{errorMsg !== '' ? (
						<div
							className="mt-1 pl-19"
							style={{ color: '#dc3545', 'padding-left': 'calc(39% + 15px)' }}
						>
							{errorMsg}
						</div>
					) : null}
					{filedcalecoupons.couponType == 'GENERIC' ? (
						<CustomInputRadio
							name={`${valueEligbility}.coupons.isMultiUseCoupon`}
							label={'Is Multi Use Coupon'}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							smallText={
								'Determines whether the coupon is a single use or multi use coupon.'
							}
						/>
					) : undefined}

					{filedcalecoupons.couponType &&
					(filedcalecoupons.couponType == 'GENERIC' ||
						filedcalecoupons.couponType == 'TARGETED') ? (
						//
						<>
							<div className="rowContainer hideForAudit">
								<div className="customLabelContainer">
									<label htmlFor="forprogramID" className="col	col-form-label">
										Coupon ID
										{/* <span class="requiredRed">*</span> */}
									</label>
								</div>
								<div className="customInputContainer">
									<div className="row">
										{/* <input type='text' className="form-control" onKeyDown={(e)=>FuncEnter(e) }
                                onChange={(e) => setCouponID(e.target.value)} value={couponID} />
                                <button type="button" class="addPlusBtn ml-2" onClick={addCoupon}></button>
                                <button type="button" class="addDeleteBtn ml-1" onClick={deleteSelectedCoupon}></button> */}
										<label
											htmlFor="forprogramID"
											className="col-form-label"
										>
											Add coupon id(s) via the 'Upload Coupons' feature
										</label>
										<small id="emailHelp" className="form-text pl-2">
											<div className="tooltipimg">
												<button
													className="tooltip-button btn br-50"
													type="button"
												>
													<span className="tooltip-icon">?</span>
												</button>
												<span className="tooltiptext">
													Navigation: Go to coupon management module,click
													on the "Upload Coupons" button, upload coupon
													information as per the system defined coupon
													template standard
												</span>
											</div>
										</small>
									</div>
								</div>
							</div>
							{/* {filedcalecoupons?.coupon?.length>0? */}
							{renderTable ? (
								<>
									<table className="mt-10">
										<tr>
											<th className="text-center">
												<input
													className="mr-2"
													type="checkbox"
													checked={selectAll}
													onChange={() => {
														handleCheckBoxAll();
													}}
												/>
											</th>
											<th>Serial No. </th>
											<th className="w-20">
												Coupon ID
												<small id="emailHelp" className=" pl-2">
													<span className="tooltipimg">
														<button className="tooltip-button btn br-50">
															<span className="tooltip-icon">?</span>
														</button>
														<span className="tooltiptext">
															{' '}
															{'Specifies the coupon ID'}
														</span>
													</span>
												</small>
											</th>
											<th className="w-50">
												Coupon Expiry
												<small id="emailHelp" className=" pl-2">
													<span className="tooltipimg">
														<button className="tooltip-button btn br-50">
															<span className="tooltip-icon">?</span>
														</button>
														<span className="tooltiptext">
															<ol className="pl-2 mb-1">
																<li>
																	Specifies the date and time the
																	coupon provided to the customer
																	will be expired
																</li>
																<li>
																	Specifies the period the coupon
																	provided to the customer will be
																	expired in months
																</li>
																<li>
																	Specifies the period the coupon
																	provided to the customer will be
																	expired in days
																</li>
																<li>
																	{' '}
																	Specifies the period the coupon
																	provided to the customer will be
																	expired in hours
																</li>
															</ol>{' '}
														</span>
													</span>
												</small>
											</th>
											<th>
												Redemption Limit
												<small id="emailHelp" className=" pl-2">
													<span className="tooltipimg">
														<button className="tooltip-button btn br-50">
															<span className="tooltip-icon">?</span>
														</button>
														<span className="tooltiptext">
															{' '}
															{
																'Determines the count of redemption limit of a coupon across transaction'
															}
														</span>
													</span>
												</small>
											</th>
											{/* <th>
                    <button
                      className="addPlusBtn"
                      type="button"
                      onClick={() => {
                        
                        if( filedcalecoupons.coupon.length < 10){
                        filedcalecoupons.coupon.push({
                          couponId: "",
                          expireOn: {
                            couponExpiry: "Date",
                            date: JSON.parse(props.fullVal).promotionPolicy.effectiveEndDate,
                          },
                          couponLimit: "999999999",
                        });
                        setFieldValue("","");
                      }
                      }}
                    >
                      
                </button>
                  </th> */}
										</tr>

										<FieldArray name={`${valueEligbility}.coupons.coupon`}>
											{() => (
												<>
													{filedcalecoupons.coupon &&
														filedcalecoupons.coupon.map(
															(item, index) => (
																<tr
																	key={index}
																	className="coupon-conditions"
																>
																	<td className="text-center">
																		<input
																			type="checkbox"
																			className="mr-2"
																			name="select"
																			onChange={(e) =>
																				handleCheckBox(
																					e,
																					index,
																					item
																				)
																			}
																			checked={
																				item.selectedCoupon
																			}
																		/>
																	</td>
																	<td>{index + 1}</td>
																	<td>
																		{/* <div className="col"> */}
																		{/* <label htmlFor={`multibuyProduct.${index}.productID`}>Name</label> */}
																		{/* <CustomInputText name={`coupon.${index}.couponId`} required={false} label=""/> */}
																		{/* <Field
                                  className="form-control"
                                  name={`${valueEligbility}.coupons.coupon.${index}.couponId`}
                                  type="text"
                                /> */}
																		{
																			filedcalecoupons.coupon[
																				index
																			].couponId
																		}

																		{/* <ErrorMessage
                                  name={`${valueEligbility}.coupons.coupon.${index}.couponId`}
                                  component="div"
                                  className="field-error"
                                /> */}
																		{/* </div> */}
																	</td>

																	<td>
																		<div className="d-flex">
																			{/* <CustomSelect name={`coupon.${index}.expireOn.couponExpiry`} label="" options={getCoolOffDropdown()}
                                                          /> */}
																			<div className="col-sm-6 mr-3">
																				<Field
																					as="select"
																					name={`${valueEligbility}.coupons.coupon.${index}.expireOn.couponExpiry`}
																					className="form-control"
																					onBlur={
																						filedcalecoupons
																							.coupon[
																							index
																						].expireOn
																							.couponExpiry ==
																						'Date'
																							? settingExpiyDate(
																									index
																							  )
																							: undefined
																					}
																				>
																					{getCoolOffDropdown()}
																				</Field>
																			</div>
																			<div className="col-sm-6 mr-3">
																				{filedcalecoupons
																					.coupon[index]
																					.expireOn
																					.couponExpiry ==
																				'Date' ? (
																					<>
																						<CustomDateTime
																							name={`${valueEligbility}.coupons.coupon.${index}.expireOn.date`}
																							label=""
																							validate={
																								customValidateCouponExpiryDate
																							}
																							valueDate={
																								filedcalecoupons
																									.coupon[
																									index
																								]
																									.expireOn
																									.date
																							}
																						/>
																						{/* <small
                                          id="emailHelp"
                                          className="form-text text-muted col-md-9 justify-content-start flex1"
                                        >
                                          Specifies the date and time the coupon
                                    provided to the customer will be expired{" "}
                                        </small> */}
																					</>
																				) : (
																					(filedcalecoupons.coupon[
																						index
																					].expireOn.date =
																						undefined)
																				)}

																				{filedcalecoupons
																					.coupon[index]
																					.expireOn
																					.couponExpiry ==
																				'Months' ? (
																					<>
																						{/* <CustomInputText name={`coupon.${index}.expireOn.months`} required={false} label='' /> */}
																						<Field
																							className="form-control"
																							name={`${valueEligbility}.coupons.coupon.${index}.expireOn.months`}
																							type="text"
																						/>
																						{/* <small
                                          id="emailHelp"
                                          className="form-text text-muted col-md-9 justify-content-start flex1"
                                        >
                                          Specifies the period the coupon provided
                                          to the customer will be expired in months
                                  </small> */}
																						<ErrorMessage
																							name={`${valueEligbility}.coupons.coupon.${index}.expireOn.months`}
																							component="div"
																							className="field-error pl-3"
																						/>
																					</>
																				) : (
																					(filedcalecoupons.coupon[
																						index
																					].expireOn.months =
																						undefined)
																				)}

																				{filedcalecoupons
																					.coupon[index]
																					.expireOn
																					.couponExpiry ==
																				'Days' ? (
																					<>
																						{/* <CustomInputText name={`coupon.${index}.expireOn.days`} required={false} label='' /> */}
																						<Field
																							className="form-control"
																							name={`${valueEligbility}.coupons.coupon.${index}.expireOn.days`}
																							type="text"
																						/>
																						{/* <small
                                          id="emailHelp"
                                          className="form-text text-muted col-md-9 justify-content-start flex1"
                                        >
                                          Specifies the period the coupon provided
                                          to the customer will be expired in days
                                  </small> */}
																						<ErrorMessage
																							name={`${valueEligbility}.coupons.coupon.${index}.expireOn.days`}
																							component="div"
																							className="field-error pl-3"
																						/>
																					</>
																				) : (
																					(filedcalecoupons.coupon[
																						index
																					].expireOn.days =
																						undefined)
																				)}

																				{filedcalecoupons
																					.coupon[index]
																					.expireOn
																					.couponExpiry ==
																				'Hours' ? (
																					<>
																						<CustomHoursPicker
																							name={`${valueEligbility}.coupons.coupon.${index}.expireOn.hours`}
																							required={
																								false
																							}
																							label=""
																							valueHour={
																								filedcalecoupons
																									.coupon[
																									index
																								]
																									.expireOn
																									.hours
																							}
																						/>
																						{/* <small
                                          id="emailHelp"
                                          className="form-text text-muted col-md-9 justify-content-start flex1"
                                        >
                                          Specifies the period the coupon provided
                                          to the customer will be expired in hours
                                  </small> */}
																					</>
																				) : (
																					(filedcalecoupons.coupon[
																						index
																					].expireOn.hours =
																						undefined)
																				)}
																			</div>
																		</div>
																	</td>
																	<td>
																		<div className="col">
																			<Field
																				className="form-control"
																				name={`${valueEligbility}.coupons.coupon.${index}.couponLimit`}
																				type="text"
																			/>

																			<ErrorMessage
																				name={`${valueEligbility}.coupons.coupon.${index}.couponLimit`}
																				component="div"
																				className="field-error"
																			/>
																		</div>
																	</td>
																	{/* <td>
                              <div className="col">
                                <button
                                  type="button"
                                  // className="secondary"
                                  className="addDeleteBtn"
                                  onClick={() => remove(index)}
                                >
                                  
                            </button>
                              </div>
                            </td> */}
																</tr>
															)
														)}
												</>
											)}
										</FieldArray>
									</table>
								</>
							) : null}
						</>
					) : null}
				</>
			) : (
				((filedcalecoupons.couponThresholdQuantity = undefined),
				(filedcalecoupons.coupon = undefined),
				(filedcalecoupons.displayCoupons = undefined),
				(filedcalecoupons.isMultiUseCoupon = undefined))
			)}
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

/* const CouponCondition = withSubForm(
  CouponConditionForm,
  couponConditionFormSchema
); */
export default CouponConditionForm;
