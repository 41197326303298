export const LOADING_BUTTON = 'LOADING_BUTTON';
export const FULL_NAME = 'FULL_NAME';
export const DISABLE_ITEM = 'DISABLE_ITEM';
export const ASSIGNED_RESOURCES = 'ASSIGNED_RESOURCES';
export const LOGGEDIN_LEVELID = 'LOGGEDIN_LEVELID';
export const LOGGEDIN_LEVELHIERARCHY = 'LOGGEDIN_LEVELHIERARCHY';
export const LOGGEDIN_BRAND = 'LOGGEDIN_BRAND';
export const MOUSE_PLACE = 'MOUSE_PLACE';
export const LOADING_BUTTON2 = 'LOADING_BUTTON2';
export const allCategoryHierarchy = 'ALL_CATEGORY_HIERARCHY';
