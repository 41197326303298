import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import LoginPage from './Login/Login';
import MainPage from './MainContent/MainPage';
import Loader from './Components/Loader/Loader';
import axios from './libraries/axios';
import { clearUserSession } from './utils/helperFunction';

class App extends Component {
	state = {
		domain: ''
	};
	UNSAFE_componentWillMount() {
		const propertiesUrl = 'web-app.properties';
		/* fetch('../env-type/srcToWebApp.properties')
      .then((r) => r.json())
      .then((data) => {

        propertiesUrl = data.url; */
		/* clear the user session before page load */
		clearUserSession();
		fetch(propertiesUrl)
			.then((r) => r.json())
			.then((data) => {
				axios.defaults.baseURL = data.propertySources[0].source.apiUrl;

				sessionStorage.setItem(
					'applctnUrls',
					JSON.stringify(data.propertySources[0].source)
				);

				sessionStorage.setItem('couponServiceUrl', '');
				sessionStorage.setItem('binServiceUrl', '');
				//   RestSvc.getData(JSON.parse(sessionStorage.getItem("applctnUrls")).savePromotionsUrl+urlTypes.coupon_maintenance.getCouponProperties)
				// .then(response => {
				//   sessionStorage.setItem("couponServiceUrl",response.data.getPropertiesResponce.couponUrlDetails);
				//   sessionStorage.setItem("binServiceUrl",response.data.getPropertiesResponce.binrangeUrlDetails);
				// })
			});

		// sessionStorage.setItem("couponServiceUrl","localhost:8085");

		// })
		const parsedData = window.location.pathname;
		let domain1 = parsedData;
		this.setState({ domain: domain1 });
	}
	// onUnload(event) {
	//   // alert('page Refreshed')
	//   this.props.history.push('/');
	// }
	// componentDidMount() {
	//     window.onbeforeunload = function() {
	//         this.onUnload();
	//         return "";
	//     }.bind(this);
	// }

	render() {
		const { propLoading, propLoading2 } = this.props;

		return (
			<div className="container-fluid">
				{propLoading || propLoading2 ? <Loader /> : ''}
				<BrowserRouter basename={this.state.domain}>
					<Switch>
						<Route exact path="/">
							<LoginPage />
						</Route>
						<Route path="/MainPage">
							<MainPage />
						</Route>
					</Switch>
				</BrowserRouter>
				{/* <HashRouter basename="/">
          <Switch>
            <Route exact path="/">
              <LoginPage />
            </Route>
            <Route path="/MainPage">
              <MainPage />
            </Route>
          </Switch>
        </HashRouter > */}
				<div className="d-none">
					<iframe
						title="iFrame"
						id="getLeaf"
						src="../../channel-tree/getLeafNode.html"
						width="100%"
						height="85%"
						frameBorder="0"
					></iframe>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		propLoading: state.loading,
		propLoading2: state.loading2
	};
};

export default connect(mapStateToProps)(App);
