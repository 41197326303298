import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Segment.css';
import { useField, FieldArray, useFormikContext } from 'formik';
import * as urlTypes from '../../../libraries/offer-service-urls';
import * as RestSvc from '../../../libraries/offer-services';
import toaster from 'toasted-notes';

// const dropdownValues = {
//   empSegment: [
//     {
//       value: "Gold",
//       name: "Gold",
//       selected: false
//     },
//     {
//       value: "Silver",
//       name: "Silver",
//       selected: false
//     },
//     {
//       value: "Platinum",
//       name: "Platinum",
//       selected: false
//     },
//   ],
//   loyalSegment: [
//     {
//       value: "Gold",
//       name: "Gold",
//       selected: false
//     },
//     {
//       value: "Silver",
//       name: "Silver",
//       selected: false
//     },
//     {
//       value: "Platinum",
//       name: "Platinum",
//       selected: false
//     },
//   ],
//   custSegment: [
//     {
//       value: "Gold",
//       name: "Gold",
//       selected: false
//     },
//     {
//       value: "Silver",
//       name: "Silver",
//       selected: false
//     },
//     {
//       value: "Platinum",
//       name: "Platinum",
//       selected: false
//     },
//   ],
// }
const Segments = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	//
	//useValues(name, props);
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();
	const fieldPrmtnAplcblEligibility = field.value.promotionPolicy.promotionApplicableEligibility;
	const { type } = props;
	const [availableEmployeeSegments, setAvlblEmplySegments] = useState([]);
	const [availableCustomerSegments, setAvlblCustSegments] = useState([]);
	const [tsssEligibilitySegments, setAvlblTSSSsegments] = useState([]);
	const [availableLoyaltyCustomerSegments, setAvlblLoyaltyCustSegments] = useState([]);
	const [empSegMap, setEmpSegMap] = useState({});
	const [custSegMap, setCustSegMap] = useState({});
	const [tsssSegMap, setTsssSegMap] = useState({});
	const [loyalSegMap, setLoyalSegMap] = useState({});

	useEffect(() => {
		//businessUnitGroupFunctionID
		let url = `${urlTypes.offers_maintenance.getSegments}${
			JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
				.businessUnitGroupFunctionID
		}`;
		RestSvc.getData(url).then((response) => {
			var result = response.data.getSegmentDetailsResponse.segmentMap;
			if (response.data.getSegmentDetailsResponse.businessError) {
				toaster.notify(
					({ onClose }) => (
						<div className="Toaster__message-wrapper">
							<div>
								<div
									id="1"
									className="toasterRed Toaster__alert Toaster__alert_error"
								>
									<div className="Toaster__alert_text">
										{
											response.data.getSegmentDetailsResponse.businessError
												.errorMessage
										}
									</div>
									<button
										className="Toaster__alert_close"
										type="button"
										aria-label="Close"
										onClick={onClose}
									>
										<span aria-hidden="true">×</span>
									</button>
								</div>
							</div>
						</div>
					),
					{
						duration: 10000
					}
				);
			} else {
				if (result.CustomerSegment != undefined) {
					let tempCust = result.CustomerSegment;
					tempCust.forEach(function (element) {
						element.selected = false;
					});
					setAvlblCustSegments(tempCust);
					const newObj = {};
					result.CustomerSegment.forEach((item) => {
						newObj[item.value] = item.name;
					});
					setCustSegMap(newObj);
				}
				if (result.LoyaltySegment != undefined) {
					let tempLoyal = result.LoyaltySegment;
					tempLoyal.forEach(function (element) {
						element.selected = false;
					});
					setAvlblLoyaltyCustSegments(tempLoyal);
					const newObj = {};
					result.LoyaltySegment.forEach((item) => {
						newObj[item.value] = item.name;
					});
					setLoyalSegMap(newObj);
				}
				if (result.EmployeeSegment != undefined) {
					let tempEmp = result.EmployeeSegment;
					tempEmp.forEach(function (element) {
						element.selected = false;
					});
					setAvlblEmplySegments(tempEmp);
					const newObj = {};
					result.EmployeeSegment.forEach((item) => {
						newObj[item.value] = item.name;
					});
					setEmpSegMap(newObj);
				}

				if (result.TsssSegment != undefined) {
					let tempCust = result.TsssSegment;
					tempCust.forEach(function (element) {
						element.selected = false;
					});
					setAvlblTSSSsegments(tempCust);
					const newObj = {};
					result.TsssSegment.forEach((item) => {
						newObj[item.value] = item.name;
					});
					setTsssSegMap(newObj);
				}
			}
		});
	}, []);
	// useEffect(() => {
	// if (type == "employee") {
	//   if (availableEmployeeSegments.length == 0)
	//   setAvlblEmplySegments(dropdownValues.empSegment);
	// }
	// if (type == "loyaltyCustomer") {
	//   if (availableLoyaltyCustomerSegments.length == 0)
	//   setAvlblLoyaltyCustSegments(dropdownValues.loyalSegment);
	// }
	// if (type == "customer") {
	//   if (availableCustomerSegments.length == 0)
	//   setAvlblCustSegments(dropdownValues.custSegment);
	// }
	// });
	const onEmpSegChange = (e, indx) => {
		if (type == 'employee') {
			let copyAvailableEmpSegments = availableEmployeeSegments;
			copyAvailableEmpSegments[indx].selected = !copyAvailableEmpSegments[indx].selected;
			setAvlblEmplySegments(copyAvailableEmpSegments);
		}
		if (type == 'loyaltyCustomer') {
			let copyAvailableEmpSegments = availableLoyaltyCustomerSegments;
			copyAvailableEmpSegments[indx].selected = !copyAvailableEmpSegments[indx].selected;
			setAvlblLoyaltyCustSegments(copyAvailableEmpSegments);
		}
		if (type == 'customer') {
			let copyAvailableEmpSegments = availableCustomerSegments;
			copyAvailableEmpSegments[indx].selected = !copyAvailableEmpSegments[indx].selected;
			setAvlblCustSegments(copyAvailableEmpSegments);
		}
		if (type == 'tsssEligibility') {
			let copyAvailableTsssSegments = tsssEligibilitySegments;
			copyAvailableTsssSegments[indx].selected = !copyAvailableTsssSegments[indx].selected;
			setAvlblTSSSsegments(copyAvailableTsssSegments);
		}
		setFieldValue('', '');
	};
	const includeSelected = () => {
		if (type == 'employee') {
			if (!fieldPrmtnAplcblEligibility.employeeEligibility.includedEmployeeSegments) {
				fieldPrmtnAplcblEligibility.employeeEligibility.includedEmployeeSegments = {
					employeeSegmentId: []
				};
			}
			availableEmployeeSegments.map((item) => {
				if (item.selected) {
					fieldPrmtnAplcblEligibility.employeeEligibility.includedEmployeeSegments.employeeSegmentId.push(
						item.value
					);
				}
			});
			availableEmployeeSegments.map((item) => {
				item.selected = false;
			});
		} else if (type == 'loyaltyCustomer') {
			if (
				!fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
					.includedLoyaltyCustomerSegments
			) {
				fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.includedLoyaltyCustomerSegments =
					{ loyaltyCustomerSegment: [] };
			}
			availableLoyaltyCustomerSegments.map((item) => {
				if (item.selected) {
					fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.includedLoyaltyCustomerSegments.loyaltyCustomerSegment.push(
						item.value
					);
				}
			});
			availableLoyaltyCustomerSegments.map((item) => {
				item.selected = false;
			});
		} else if (type == 'customer') {
			if (!fieldPrmtnAplcblEligibility.customerSegmentEligibility.includedCustomerSegments) {
				fieldPrmtnAplcblEligibility.customerSegmentEligibility.includedCustomerSegments = {
					customerSegmentId: []
				};
			}
			availableCustomerSegments.map((item) => {
				if (item.selected) {
					fieldPrmtnAplcblEligibility.customerSegmentEligibility.includedCustomerSegments.customerSegmentId.push(
						item.value
					);
				}
			});
			availableCustomerSegments.map((item) => {
				item.selected = false;
			});
		} else if (type == 'tsssEligibility') {
			if (!fieldPrmtnAplcblEligibility.tsssEligibility.includedTsssSegments) {
				fieldPrmtnAplcblEligibility.tsssEligibility.includedTsssSegments = {
					tsssSegmentId: []
				};
			}
			tsssEligibilitySegments.map((item) => {
				if (item.selected) {
					fieldPrmtnAplcblEligibility.tsssEligibility.includedTsssSegments.tsssSegmentId.push(
						item.value
					);
				}
			});
			tsssEligibilitySegments.map((item) => {
				item.selected = false;
			});
		}
		setFieldValue('', '');
	};
	const excludeSelected = () => {
		if (type == 'employee') {
			if (!fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments) {
				fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments = {
					employeeSegmentId: []
				};
			}
			availableEmployeeSegments.map((item) => {
				if (item.selected) {
					fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments.employeeSegmentId.push(
						item.value
					);
				}
			});
			availableEmployeeSegments.map((item) => {
				item.selected = false;
			});
		} else if (type == 'loyaltyCustomer') {
			if (
				!fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
					.excludedLoyaltyCustomerSegments
			) {
				fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.excludedLoyaltyCustomerSegments =
					{ loyaltyCustomerSegment: [] };
			}
			availableLoyaltyCustomerSegments.map((item) => {
				if (item.selected) {
					fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.excludedLoyaltyCustomerSegments.loyaltyCustomerSegment.push(
						item.value
					);
				}
			});
			availableLoyaltyCustomerSegments.map((item) => {
				item.selected = false;
			});
		} else if (type == 'customer') {
			if (!fieldPrmtnAplcblEligibility.customerSegmentEligibility.excludedCustomerSegments) {
				fieldPrmtnAplcblEligibility.customerSegmentEligibility.excludedCustomerSegments = {
					customerSegmentId: []
				};
			}
			availableCustomerSegments.map((item) => {
				if (item.selected) {
					fieldPrmtnAplcblEligibility.customerSegmentEligibility.excludedCustomerSegments.customerSegmentId.push(
						item.value
					);
				}
			});
			availableCustomerSegments.map((item) => {
				item.selected = false;
			});
		} else if (type == 'tsssEligibility') {
			if (!fieldPrmtnAplcblEligibility.tsssEligibility.excludedTsssSegments) {
				fieldPrmtnAplcblEligibility.tsssEligibility.excludedTsssSegments = {
					tsssSegmentId: []
				};
			}
			tsssEligibilitySegments.map((item) => {
				if (item.selected) {
					fieldPrmtnAplcblEligibility.tsssEligibility.excludedTsssSegments.tsssSegmentId.push(
						item.value
					);
				}
			});
			tsssEligibilitySegments.map((item) => {
				item.selected = false;
			});
		}

		setFieldValue('', '');
	};
	return (
		<div className="p-2">
			{type == 'employee' ? (
				<div className="row">
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="font-weight-bold flex-grow-1">
								Included(
								{fieldPrmtnAplcblEligibility.employeeEligibility
									.includedEmployeeSegments?.employeeSegmentId?.length
									? fieldPrmtnAplcblEligibility.employeeEligibility
											.includedEmployeeSegments?.employeeSegmentId?.length
									: 0}
								)
							</div>
							<div
								className={
									fieldPrmtnAplcblEligibility.employeeEligibility
										.includedEmployeeSegments?.employeeSegmentId?.length > 0
										? ''
										: 'd-none'
								}
								onClick={() => {
									fieldPrmtnAplcblEligibility.employeeEligibility.includedEmployeeSegments.employeeSegmentId =
										[];
									setFieldValue('', '');
								}}
							>
								Clear All
							</div>
						</div>
						<div className="border segmentBox">
							<FieldArray name="promotionPolicy.promotionApplicableEligibility.employeeEligibility.includedEmployeeSegments.employeeSegmentId">
								{({ insert, remove, push }) => (
									<>
										{fieldPrmtnAplcblEligibility.employeeEligibility
											.includedEmployeeSegments &&
											fieldPrmtnAplcblEligibility.employeeEligibility
												.includedEmployeeSegments.employeeSegmentId.length >
												0 &&
											fieldPrmtnAplcblEligibility.employeeEligibility.includedEmployeeSegments.employeeSegmentId.map(
												(id, index) => (
													<>
														{/* <CustomInputText name={`promotionPolicy.promotionApplicableEligibility.employeeEligibility.includedEmployeeSegments.${index}.employeeSegmentId`}
                       label={''} smallText={''} /> */}
														<span className="segment">
															{empSegMap[id]}
															<button
																type="button"
																className="segCloseBtn"
																onClick={() => remove(index)}
															>
																X
															</button>
														</span>
													</>
												)
											)}
									</>
								)}
							</FieldArray>
						</div>
					</div>
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="col-sm-2 p-0"></div>
							<div className="col-sm-10 font-weight-bold p-0">
								List of Employee Segment IDs(
								{availableEmployeeSegments.length -
									(fieldPrmtnAplcblEligibility.employeeEligibility
										.includedEmployeeSegments?.employeeSegmentId?.length
										? fieldPrmtnAplcblEligibility.employeeEligibility
												.includedEmployeeSegments?.employeeSegmentId?.length
										: 0) -
									(fieldPrmtnAplcblEligibility.employeeEligibility
										.excludedEmployeeSegments?.employeeSegmentId?.length
										? fieldPrmtnAplcblEligibility.employeeEligibility
												.excludedEmployeeSegments?.employeeSegmentId?.length
										: 0)}
								)
							</div>
						</div>
						<div className="d-flex">
							<div className="flex-grow-1 segmentincl">
								<button
									type="button"
									className="lesserThan-arrow"
									onClick={() => {
										includeSelected();
									}}
								></button>
							</div>
							<div className="flex-grow-8 border segmentBox">
								{availableEmployeeSegments.map((i, indx) => (
									<div
										key={indx}
										className={
											fieldPrmtnAplcblEligibility.employeeEligibility.includedEmployeeSegments?.employeeSegmentId?.some(
												(item, j) => {
													return item == i.value;
												}
											) ||
											fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments?.employeeSegmentId?.some(
												(item, j) => {
													return item == i.value;
												}
											)
												? `d-none`
												: ``
										}
									>
										<input
											type="checkbox"
											onChange={(e) => onEmpSegChange(e, indx)}
											value={i.selected}
											checked={i.selected}
										/>
										<label key={i.value} className="pl-3 font-weight-bold">
											{i.name}
										</label>
									</div>
								))}
							</div>
							<div className="flex-grow-1 segmentexcl">
								<button
									type="button"
									className="greater-than-arrow"
									onClick={() => {
										excludeSelected();
									}}
								></button>
							</div>
						</div>
					</div>
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="font-weight-bold flex-grow-1">
								Excluded(
								{fieldPrmtnAplcblEligibility.employeeEligibility
									.excludedEmployeeSegments?.employeeSegmentId?.length
									? fieldPrmtnAplcblEligibility.employeeEligibility
											.excludedEmployeeSegments?.employeeSegmentId?.length
									: 0}
								)
							</div>
							<div
								className={
									fieldPrmtnAplcblEligibility.employeeEligibility
										.excludedEmployeeSegments?.employeeSegmentId?.length > 0
										? ''
										: 'd-none'
								}
								onClick={() => {
									fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments.employeeSegmentId =
										[];
									setFieldValue('', '');
								}}
							>
								Clear All
							</div>
						</div>
						{/* <div className="font-weight-bold">
         Excluded({fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments?.length?fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments?.length:0})
         </div> */}
						<div className="border segmentBox">
							<FieldArray name="promotionPolicy.promotionApplicableEligibility.employeeEligibility.excludedEmployeeSegments.employeeSegmentId">
								{({ insert, remove, push }) => (
									<>
										{fieldPrmtnAplcblEligibility.employeeEligibility
											.excludedEmployeeSegments &&
											fieldPrmtnAplcblEligibility.employeeEligibility
												.excludedEmployeeSegments.employeeSegmentId.length >
												0 &&
											fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments.employeeSegmentId.map(
												(id, index) => (
													<>
														{/* <CustomInputText name={`promotionPolicy.promotionApplicableEligibility.employeeEligibility.excludedEmployeeSegments.${index}.employeeSegmentId`}
                       label={''} smallText={''} /> */}
														<span className="segment">
															{empSegMap[id]}

															<button
																type="button"
																className="segCloseBtn"
																onClick={() => remove(index)}
															>
																X
															</button>
														</span>
													</>
												)
											)}
									</>
								)}
							</FieldArray>
						</div>
					</div>
				</div>
			) : null}
			{type == 'loyaltyCustomer' ? (
				<div className="row">
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="font-weight-bold flex-grow-1">
								Included(
								{fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
									.includedLoyaltyCustomerSegments?.loyaltyCustomerSegment?.length
									? fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
											.includedLoyaltyCustomerSegments?.loyaltyCustomerSegment
											?.length
									: 0}
								)
							</div>
							<div
								className={
									fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
										.includedLoyaltyCustomerSegments?.loyaltyCustomerSegment
										?.length > 0
										? ''
										: 'd-none'
								}
								onClick={() => {
									fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.includedLoyaltyCustomerSegments.loyaltyCustomerSegment =
										[];
									setFieldValue('', '');
								}}
							>
								Clear All
							</div>
						</div>
						<div className="border segmentBox">
							<FieldArray name="promotionPolicy.promotionApplicableEligibility.loyaltyCustomerEligibility.includedLoyaltyCustomerSegments.loyaltyCustomerSegment">
								{({ insert, remove, push }) => (
									<>
										{fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
											.includedLoyaltyCustomerSegments &&
											fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
												.includedLoyaltyCustomerSegments
												.loyaltyCustomerSegment.length > 0 &&
											fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.includedLoyaltyCustomerSegments.loyaltyCustomerSegment.map(
												(id, index) => (
													<>
														{/* <CustomInputText name={`promotionPolicy.promotionApplicableEligibility.employeeEligibility.includedEmployeeSegments.${index}.employeeSegmentId`}
                       label={''} smallText={''} /> */}
														<span className="segment">
															{loyalSegMap[id]}
															<button
																type="button"
																className="segCloseBtn"
																onClick={() => remove(index)}
															>
																X
															</button>
														</span>
													</>
												)
											)}
									</>
								)}
							</FieldArray>
						</div>
					</div>
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="col-sm-2 p-0"></div>
							<div className="col-sm-10 font-weight-bold p-0">
								List of Loyalty Customer Segment IDs(
								{availableLoyaltyCustomerSegments.length -
									(fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
										.includedLoyaltyCustomerSegments?.loyaltyCustomerSegment
										?.length
										? fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
												.includedLoyaltyCustomerSegments
												?.loyaltyCustomerSegment?.length
										: 0) -
									(fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
										.excludedLoyaltyCustomerSegments?.loyaltyCustomerSegment
										?.length
										? fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
												.excludedLoyaltyCustomerSegments
												?.loyaltyCustomerSegment?.length
										: 0)}
								)
							</div>
						</div>
						<div className="d-flex">
							<div className="flex-grow-1 segmentincl">
								<button
									type="button"
									className="btn btn-outline-secondary br-50"
									onClick={() => {
										includeSelected();
									}}
								>
									&lt;
								</button>
							</div>
							<div className="flex-grow-8 border segmentBox">
								{availableLoyaltyCustomerSegments.map((i, indx) => (
									<div
										key={indx}
										className={
											fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.includedLoyaltyCustomerSegments?.loyaltyCustomerSegment?.some(
												(item, j) => {
													return item == i.value;
												}
											) ||
											fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.excludedLoyaltyCustomerSegments?.loyaltyCustomerSegment?.some(
												(item, j) => {
													return item == i.value;
												}
											)
												? `d-none`
												: ``
										}
									>
										<input
											type="checkbox"
											onChange={(e) => onEmpSegChange(e, indx)}
											value={i.selected}
											checked={i.selected}
										/>
										<label key={i.value} className="pl-3 font-weight-bold">
											{i.name}
										</label>
									</div>
								))}
							</div>
							<div className="flex-grow-1 segmentexcl">
								<button
									type="button"
									className="btn btn-outline-secondary br-50"
									onClick={() => {
										excludeSelected();
									}}
								>
									&gt;
								</button>
							</div>
						</div>
					</div>
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="font-weight-bold flex-grow-1">
								Excluded(
								{fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
									.excludedLoyaltyCustomerSegments?.loyaltyCustomerSegment?.length
									? fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
											.excludedLoyaltyCustomerSegments?.loyaltyCustomerSegment
											?.length
									: 0}
								)
							</div>
							<div
								className={
									fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
										.excludedLoyaltyCustomerSegments?.loyaltyCustomerSegment
										?.length > 0
										? ''
										: 'd-none'
								}
								onClick={() => {
									fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.excludedLoyaltyCustomerSegments.loyaltyCustomerSegment =
										[];
									setFieldValue('', '');
								}}
							>
								Clear All
							</div>
						</div>
						{/* <div className="font-weight-bold">
         Excluded({fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments?.length?fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments?.length:0})
         </div> */}
						<div className="border segmentBox">
							<FieldArray name="promotionPolicy.promotionApplicableEligibility.loyaltyCustomerEligibility.excludedLoyaltyCustomerSegments.loyaltyCustomerSegment">
								{({ insert, remove, push }) => (
									<>
										{fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
											.excludedLoyaltyCustomerSegments &&
											fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
												.excludedLoyaltyCustomerSegments
												.loyaltyCustomerSegment.length > 0 &&
											fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.excludedLoyaltyCustomerSegments.loyaltyCustomerSegment.map(
												(id, index) => (
													<>
														{/* <CustomInputText name={`promotionPolicy.promotionApplicableEligibility.employeeEligibility.excludedEmployeeSegments.${index}.employeeSegmentId`}
                       label={''} smallText={''} /> */}
														<span className="segment">
															{loyalSegMap[id]}

															<button
																type="button"
																className="segCloseBtn"
																onClick={() => remove(index)}
															>
																X
															</button>
														</span>
													</>
												)
											)}
									</>
								)}
							</FieldArray>
						</div>
					</div>
				</div>
			) : null}

			{type == 'customer' ? (
				<div className="row">
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="font-weight-bold flex-grow-1">
								Included(
								{fieldPrmtnAplcblEligibility.customerSegmentEligibility
									.includedCustomerSegments?.customerSegmentId?.length
									? fieldPrmtnAplcblEligibility.customerSegmentEligibility
											.includedCustomerSegments?.customerSegmentId?.length
									: 0}
								)
							</div>
							<div
								className={
									fieldPrmtnAplcblEligibility.customerSegmentEligibility
										.includedCustomerSegments?.customerSegmentId?.length > 0
										? ''
										: 'd-none'
								}
								onClick={() => {
									fieldPrmtnAplcblEligibility.customerSegmentEligibility.includedCustomerSegments.customerSegmentId =
										[];
									setFieldValue('', '');
								}}
							>
								Clear All
							</div>
						</div>
						<div className="border segmentBox">
							<FieldArray name="promotionPolicy.promotionApplicableEligibility.customerSegmentEligibility.includedCustomerSegments.customerSegmentId">
								{({ insert, remove, push }) => (
									<>
										{fieldPrmtnAplcblEligibility.customerSegmentEligibility
											.includedCustomerSegments &&
											fieldPrmtnAplcblEligibility.customerSegmentEligibility
												.includedCustomerSegments.customerSegmentId.length >
												0 &&
											fieldPrmtnAplcblEligibility.customerSegmentEligibility.includedCustomerSegments.customerSegmentId.map(
												(id, index) => (
													<>
														{/* <CustomInputText name={`promotionPolicy.promotionApplicableEligibility.employeeEligibility.includedEmployeeSegments.${index}.employeeSegmentId`}
                       label={''} smallText={''} /> */}
														<span className="segment">
															{custSegMap[id]}
															<button
																type="button"
																className="segCloseBtn"
																onClick={() => remove(index)}
															>
																X
															</button>
														</span>
													</>
												)
											)}
									</>
								)}
							</FieldArray>
						</div>
					</div>
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="col-sm-2 p-0"></div>
							<div className="col-sm-10 font-weight-bold p-0">
								List of Customer Segment IDs(
								{availableCustomerSegments.length -
									(fieldPrmtnAplcblEligibility.customerSegmentEligibility
										.includedCustomerSegments?.customerSegmentId?.length
										? fieldPrmtnAplcblEligibility.customerSegmentEligibility
												.includedCustomerSegments?.customerSegmentId?.length
										: 0) -
									(fieldPrmtnAplcblEligibility.customerSegmentEligibility
										.excludedCustomerSegments?.customerSegmentId?.length
										? fieldPrmtnAplcblEligibility.customerSegmentEligibility
												.excludedCustomerSegments?.customerSegmentId?.length
										: 0)}
								)
							</div>
						</div>
						<div className="d-flex">
							<div className="flex-grow-1 segmentincl">
								<button
									type="button"
									className="btn btn-outline-secondary br-50"
									onClick={() => {
										includeSelected();
									}}
								>
									&lt;
								</button>
							</div>
							<div className="flex-grow-8 border segmentBox">
								{availableCustomerSegments.map((i, indx) => (
									<div
										key={indx}
										className={
											fieldPrmtnAplcblEligibility.customerSegmentEligibility.includedCustomerSegments?.customerSegmentId?.some(
												(item, j) => {
													return item == i.value;
												}
											) ||
											fieldPrmtnAplcblEligibility.customerSegmentEligibility.excludedCustomerSegments?.customerSegmentId?.some(
												(item, j) => {
													return item == i.value;
												}
											)
												? `d-none`
												: ``
										}
									>
										<input
											type="checkbox"
											onChange={(e) => onEmpSegChange(e, indx)}
											value={i.selected}
											checked={i.selected}
										/>
										<label key={i.value} className="pl-3 font-weight-bold">
											{i.name}
										</label>
									</div>
								))}
							</div>
							<div className="flex-grow-1 segmentexcl">
								<button
									type="button"
									className="btn btn-outline-secondary br-50"
									onClick={() => {
										excludeSelected();
									}}
								>
									&gt;
								</button>
							</div>
						</div>
					</div>
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="font-weight-bold flex-grow-1">
								Excluded(
								{fieldPrmtnAplcblEligibility.customerSegmentEligibility
									.excludedCustomerSegments?.customerSegmentId?.length
									? fieldPrmtnAplcblEligibility.customerSegmentEligibility
											.excludedCustomerSegments?.customerSegmentId?.length
									: 0}
								)
							</div>
							<div
								className={
									fieldPrmtnAplcblEligibility.customerSegmentEligibility
										.excludedCustomerSegments?.customerSegmentId?.length > 0
										? ''
										: 'd-none'
								}
								onClick={() => {
									fieldPrmtnAplcblEligibility.customerSegmentEligibility.excludedCustomerSegments.customerSegmentId =
										[];
									setFieldValue('', '');
								}}
							>
								Clear All
							</div>
						</div>
						{/* <div className="font-weight-bold">
         Excluded({fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments?.length?fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments?.length:0})
         </div> */}
						<div className="border segmentBox">
							<FieldArray name="promotionPolicy.promotionApplicableEligibility.customerSegmentEligibility.excludedCustomerSegments.customerSegmentId">
								{({ insert, remove, push }) => (
									<>
										{fieldPrmtnAplcblEligibility.customerSegmentEligibility
											.excludedCustomerSegments &&
											fieldPrmtnAplcblEligibility.customerSegmentEligibility
												.excludedCustomerSegments.customerSegmentId.length >
												0 &&
											fieldPrmtnAplcblEligibility.customerSegmentEligibility.excludedCustomerSegments.customerSegmentId.map(
												(id, index) => (
													<>
														{/* <CustomInputText name={`promotionPolicy.promotionApplicableEligibility.employeeEligibility.excludedEmployeeSegments.${index}.employeeSegmentId`}
                       label={''} smallText={''} /> */}
														<span className="segment">
															{custSegMap[id]}

															<button
																type="button"
																className="segCloseBtn"
																onClick={() => remove(index)}
															>
																X
															</button>
														</span>
													</>
												)
											)}
									</>
								)}
							</FieldArray>
						</div>
					</div>
				</div>
			) : null}

			{type == 'tsssEligibility' ? (
				<div className="row">
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="font-weight-bold flex-grow-1">
								Included(
								{fieldPrmtnAplcblEligibility.tsssEligibility.includedTsssSegments
									?.tsssSegmentId?.length
									? fieldPrmtnAplcblEligibility.tsssEligibility
											.includedTsssSegments?.tsssSegmentId?.length
									: 0}
								)
							</div>
							<div
								className={
									fieldPrmtnAplcblEligibility.tsssEligibility.includedTsssSegments
										?.tsssSegmentId?.length > 0
										? ''
										: 'd-none'
								}
								onClick={() => {
									fieldPrmtnAplcblEligibility.tsssEligibility.includedTsssSegments.tsssSegmentId =
										[];
									setFieldValue('', '');
								}}
							>
								Clear All
							</div>
						</div>
						<div className="border segmentBox">
							<FieldArray name="promotionPolicy.promotionApplicableEligibility.tsssEligibility.includedTsssSegments.tsssSegmentId">
								{({ insert, remove, push }) => (
									<>
										{fieldPrmtnAplcblEligibility.tsssEligibility
											.includedTsssSegments &&
											fieldPrmtnAplcblEligibility.tsssEligibility
												.includedTsssSegments.tsssSegmentId.length > 0 &&
											fieldPrmtnAplcblEligibility.tsssEligibility.includedTsssSegments.tsssSegmentId.map(
												(id, index) => (
													<>
														<span className="segment">
															{tsssSegMap[id]}
															<button
																type="button"
																className="segCloseBtn"
																onClick={() => remove(index)}
															>
																X
															</button>
														</span>
													</>
												)
											)}
									</>
								)}
							</FieldArray>
						</div>
					</div>
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="col-sm-2 p-0"></div>
							<div className="col-sm-10 font-weight-bold p-0">
								List of TSSS IDs(
								{tsssEligibilitySegments.length -
									(fieldPrmtnAplcblEligibility.tsssEligibility
										.includedTsssSegments?.tsssSegmentId?.length
										? fieldPrmtnAplcblEligibility.tsssEligibility
												.includedTsssSegments?.tsssSegmentId?.length
										: 0) -
									(fieldPrmtnAplcblEligibility.tsssEligibility
										.excludedTsssSegments?.tsssSegmentId?.length
										? fieldPrmtnAplcblEligibility.tsssEligibility
												.excludedTsssSegments?.tsssSegmentId?.length
										: 0)}
								)
							</div>
						</div>
						<div className="d-flex">
							<div className="flex-grow-1 segmentincl">
								<button
									type="button"
									className="btn btn-outline-secondary br-50"
									onClick={() => {
										includeSelected();
									}}
								>
									&lt;
								</button>
							</div>
							<div className="flex-grow-8 border segmentBox">
								{tsssEligibilitySegments.map((i, indx) => (
									<div
										key={indx}
										className={
											fieldPrmtnAplcblEligibility.tsssEligibility.includedTsssSegments?.tsssSegmentId?.some(
												(item, j) => {
													return item == i.value;
												}
											) ||
											fieldPrmtnAplcblEligibility.tsssEligibility.excludedTsssSegments?.tsssSegmentId?.some(
												(item, j) => {
													return item == i.value;
												}
											)
												? `d-none`
												: ``
										}
									>
										<input
											type="checkbox"
											onChange={(e) => onEmpSegChange(e, indx)}
											value={i.selected}
											checked={i.selected}
										/>
										<label key={i.value} className="pl-3 font-weight-bold">
											{i.name}
										</label>
									</div>
								))}
							</div>
							<div className="flex-grow-1 segmentexcl">
								<button
									type="button"
									className="btn btn-outline-secondary br-50"
									onClick={() => {
										excludeSelected();
									}}
								>
									&gt;
								</button>
							</div>
						</div>
					</div>
					<div className="col-sm-4 p-0">
						<div className="d-flex">
							<div className="font-weight-bold flex-grow-1">
								Excluded(
								{fieldPrmtnAplcblEligibility.tsssEligibility.excludedTsssSegments
									?.tsssSegmentId?.length
									? fieldPrmtnAplcblEligibility.tsssEligibility
											.excludedTsssSegments?.tsssSegmentId?.length
									: 0}
								)
							</div>
							<div
								className={
									fieldPrmtnAplcblEligibility.tsssEligibility.excludedTsssSegments
										?.tsssSegmentId?.length > 0
										? ''
										: 'd-none'
								}
								onClick={() => {
									fieldPrmtnAplcblEligibility.tsssEligibility.excludedTsssSegments.tsssSegmentId =
										[];
									setFieldValue('', '');
								}}
							>
								Clear All
							</div>
						</div>

						<div className="border segmentBox">
							<FieldArray name="promotionPolicy.promotionApplicableEligibility.tsssEligibility.excludedTsssSegments.tsssSegmentId">
								{({ insert, remove, push }) => (
									<>
										{fieldPrmtnAplcblEligibility.tsssEligibility
											.excludedTsssSegments &&
											fieldPrmtnAplcblEligibility.tsssEligibility
												.excludedTsssSegments.tsssSegmentId.length > 0 &&
											fieldPrmtnAplcblEligibility.tsssEligibility.excludedTsssSegments.tsssSegmentId.map(
												(id, index) => (
													<>
														<span className="segment">
															{tsssSegMap[id]}

															<button
																type="button"
																className="segCloseBtn"
																onClick={() => remove(index)}
															>
																X
															</button>
														</span>
													</>
												)
											)}
									</>
								)}
							</FieldArray>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default Segments;
