import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../BasicDetails.scss';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import Datetime from 'react-datetime';
import appLabels from '../../../../utils/appLabels';

const CustomDateTime = ({ label, required, prepend, smallText, type, flag, ...props }) => {
	const [field, meta] = useField(props);
	const { setFieldValue, setFieldTouched } = useFormikContext();

	const [errMsg, setErrMsg] = useState('');
	const formatDate = (value) => {
		return moment(value).format('YYYY-MM-DD HH:mm:ss');
	};
	let inputProps = {
		//() => alert('You went to the input but it was disabled')
		onClick: (e) => {
			e.preventDefault();
			var s = e.target.parentElement;
			s.scrollIntoView();
		},
		placeholder: 'DD-MM-YYYY HH:mm',
		open: true
	};

	let inputProps1 = {
		placeholder: 'DD-MM-YYYY HH:mm',
		open: true
	};

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	let formattedName = '';
	let newJson = '';
	let basicOrEligibilityJson = '';
	if (isAuditFlw) {
		let newCustName = JSON.parse(JSON.stringify(props.name));
		// eslint-disable-next-line no-unused-vars
		newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
		basicOrEligibilityJson = newCustName.includes('promotionPolicy')
			? 'newJson'
			: 'newJson.promotionPolicy.priceDerivationRuleEligibility';
		let arrayOfName = newCustName.split('.');

		for (let i = 0; i < arrayOfName.length; i++) {
			if (isNaN(arrayOfName[i])) {
				formattedName = formattedName + '?.' + arrayOfName[i];
			} else {
				formattedName = formattedName + '?.[' + arrayOfName[i] + ']';
			}
		}
	}

	return (
		<div className="container">
			<div
				className={
					isAuditFlw &&
					eval(basicOrEligibilityJson + formattedName) === field.value &&
					flag != 'show'
						? 'hideForAudit rowContainer'
						: 'rowContainer'
				}
			>
				<div
					className={
						props.name.includes('cashbackInformation')
							? 'd-none'
							: 'customLabelContainer'
					}
				>
					<label htmlFor="forprogramID" className="col col-form-label">
						{label}

						{required ? <span className="requiredRed">*</span> : null}
					</label>
				</div>
				<div
					className={
						props.name.includes('coupons')
							? 'customInputContainer dateTime couponDate'
							: props.name.includes('cashbackInformation')
							? 'customInputContainerPA dateTime'
							: 'customInputContainer dateTime'
					}
				>
					<div className="row">
						{/* <input
              type="datetime-local"
              {...field}
              {...props}
              value={field.value && field.value.replace(' ', 'T')}
              className="form-control col-md-9"
              onChange={e => {
                // alert('val ' + moment(e.target.value).format('YYYY-MM-DD HH:mm:ss'))
                
                // field.value = 'hey'
                const { value } = e.target
                if (field.name === "promotionPolicy.effectiveStartDate" || 
                field.name === `promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod.${props?.index}.blackOutStartDate`
                ) {
                  const currentTime = moment().format("hh:mm")
                  const eventTime = moment(value).format("hh:mm")
                  if (currentTime == eventTime) {
                    // change to default time
                    const newDateTime = moment(moment(value).format('YYYY-MM-DD') + " " + '00:00')
                    setFieldValue(field.name, moment(newDateTime).format('YYYY-MM-DD HH:mm:ss'))
                  } else {
                    setFieldValue(field.name, moment(e.target.value).format('YYYY-MM-DD HH:mm:ss'))
                  }
                } else if (field.name === 'promotionPolicy.effectiveEndDate' || 
                field.name === `promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod.${props?.index}.blackOutEndDate`
                ) {
                  const currentTime = moment().format("hh:mm")
                  const eventTime = moment(value).format("hh:mm")
                  if (currentTime == eventTime) {
                    // change to default time
                    const newDateTime = moment(moment(value).format('YYYY-MM-DD') + " " + '23:59')
                    setFieldValue(field.name, moment(newDateTime).format('YYYY-MM-DD HH:mm:ss'))
                  } else {
                    setFieldValue(field.name, moment(e.target.value).format('YYYY-MM-DD HH:mm:ss'))
                  }
                } else {
                  setFieldValue(field.name, moment(e.target.value).format('YYYY-MM-DD HH:mm:ss'))
                }
                // setFieldValue(field.name, val);
              }}
              className={`form-control ${
                meta.touched && meta.error ? "is-invalid" : ""
                }`}
            /> */}
						<div className={isAuditFlw ? 'hideForAudit' : ''}>
							<Datetime
								// locale="en-GB"
								{...field}
								{...props}
								style={{ width: '100% !important' }}
								name={props.name}
								id={props.name}
								// inputProps={{ placeholder: 'YYYY-MM-DD HH:mm' }}
								// inputProps={{ placeholder: 'DD-MM-YYYY HH:mm' }}
								value={
									props.valueDate
										? moment(props.valueDate).format('DD-MM-YYYY HH:mm')
										: undefined
								}
								dateFormat="DD-MM-YYYY"
								timeFormat="HH:mm"
								onChange={(dateFromValue) => {
									if (
										!moment(dateFromValue, 'DD-MM-YYYY HH:mm', true).isValid()
									) {
										setErrMsg(appLabels.errorLabel.invalidDateFormat);
										//  setFieldError(props.name,'Invalid Date Format');
									} else {
										setErrMsg('');
										// setFieldValue(props.name, formatDate(dateFromValue));
										if (
											props.name === 'promotionPolicy.effectiveEndDate' ||
											props.name ===
												`promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod.${props?.index}.blackOutEndDate`
										) {
											const eventTime = moment(dateFromValue).format('hh:mm');

											if (eventTime == '12:00') {
												// change to default time
												const newDateTime = moment(
													moment(dateFromValue).format('YYYY-MM-DD') +
														' ' +
														'23:59'
												);
												setFieldValue(props.name, formatDate(newDateTime));
											} else {
												setFieldValue(
													props.name,
													formatDate(dateFromValue)
												);
											}
										} else {
											setFieldValue(props.name, formatDate(dateFromValue));
										}
									}
									setTimeout(() => setFieldTouched(props.name, true));
								}}
								onBlur={() => {
									setFieldValue('', '');
								}}
								inputProps={
									props?.isBottomDateTimePicker ? inputProps : inputProps1
								}
								// isInvalid={!!meta.error}
								// readOnly={this.props.status !== ProjectStatus.InProgress}
								closeOnSelect={true}
							/>
						</div>
						{isAuditFlw ? (
							<div>
								<div className="d-flex gap70 replacedText">
									<input
										type="text"
										className="form-control lightGray"
										value={moment(field.value).format('DD-MM-YYYY HH:mm')}
									/>
									<input
										type="text"
										className={
											eval(basicOrEligibilityJson + formattedName) ===
											field.value
												? 'd-none'
												: 'form-control lightBlue'
										}
										value={moment(
											eval(basicOrEligibilityJson + formattedName)
										).format('DD-MM-YYYY HH:mm')}
									/>
								</div>
							</div>
						) : null}
						{smallText && (
							<small id="emailHelp" className="form-text pl-2">
								<div className="tooltipimg">
									<button className="tooltip-button btn br-50" type="button">
										<span className="tooltip-icon">?</span>
									</button>
									<span className="tooltiptext"> {smallText}</span>
								</div>
							</small>
						)}
						{meta.touched && meta.error ? (
							<div className="invalid-feedback d-block">{meta.error}</div>
						) : null}
						<div className="invalid-feedback d-block">{errMsg}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomDateTime;
