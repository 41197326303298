import { Comparator } from 'react-bootstrap-table2-filter';
import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';

class DateFilter extends React.Component {
	static propTypes = {
		column: PropTypes.object.isRequired,
		onFilter: PropTypes.func.isRequired
	};
	constructor(props) {
		super(props);
		this.state = { date: '' };
		this.filter = this.filter.bind(this);
		this.getValue = this.getValue.bind(this);
	}
	getValue() {
		return this.input.value;
	}
	filter() {
		this.props.onFilter({ date: this.state.date._d, comparator: this.select.value });
	}
	render() {
		return [
			<div key="key-1" className="d-flex pb-2">
				<select
					key="select"
					ref={(node) => (this.select = node)}
					className="form-control"
					style={{
						maxWidth: '65px',
						// marginRight: "36px",
						marginLeft: '8px'
					}}
				>
					<option value={Comparator.GT}>&gt;</option>
					<option value={Comparator.EQ}>=</option>
					<option value={Comparator.LT}>&lt;</option>
				</select>
				<div className="d-flex dfilter">
					<Datetime
						locale="en-GB"
						onChange={(value) => {
							this.setState({ date: value }, () => {
								this.filter();
							});
						}}
						dateFormat="DD-MM-YYYY"
						inputProps={{ placeholder: 'DD-MM-YYYY HH:mm' }}
						value={this.state.date}
						closeOnSelect={true}
						timeFormat="HH:mm:ss"
					/>
					<button
						className="clrBtn"
						onClick={() => {
							this.setState({ date: '' }, () => {
								this.filter();
							});
						}}
					>
						X
					</button>
				</div>
			</div>
			// <input
			//   key="input"
			//   ref={ node => this.input = node }
			//   type="text"
			//   placeholder="Input price"
			//   onChange={this.filter}
			// />,
			// <button
			//   key="submit"
			//   className="btn btn-warning"
			//   onClick={ this.filter }
			// >
			//   { `Filter ${this.props.column.text}` }
			// </button>
		];
	}
}
export default DateFilter;
