import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './createOffer.scss';
import { Formik } from 'formik';
import EligibilityComponentEBSH from './components/EligibilityComponentEBSH';
import * as Yup from 'yup';
import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import toaster from 'toasted-notes';
import { isObjectEmpty, trimDate, appendDate, getOfferFlowType } from '../../utils/helperFunction';
import omitEmpty from 'omit-empty';
import chevronDown from '../../assets/images/chevron-down.png';
import { Link, Element } from 'react-scroll';
import { ConfirmationModal } from '../../MainContent/ConfirmationModal';
import { InformationModal } from '../../MainContent/InformationModal';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';

//Local const
const localConst = {
	offerMaintainance: 'Offer Maintenance',
	basicDetails: 'Basic Details',
	PromotionType: 'Promotion Type',
	description: 'Description',
	combinationPromotion: 'Combination with other Promotions',
	shares: 'Shares',
	redemptionSettings: 'Redemption Settings',
	others: 'Others',
	applicationEligibility: 'Applicable Eligibility',
	channelEligibility: 'Channel Eligibility',
	privilegeCustomer: 'Privilege Customer Eligibility',
	anonymousCostomer: 'Anonymous Customer Eligibility',
	employeeEligibility: 'Employee Eligibility',
	loyaltyCustomer: 'Loyalty Customer Eligibility',
	customerSegments: 'Customer Segments Eligibility',
	loyaltyCard: 'LoyaltyCard Customer Eligibility',
	happyHours: 'Happy Hours',
	collectionRequired: 'Collection Required',
	eligibilitySettings: 'Eligibility Settings'
};

let initialValues = {
	earnBurnEligibility: {
		groupDetail: [
			{
				merchandizeHierarchyGroup: [],
				isEarn: false,
				isBurn: true
			}
		],
		thresholdTypeCode: '',
		exclusions: {
			excludedItemIDList: {
				excludedItemID: []
			},
			merchandizeHierarchyGroup: []
		},
		paymentEligibility: {
			allowAggAndIssuer: false
		},
		benefit: {
			benefitType: ''
		}
	}
};

const SignupSchema = Yup.object().shape({
	earnBurnEligibility: Yup.object().shape({
		triggerOperatorValue: Yup.string().when('triggerOperator', {
			//  is: triggerOperator => ("<" || ">" || ">=" || "<=" || "="),
			is: (triggerOperator) => {
				if (
					triggerOperator == '<' ||
					triggerOperator == '=' ||
					triggerOperator == '>' ||
					triggerOperator == '>=' ||
					triggerOperator == '<='
				) {
					return triggerOperator;
				}
			},
			then: Yup.string()
				.required('Please define price range value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price range value should be between 0.01 to 999999.99'
				)
		}),
		triggerOperatorValueFrom: Yup.string().when('triggerOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value From should be between 0.01 to 999999.99'
				)
				.required('Please define price range value from')
				.test(
					'triggerOperatorValueFrom_test',
					'Price Range Value From should be less than the Price Range Value To',
					function (value) {
						const { triggerOperatorValueTo } = this.parent;
						return (
							parseFloat(value) < triggerOperatorValueTo ||
							parseFloat(value) == triggerOperatorValueTo
						);
					}
				)
		}),
		triggerOperatorValueTo: Yup.string().when('triggerOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value To should be between 0.01 to 999999.99'
				)
				.required('Please define price range value to')
				.test(
					'triggerOperatorValueTo_test',
					'Price Range Value To should not less than the Price Range Value From',
					function (value) {
						const { triggerOperatorValueFrom } = this.parent;
						return (
							parseFloat(value) > triggerOperatorValueFrom ||
							parseFloat(value) == triggerOperatorValueFrom
						);
					}
				)
		}),
		rewardOperatorValue: Yup.string().when('rewardOperator', {
			// is: rewardOperator => ("<" || ">" || ">=" || "<=" || "="),
			is: (rewardOperator) => {
				if (
					rewardOperator == '<' ||
					rewardOperator == '=' ||
					rewardOperator == '>' ||
					rewardOperator == '>=' ||
					rewardOperator == '<='
				) {
					return rewardOperator;
				}
			},
			then: Yup.string()
				.required('Please define price range value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price range value should be between 0.01 to 999999.99'
				)
		}),
		rewardOperatorValueFrom: Yup.string().when('rewardOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value From should be between 0.01 to 999999.99'
				)
				.required('Please define price range value from')
				.test(
					'RewardOperatorValueFrom_test',
					'Price Range Value From should be less than the Price Range Value To',
					function (value) {
						const { rewardOperatorValueTo } = this.parent;
						return (
							parseFloat(value) < rewardOperatorValueTo ||
							parseFloat(value) == rewardOperatorValueTo
						);
					}
				)
		}),
		rewardOperatorValueTo: Yup.string().when('rewardOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value To should be between 0.01 to 999999.99'
				)
				.required('Please define price range value to')
				.test(
					'rewardOperatorValueTo_test',
					'Price Range Value To should not less than the Price Range Value From',
					function (value) {
						const { rewardOperatorValueFrom } = this.parent;
						return (
							parseFloat(value) > rewardOperatorValueFrom ||
							parseFloat(value) == rewardOperatorValueFrom
						);
					}
				)
		}),

		thresholdTypeCode: Yup.string().required('Please provide a Threshold Type value'),
		groupTriggerCombinationCode: Yup.string().when('thresholdTypeCode', {
			is: !{},
			then: Yup.string().required('Please define a Group Trigger Combination Code value')
		}),

		groupRewardCombinationCode: Yup.string().when('thresholdTypeCode', {
			is: !{},
			then: Yup.string().required('Please define a Group Reward Combination Code value')
		}),

		paymentEligibility: Yup.object().shape({
			applicableOn: Yup.string(),
			paymentThresholdTypeCode: Yup.string(),

			payment:
				/* Yup.object().when("paymentThresholdTypeCode", {
         is: "PAYMENT_AMOUNT_THRESHOLD", */
				Yup.object().when('applicableOn', {
					is: (applicableOn) => {
						if (applicableOn == 'TENDER_VALUE' || applicableOn == 'TRANSACTION_VALUE') {
							return applicableOn;
						}
					},
					then: Yup.object().shape({
						paymentThresholdAmount: Yup.string()
							.required('Please define a payment threshold amount value')
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Payment Threshold Amount should be between 0.01 to 999999.99'
							),
						paymentInformation: Yup.string(),
						emiType: Yup.string(),
						paymentGroup: Yup.array().of(
							Yup.object().shape({
								aggregators: Yup.array().required('Please define an aggregator'),
								issuers: Yup.array().of(
									Yup.object().shape({
										emi: Yup.object().shape({
											emiScale: Yup.array().of(
												Yup.object().shape({
													tenurePeriodInMonths: Yup.string()
														.required(
															'Please define a tenure period in months value'
														)
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Tenure Period in Months should be between 1 to 999'
														),
													interestRate: Yup.string()
														.required(
															'Please define a value for Interest rates'
														)
														.matches(
															/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
															'Tenure Percentage should be between 0.01 to 100.00'
														)
														.test(
															'percent_test',
															'Tenure Percentage should be between 0.01 to 100.00',
															function (value) {
																return parseFloat(value) < 100.01;
															}
														)
												})
											),
											coolOffPeriod: Yup.object().shape({
												coolOffPeriodType: Yup.string(),
												months: Yup.string().when('coolOffPeriodType', {
													is: 'Months',
													then: Yup.string()
														.required('Please define months value')
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Months should be between 1 to 999'
														)
												}),
												days: Yup.string().when('coolOffPeriodType', {
													is: 'Days',
													then: Yup.string()
														.required('Please define days value')
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Days should be between 1 to 999'
														)
												}),
												years: Yup.string().when('coolOffPeriodType', {
													is: 'Years',
													then: Yup.string()
														.required('Please define years value')
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Years should be between 1 to 999'
														)
												})
											})
										})
									})
								)
							})
						)
					})
				})
		}),
		expireOn: Yup.object().shape({
			expireOnType: Yup.string(),
			date: Yup.date().when('expireOnType', {
				is: 'Date',
				then: Yup.date()
					.min(
						new Date(),
						'Expiry On date should not be lesser than the promotion start date and time'
					)
					.required('Please define a date value')
			}),
			days: Yup.string().when('expireOnType', {
				is: 'Days',
				then: Yup.string()
					.required('Please define a days value')
					.matches(/^(?=.*[1-9])\d{0,3}$/, 'Please enter a value greater than 1 to 999.')
			}),
			months: Yup.string().when('expireOnType', {
				is: 'Months',
				then: Yup.string()
					.required('Please define a months value')
					.matches(/^(?=.*[1-9])\d{0,3}$/, 'Please enter a value greater than 1 to 999.')
			}),
			hours: Yup.string().when('expireOnType', {
				is: 'Hours',
				then: Yup.string().required('Please define a hours value')
			})
		}),
		benefit: Yup.object().shape({
			benefitType: Yup.string().required('Required: Benefit Type must be selected'),
			priceModificationMethodCode: Yup.string().when('benefitType', {
				is: (benefitType) => {
					if (benefitType == 'EARN_BURN') {
						return benefitType;
					}
				},
				then: Yup.string().required('Please define price modification method code value')
			}),

			priceModificationPercent: Yup.string().when('benefitType', {
				//is: benefitType =>  ("INSTANT" || "CASHBACK" || "FREEBIE" ),
				is: (benefitType) => {
					if (benefitType == 'EARN_BURN') {
						return benefitType;
					}
				},
				then: Yup.string().when('priceModificationMethodCode', {
					is: (priceModificationMethodCode) => {
						if (
							priceModificationMethodCode == 'PERCENTAGE_OFF' ||
							priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF'
						) {
							return priceModificationMethodCode;
						}
					},
					//  priceModificationMethodCode => ("PERCENTAGE_OFF" || "APPORTIONED_PERCENTAGE_OFF"),
					then: Yup.string()
						.required('Please define price modification percent value')
						.matches(
							/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
							'Price Modification Percent should be between 0.01 to 100.00'
						)
						.test(
							'percent_test',
							'Price Modification Percent should be between 0.01 to 100.00',
							function (value) {
								return parseFloat(value) < 100.01;
							}
						)
				})
			}),
			priceModificationAmount: Yup.string().when('priceModificationMethodCode', {
				// is:  priceModificationMethodCode => ("AMOUNT_OFF" || "APPORTIONED_AMOUNT_OFF"),
				is: (priceModificationMethodCode) => {
					if (
						priceModificationMethodCode == 'AMOUNT_OFF' ||
						priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF'
					) {
						return priceModificationMethodCode;
					}
				},
				then: Yup.string()
					.required('Please define price modification amount value')
					.matches(
						/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
						'Price Modification Amount should be between 0.01 to 999999.99'
					)
			}),

			//MARKDOWN KIT_MARKDOWN
			newPriceAmount: Yup.string().when('priceModificationMethodCode', {
				// is:  priceModificationMethodCode => ("MARKDOWN" || "KIT_MARKDOWN"),
				is: (priceModificationMethodCode) => {
					if (
						priceModificationMethodCode == 'MARKDOWN' ||
						priceModificationMethodCode == 'KIT_MARKDOWN'
					) {
						return priceModificationMethodCode;
					}
				},
				then: Yup.string()
					.required('Please define new price amount value')
					.matches(
						/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
						'Price Modification Amount should be between 0.01 to 999999.99'
					)
			}),

			priceModificationQuantity: Yup.string().when('priceModificationMethodCode', {
				is: 'QUANTITY_PRICING',
				then: Yup.string()
					.required('Please define quantity value')
					.matches(
						/^(?=.*[1-9])\d{0,6}$/,
						'Price Modification Quantity should be between 1 to 999999'
					)
			})
		})
	})
});

const EligibilityForm = ({ handleSubmit, ...props }) => {
	//state and props
	const [merchandizeTableData, setMerchandizeTableData] = useState([]);
	const location = useLocation();

	let history = useHistory();
	const myparam = location.state.params;
	const [initialData, setInitialData] = useState({});
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState('');
	const [informationTitle] = useState('Error');
	const myparam3 = location.state.SellingHierarchyTableDatafromBasicDetails;

	useEffect(() => {
		if (myparam) {
			if (
				JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility
					.earnBurnEligibility != undefined
			) {
				const prevData = JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility;

				const packageProductData = prevData.earnBurnEligibility;

				if (packageProductData?.groupDetail[0]?.multibuyProduct) {
					delete packageProductData.groupDetail[0].multibuyProduct;

					packageProductData.groupDetail[0] = {
						isBurn: true,
						isEarn: false,
						merchandizeHierarchyGroup: []
					};
				}

				let appendablePkgProdData = { groupDetail: [] };

				if (prevData.earnBurnEligibility?.expireOn != undefined) {
					prevData.earnBurnEligibility = appendDate(
						prevData.earnBurnEligibility,
						'expireOn',
						'expireOnType'
					);
				}

				if (packageProductData.groupDetail[0].merchandizeHierarchyGroup.length) {
					/*  packageProductData.map((pkg, indexPkg) => {
             appendablePkgProdData[indexPkg] = {
               groupDetail: [],
             }; */
					//debugger
					// appendablePkgProdData.push({"groupDetail":[]})
					appendablePkgProdData.groupDetail = packageProductData.groupDetail;
					/*    packageProductData.groupDetail.map((grp, iGrp) =>{
             prevData.earnBurnEligibility.groupDetail[iGrp].multibuyProduct = [];
             }) */

					/*  packageProductData.groupDetail.map((grp, iGrp) => {
            // appendablePkgProdData.groupDetail[iGrp] = {
             packageProductData.groupDetail[iGrp] = {
               multibuyProduct: [],
             };
 
            // appendablePkgProdData.groupDetail[iGrp].multibuyProduct = grp.multibuyProduct;
 
                appendablePkgProdData.groupDetail.push(grp);
 
             prevData.earnBurnEligibility.groupDetail[iGrp].multibuyProduct = [];
             // resetting to avoid the mismatch, when user goes to basic details and again comes back to eligibility during modify flow
           }); */
					//});
				}
				prevData.earnBurnEligibility.groupDetail.map((val, j) => {
					if (prevData.earnBurnEligibility.groupDetail[j]) {
						if (
							!prevData.earnBurnEligibility?.groupDetail[j]?.merchandizeHierarchyGroup
						) {
							prevData.earnBurnEligibility.groupDetail[j].merchandizeHierarchyGroup =
								[];
						}
					}
				});

				// Modify Settings

				if (!prevData.earnBurnEligibility.exclusions) {
					prevData.earnBurnEligibility.exclusions = {
						excludedItemIDList: {
							excludedItemID: []
						},
						merchandizeHierarchyGroup: []
					};
				}

				if (!prevData.earnBurnEligibility.paymentEligibility) {
					prevData.earnBurnEligibility.paymentEligibility = { applicableOn: '' };
				}
				if (
					prevData.earnBurnEligibility?.paymentEligibility?.payment?.paymentGroup !=
					undefined
				) {
					prevData.earnBurnEligibility.paymentEligibility.payment.paymentGroup.map(
						(item) => {
							item.issuers.map((item1) => {
								if (item1.cashbackInformation) {
									item1.cashbackInformation = appendDate(
										item1.cashbackInformation,
										'creditBy',
										'cashbackCreditData'
									);
									item1.cashbackInformation = appendDate(
										item1.cashbackInformation,
										'expireOn',
										'cashbackExpireData'
									);
								}
							});
						}
					);
				}
				if (
					prevData.earnBurnEligibility?.paymentEligibility &&
					prevData.earnBurnEligibility?.paymentEligibility?.allowAggAndIssuer == undefined
				) {
					prevData.earnBurnEligibility.paymentEligibility.allowAggAndIssuer = false;
				}

				setMerchandizeTableData(appendablePkgProdData);
				setInitialData(prevData);
			} else {
				initialValues.earnBurnEligibility.exclusions.excludedItemIDList.excludedItemID = [];
				initialValues.earnBurnEligibility.exclusions.merchandizeHierarchyGroup = [];
				setInitialData(initialValues);
			}
		}
		if (myparam3) {
			setMerchandizeTableData(JSON.parse(myparam3));
		}
		return () => {
			setMerchandizeTableData({});
		};
	}, []);

	const validateFormikExcludedData = () => {
		let isNonFormikDataValid = true;
		merchandizeTableData.groupDetail.map((Grp, i) => {
			if (Grp.merchandizeHierarchyGroup && !Grp.merchandizeHierarchyGroup.length) {
				setInformationMsg(`Please enter the selling hierarchy data at Group-${i + 1} `);
				setInformationModalShow(true);
				isNonFormikDataValid = false;
			}
		});
		/* if (!merchandizeTableData.length) {
      setInformationMsg("Please enter the product data");
      setInformationModalShow(true);
      isNonFormikDataValid = false;
    } */
		return isNonFormikDataValid;
	};

	const validatePaymentData = (consolidatedData) => {
		let isNonFormikDataValid1 = true;
		const Pay =
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
				.paymentEligibility;

		if (!(Object.keys(Pay).length === 0) && Pay.constructor === Object) {
			const { applicableOn } =
				consolidatedData.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
					?.paymentEligibility || {};
			if (applicableOn) {
				const { paymentInformation } =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.earnBurnEligibility?.paymentEligibility?.payment || {};
				const { paymentGroup } =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.earnBurnEligibility?.paymentEligibility?.payment || {};
				if (paymentInformation == undefined && paymentGroup == undefined) {
					setInformationMsg('Please enter payment details');
					setInformationModalShow(true);
					isNonFormikDataValid1 = false;
				} else if (
					paymentInformation &&
					!(Object.keys(paymentInformation).length === 0) &&
					paymentInformation.constructor === Object
				) {
					const { paymentMode, provider, paymentType, paymentSubType } =
						consolidatedData.promotionPolicy.priceDerivationRuleEligibility
							.earnBurnEligibility.paymentEligibility?.payment?.paymentInformation ||
						{};

					let Arr = [];
					if (paymentMode) Arr.push(paymentMode);
					if (provider) Arr.push(provider);
					if (paymentType) Arr.push(paymentType);
					if (paymentSubType) Arr.push(paymentSubType);
					//debugger
					if (Arr.length < 1) {
						setInformationMsg('Please enter payment details');
						setInformationModalShow(true);
						isNonFormikDataValid1 = false;
					}
				}
			}
		}
		return isNonFormikDataValid1;
	};

	//local functions
	const submitConsolidatedForm = (values) => {
		const consolidatedData = getConsolidatedData(JSON.parse(JSON.stringify(values)));
		if (validateFormikExcludedData()) {
			if (validatePaymentData(consolidatedData)) {
				// let consolidatedData = JSON.parse(myparam)
				//
				// consolidatedData.promotionPolicy.priceDerivationRuleEligibility = props.values

				// alert('hey'+JSON.stringify(consolidatedData))
				// postApi(apiURL, consolidatedData)
				//     .then((res) => {
				//         alert('sucess ' + JSON.stringify(res))
				//     })
				//     .catch((err) => {
				//         alert('failed ' + JSON.stringify(err))

				//     })

				let businessUnitFunctionID = JSON.parse(
					sessionStorage.getItem('taggedBrandToLoggedInUser')
				).businessUnitGroupFunctionID;
				const newConsolidatedData = omitEmpty(consolidatedData);

				const svPromUrl = JSON.parse(
					sessionStorage.getItem('applctnUrls')
				).savePromotionsUrl;

				let offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

				if (offerFlow.type == 'modify-offer-flow') {
					let dataToSend = {
						modifyPromotionRequest: {
							user: {
								userID: sessionStorage.getItem('loggedInUserId')
							},
							promotionId: offerFlow.slctdPromotionId,
							inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
						}
					};
					props.showLoadingButton(true);
					RestSvc.postData(
						urlTypes.save_promotion_end_points.modifyPromotionUrl,
						dataToSend,
						svPromUrl
					)
						.then((response) => {
							props.showLoadingButton(false);
							var result = response.data.modifyPromotionResponse;
							if (result.businessError) {
								toaster.notify(
									({ onClose }) => (
										<div className="Toaster__message-wrapper">
											<div>
												<div
													id="1"
													className="toasterRed Toaster__alert Toaster__alert_error"
												>
													<div className="Toaster__alert_text">
														{result.businessError.errorMessage}
													</div>
													<button
														className="Toaster__alert_close"
														type="button"
														aria-label="Close"
														onClick={onClose}
													>
														<span aria-hidden="true">x</span>
													</button>
												</div>
											</div>
										</div>
									),
									{
										duration: 10000
									}
								);
							} else {
								const promotionSuccessMsg =
									"Promotion '" +
									result.promotionId +
									"' is modified successfully.";
								toaster.notify(
									({ onClose }) => (
										<div className="Toaster__message-wrapper">
											<div>
												<div
													id="1"
													className="bg-success Toaster__alert Toaster__alert_error"
												>
													<div className="Toaster__alert_text">
														{promotionSuccessMsg}
													</div>
													<button
														className="Toaster__alert_close"
														type="button"
														aria-label="Close"
														onClick={onClose}
													>
														<span aria-hidden="true">x</span>
													</button>
												</div>
											</div>
										</div>
									),
									{
										duration: 10000
									}
								);
								props.setDisableItem(false);
								history.push('/MainPage/offerconfigurator');
							}
						})
						.catch((error) => {
							props.showLoadingButton(false);
							console.error(error);
						});
				} else {
					let dataToSend = {
						savePromotionJsonRequest: {
							user: {
								userID: sessionStorage.getItem('loggedInUserId')
							},
							businessUnitGroupFunctionID: businessUnitFunctionID,
							inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
							//"inputJson": inputJson
						}
					};
					props.showLoadingButton(true);
					RestSvc.postData(
						urlTypes.save_promotion_end_points.savePromotionJsonUrl,
						dataToSend,
						svPromUrl
					)
						.then((response) => {
							props.showLoadingButton(false);
							var result = response.data.savePromotionJsonResponse;
							if (result.businessError) {
								toaster.notify(
									({ onClose }) => (
										<div className="Toaster__message-wrapper">
											<div>
												<div
													id="1"
													className="toasterRed Toaster__alert Toaster__alert_error"
												>
													<div className="Toaster__alert_text">
														{result.businessError.errorMessage}
													</div>
													<button
														className="Toaster__alert_close"
														type="button"
														aria-label="Close"
														onClick={onClose}
													>
														<span aria-hidden="true">x</span>
													</button>
												</div>
											</div>
										</div>
									),
									{
										duration: 10000
									}
								);
							} else {
								const promotionSuccessMsg =
									"Promotion '" + result.promotionId + "' is saved successfully.";
								toaster.notify(
									({ onClose }) => (
										<div className="Toaster__message-wrapper">
											<div>
												<div
													id="1"
													className="bg-success Toaster__alert Toaster__alert_error"
												>
													<div className="Toaster__alert_text">
														{promotionSuccessMsg}
													</div>
													<button
														className="Toaster__alert_close"
														type="button"
														aria-label="Close"
														onClick={onClose}
													>
														<span aria-hidden="true">x</span>
													</button>
												</div>
											</div>
										</div>
									),
									{
										duration: 10000
									}
								);
								props.setDisableItem(false);
								history.push('/MainPage/offerconfigurator');
							}
						})
						.catch((error) => {
							props.showLoadingButton(false);
							console.error(error);
						});
				}
			}
		}
	};

	const getConsolidatedData = (values) => {
		let consolidatedData = JSON.parse(myparam);

		consolidatedData.promotionPolicy.promotionID = consolidatedData.promotionPolicy.promotionID
			? consolidatedData.promotionPolicy.programID +
			  ':' +
			  consolidatedData.promotionPolicy.promotionID
			: '';
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility = values;
		//consolidatedData.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.groupDetail = PKGProductTableData;

		/*  if (!isObjectEmpty(PKGProductTableData)) {

     try {
        const productData = { ...PKGProductTableData }
        let newPriceDerivationRuleEligibility = consolidatedData.promotionPolicy.priceDerivationRuleEligibility
        //debugger
        const newPackageProductEligibility = newPriceDerivationRuleEligibility.earnBurnEligibility.groupDetail.map((response, j) => {
          //let newArr = { ...response }
          //debugger
            return { ...response, multibuyProduct: productData.groupDetail[j].multibuyProduct }      
        })
        //debugger
        newPriceDerivationRuleEligibility.earnBurnEligibility = newPackageProductEligibility
        consolidatedData.promotionPolicy.priceDerivationRuleEligibility = newPriceDerivationRuleEligibility
      } catch (err) {
        
      }
    }  */

		if (!isObjectEmpty(merchandizeTableData)) {
			try {
				const productData = { ...merchandizeTableData };
				let newPriceDerivationRuleEligibility =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility;
				//debugger;
				const newGroup =
					newPriceDerivationRuleEligibility.earnBurnEligibility.groupDetail.map(
						(response, j) => {
							//debugger;

							//debugger;
							return {
								...response,
								merchandizeHierarchyGroup:
									productData.groupDetail[j].merchandizeHierarchyGroup
							};
						}
					);
				newPriceDerivationRuleEligibility.earnBurnEligibility.groupDetail = newGroup;
				consolidatedData.promotionPolicy.priceDerivationRuleEligibility =
					newPriceDerivationRuleEligibility;
			} catch (err) {
				console.error(err);
			}
		}
		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
				?.group
		) {
			delete consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.earnBurnEligibility?.group;
		}

		/*   if (
      consolidatedData.promotionPolicy.priceDerivationRuleEligibility
        .earnBurnEligibility?.expireOn?.expireOnType ) {

        delete  consolidatedData.promotionPolicy.priceDerivationRuleEligibility
          .earnBurnEligibility?.expireOn?.expireOnType
      
    } */

		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
				?.expireOn != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.earnBurnEligibility,
					'expireOn',
					'expireOnType'
				);
		}
		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility
				.paymentEligibility?.payment?.paymentGroup != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.earnBurnEligibility.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers.map((item1) => {
						if (item1.cashbackInformation) {
							item1.cashbackInformation = trimDate(
								item1.cashbackInformation,
								'creditBy',
								'cashbackCreditData'
							);
							item1.cashbackInformation = trimDate(
								item1.cashbackInformation,
								'expireOn',
								'cashbackExpireData'
							);
						}
					});
				}
			);
		}

		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod[0]={};
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate=[];
		// if(consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity&&consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date==""){
		//     consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date= undefined
		// }
		// if(!consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.customerActivationRequired){
		//     consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity= undefined
		// }

		return consolidatedData;
	};

	const gotoCreateOffer = async (values) => {
		// const consolidatedData = getConsolidatedData(values);

		const dataFromBasicDetails = JSON.parse(myparam);
		//const tableData = JSON.parse(myparam2);
		dataFromBasicDetails.promotionPolicy.priceDerivationRuleEligibility = values;
		const consolidatedData = dataFromBasicDetails;
		await history.push('/MainPage/offerconfigurator/offerBasicDetails', {
			params: JSON.stringify(consolidatedData),
			MTMBProdTableData: JSON.stringify(merchandizeTableData)
		});
	};

	const onPKGMerchTableChange = (val) => {
		//debugger;
		setMerchandizeTableData(val);
	};

	const linkText = {
		elibilitySettings: [
			'Purchase and Reward Conditions',
			'Exclusions Settings',
			'Payment Conditions',
			'Benefit Settings'
		]
	};

	const [confirmationModalShow, setCnfrmatnModalShow] = useState(false);
	const showCancelConfirmation = () => {
		setCnfrmatnModalShow(true);
	};

	const confirmationModalClose = () => {
		setCnfrmatnModalShow(false);
	};

	const proceedToOperation = () => {
		props.setDisableItem(false);
		history.push('/MainPage/offerconfigurator');
	};

	const onMouseLeaveHandler = () => {
		props.setMousePlace('');
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

	return (
		<div className="main-container create-offer-container">
			<div className="header-flex">
				<div className="back-div" onClick={showCancelConfirmation}>
					<div className="pr-2">
						<img src={chevronDown} alt="" />
					</div>
					<div>Back</div>
				</div>

				<div className="header-text">{getOfferFlowType()}</div>
			</div>
			<div className="flex-mainContent">
				<div className="left-navigation br-right">
					<div className="text-header">Earn Burn Selling Hierarchy</div>
					{linkText.elibilitySettings.map((val, ind) => (
						<div key={ind} className="text-content">
							<Link
								//activeClass="activePromotionSection"
								activeClass=""
								className={
									'scrollTo' + val.replace(/\s/g, '') == props.curntMousePlace
										? 'activePromotionSection'
										: ''
								}
								to={'scrollTo' + val.replace(/\s/g, '')}
								spy={true}
								smooth={true}
								duration={250}
								containerId="containerElement"
							>
								{val}
							</Link>
						</div>
					))}
				</div>
				<Element
					id="containerElement"
					className="right-content"
					onMouseLeave={onMouseLeaveHandler}
				>
					<>
						{!isObjectEmpty(initialData) && (
							<Formik
								initialValues={initialData}
								onSubmit={async (values) => {
									await new Promise((resolve) => setTimeout(resolve, 500));
									await submitConsolidatedForm(values);
								}}
								validationSchema={SignupSchema}
							>
								{(props) => {
									const { isSubmitting, handleSubmit } = props;
									return (
										<form onSubmit={handleSubmit}>
											<div className="item-title">
												{localConst.eligibilitySettings}
											</div>
											<EligibilityComponentEBSH
												onPKGMerchTableChange={onPKGMerchTableChange}
												dataMRCHtable={merchandizeTableData}
												fullVal={myparam}
											/>
											<div className="footer">
												<button
													type="button"
													className="goTo-basicDetails mr-3"
													onClick={async () => {
														await gotoCreateOffer(props.values);
													}}
												>
													Go To Basic Details
												</button>
												{offerFlow.type == 'view-offer-flow' ? null : (
													<button
														type="submit"
														className="submit-class"
														disabled={isSubmitting}
													>
														Submit
													</button>
												)}
											</div>
										</form>
									);
								}}
							</Formik>
						)}
					</>
				</Element>
			</div>
			{confirmationModalShow ? (
				<ConfirmationModal
					show={confirmationModalShow}
					onHide={confirmationModalClose}
					onProceed={proceedToOperation}
					confirmmsg={'Are you sure you want to navigate away?'}
				/>
			) : null}
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
